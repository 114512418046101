import React from "react";

const TrendUp01 = ({ stroke }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.3346 5.83331L11.7774 12.3905C11.4474 12.7205 11.2824 12.8855 11.0922 12.9473C10.9248 13.0017 10.7445 13.0017 10.5771 12.9473C10.3868 12.8855 10.2218 12.7205 9.89183 12.3905L7.61078 10.1095C7.28076 9.77944 7.11576 9.61443 6.92548 9.55261C6.75811 9.49823 6.57782 9.49823 6.41045 9.55261C6.22018 9.61443 6.05517 9.77944 5.72516 10.1095L1.66797 14.1666M18.3346 5.83331H12.5013M18.3346 5.83331V11.6666"
        stroke={stroke}
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default TrendUp01;
