import { nanoid } from "nanoid";
import constants from "../constants";
import { useContext, useState } from "react";
import { getDataFromStorage } from "../util";
import useAxiosPrivate from "./useAxiosPrivate";
import AlertContext from "../context/AlertContext";
import InstructionPageHooks from "./InstructionPageHooks";

export default function CodingTableHooks() {
  const {
    lpId,
    setMsg,
    setShow,
    setTitle,
    setTopic,
    testData,
    setStatus,
    setIsUnAuth,
    setIsLoaded,
    setIsTakeTest,
    setCodingTestData,
    setShowInstruction,
    setShowBreadCrumbsData,
    assignQuestionToLearner,
  } = useContext(AlertContext);

  const axios = useAxiosPrivate();
  const { takeTest } = InstructionPageHooks();

  const [expanded, setExpanded] = useState(false);

  const handleChange = (test) => {
    if (handleExpand(test).expand) {
      setExpanded(!expanded);
      setTopic({ id: test.topicid, name: test.topicname });
    } else {
      setExpanded(false);
    }
  };

  const handleExpand = (test) => {
    if (
      !test.showIncompleteTest &&
      (((testData.viewtest === constants.DISPLAY_TEST_RESULT.SUBMISSION ||
        testData.viewtest === constants.DISPLAY_TEST_RESULT.ALL) &&
        test.completedcount === test.totalcount) ||
        ((test.showTakeTest || test.showRetakeTest || test.showResumeTest) &&
          (test.completedcount === 0 ||
            test.completedcount === test.totalcount)) ||
        ((test.showTakeTest || test.showRetakeTest || test.showResumeTest) &&
          (test.completedcount !== 0 ||
            test.completedcount !== test.totalcount) &&
          (testData.viewtest === constants.DISPLAY_TEST_RESULT.NONE ||
            testData.viewtest === constants.DISPLAY_TEST_RESULT.SCORE)))
    ) {
      return {
        expand: false,
        disable: false,
      };
    } else if (
      !test.showIncompleteTest &&
      (test.showTakeTest || test.showRetakeTest || test.showResumeTest) &&
      (test.completedcount !== 0 || test.completedcount !== test.totalcount)
    ) {
      return {
        expand: true,
        disable: false,
      };
    } else {
      return {
        expand: false,
        disable: true,
      };
    }
  };

  const testStatus = (test) => {
    if (test.showIncompleteTest) {
      return constants.PROGRESS_STATUS.IN_PROGRESS;
    } else if (test.showRetakeTest || test.showResumeTest) {
      return constants.PROGRESS_STATUS.QN_CONTINUE;
    } else if (
      test.completedcount >= 0 &&
      test.completedcount < test.totalcount
    ) {
      return constants.PROGRESS_STATUS.UNLOCKED;
    } else if (testData.viewtest === constants.DISPLAY_TEST_RESULT.SCORE) {
      return constants.PROGRESS_STATUS.REATTEMPT;
    } else {
      return constants.PROGRESS_STATUS.EVALUATED;
    }
  };

  const currentTestLabel = (test) => {
    if (test.showIncompleteTest) {
      return constants.PROGRESS_STATUS.IN_PROGRESS;
    } else if (test.showResumeTest) {
      return constants.PROGRESS_STATUS.QN_CONTINUE;
    } else if (test.showTakeTest) {
      return constants.PROGRESS_STATUS.UNLOCKED;
    } else if (test.showRetakeTest) {
      return constants.PROGRESS_STATUS.REATTEMPT;
    } else if (
      testData.viewtest === constants.DISPLAY_TEST_RESULT.ALL ||
      testData.viewtest === constants.DISPLAY_TEST_RESULT.SUBMISSION
    ) {
      return constants.PROGRESS_STATUS.EVALUATED;
    } else {
      return constants.PROGRESS_STATUS.LOCKED;
    }
  };

  const viewCode = (test, type, question) => {
    setIsTakeTest(false);
    setIsLoaded(true);

    for (let i = 0; i < test.qndata.length; i++) {
      if (
        test.qndata[i].status === constants.QUESTION_STATUS.SUBMITTED ||
        test.qndata[i].status === constants.QUESTION_STATUS.TO_BE_EVALUATED
      ) {
        axios
          .post(
            "node/learner/report/question",
            JSON.stringify({
              lpid: lpId,
              lmtid: testData.lmtid,
              lmpid: testData.lmpid,
              testid: testData.testid,
              mockuse: testData.mockuse,
              learnerid: getDataFromStorage("learnerid"),
              testqnno: type === "single" ? question.qnno : test.qndata[0].qnno,
              questionid:
                type === "single" ? question.qnid : test.qndata[0].qnid,
            }),
            {
              timeout: 60000,
              headers: {
                "Content-type": "application/json",
              },
            }
          )
          .then((res) => {
            setIsLoaded(false);

            if (res.data.resultCode !== constants.RESULT_STATUS.SUCCESS) {
              setShow(true);
              setTitle("Info ");
              setMsg(res.data.msg);
            } else {
              let totalCount = 0;

              setStatus(
                type === "single" ? question.status : test.qndata[0].status
              );

              for (let i = 0; i < testData.data.topics.length; i++) {
                totalCount = totalCount + testData.data.topics[i].totalcount;
              }

              let response = res.data.data;
              let testCases =
                response.testcases !== null
                  ? JSON.parse(response.testcases)
                  : [];

              testCases = testCases.filter(
                (example) => example.isexample === 1
              );

              for (let i = 0; i < testCases.length; i++) {
                if (i === 0) testCases[i].isActive = true;
                testCases[i].id = nanoid();
              }

              setShowBreadCrumbsData({
                topicName: test.topicname,
                type: constants.libTypeIds.CODING_CHALLENGE,
                questionNo:
                  type === "single" ? question.qnno : test.qndata[0].qnno,
              });

              response.lpId = lpId;
              response.topics = test;
              response.secData = module;
              response.type = testData.testtype;
              response.viewTest = testData.viewtest;
              response.testname = testData.testname;
              response.secData.totalCount = totalCount;
              response.lpConfigId = testData.lpconfigid;
              response.secData.testId = testData.testid;
              response.secData.testMode = testData.testmode;
              response.testcases = JSON.stringify(testCases);
              response.breadCrumbsData = testData.data.topics;
              response.secData.proctored = response.proctored;
              response.secData.completedcount = test.completedcount;
              response.status =
                type === "single" ? question.status : test.qndata[0].status;
              assignQuestionToLearner(response);
            }
          })
          .catch((error) => {
            setIsLoaded(false);
            setShow(true);
            setTitle("Error");

            if (error.message === "timeout of 60000ms exceeded") {
              setMsg(
                "Something went wrong… program is taking too long to finish. Please, try again"
              );
            } else if (error.message.includes("403")) {
              setIsUnAuth(true);
              setMsg(
                "You have been logged-out due to inactivity. Login again."
              );
            } else {
              setMsg(error.message);
            }
          });
        break;
      }
    }
  };

  const handleCodingTest = (test, status, question) => {
    if (
      (question === undefined &&
        (status === constants.PROGRESS_STATUS.UNLOCKED ||
          status === constants.PROGRESS_STATUS.QN_CONTINUE ||
          status === constants.PROGRESS_STATUS.REATTEMPT)) ||
      (!handleExpand(test).expand &&
        !handleExpand(test).disable &&
        status !== constants.PROGRESS_STATUS.EVALUATED &&
        testData.status !== constants.PROGRESS_STATUS.EVALUATED)
    ) {
      document.documentElement.requestFullscreen();
      test.status = currentTestLabel(test);
      setCodingTestData(test);
      setStatus(currentTestLabel(test));
      setTopic({ id: test.topicid, name: test.topicname });

      if (testData.testmode === constants.MODE.TEST_MODE) {
        setShowInstruction(true);
      } else {
        takeTest(test);
      }
    } else {
      viewCode(test, question !== undefined && "single", question);
    }
  };

  const testScore = (score) => {
    let marks = 0,
      actualScore = 0;

    for (let i = 0; i < score.length; i++) {
      marks += score[i].marks;
      actualScore += score[i].actualscore;
    }

    return actualScore + "/" + marks;
  };

  return {
    expanded,
    viewCode,
    testScore,
    testStatus,
    handleExpand,
    handleChange,
    currentTestLabel,
    handleCodingTest,
  };
}
