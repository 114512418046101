import { useContext } from "react";
import constants from "../../constants";
import { getPreSignedUrl} from "../../util";
import useAxiosPrivate from "../useAxiosPrivate";
import AlertContext from "../../context/AlertContext";
import StudentTestPageHooks from "./StudentTestPageHooks";
import useCommonFunctionHooks from "../useCommonFunctionHooks";
import StudentTestPageCalcStatsHooks from "./StudentTestPageCalcStatsHooks";
import { streamToUint8Array } from "../../Components/CommonComponents/PdfViewer";
import useUploadLargeFileToS3 from "../useUploadLargeFileToS3";
import { StudentTestPageContext } from "../../context/StudentTestPageContext";
export default function StudentTestAdminHooks() {
  const Alert = useContext(AlertContext);
  // const {fSections, setFSections} = useContext(StudentTestPageContext);
  const axios = useAxiosPrivate();
  const { getS3Object } = useUploadLargeFileToS3();
  let { calcStats } = StudentTestPageCalcStatsHooks();
  let { uRole, tempReport, handleVideoQuestions } = StudentTestPageHooks();

  const { getCatchBlockDetails, getHeaderDetailsForAxios } =
    useCommonFunctionHooks();

  const handleMcq = (
    testid,
    lpid,
    lpconfigid,
    status,
    learnerid,
    proctored,
    testmode,
    configdata,
    learnerlist,
    fromreport,
    cellobj,
    libtypeid,
    viewTest,
    autoEval
  ) => {
    let testData = {
      lpid: lpid,
      testid: testid,
      status: status,
      cellobj: cellobj,
      autoEval: autoEval,
      testmode: testmode,
      viewTest: viewTest,
      learnerid: learnerid,
      proctored: proctored,
      libtypeid: libtypeid,
      configdata: configdata,
      lpconfigid: lpconfigid,
    };

    if (Alert.isAdmin) {
      Alert.setLearnerId(learnerid);
      Alert.setFromReport(fromreport);
      Alert.setTestId(testid);
    }

    Alert.setMcqAndDesTestAndLpId(testData);

    Alert.setTakeMcqAndDesTest(true);
    handleAdminView(testData);
  };

  const fetchVideoData = async (path) => {
    try {
      let url;
      if (process.env.REACT_APP_DEPLOYMENT === "LOCAL") {
        url = { url: process.env.REACT_APP_NODE_API + path };
      } else {
        const result = await getS3Object(
          path,
          process.env.REACT_APP_S3_BUCKET_NAME
        );
        
        
        const uint8Array = await streamToUint8Array(result);
        const blob = new Blob([uint8Array], { type: 'video/mp4' });
        url = URL.createObjectURL(blob);
        
      }
      return url;
    } catch (error) {
      console.error("Error fetching Video data:", error);
    }
  };

  const handleAdminView = (testData) => {
    if (
      Alert.isAdmin &&
      testData.status !== constants.PROGRESS_STATUS.UNLOCKED
    ) {
      if (Alert.isAdmin) {
        Alert.hasRendered.current = false;
      }

      if (!Alert.hasRendered.current) {
        Alert.hasRendered.current = true;

        Alert.setIsLoaded(true);
        axios
          .post(
            "node/learner/course/test/report",
            JSON.stringify({
              lpid: testData.lpid,
              testid: testData.testid,
              learnerid: testData.learnerid,
              libtypeid: testData.libtypeid,
              lpconfigid: testData.lpconfigid,
            }),
            getHeaderDetailsForAxios()
          )
          .then((res) => {
            Alert.backupTestDataq.current = res.data.data;

            (async () => {
              try {
                if (res.data.resultCode !== constants.RESULT_STATUS.SUCCESS) {
                  Alert.setShowNotify({
                    show: true,
                    title: "Info",
                    msg: res.data.message,
                  });
                } else {
                  tempReport = res.data.data;

                  if (tempReport.videoqn === 1) {
                    handleVideoQuestions(tempReport.sections);
                    Alert.videoQnLoad.current = true;
                  }
                  Alert.navigate("/mcq");

                  Alert.setTsCoBkup({});

                  Alert.setDescScores({});
                  Alert.setApiLoaded(true);
                  Alert.setMcqTestData(res.data.data);
                  calcStats(tempReport, ["all"]);
                  Alert.updateComingObj(res.data.data);
                  clearInterval(Alert.interval.current);
                  Alert.setBackupTestData(res.data.data);
                  Alert.setTestMode(res.data.data.testmode);
                  Alert.updateQuestionContainerSingle(false);
                  Alert.backupTestDataq.current = res.data.data;
                  Alert.setCommentsData(res.data.data.commentsdata);
                  Alert.setRejectData({
                    duration: res.data.data.duration,
                    timeleft: res.data.data.timeleft,
                    remaingQnCount: res.data.data.remaingQnCount,
                  });
                  Alert.setTestPageShow(true);
                  

                  for (let i = 0; i < res.data.data.sections.length; i++) {
                    for (
                      let j = 0;
                      j < res.data.data.sections[i].questions.length;
                      j++
                    ) {
                      if (process.env.REACT_APP_DEPLOYMENT === "AWS") {
                        let question = res.data.data.sections[i].questions[j];

                        if (uRole === "learner") {
                          question.review = -1;
                        }

                        for (let k = 0; k < question.links.length; k++) {
                          let linkRes = await getPreSignedUrl(
                            question.links[k].link
                          );
                          question.links[k].link = linkRes;
                        }

                        if (tempReport.videoqn === 1) {
                          if (
                            res.data.data.sections[i].questions[j].answer !==
                              "" &&
                            res.data.data.sections[i].questions[j].answer !=
                              null
                          ) {
                            let videoRes = await fetchVideoData(res.data.data.sections[i].questions[j].answer);
                            res.data.data.sections[i].questions[j].answer = videoRes;
                          }
                        }
                      }
                      if(res.data.data.sections.length-1 ===i && res.data.data.sections[i].questions.length-1 ===j){
                        console.log("res.data.data.sections[i].questions[j]",res.data.data.sections[i].questions[j]);
                        // Alert.navigate("/mcq");
                        Alert.setIsLoaded(false);                        
                        if (tempReport.videoqn === 1) Alert.setTestPageShowReload((prevState) => !prevState);
                        // setFSections(res.data.data.sec tions);
                        // Alert.updateComingObj(res.data.data);
                        Alert.videoQnLoad.current = false;
                      }
                    }
                    
                  };
                 
                }
              } catch (err) {
                getCatchBlockDetails(err);
              } 
              // finally {
              //   Alert.setIsLoaded(false);
              // }
            })();
          })
          .catch((error) => {
            getCatchBlockDetails(error);
          })
          // .finally(() => Alert.setIsLoaded(false));
      }
    }
  };

  return { handleMcq };
}
