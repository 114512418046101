import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

export default function ProgressCircle(props) {
  return (
    <div className='progresscircle-div'>
        <Box className='progresscircle-div-title-box'><Typography className='progresscircle-div-title'>Attendance</Typography></Box>
          <Box className='progresscircle-div-box'>
            <CircularProgress className='progresscircle-div-box-circle' variant="determinate" thickness={5} value={props.overallAttendancePercentage?parseFloat(props.overallAttendancePercentage.toFixed()):0} />
            <Box className='progresscircle-div-box-percentage-box'>
                <Typography className='progresscircle-div-box-percentage' variant="caption" component="div">{props.overallAttendancePercentage?parseFloat(props.overallAttendancePercentage.toFixed()):0}%</Typography>
            </Box>
          </Box>
    </div>
  )
}
