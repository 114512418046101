import React, { useContext, useState } from "react";
import Button from "../../Button";
import {
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import _ from "lodash";
import { nanoid } from "nanoid";
import Work from "./Work";
import ProjectForm from "./ProjectForm";
import CertificateForm from "./CertificateForm";
import AchievementForm from "./AchievementForm";
import { ProfileContext } from "../../../context/ProfileContext";
import dayjs from "dayjs";
import AlertContext from "../../../context/AlertContext";
import { isEnableProfileDetailsEdit } from "../../../util";

const AcademicDetail = ({ from }) => {
  const { profileDetails, setProfileDetails } = useContext(ProfileContext);
  const { setShowNotify } = useContext(AlertContext);

  const [detail, setDetail] = useState({});
  const [openPopup, setOpenPopup] = useState(false);
  const [workId, setWorkId] = useState(null);
  const [isEditWork, setIsEditWork] = useState(false);

  const handleSave = () => {
    if (workId === 1) {
      if (dayjs(detail.enddate).diff(dayjs(detail.startddate)) < 0) {
        setShowNotify({
          show: true,
          title: "Warning !",
          msg: "Check the project startdate and enddate.",
        });
        return;
      }
    }
    let details = JSON.parse(JSON.stringify(profileDetails.academic));
    let index = _.findIndex(details, (detail) => detail.id === workId, 0);
    let specificAcadmic = details[index];
    specificAcadmic.details.push({
      id: nanoid(),
      ...detail,
    });
    details[index] = specificAcadmic;
    setProfileDetails((prev) => ({ ...prev, academic: details }));
    handleClosePopup();
  };

  const handleUpdate = () => {
    let details = JSON.parse(JSON.stringify(profileDetails.academic));
    let index = _.findIndex(details, (detail) => detail.id === workId, 0);
    let specificAcadmic = details[index];
    details[index].details = _.map(specificAcadmic.details, (details) => {
      if (details.id === detail.id) details = detail;
      return details;
    });
    setProfileDetails((prev) => ({ ...prev, academic: details }));
    handleClosePopup();
  };

  const handleClosePopup = () => {
    setOpenPopup(false);
  };

  const handleDelete = (id, values) => {
    let details = JSON.parse(JSON.stringify(profileDetails.academic));
    let index = _.findIndex(details, (detail) => detail.id === id, 0);
    let specificAcadmic = details[index];
    details[index].details = _.filter(
      specificAcadmic.details,
      (details) => details.id !== values.id
    );
    setProfileDetails((prev) => ({ ...prev, academic: details }));
  };

  const handleEditWork = (id, values) => {
    handleShowPopup(id, values, true);
  };

  const handleShowPopup = (id, values, isEdit) => {
    setDetail(values);
    setOpenPopup(true);
    setWorkId(id);
    setIsEditWork(isEdit);
  };

  return (
    <React.Fragment>
      <Dialog
        open={openPopup}
        id="academicDetailDialogContainer"
        scroll="paper"
      >
        <DialogTitle className="academicDetailDialogTitleSec">
          <p className="academicDetailDialogTitle">
            Add{" "}
            {workId === 1
              ? "Project Details"
              : workId === 2
              ? "Certificate Details"
              : "Achievement"}
          </p>
          <svg
            className="academicDetailDialogCloseIcon"
            onClick={handleClosePopup}
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M18 6L6 18M6 6L18 18"
              stroke="#344054"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </DialogTitle>
        <DialogContent id="academicDetailDialogContentSec">
          {workId === 1 ? (
            <ProjectForm detail={detail} setDetail={setDetail} />
          ) : workId === 2 ? (
            <CertificateForm detail={detail} setDetail={setDetail} />
          ) : (
            <AchievementForm detail={detail} setDetail={setDetail} />
          )}
        </DialogContent>
        {isEnableProfileDetailsEdit && (
          <DialogActions id="academicDetailDialogBtnSec">
            <Button
              onClick={isEditWork ? handleUpdate : handleSave}
              size="sm"
              hierarchy={{ type: "primary", buttonText: "Save" }}
            />
          </DialogActions>
        )}
      </Dialog>
      {profileDetails.academic?.map((academicDetail, index) => (
        <React.Fragment key={index}>
          <div className="academicDetailContainer">
            <div className="academicDetailSection">
              <div className="academicDetail">
                <div className="academicDetailTitle">
                  {academicDetail.title}
                </div>
                {isEnableProfileDetailsEdit && (
                  <div className="academicDetailBtnSec">
                    <Button
                      size="sm"
                      hierarchy={{
                        type: "linkGrey",
                        buttonText: academicDetail.btnText,
                      }}
                      onClick={() => handleShowPopup(academicDetail.id, {})}
                      icon={{
                        type: "leading",
                        icon: (
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M10.0013 4.16666V15.8333M4.16797 9.99999H15.8346"
                              stroke="#667085"
                              strokeWidth="1.67"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        ),
                      }}
                    />
                  </div>
                )}
              </div>
            </div>

            <Work
              id={academicDetail.id}
              details={academicDetail.details}
              handleEditWork={handleEditWork}
              handleDelete={handleDelete}
              from={from}
            />
          </div>
          {index !== profileDetails.academic.length - 1 && <Divider />}
        </React.Fragment>
      ))}
    </React.Fragment>
  );
};

export default AcademicDetail;
