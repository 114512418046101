import React from "react";

const GraduationHat01 = ({ stroke, width, height, strokeWidth }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.16797 8.33331V13.3426C4.16797 13.6417 4.16797 13.7913 4.21351 13.9233C4.25379 14.0401 4.31952 14.1465 4.40595 14.2347C4.50369 14.3345 4.63747 14.4014 4.90502 14.5352L9.40502 16.7852C9.62365 16.8945 9.73298 16.9491 9.84764 16.9707C9.94919 16.9897 10.0534 16.9897 10.155 16.9707C10.2696 16.9491 10.379 16.8945 10.5976 16.7852L15.0976 14.5352C15.3651 14.4014 15.4989 14.3345 15.5967 14.2347C15.6831 14.1465 15.7488 14.0401 15.7891 13.9233C15.8346 13.7913 15.8346 13.6417 15.8346 13.3426V8.33331M1.66797 7.08331L9.70316 3.06571C9.81248 3.01105 9.86714 2.98372 9.92447 2.97297C9.97525 2.96344 10.0274 2.96344 10.0781 2.97297C10.1355 2.98372 10.1901 3.01105 10.2994 3.06571L18.3346 7.08331L10.2994 11.1009C10.1901 11.1556 10.1355 11.1829 10.0781 11.1936C10.0274 11.2032 9.97525 11.2032 9.92447 11.1936C9.86714 11.1829 9.81248 11.1556 9.70316 11.1009L1.66797 7.08331Z"
        stroke={stroke}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default GraduationHat01;
