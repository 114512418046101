import Button from "../../Button";
import constants from "../../../constants";
import { getDataFromStorage } from "../../../util";
import TestSvgIcon from "../../../Svg/TestSvgIcon";
import React, { useContext, useEffect } from "react";
import AlertContext from "../../../context/AlertContext";
import useAxiosPrivate from "../../../Hooks/useAxiosPrivate";
import McqAndDescHooks from "../../../Hooks/McqAndDescHooks";
import useCommonFunctionHooks from "../../../Hooks/useCommonFunctionHooks";
import { MockTestPageContext } from "../../../context/MockTestPageContext";
import MockTestLibraryHooks from "../../../Hooks/Mock/Student/MockTestLibraryHooks";

export default function MockTestResults() {
  const { setLpId, setStatus, setTestData, setIsLoaded, setShowNotify } =
    useContext(AlertContext);

  const { searchText, setMockTest, searchMockTest, setSearchMockTest } =
    useContext(MockTestPageContext);

  const axios = useAxiosPrivate();
  const { viewTest } = McqAndDescHooks();
  const { handleSearch, handleCodingInfo } = MockTestLibraryHooks();

  const { getCatchBlockDetails, getHeaderDetailsForAxios } =
    useCommonFunctionHooks();

  searchMockTest.sort(
    (date1, date2) =>
      new Date(date2.submitteddate) - new Date(date1.submitteddate)
  );

  const getSubmittedDate = (date) => {
    date = new Date(date);

    return (
      ((date.getDate() + 1).toString().length === 1
        ? "0" + date.getDate()
        : date.getDate()) +
      "/" +
      ((date.getMonth() + 1).toString().length === 1
        ? "0" + (date.getMonth() + 1)
        : date.getMonth() + 1) +
      "/" +
      date.getFullYear()
    );
  };

  const handleView = (test) => {
    let testData = {
      mockuse: true,
      testid: test.id,
      lpconfigid: 1567,
      lmpid: test.lmpid,
      lmtid: test.lmtid,
      testname: test.name,
      testmode: test.mode,
      status: test.status,
      libtype: test.libtype,
      testtype: test.libtype,
      viewtest: test.viewtest,
      autoeval: test.autoeval === 1,
      proctored: constants.PROCTORING_MODE.TAB_CAM,

      configdata: {
        marks: test.marks,
        duration: test.testduration,
        questionscount: test.questionscount,
      },
    };

    setTestData(testData);
    setStatus(constants.PROGRESS_STATUS.EVALUATED);
    setLpId(parseInt(sessionStorage.getItem("lpId")));

    if (test.libtype === constants.libTypeIds.CODING_CHALLENGE) {
      handleCodingInfo(testData);
    } else {
      viewTest();
    }
  };

  useEffect(() => {
    handleSearch();
  }, [searchText]);

  useEffect(() => {
    setIsLoaded(true);
    axios
      .post(
        "node/learner/mock/completed",
        JSON.stringify({
          learnerid: getDataFromStorage("learnerid"),
        }),
        getHeaderDetailsForAxios()
      )
      .then((res) => {
        if (res.data.resultCode !== constants.RESULT_STATUS.SUCCESS) {
          setShowNotify({
            show: true,
            title: "Info",
            msg: res.data.message,
          });
        } else {
          setMockTest(res.data.data.tests);
          setSearchMockTest(res.data.data.tests);
        }
      })
      .catch((error) => {
        getCatchBlockDetails(error);
      })
      .finally(() => {
        setIsLoaded(false);
      });
  }, []);

  return (
    <div className="mockTestResultsMainContainer">
      {searchMockTest.map((test) => (
        <div key={test.id} className="mockTestResultsGrid">
          <div className="mockTestResultsGridHeader">
            <div className="mockTestResultsGridHeaderTitle">{test.name}</div>
            <div className="mockTestResultsGridHeaderLogo">
              <TestSvgIcon autoEval={test.autoeval} libType={test.libtype} />
            </div>
          </div>
          <div className="mockTestResultsGridScoreDiv">
            Score
            <div className="mockTestResultsGridScore">
              {test.actualscore + "/" + test.targetscore}
            </div>
          </div>
          <div className="mockTestResultsGridCompletionDiv">
            Completed
            <div className="mockTestResultsGridCompletedDate">
              {getSubmittedDate(test.submitteddate)}
              <Button
                size={"sm"}
                onClick={() => handleView(test)}
                hierarchy={{
                  buttonText: "View",
                  type: "secondaryGrey",
                }}
              />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
