import React from 'react'

export default function VideoSvg() {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="18" height="18" rx="9" fill="#F8E0DB"/>
        <path d="M6.5 5.49476C6.5 5.00917 6.5 4.76638 6.60125 4.63255C6.68945 4.51595 6.82426 4.4438 6.9702 4.43509C7.13772 4.42509 7.33973 4.55976 7.74376 4.82912L13.0015 8.3343C13.3354 8.55686 13.5023 8.66815 13.5605 8.80841C13.6113 8.93103 13.6113 9.06885 13.5605 9.19148C13.5023 9.33174 13.3354 9.44302 13.0015 9.66558L7.74376 13.1708C7.33973 13.4401 7.13772 13.5748 6.9702 13.5648C6.82426 13.5561 6.68945 13.4839 6.60125 13.3673C6.5 13.2335 6.5 12.9907 6.5 12.5051V5.49476Z" fill="#F97256"/>
    </svg>
  )
}
