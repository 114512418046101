import React from "react";

const CheckVerified01 = ({ stroke, strokeWidth, width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.5013 9.99999L9.16797 11.6667L12.918 7.91666M6.11278 3.18224C6.78264 3.12878 7.41856 2.86537 7.93003 2.42951C9.12357 1.41237 10.879 1.41237 12.0726 2.42951C12.584 2.86537 13.22 3.12878 13.8898 3.18224C15.453 3.30698 16.6943 4.54828 16.8191 6.11146C16.8725 6.78132 17.1359 7.41725 17.5718 7.92871C18.5889 9.12226 18.5889 10.8777 17.5718 12.0713C17.1359 12.5827 16.8725 13.2187 16.8191 13.8885C16.6943 15.4517 15.453 16.693 13.8898 16.8177C13.22 16.8712 12.584 17.1346 12.0726 17.5705C10.879 18.5876 9.12357 18.5876 7.93003 17.5705C7.41856 17.1346 6.78264 16.8712 6.11278 16.8177C4.54959 16.693 3.30829 15.4517 3.18355 13.8885C3.13009 13.2187 2.86668 12.5827 2.43082 12.0713C1.41369 10.8777 1.41369 9.12226 2.43082 7.92871C2.86668 7.41725 3.13009 6.78132 3.18355 6.11146C3.30829 4.54828 4.54959 3.30698 6.11278 3.18224Z"
        stroke={stroke}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CheckVerified01;
