import { useContext } from "react";
import { UserReportContext } from "../../../context/UserReportContext";

export default function UserTimeSpentReportTableHooks() {
  const UserReportCxt = useContext(UserReportContext);

  const convertTimeSpent = (time) => {
    const value =
      time > 60
        ? Math.floor(time / 60) +
          " hrs " +
          (Math.round(time % 60) !== 0 ? Math.round(time % 60) + " mins" : "")
        : time === 0
        ? "-"
        : time + " mins";

    return value;
  };

  const generateDataForUserTimeSpentReportTable = (data) => {
    const result = [];

    data.forEach((item) => {
      let lp = result.find((lp) => lp.lpname === item.lpname);

      if (!lp) {
        lp = { lpname: item.lpname, modules: [] };
        result.push(lp);
      }

      let module = lp.modules.find((m) => m.mname === item.mname);

      if (!module) {
        module = { morderno: item.morderno, mname: item.mname, test: [] };
        lp.modules.push(module);
      }

      module.test.push({
        id: item.id,
        day: item.day,
        name: item.name,
        libtype: item.libtype,
        orderno: item.orderno,
        itemtype: item.itemtype,
        timespent: item.timespent,
        publisheddate: item.publisheddate,
      });
    });

    UserReportCxt.setUserTimeSpentTableData(result);
  };

  return { convertTimeSpent, generateDataForUserTimeSpentReportTable };
}
