import { useContext } from "react";
import constants from "../../constants";
import AlertContext from "../../context/AlertContext";
import StudentTestPageHooks from "./StudentTestPageHooks";
import { StudentTestPageContext } from "../../context/StudentTestPageContext";

export default function StudentTestContainerHooks() {
  const Alert = useContext(AlertContext);
  const StudentTestPageCxt = useContext(StudentTestPageContext);

  const { checkIfObjectExistsInArray } = StudentTestPageHooks();

  const setFilters = (e) => {
    let tempFilter;
    let tempFilterData;
    let name = e === undefined || e == null ? "null" : e.target.name;

    if (StudentTestPageCxt.viewFilters[name] === undefined) {
      tempFilter = StudentTestPageCxt.viewFilters;
      tempFilter = { ...StudentTestPageCxt.viewFilters, [name]: true };
      StudentTestPageCxt.setViewFilters(tempFilter);
    } else {
      tempFilter = StudentTestPageCxt.viewFilters;

      let value = StudentTestPageCxt.viewFilters[name];

      tempFilter = { ...StudentTestPageCxt.viewFilters, [name]: !value };
      StudentTestPageCxt.setViewFilters(tempFilter);
    }

    if (
      (tempFilter["tq"] === true || tempFilter["tq"] === false) &&
      tempFilter["qa"] === false &&
      tempFilter["na"] === false &&
      tempFilter["ca"] === false &&
      tempFilter["wa"] === false &&
      tempFilter["ea"] === false &&
      tempFilter["me"] === false &&
      tempFilter["ha"] === false
    ) {
      tempFilterData = JSON.stringify(Alert.backupTestData);
      tempFilterData = JSON.parse(tempFilterData);
      Alert.setMcqTestData(tempFilterData);
      Alert.updateComingObj(tempFilterData);
    } else {
      tempFilterData = JSON.stringify(Alert.backupTestData);
      tempFilterData = JSON.parse(tempFilterData);

      for (let i = 0; i < Alert.backupTestData.sections?.length; i++) {
        tempFilterData.sections[i].questions = [];

        if (
          StudentTestPageCxt.selSec === "all"
            ? true
            : i === StudentTestPageCxt.selSec
        ) {
          for (
            let j = 0;
            j < Alert.backupTestData.sections[i].questions.length;
            j++
          ) {
            if (tempFilter["qa"] === true) {
              if (
                Alert.backupTestData.sections[i].questions[j].answer !== "" &&
                Alert.backupTestData.sections[i].questions[j].answer != null
              ) {
                if (
                  !checkIfObjectExistsInArray(
                    Alert.backupTestData.sections[i].questions[j],
                    tempFilterData.sections[i].questions
                  )
                ) {
                  tempFilterData.sections[i].questions.push(
                    Alert.backupTestData.sections[i].questions[j]
                  );
                }
              }
            }

            if (tempFilter["na"] === true) {
              if (
                Alert.backupTestData.sections[i].questions[j].answer === "" ||
                Alert.backupTestData.sections[i].questions[j].answer == null
              ) {
                if (
                  !checkIfObjectExistsInArray(
                    Alert.backupTestData.sections[i].questions[j],
                    tempFilterData.sections[i].questions
                  )
                ) {
                  tempFilterData.sections[i].questions.push(
                    Alert.backupTestData.sections[i].questions[j]
                  );
                }
              }
            }

            if (tempFilter["ca"] === true) {
              if (
                Alert.backupTestData.sections[i].questions[j].actualscore ===
                Alert.backupTestData.sections[i].questions[j].targetscore
              ) {
                if (
                  !checkIfObjectExistsInArray(
                    Alert.backupTestData.sections[i].questions[j],
                    tempFilterData.sections[i].questions
                  )
                ) {
                  tempFilterData.sections[i].questions.push(
                    Alert.backupTestData.sections[i].questions[j]
                  );
                }
              }
            }

            if (tempFilter["wa"] === true) {
              if (
                Alert.backupTestData.sections[i].questions[j].actualscore !==
                Alert.backupTestData.sections[i].questions[j].targetscore
              ) {
                if (
                  !checkIfObjectExistsInArray(
                    Alert.backupTestData.sections[i].questions[j],
                    tempFilterData.sections[i].questions
                  )
                ) {
                  tempFilterData.sections[i].questions.push(
                    Alert.backupTestData.sections[i].questions[j]
                  );
                }
              }
            }

            if (tempFilter["ea"] === true) {
              if (
                Alert.backupTestData.sections[i].questions[j].complexityid ===
                constants.complexityid.EASY
              ) {
                if (
                  !checkIfObjectExistsInArray(
                    Alert.backupTestData.sections[i].questions[j],
                    tempFilterData.sections[i].questions
                  )
                ) {
                  tempFilterData.sections[i].questions.push(
                    Alert.backupTestData.sections[i].questions[j]
                  );
                }
              }
            }

            if (tempFilter["me"] === true) {
              if (
                Alert.backupTestData.sections[i].questions[j].complexityid ===
                constants.complexityid.MEDIUM
              ) {
                if (
                  !checkIfObjectExistsInArray(
                    Alert.backupTestData.sections[i].questions[j],
                    tempFilterData.sections[i].questions
                  )
                ) {
                  tempFilterData.sections[i].questions.push(
                    Alert.backupTestData.sections[i].questions[j]
                  );
                }
              }
            }

            if (tempFilter["ha"] === true) {
              if (
                Alert.backupTestData.sections[i].questions[j].complexityid ===
                constants.complexityid.HARD
              ) {
                if (
                  !checkIfObjectExistsInArray(
                    Alert.backupTestData.sections[i].questions[j],
                    tempFilterData.sections[i].questions
                  )
                ) {
                  tempFilterData.sections[i].questions.push(
                    Alert.backupTestData.sections[i].questions[j]
                  );
                }
              }
            }
          }
        }
      }
    }

    let tempTq = 0;

    for (let i = 0; i < tempFilterData.sections?.length; i++) {
      for (let j = 0; j < tempFilterData.sections[i].questions.length; j++) {
        tempTq++;
      }
    }

    Alert.setFilterQuestionCount(tempTq);
    Alert.setMcqTestData(tempFilterData);

    if (StudentTestPageCxt.selSec !== "all") {
      StudentTestPageCxt.setSectionObj(
        tempFilterData.sections[StudentTestPageCxt.selSec]
      );
    }

    StudentTestPageCxt.setFSections(tempFilterData.sections);
    Alert.updateComingObj(tempFilterData);
  };

  return { setFilters };
}
