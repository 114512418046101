import React from 'react'

export default function Leetcode() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_5673_10727)">
        <path d="M10.7352 11.9534L8.93725 13.6914C8.62658 14.0027 8.19658 14.1327 7.72192 14.1327C7.24725 14.1327 6.81725 14.0027 6.50592 13.6914L3.61792 10.7827C3.30658 10.4714 3.14992 10.0161 3.14992 9.54074C3.14992 9.06541 3.30658 8.63607 3.61792 8.32474L6.49725 5.40474C6.80858 5.09341 7.24725 4.97474 7.72192 4.97474C8.19658 4.97474 8.62658 5.10474 8.93725 5.41607L10.7352 7.15341C11.0779 7.49674 11.6453 7.48474 12.0019 7.12807C12.3586 6.77074 12.3706 6.20341 12.0279 5.86074L10.2886 4.10341C9.83949 3.65944 9.2747 3.3506 8.65858 3.21207L10.3033 1.54341C10.6473 1.20074 10.6353 0.63274 10.2786 0.276073C9.92192 -0.0805932 9.35392 -0.0919266 9.01058 0.25074L2.27725 6.98474C1.62325 7.63941 1.28125 8.54274 1.28125 9.54141C1.28125 10.5401 1.62325 11.4714 2.27725 12.1247L5.17525 15.0321C5.82925 15.6847 6.73325 16.0001 7.73125 16.0001C8.72925 16.0001 9.63325 15.6587 10.2879 15.0041L12.0273 13.2461C12.3699 12.9034 12.3579 12.3361 12.0012 11.9794C11.6446 11.6227 11.0772 11.6107 10.7352 11.9534ZM13.8746 8.67341H7.11125C6.64325 8.67341 6.26458 9.07607 6.26458 9.57074C6.26458 10.0654 6.64325 10.4681 7.11125 10.4681H13.8746C14.3419 10.4681 14.7212 10.0654 14.7212 9.57074C14.7212 9.07607 14.3419 8.67341 13.8746 8.67341Z" fill="#667085"/>
        </g>
        <defs>
        <clipPath id="clip0_5673_10727">
        <rect width="16" height="16" fill="white"/>
        </clipPath>
        </defs>
    </svg>
  )
}
