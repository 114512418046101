import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import Badge from "../../../CommonComponents/Badge";
import Button from "../../../Button";
import { useParams } from "react-router-dom";
import useAxiosPrivate from "../../../../Hooks/useAxiosPrivate";
import AlertContext from "../../../../context/AlertContext";
import _ from "lodash";
import constants from "../../../../constants";
import Container from "../Report/DropdownContainer";

const badgeColor = { 1: "#2E90FA", 2: "#e54b2a", 3: "#c11574" };

export const getTests = (data, id, isLp) => {
  let tests = [];
  for (const path of data) {
    if (isLp && path.value === id) {
      for (const module of path.children) {
        for (const test of module.children) {
          tests.push(test.value);
        }
      }
    }
    if (!isLp)
      for (const module of path.children) {
        if (module.value === id) {
          for (const test of module.children) {
            tests.push(test.value);
          }
        }
      }
  }
  return tests;
};

export const findNameById = (data, id, isLp, isModule) => {
  for (const lp of data) {
    if (isLp && lp.value === id) return lp.label;
    for (const module of lp.children) {
      if (isModule && module.value === id) return module.label;
      for (const test of module.children) {
        if (test.value === id) {
          return test.label;
        }
      }
    }
  }
};

// export const findNameById = (id, transformedData) => {
//   for (const item of transformedData) {
//     for (const moduleChild of item.children)
//       for (const child of moduleChild.children) {
//         if (child.value === id) {
//           return child.label;
//         }
//       }
//   }
//   return null;
// };

export const mapIdsToNames = (sidObject, transformedData) => {
  const assessments = sidObject[2]?.map((id) => ({
    id,
    name: findNameById(transformedData, id, false, false),
    isAssessment: true,
  }));
  const assignments = sidObject[1]?.map((id) => ({
    id,
    name: findNameById(transformedData, id, false, false),
    isAssessment: false,
  }));
  const video = sidObject[3]?.map((id) => ({
    id,
    name: findNameById(transformedData, id, false, false),
    isVideo: true,
  }));
  return { 2: assessments, 1: assignments, 3: video };
};

export const markCheckedNodes = (data, idsToCheck) => {
  const updateCheckedStatus = (node) => {
    if (node.children) {
      // Update the children first
      const updatedChildren = node.children.map((child) =>
        updateCheckedStatus(child)
      );

      // Check if all children are checked
      const allChildrenChecked = updatedChildren.every(
        (child) => child.checked
      );

      return {
        ...node,
        children: updatedChildren,
        checked: allChildrenChecked,
      };
    } else {
      // Leaf node, directly check if its value is in idsToCheck
      return {
        ...node,
        checked: idsToCheck.includes(node.value),
      };
    }
  };

  return data.map((lp) => updateCheckedStatus(lp));
};

const SkillBody = ({
  bSkillId,
  open,
  setSkillsCollapse,
  batchTests,
  setBatchTests,
  setTestOptions,
  testOptions,
  allTests,
  payload,
  setPayload,
}) => {
  const { id } = useParams();
  const axios = useAxiosPrivate();
  const { setIsLoaded, setShowNotify } = useContext(AlertContext);
  const [skillTests, setSkillTests] = useState({});
  const [tests, setTests] = useState({
    1: [],
    2: [],
    3: [],
  });
  const curPayload = useRef(payload);

  useEffect(() => {
    if (!open || !bSkillId) return;
    const fetchSkillTests = async () => {
      setIsLoaded(true);
      try {
        let response = await axios.get(
          `node/admin/batch/${id}/skills/${bSkillId}/tests`,
          {
            headers: {
              "Content-type": "application/json",
            },
          }
        );
        // console.log("response of fetch skill tests", response);
        if (response.data.resultCode === constants.RESULT_STATUS.SUCCESS) {
          if (response.data.data) {
            setSkillTests(JSON.parse(response.data.data));
          }
        } else if (
          response.data.resultCode === constants.RESULT_STATUS.TECHNICAL_ERROR
        ) {
          setShowNotify({
            show: true,
            title: "Error !",
            msg: response.data.msg,
          });
        } else {
          setShowNotify({
            show: true,
            title: "Error !",
            msg: "Something went wrong. Please try again later or contact gradious team.",
          });
        }
      } catch (error) {
        console.error("error while fetch batch skill tests", error);
        if (error.message.includes("403")) {
          setShowNotify({
            show: true,
            title: "Warning !",
            msg: "You have been logged-out due to inactivity. Login again.",
            isUnAuth: true,
          });
        } else
          setShowNotify({
            show: true,
            title: "Error !",
            msg: "Something went wrong. Please try again later or contact gradious team.",
          });
      } finally {
        setIsLoaded(false);
      }
    };
    fetchSkillTests();
  }, [id, axios, setIsLoaded, bSkillId, open, setShowNotify]);

  useEffect(() => {
    if (skillTests && Object.keys(skillTests).length > 0) {
      let temp = { 1: [], 2: [] },
        payloadDetails = {
          1: [],
          2: [],
          3:
            skillTests && skillTests.videoid
              ? [
                  {
                    id: skillTests.videoid,
                    name: findNameById(
                      allTests.current,
                      skillTests.videoid,
                      false,
                      false
                    ),
                    isVideo: true,
                    isTest: true,
                  },
                ]
              : [],
        },
        videoId = skillTests.videoid ? [skillTests.videoid] : [];
      skillTests.tests.forEach((item) => {
        if (item.lpid) {
          let test = {
            id: item.lpid,
            name: findNameById(allTests.current, item.lpid, true),
            isAssessment: item.isassessment === 1,
            isLp: true,
          };
          if (item.isassessment) payloadDetails[2].push(test);
          else payloadDetails[1].push(test);
          let tests = getTests(allTests.current, item.lpid, true);
          if (item.isassessment) temp[2] = [...temp[2], ...tests];
          else temp[1] = [...temp[1], ...tests];
        } else if (item.mid) {
          let test = {
            id: item.mid,
            name: findNameById(allTests.current, item.mid, false, true),
            isAssessment: item.isassessment === 1,
            isModule: true,
          };
          if (item.isassessment) payloadDetails[2].push(test);
          else payloadDetails[1].push(test);

          let tests = getTests(allTests.current, item.mid, false);
          if (item.isassessment) temp[2] = [...temp[2], ...tests];
          else temp[1] = [...temp[1], ...tests];
        }
        if (item.lpcid) {
          let test = {
            id: item.lpcid,
            name: findNameById(allTests.current, item.lpcid, false, false),
            isAssessment: item.isassessment === 1,
            isTest: true,
          };
          if (item.isassessment) payloadDetails[2].push(test);
          else payloadDetails[1].push(test);
          if (item.isassessment) temp[2].push(item.lpcid);
          else temp[1].push(item.lpcid);
        }
      });

      const mapped = mapIdsToNames({ ...temp, 3: videoId }, allTests.current);
      const idsToCheck = [...videoId, ...temp[2], ...temp[1]];

      const updatedTransformedData = markCheckedNodes(
        allTests.current,
        idsToCheck
      );
      setBatchTests(updatedTransformedData);
      setTestOptions((prev) => ({
        1: markCheckedNodes(prev[1], temp[1]),
        2: markCheckedNodes(prev[2], temp[2]),
        3: markCheckedNodes(prev[3], skillTests.videoid ? videoId : []),
      }));
      setTests(mapped);
      setPayload(payloadDetails);
      curPayload.current = payloadDetails;
    }
  }, [skillTests, allTests, setBatchTests, setTestOptions, setPayload]);

  const onSelectTest = useCallback(
    (currentNode, selectedNodes, index) => {
      const isAssessment = index === 2,
        payloadDetails = [];
      let idsToCheck = [];
      if (index === 3 && selectedNodes.length > 1) {
        setTestOptions((prev) => ({
          ...prev,
          [index]: markCheckedNodes(
            prev[index],
            tests[index].map((item) => item.id)
          ),
        }));
        setShowNotify({
          show: true,
          title: "Warning !",
          msg: "You can only map one video assessment to a skill.",
        });

        return;
      }

      for (let item of selectedNodes) {
        let selectedNode = {
          id: item.value,
          name: item.label,
        };
        if (index === 3) selectedNode.isVideo = true;
        else selectedNode.isAssessment = isAssessment;
        if (item.isLp) selectedNode.isLp = item.isLp;
        else if (item.isModule) selectedNode.isModule = item.isModule;
        else selectedNode.isTest = true;
        payloadDetails.push(selectedNode);

        // update test state to diplay selected tests
        if (item.isLp) {
          idsToCheck = [
            ...idsToCheck,
            ...getTests(allTests.current, item.value, true),
          ];
        } else if (item.isModule) {
          console.log("item", item, "all", allTests.current);
          idsToCheck = [
            ...idsToCheck,
            ...getTests(allTests.current, item.value, false),
          ];
        }
        if (item.isTest) {
          idsToCheck = [...idsToCheck, item.value];
        }
      }
      const mapped = mapIdsToNames({ [index]: idsToCheck }, allTests.current);
      const updatedTransformedData = markCheckedNodes(
        allTests.current,
        idsToCheck
      );
      setBatchTests(updatedTransformedData);
      setTestOptions((prev) => ({
        ...prev,
        [index]: markCheckedNodes(prev[index], idsToCheck),
      }));
      setTests((prev) => ({ ...prev, [index]: mapped[index] }));
      setPayload((pre) => ({ ...pre, [index]: payloadDetails }));
      curPayload.current[index] = payloadDetails;
    },
    [allTests, setBatchTests, setShowNotify, setTestOptions, tests, setPayload]
  );

  const onDeleteTest = (index, id) => {
    let newDetails = tests[index];
    newDetails = _.filter(newDetails, (item) => item.id !== id);
    const checkedBatchTests = markCheckedNodes(
      batchTests,
      newDetails.map((item) => item.id)
    );
    setTestOptions((prev) => ({
      ...prev,
      [index]: markCheckedNodes(
        prev[index],
        newDetails.map((item) => item.id)
      ),
    }));
    setTests((prev) => ({ ...prev, [index]: newDetails }));
    let updatedPayload = { ...curPayload.current };
    if (index === 1 || index === 2) {
      let result = [],
        isAssessment = index === 2;
      allTests.current = checkedBatchTests;
      const processSelection = (item, isLp = false) => {
        if (item.children && item.children.length > 0) {
          const selectedChildren = item.children.filter(
            (child) => child.checked || processSelection(child)
          );
          if (selectedChildren.length === item.children.length) {
            // All children are selected, return module or LP
            if (item.isModule) {
              return {
                id: item.value,
                name: item.label,
                isAssessment: isAssessment,
                isModule: true,
              };
            } else if (isLp) {
              return {
                id: item.value,
                name: item.label,
                isAssessment,
                isLp: true,
              };
            }
          } else if (selectedChildren.length > 0) {
            selectedChildren.forEach((child) => {
              let node = {
                id: child.value,
                name: child.label,
                isAssessment,
              };
              if (child.isLp) node.isLp = child.isLp;
              if (child.isModule) node.isModule = child.isModule;
              if (child.isTest) node.isTest = child.isTest;
              result.push(node);
            });
          }
        } else if (item.isTest && item.checked) {
          return {
            id: item.value,
            name: item.label,
            isAssessment,
            isTest: true,
          };
        }
      };
      checkedBatchTests.forEach((lp) => {
        const selectedLp = processSelection(lp, true);
        if (selectedLp) result.push(selectedLp);
      });
      updatedPayload[index] = result;
    } else if (index === 3) {
      updatedPayload[index] = updatedPayload[index].filter(
        (item) => !(item.id === id && item.isVideo)
      );
    }
    console.log("updated", updatedPayload);
    setPayload(updatedPayload);
    curPayload.current = updatedPayload;
  };

  const onSave = async (event) => {
    console.log("payload", payload);
    event.preventDefault();
    if (bSkillId) {
      const finalPayload = [
        ...curPayload.current[1],
        ...curPayload.current[2],
        ...curPayload.current[3],
      ];
      setIsLoaded(true);
      try {
        let response = await axios.post(
          `node/admin/batch/${id}/skills/${bSkillId}/tests`,
          finalPayload,
          {
            headers: {
              "Content-type": "application/json",
            },
          }
        );
        if (response.data.resultCode === constants.RESULT_STATUS.SUCCESS) {
          setShowNotify({
            show: true,
            title: "Info",
            msg: "Tests are mapped successfully.",
          });
          // setSkillsCollapse((prev) => ({ ...prev, [bSkillId]: false }));
        } else
          setShowNotify({
            show: true,
            title: "Error !",
            msg: "Something went wrong. Please try again later or contact gradious team.",
          });
      } catch (error) {
        setShowNotify({
          show: true,
          title: error.message.includes("403") ? "Warning !" : "Error !",
          msg: error.message.includes("403")
            ? "You have been logged-out due to inactivity. Login again."
            : "Something went wrong. Please try again later or contact gradious team.",
          isUnAuth: error.message.includes("403"),
        });
      } finally {
        setIsLoaded(false);
      }
    }
  };

  return (
    <div className="skillBodyContainer">
      <div className="skillBodyMapContainer">
        <div className="skillBodyMapSec">
          <div className="skillBodyAssignmentContainer">
            {Object.keys(tests).map((data, index) => (
              <div key={index} className="skillBodyAssignmentSec">
                <div className="skillBodyAssignmentTitle">
                  Select{" "}
                  {index === 0
                    ? "Assignments"
                    : index === 1
                    ? "Assessments"
                    : "Video Test"}{" "}
                  related to skill{" "}
                  <Badge
                    size="sm"
                    label={tests[index + 1]?.length ?? 0}
                    color="grey"
                  />
                </div>
                <div className="skillBodyAssignmentInputSec">
                  <Container
                    id={index}
                    className="skillBodyAssignmentInput"
                    keepTreeOnSearch={true}
                    keepOpenOnSelect={false}
                    onChange={(currentNode, selectedNodes) =>
                      onSelectTest(currentNode, selectedNodes, index + 1)
                    }
                    data={testOptions[index + 1]}
                    showPartiallySelected={true}
                    texts={{
                      placeholder: `Select ${
                        index === 0
                          ? "Assignments"
                          : index === 1
                          ? "Assessments"
                          : "Video Test"
                      }`,
                    }}
                  />
                </div>
                <div className="skillBodyAssignments">
                  {_.map(tests[index + 1], (item, testIndex) => (
                    <Badge
                      key={testIndex}
                      label={item.name}
                      icon={{
                        icon: (
                          <svg
                            width="12"
                            height="12"
                            viewBox="0 0 12 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            onClick={() => onDeleteTest(index + 1, item.id)}
                          >
                            <path
                              d="M9 3L3 9M3 3L9 9"
                              stroke={badgeColor[index + 1]}
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        ),
                        position: "end",
                      }}
                      color={
                        index === 0 ? "blue" : index === 1 ? "primary" : "pink"
                      }
                    />
                  ))}
                </div>
              </div>
            ))}
          </div>

          <div className="skillBodyMapBtnSec">
            <Button
              size="sm"
              hierarchy={{ type: "primary", buttonText: "Save" }}
              onClick={onSave}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(SkillBody);
