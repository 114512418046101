import React, { useContext, useEffect } from "react";
import { getDataFromStorage } from "../util";
import SettingSvgIcon from "./SettingSvgIcon";
import HeaderHooks from "../Hooks/HeaderHooks";
import IconButton from "@mui/material/IconButton";
import NotificationSvgIcon from "./NotificationSvgIcon";
import NotificationHighlightSvgIcon from "./NotificationHighlightSvgIcon";
import AlertContext from "../context/AlertContext";
export default function HeaderRightNavSvgIcon() {
  const { getLatestSessions } = HeaderHooks();
  const {showBlink} = useContext(AlertContext);
  return (
    <div className="headerRightNavSvgIcon">
      <IconButton
        className="notificationSvgIcon"
        onClick={() => getLatestSessions(getDataFromStorage("learnerid"))}
      >
        {showBlink ? (
          <span id="svg-element" 
          // className="blinking"
          ><NotificationHighlightSvgIcon/></span>
        ) : (
          <NotificationSvgIcon />
        )}
      </IconButton>
    </div>
  );
}