import React, { useState, useEffect, useContext } from "react";
import { makeStyles } from "@mui/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import constants from "../../../constants";
function BatchListView(Props) {

  const getStyles = makeStyles({
    headerColCell: {

      // position:'sticky',
      // top:'6vh',
      paddingLeft:"200px",
      fontFamily: "Inter",
      fontSize: "14px",
      fontWeight: "500",
      lineHeight: "20px",
      textAlign: "left",
      color: "#667085"
     
    },
    bodyColCell:{
      // position:'sticky',
      paddingLeft:"200px",
      fontFamily: "Inter",
      fontSize: "14px",
      fontWeight: "500",
      lineHeight: "20px",
      textAlign: "left",
      color: "#344054"

    }
  
  
  });
  const classes = getStyles();
  return (
    <div className={Props.from == "batch"? "listBatchViewContainer":"listLpViewContainer"}>
      {/* <TableContainer sx={{ maxHeight: "100vh" }}> */}
        <Table aria-label="table with sticky header"
          stickyHeader
          // sx={{top:"10vh"}}
          stripe="odd"
          hoverRow
          
          >
          <TableHead className="batchListHeader">
            <TableRow className="batchListHeaderRow">
              <TableCell  
              className={Props.from == "batch"? "batchListHeaderCol" :classes.headerColCell}
              //className="batchListHeaderCol
              >
                {Props.from == "batch" ? "Batch Name" : "Learning Path Name"}
              </TableCell>
              <TableCell className="batchListHeaderCol" align="center">
                CreatedBy
              </TableCell>
              {Props.from == "batch" ? (
                <>
                  <TableCell className="batchListHeaderCol">
                    Start Date
                  </TableCell>
                  <TableCell className="batchListHeaderCol">Type</TableCell>
                  <TableCell className="batchListHeaderCol">
                    Order Mandatory
                  </TableCell>
                </>
              ) : null}
            </TableRow>
          </TableHead>
          {/* <div > */}
          <TableBody className="scrollableTableListView">
            {Props.from == "batch"
              ? Props.data
                  .filter((bat) => bat.archived == Props.showArchived)
                  .map((batch) => (
                    <TableRow
                      className="batchListBodyRow"
                      key={batch.id}
                      onClick={() =>
                        Props.handleBatchDetails(batch.id, batch.startdate)
                      }
                      // style={{ cursor: "pointer" }}
                    >
                      <TableCell  className="batchListBodyRowName">
                        {batch.name}
                      </TableCell>

                      <TableCell
                        className="batchListBodyRowValue"
                        align="center"
                      >
                        {batch.createdby != "null" ? batch.createdby : "-"}
                      </TableCell>
                      <TableCell className="batchListBodyRowValue">
                        {(new Date(batch.startdate).getDate() > 9
                          ? new Date(batch.startdate).getDate()
                          : `0${new Date(batch.startdate).getDate()}`) +
                          "/" +
                          (new Date(batch.startdate).getMonth() + 1 > 9
                            ? new Date(batch.startdate).getMonth() + 1
                            : `0${new Date(batch.startdate).getMonth() + 1}`) +
                          "/" +
                          new Date(batch.startdate).getFullYear()}
                      </TableCell>
                      <TableCell className="batchListBodyRowValue">
                        {batch.type == constants.BATCH_TYPE.TESTING
                          ? "Evaluation"
                          : "Learning"}
                      </TableCell>
                      <TableCell className="batchListBodyRowValue">
                        {batch.useorder == 0 ? "No" : "Yes"}
                      </TableCell>
                    </TableRow>
                  ))
              : Props.data
                  .filter((lp) => lp.archived == Props.showArchivedLps)
                  .map((lp) => (
                    <TableRow
                    className="batchListBodyRow"
                      onClick={() => Props.handleSpecificLp(lp.id, lp.name)}
                      key={lp.id}
                      style={{ cursor: "pointer" }}
                    >
                      <TableCell className= {classes.bodyColCell}>
                        {lp.name}
                      </TableCell>
                      <TableCell
                        className="batchListBodyRowValue"
                        align="center"
                      >
                        {lp.createdby != "null" ? lp.createdby : "-"}
                      </TableCell>
                    </TableRow>
                  ))}
          </TableBody>
          {/* </div> */}
        </Table>
      {/* </TableContainer> */}
    </div>
  );
}

export default BatchListView;
