import { useContext } from "react";
import constants from "../../../constants";
import { getDataFromStorage } from "../../../util";
import useAxiosPrivate from "../../useAxiosPrivate";
import AlertContext from "../../../context/AlertContext";
import useCommonFunctionHooks from "../../useCommonFunctionHooks";
import { MockTestPageContext } from "../../../context/MockTestPageContext";

export default function MockTestLibraryHooks() {
  const Alert = useContext(AlertContext);
  const MockTestPageCxt = useContext(MockTestPageContext);

  const axios = useAxiosPrivate();

  const { getCatchBlockDetails, getHeaderDetailsForAxios } =
    useCommonFunctionHooks();

  const handleSearch = () => {
    MockTestPageCxt.setSearchMockTest(
      MockTestPageCxt.mockTest.filter((test) =>
        test.name
          .toLowerCase()
          .includes(MockTestPageCxt.searchText.toLowerCase())
      )
    );
  };

  const getTime = (seconds) => {
    const min = Math.floor(seconds / 60) % 60;
    const hrs = Math.floor(seconds / (60 * 60));

    if (hrs !== 0) {
      return " " + hrs + " hrs " + (min !== 0 ? min + " mins" : "");
    } else {
      return " " + (min !== 0 ? min + " mins" : "0 mins");
    }
  };

  const getTestType = (type) => {
    if (type.hasOwnProperty("toc")) {
      return "Practice";
    } else {
      if ((type.libtype || type.testtype) === constants.libTypeIds.MCQ) {
        return "MCQ";
      } else if (
        (type.autoeval || type.autoeval === 1) &&
        (type.libtype || type.testtype) === constants.libTypeIds.DESCRIPTIVE
      ) {
        return "Coding Snippet";
      } else if (
        (type.libtype || type.testtype) ===
        constants.libTypeIds.CODING_CHALLENGE
      ) {
        return "Coding Challenge";
      } else if (
        type.videoqn === 1 &&
        (type.libtype || type.testtype) === constants.libTypeIds.DESCRIPTIVE
      ) {
        return "Video Assessment";
      } else {
        return "Descriptive";
      }
    }
  };

  const handleTest = (test) => {
    if (test.hasOwnProperty("toc")) {
      Alert.setLpId(test.id);
      localStorage.setItem("mockLp", true);
      sessionStorage.setItem("lpId", test.id);
      Alert.navigate("home");
    } else {
      let testData = {
        mockuse: true,
        testid: test.id,
        testname: test.name,
        testmode: test.mode,
        videoqn: test.videoqn,
        testtype: test.libtype,
        viewtest: test.viewtest,
        status: constants.PROGRESS_STATUS.UNLOCKED,
        proctored: constants.PROCTORING_MODE.TAB_CAM,
        instruction: test.instruction !== "" ? test.instruction : null,
      };

      Alert.setStatus(testData.status);

      if (test.libtype === constants.libTypeIds.CODING_CHALLENGE) {
        testData.lmpid =
          test.status === constants.PROGRESS_STATUS.EVALUATED
            ? null
            : test.lmpid;
        testData.lmtid =
          test.status === constants.PROGRESS_STATUS.EVALUATED
            ? null
            : test.lmtid;

        handleCodingInfo(testData);
      } else {
        testData.autoeval = test.autoeval === 1;
        testData.configdata = {
          marks: test.marks,
          duration: test.testduration,
          questionscount: test.questionscount,
        };

        Alert.setTestData(testData);
        Alert.setShowInstruction(true);
        Alert.navigate("/instruction");
      }
    }
  };

  const handleCodingInfo = (test) => {
    Alert.setIsLoaded(true);

    axios
      .post(
        "node/learner/course/test/config",
        JSON.stringify({
          mockuse: true,
          lpid: Alert.lpId,
          lmtid: test.lmtid,
          lmpid: test.lmpid,
          testid: test.testid,
          learnerid: getDataFromStorage("learnerid"),
        }),
        getHeaderDetailsForAxios()
      )
      .then((res) => {
        test.data = res.data.data;
        Alert.setShowInstruction(false);
        Alert.setTestData(test);
        Alert.navigate("/instruction");
      })
      .catch((error) => {
        getCatchBlockDetails(error);
      })
      .finally(() => Alert.setIsLoaded(false));
  };

  return {
    getTime,
    handleTest,
    getTestType,
    handleSearch,
    handleCodingInfo,
  };
}
