import React from "react";
import Tooltip from "@mui/material/Tooltip";

export default function Overlay(Props) {
  return (
    <Tooltip title={Props.title}>
      <div className={Props.className}>{Props.component}</div>
    </Tooltip>
  );
}
