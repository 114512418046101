import React from "react";

export default function ErrorNoticeSvgIcon() {
  return (
    <svg
      width="56"
      height="57"
      fill="none"
      viewBox="0 0 56 57"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="4" y="4.5" width="48" height="48" rx="24" fill="#FEE4E2" />
      <rect
        x="4"
        y="4.5"
        rx="24"
        width="48"
        height="48"
        stroke="#FEF3F2"
        stroke-width="8"
      />
      <path
        stroke="#D92D20"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M28 24.5V28.5M28 32.5H28.01M38 28.5C38 34.0228 33.5228 38.5 28 38.5C22.4772 38.5 18 34.0228 18 28.5C18 22.9772 22.4772 18.5 28 18.5C33.5228 18.5 38 22.9772 38 28.5Z"
      />
    </svg>
  );
}
