import React, { useState } from "react";
import Button from "../../../Button";
const  Pagination = (Props) => {
//   const [currentPage, setCurrentPage] = useState(1);

  const handlePrevious = () => {
    // setCurrentPage((prev) => Math.max(prev - 1, 1));
    Props.handlePrevious();
  };

  const handleNext = () => {
    // setCurrentPage((prev) => Math.min(prev + 1, totalPages));
    Props.handleNext();
  };

  const handleNumberChnage = (i)=>{

    if(Props.from =="leaderboard"){

      let newmin = (i - 1) * 10;
      let newmax = i * 10;
  
      Props.setMinIndex(newmin);
      Props.setMaxIndex(newmax);

    }

    console.log("i?",i);

    Props.setCurrentPage(i);

  }



  const renderPageNumbers = () => {
    let pages = [];
    for (let i = 1; i <= Props.totalPages; i++) {
      if (
        i <= 3 ||
        i > Props.totalPages - 3 ||
        (i >= Props.currentPage - 1 && i <= Props.currentPage + 1)
      ) {
        pages.push(
          <button
            key={i}
            onClick={()=>handleNumberChnage(i)}
            className={`page-number ${Props.currentPage === i ? "active" : ""}`}
          >
            {i}
          </button>
        );
      } else if (i === 4 || i === Props.totalPages - 3) {
        pages.push(
          <span key={i} className="ellipsis">
            ...
          </span>
        );
      }
    }
    return pages;
  };

  return (
    <div className="paginationCont">
      {/* <button 
        onClick={handlePrevious}
        disabled={currentPage === 1}
        className="prev-button"
      >
        Previous
      </button> */}
      <div className="prev-button">
        <Button
          disable={Props.currentPage === 1}
          icon={{
            type: "leading",
            icon: (
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15.8334 9.99996H4.16675M4.16675 9.99996L10.0001 15.8333M4.16675 9.99996L10.0001 4.16663"
                  stroke="#344054"
                  stroke-width="1.67"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            ),
          }}
          size={"sm"}
          onClick={handlePrevious}
          hierarchy={{
            buttonText: "Previous",
            type: "secondaryGrey",
          }}
        />
      </div>
      <div className="page-numbers">{renderPageNumbers()}</div>
      {/* <button 
        onClick={handleNext}
        disabled={currentPage === totalPages}
        className="next-button"
      >
        Next
      </button> */}
      <div className="next-button">
        <Button
          icon={{
            type: "trailing",
            icon: (
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4.16675 9.99996H15.8334M15.8334 9.99996L10.0001 4.16663M15.8334 9.99996L10.0001 15.8333"
                  stroke="#344054"
                  stroke-width="1.67"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            ),
          }}
          disable={Props.currentPage === Props.totalPages}
          size={"sm"}
          onClick={handleNext}
          hierarchy={{
            buttonText: "Next  ",
            type: "secondaryGrey",
          }}
        />
      </div>
    </div>
  );
};

export default Pagination;
