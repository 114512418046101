import React, { useContext } from 'react'
import CommonBtn from './CommonBtn';
import PopupCloseIcon from './PopupCloseIcon';
import AlertContext from '../../context/AlertContext';

export default function Popup(props) {
    const {isCommonPopup,setIsCommonPopup,
        isWobtnPopup,setIsWoBtnPopup,
        isCommonPopupCloseIcon,setIsCommonPopupCloseIcon,
        commonPopupTitle,setCommonPopupTitle,
        commonPopupTitleDesc,setCommonPopupTitleDesc,
        iscommonPopupBodyComponent,setIsCommonPopupBodyComponent,
        commonPopupBodyComponent,setCommonPopupBodyComponent,
        commonPopupBodyDesc,setCommonPopupBodyDesc,
        iscommonPopupSingleBtn,setIsCommonPopupSingleBtn,
        commonPopupBtnSize,setCommonPopupBtnSize,
        commonPopupSingleBtnTxt,setCommonPopupSingleBtnTxt,
        commonPopupSingleBtnFunc,setCommonPopupSingleBtnFunc,
        commonPopupPrimaryBtnTxt,setCommonPopupPrimaryBtnTxt,
        commonPopupSecBtnTxt,setCommonPopupSecBtnTxt,
        commonPopupPrimaryBtnFunc,setCommonPopupPrimaryBtnFunc,
        commonPopupSecBtnFunc,setCommonPopupSecBtnFunc } = useContext(AlertContext);

    
    let isPopupOpen = `popup-open ${/* props.isPopup */ isCommonPopup ?'show':'hide'}`;
  return (
    <div className={isPopupOpen}>
            <div className={"popup-content"}>
                <div className={"popup-title"}>
                    <div className={"popup-head-close-div"}>
                        <div>
                            <div className={"title"}>{commonPopupTitle}</div>
                            <div className={"desc"}>{commonPopupTitleDesc}</div>
                        </div>
                        { isCommonPopupCloseIcon && 
                        <div>
                            <PopupCloseIcon handleClosePopup={props.secondaryOnclick}/>
                        </div> 
                        }
                    </div>
                </div>
                {iscommonPopupBodyComponent ? (
                    <div className={"popup-body"}>
                        {/* {props.popupBodyComponent} */}
                        {commonPopupBodyComponent && commonPopupBodyComponent}
                    </div>
                    ) :
                    <div className={"popup-body-desc"}>
                        {commonPopupBodyDesc}
                    </div>
                }

                {!isWobtnPopup && 
                <div className="popup-footer">
                    <CommonBtn 
                        btnSize = {props.btnSize} 
                        primBtnTxt={props.primBtnTxt}
                        secBtnTxt={props.secBtnTxt}
                        primaryOnclick={props.primaryOnclick}
                        secondaryOnclick={props.secondaryOnclick}
                    />
                </div>
                }
            </div>
        </div>
  )
}
