import React from "react";

export default function CreateTestHooks() {
  const customStylesForTests = {
    control: (base, state) => ({
      ...base,
      height: "38px",
      cursor: "pointer",
      paddingLeft: "7px",
      backgroundColor: "#ffffff",
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        margin: "0px",
        height: "30px",
        fontSize: "12px",
        textAlign: "left",
        fontWeight: "600",
        lineHeight: "15px",
        fontFamily: "Inter",
        color: isSelected ? "#000000" : undefined,
        backgroundColor: isFocused ? "#e9ecef" : undefined,
      };
    },
    singleValue: (styles, { data }) => ({
      ...styles,
      fontSize: "12px",
      fontWeight: "600",
      lineHeight: "20px",
      fontFamily: "Inter",
      fontStyle: "normal",
      borderRadius: "20px",
    }),
    placeholder(base, state) {
      return {
        ...base,
        fontSize: "14px",
        color: "#BDC1C6",
        fontWeight: "400",
        lineHeight: "20px",
        fontFamily: "Inter",
        fontStyle: "normal",
      };
    },
    menuList: (base) => ({
      ...base,
      height: "135px",
    }),
  };

  return { customStylesForTests };
}
