import React, { useContext } from "react";

import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import AlertContext from "../../../../context/AlertContext";

export const GlobalFilter = ({ filter, setFilter }) => {
  const {setSearchText}=useContext(AlertContext)

  return (
    <div className="report-global-filter">
      <Paper
        component="form"
        sx={{
          mt: 0,
          display: "flex",
          alignItems: "center",
          width: 480,
          bgcolor: "#F2F4F7",
          boxShadow: 0,
        }}
      >
        <InputBase
          sx={{ bgcolor: "#F2F4F7", width: 450, pl: 2, pr: 2 }}
          placeholder="Search"
          inputProps={{ "aria-label": "Global Search" }}
          value={filter || ""}
          onKeyDown={(e)=>e.key==='Enter'?e.preventDefault():null}
          onChange={(e) => {
            setSearchText(e.target.value);
            setFilter(e.target.value)
          }}
        />
        <IconButton
          type="button"
          sx={{ p: "10px", bgcolor: "#F2F4F7" }}
          aria-label="search"
        >
          {/* <SearchIcon /> */}

          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_2109_19747)">
              <path
                d="M3.80387 3.80358C3.74205 3.87577 3.70975 3.96863 3.71342 4.0636C3.71708 4.15858 3.75646 4.24867 3.82366 4.31587C3.89087 4.38308 3.98096 4.42245 4.07593 4.42612C4.1709 4.42978 4.26376 4.39748 4.33595 4.33566C5.07375 3.59988 6.07321 3.18669 7.11519 3.18669C8.15718 3.18669 9.15664 3.59988 9.89444 4.33566C9.92939 4.37132 9.97107 4.39968 10.0171 4.41911C10.063 4.43855 10.1124 4.44866 10.1624 4.44887C10.237 4.44855 10.3099 4.42612 10.3717 4.3844C10.4336 4.34267 10.4817 4.28354 10.51 4.21447C10.5383 4.1454 10.5454 4.0695 10.5306 3.99636C10.5157 3.92323 10.4795 3.85614 10.4265 3.80358C9.54779 2.92632 8.35687 2.43359 7.11519 2.43359C5.87352 2.43359 4.68259 2.92632 3.80387 3.80358Z"
                fill="#373739"
                fillOpacity="0.5"
              />
              <path
                d="M13.6679 11.2257C13.5972 11.1554 13.5016 11.116 13.4019 11.116C13.3022 11.116 13.2065 11.1554 13.1358 11.2257L12.4528 11.9163L11.7396 11.1993C12.8154 9.99972 13.3793 8.42686 13.3106 6.81699C13.2419 5.20712 12.5462 3.688 11.3721 2.5844C10.198 1.4808 8.63876 0.880307 7.02773 0.911299C5.41669 0.942291 3.8817 1.60231 2.75093 2.75024C1.63092 3.887 0.994918 5.41351 0.976486 7.00922C0.958055 8.60494 1.55863 10.1457 2.65209 11.3081C3.74555 12.4704 5.24684 13.1638 6.84071 13.2427C8.43458 13.3217 9.99704 12.78 11.2 11.7314L11.917 12.4484L11.2264 13.1352C11.1561 13.2059 11.1167 13.3015 11.1167 13.4012C11.1167 13.5009 11.1561 13.5965 11.2264 13.6672L16.5094 18.9502C16.5801 19.0205 16.6758 19.06 16.7755 19.06C16.8752 19.06 16.9708 19.0205 17.0415 18.9502L18.9509 17.0408C19.0212 16.9701 19.0607 16.8745 19.0607 16.7748C19.0607 16.6751 19.0212 16.5794 18.9509 16.5087L13.6679 11.2257ZM3.28678 10.9427C2.30437 9.92066 1.76193 8.55413 1.77594 7.13656C1.78995 5.71899 2.3593 4.36345 3.36172 3.36103C4.36414 2.35861 5.71968 1.78926 7.13725 1.77525C8.55482 1.76124 9.92135 2.30368 10.9434 3.28609C11.9258 4.30813 12.4682 5.67466 12.4542 7.09223C12.4402 8.5098 11.8709 9.86534 10.8685 10.8678C9.86603 11.8702 8.51049 12.4395 7.09292 12.4535C5.67535 12.4676 4.30882 11.9251 3.28678 10.9427ZM16.7698 18.1465L12.0302 13.4031L13.4038 12.0295L18.1472 16.7691L16.7698 18.1465Z"
                fill="#373739"
                fillOpacity="0.5"
              />
            </g>
            <defs>
              <clipPath id="clip0_2109_19747">
                <rect width="20" height="20" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </IconButton>
      </Paper>

      {/* <input id="global-filter-txtbox" value={filter||''} onChange={(e)=>setFilter(e.target.value)}/> */}
    </div>
  );
};
