import { nanoid } from "nanoid";
import { useState } from "react";

function useAddComments() {
  const [comments, setComments] = useState("");
  const [slidecomments, setSlidecomments] = useState(false);
  const [commentAttachment, setCommentAttachment] = useState([]);

  const changeComments = (event) => {
    setComments(event.target.value);
  };

  const handleCommentAttachment = (event) => {
    let commentedDate = String(new Date());
    let attachments = [...commentAttachment];
    let files = Array.from(event.target.files);

    for (let i = 0; i < files.length; i++) {
      let attachment = { id: nanoid(), file: files[i], date: commentedDate };
      attachments.push(attachment);
    }

    setCommentAttachment(attachments);
    event.target.value = "";
  };

  const handleDeleteCommentAttachment = (attachmentId) => {
    setCommentAttachment((prevAttachment) =>
      prevAttachment.filter((attachment) => attachment.id !== attachmentId)
    );
  };

  const toggleDrawer = (anchor, open) => (event) => {
    console.log("check");

    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setSlidecomments(!slidecomments);
  };

  return {
    comments,
    setComments,
    toggleDrawer,
    slidecomments,
    changeComments,
    setSlidecomments,
    commentAttachment,
    setCommentAttachment,
    handleCommentAttachment,
    handleDeleteCommentAttachment,
  };
}

export default useAddComments;
