import React from 'react'

export default function PartialProctorIcon() {
  return (
    <div>
        <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="44" height="44" rx="12" fill="#FBEFED"/>
            <g clip-path="url(#clip0_114_9205)">
            <path d="M20.6922 10.075C19.9 10.2859 19.2812 10.9188 19.0703 11.7203C19.0094 11.95 19 12.6484 19 16.4781C19 19.5625 19.0141 21.0438 19.0516 21.2125C19.2109 21.9906 19.8203 22.6563 20.5891 22.8906C20.875 22.9797 21.0531 22.9844 26.5 22.9844C31.9469 22.9844 32.125 22.9797 32.4109 22.8906C33.1703 22.6563 33.7187 22.0656 33.9297 21.2688C34.0328 20.8609 34.0375 12.1234 33.9297 11.7203C33.7187 10.9094 33.0953 10.2813 32.2891 10.0703C31.8859 9.96719 21.0812 9.96719 20.6922 10.075ZM32.1531 11.6219C32.4578 11.8469 32.4766 11.9031 32.4906 12.7469L32.5047 13.5156H26.5047H20.5V12.8172C20.5 12.0906 20.5281 11.9266 20.7016 11.7391C20.9312 11.4906 20.6781 11.5 26.5562 11.5141C31.9984 11.5234 32.0266 11.5234 32.1531 11.6219ZM32.4906 18.0109C32.4766 20.9688 32.4766 21.0109 32.3781 21.1422C32.3266 21.2125 32.2094 21.3203 32.125 21.3766L31.9703 21.4844H26.5H21.0297L20.875 21.3766C20.7906 21.3203 20.6734 21.2125 20.6219 21.1422C20.5234 21.0109 20.5234 20.9688 20.5094 18.0109L20.5 15.0156H26.5H32.5L32.4906 18.0109Z" fill="#667085"/>
            <path d="M15.578 12.5359C14.8046 12.6484 14.0546 13.164 13.6327 13.8671C13.4265 14.214 13.3046 14.603 13.2577 15.053L13.2249 15.3905L13.0608 15.2452C12.8593 15.0718 12.7233 15.0155 12.4796 15.0155C12.0108 15.0155 11.6733 15.4562 11.7812 15.9249C11.814 16.0796 11.9687 16.2577 12.728 17.0171C14.0358 18.3249 13.9655 18.3249 15.2405 17.064C15.714 16.5952 16.1312 16.1546 16.1687 16.0843C16.3187 15.7984 16.2062 15.339 15.9437 15.1468C15.7937 15.039 15.4702 14.9874 15.2733 15.0343C15.2077 15.053 15.053 15.1562 14.9358 15.264L14.7202 15.4609L14.753 15.1796C14.7952 14.8187 14.9265 14.5515 15.1655 14.3359C15.4233 14.0968 15.6108 14.0312 16.1171 13.989C16.4452 13.9609 16.5858 13.928 16.689 13.853C16.9843 13.6421 17.078 13.1546 16.8765 12.8546C16.6608 12.5265 16.2765 12.4327 15.578 12.5359Z" fill="#667085"/>
            <path d="M11.5844 21.1045C10.8156 21.3529 10.2109 22.0139 10.0516 22.7873C10.0141 22.9561 10 24.4373 10 27.5217C10 31.4029 10.0094 32.0498 10.0703 32.2889C10.2812 33.0951 10.9094 33.7186 11.7203 33.9295C12.1234 34.0326 22.8766 34.0326 23.2797 33.9295C24.1141 33.7139 24.7797 33.0248 24.9484 32.2092C24.9859 32.0404 25 30.9295 24.9906 28.717C24.9766 25.5248 24.9766 25.4732 24.8781 25.3467C24.6953 25.0982 24.5453 25.0232 24.25 25.0232C23.9547 25.0232 23.8047 25.0982 23.6219 25.3467C23.5234 25.4732 23.5234 25.5342 23.5 28.7404C23.4812 31.2529 23.4625 32.0311 23.4156 32.1107C23.3828 32.167 23.2797 32.2748 23.1859 32.3451L23.0172 32.4764H17.5H11.9828L11.8094 32.3404C11.4906 32.0967 11.5 32.2139 11.5 28.9373V25.9842H14.9875H18.4703L18.625 25.8764C18.85 25.7264 18.9625 25.5529 18.9859 25.3186C19.0187 25.0467 18.8781 24.7654 18.6391 24.6201L18.4609 24.5076L14.9781 24.4936L11.4953 24.4842L11.5094 23.7342C11.5234 22.9092 11.5469 22.8436 11.875 22.6186L12.0297 22.5154H14.1906C16.3422 22.5154 16.3562 22.5154 16.5625 22.4076C16.8719 22.2529 16.9984 22.042 16.975 21.7139C16.9516 21.4092 16.825 21.2029 16.5906 21.0904C16.45 21.0248 16.1594 21.0154 14.1391 21.0201C12.0578 21.0201 11.8281 21.0295 11.5844 21.1045Z" fill="#667085"/>
            <path d="M29.7624 26.0549C29.6264 26.1018 29.3452 26.3502 28.7358 26.9549C28.2764 27.4143 27.8686 27.8455 27.8311 27.9158C27.6811 28.2018 27.7936 28.6611 28.0561 28.8533C28.2061 28.9611 28.5296 29.0127 28.7264 28.9658C28.7921 28.9471 28.9468 28.8439 29.0639 28.7361L29.2796 28.5393L29.2468 28.8111C29.2093 29.1627 29.0686 29.458 28.8389 29.6596C28.5858 29.8893 28.3702 29.9689 27.878 30.0111C27.3952 30.0533 27.2171 30.1424 27.0905 30.4096C26.9077 30.7986 27.0483 31.2111 27.4374 31.4127C27.6296 31.5111 27.6905 31.5205 28.0796 31.4971C28.8436 31.4455 29.4389 31.183 29.9405 30.6768C30.4139 30.1939 30.6718 29.6361 30.7421 28.9471L30.7749 28.6096L30.9389 28.7549C31.1405 28.9283 31.2764 28.9846 31.5202 28.9846C31.9889 28.9846 32.3264 28.5439 32.2186 28.0752C32.1858 27.9205 32.0311 27.7424 31.2952 27.0064C30.8124 26.5189 30.3811 26.1018 30.3436 26.083C30.1983 26.0033 29.9405 25.9893 29.7624 26.0549Z" fill="#667085"/>
            </g>
            <defs>
            <clipPath id="clip0_114_9205">
            <rect width="24" height="24" fill="white" transform="translate(10 10)"/>
            </clipPath>
            </defs>
        </svg>
    </div>
  )
}
