import React, { useContext, useEffect } from "react";
import DarkThemeSvgIcon from "./DarkThemeSvgIcon";
import LightThemeSvgIcon from "./LightThemeSvgIcon";
import AlertContext from "../context/AlertContext";

export default function ThemeSvgIcon() {
  const { theme, setTheme, setEditorTheme } = useContext(AlertContext);

  const handleEditorTheme = () => {
    setTheme(!theme);
  };

  useEffect(() => {
    setEditorTheme(!theme ? "tomorrow" : "monokai");
  }, [theme]);

  return (
    <div className="themeSvgIcon" onClick={handleEditorTheme}>
      {theme ? <LightThemeSvgIcon /> : <DarkThemeSvgIcon />}
    </div>
  );
}
