import React from "react";

export default function DarkThemeSvgIcon() {
  return (
    <svg
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        stroke="#667085"
        stroke-width="1.6"
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M5.43751 1.33268C5.04153 2.20828 4.82109 3.18025 4.82109 4.20371C4.82109 8.05582 7.94385 11.1786 11.796 11.1786C12.8194 11.1786 13.7914 10.9581 14.667 10.5622C13.5726 12.9822 11.1372 14.666 8.30853 14.666C4.45641 14.666 1.33366 11.5433 1.33366 7.69114C1.33366 4.86248 3.0175 2.4271 5.43751 1.33268Z"
      />
    </svg>
  );
}
