import "./LandingPage.scss";
import Profile from "./Profile";
import { React, useContext } from "react";
import { useState, useEffect } from "react";
import constants from "../../../../constants";
import Batch from "../../../CommonComponents/Batch";
import AlertContext from "../../../../context/AlertContext";
import useAxiosPrivate from "../../../../Hooks/useAxiosPrivate";
import { getDataFromStorage, getMinutesGap } from "../../../../util";
import Loading from "../../../Notification-Loading/Loading//Loading";
import useCommonFunctionHooks from "../../../../Hooks/useCommonFunctionHooks";

export default function LandingPage(Props) {
  const Alert = useContext(AlertContext);

  const axios = useAxiosPrivate();
  const useCommonFunctionHks = useCommonFunctionHooks();

  const [userbatch, setuserbatch] = useState([]);

  const navigatehome = (batchid) => {
    Alert.setBatchId(batchid);
    Alert.setNavhome(true);
  };

  const handleSetLocalStorage = (batch) => {
    localStorage.setItem("logo", batch.logo);
    localStorage.setItem("batchid", batch.id);
    localStorage.setItem("batchType", batch.type);
    localStorage.setItem("batchname", batch.name);
    localStorage.setItem("favicon", batch.favicon);
  };

  useEffect(() => {
    if (Alert.navhome && !Alert.isFirstLoggedIn) {
      Alert.setIsLoaded(true);
      axios
        .post(
          "node/learner/dashboard",
          JSON.stringify({
            batchId: Alert.batchId,
            learnerId: getDataFromStorage("learnerid"),
          }),
          useCommonFunctionHks.getHeaderDetailsForAxios()
        )
        .then((res) => {
          if (res.data.resultCode === constants.RESULT_STATUS.TECHNICAL_ERROR) {
            Alert.setShowNotify({
              show: true,
              title: "Info",
              msg: res.data.msg,
            });
          } else {
            if (res.data.resultCode === constants.RESULT_STATUS.NO_DATA) {
              Alert.setShowNotify({
                show: true,
                title: "Info",
                msg: "Learningpath is not published",
              });
            } else {
              Alert.setDashBoardDetails(res.data.data);

              const givenTime = res.data.data.attendanceExpiry;
              const minutesGap = getMinutesGap(givenTime);

              let mss = minutesGap * 60 * 1000;

              if (Alert.showBlink && !res.data.data.markAttendance) {
                Alert.setShowBlink(res.data.data.markAttendance);
              }

              if (
                !Alert.showBlink &&
                res.data.data.markAttendance &&
                minutesGap >= 0
              ) {
                Alert.setShowBlink(res.data.data.markAttendance);
                setTimeout(() => {
                  document
                    .getElementById("svg-element")
                    .classList.remove("blinking");
                  Alert.setShowBlink(false);
                }, [mss]);
              }

              localStorage.setItem("batchid", Alert.batchId);

              let isTestBatch = res.data.data.batchDetails.some(
                (data) =>
                  data.id == Alert.batchId &&
                  data.type == constants.BATCH_TYPE.TESTING
              );

              let issingleLP = res.data.data.lpData.length > 1 ? false : true;

              localStorage.setItem("issinglelp", issingleLP);
              localStorage.setItem(
                "issingleBatch",
                res.data.data.batchDetails.length > 1 ? false : true
              );

              if (isTestBatch) {
                localStorage.setItem("isTestBatch", true);

                if (res.data.data.lpData.length > 1) {
                  Alert.navigate("/landingpage");
                } else {
                  sessionStorage.setItem("lpId", res.data.data.lpData[0].lpId);
                  Alert.setLpId(res.data.data.lpData[0].lpId);
                  Alert.navigate("/home");
                }
              } else {
                localStorage.setItem("isTestBatch", false);
                Alert.navigate("/landingpage");
              }
            }
          }
        })
        .catch((error) => {
          useCommonFunctionHks.getCatchBlockDetails(error);
        })
        .finally(() => Alert.setIsLoaded(false));
    }

    if (!Alert.navhome && !Alert.isFirstLoggedIn) {
      Alert.setIsLoaded(true);

      const id = getDataFromStorage("learnerid");

      axios
        .get(
          "node/learner/batch/" + id,
          useCommonFunctionHks.getHeaderDetailsForAxios()
        )
        .then((res) => {
          if (res.data.resultCode !== constants.RESULT_STATUS.SUCCESS) {
            Alert.setShowNotify({
              show: true,
              title: "Error",
              msg: res.data.msg,
            });
          } else {
            if (res.data.data.length === 1) {
              for (let i = 0; i < res.data.data.length; i++) {
                localStorage.setItem("issingleBatch", true);
                localStorage.setItem("batchid", res.data.data[i]?.id);
                localStorage.setItem("batchType", res.data.data[i].type);
                localStorage.setItem("batchname", res.data.data[i]?.name);

                if (res.data.data[i].type == constants.BATCH_TYPE.TESTING) {
                  let courseNav =
                    typeof res.data.data[i].courses == "string"
                      ? JSON.parse(res.data.data[i].courses)
                      : res.data.data[i].courses;

                  localStorage.setItem(
                    "issinglelp",
                    courseNav.length > 1 ? false : true
                  );

                  localStorage.setItem("isTestBatch", true);

                  if (courseNav.length > 1) {
                    navigatehome(res.data.data[i].id);
                    handleSetLocalStorage(res.data.data[i]);
                  } else {
                    sessionStorage.setItem("lpId", courseNav[0].lpId);
                    Alert.setLpId(courseNav[0].lpId);
                    Alert.navigate("/home");
                    handleSetLocalStorage(res.data.data[i]);
                  }
                } else {
                  localStorage.setItem("isTestBatch", false);
                  navigatehome(res.data.data[i].id);
                  handleSetLocalStorage(res.data.data[i]);
                }
              }
            } else if (res.data.data.length === 0) {
              Alert.setShowNotify({
                show: true,
                title: "Warning",
                primaryButtonFunction: useCommonFunctionHks.handleUnAuthAlert,
                msg: "You don't belong to any batch. Please contact Gradious Team.",
              });
            } else {
              if (localStorage.getItem("batchid") != null) {
                let batch = res.data.data.filter(
                  (data) => data.id == localStorage.getItem("batchid")
                );

                if (batch && batch.length === 1) {
                  handleSetLocalStorage(batch[0]);
                  Alert.navigate(
                    batch[0].type === constants.BATCH_TYPE.TESTING
                      ? "/home"
                      : "/landingpage"
                  );
                } else {
                  setuserbatch(res.data);
                }
              } else {
                setuserbatch(res.data);
              }
            }
          }
        })
        .catch((error) => {
          useCommonFunctionHks.getCatchBlockDetails(error);
        })
        .finally(() => Alert.setIsLoaded(false));
    }
  }, [Alert.navhome, Alert.isFirstLoggedIn]);

  return (
    <div>
      {Alert.isLoaded && <Loading />}
      {!Alert.navhome &&
        !Alert.isFirstLoggedIn &&
        userbatch.resultCode === constants.RESULT_STATUS.SUCCESS && (
          <Batch
            batch={userbatch}
            navigatehome={navigatehome}
            handleSetLocalStorage={handleSetLocalStorage}
          />
        )}

      {Alert.isFirstLoggedIn && (
        <Profile
          setMsg={Alert.setMsg}
          type="profilepic"
          setShow={Alert.setShow}
          seconds={Alert.seconds}
          isTakeTest={Alert.isTakeTest}
          setIsUnAuth={Alert.setIsUnAuth}
          setCamAvailable={Alert.setCamAvailable}
          setIsFirstLoggedIn={Alert.setIsFirstLoggedIn}
        />
      )}
    </div>
  );
}
