import React from 'react'

export default function BookmarkedSvg() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M19 21L12 16L5 21V5C5 4.46957 5.21071 3.96086 5.58579 3.58579C5.96086 3.21071 6.46957 3 7 3H17C17.5304 3 18.0391 3.21071 18.4142 3.58579C18.7893 3.96086 19 4.46957 19 5V21Z" fill="url(#paint0_linear_2373_13426)"/>
        <defs>
        <linearGradient id="paint0_linear_2373_13426" x1="17.5" y1="3" x2="6.5" y2="17" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FB6514"/>
        <stop offset="1" stop-color="#96670C"/>
        </linearGradient>
        </defs>
    </svg>
  )
}
