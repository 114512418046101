import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Card, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import Badge from '@mui/material/Badge';
import Avatar from '@mui/material/Avatar';
import StarSvg from '../../Svg/StarSvg';
import { getDataFromStorage } from '../../util';
import AngleRightSvg from '../../Svg/AngleRightSvg';
import ArrowSvg from '../../Svg/ArrowSvg'
import RankFirstSvg from '../../Svg/RankFirstSvg';
import RankSecondSvg from '../../Svg/RankSecondSvg';
import RankThirdSvg from '../../Svg/RankThirdSvg';
import CircularProgress from '@mui/material/CircularProgress';


export default function Leaderboard(props) {
  /* sorting by rank */
  let sortedData = props.leaderBoardData?.sort((a, b) => a.ranking - b.ranking);
  let currentUserId = getDataFromStorage("learnerid");
  /* get top 3 students */
  let top3Students = sortedData?.slice(0, 3);
  /* get 10 students for table */
  let remainingStudents = sortedData?.slice(3, 13);
  /* check current user is in top3 or remaing 10 */
  const isCurrentUserInTop3 = top3Students.some(student => student.id == currentUserId);
  const isCurrentUserInTable = remainingStudents.some(student => student.id == currentUserId);

  if (isCurrentUserInTop3) {
    remainingStudents = sortedData?.slice(3, 13);
  } else if (isCurrentUserInTable) {
    remainingStudents = sortedData?.slice(3, 13);
  } else {
    let findPosition = sortedData?.findIndex((data) => {
      return data.id == currentUserId;
    });
    let current = sortedData[findPosition];
    remainingStudents.splice(9, 1, current);
  }

  /* capitalize first letter */
  function capitalizeFirstLetter(str) {
    if(str!= undefined) return str[0].toUpperCase() + str.slice(1);
  }

  return (
    <Box className='leaderboard-box-div'>
      <Typography className='leaderboard-box-header' gutterBottom>
        Leaderboard
      </Typography>
      { (!props.isLoaded && props.loadingLeaderboard && props.leaderBoardData.length == 0) ? (
        <Box style={{ height: "50px", display: "flex", justifyContent: "center" }}><CircularProgress style={{ color: '#F55533' }}/></Box>
      ) : (!props.loadingLeaderboard && props.leaderBoardData.length == 0) ? (
        <Box className='leaderboard-empty-data-box' >No Leaderboard Data</Box>
      ) : (
        <>
          <Box className='leaderboard-paper-table'>
            <Box className='leaderboard-table-topranker-box'>
              <Card className='leaderboard-firstrank'>
                <Badge
                  className='leaderboard-firstrank-avatar-badge'
                  overlap="circular"
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                  badgeContent={
                    <RankFirstSvg />
                  }
                >
                  <Avatar className='leaderboard-firstrank-avatar' alt={capitalizeFirstLetter(top3Students[0]?.name)} src={top3Students[0]?.picture} />
                </Badge>
                <Typography className='leaderboard-firstrank-learnername'>{capitalizeFirstLetter(top3Students[0]?.name)}</Typography>
                <Typography className='leaderboard-firstrank-pointsearned'><span><StarSvg className='leaderboard-table-head-starsvg' height={"16"} width={"16"} />
                {top3Students[0]?.totalPointsEarned}{
                // top3Students[0]?.id == getDataFromStorage("learnerid") && <ArrowSvg className='currentlearner-top3card-svg' height={"10"} width={"10"} pathfill={"#FD710B"} handleFunction={props.handleLeaderboardDetail} />
                }
                </span></Typography>
              </Card>
              <Card className='leaderboard-secondrank'>
                <Badge
                  className='leaderboard-secondrank-avatar-badge'
                  overlap="circular"
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                  badgeContent={
                    top3Students[1]?.ranking == 1 ?
                      <RankFirstSvg /> :
                      top3Students[1]?.ranking == 3 ?
                      <RankThirdSvg /> :
                      <RankSecondSvg />
                  }
                >
                  <Avatar className='leaderboard-secondrank-avatar' alt={capitalizeFirstLetter(top3Students[1]?.name)} src={top3Students[1]?.picture} />
                </Badge>
                <Typography className='leaderboard-secondrank-learnername'>{capitalizeFirstLetter(top3Students[1]?.name)}</Typography>
                <Typography className='leaderboard-secondrank-pointsearned'><span><StarSvg className='leaderboard-table-head-starsvg' height={"16"} width={"16"} />{top3Students[1]?.totalPointsEarned}
                {/* {top3Students[1]?.id == getDataFromStorage("learnerid") && <ArrowSvg className='currentlearner-top3card-svg' height={"10"} width={"10"} pathfill={"#FD710B"} handleFunction={props.handleLeaderboardDetail} />} */}
                </span></Typography>
              </Card>
              <Card className='leaderboard-thirdrank'>
                <Badge
                  className='leaderboard-thirdrank-avatar-badge'
                  overlap="circular"
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                  badgeContent={
                    top3Students[2]?.ranking == 1 ?
                      <RankFirstSvg /> :
                      top3Students[2]?.ranking == 2 ?
                        <RankSecondSvg /> :
                        <RankThirdSvg />
                  }
                >
                  <Avatar className='leaderboard-thirdrank-avatar' alt={capitalizeFirstLetter(top3Students[2]?.name)} src={top3Students[2]?.picture} />
                </Badge>
                <Typography className='leaderboard-thirdrank-learnername'>{capitalizeFirstLetter(top3Students[2]?.name)}</Typography>
                <Typography className='leaderboard-thirdrank-pointsearned'><span><StarSvg className='leaderboard-table-head-starsvg' height={"16"} width={"16"} />{top3Students[2]?.totalPointsEarned}
                {/* {top3Students[2]?.id == getDataFromStorage("learnerid") && <ArrowSvg className='currentlearner-top3card-svg' height={"10"} width={"10"} pathfill={"#FD710B"} handleFunction={props.handleLeaderboardDetail} />} */}
                </span></Typography>
              </Card>
            </Box>

            <TableContainer className='leaderboard-table-container' component={Paper}>
              <Table className='leaderboard-table' stickyHeader>
                <TableHead className='leaderboard-table-head'>
                  <TableRow className='leaderboard-table-head-row'>
                    <TableCell className='leaderboard-table-head-ranktablecell'>Rank</TableCell>
                    <TableCell className='leaderboard-table-head-nametablecell'>Name</TableCell>
                    <TableCell className='leaderboard-table-head-pointstablecell'>Points<StarSvg className='leaderboard-table-head-starsvg' height={"12px"} width={"12px"} /></TableCell>
                    {/* Add more columns as needed */}
                  </TableRow>
                </TableHead>
                <TableBody className='leaderboard-table-body'>
                  {remainingStudents.length > 0 && remainingStudents?.map((member, index) => (
                    <TableRow className={member?.id == getDataFromStorage("learnerid") ? 'leaderboard-table-body-row-currentlearner' : 'leaderboard-table-body-row'} key={member?.id}>
                      <TableCell className='leaderboard-table-body-row-ranktablecell'>{member?.ranking}</TableCell>
                      <TableCell className='leaderboard-table-body-row-nametablecell'>{capitalizeFirstLetter(member?.name)}{member?.id == getDataFromStorage("learnerid") && <span className='current-learner'>{getDataFromStorage("role")=="parent" ?"" :"(You)"}</span>}</TableCell>
                      <TableCell className='leaderboard-table-body-row-pointstablecell'>
                        {member?.totalPointsEarned}
                        {/* {member?.id == getDataFromStorage("learnerid") && (getDataFromStorage("role")=="learner"&&<ArrowSvg handleFunction={props.handleLeaderboardDetail}/>)} */}
                        </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </>) }
    </Box>
  );
}
