import { nanoid } from "nanoid";
import "./McqQuestionEntry.scss";
import "quill/dist/quill.snow.css";
import { Input, Table } from "antd";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import { Modal } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Modules from "../Modules/Modules";
import "react-quill/dist/quill.snow.css";
import { mkConfig, generateCsv, download } from "export-to-csv";
import Button from "@mui/material/Button";
import constants from "../../../../constants";
import { Editor } from "@tinymce/tinymce-react";
import Complexity from "../Complexity/Complexity";
import { getPreSignedUrl } from "../../../../util";
import DataTable from "react-data-table-component";
import CreatableSelect from "react-select/creatable";
import AlertContext from "../../../../context/AlertContext";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import useAxiosPrivate from "../../../../Hooks/useAxiosPrivate";
import Notify from "../../../Notification-Loading/Notify/Notify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useAddOrUpdateLp from "../../../../Hooks/useAddOrUpdateLp";
import Loading from "../../../Notification-Loading/Loading/Loading";
import React, { useState, useEffect, useRef, useContext } from "react";
import useUploadLargeFileToS3 from "../../../../Hooks/useUploadLargeFileToS3";

import {
  faPlus,
  faTrash,
  faFileAlt,
  faArrowLeft,
  faCircleInfo,
  faArrowUpFromBracket,
} from "@fortawesome/free-solid-svg-icons";

function Mcqtest(Props) {
  const {
    msg,
    show,
    title,
    setMsg,
    lpData2,
    setShow,
    setTitle,
    isUnAuth,
    isFromLp,
    fromList,
    navigate,
    setLpData2,
    setIsUnAuth,
    handleClose,
    isFromBatch,
    setFromList,
    setIsFromLp,
    isOldLpDetail,
    handleUnAuthAlert,
    handleNavigateToLpAfterAddTest,
  } = useContext(AlertContext);

  const axios = useAxiosPrivate();

  const customStyles = {
    headCells: {
      style: {
        fontSize: "12px",
        color: "#9AA0A6",
        fontWeight: "500",
        lineHeight: "15px",
        position: "sticky",
        fontFamily: "Inter",
        fontStyle: "normal",
        background: "#FFFFFF",
        textTransform: "uppercase",
      },
    },
    cells: {
      style: {
        color: "#202124",
        fontSize: "14px",
        fontWeight: "400",
        lineHeight: "20px",
        fontStyle: "normal",
        fontFamily: "Inter",
        background: "#FFFFFF",
      },
    },
  };

  const columns = [
    {
      name: "Files",
      selector: (row) => (
        <a
          target="_blank"
          rel="noreferrer"
          className="mcquploadlibTaps"
          href={process.env.REACT_APP_NODE_API + row.link}
        >
          {row.link.substring(row.link.lastIndexOf("/") + 1, row.link.length)}
        </a>
      ),
    },
  ];

  const [topicAndComplexity, setTopicAndCompexity] = useState([]);
  const [dataTopicAndComplexity, setDataTopicAndComplexity] = useState([]);

  const [count, setCount] = useState(1);
  const [topic, setTopic] = useState(null);
  const [topics, setTopics] = useState([]);
  const [level, setLevel] = useState(null);
  const [reset, setReset] = useState(false);
  const [htmlData, setHtmlData] = useState("");
  const [handleadd, setHandleadd] = useState(false);
  const [complexities, setComplexities] = useState([]);

  const [UserInput, setuserinp] = useState(
    Props.isqueReport
      ? {
          name: Props.selectque.name,
          marks: Props.selectque.marks,
          ansexp: Props.selectque.ansexp,
          maxtime: Props.selectque.timelimit,
          description: Props.selectque.description,
        }
      : {}
  );

  const [mcqAns, setMcqAns] = useState(
    Props.isqueReport && Props.tableShowStatus === constants.libTypeIds.MCQ
      ? Props.selectque.isAnswer
      : [
          {
            id: nanoid(),
            optionText: "",
            isAnswer: false,
          },
          {
            id: nanoid(),
            optionText: "",
            isAnswer: false,
          },
        ]
  );

  const [libTypes, setLibtypes] = useState([]);
  const [linksdata, setLinksData] = useState("");
  const [selectedCheck, setSelectedCheck] = useState(null);
  const [libTypebyParent, setlibTypebyParent] = useState([]);
  const [uploadExcel, setUploadExcel] = useState("Upload via Excel");

  let [showNotification, setShowNotification] = useState(false);

  const [selectedRows, setSelectedRows] = useState([]);

  let [resultCode, setResultCode] = useState("");
  let [filesFromLib, setfilesFromLip] = useState([]);
  let [selectedFile, setSelectedFile] = useState([]);
  let [alreadySelected, setalreadySelected] = useState(null);
  let [isSelectedTopic, setisSelectedTopic] = useState(true);
  let [enableSaveButton, setenableSaveButton] = useState(false);
  let [alreadySelectedCom, setalreadySelectedCom] = useState(null);
  let [isSelectedComplexity, setisSelectedComplexity] = useState(true);

  const [showFromLibrary, setShowFromLibrary] = useState(false);

  let [loading, setloading] = useState(false);

  const mcqAnsCount = useRef(mcqAns.length);

  let [checkedState, setCheckedState] = useState(
    Props.isqueReport && Props.tableShowStatus === constants.libTypeIds.MCQ
      ? mcqAns.map((data) => (data.isAnswer === 1 ? true : false))
      : []
  );
  let [chooseAnswer, setChooseAnswer] = useState(
    Props.isqueReport && Props.tableShowStatus === constants.libTypeIds.MCQ
      ? Props.selectque.type === 8
        ? 1
        : Props.selectque.isAnswer.length
      : null
  );

  let [isMultiAnsChecked, setIsMultiAnsChecked] = useState(true);
  let [chooseMultiAnswer, setChooseMultiAnswer] = useState(false);
  let [isCheckboxChecked, setIsCheckboxChecked] = useState(false);

  const [showOptionsbtn, setShowOptionbtn] = useState(false);
  const [libTypesIdbyParent, setlibTypeIdbyParent] = useState(0);
  const [selectSaqLaqLength, setSelectSaqLaqLength] = useState(500);
  const [libTypesNamebyParent, setlibTypeNamebyParent] = useState(null);

  let [Errors, setErrors] = useState({
    url: "",
    marks: "",
    maxtime: "",
    exampleio: "",
    testcaseio: "",
    oursolution: "",
    boilerplate: "",
    language: "java",
    executioncode: "",
    difficulty: "easy",
    problemstatement: "",
  });

  const editorRef = useRef(null);

  let response;

  const [embedLink, setEmbedLink] = useState("");

  const [embedLinksList, setEmbedLinksList] = useState(
    Props.isqueReport &&
      Props.tableShowStatus !== constants.libTypeIds.CODING_CHALLENGE
      ? Props.selectque.embedLinks
      : []
  );

  let [libraryLinksData, setLibraryLinksData] = useState(
    Props.isqueReport &&
      Props.tableShowStatus !== constants.libTypeIds.CODING_CHALLENGE
      ? Props.selectque.link
      : []
  );

  const [makeStableState, setMakeStableState] = useState(false);
  const [fileName, setFileName] = useState("Browse Files to upload");

  const deletelibFile = (filename) => {
    let temp = [];

    for (let fname = 0; fname < libraryLinksData.length; fname++) {
      if (libraryLinksData[fname].link !== filename) {
        temp.push(libraryLinksData[fname]);
      }
    }

    setLibraryLinksData(temp);
  };

  const csvConfig = mkConfig({
    fieldSeparator: ",",
    decimalSeparator: ".",
    useKeysAsHeaders: true,
    filename:"mcq_invalid_questions_data",
  });

  const handleExportRows = (rows) => {
    const csv = generateCsv(csvConfig)(rows);
    download(csvConfig)(csv);
  };

  const handleGetSignedUrl = async (linkData) => {
    getPreSignedUrl(linkData)
      .then((res) => {
        window.open(res, "_blank", "noreferrer");
      })
      .catch((err) => {
        if (err.message.includes("403")) {
          setShow(true);
          setIsUnAuth(true);
          setTitle("Error");
          setMsg("You have been logged-out due to inactivity. Login again.");
        }
      });
  };

  useEffect(() => {
    // axios
    //   .get(process.env.REACT_APP_NODE_API + "node/admin/library/gettypes", {
    //     headers: {
    //       "Content-type": "application/json",
    //       Authorization: localStorage.getItem("tokenKey"),
    //     },
    //   })
    //   .then((res) => {
    //     let json = res.data;
    //     let types = json.map((type) => {
    //       return {
    //         value: type.id,
    //         label: type.name,
    //       };
    //     });

    //     setLibtypes(types);
    //   })
    //   .catch((error) => {
    //     if (error.message.includes("403")) {
    //       setShow(true);
    //       setTitle("Error");
    //       setIsUnAuth(true);
    //       setMsg("You have been logged-out due to inactivity. Login again.");
    //     }
    //   });

    // axios
    //   .get(process.env.REACT_APP_NODE_API + "node/admin/library/gettypes", {
    //     headers: {
    //       "Content-type": "application/json",
    //       Authorization: localStorage.getItem("tokenKey"),
    //     },
    //   })
    //   .then((res) => {
    //     let json = res.data;
    //     let types = json.map((type) => {
    //       return {
    //         value: type.id,
    //         label: type.name,
    //       };
    //     });

    //     setLibtypes(types);
    //   })
    //   .catch((error) => {
    //     if (error.message.includes("403")) {
    //       setShow(true);
    //       setTitle("Error");
    //       setIsUnAuth(true);
    //       setMsg("You have been logged-out due to inactivity. Login again.");
    //     }
    //   });

    axios
      .get("node/admin/library/gettypes", {
        headers: {
          "Content-type": "application/json",
        },
      })
      .then((res) => {
        let json = res.data.filter(
          (data) => data.parenttype === constants.libTypeIds.MCQ
        );

        setlibTypebyParent(json);

        if (Props.isqueReport) {
          let selectedData = json.filter(
            (data) => data.id === Props.selectque.type
          );

          if (selectedData[0].name === "SAQ") {
            setSelectSaqLaqLength(500);
          } else {
            setSelectSaqLaqLength(1000);
          }

          setlibTypeIdbyParent(selectedData[0].id);
          setSelectedCheck(selectedData[0].id);
          setlibTypeNamebyParent(selectedData[0].name);
        } else {
          setlibTypeNamebyParent(json[0].name);
          setlibTypeIdbyParent(json[0].id);
          setSelectedCheck(json[0].id);
          setChooseAnswer(1);
        }

        setlibTypebyParent(json);
        setShowOptionbtn(true);
      })
      .catch((error) => {
        if (error.message.includes("403")) {
          setShow(true);
          setTitle("Error");
          setIsUnAuth(true);
          setMsg("You have been logged-out due to inactivity. Login again.");
        }
      });
  }, []);

  const handleCloselib = () => setShowFromLibrary(false);
  const { handleAddOrUpdateLp } = useAddOrUpdateLp();
  const { uploadLargeFileToS3 } = useUploadLargeFileToS3();

  const [fileLibraryName, setFileLibraryName] = useState(
    "Upload Files from library"
  );

  let [showmcqsave, setshowmcqsave] = useState(false);

  const handleInputChange = (event, name) => {
    if (event.name === "topicname") {
      let tempusrinp = UserInput;

      if (tempusrinp.hasOwnProperty("topic")) {
        delete tempusrinp.topic;
      }

      setuserinp({
        result: "",
        ...tempusrinp,
        success: true,

        // [event.name]: event.value,
      });
    } else if (event.name === "topic") {
      let tempusrinp = UserInput;

      if (tempusrinp.hasOwnProperty("topicname")) {
        delete tempusrinp.topicname;
      }

      setuserinp({
        result: "",
        success: true,
        ...tempusrinp,

        // [event.name]: event.value,
      });
    } else if (name === "complexity") {
      setuserinp({
        result: "",
        ...UserInput,
        success: true,

        // [name]: event.value,
      });
      setLevel({ name: event.label, value: event.value });
    } else if (name === "description") {
      setHtmlData(event);
      setuserinp({
        result: "",
        ...UserInput,
        success: true,
        ["description"]: event,
      });
    } else if (name === "ansexp") {
      setuserinp({
        result: "",
        ...UserInput,
        success: true,
        ["ansexp"]: event,
      });
    } else {
      const target = event.target;

      let value = event.target.value;

      const name = target.name;

      if (name === "maxtime") {
        if (Props.tableShowStatus === constants.libTypeIds.ASSIGNMENT) {
          value = value * 60;
        }

        if (isNaN(parseInt(value))) {
          setErrors({
            ...Errors,
            success: false,
            maxtime: "Please enter only Numbers",
          });
        } else {
          setErrors({ ...Errors, maxtime: "", success: true });
        }
      }

      if (name === "marks") {
        if (isNaN(parseInt(value))) {
          setErrors({
            ...Errors,
            success: false,
            marks: "Please enter only Numbers",
          });
        } else {
          setErrors({ ...Errors, marks: "", success: true });
        }
      }

      setuserinp({ ...UserInput, [name]: value, result: "", success: true });
    }

    /* stable the state from qn report */

    if (Props.isqueReport && !makeStableState) {
      setMakeStableState(true);
    } else if (Props.isqueReport && makeStableState) {
      Props.setIsStableState(false);
    }
  };

  const handleNavigate = () => {
    if (resultCode === 1000) {
      setShow(false);
      navigate(`/library/${constants.libTypeIds.MCQ}/questionlist`);
    }
  };

  const deleteUploadFile = (filename) => {
    let temp = selectedFile.filter((f) => f.file.name !== filename);

    setSelectedFile(temp);
  };

  const handleAddbtn = () => {
    setShowFromLibrary(false);

    let temp = [...libraryLinksData];

    for (let t = 0; t < selectedRows.length; t++) {
      temp.push({ link: selectedRows[t].link, checked: false });
    }

    var unique = [];

    let uniqueLinks = [];

    var uniq = temp.filter((ele) => {
      var isduplicate = unique.includes(ele.link);

      if (!isduplicate) {
        unique.push(ele.link);
        uniqueLinks.push(ele);

        return true;
      } else {
        return false;
      }
    });

    setLibraryLinksData(uniqueLinks);
    setSelectedRows([]);
  };

  const handleChange = (state) => {
    setSelectedRows(state.selectedRows);
  };

  const embedLinkDeleteButtonClick = (event, index) => {
    event.preventDefault();

    const temp = [...embedLinksList];

    temp.splice(index, 1);
    setEmbedLinksList(temp);
  };

  const addQuestion = async (e, status) => {
    e.preventDefault();
    setloading(true);

    let questionsdata;
    let form = new FormData(),
      isProceed = false;

    let count = 0;

    for (let i = 0; i < mcqAns.length; i++) {
      if (mcqAns[i].isAnswer) {
        count++;
      }
    }

    if (count === 0) {
      isCheckboxChecked = false;
      setIsCheckboxChecked(false);
      isMultiAnsChecked = true;
      setIsMultiAnsChecked(true);
    } else if (libTypesNamebyParent === "MCQ_MULTI" && count < 2) {
      isMultiAnsChecked = false;
      setIsMultiAnsChecked(false);
    } else {
      isCheckboxChecked = true;
      setIsCheckboxChecked(true);
      isMultiAnsChecked = true;
      setIsMultiAnsChecked(true);
    }

    if (isCheckboxChecked && isMultiAnsChecked) {
      if (Props.isqueReport) {
        UserInput.id = Props.selectque.id;

        if (isSelectedTopic) {
          UserInput.topic = alreadySelected;
        }

        if (
          isSelectedComplexity &&
          Props.tableShowStatus !== constants.libTypeIds.CONTENT
        ) {
          UserInput.complexity = alreadySelectedCom;
        }
      }

      UserInput["topicData"] = topicAndComplexity;

      let MandatoryFields = [];
      if (
        UserInput.name === "" ||
        UserInput.name === null ||
        UserInput.name === undefined
      ) {
        // setloading(false);
        // setShow(true);
        // setTitle("Warning ");
        // setMsg("Please Give Name");
        // return;

        MandatoryFields.push("Name");
      }

      // if (
      //   UserInput.topic === "" ||
      //   UserInput.topic === null ||
      //   UserInput.topic === undefined
      // ) {
      //   // setloading(false);
      //   // setShow(true);
      //   // setTitle("Warning ");
      //   // setMsg("Please Choose Topic");
      //   // return;
      //   if (!UserInput.hasOwnProperty("topicname")) {
      //     MandatoryFields.push("Topic");
      //   }
      // }
      //if(UserInput.description=== "" || UserInput.description ===null || UserInput.description===undefined){
      // setloading(false);
      // setShow(true);
      // setTitle("Warning ");
      // setMsg("Please Give Description");
      // return;

      // }
      //if(UserInput.ansexp=== "" || UserInput.ansexp ===null || UserInput.ansexp===undefined){
      // setloading(false);
      // setShow(true);
      // setTitle("Warning ");
      // setMsg("Please Give Answer Explanation");
      // return;
      //MandatoryFields.push("Answer Explanation");
      // }
      // if (
      //   UserInput.complexity === "" ||
      //   UserInput.complexity === null ||
      //   UserInput.complexity === undefined
      // ) {
      //   // setloading(false);
      //   // setShow(true);
      //   // setTitle("Warning ");
      //   // setMsg("Please Choose Complexity");
      //   // return;
      //   MandatoryFields.push("Complexity");
      // }
      if (topicAndComplexity.length < 1) {
        // if (!userInput.hasOwnProperty("topicname")) {
  
        // }
        MandatoryFields.push("Topic");
      }

      if (MandatoryFields.length > 0) {
        setloading(false);
        setShow(true);
        setTitle("Warning ");
        setMsg(`Missing ${MandatoryFields.join(",")} `);

        return;
      }

      questionsdata = JSON.stringify({
        qdata: UserInput,
      });
      form.append("qdata", questionsdata);
      form.append("libtypeid", libTypesIdbyParent);
      form.append("mcqAns", JSON.stringify(mcqAns));
      form.append("libtypename", libTypesNamebyParent);

      if (embedLinksList.length > 0) {
        form.append("embedLinks", embedLinksList);
      }

      if (Props.selectque?.testDetails?.length > 0) {
        form.append(
          "testConfigIds",
          Props.selectque.testDetails
            .map((testData) => testData.testconfigid)
            .toString()
        );
      }

      let candown = [],
        fileUploadCount = 0,
        fileNames = [];

      if (selectedFile.length > 0) {
        form.append("hasfiles", "yes");

        for (let i = 0; i < selectedFile.length; i++) {
          fileNames.push(selectedFile[i].file.name);
          candown.push({
            [selectedFile[i].file.name]: false,
          });

          let filePath = "library/misc/" + selectedFile[i].file.name;

          if (process.env.REACT_APP_DEPLOYMENT === "AWS")
            await uploadLargeFileToS3(
              selectedFile[i].file,
              filePath,
              selectedFile[i].file.name.replace(/^.*\./, "")
            );
          else form.append(selectedFile[i].file.name, selectedFile[i].file);
          fileUploadCount++;
        }

        form.append("isfiledownloadable", JSON.stringify(candown));
      }

      form.append("fileNames", JSON.stringify(fileNames));

      if (fileUploadCount === selectedFile.length) isProceed = true;

      if (libraryLinksData.length > 0) {
        form.append("links", JSON.stringify(libraryLinksData));
      }
    } else if (!isMultiAnsChecked) {
      setloading(false);
      setShow(true);
      setTitle("Info");
      setMsg("You should select multiple answers");
    } else {
      setloading(false);
      setShow(true);
      setTitle("Info");
      setMsg("Options can't be blank!");
    }

    isProceed = true;

    if (isProceed) {
      axios({
        data: form,
        method: "post",
        url:
          status === "add"
            ? "node/admin/library/add"
            : "node/admin/library/update",
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then((res) => {
          setloading(false);

          if (res.data.resultCode == "1000") {
            if (fromList.isFromList) {
              setFromList({ isFromList: false, libType: "" });

              let newDetails = { ...lpData2 },
                uniqueId = 1,
                libraryData = {
                  isNew: true,
                  published: 0,
                  isEdited: false,
                  title: UserInput.name,
                  id: res.data.data.libid,
                  libTypeId: lpData2.libTypeId,
                  topics: [
                    {
                      topicid: UserInput.topic,
                    },
                  ],
                };

              if (isOldLpDetail) libraryData.status = "new";

              for (let i = 0; i < newDetails.modules.length; i++) {
                if (newDetails.modules[i].id === newDetails.moduleId)
                  newDetails.modules[i].moduleData.push(libraryData);

                for (
                  let j = 0;
                  j < newDetails.modules[i].moduleData.length;
                  j++
                ) {
                  newDetails.modules[i].moduleData[j].moduleDataId = uniqueId;
                  newDetails.modules[i].moduleData[j].key = nanoid();
                  uniqueId++;
                }

                newDetails.modules[i].id = uniqueId;
                uniqueId++;
              }

              newDetails.isAddTest = false;
              handleNavigateToLpAfterAddTest(newDetails);
            } else if (isOldLpDetail) {
              let newDetails = JSON.parse(JSON.stringify(lpData2));

              for (let i = 0; i < newDetails.modules.length; i++) {
                if (newDetails.modules[i].id === newDetails.moduleId) {
                  for (
                    let j = 0;
                    j < newDetails.modules[i].moduleData.length;
                    j++
                  ) {
                    if (
                      newDetails.modules[i].moduleData[j] &&
                      UserInput.id === newDetails.modules[i].moduleData[j].libId
                    ) {
                      newDetails.modules[i].moduleData[j].libId =
                        res.data.data.libid;
                      newDetails.modules[i].moduleData[j].title =
                        UserInput.name;
                      newDetails.modules[i].moduleData[j].testDuration =
                        UserInput.maxtime;
                      newDetails.modules[i].moduleData[j].moved = "yes";
                    }
                  }
                }
              }

              setLpData2(newDetails);

              return (async () => {
                try {
                  let res = await handleAddOrUpdateLp({}, newDetails.modules);

                  if (res.title === "success") {
                    setShow(true);
                    setTitle("Success");
                    setMsg(res.message);
                    handleNavigateToLpAfterAddTest(newDetails);
                  }
                } catch (err) {
                  setIsUnAuth(err.hasOwnProperty("isUnAuth"));
                  setShow(true);
                  setTitle("Error");
                  setMsg(err.message);
                }
              })();
            } else {
              setResultCode(res.data.resultCode);
              setShow(true);
              setTitle("Success");
              setMsg(
                <>
                  {res.data.message} <br />
                  {/* {"Inserted Question id : " + res.data.resultid} */}
                </>
              );
            }
          } else {
            setShow(true);
            setTitle("Error");
            setMsg(res.data.message);
          }
        })
        .catch((error) => {
          setloading(false);
          if (error.message.includes("403")) {
            setShow(true);
            setTitle("Error");
            setIsUnAuth(true);
            setMsg("You have been logged-out due to inactivity. Login again.");
          }
        });
    }
  };

  const restrictCall = () => {
    setCheckedState([...checkedState, false]);

    if (chooseMultiAnswer) {
      setChooseAnswer(mcqAnsCount.current);
    }
  };

  const addNewOptionsforMcq = (event, type) => {
    if (event !== "selection") {
      event.preventDefault();
    }

    const newOption = {
      id: nanoid(),
      optionText: "",
      isAnswer: false,
    };

    if (event === "selection") {
      if (type === "multi") {
        const allOptions = [...mcqAns, newOption, newOption];

        mcqAnsCount.current = mcqAnsCount.current + 2;
        setMcqAns(allOptions);
        restrictCall();
      } else {
        const allOptions = [...mcqAns, newOption];

        mcqAnsCount.current++;
        setMcqAns(allOptions);
        restrictCall();
      }
    } else {
      const newOption = {
        id: nanoid(),
        optionText: "",
        isAnswer: false,
      };

      const allOptions = [...mcqAns, newOption];

      setMcqAns(allOptions);
      mcqAnsCount.current++;
      restrictCall();
    }
  };

  const handleFileDownloadChangeforLink = (pos) => {
    let temp = [...libraryLinksData];

    temp[pos].checked = !temp[pos].checked;
    setLibraryLinksData(temp);
  };

  const selectMcqAnswer = (name, id) => {
    setIsCheckboxChecked(false);
    setMcqAns([
      {
        id: nanoid(),
        optionText: "",
        isAnswer: false,
      },
      {
        id: nanoid(),
        optionText: "",
        isAnswer: false,
      },
    ]);
    setlibTypeNamebyParent(name);
    setlibTypeIdbyParent(id);
    setSelectedCheck(id);

    if (name === "MCQ_SINGLE") {
      setChooseAnswer(1);
      setCheckedState(new Array(mcqAnsCount.current).fill(false));
      setChooseMultiAnswer(false);

      if (mcqAns.length <= 1) {
        addNewOptionsforMcq("selection", "single");
      }
    } else {
      if (mcqAnsCount.current > 1) {
        setChooseAnswer(mcqAnsCount.current);
      } else {
        setChooseMultiAnswer(true);
      }

      setCheckedState(new Array(mcqAnsCount.current).fill(false));

      if (mcqAns.length <= 1) {
        addNewOptionsforMcq("selection", "multi");
      }
    }
  };

  const handleBackBtn = () => {
    Props.setselectque([]);
    setIsFromLp(false);
    Props.setisqueReport(false);
    setFromList({ isFromList: false, libType: "" });

    if (isFromBatch) {
      navigate(`/batches/${sessionStorage.getItem('abatchid')}/courses`);
    } else {
      navigate("/learningpath");
    }
  };

  const handleBackFromQuestion = () => {
    navigate(`/library/${constants.libTypeIds.MCQ}/questionlist`);
  };

  const handleUpdate = (value, editor) => {
    handleInputChange(value, "description");
  };

  const handleAnsExpUpdate = (value, editor) => {
    handleInputChange(value, "ansexp");
  };

  let cancelClikedInConfirmBox = () => {
    setShowNotification(false);
    navigate(`/library/${constants.libTypeIds.DESCRIPTIVE}/questionlist`);
  };

  const handleUploadUserfile = (event) => {
    let file = event.target.files[0];

    if (!file.size) return;

    let reader = new FileReader();

    reader.onload = async (e) => {
      const form = new FormData();

      form.append("mcq", file);

      Props.setIsLoaded(true);
      axios({
        data: form,
        method: "post",
        url: "node/admin/library/addviacsv",
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then((response) => {
          Props.setIsLoaded(false);
          setResultCode(response.data.resultCode);
          event.target.value = "";

          if (response.data.resultCode === 1000 && !response.data.hasOwnProperty("data")) {
            setShow(true);
            setTitle("Success");
            setMsg(response.data.message);
            event.target.value = "";
            setResultCode(response.data.resultCode);
          } else if (response.data.resultCode === 2050) {
            Props.setIsLoaded(false);
            setShow(true);
            setTitle("Warning !");
            setMsg(response.data.msg);
          } else {
            setShow(true);
            setTitle("Warning!");
            setMsg(response.data.msg);
          }

          if(response.data.hasOwnProperty("data")){
            handleExportRows(response.data.data);
            setShow(true);
            setTitle("Warning");
            setMsg(response.data.msg)
          }
        })
        .catch((err) => {
          if (err.message.includes("403")) {
            setShow(true);
            setTitle("Error");
            setIsUnAuth(true);
            setMsg("You have been logged-out due to inactivity. Login again.");
          }
          event.target.value = "";
        });
    };
    reader.readAsDataURL(file);
  };

  const downloadFile = async (libType) => {
    let URL = "";

    if (process.env.REACT_APP_DEPLOYMENT === "LOCAL")
      URL =
        libType === constants.libTypeIds.DESCRIPTIVE
          ? "../../../assets/files/TEMPLATE_SAQ_LAQ.xlsx"
          : "../../../assets/files/TEMPLATE_MCQ_SCQ.xlsx";
    else {
      let preSignedUrl = await getPreSignedUrl(
        libType === constants.libTypeIds.DESCRIPTIVE
          ? "Templates/TEMPLATE_SAQ_LAQ.xlsx"
          : "Templates/TEMPLATE_MCQ_SCQ.xlsx"
      );

      URL = preSignedUrl;
    }

    window.location.href = URL;
  };

  const handleAddOptionChange = (event, position, checkbox) => {
    const fieldName = event.target.getAttribute("name");

    var mcqoptions;

    let fieldValue;

    if (checkbox === "checkbox") {
      fieldValue = event.target.checked;

      //  if (checkedState.filter((i) => i).length >= chooseAnswer && event.target.checked) return;
      //  const updatedCheckedState = checkedState.map((item, index) =>
      //    index === position ? !item : item
      //  );

      if (chooseAnswer === 1) {
        var tempcs = new Array(mcqAnsCount.current).fill(false);

        tempcs[position] = true;

        var temp = JSON.stringify(mcqAns);

        temp = JSON.parse(temp);
        temp.map((opt, index) => {
          opt["isAnswer"] = tempcs[index];
        });
        mcqoptions = temp;
        setMcqAns(temp);
        setCheckedState(tempcs);
      }

      if (chooseAnswer > 1) {
        let tempcs = [...checkedState];

        tempcs[position] = !tempcs[position];

        let temp = JSON.stringify(mcqAns);

        temp = JSON.parse(temp);
        temp.map((opt, index) => {
          opt["isAnswer"] = tempcs[index];
        });
        mcqoptions = temp;
        setMcqAns(temp);
        setCheckedState(tempcs);
      }
    } else {
      fieldValue = event.target.value;

      let temp = JSON.stringify(mcqAns);

      temp = JSON.parse(temp);
      temp[position][fieldName] = fieldValue;
      setMcqAns(temp);
      mcqoptions = temp;
    }
    let show = 0;

    if (Props.isqueReport && checkbox == "text") {
      show = 1;
    }
    for (let i = 0; i < mcqoptions.length; i++) {
      if (
        mcqoptions[i]["isAnswer"] === true &&
        mcqoptions[i]["optionText"] != "" &&
        mcqoptions[i]["optionText"] != null
      ) {
        show = 1;
        break;
      }
    }
    if (show === 1) {
      setshowmcqsave(true);
    } else {
      setshowmcqsave(false);
    }
  };

  const handleOptionDelete = (pos) => {
    const deleteOption = [...mcqAns];

    deleteOption.splice(pos, 1);

    mcqAnsCount.current = deleteOption.length;

    let tempcs = checkedState;

    tempcs.splice(pos, 1);
    setCheckedState(tempcs);
    setMcqAns(deleteOption);

    let show = 0;

    for (let i = 0; i < deleteOption.length; i++) {
      if (
        deleteOption[i]["isAnswer"] === true &&
        deleteOption[i]["optionText"] != "" &&
        deleteOption[i]["optionText"] != null
      ) {
        show = 1;
        break;
      }
    }

    if (show === 1) {
      setshowmcqsave(true);
    } else {
      setshowmcqsave(false);
    }
  };

  const uploadHandler = (e) => {
    const file = e.target.files;

    let temp = [...selectedFile];

    for (var i = 0; i < file.length; i++) {
      if (Props.isqueReport) {
        if (
          !Props.selectque.link
            .map((i) =>
              i.link.substring(i.link.lastIndexOf("/") + 1, i.link.length)
            )
            .includes(file[i].name)
        ) {
          temp.push({
            file: file[i],
            checked: false,
            url: URL.createObjectURL(file[i]),
          });
        }
      } else {
        if (!temp.map((data) => data.file.name).includes(file[i].name)) {
          temp.push({
            file: file[i],
            checked: false,
            url: URL.createObjectURL(file[i]),
          });
        }
      }
    }

    setSelectedFile(temp);
  };

  const handleDelete = (key) => {
    const newData = dataTopicAndComplexity.filter((item) => item.key !== key);

    const topicandcomplexityData = topicAndComplexity.filter(
      (item) => item.key !== key
    );

    //Props.setInVatypes(newData);

    setTopicAndCompexity(topicandcomplexityData);
    setDataTopicAndComplexity(newData);
  };

  const defaultColumns = [
    {
      title: "Topic",
      width: "100px",
      editable: false,
      dataIndex: "topicname",
    },
    {
      width: "100px",
      editable: false,
      title: "Complexity",
      dataIndex: "complexity",
    },
    {
      width: "100px",
      title: "Action",
      dataIndex: "Action",
      render: (_, record) =>
        dataTopicAndComplexity.length >= 1 ? (
          <button
            onClick={() => handleDelete(record.key)}
            style={{ border: "none", background: "none" }}
          >
            <FontAwesomeIcon className="faTrashCan" icon={faTrashCan} />
          </button>
        ) : null,
    },
  ];

  const handleSave = (row) => {
    const newData = [...dataTopicAndComplexity];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];

    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    setDataTopicAndComplexity(newData);
  };

  const Columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        handleSave,
        title: col.title,
        editable: col.editable,
        dataIndex: col.dataIndex,
      }),
    };
  });

  const handleAdd = (e) => {
    e.preventDefault();

    if (topicAndComplexity.map((item) => item.name).includes(topic?.name)) {
      setShow(true);
      setTitle("Warning !");
      setMsg(`Topic "${topic?.name}" already added!`);

      return;
    }

    // setShowAdd(false);

    let newData;

    newData = {
      key: count + 1,
      topicname: topic?.name,
      complexity: level?.name,
    };


    let topicData;

    topicData = {
      key: count+1,
      type: topic?.type,
      name: topic?.name,
      value: topic?.value,
      complexity: level?.value,
    };
    setCount((count) => count + 1);

    if (topicAndComplexity != null) {
      setTopicAndCompexity([...topicAndComplexity, topicData]);
    }

    setDataTopicAndComplexity([...dataTopicAndComplexity, newData]);
    console.log("new___Data",newData);
    setHandleadd(true);
    setReset(true);
  };

  useEffect(() => {
    if (
      Props.isqueReport &&
      (Props.tableShowStatus === constants.libTypeIds.CODING_CHALLENGE ||
        Props.tableShowStatus === constants.libTypeIds.DESCRIPTIVE ||
        Props.tableShowStatus === constants.libTypeIds.MCQ)
    ) {
      const topicDetails = JSON.parse(Props?.selectque?.topicData)?.map(
        (q) => ({
          topicid: q.topicid,
          topicname: topics.find((t) => t.value === q.topicid)?.label || "",
          complexityid: q.complexityid,
        })
      );

      const resultArray = topicDetails?.map((qd, index) => ({
        key: index + 1,
        topicname: qd.topicname,
        complexity:
          complexities.find((c) => c.value === qd.complexityid)?.label || "",
      }));

      setDataTopicAndComplexity(resultArray);
      setCount(resultArray?.length);

      const topicDetailstoDb = JSON.parse(Props.selectque.topicData)?.map(
        (q, index) => ({
          key: index + 1,
          type: "id",
          value: q.topicid,
          complexity: q.complexityid,
          name: topics.find((t) => t.value === q.topicid)?.label || "",
        })
      );

      setTopicAndCompexity(topicDetailstoDb);
    }
  }, [topics, complexities]);

  useEffect(() => {
    if (handleadd) {
      setHandleadd(!handleadd);
    }
  }, [handleadd]);

  const getfilesfromlibrary = () => {
    setShowFromLibrary((pre) => !pre);

    axios
      .get("node/admin/library/getlinks", {
        headers: {
          "Content-type": "application/json",
        },
      })
      .then((res) => {
        setfilesFromLip(res.data);
      })
      .catch((error) => {
        if (error.message.includes("403")) {
          setShow(true);
          setTitle("Error");
          setIsUnAuth(true);
          setMsg("You have been logged-out due to inactivity. Login again.");
        }
      });
  };

  return (
    <>
      {showNotification && (
        <Notify
          title="Result"
          message={response}
          show={setShowNotification}
          onHide={cancelClikedInConfirmBox}
        />
      )}

      {(loading || Props.isLoaded) && <Loading />}

      <Form id="identifier">
        {fromList.isFromList || isFromBatch || isOldLpDetail || isFromLp ? (
          <div style={{ textAlign: "left" }}>
            <FontAwesomeIcon
              icon={faArrowLeft}
              onClick={handleBackBtn}
              className="adminTestBackBtn"
            />
          </div>
        ) : (
          <div style={{ textAlign: "left" }}>
            <FontAwesomeIcon
              icon={faArrowLeft}
              className="adminTestBackBtn"
              onClick={handleBackFromQuestion}
            />
            &nbsp;
            <span className="adminTestBack" onClick={handleBackFromQuestion}>
              {" "}
              Back{" "}
            </span>
          </div>
        )}
        {/* {UserInput.success && <p className="mcqerrors">{UserInput.result}</p>}
        {!UserInput.success && <h3>{UserInput.result}</h3>} */}
        <br />
        {/* {Props.tableShowStatus === constants.libTypeIds.MCQ ? ( */}
        <div className="radiobtnforMcq">
          {libTypebyParent.map((data) => (
            <div className="singleSelectformcq">
              <input
                type="radio"
                name="mcqAnswer"
                value="SinleAns"
                id="singleSelectformcq"
                checked={selectedCheck === data.id}
                onClick={() => selectMcqAnswer(data.name, data.id)}
              />
              <label htmlFor="singleSelectformcq">
                {data.name.replace("MCQ_", "")}
              </label>
            </div>
          ))}

          {/* mcq file upload */}

          <div className="table-options-get-excel-button">
            <Button
              sx={{
                border: 1,
                height: 40,
                boxShadow: 0,
                minWidth: 105,
                borderRadius: 2,
                bgcolor: "white",
                color: "#344054",
                borderColor: "#D0D5DD",
                "&:hover": {
                  color: "orange",
                  backgroundColor: "white",
                },
              }}
              variant="contained"
              onClick={() => downloadFile(constants.libTypeIds.MCQ)}
            >
              <span className="report-options-export-button-icon">
                <svg
                  width="20"
                  height="18"
                  fill="none"
                  viewBox="0 0 20 18"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    stroke="#344054"
                    stroke-width="1.66667"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M6.66663 13.1667L9.99996 16.5M9.99996 16.5L13.3333 13.1667M9.99996 16.5V9M16.6666 12.9524C17.6845 12.1117 18.3333 10.8399 18.3333 9.41667C18.3333 6.88536 16.2813 4.83333 13.75 4.83333C13.5679 4.83333 13.3975 4.73833 13.3051 4.58145C12.2183 2.73736 10.212 1.5 7.91663 1.5C4.46485 1.5 1.66663 4.29822 1.66663 7.75C1.66663 9.47175 2.36283 11.0309 3.48908 12.1613"
                  />
                </svg>
              </span>
              <span className="report-options-export-button-label">
                Template
              </span>
            </Button>
          </div>

          <div
            className="mcq_fileupload"
            onClick={() => document.querySelector(".input-field").click()}
          >
            <br />

            <input
              hidden
              type="file"
              name="myfile"
              className="input-field"
              style={{ width: "250px", height: "35px" }}
              onChange={(event) => handleUploadUserfile(event)}
            />
            <span
              style={{
                fontSize: "14px",
                color: "#1475cf",
                fontFamily: "Inter",
              }}
              className="mcqUploadFileName"
            >
              <span>
                <FontAwesomeIcon
                  icon={faArrowUpFromBracket}
                  className="mcqcontentUploadIcon"
                />
              </span>
              {uploadExcel}
            </span>
            <br />
            <p>{linksdata}</p>
          </div>
        </div>
        {/* // ) : null} */}
        {/* <Modules
          reset={addQuestion}
          fromList={fromList}
          isFromLp={isFromLp}
          isFromBatch={isFromBatch}
          setvalue={handleInputChange}
          isOldLpDetail={isOldLpDetail}
          selectmodule={Props.selectque}
          isqueReport={Props.isqueReport}
          alreadySelected={alreadySelected}
          isSelectedTopic={isSelectedTopic}
          tableShowStatus={Props.tableShowStatus}
          setalreadySelected={setalreadySelected}
          setisSelectedTopic={setisSelectedTopic}
        />{" "} */}
        <Modules
          reset={reset}
          topic={topic}
          topics={topics}
          setTopic={setTopic}
          fromList={fromList}
          isFromLp={isFromLp}
          selectmodule={null}
          handleadd={handleadd}
          setTopics={setTopics}
          isFromBatch={isFromBatch}
          setvalue={handleInputChange}
          isOldLpDetail={isOldLpDetail}
          isqueReport={Props.isqueReport}
          alreadySelected={alreadySelected}
          isSelectedTopic={isSelectedTopic}
          tableShowStatus={Props.tableShowStatus}
        />{" "}
        <Complexity
          level={level}
          setLevel={setLevel}
          fromList={fromList}
          isFromLp={isFromLp}
          selectmodule={null}
          handleadd={handleadd}
          isFromBatch={isFromBatch}
          setvalue={handleInputChange}
          isOldLpDetail={isOldLpDetail}
          isqueReport={Props.isqueReport}
          setComplexities={setComplexities}
          tableShowStatus={Props.tableShowStatus}
          isSelectedComplexity={isSelectedComplexity}
          setalreadySelectedCom={setalreadySelectedCom}
          setisSelectedComplexity={setisSelectedComplexity}
        />{" "}
        <div className="addBtnToAddTopicAndComplexity">
          <button
            type="primary"
            onClick={handleAdd}
            className="btnMedium"
            disabled={topic?.name == null || level?.value == null}
          >
            Add
          </button>
        </div>
        <br />
        <div className="tableForTopicAndComplexity">
          <Table
            bordered
            columns={Columns}
            pagination={false}
            dataSource={dataTopicAndComplexity}

            // components={components}
            //rowClassName={() => "editable-row"}
          />
        </div>
        <br />
        <div className="mcqquestion_langname">
          <label htmlFor="mcqquestion_langname">
            Name<span style={{ color: "red", width: "4px" }}> *</span>
          </label>
          <br />
          <input
            name="name"
            type="text"
            value={UserInput.name}
            className="form-control"
            id="mcqquestion_langname"
            onChange={handleInputChange}
          />
        </div>{" "}
        <br />
        <div className="mcqquestion_description">
          <label htmlFor="mcqquestion_description">
            {Props.tableShowStatus === constants.libTypeIds.ASSIGNMENT
              ? "Instruction"
              : "Description"}
          </label>
          <br />
          <div></div>
        </div>{" "}
        <div className="mcqeditor-tinymce">
          <Editor
          tinymceScriptSrc='/tinymce/tinymce.min.js'
            onEditorChange={handleUpdate}
            value={UserInput.description}
            onInit={(evt, editor) => (editorRef.current = editor)}
            init={{
              license_key: 'gpl',
              readonly: true,
              menubar: false,
              min_height: 150,
              branding: false,
              statusbar: false,
              elementpath: false,
              selector: "textarea",
              allow_html_in_named_anchor: true,
              plugins: [
                "link",
                "help",
                "code",
                "lists",
                "image",
                "table",
                "media",
                "anchor",
                "editor",
                "advlist",
                "preview",
                "charmap",
                "wordcount",
                "fullscreen",
                "autoresize",
                "visualblocks",
                "searchreplace",
                "insertdatetime",
              ],
              toolbar:
                Props.tableShowStatus === constants.libTypeIds.CONTENT ||
                Props.tableShowStatus === constants.libTypeIds.ASSIGNMENT
                  ? "undo redo | blocks | " +
                    "bold italic underline forecolor | link | alignleft aligncenter " +
                    "alignright alignjustify | bullist numlist outdent indent | " +
                    "removeformat | image | table tabledelete | tableprops tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol | help |"
                  : "undo redo | blocks | " +
                    "bold italic underline forecolor | link | alignleft aligncenter " +
                    "alignright alignjustify | bullist numlist outdent indent | " +
                    "removeformat | help |",
              content_style:
                "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
            }}
          />
        </div>
        <br />
        <div className="mcq_ans_exp">
          <br />
          <div className="mcq_ans_exp_label">
            <label htmlFor="mcqquestion_description">Answer</label>
          </div>
          <Editor
          tinymceScriptSrc='/tinymce/tinymce.min.js'
            value={UserInput.ansexp}
            onEditorChange={handleAnsExpUpdate}
            onInit={(evt, editor) => (editorRef.current = editor)}
            init={{
              license_key: 'gpl',
              readonly: true,
              menubar: false,
              min_height: 150,
              branding: false,
              statusbar: false,
              elementpath: false,
              selector: "textarea",
              allow_html_in_named_anchor: true,
              plugins: [
                "link",
                "code",
                "help",
                "lists",
                "image",
                "media",
                "table",
                "anchor",
                "editor",
                "advlist",
                "charmap",
                "preview",
                "wordcount",
                "fullscreen",
                "autoresize",
                "visualblocks",
                "searchreplace",
                "insertdatetime",
              ],
              toolbar:
                "undo redo | blocks | " +
                "bold italic underline forecolor | link | alignleft aligncenter " +
                "alignright alignjustify | bullist numlist outdent indent | " +
                "removeformat | image | table tabledelete | tableprops tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol | help |",
              content_style:
                "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
            }}
          />
        </div>
        <div className="mcqembedLinkView">
          <ul className="mcqembed-list">
            {embedLinksList?.map((text, index) => (
              <div className="embededLinkLists">
                <li key={index}>{text}</li>
                <button
                  style={{ border: "none", background: "none" }}
                  onClick={(event) => embedLinkDeleteButtonClick(event, index)}
                >
                  {Props.selectque?.published === 0 && (
                    <FontAwesomeIcon
                      icon={faTrashCan}
                      className="mcqfaTrashCan"
                    />
                  )}
                </button>
              </div>
            ))}
          </ul>
        </div>
        <br />
        <div className="mcqQuestionOptions">
          {mcqAns.map((data, index) => (
            <>
              <div className="singleOption" key={data.id}>
                <div style={{ display: "inline-block" }}>
                  <input
                    type="text"
                    name="optionText"
                    id={index + "text"}
                    className="mcqOptions"
                    onChange={(e) => handleAddOptionChange(e, index, "text")}
                    value={
                      Props.isqueReport
                        ? data.optionText
                        : mcqAns[index][index + "optionText"]
                    }
                  />
                </div>
                <div
                  className="mcqcheckBoxdiv"
                  style={{ display: "inline-block" }}
                >
                  <input
                    type="checkbox"
                    name="isAnswer"
                    id={`isanswer-${index}`}
                    style={{ margin: "0 5px 0 0" }}
                    checked={
                      Props.isqueReport ? data.isAnswer : checkedState[index]
                    }
                    onChange={(e) =>
                      handleAddOptionChange(e, index, "checkbox")
                    }
                  />
                  is Answer
                </div>
                <div style={{ display: "inline-block" }}>
                  <FontAwesomeIcon
                    icon={faTrashCan}
                    className="deleteMcqOption"
                    onClick={() => handleOptionDelete(index)}
                  />
                </div>
              </div>
            </>
          ))}

          <button
            type="button"
            id="mcqaddOptBtn"
            onClick={(e) => addNewOptionsforMcq(e)}
          >
            Add Option
          </button>
        </div>
        <div className="mcqcontent_fileupload">
          <input
            hidden
            multiple
            type="file"
            name="file"
            onChange={uploadHandler}
            key={selectedFile.length}
            className="contentinput-field"
            onClick={(e) => {
              e.target.value = null;
            }} // Reset input value
            onBlur={(e) => {
              e.target.form.dispatchEvent(new Event("submit"));
            }}
          />
          <div
            className="content_fileupload_trigger"
            onClick={() =>
              document.querySelector(".contentinput-field").click()
            }
          >
            <span
              className="contentUploadFileName"
              style={{
                fontSize: "14px",
                color: "#1475cf",
                fontFamily: "Inter",
              }}
            >
              <span>
                <FontAwesomeIcon
                  icon={faArrowUpFromBracket}
                  className="mcqcontentUploadIcon"
                />
              </span>
              {fileName}
            </span>
          </div>
        </div>
        <div className="mcqcontent_fileupload" onClick={getfilesfromlibrary}>
          <span
            className="contentUploadFileName"
            style={{
              fontSize: "14px",
              color: "#1475cf",
              fontFamily: "Inter",
            }}
          >
            <span>
              <FontAwesomeIcon
                icon={faArrowUpFromBracket}
                className="mcqcontentUploadIcon"
              />
            </span>
            {fileLibraryName}
          </span>
        </div>
        <Modal show={showFromLibrary} onHide={handleCloselib} centered>
          <div>
            <Tab.Container defaultActiveKey="first">
              <Nav variant="pills">
                <Nav.Item className="mcqquestion-navItem">
                  <Nav.Link eventKey="first">Content</Nav.Link>
                </Nav.Item>
                <Nav.Item className="mcqquestion-navItem">
                  <Nav.Link eventKey="second">Misc</Nav.Link>
                </Nav.Item>
              </Nav>

              <Tab.Content>
                <Tab.Pane eventKey="first">
                  <DataTable
                    selectableRows
                    columns={columns}
                    customStyles={customStyles}
                    className="customDatatable"
                    data={filesFromLib.contentlinksdata}
                    onSelectedRowsChange={handleChange}
                  />
                </Tab.Pane>
                <Tab.Pane eventKey="second">
                  <DataTable
                    selectableRows
                    columns={columns}
                    customStyles={customStyles}
                    className="customDatatable"
                    data={filesFromLib.misclinksdata}
                    onSelectedRowsChange={handleChange}
                  />
                </Tab.Pane>
              </Tab.Content>
              <button
                type="button"
                id="addLibrary"
                onClick={handleAddbtn}
                className="btn btn-primary"
              >
                <span>Add</span>
              </button>
            </Tab.Container>
          </div>
        </Modal>
        <div className="mcqshowinglist">
          <ul className="mcqfile-lists">
            {selectedFile?.map((f, index) => (
              <>
                <li key={index}>
                  <FontAwesomeIcon icon={faFileAlt} />
                  <a
                    href={f.url}
                    target="_blank"
                    rel="noreferrer"
                    className="mcqlistsName"
                  >
                    {f.file.name}
                  </a>

                  {!Props.isFromBatch &&
                    !Props.isOldLpDetail &&
                    !Props.isFromLp &&
                    Props.selectque.published === 0 && (
                      <div className="actions">
                        <FontAwesomeIcon
                          icon={faTrash}
                          className="mcqtrashIconforDeleteFile"
                          onClick={() => deleteUploadFile(f.file.name)}
                        />
                      </div>
                    )}
                </li>
              </>
            ))}
          </ul>
        </div>
        <div className="mcqshowinglist">
          <ul className="mcqfile-lists">
            {libraryLinksData?.map((i, index) => (
              <li key={index}>
                <FontAwesomeIcon icon={faFileAlt} />
                {process.env.REACT_APP_DEPLOYMENT !== "AWS" ? (
                  <a
                    target="_blank"
                    rel="noreferrer"
                    className="mcqlistsName"
                    href={String(process.env.REACT_APP_NODE_API) + i.link}
                  >
                    {i.link.substring(
                      i.link.lastIndexOf("/") + 1,
                      i.link.length
                    )}
                  </a>
                ) : (
                  <p
                    className="mcqlistsName"
                    onClick={() => handleGetSignedUrl(i.link)}
                  >
                    {i.link.substring(
                      i.link.lastIndexOf("/") + 1,
                      i.link.length
                    )}
                  </p>
                )}

                <div className="actions">
                  <FontAwesomeIcon
                    icon={faTrash}
                    className="mcqtrashIconforDeleteFile"
                    onClick={() => deletelibFile(i.link)}
                  />
                </div>

                {Props.tableShowStatus === constants.libTypeIds.ASSIGNMENT && (
                  <div className="mcqcheckisDownload">
                    <input
                      type="checkbox"
                      checked={i.checked}
                      onChange={(e) => handleFileDownloadChangeforLink(index)}
                    />
                  </div>
                )}
              </li>
            ))}
          </ul>
        </div>
        <Notify
          show={show}
          title={title}
          message={msg}
          onHide={
            isUnAuth
              ? handleUnAuthAlert
              : resultCode === 1000
              ? handleNavigate
              : handleClose
          }
        />
        {/* <div
          className="question_updatebtn" >
          {Props.tableShowStatus === constants.libTypeIds.CODING_CHALLENGE && (
            <button
              onClick={(e) => {
                addQuestion(e,"update");
              }}
              disabled={!enableSaveButton}
            >
              Update
            </button>
          )}
        </div> */}
        <div className="mcqquestion_savebtn">
          <button
            onClick={(e) => {
              addQuestion(e, "add");
            }}
            disabled={false}
          >
            {Props.isqueReport ? "Save as new" : "Save"}
          </button>
        </div>
        <div style={{ height: "100px", float: "left" }}></div>
      </Form>
    </>
  );
}
export default Mcqtest;
