import { useRef, useState } from "react";

export default function IoHooks() {
  let tsInp = useRef([]);

  const textAreaRef = useRef(null);

  let [inEditMode, setInEditMode] = useState(false);

  const [errors, setErrors] = useState("");
  const [editContactId, setEditContactId] = useState(null);
  const [initialOutputs, setInitialOutputs] = useState([""]);

  const [addFormData, setAddFormData] = useState({
    input: "",
    output: [],
    isExample: false,
  });

  const focusOnField = () => {
    tsInp.current = [];
  };

  const addTextArea = () => {
    let updated = [...initialOutputs];

    updated.push("");
    setInitialOutputs(updated);
  };

  const handleDeleteOutput = (e, index) => {
    let updated = [...initialOutputs];

    updated.splice(index, 1);
    setInitialOutputs(updated);
  };

  const checkOuputs = (arr) => {
    if (arr.length === 0) {
      return false;
    } else {
      for (let i = 0; i < arr.length; i++) {
        if (arr[i] === "") {
          return false;
        }
      }
    }

    return true;
  };

  const handleAddFormChange = (event, name, from, index) => {
    if (from === "newinp") {
      const newFormData = { ...addFormData };

      newFormData.input = event;
      setErrors("");
      setAddFormData(newFormData);
    } else {
      let fieldName;
      let fieldValue;

      if (name === "checkbox") {
        fieldValue = event.target.checked;
        fieldName = event.target.getAttribute("name");
      } else {
        fieldName = event.target.getAttribute("name");
        fieldValue = event.target.value;
      }

      let newFormData = { ...addFormData };

      if (fieldName === "output") {
        initialOutputs[index] = event.target.value;

        let outputs = [...initialOutputs];

        newFormData[fieldName] = outputs;
      } else {
        newFormData[fieldName] = fieldValue;
      }

      setErrors("");
      setAddFormData(newFormData);
    }
  };

  return {
    tsInp,
    errors,
    setErrors,
    inEditMode, 
    addTextArea,
    checkOuputs,
    textAreaRef,
    addFormData,
    focusOnField,
    setInEditMode,
    editContactId,
    initialOutputs,
    setEditContactId,
    setInitialOutputs,
    handleDeleteOutput,
    handleAddFormChange,
  };
}
