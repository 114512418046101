import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Select from "react-select";
import DatePicker from "react-datepicker";
import Checkbox from "@mui/material/Checkbox";
import moment from "moment";
import useAxiosPrivate from "../../../../Hooks/useAxiosPrivate";
import constants from "../../../../constants";
function RejectBulk(Props) {
  const axios = useAxiosPrivate();
  const [rejectedConfirm, setRejectedConfirm] = useState(false);
  const [rejectOption, setRejectOption] = useState("");
  const [selectedTest, setSelectedTest] = useState("");
  const [extendTime, setExtendTime] = useState(0);
  const [extendTimeFailCase, setExtendTimeFailCase] = useState(0);
  const [rejectOptionFailCase, setRejectOptionFailCase] = useState("extsam");
  const [extensionDate, setExtensionDate] = useState("");
  const [selectedDate,setSelectedDate] = useState(new Date());

  const customStylesForPendingTests = {
    control: (base, state) => ({
      ...base,
      width: "246px",
      height: "20px !important",
      color: "000000",
      textAlign: "left",
      borderRadius: "4px",
      paddingLeft: "10px",
      marginBottom: "-5px",
      backgroundColor: "#ffffff",
      border: "1px solid #BDC1C6",
      innerHeight: "20px !important",
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        padding: "8px",
        fontSize: "12px",
        fontWeight: "600",
        textAlign: "left",
        lineHeight: "15px",
        fontFamily: "Inter",
        color: isSelected ? "#000000" : undefined,
        backgroundColor: isSelected
          ? "#ffffff"
          : isFocused
          ? "#e9ecef"
          : undefined,
      };
    },
    singleValue: (styles) => ({
      ...styles,
      color: "#000000",
      fontSize: "12px",
      fontWeight: "600",
      lineHeight: "15px",
      fontFamily: "Inter",
      fontStyle: "normal",
      textAlign: "center",
      width: "max-content",
    }),
    placeholder: (base) => ({
      ...base,
      fontSize: "14px",
      color: "#BDC1C6",
      fontWeight: "400",
      lineHeight: "20px",
      fontFamily: "Inter",
      fontStyle: "normal",
    }),
    menuList: (base) => ({
      ...base,
      height: "150px",
    }),
  };

  useEffect(() => {
    console.log("Props.pendingTestLists", Props.pendingTestLists);
  }, []);

  const handleCancel = () => {
    Props.setShowReject(false);
    Props.setSelectedUsers([]);
  };
  const handleReject = async() => {
    // console.log("rejectOption",rejectOption);
    // console.log("rejectOptionFailCase",rejectOptionFailCase);
    // console.log("extendTime",extendTime);
    // console.log("extendTimeFailCase",extendTimeFailCase);
    // console.log("selectedTest",selectedTest);
    // console.log("extensionDate",extensionDate);
    // console.log("Props.testData",Props.testData);
    // console.log("Props.selectedUsers",Props.selectedUsers);
    let selectedTestDetails = Props.testData.find(i=>i.testid == selectedTest.value);
    let objForRejectBulk ={};
    objForRejectBulk.learnerid = Props.selectedUsers;
    objForRejectBulk.exdate = extensionDate;
    objForRejectBulk.reset = rejectOption ==="restart";
    if(rejectOption === "givetime"){
      objForRejectBulk.timeleft = parseInt(extendTime) *60;
    }
    if(rejectOption ==="restart" && rejectOptionFailCase==="givetime"){
      objForRejectBulk.timeleft=  parseInt(extendTimeFailCase)*60;

    }
    objForRejectBulk.testid= selectedTestDetails.testid;
    objForRejectBulk.libtypeid = selectedTestDetails.testtype;
    objForRejectBulk.lpconfigid = selectedTestDetails.lpconfigid;
    objForRejectBulk.lpid = selectedTestDetails.lpid;
    console.log("objForRejectBulk",objForRejectBulk);
    // api/node/admin/users/resubmit post
    let response = await axios.post("node/admin/users/resubmit",objForRejectBulk,
      {
        headers:{
          "Content-Type": "application/json",
        }
      }
    );
    console.log("response+++",response.data);
    if(response.data.resultCode === constants.RESULT_STATUS.SUCCESS){
      // show popup with success message;
      Props.setShowReject(false);
      Props.setSelectedUsers([]);

    }
    else{

      // show popup with error warning

    }
  };

  const handleRejectOptionChnage = (e, n) => {
    if (n === 1) {
      setRejectOption(e.target.value);
    } else {
      setRejectOptionFailCase(e);
    }
  };

  const handleGivenTime = (e, n) => {
    if (n === 1) {
      setExtendTime(e);
      // setExtendTimeFailCase(0);
    } else {
      setExtendTimeFailCase(e);
      // setExtendTime(0);
    }
  };

  const handleTestChange = (e) => {
    console.log("eeee", e);
    setSelectedTest(e);
  };

  const handleRejectConfirm = () => {
    setRejectedConfirm((rejectedConfirm) => !rejectedConfirm);
  };

  const handleExtendedDueDate = (date) => {
    console.log(date);
    setSelectedDate(date);
    setExtensionDate(moment(date).format("YYYY-MM-DD HH:mm:ss"));
  };
  return (
    <div
      style={{
        top: "50px",
        zIndex: 4,
        right: "10px",
        display: "block",
        position: "absolute",
      }}
      className="rejectBulkUsers"
    >
      <Box
        sx={{
          p: 3,
          boxShadow: 2,
          width: "40vw",
          borderRadius: 3,
          bgcolor: "#FFFFFF",
          overflow: "scroll",
          height: "85vh",
        }}
      >
        <label className="pendingtestlabel">Tests</label>
        <Select
          // isDisabled
          name="pendingtests"
          options={Props.pendingTestLists}
          id="pendingtestsOfLearners"
          styles={customStylesForPendingTests}
          placeholder="Select Test"
          value={selectedTest}
          components={{
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null,
          }}
          onChange={
            (selectedTest) => handleTestChange(selectedTest)
            // console.log("selectedTest", selectedTest)
          }
        />

        <h4
          // style={{ position: "absolute", left: "24px", top: "65px" }}
          className="rejectOptionsforBulkUsers"
        >
          Reject options
        </h4>
        <div className="rejectOptionContainer">
          <div>
            <input
              type="radio"
              value="givetime"
              name="rejectopt"
              className="options-text-radio"
              checked={rejectOption === "givetime"}
              onChange={(e) => handleRejectOptionChnage(e, 1)}
            />{" "}
            &nbsp;Extend with given time &nbsp;{" "}
            <input
              type="number"
              value={extendTime}
              //onChange={(e) => handleGivenTime(e, 1)}
              onChange={(e)=>{setExtendTime(e.target.value)}}
              disabled={rejectOption !== "givetime"}
              className="options-text-input-givetime"
            />{" "}
            min
          </div>
          <div>
            <input
              type="radio"
              value="extsam"
              name="rejectopt"
              className="options-text-radio"
              checked={rejectOption === "extsam"}
              onChange={(e) => handleRejectOptionChnage(e, 1)}
            />{" "}
            &nbsp;Extend with remaining time{" "}
          </div>
          <div>
            <input
              type="radio"
              value="restart"
              name="rejectopt"
              className="options-text-radio"
              checked={rejectOption === "restart"}
              onChange={(e) => handleRejectOptionChnage(e, 1)}
            />{" "}
            &nbsp;Retake with new questions{" "}
          </div>
          {rejectOption == "restart" ? (
            <div className="nestedCheckOptionforReject">
              <div className="nestedOption">
                <Checkbox
                  name="rejectopt"
                  value="givetime"
                  checked={rejectOptionFailCase === "givetime"}
                  onChange={(e) => handleRejectOptionChnage("givetime", 2)}
                />
                {/* <input type="radio"
               value="givetime"
               name="rejectopt"
               className="options-text-radio"
               checked={rejectOptionFailCase === "givetime"}
               onChange={(e) => handleRejectOptionChnage(e, 2)}

                /> */}
                &nbsp;Extend with given time
                <input
                  type="number"
                  value={extendTimeFailCase}
                  //onChange={(e) => handleGivenTime(e, 2)}
                  onChange={(e)=>{setExtendTimeFailCase(e.target.value)}}
                  disabled={rejectOptionFailCase !== "givetime"}
                  className="options-text-input-givetime"
                />{" "}
                min
              </div>
              <div className="nestedOption">
                <Checkbox
                  name="rejectopt"
                  value="extsam"
                  checked={rejectOptionFailCase === "extsam"}
                  onChange={(e) => handleRejectOptionChnage("extsam", 2)}
                />
                {/* <input
                  type="radio"
                  value="extsam"
                  name="rejectopt"
                  className="options-text-radio"
                  checked={rejectOptionFailCase === "extsam"}
                  onChange={(e) => handleRejectOptionChnage(e, 2)}
                /> */}{" "}
                &nbsp;Extend with remaining time{" "}
              </div>
            </div>
          ) : null}
        </div>
        <div className="selectExtendlabel">Select extended Deadline</div>
        <div className="rejectbulkDatePicker">
          <DatePicker
            showIcon
            showTimeInput
            //   disabled={!extension}
            timeInputLabel="Time:"
            className="codingDatePicker"
            dateFormat="dd/MMM/yyyy h:mm aa"
            selected={selectedDate}
            onChange={(date) => handleExtendedDueDate(date)}
            //   minDate={new Date(props.minExtenDate)}
          />
        </div>

        <div className="rejectBulk-save-section">
          <span>
            <Checkbox
              checked={rejectedConfirm}
              disabled={rejectOption === ""}
              onChange={() => handleRejectConfirm(!rejectedConfirm)}
              inputProps={{ "aria-label": "controlled" }}
            />

            <span className="confirmselectedOptionlabel">
              Confirm selected option
            </span>
          </span>
        </div>
        <div className="rejectBulkbtnsec">
          <button
            //style={{ position: "absolute", bottom: "10px" }}
            className="btnMedium"
            onClick={handleCancel}
          >
            {" "}
            Cancel
          </button>
          <button
            //style={{ position: "absolute", bottom: "10px", left: "130px" }}
            className="btnMedium"
            onClick={handleReject}
            disabled={selectedTest==""}
          >
            {" "}
            Reject
          </button>
        </div>
      </Box>
    </div>
  );
}

export default RejectBulk;
