import useAxiosPrivate from "./useAxiosPrivate";

const useAddOrUpdateLp = () => {
  const axios = useAxiosPrivate();
  const apiCall = (specs) => {
    const payload = {
      timeout: 30000,
      mode: "no-cors",
      method: specs.method,
      url: getAPIurl(specs),
      headers: {
        "Content-type": "application/json",
      },
    };

    if (
      (specs.body.language === "java" ||
        specs.body.language === "javascript" ||
        specs.body.language === "c" ||
        specs.body.language === "cpp" ||
        specs.body.language === "python") &&
      (specs.body.type === "runcode" ||
        specs.body.type === "submitcode" ||
        specs.body.type === "testcode")
    ) {
      payload.data = specs.body;
    }

    return new Promise((resolve, reject) => {
      axios(payload)
        .then((result) => {
          if (result.data) {
            resolve(result.data);
          }

          if (result.data.message) {
            resolve(result.data.message);
          }
        })
        .catch((error) => {
          if (error) {
            reject(error);
          }

          reject(error);
        });
    });
  };

  const getAPIurl = (specs) => {
    if (specs.method === "post") {
      if (specs.language) {
        if (
          specs.language === "javascript" &&
          (specs.api === "runcode" || specs.api === "testcode")
        ) {
          return process.env.REACT_APP_NODE_API + "node/learner/code/js/test";
        }

        if (specs.language === "javascript" && specs.api === "submitcode") {
          return process.env.REACT_APP_NODE_API + "node/learner/code/submit";
        }

        if (specs.language === "c" && specs.api === "submitcode") {
          return (
            process.env.REACT_APP_CONTAINER_API + "node/learner/code/c/submit"
          );
        }

        if (specs.language === "cpp" && specs.api === "submitcode") {
          return (
            process.env.REACT_APP_CONTAINER_API + "node/learner/code/cpp/submit"
          );
        }

        if (specs.language === "python" && specs.api === "submitcode") {
          return (
            process.env.REACT_APP_CONTAINER_API +
            "node/learner/code/python/submit"
          );
        }

        if (specs.language === "java" && specs.api === "submitcode") {
          return (
            process.env.REACT_APP_CONTAINER_API +
            "node/learner/code/java/submit"
          );
        }

        if (
          specs.language === "java" &&
          (specs.api === "runcode" || specs.api === "testcode")
        ) {
          return (
            process.env.REACT_APP_CONTAINER_API + "node/learner/code/java/test"
          );
        }

        if (
          specs.language === "c" &&
          (specs.api === "runcode" || specs.api === "testcode")
        ) {
          return (
            process.env.REACT_APP_CONTAINER_API + "node/learner/code/c/test"
          );
        }

        if (
          specs.language === "cpp" &&
          (specs.api === "runcode" || specs.api === "testcode")
        ) {
          return (
            process.env.REACT_APP_CONTAINER_API + "node/learner/code/cpp/test"
          );
        }

        if (
          specs.language === "python" &&
          (specs.api === "runcode" || specs.api === "testcode")
        ) {
          return (
            process.env.REACT_APP_CONTAINER_API +
            "node/learner/code/python/test"
          );
        }
      }
    }
  };

  return {apiCall,getAPIurl};
};

export default useAddOrUpdateLp;
