import React from "react";
export default function NotificationSvgIcon() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.4417 23.4998C17.2952 23.7524 17.0849 23.962 16.8319 24.1078C16.5788 24.2535 16.292 24.3302 16 24.3302C15.708 24.3302 15.4212 24.2535 15.1681 24.1078C14.9151 23.962 14.7048 23.7524 14.5583 23.4998M21 12.6665C21 11.3404 20.4732 10.0687 19.5355 9.13097C18.5979 8.19329 17.3261 7.6665 16 7.6665C14.6739 7.6665 13.4021 8.19329 12.4645 9.13097C11.5268 10.0687 11 11.3404 11 12.6665C11 18.4998 8.5 20.1665 8.5 20.1665H23.5C23.5 20.1665 21 18.4998 21 12.6665Z"
        stroke="#667085"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <circle className="blinking" cx="20" cy="11" r="3.5" fill="#F79009" stroke="#F2F4F7" />
    </svg>
  );
}
