import "./StudentList.scss";
import React, { useState, useMemo } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
} from "@mui/material";
import Select from "react-select";
import Scrollbars from "react-custom-scrollbars-2";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const InviteesTable = ({ data, selectedRows, setSelectedRows }) => {
  const [searchText, setSearchText] = useState("");
  const [selectedBranches, setSelectedBranches] = useState([]);
  const [selectedColleges, setSelectedColleges] = useState([]);

  const isValidString = (value) => typeof value === 'string' && value.trim() !== '';

  const branchOptions = useMemo(() => {
    const validBranches = [...new Set(data.map((item) => item.branch).filter(isValidString))];
    return validBranches.map(branch => ({
      value: branch,
      label: branch,
    }));
  }, [data]);

  const collegeOptions = useMemo(() => {
    const validColleges = [...new Set(data.map((item) => item.college).filter(isValidString))];
    return validColleges.map(college => ({
      value: college,
      label: college,
    }));
  }, [data]);

  const filteredData = useMemo(() => {
    return data.filter((item) => {
      const matchedBranch = selectedBranches.length === 0 || (isValidString(item.branch) && selectedBranches.includes(item.branch));
      const matchedCollege = selectedColleges.length === 0 || (isValidString(item.college) && selectedColleges.includes(item.college));
      const matchedSearchText = 
        item.firstname.toLowerCase().includes(searchText.toLowerCase()) ||
        item.lastname.toLowerCase().includes(searchText.toLowerCase()) ||
        item.email.toLowerCase().includes(searchText.toLowerCase());

      return matchedBranch && matchedCollege && matchedSearchText;
    });
  }, [data, selectedBranches, selectedColleges, searchText]);

  const handleRowSelect = (id) => {
    setSelectedRows(prev => 
      prev.includes(id) ? prev.filter(rowId => rowId !== id) : [...prev, id]
    );
  };

  const handleSelectAll = (event) => {
    setSelectedRows(event.target.checked ? filteredData.map(row => row.userid) : []);
  };

  const customSelectStyles = {
    control: (provided) => ({
      ...provided,
      outline: "none",
      color: "#373739",
      fontSize: "16px",
      boxShadow: "none",
      fontWeight: "400",
      lineHeight: "150%",
      borderRadius: "4px",
      fontFamily: "Inter",
      fontStyle: "normal",
      margin: "0 0 15px 30px",
      letterSpacing: "0.07px",
      border: "0px solid white",
      backgroundColor: "#F2F3F5",
    }),
    option: (styles, { isDisabled, isFocused }) => ({
      ...styles,
      color: "black",
      zIndex: "2 !important",
      cursor: isDisabled ? "not-allowed" : "default",
      backgroundColor: isFocused ? "white" : "#F2F3F5",
    }),
  };

  return (
    <div className="studentListTable">
      <div style={{ display: "flex", width: "100%", justifyContent: "flex-end" }}>
        <FontAwesomeIcon icon={faSearch} className="adminTestSearchIcon" />
        <input
          type="text"
          id="search"
          name="search"
          value={searchText}
          placeholder="Search"
          className="studentListSearchBox"
          onChange={(e) => setSearchText(e.target.value)}
        />
        <Select
          styles={customSelectStyles}
          options={branchOptions}
          isMulti
          isDisabled={branchOptions.length === 0}
          value={selectedBranches.map(branch => ({ value: branch, label: branch }))}
          onChange={(selectedOptions) => setSelectedBranches(selectedOptions.map(option => option.value))}
          placeholder={branchOptions.length === 0 ? "No branches available" : "Branch"}
          components={{ IndicatorSeparator: () => null }}
        />
        <Select
          styles={customSelectStyles}
          options={collegeOptions}
          isMulti
          isDisabled={collegeOptions.length === 0}
          value={selectedColleges.map(college => ({ value: college, label: college }))}
          onChange={(selectedOptions) => setSelectedColleges(selectedOptions.map(option => option.value))}
          placeholder={collegeOptions.length === 0 ? "No colleges available" : "College"}
          components={{ IndicatorSeparator: () => null }}
        />
      </div>
      <Scrollbars autoHide style={{ width: "100%" }}>
        <TableContainer component={Paper} style={{ fontFamily: "Inter, sans-serif" }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Checkbox
                    checked={selectedRows.length === filteredData.length && filteredData.length > 0}
                    indeterminate={selectedRows.length > 0 && selectedRows.length < filteredData.length}
                    onChange={handleSelectAll}
                    style={{ color: "red" }}
                  />
                </TableCell>
                <TableCell><b>First Name</b></TableCell>
                <TableCell><b>Last Name</b></TableCell>
                <TableCell><b>College</b></TableCell>
                <TableCell><b>Branch</b></TableCell>
                <TableCell><b>Email</b></TableCell>
                <TableCell><b>Mobile Number</b></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredData.map((row) => (
                <TableRow key={row.userid}>
                  <TableCell>
                    <Checkbox
                      checked={selectedRows.includes(row.userid)}
                      onChange={() => handleRowSelect(row.userid)}
                    />
                  </TableCell>
                  <TableCell>{row.firstname}</TableCell>
                  <TableCell>{row.lastname}</TableCell>
                  <TableCell>{isValidString(row.college) ? row.college : '-'}</TableCell>
                  <TableCell>{isValidString(row.branch) ? row.branch : '-'}</TableCell>
                  <TableCell>{row.email}</TableCell>
                  <TableCell>{row.mobile}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbars>
    </div>
  );
};

export default InviteesTable;