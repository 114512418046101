import { useContext } from "react";
import constants from "../constants";
import useAxiosPrivate from "./useAxiosPrivate";
import AlertContext from "../context/AlertContext";
import { getPreSignedUrl, getDataFromStorage } from "../util";
import useCommonFunctionHooks from "./useCommonFunctionHooks";

export default function McqAndDescHooks() {
  const Alert = useContext(AlertContext);

  const {
    lpId,
    status,
    testData,
    setIsLoaded,
    setShowNotify,
    setResultCode,
    setIsTakeTest,
    setButtonDisable,

    // mcq

    queLen,
    secLen,
    navigate,
    duration,
    interval,
    setQueLen,
    setSecLen,
    setSecObj,
    setQueObj,
    setOptObj,
    setQueInd,
    setSecInd,
    comingObj,
    setTestMode,
    hasRendered,
    setAnsStats,
    mcqTestData,
    setApiLoaded,
    setTestStats,
    setNextButton,
    setDescScores,
    setLpConfigId,
    setPrevButton,
    setMcqTestData,
    updateComingObj,
    backupTestDataq,
    questionListObj,
    setTestPageShow,
    setTotalSecScore,
    setTotalTestScore,
    setBackupTestData,
    setTotalLearnerScore,
    sectionWithQuestions,
    setTakeMcqAndDesTest,
    setFilterQuestionCount,
    setMcqAndDesTestAndLpId,
    updateQuestionContainerSingle,
    // mcq end
  } = useContext(AlertContext);

  const axios = useAxiosPrivate();
  const { getCatchBlockDetails, getHeaderDetailsForAxios } =
    useCommonFunctionHooks();

  let tempReport,
    tempTq = 0,
    tempQa = 0,
    tempNa = 0,
    tempCa = 0,
    tempWa = 0,
    totalTS = 0,
    totalSS = 0,
    totalLS = 0,
    tempTestData,
    answerObject = {},
    tempScoresObj = {};

  const traverseInitialize = () => {
    setPrevButton(false);
    setNextButton(false);

    if (queLen > 1 || secLen > 1) {
      setNextButton(true);
    }
  };

  const questionUpdate = (secIndex, quesIndex, theObject) => {
    setSecLen(theObject.sections.length);
    setSecInd(secIndex);
    setSecObj(theObject.sections[secIndex]);
    setQueLen(theObject.sections[secIndex].questions.length);
    setQueInd(quesIndex);
    setQueObj(theObject.sections[secIndex].questions[quesIndex]);
    setOptObj(theObject.sections[secIndex].questions[quesIndex]?.options);

    if (
      comingObj.sections?.length === 1 &&
      comingObj.sections[0].questions.length === 1
    ) {
      setPrevButton(false);
      setNextButton(false);
    } else {
      if (secIndex === 0 && quesIndex === 0) {
        setPrevButton(false);
        setNextButton(true);
      } else if (
        secIndex === mcqTestData.sections.length - 1 &&
        quesIndex ===
          mcqTestData.sections[mcqTestData.sections.length - 1].questions
            .length -
            1
      ) {
        setPrevButton(true);
        setNextButton(false);
      } else {
        setNextButton(true);
        setPrevButton(true);
        return;
      }
    }
  };

  const calcStats = (tempReport, sec) => {
    let actSec = 0;
    let secArr = [];
    let allQIids = [];
    let evalIds = [];
    let idScore = {};
    let allSec = false;
    let notEvalIds = [];

    for (let i = 0; i < tempReport.sections.length; i++) {
      secArr.push(i);
    }

    if (sec[0] === "all") {
      allSec = true;
      sec = secArr;
      actSec = sec.length;
    }

    for (let i = 0; i < tempReport.sections.length; i++) {
      if (sec.includes(i)) {
        for (let j = 0; j < tempReport.sections[i].questions.length; j++) {
          let questOnJ = tempReport.sections[i].questions[j];
          let score = questOnJ.actualscore;

          tempScoresObj = {
            ...tempScoresObj,
            [tempReport.sections[i].questions[j].id]: score,
          };

          tempTq++;
          allQIids.push(tempReport.sections[i].questions[j].id);

          if (
            tempReport.sections[i].questions[j].actualscore == null ||
            tempReport.sections[i].questions[j].hasOwnProperty(
              "actualscore"
            ) === false
          ) {
            notEvalIds.push(tempReport.sections[i].questions[j].id);
          } else if (tempReport.sections[i].questions[j].actualscore != null) {
            evalIds.push(tempReport.sections[i].questions[j].id);
            idScore = {
              ...idScore,
              [tempReport.sections[i].questions[j].id]:
                tempReport.sections[i].questions[j].actualscore,
            };
          }

          if (allSec) {
            totalTS = totalTS + tempReport.sections[i].questions[j].targetscore;
          }

          totalSS = totalSS + tempReport.sections[i].questions[j].targetscore;
          totalLS = totalLS + tempReport.sections[i].questions[j].actualscore;

          if (
            tempReport.sections[i].questions[j].answer === "" ||
            tempReport.sections[i].questions[j].answer == null
          ) {
            tempNa++;
          } else {
            tempQa++;
          }

          if (
            tempReport.sections[i].questions[j].targetscore ===
            tempReport.sections[i].questions[j].actualscore
          ) {
            tempCa++;
          } else {
            tempWa++;
          }
        }
      }
    }
    setTestStats({
      tq: tempTq,
      qa: tempQa,
      na: tempNa,
      ca: tempCa,
      wa: tempWa,
    });

    if (allSec) {
      setTotalTestScore(totalTS);
    }

    setFilterQuestionCount(tempTq);
    setTotalSecScore(totalSS);
    setTotalLearnerScore(totalLS);

    if (
      (sec[0] === "all" && sec.length > 1) ||
      actSec === 1 ||
      status === constants.PROGRESS_STATUS.TO_BE_EVALUATED ||
      status === constants.PROGRESS_STATUS.PARTIAL_EVALUATED
    ) {
      setAnsStats({
        allids: allQIids,
        evalids: evalIds,
        idscore: idScore,
        notevalids: notEvalIds,
      });
    }
  };

  // const assignQuestionIds = (dataArr, sections1) => {
  //   let arrTobeAssignIds = [];
  //   let arrayOfquestions = JSON.parse(JSON.stringify(sections1));
  //   let allQuestions = arrayOfquestions.map((item, index) => {
  //     dataArr.map((section, ind) => {
  //       let currentSection = {};
  //       currentSection.secName = section.secName;
  //       currentSection.secId = section.secId;
  //       currentSection.qnsObj = section.qnsObj;
  //       let sections = item.questions;
  //       const qobject = section.qnsObj;
  //       Object.keys(qobject).forEach((key) => {
  //         const obj = qobject[key];
  //         const matchIndex = sections.findIndex(
  //           (src) =>
  //             obj.topicId == src.topicid && obj.complexityId == src.complexityid
  //         );
  //         if (matchIndex != -1) {
  //           obj.qId = sections[matchIndex].id;
  //           sections.splice(matchIndex, 1);
  //         }
  //         currentSection.qnsObj[key] = obj;
  //       });
  //       arrTobeAssignIds.push(currentSection);
  //     });
  //   });
  //   return arrTobeAssignIds;
  // };

  const assignQuestionIds = (dataArr, sections1) => {
    return dataArr.map((section) => {
      const currentSection = {
        secName: section.secName,
        secId: section.secId,
        qnsObj: { ...section.qnsObj },
      };

      const relevantSection = sections1.find(
        (s) => s.sectionid === section.secId
      );
      if (relevantSection) {
        Object.keys(currentSection.qnsObj).forEach((key, index) => {
          const obj = currentSection.qnsObj[key];
          const matchedQuestion = relevantSection.questions[index];

          if (matchedQuestion) {
            obj.qId = matchedQuestion.id;
          }
        });
      }

      return currentSection;
    });
  };

  const handleVideoQuestions = (dataOfSections) => {
    // let dataOfSections = response.data.data.sections;
    let mArr = [];
    let questionList = dataOfSections.map((item, ind) => {
      let cc = 1;
      let MqObj = {
        secId: item.sectionid,
        secName: item.sectionname,
        qnsObj: {},
      };

      item.qnconfig.map((i) => {
        for (let j = 0; j < i.qncount + i.fixedqncount; j++) {
          let qObj = {};
          qObj.qId = "";
          qObj.isAnswered = false;
          qObj.qnduration = i.qnduration / (i.qncount + i.fixedqncount);
          qObj.marks = i.marks;
          qObj.complexityId = i.complexityid;
          qObj.topicId = i.topicid;
          MqObj.qnsObj[cc] = qObj;
          cc++;
        }
        return "";
      });

      mArr.push(MqObj);

      return "";
    });

    let videoQuestionsObj = assignQuestionIds(mArr, dataOfSections);
    // setQuestionListObj(videoQuestionsObj);
    questionListObj.current = videoQuestionsObj;
    sectionWithQuestions.current = dataOfSections;
    // setSectionWithQuestions(dataOfSections);
  };

  const startTest = () => {
    setLpConfigId(Alert.testData.lpconfigid);
    Alert.setMcqAndDesTestAndLpId({
      lpid: lpId,
      status: status,
      testid: Alert.testData.testid,
      cellobj: Alert.testData.libtype,
      autoEval: Alert.testData.autoeval,
      testmode: Alert.testData.testmode,
      viewTest: Alert.testData.viewTest,
      libtypeid: Alert.testData.testtype,
      proctored: Alert.testData.proctored,
      configdata: Alert.testData.configdata,
      lpconfigid: Alert.testData.lpconfigid,
      learnerid: getDataFromStorage("learnerid"),
    });

    Alert.setTestPageShow(true);
    setTakeMcqAndDesTest(true);

    if (
      status === constants.PROGRESS_STATUS.UNLOCKED ||
      status === constants.PROGRESS_STATUS.REATTEMPT
    ) {
      Alert.setIsLoaded(true);
      Alert.setButtonDisable(true);
      axios
        .post(
          "node/learner/course/test/get",
          JSON.stringify({
            lpid: lpId,
            isTakingTest: true,
            testid: Alert.testData.testid,
            mockuse: Alert.testData.mockuse,
            libtypeid: Alert.testData.testtype,
            lpconfigid: Alert.testData.lpconfigid,
            mocklp: localStorage.getItem("mockLp"),
            learnerid: getDataFromStorage("learnerid"),
          }),
          getHeaderDetailsForAxios()
        )
        .then((res) => {
          (async () => {
            try {
              setResultCode(res.data.resultCode);

              if (res.data.resultCode !== constants.RESULT_STATUS.SUCCESS) {
                setShowNotify({
                  show: true,
                  title: "Info",
                  msg: res.data.msg,
                });
              } else {
                tempTestData = res.data.data;

                if (tempTestData.videoqn === 1) {
                  handleVideoQuestions(tempTestData.sections);
                }

                for (let i = 0; i < tempTestData.sections.length; i++) {
                  for (
                    let j = 0;
                    j < tempTestData.sections[i].questions.length;
                    j++
                  ) {
                    if (status === constants.PROGRESS_STATUS.QN_CONTINUE) {
                      if (
                        tempTestData.sections[i].questions[j].answer === "" ||
                        tempTestData.sections[i].questions[j].answer === null ||
                        tempTestData.sections[i].questions[j].answer ===
                          undefined
                      ) {
                        tempTestData.sections[i].questions[j].choosed = -1;
                        tempTestData.sections[i].questions[j].answered = -1;
                        tempTestData.sections[i].questions[j].visited =
                          i === 0 && j === 0 ? "this" : "new";
                        tempTestData.sections[i].questions[j].review = -1;
                        tempTestData.sections[i].questions[j].previsited =
                          i === 0 && j === 0 ? "this" : "new";
                      } else {
                        tempTestData.sections[i].questions[j].visited =
                          "completed";
                        tempTestData.sections[i].questions[j].previsited =
                          "completed";
                        tempTestData.sections[i].questions[j].answered = 0;
                      }

                      answerObject = {
                        ...answerObject,
                        [tempTestData.sections[i].questions[j].id]:
                          tempTestData.sections[i].questions[j].answer,
                      };
                    } else {
                      tempTestData.sections[i].questions[j].choosed = -1;
                      tempTestData.sections[i].questions[j].answered = -1;
                      tempTestData.sections[i].questions[j].visited =
                        i === 0 && j === 0 ? "this" : "new";
                      tempTestData.sections[i].questions[j].review = -1;
                      tempTestData.sections[i].questions[j].previsited =
                        i === 0 && j === 0 ? "this" : "new";

                      answerObject = {
                        ...answerObject,
                        [tempTestData.sections[i].questions[j].id]: "",
                      };
                    }

                    if (process.env.REACT_APP_DEPLOYMENT === "AWS") {
                      let question = tempTestData.sections[i].questions[j];

                      for (let k = 0; k < question.links.length; k++) {
                        let linkRes = await getPreSignedUrl(
                          question.links[k].link
                        );

                        question.links[k].link = linkRes;
                      }
                    }
                  }
                }

                Alert.setAnswers(answerObject);
                Alert.setMcqTestData(tempTestData);
                Alert.updateComingObj(tempTestData);
                questionUpdate(0, 0, tempTestData);
                Alert.updateQuestionContainerSingle(true);
                traverseInitialize();
                Alert.setTestMode(res.data.data.testmode);
                duration.current = tempTestData.duration;

                if (
                  Alert.testData.proctored ===
                    constants.PROCTORING_MODE.TAB_CAM &&
                  Alert.testData.testmode === constants.MODE.TEST_MODE
                ) {
                  setIsTakeTest(true);
                }

                navigate("/mcq");
              }
            } catch (err) {
              getCatchBlockDetails(err);
            } finally {
              Alert.setIsLoaded(false);
              Alert.setButtonDisable(false);
            }
          })();
        })
        .catch((error) => {
          getCatchBlockDetails(error);
        })
        .finally(() => {
          Alert.setIsLoaded(false);
          Alert.setButtonDisable(false);
        });
    }

    if (status === constants.PROGRESS_STATUS.QN_CONTINUE) {
      Alert.hasRendered.current = true;
      Alert.setIsLoaded(true);
      Alert.setButtonDisable(true);
      axios
        .post(
          "node/learner/course/test/report",
          JSON.stringify({
            lpid: lpId,
            isTakingTest: true,
            lmpid: Alert.testData.lmpid,
            testid: Alert.testData.testid,
            mockuse: Alert.testData.mockuse,
            libtypeid: Alert.testData.testtype,
            lpconfigid: Alert.testData.lpconfigid,
            mocklp: localStorage.getItem("mockLp"),
            learnerid: getDataFromStorage("learnerid"),
          }),
          getHeaderDetailsForAxios()
        )
        .then((res) => {
          Alert.hasRendered.current = false;
          Alert.backupTestDataq.current = res.data.data;

          (async () => {
            try {
              if (res.data.resultCode !== constants.RESULT_STATUS.SUCCESS) {
                setShowNotify({
                  show: true,
                  title: "Info",
                  msg: res.data.message,
                });
              } else {
                tempTestData = res.data.data;

                if (tempTestData.videoqn === 1) {
                  handleVideoQuestions(tempTestData.sections);
                }

                for (let i = 0; i < tempTestData.sections.length; i++) {
                  for (
                    let j = 0;
                    j < tempTestData.sections[i].questions.length;
                    j++
                  ) {
                    if (status === constants.PROGRESS_STATUS.QN_CONTINUE) {
                      tempTestData.sections[i].questions[j].review = -1;

                      if (
                        tempTestData.sections[i].questions[j].answer === "" ||
                        tempTestData.sections[i].questions[j].answer === null ||
                        tempTestData.sections[i].questions[j].answer ===
                          undefined
                      ) {
                        tempTestData.sections[i].questions[j].choosed = -1;
                        tempTestData.sections[i].questions[j].answered = -1;
                        tempTestData.sections[i].questions[j].visited =
                          i === 0 && j === 0 ? "this" : "new";
                        tempTestData.sections[i].questions[j].review = -1;
                        tempTestData.sections[i].questions[j].previsited =
                          i === 0 && j === 0 ? "this" : "new";
                      } else {
                        tempTestData.sections[i].questions[j].visited =
                          "completed";
                        tempTestData.sections[i].questions[j].previsited =
                          "completed";
                        tempTestData.sections[i].questions[j].answered = 0;
                      }

                      answerObject = {
                        ...answerObject,
                        [tempTestData.sections[i].questions[j].id]:
                          tempTestData.sections[i].questions[j].answer,
                      };
                    } else {
                      tempTestData.sections[i].questions[j].choosed = -1;
                      tempTestData.sections[i].questions[j].answered = -1;
                      tempTestData.sections[i].questions[j].visited =
                        i === 0 && j === 0 ? "this" : "new";
                      tempTestData.sections[i].questions[j].review = -1;
                      tempTestData.sections[i].questions[j].previsited =
                        i === 0 && j === 0 ? "this" : "new";

                      answerObject = {
                        ...answerObject,
                        [tempTestData.sections[i].questions[j].id]: "",
                      };
                    }

                    if (process.env.REACT_APP_DEPLOYMENT === "AWS") {
                      let question = tempTestData.sections[i].questions[j];

                      for (let k = 0; k < question.links.length; k++) {
                        let linkRes = await getPreSignedUrl(
                          question.links[k].link
                        );

                        question.links[k].link = linkRes;
                      }
                    }
                  }
                }

                Alert.setAnswers(answerObject);
                Alert.setMcqTestData(tempTestData);
                Alert.updateComingObj(tempTestData);
                questionUpdate(0, 0, tempTestData);
                Alert.updateQuestionContainerSingle(true);
                traverseInitialize();
                Alert.setTestMode(res.data.data.testmode);
                duration.current = tempTestData.timeleft;

                if (
                  Alert.testData.proctored ===
                    constants.PROCTORING_MODE.TAB_CAM &&
                  Alert.testData.testmode === constants.MODE.TEST_MODE
                ) {
                  setIsTakeTest(true);
                }

                navigate("/mcq");
              }
            } catch (err) {
              getCatchBlockDetails(err);
            } finally {
              Alert.setIsLoaded(false);
              Alert.setButtonDisable(false);
            }
          })();
        })
        .catch((error) => {
          getCatchBlockDetails(error);
        })
        .finally(() => {
          Alert.setIsLoaded(false);
          Alert.setButtonDisable(false);
        });
    }
  };

  const viewTest = () => {
    setTakeMcqAndDesTest(true);

    if (
      status === constants.PROGRESS_STATUS.EVALUATED ||
      status === constants.PROGRESS_STATUS.TO_BE_EVALUATED ||
      status === constants.PROGRESS_STATUS.PARTIAL_EVALUATED
    ) {
      setMcqAndDesTestAndLpId({
        lpid: lpId,
        status: status,
        testid: testData.testid,
        cellobj: testData.libtype,
        autoEval: testData.autoeval,
        testmode: testData.testmode,
        viewTest: testData.viewtest,
        libtypeid: testData.testtype,
        proctored: testData.proctored,
        configdata: testData.configdata,
        lpconfigid: testData.lpconfigid,
        learnerid: getDataFromStorage("learnerid"),
      });

      if (hasRendered.current === false) {
        hasRendered.current = true;

        setButtonDisable(true);
        setIsLoaded(true);
        axios
          .post(
            "node/learner/course/test/report",
            JSON.stringify({
              lpid: lpId,
              lmpid: testData.lmpid,
              testid: testData.testid,
              mockuse: testData.mockuse,
              libtypeid: testData.libtypeid,
              lpconfigid: testData.lpconfigid,
              learnerid: getDataFromStorage("learnerid"),
            }),
            getHeaderDetailsForAxios()
          )
          .then((res) => {
            setButtonDisable(false);
            hasRendered.current = false;
            backupTestDataq.current = res.data.data;
            res.data.data.mockuse = testData.mockuse;

            (async () => {
              try {
                if (res.data.resultCode !== constants.RESULT_STATUS.SUCCESS) {
                  setShowNotify({
                    show: true,
                    title: "Info",
                    msg: res.data.message,
                  });
                } else {
                  tempReport = res.data.data;

                  if (tempReport.videoqn === 1) {
                    handleVideoQuestions(tempReport.sections);
                  }

                  for (let i = 0; i < res.data.data.sections.length; i++) {
                    for (
                      let j = 0;
                      j < res.data.data.sections[i].questions.length;
                      j++
                    ) {
                      if (process.env.REACT_APP_DEPLOYMENT === "AWS") {
                        let question = res.data.data.sections[i].questions[j];

                        if (getDataFromStorage("role") === "learner") {
                          question.review = -1;
                        }

                        for (let k = 0; k < question.links.length; k++) {
                          let linkRes = await getPreSignedUrl(
                            question.links[k].link
                          );

                          question.links[k].link = linkRes;
                        }

                        if (tempReport.videoqn === 1) {
                          if (
                            res.data.data.sections[i].questions[j].answer !==
                              "" &&
                            res.data.data.sections[i].questions[j].answer !=
                              null
                          ) {
                            let videoRes = await getPreSignedUrl(
                              res.data.data.sections[i].questions[j].answer
                            );
                            res.data.data.sections[i].questions[j].answer =
                              videoRes;
                          }
                        }
                      }
                    }
                  }

                  calcStats(tempReport, ["all"]);
                  setTestMode(res.data.data.testmode);
                  setApiLoaded(true);
                  backupTestDataq.current = res.data.data;
                  setDescScores(tempScoresObj);
                  setBackupTestData(res.data.data);
                  setMcqTestData(res.data.data);
                  updateComingObj(res.data.data);
                  updateQuestionContainerSingle(false);
                  clearInterval(interval.current);
                  setIsLoaded(false);
                  setTestPageShow(true);
                  navigate("/mcq");
                }
              } catch (err) {
                getCatchBlockDetails(err);
              } finally {
                setIsLoaded(false);
              }
            })();
          })
          .catch((error) => {
            getCatchBlockDetails(error);
          })
          .finally(() => setIsLoaded(false));
      }
    }
  };

  return {
    interval,
    calcStats,
    viewTest,
    startTest,
    questionUpdate,
    handleVideoQuestions,
  };
}
