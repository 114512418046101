import "./PushNotification.scss";
import AlertContext from "./context/AlertContext";
import useAxiosPrivate from "./Hooks/useAxiosPrivate";
import React, { useContext, useEffect, useState } from "react";

function PushNotification({ notificationData, setNotificationData, setOpen }) {
  const { setShow, setIsUnAuth, setMsg, setTitle,setShowBlink } = useContext(AlertContext);

  const axios = useAxiosPrivate();

  const [date, setDate] = useState(
    notificationData?.data?.notification?.body.split("\n")[0]
  );
  const [jwtToken, setJwtToken] = useState(
    notificationData?.data?.data?.jwtToken
  );
  const [time, setTime] = useState(
    notificationData?.data?.notification?.body.split("\n")[1]
  );
  const [body, setBody] = useState(
    notificationData?.data?.notification?.body.split("\n")[2]
  );

  const requestData = {
    jwtToken: jwtToken,
  };

  const markAsPresent = async () => {
    try {
      const response = await axios.put(
        `node/learner/attendance/record/${1}`,
        requestData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      setNotificationData({ ...notificationData, open: false });

      if (response.data?.message === "token expired") {
        setNotificationData({
          open: true,
          msg: `As you didn't mark attendance within the time limit,\nYour attendance is recorded as Absent.`,
        });
      } else {
        setNotificationData({
          open: true,
          msg: "Your attendance is recorded as present.",
        });
        setShowBlink(false);
      }
    } catch (err) {
      setNotificationData({ ...notificationData, open: false });

      if (err.message.includes("403")) {
        setShow(true);
        setTitle("Error");
        setIsUnAuth(true);
        setMsg("You have been logged-out due to inactivity. Login again.");
      } else {
        setShow(true);
        setTitle("Error");
        setMsg("Error While Responding!");
      }
    }
  };

  const markAsAbsent = async () => {
    try {
      const response = await axios.put(
        `node/learner/attendance/record/${2}`,
        requestData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      setNotificationData({ ...notificationData, open: false });
      
      if (response.data?.message === "token expired") {
        setNotificationData({
          open: true,
          msg: `As you didn't mark attendance within the time limit,\nYour attendance is recorded as Absent.`,
        });
      } else {
        setNotificationData({
          open: true,
          msg: "Your attendance is recorded as Absent.",
        });
        setShowBlink(false);
      }
    } catch (err) {
      setNotificationData({ ...notificationData, open: false });

      if (err.message.includes("403")) {
        setShow(true);
        setTitle("Error");
        setIsUnAuth(true);
        setMsg("You have been logged-out due to inactivity. Login again.");
      }
    }
  };

  return (
    <div
      className={
        notificationData?.msg === "Your attendance is recorded as present."
          ? "notificationforpresent"
          : "notification"
      }
    >
      <div className="notifyBody">
        {/* {notificationData?.data?.notification?.title && ( */}
        <p className="notifyPara">Attendance</p>
        {/* )} */}

        {notificationData?.data?.notification?.title && (
          <h5 className="notifyHeading">
            {notificationData?.data?.notification?.title.split("\n")[1]}
          </h5>
        )}
        {notificationData?.msg && (
          <button
            className="close-button"
            onClick={() =>
              setNotificationData({ ...notificationData, open: false })
            }
          >
            X
          </button>
        )}

        {notificationData?.data && (
          <>
            {" "}
            <p className="notifyPara">{date}</p>
            <p className="notifyPara">{time}</p>
            <p className="notifyPara">{body}</p>
          </>
        )}
      </div>

      {notificationData?.data && (
        <div className="responseButtons">
          <button className="btnSmallPresent" onClick={markAsPresent}>
            Present
          </button>
          <button className="btnSmallAbsent" onClick={markAsAbsent}>
            Absent
          </button>
          <button
            className="btnSmallIgnore"
            onClick={() =>
              setNotificationData({ ...notificationData, open: false })
            }
          >
            Ignore
          </button>
        </div>
      )}

      {notificationData?.msg && (
        <p className="markMessage"> {notificationData.msg}</p>
      )}
    </div>
  );
}

export default PushNotification;
