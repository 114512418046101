import { Table } from "antd";
import update from "immutability-helper";
import AlertContext from "../../../../context/AlertContext";
import React, { useCallback, useContext, useState } from "react";
import { MockTestPageContext } from "../../../../context/MockTestPageContext";
import MockLearningPathModuleDetailsHooks from "./MockLearningPathModuleDetailsHooks";

export default function MockLearningPathModuleTableHooks() {
  const Alert = useContext(AlertContext);
  const MockTestPageCxt = useContext(MockTestPageContext);

  const MockLearningPathModuleDetailsHks = MockLearningPathModuleDetailsHooks();

  const moduleData = [Table.EXPAND_COLUMN];

  const handleModuleName = (event, moduleid, index) => {
    MockLearningPathModuleDetailsHks.setCursorPointer(
      event.target.selectionStart
    );
    MockTestPageCxt.setModules((modules) =>
      modules.map((module) => {
        if (module.id === moduleid) module.moduleName = event.target.value;

        return module;
      })
    );
  };

  const handleDeleteModule = (record) => {
    MockTestPageCxt.setModules((pre) =>
      pre.filter((module) => module.id !== record.id)
    );
  };

  const handleEnterKey = (event, data) => {
    if (event.keyCode === 13) {
      Alert.setModuleExpandKey([data.key]);
      MockTestPageCxt.setModules((pre) =>
        pre.map((module) => {
          if (module.id === data.id && module.moduleName === "") {
            module.name = "Untitled Module";
          }

          return module;
        })
      );

      MockTestPageCxt.setExpandedRecords(data);
      MockTestPageCxt.setFocus(data.id);
    }
  };

  const movemoduleDetails = useCallback(
    (dragIndex, hoverIndex) => {
      let newLpDetails = JSON.parse(JSON.stringify(Alert.lpData2));

      if (Alert.isOldLpDetail) newLpDetails.status = "update";

      let filteredModule = MockTestPageCxt.modules.filter(
        (module) => module.id === MockTestPageCxt.expandedRecords.id
      );

      const dragRow = filteredModule[0].moduleData[dragIndex];

      let movedModuleData = update(filteredModule[0].moduleData, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragRow],
        ],
      });

      let newDetails = [...MockTestPageCxt.modules],
        orderno = 1;

      for (let i = 0; i < newDetails.length; i++) {
        if (newDetails[i].id === MockTestPageCxt.expandedRecords.id) {
          newDetails[i].moduleData = movedModuleData;

          if (Alert.isOldLpDetail) newDetails[i].moved = "yes";
        }

        for (let k = 0; k < newDetails[i].moduleData.length; k++) {
          newDetails[i].moduleData[k].orderno = orderno;
          orderno++;
        }
      }

      newLpDetails.modules = newDetails;
      MockTestPageCxt.setNewLpDetails(newLpDetails);
      Alert.setLpData2(newLpDetails);
      MockTestPageCxt.setModules(newDetails);
    },
    [MockTestPageCxt.modules, MockTestPageCxt.expandedRecords]
  );

  const ref = React.useCallback(
    (node) => {
      if (node && parseInt(MockTestPageCxt.focus) === parseInt(node.id)) {
        if (node) {
          node.selectionStart = MockLearningPathModuleDetailsHks.cursorPointer;
          node.selectionEnd = MockLearningPathModuleDetailsHks.cursorPointer;
          node.focus();
        }
      }
    },
    [MockTestPageCxt.focus, MockLearningPathModuleDetailsHks.cursorPointer]
  );

  const movemodules = useCallback(
    (dragIndex, hoverIndex) => {
      let newLpDetails = JSON.parse(JSON.stringify(Alert.lpData2));

      if (Alert.isOldLpDetail) newLpDetails.status = "update";

      const dragRow = MockTestPageCxt.modules[dragIndex];

      Alert.setModuleExpandKey([]);

      let movedModule = update(MockTestPageCxt.modules, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragRow],
        ],
      });

      let orderno = 1,
        moduleOrderno = 1;

      for (let i = 0; i < movedModule.length; i++) {
        if (Alert.isOldLpDetail) {
          movedModule[i].moduleorderno = moduleOrderno;
          moduleOrderno++;

          for (let k = 0; k < movedModule[i].moduleData.length; k++) {
            movedModule[i].moduleData[k].moved = "yes";
            movedModule[i].moduleData[k].orderno = orderno;
            orderno++;
          }
        }
      }

      newLpDetails.modules = movedModule;
      MockTestPageCxt.setNewLpDetails(newLpDetails);
      Alert.setLpData2(newLpDetails);
      MockTestPageCxt.setModules(movedModule);
    },
    [MockTestPageCxt.modules]
  );

  return {
    ref,
    moduleData,
    movemodules,
    handleEnterKey,
    handleModuleName,
    movemoduleDetails,
    handleDeleteModule,
  };
}
