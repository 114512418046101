import Webcam from "react-webcam";
import WarningPopup from "./WarningPopup";
import constants from "../../../../constants";
import { useLocation } from "react-router-dom";
import NotifyBody from "../../../Notify/NotifyBody";
import { getDataFromStorage } from "../../../../util";
import AlertContext from "../../../../context/AlertContext";
import useAxiosPrivate from "../../../../Hooks/useAxiosPrivate";
import useUploadLargeFileToS3 from "../../../../Hooks/useUploadLargeFileToS3";
import useCommonFunctionHooks from "../../../../Hooks/useCommonFunctionHooks";
import CodingHeaderLearnerSideHooks from "../../../../Hooks/CodingPage/CodingHeaderLearnerSideHooks";

import React, {
  useRef,
  useState,
  useEffect,
  useContext,
  useCallback,
} from "react";

const videoConstraints = {
  width: 1280,
  height: 720,
  facingMode: "user",
};

const Profile = ({
  ids,
  type,
  seconds,
  details,
  libtypeid,
  isTakeTest,
  questionid,
  setCameraPopup,
  externalDevices,
  setIsNextQuestion,
  setIsFirstLoggedIn,
  setCameraWarningMsg,
}) => {
  const Alert = useContext(AlertContext);

  const axios = useAxiosPrivate();
  const useCommonFunctionHks = useCommonFunctionHooks();
  const { uploadLargeFileToS3 } = useUploadLargeFileToS3();

  const { handleSubmitLearnerCodeEvaluation, submitAnswers } =
    CodingHeaderLearnerSideHooks();

  const { pathname } = useLocation();

  const webcamRef = useRef(null);

  const [openPopup, setOpenPopup] = useState(false);
  const [warningBody, setWarningBody] = useState("");
  const [warningTitle, setWarningTitle] = useState("");
  const [actionType, setActionType] = useState(constants.TEST_ACTIONS.WARN);

  const capture = useCallback(
    (from) => {
      let pictureSrc = webcamRef.current.getScreenshot();

      if (from === "verifypic") {
        handleSavePicture(pictureSrc);
      }
    },
    [webcamRef]
  );

  const handleWebCamError = () => {
    Alert.setCamAvailable(false);
  };

  const b64toBlob = (dataURI) => {
    return new Promise((resolve, reject) => {
      try {
        let byteString = atob(dataURI.split(",")[1]);
        let ab = new ArrayBuffer(byteString.length);
        let ia = new Uint8Array(ab);

        for (let i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i);
        }

        let blobdata = new Blob([ab], { type: "image/png" });

        resolve(blobdata);
      } catch (err) {
        reject(err);
      }
    });
  };

  if (Alert.stopProctoring.current.stop) {
    Alert.stopProctoring.current.flag = Alert.stopProctoring.stop;
  } else if (Alert.showNotify.show && !Alert.showNotify.timer) {
    Alert.stopProctoring.current.flag = Alert.showNotify.show;
  } else {
    Alert.stopProctoring.current.flag = false;
  }

  const handleSavePicture = async (pic) => {
    if (
      !Alert.testData.mockuse &&
      !localStorage.getItem("mockLp") &&
      !Alert.stopProctoring.current.flag
    ) {
      let blob;
      let form = new FormData();

      const d = new Date();

      let time = d.getTime();

      blob = await b64toBlob(pic);

      form.append(
        "images",
        blob,
        getDataFromStorage("learnerid") + `_` + time + `.png`
      );

      let UtcDate = new Date();
      let IstDate = new Date(UtcDate);

      IstDate.setHours(UtcDate.getHours() + 5);
      IstDate.setMinutes(UtcDate.getMinutes() + 30);
      IstDate.setMilliseconds("");

      let timeStamp = IstDate.toISOString().replace(".000", "");
      let imageName = `${getDataFromStorage("learnerid")}_${timeStamp}.png`;

      form.append(
        "lpconfigid",
        parseInt(libtypeid) === constants.libTypeIds.CODING_CHALLENGE
          ? details.lpConfigId
          : Alert.lpConfigId
      );

      form.append("devices", null);
      form.append("lpid", Alert.lpId);
      form.append("type", "verifypic");
      form.append("libtypeid", libtypeid);
      form.append("questionid", questionid);
      form.append("images", blob, imageName);
      if (externalDevices) {
        form.append("externaldevices", externalDevices);
      }
      form.append("learnername", getDataFromStorage("name"));
      form.append("learnerid", getDataFromStorage("learnerid"));

      form.append(
        "testid",
        parseInt(libtypeid) === constants.libTypeIds.CODING_CHALLENGE
          ? details?.secData?.testId
          : questionid
      );

      let filePath = `Proctoring/`,
        fileName = imageName;

      if (libtypeid === constants.libTypeIds.CODING_CHALLENGE)
        filePath += `${
          details?.secData?.testId
        }/${questionid}/${getDataFromStorage("learnerid")}/${fileName}`;
      else if (libtypeid === constants.libTypeIds.MCQ)
        filePath += `${questionid}/${getDataFromStorage(
          "learnerid"
        )}/${fileName}`;
      else
        filePath += `${questionid}/${getDataFromStorage(
          "learnerid"
        )}/${fileName}`;

      form.append("filepath", filePath);

      await uploadLargeFileToS3(blob, filePath, "png");

      let payload = {
        image: pic.split(",")[1],
        batchid: localStorage.getItem("batchid"),
        learnerid: getDataFromStorage("learnerid"),
      };

      await axios
        .post(
          "node/learner/pic/verify",
          payload,
          useCommonFunctionHks.getHeaderDetailsForAxios()
        )
        .then((res) => {
          if (
            !Alert.stopProctoring.current.flag &&
            (res.data.Mobile_Found ||
              res.data.Multi_People_Found ||
              res.data.message === "Face not matched") &&
            res.data.resultCode !== constants.RESULT_STATUS.SUCCESS &&
            res.data.resultCode !== constants.RESULT_STATUS.TIMEOUT
          ) {
            Alert.setShowNotify({
              size: "sm",
              show: true,
              timer: true,
              backdrop: true,
              position: "top",
              hideButton: true,
              title: "Warning",
              msg: (
                <NotifyBody component="proctoringDataWarning" data={res.data} />
              ),
            });
          }

          form.append("procData", JSON.stringify(res.data));
        })
        .catch((error) => {
          console.error("Error:", error);
        });

      axios
        .post(
          "node/learner/verifypic/save",
          form,
          useCommonFunctionHks.getHeaderDetailsForAxios("multipart")
        )
        .then((saveResponse) => {
          setActionType(
            saveResponse.data.liveaction || constants.TEST_ACTIONS.WARN
          );
          setWarningBody(saveResponse.data.actiontext || "");

          if (saveResponse.data.liveaction === constants.TEST_ACTIONS.WARN) {
            setWarningTitle("Warning:");
            setOpenPopup(true);
          }

          if (
            saveResponse.data.liveaction ===
            constants.TEST_ACTIONS.REMOVE_FROM_TEST
          ) {
            setWarningTitle("You were removed from the Test:");
            setOpenPopup(true);
          }

          if (
            saveResponse.data.liveaction ===
            constants.TEST_ACTIONS.REMOVE_FROM_BATCH
          ) {
            setWarningTitle("You are removed from the Batch:");
            setOpenPopup(true);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  useEffect(() => {
    let interval;

    const time = pathname === "/sandpack" ? 30000 : 15000;

    if (isTakeTest) {
      interval = setInterval(() => capture("verifypic"), time);
    } else {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [isTakeTest, pathname, capture]);

  const handleWarningOkBtn = async () => {
    setOpenPopup(false);

    if (actionType) {
      axios.post("node/learner/eventlog", {
        eventtext: warningBody,
        qnid: questionid ?? null,
        userid: getDataFromStorage("learnerid"),
        testid:
          (parseInt(libtypeid) === constants.libTypeIds.CODING_CHALLENGE
            ? details?.secData?.testId
            : questionid) ?? null,
        tag:
          actionType === constants.TEST_ACTIONS.REMOVE_FROM_TEST
            ? "ZOOM_REMOVE_TEST"
            : actionType === constants.TEST_ACTIONS.REMOVE_FROM_BATCH
            ? "ZOOM_REMOVE_BATCH"
            : "ZOOM_WARN",
      });

      if (actionType === constants.TEST_ACTIONS.REMOVE_FROM_TEST) {
        if (parseInt(libtypeid) === constants.libTypeIds.CODING_CHALLENGE) {
          handleSubmitLearnerCodeEvaluation(
            true,
            false,
            true,
            false,
            actionType
          );
        } else if (
          parseInt(libtypeid) === constants.libTypeIds.MCQ ||
          parseInt(libtypeid) === constants.libTypeIds.DESCRIPTIVE
        )
          submitAnswers(true, false, false, actionType);
      } else if (actionType === constants.TEST_ACTIONS.REMOVE_FROM_BATCH) {
        if (parseInt(libtypeid) === constants.libTypeIds.CODING_CHALLENGE) {
          handleSubmitLearnerCodeEvaluation(
            true,
            false,
            true,
            false,
            actionType
          );
        } else if (
          parseInt(libtypeid) === constants.libTypeIds.MCQ ||
          parseInt(libtypeid) === constants.libTypeIds.DESCRIPTIVE
        )
          submitAnswers(true, false, false, actionType);
      }

      setWarningTitle("");
      setWarningBody("");
      setActionType(constants.TEST_ACTIONS.WARN);
    }
  };

  return (
    <div className={`hidDiv ${isTakeTest && "hidingDiv"}`}>
      <div className="camVideo">
        <Webcam
          width={300}
          height={300}
          audio={false}
          ref={webcamRef}
          screenshotFormat="image/jpeg"
          style={{ borderRadius: "30px" }}
          videoConstraints={videoConstraints}
          onUserMediaError={handleWebCamError}
        />
      </div>
      <WarningPopup
        open={openPopup}
        body={warningBody}
        title={warningTitle}
        setOpenPopup={handleWarningOkBtn}
        icon={
          <svg
            width="56"
            height="56"
            fill="none"
            viewBox="0 0 56 56"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect x="4" y="4" width="48" height="48" rx="24" fill="#FEF0C7" />
            <rect
              x="4"
              y="4"
              rx="24"
              width="48"
              height="48"
              strokeWidth="8"
              stroke="#FFFAEB"
            />
            <path
              stroke="#DC6803"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M27.9998 24V28M27.9998 32H28.0098M26.2898 18.86L17.8198 33C17.6451 33.3024 17.5527 33.6453 17.5518 33.9945C17.5508 34.3437 17.6413 34.6871 17.8142 34.9905C17.9871 35.2939 18.2365 35.5467 18.5375 35.7238C18.8385 35.9009 19.1806 35.9961 19.5298 36H36.4698C36.819 35.9961 37.1611 35.9009 37.4621 35.7238C37.7631 35.5467 38.0124 35.2939 38.1854 34.9905C38.3583 34.6871 38.4488 34.3437 38.4478 33.9945C38.4468 33.6453 38.3544 33.3024 38.1798 33L29.7098 18.86C29.5315 18.5661 29.2805 18.3231 28.981 18.1544C28.6814 17.9858 28.3435 17.8972 27.9998 17.8972C27.656 17.8972 27.3181 17.9858 27.0186 18.1544C26.7191 18.3231 26.468 18.5661 26.2898 18.86Z"
            />
          </svg>
        }
      />
    </div>
  );
};

export default Profile;
