import React from 'react';
import InputField from './InputField';
import constants from '../../constants';

export default function Skills(props) {
    return (
    <div>
        {props?.skill && Object.keys(props?.skill)?.length>0 &&  
        <>
        {props?.skill && Object.entries(props?.skill).map(([skillid,skillData])=>{
            let hasRelevantData = skillData.attributes[constants.PROFILE_ATTRIBUTES.ASSIGNMENT_SCORE] || skillData.attributes[constants.PROFILE_ATTRIBUTES.ASSESSMENT_SCORE];
            if (hasRelevantData) {
                return (
                <div className='skill_div'>
                    <div className='skill_name'>{skillData.skillname}</div>
                    <div className='skill_fields_div'>
                    <div className='skill_inputfield'>
                        <div className='agc_div'>
                            <div className='label_gact'>No. of Assignment Submission on time</div>
                            <div className='gcat_input_div'>
                                <input
                                    className='editable_input_span'
                                    type='number'
                                    value={props.getValue(skillData.attributes,constants.PROFILE_ATTRIBUTES.ASSIGNMENT_COMPLETED)}
                                    placeholder={"Enter mark"}
                                    name="aptitude"
                                    onChange={(e)=>props.handleSkillChange(e,skillid,constants.PROFILE_ATTRIBUTES.ASSIGNMENT_COMPLETED, props.getValue(skillData.attributes,constants.PROFILE_ATTRIBUTES.ASSIGNMENT_COMPLETED,"totalCount"))}
                                />
                                <span className='noneditable_input_span'>/ {props.getValue(skillData.attributes,constants.PROFILE_ATTRIBUTES.ASSIGNMENT_COMPLETED,"totalCount")}</span>
                            </div>
                        </div>
                        <div className='agc_div'>
                            <div className='label_gact'>Assignment Marks Total</div>
                            <div className='gcat_input_div'>
                                <input
                                    type='number'
                                    value={props.getValue(skillData.attributes,constants.PROFILE_ATTRIBUTES.ASSIGNMENT_SCORE)}
                                    placeholder={"Enter mark"}
                                    name="grammar"
                                    onChange={(e)=>props.handleSkillChange(e,skillid,constants.PROFILE_ATTRIBUTES.ASSIGNMENT_SCORE, props.getValue(skillData.attributes,constants.PROFILE_ATTRIBUTES.ASSIGNMENT_SCORE,"targetScore"))}
                                    className='editable_input_span'
                                />
                                <span className='noneditable_input_span'>/ {props.getValue(skillData.attributes,constants.PROFILE_ATTRIBUTES.ASSIGNMENT_SCORE,"targetScore")}</span>
                            </div>
                        </div>
                        <div className='agc_div'>
                            <div className='label_gact'>Assessment Marks Total</div>
                            <div className='gcat_input_div'>
                                <input
                                    type='number'
                                    value={props.getValue(skillData.attributes,constants.PROFILE_ATTRIBUTES.ASSESSMENT_SCORE)}
                                    name="coding"
                                    placeholder={"Enter mark"}
                                    onChange={(e)=>props.handleSkillChange(e,skillid,constants.PROFILE_ATTRIBUTES.ASSESSMENT_SCORE, props.getValue(skillData.attributes,constants.PROFILE_ATTRIBUTES.ASSESSMENT_SCORE, "targetScore"))}
                                    className='editable_input_span'
                                />
                                <span className='noneditable_input_span'>/ {props.getValue(skillData.attributes,constants.PROFILE_ATTRIBUTES.ASSESSMENT_SCORE, "targetScore")}</span>
                            </div>
                        </div>
                    </div> 
                    <div className='skillfeedback_div'>
                        <textarea className='skill_feedback' placeholder={`Enter feedback for ${skillData.skillname}, using # for new points`} value={skillData.attributes[constants.PROFILE_ATTRIBUTES.SKILL_FEEDBACK]} onChange={(e)=>props.handleSkillChange(e,skillid,constants.PROFILE_ATTRIBUTES.SKILL_FEEDBACK)} maxLength={"500"} name='skillfeedback'>

                        </textarea>
                    </div>
                    </div>
                </div>
                )
            }
            return null;
        })}
        </>}
    </div>
  )
}
