import React from "react";
import constants from "../../../../../constants";
import ExpandableTableText from "./ExpandableTableText";

export default function ExpandableTable(Props) {
  let showData = Object.values(
    Props.topicData[0].libtypeid === constants.libTypeIds.CODING_CHALLENGE
      ? Props.topicData[0].qnCountJson
      : []
  ).filter((co) => co.isNotEnough === true);

  return (
    <div className="warningData-tooltip">
      {Props.topicData?.map((data, index) => (
        <div key={index}>
          {data.libtypeid === constants.libTypeIds.CODING_CHALLENGE ? (
            <ExpandableTableText data={showData} />
          ) : (
            <div className="warningData-info">
              There are {data.questioncount}
              {data.questioncount > 1 ? " questions" : " question"} available in{" "}
              {data.complexityname}
            </div>
          )}
        </div>
      ))}
    </div>
  );
}
