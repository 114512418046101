import React from "react";
import "./ConfirmNotify.scss";
import Modal from "react-bootstrap/Modal";
import SuccessImg from "../Notify/Images/SuccessImg.png";
import TestCasesFailed from "../Notify/Images/TestCasesFailed.png";
import ButtonMedium from "../../Buttons/ButtonMedium/ButtonMedium";

export default function ConfirmNotify(Props) {
  return (
    <div>
      <Modal
        centered
        backdrop="static"
        show={Props.show}
        onHide={Props.onHide}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Body>
          <div
            className={
              Props.resultCode === 1000 ? "modalSuccessTitle" : "modalTitle"
            }
          >
            {Props.title}
          </div>
          {Props.svg !== "" && Props.svg !== undefined ? (
            <div className="timeSvg">{Props.svg}</div>
          ) : (
            Props.resultCode !== "" &&
            Props.resultCode !== undefined && (
              <img
                src={Props.resultCode === 1000 ? SuccessImg : TestCasesFailed}
                alt={
                  Props.resultCode === 1000 ? "SuccessImg" : "TestCasesFailed"
                }
                className={
                  Props.resultCode === 1000
                    ? "SuccessImg"
                    : "testCasesFailedImg"
                }
              />
            )
          )}
          <div
            className={
              Props.resultCode !== "" && Props.resultCode !== undefined
                ? Props.resultCode === 1000
                  ? "modalMsgForSuccess"
                  : "modalMsgForTestCasesFailed"
                : "modalMsg"
            }
          >
            {Props.message}
          </div>
          <div
            className={
              Props.resultCode !== "" && Props.resultCode !== undefined
                ? Props.resultCode !== 1000
                  ? "modalButtonDivConfirmNotify"
                  : "modalButtonDivSuccess"
                : "modalButtonDiv"
            }
          >
            <div className="modalButton1">
              <ButtonMedium
                primaryBtn="true"
                onHide={Props.onHide}
                btnText={
                  Props.btnText2 !== "" && Props.btnText2 !== undefined
                    ? Props.btnText
                    : "Try Again"
                }
              />
            </div>
            <div
              className={
                Props.resultCode === 1000
                  ? "modalButton2Success"
                  : "modalButton2"
              }
            >
              <ButtonMedium
                secondaryBtn="true"
                onHideSecondary={Props.onHide2}
                btnText2={
                  Props.btnText !== "" && Props.btnText !== undefined
                    ? Props.btnText2
                    : "Quit"
                }
              />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
