import { nanoid } from "nanoid";
import React, { useState } from "react";
import Scrollbars from "react-custom-scrollbars-2";
import DataTable from "react-data-table-component";
import { Offcanvas, Button } from "react-bootstrap";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";

const headingCheckboxStyle = {
  color: "#F55533", // Add your desired style properties here
};

const QuestionList = (props) => {
  const [searchText, setSearchText] = useState("");

  // const [selectedQns, setSelectedQns] = useState([]);

  const customStyles = {
    headCells: {
      style: {
        fontSize: "12px",
        color: "#9AA0A6",
        fontWeight: "500",
        lineHeight: "15px",
        position: "sticky",
        fontFamily: "Inter",
        fontStyle: "normal",
        background: "#FFFFFF",
        textTransform: "uppercase",
      },
    },
    cells: {
      style: {
        color: "#202124",
        fontSize: "14px",
        fontWeight: "400",
        lineHeight: "20px",
        fontStyle: "normal",
        fontFamily: "Inter",
        background: "#FFFFFF",
      },
    },
  };

  const filteredData =
    props.published === 0 || props.isNewTest
      ? props.listQnData.data.filter((question) =>
          question.name.toLowerCase().includes(searchText.toLowerCase())
        )
      : props.listQnData.data.filter((question) => question.selected);

  const columns =
    props.published === 0 || props.isNewTest
      ? [
          {
            width: "45%",
            sortable: true,
            name: "Question",
            selector: (row) => row.name,
          },
          {
            width: "15%",
            name: "Topic",
            sortable: true,
            selector: (row) => row.topicname,
          },
          {
            width: "15%",
            name: "Type",
            sortable: true,
            selector: (row) => row.libname,
          },
          {
            width: "15%",
            name: "Level",
            sortable: true,
            selector: (row) => row.complexityname,
          },
        ]
      : [
          {
            width: "45%",
            name: "Question",
            selector: (row) => row.name,
          },
          {
            width: "15%",
            name: "Topic",
            selector: (row) => row.topicname,
          },
          {
            name: "Type",
            width: "15%",
            selector: (row) => row.libname,
          },
          {
            width: "15%",
            name: "Level",
            selector: (row) => row.complexityname,
          },
        ];

  // const handleSelectTopicAddToLp = (state) => {
  //   selectedQns = state.selectedRows;
  // };

  // const rowSelectCritera = (row) => row.selected;

  return (
    <Offcanvas
      placement="end"
      show={props.showQnList}
      id="adminTestQnListPopup"
      onHide={props.handleCloseListPopup}
    >
      <div id="adminTestBtnContainer">
        <div className="adminTestQnListHeadSec">
          <h2 className="adminTestQnListHead">Question List</h2>
          <span className="adminTestQnCount">
            ( No. of questions:{" "}
            {props.published === 0 || props.isNewTest
              ? props.listQnData.data.length
              : filteredData.length}{" "}
            )
          </span>
        </div>
        <div
          style={{ display: "flex", width: "60%", justifyContent: "flex-end" }}
        >
          <p>No. of selected questions: {props.selectedQns.length}</p>
          <FontAwesomeIcon icon={faSearch} className="adminTestQnSearchIcon" />
          {props.published === 0 || props.isNewTest ? (
            <input
              type="text"
              id="search"
              name="search"
              placeholder="Search"
              className="adminTestQnSearchBox"
              onChange={(event) => setSearchText(event.target.value)}
            />
          ) : null}
          {/* <Button variant="primary" id='adminAddTestBtn' onClick={handleCreateTestBtn}>{props.lpData.libTypeId === constants.libTypeIds.ASSIGNMENT || props.lpData.libTypeId === constants.libTypeIds.CONTENT ? 'Create' : "Create Test"}</Button> */}
        </div>
      </div>
      {/* <Scrollbars
        autoHide
        key={nanoid()}
        style={{ width: "100%", height: "80vh" }}
      > */}
      {/* {props.published === 0 || props.isNewTest ? (
          <DataTable
            selectableRows
            columns={columns}
            data={filteredData}
            customStyles={customStyles}
            selectableRowSelected={rowSelectCritera}
            onSelectedRowsChange={(data) => handleSelectTopicAddToLp(data)}

          // className="adminTestListTable"
          />
        ) : (
          <DataTable
            columns={columns}
            data={filteredData}
            customStyles={customStyles}

          // selectableRows
          // className="adminTestListTable"
          // selectableRowSelected={rowSelectCritera}
          // onSelectedRowsChange={(data) => handleSelectTopicAddToLp(data)}
          />
        )} */}
      <div style={{height:"80vh",overflow:'auto'}}>
        <QuestionTable
          data={filteredData}
          setSelectedQns={props.setSelectedQns}
          selectedQns={props.selectedQns}
        />
      </div>
      {/* </Scrollbars> */}
      <div className="adminTestBtnSec">
        {/* <div className="adminTestSecondaryBtn">
                    <ButtonMedium btnText2='Cancel' secondaryBtn={true} onHideSecondary={props.handleCloseListPopup} />
                </div>
                <div>
                    <ButtonMedium btnText='Save' primaryBtn={true} onHide={() => props.handleAddQnsFromList(selectedQns)} />
                </div> */}
        <Button
          onClick={props.handleCloseListPopup}
          style={{
            color: "black",
            background: "white",
            marginRight: "10px",
            border: "2px solid grey",
          }}
        >
          Cancel
        </Button>
        {props.published === 0 || props.isNewTest ? (
          <Button
            style={{ background: "#F55533", border: "none" }}
            onClick={() => props.handleAddQnsFromList(props.selectedQns)}
          >
            Add
          </Button>
        ) : null}
      </div>
    </Offcanvas>
  );
};

export default QuestionList;

const QuestionTable = ({ data, selectedQns, setSelectedQns }) => {
  const handleRowSelect = (id) => {
    const selectedIndex = selectedQns.indexOf(id);
    let newSelectedQns = [...selectedQns];

    if (selectedIndex === -1) newSelectedQns.push(id);
    else newSelectedQns.splice(selectedIndex, 1);
    setSelectedQns(newSelectedQns);
  };

  console.log("select questions", selectedQns);

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow style={{ fontFamily: "Inter" }}>
            <TableCell>
              <Checkbox
                checked={selectedQns.length === data.length}
                onChange={() =>
                  setSelectedQns(
                    selectedQns.length === data.length
                      ? []
                      : data.map((row) => row.id)
                  )
                }
                style={headingCheckboxStyle}
              />
            </TableCell>
            <TableCell>
              <b>QUESTION</b>
            </TableCell>
            <TableCell>
              <b>TOPIC</b>
            </TableCell>
            <TableCell>
              <b>TYPE</b>
            </TableCell>
            <TableCell>
              <b>LEVEL</b>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.length > 0 ? (
            data.map((question) => (
              <TableRow key={question.id} style={{ fontFamily: "Inter" }}>
                <TableCell>
                  <Checkbox
                    checked={selectedQns.includes(question.id)}
                    onChange={() => handleRowSelect(question.id)}
                    style={headingCheckboxStyle}
                  />
                </TableCell>
                <TableCell>{question.name}</TableCell>
                <TableCell>{question.topicname}</TableCell>
                <TableCell>{question.libname}</TableCell>
                <TableCell>{question.complexityname}</TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell style={{ textAlign: "center" }} colSpan={5}>
                No questions to display
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
