import React, { useContext } from "react";
import { useLocation, Outlet } from "react-router-dom";
import SideNav from "../../../Student/Profile/SideNav";
import { Paper } from "@mui/material";
import { getDataFromStorage } from "../../../../util";
import constants from "../../../../constants";
import BatchDetails from "./BatchDetails";
import Loading from "../../../Notification-Loading/Loading/Loading";
import AlertContext from "../../../../context/AlertContext";

const paperStyle = {
  border: "1px solid #EAECF0",
  width:
    parseInt(getDataFromStorage("accountType")) === constants.ACCOUNT_TYPE.LMS
      ? "80%"
      : "100%",
  display: "flex",
  gap: "20px",
  boxShadow: "none",
  borderRadius:
    parseInt(getDataFromStorage("accountType")) === constants.ACCOUNT_TYPE.LMS
      ? "10px"
      : "none",
  overflow: "auto",
  padding: "0px 0px 24px 0px",
};

const Settings = () => {
  const { isLoaded } = useContext(AlertContext);
  const { pathname } = useLocation();
  const settingMenuLists = [
    {
      id: 1,
      icon: (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_5487_13822)">
            <path
              d="M10 13.3333V10M10 6.66666H10.0083M18.3333 10C18.3333 14.6024 14.6024 18.3333 10 18.3333C5.39763 18.3333 1.66667 14.6024 1.66667 10C1.66667 5.39762 5.39763 1.66666 10 1.66666C14.6024 1.66666 18.3333 5.39762 18.3333 10Z"
              stroke={pathname.includes("details") ? "white" : "#667085"}
              strokeWidth="1.6"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
          <defs>
            <clipPath id="clip0_5487_13822">
              <rect width="20" height="20" fill="white" />
            </clipPath>
          </defs>
        </svg>
      ),
      text: "Batch Details",
      path: `details`,
      active: pathname.includes("details"),
    },
    {
      id: 2,
      icon: (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.125 17.2917L9.35248 17.9736C9.58881 18.1049 9.70698 18.1706 9.83213 18.1963C9.94288 18.2191 10.0571 18.2191 10.1679 18.1963C10.293 18.1706 10.4112 18.1049 10.6475 17.9736L11.875 17.2917M4.375 15.2083L3.18581 14.5477C2.93621 14.409 2.8114 14.3397 2.72053 14.2411C2.64013 14.1538 2.57929 14.0504 2.54207 13.9378C2.5 13.8104 2.5 13.6677 2.5 13.3821V12.0833M2.5 7.91667V6.61788C2.5 6.33234 2.5 6.18957 2.54207 6.06224C2.57929 5.94959 2.64013 5.84619 2.72053 5.75895C2.8114 5.66034 2.93621 5.591 3.18581 5.45233L4.375 4.79167M8.125 2.70834L9.35248 2.02641C9.58881 1.89511 9.70698 1.82946 9.83213 1.80372C9.94288 1.78094 10.0571 1.78094 10.1679 1.80372C10.293 1.82946 10.4112 1.89511 10.6475 2.02641L11.875 2.70834M15.625 4.79167L16.8142 5.45233C17.0638 5.591 17.1886 5.66034 17.2795 5.75895C17.3599 5.84619 17.4207 5.94959 17.4579 6.06224C17.5 6.18957 17.5 6.33234 17.5 6.61788V7.91667M17.5 12.0833V13.3821C17.5 13.6677 17.5 13.8104 17.4579 13.9378C17.4207 14.0504 17.3599 14.1538 17.2795 14.2411C17.1886 14.3397 17.0638 14.409 16.8142 14.5477L15.625 15.2083M8.125 8.95834L10 10M10 10L11.875 8.95834M10 10V12.0833M2.5 5.83334L4.375 6.875M15.625 6.875L17.5 5.83334M10 16.25V18.3333"
            stroke={pathname.includes("skillmap") ? "white" : "#667085"}
            strokeWidth="1.6"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
      text: "Resume Skill Mapping",
      path: `skillmap`,
      active: pathname.includes("skillmap"),
    },
    {
      id: 3,
      icon: (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14.1667 17.5V15.8333C14.1667 14.9493 13.8155 14.1014 13.1903 13.4763C12.5652 12.8512 11.7174 12.5 10.8333 12.5H4.16666C3.28261 12.5 2.43476 12.8512 1.80964 13.4763C1.18452 14.1014 0.833328 14.9493 0.833328 15.8333V17.5M19.1667 17.5V15.8333C19.1661 15.0948 18.9203 14.3773 18.4678 13.7936C18.0153 13.2099 17.3818 12.793 16.6667 12.6083M13.3333 2.60833C14.0503 2.79192 14.6859 3.20892 15.1397 3.79359C15.5935 4.37827 15.8399 5.09736 15.8399 5.8375C15.8399 6.57764 15.5935 7.29673 15.1397 7.88141C14.6859 8.46608 14.0503 8.88308 13.3333 9.06667M10.8333 5.83333C10.8333 7.67428 9.34094 9.16667 7.49999 9.16667C5.65905 9.16667 4.16666 7.67428 4.16666 5.83333C4.16666 3.99238 5.65905 2.5 7.49999 2.5C9.34094 2.5 10.8333 3.99238 10.8333 5.83333Z"
            stroke={pathname.includes("trainers") ? "white" : "#667085"}
            strokeWidth="1.6"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
      text: "Trainers",
      path: `trainers`,
      active: pathname.includes("trainers"),
    },
    {
      id: 4,
      icon: (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M13.3333 17.5V15.8333C13.3333 14.9493 12.9821 14.1014 12.357 13.4763C11.7319 12.8512 10.884 12.5 9.99999 12.5H4.16666C3.28261 12.5 2.43476 12.8512 1.80964 13.4763C1.18452 14.1014 0.833328 14.9493 0.833328 15.8333V17.5M16.6667 6.66667V11.6667M19.1667 9.16667H14.1667M10.4167 5.83333C10.4167 7.67428 8.92428 9.16667 7.08333 9.16667C5.24238 9.16667 3.74999 7.67428 3.74999 5.83333C3.74999 3.99238 5.24238 2.5 7.08333 2.5C8.92428 2.5 10.4167 3.99238 10.4167 5.83333Z"
            stroke={pathname.includes("clients") ? "white" : "#667085"}
            strokeWidth="1.6"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
      text: "Clients",
      path: `clients`,
      active: pathname.includes("clients"),
    },
  ];

  return (
    <React.Fragment>
      {parseInt(getDataFromStorage("accountType")) ===
      constants.ACCOUNT_TYPE.LMS ? (
        <div className="settingsContainer">
          <div className="settingsSection">
            <SideNav menuListItems={settingMenuLists} title="Settings" />
            <Paper sx={paperStyle} className="personalInfoSec">
              <Outlet />
            </Paper>
          </div>
        </div>
      ) : (
        <Paper sx={paperStyle} className="personalInfoSec">
          <BatchDetails />
        </Paper>
      )}
      {isLoaded && <Loading />}
    </React.Fragment>
  );
};

export default Settings;
