import React, { useContext } from "react";
import ExpandSvgIcon from "./ExpandSvgIcon";
import InwardSvgIcon from "./InwardSvgIcon";
import AlertContext from "../context/AlertContext";

export default function FullScreenSvgIcon(Props) {
  const { isFullScreen } = useContext(AlertContext);
  return (
    <div className="fullScreenSvgIcon" onClick={Props.onClick}>
      {!isFullScreen ? <ExpandSvgIcon stroke ={Props.stroke} /> : <InwardSvgIcon  stroke ={Props.stroke}/>}
    </div>
  );
}
