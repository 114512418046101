import React from 'react'

export default function NoProctoringIcon() {
  return (
        <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="44" height="44" rx="12" fill="#F9FAFB"/>
            <g clip-path="url(#clip0_298_21684)">
            <path d="M19.9 14.24C20.5883 14.0789 21.2931 13.9983 22 14C29 14 33 22 33 22C32.393 23.1356 31.6691 24.2047 30.84 25.19M24.12 24.12C23.8454 24.4147 23.5141 24.6512 23.1462 24.8151C22.7782 24.9791 22.3809 25.0673 21.9781 25.0744C21.5753 25.0815 21.1752 25.0074 20.8016 24.8565C20.4281 24.7056 20.0887 24.481 19.8038 24.1962C19.519 23.9113 19.2944 23.5719 19.1435 23.1984C18.9926 22.8248 18.9185 22.4247 18.9256 22.0219C18.9327 21.6191 19.0209 21.2218 19.1849 20.8538C19.3488 20.4859 19.5853 20.1546 19.88 19.88M11 11L33 33M27.94 27.94C26.2306 29.243 24.1491 29.9649 22 30C15 30 11 22 11 22C12.2439 19.6819 13.9691 17.6566 16.06 16.06L27.94 27.94Z" stroke="#667085" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
            <defs>
            <clipPath id="clip0_298_21684">
            <rect width="24" height="24" fill="white" transform="translate(10 10)"/>
            </clipPath>
            </defs>
        </svg>
  )
}
