import React, { Component, useState, useRef } from "react";
import "./RichTextEditor.scss";
import { Editor } from "@tinymce/tinymce-react";
import { createRoot } from "react-dom/client";
export default class RichTextEditor {
  static get toolbox() {
    return {
      title: "RichTextEditor",
      description: "Create a Text",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24"
          viewBox="0 0 24 24"
          width="24"
        >
          <path d="M0 0h24v24H0V0z" fill="none" />
          <path d="M19 15v4H5v-4h14m1-2H4c-.55 0-1 .45-1 1v6c0 .55.45 1 1 1h16c.55 0 1-.45 1-1v-6c0-.55-.45-1-1-1zM7 18.5c-.82 0-1.5-.67-1.5-1.5s.68-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5zM19 5v4H5V5h14m1-2H4c-.55 0-1 .45-1 1v6c0 .55.45 1 1 1h16c.55 0 1-.45 1-1V4c0-.55-.45-1-1-1zM7 8.5c-.82 0-1.5-.67-1.5-1.5S6.18 5.5 7 5.5s1.5.68 1.5 1.5S7.83 8.5 7 8.5z" />
        </svg>
      ),
    };
  }

  static get isReadOnlySupported() {
    return true;
  }

  constructor({ api, data, config }) {
    // Initialize state based on props
    this.api = api;
    this.config = config;
    this.data = {
      value: data && data.value ? data.value : "",
    };
    this.editorRef = React.createRef();
    this.state = {
      value: this.data.value,
    };
    this.nodes = {
      holder: null,
    };
  }

  handleFormChange = (content) => {
    this.setState({ value: content }, () => {
      this.data.value = this.state.value;
    });
    console.log("Editor content changed:", content);
  };

  render() {
    const rootnode = document.createElement("div");
    rootnode.classList.add("editortinymce");
    // Render the TinyMCE editor
    const EditorComponent = () => {
      // const [content, setContent] = useState(this.data.value);
      const editorRef = useRef(null);
      const handleEditorChange = (newContent, editor) => {
        this.data.value = newContent; // Sync with the class-level data
      };

      const handleInit = (evt, editor) => {
        editorRef.current = editor;
      };

      if (
        this.data.value === "" ||
        (this.config.context.isAdmin && !this.config.context.preview)
      ) {
        return (
          <Editor
            tinymceScriptSrc="/tinymce/tinymce.min.js"
            textareaName="contentTool"
            initialValue={this.data.value}
            // value={this.data.value} // Bind value to state
            onEditorChange={handleEditorChange} // Handle content changes
            onInit={handleInit} // Store editor reference
            init={{
              license_key: "gpl",
              minHeight: 400,
              menubar: false,
              branding: false,
              statusbar: false,
              elementpath: false,
              allow_html_in_named_anchor: true,
              plugins: [
                "code",
                "help",
                "lists",
                "media",
                "table",
                "anchor",
                "charmap",
                "preview",
                "advlist",
                "wordcount",
                "fullscreen",
                "autoresize",
                "visualblocks",
                "searchreplace",
                "insertdatetime",
              ],
              toolbar:
                "undo redo | blocks | " +
                "bold italic underline forecolor | alignleft aligncenter " +
                "alignright alignjustify | bullist numlist outdent indent | " +
                "removeformat | help |",
              content_style:
                "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
            }}
          />
        );
      } else {
        return (
          <Editor
            disabled={true}
            toolbar={false}
            menubar={false}
            branding={false}
            statusbar={false}
            value={this.data.value}
            tinymceScriptSrc="/tinymce/tinymce.min.js"
            init={{
              resize: false,
              readonly: true,
              menubar: false,
              statusbar: false,
              license_key: "gpl",
              selector: "textarea",
              plugins: "autoresize",
            }}
          />
        );
      }
    };

    createRoot(rootnode).render(<EditorComponent />);

    this.wrapper = document.createElement("div");
    this.wrapper.classList.add("editortinymce");
    this.wrapper.appendChild(rootnode);

    return this.wrapper;
  }

  save() {
    // Save the current state when the editor's content is saved
    return {
      value: this.data.value, // Save the latest editor content
    };
  }
}
