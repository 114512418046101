import Select from "react-select";
import AngleLeftSvg from "../Svg/AngleLeftSvg";
import AngleRightSvg from "../Svg/AngleRightSvg";
import AlertContext from "../context/AlertContext";
import React, { useContext, useEffect } from "react";
import StudentListHooks from "../Hooks/StudentListHooks";

export default function StudentList() {
  const { showPrevBtn, showNextBtn } = useContext(AlertContext);

  const {
    customStyle,
    studentListLoad,
    sortedLearnerList,
    currentLearnerName,
    handleChangeLearnerByBtn,
  } = StudentListHooks();

  useEffect(() => {
    studentListLoad("", "first");
  }, []);

  return (
    <div className="evaluationLearnerChange">
      <AngleLeftSvg
        disabled={showPrevBtn}
        handleFunction={() => handleChangeLearnerByBtn("prev")}
      />
      <Select
        isClearable={false}
        styles={customStyle}
        onChange={studentListLoad}
        options={sortedLearnerList}
        value={{ label: currentLearnerName }}
        className="codeEditorLanguageSelectorAdmin"
        isOptionDisabled={(option) => option.isdisabled}
        components={{
          IndicatorSeparator: () => null,
        }}
      />
      <AngleRightSvg
        disabled={showNextBtn}
        handleFunction={() => handleChangeLearnerByBtn("next")}
      />
    </div>
  );
}
