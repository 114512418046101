import useAuth from "./useAuth";
import axios from "../api/axios";
import { useNavigate } from "react-router-dom";

const useLogout = () => {
  const { setAuth } = useAuth();
  const navigate = useNavigate();

  const logout = async () => {
    try {
      await axios.post(
        "node/auth/logout",
        { refreshToken: localStorage.getItem("refreshToken") },
        {
          headers: {
            Authorization: "abdjcdifouvn",
            "Content-Type": "application/json",
          },
        }
      );
    } catch (err) {
      console.error("useLogout err", err);
    } finally {
      localStorage.removeItem("mockLp");
      localStorage.removeItem("tokenKey");
      localStorage.removeItem("batchType");
      localStorage.removeItem("issinglelp");
      localStorage.removeItem("isTestBatch");
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("issingleBatch");
      navigate("/");
      setAuth({});
    }
  };

  return logout;
};

export default useLogout;
