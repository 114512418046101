import React from 'react'

export default function AddSvg() {
  return (
    <svg width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="24" height="22" rx="11" fill="#F2F4F7"/>
        <path d="M11.4258 14.446V8.68466H12.5721V14.446H11.4258ZM9.12038 12.1364V10.9901H14.8817V12.1364H9.12038Z" fill="#344054"/>
    </svg>
  )
}
