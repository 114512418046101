import constants from "../../../constants";
import { getDataFromStorage } from "../../../util";
import SandPackEditorTools from "./SandPackEditorTools";
import AlertContext from "../../../context/AlertContext";
import useAxiosPrivate from "../../../Hooks/useAxiosPrivate";
import { SandpackProvider } from "@codesandbox/sandpack-react";
import Loading from "../../Notification-Loading/Loading/Loading";
import React, { memo, useContext, useEffect, useState, useRef } from "react";
import Profile from "../Home/LandingPage/Profile";
import HeaderHooks from "../Header/Hooks/HeaderHooks";
import useSubmitAssignment from "../../../Hooks/useSubmitAssignment";
import useCommonFunctionHooks from "../../../Hooks/useCommonFunctionHooks";
export default memo(function SandPackEditor(Props) {
  const Alert = useContext(AlertContext);

  const {
    isLoaded,
    lpConfigId,
    isFullScreensp,
    setIsFullScreensp,
    openSandpackEditor,
    setOpenSandpackEditor,
    isTakeTest,
    setMsg,
    setShow,
    setTitle,
    camAvailable,
    setCamAvailable,
    setCameraWarningMsg,
    navigate,
    setIsAddSubmission,
    setIsEditSubmission,
    isAdmin,
    tabSwitchCount,
    setIsLoaded,
    sandpackFiles,
    testData,
    contentData,
    setContentData,
    learnerNotes,
    lpId,
    setStatus,
    setIsSubmitted,
    setLpData,
    setIsUnAuth,
  } = useContext(AlertContext);
  const { handleSubmitAssignmentOnSandpack } = HeaderHooks();
  const axios = useAxiosPrivate();
  const [startTime, setStartTime] = useState(null);
  const focusOutTimer = useRef(null);
  const focusOutSec = useRef(0);

  const submitAssignment = useSubmitAssignment();
  const useCommonFunctionHks = useCommonFunctionHooks();

  const closeAssignment = async () => {
    Alert.stopProctoring.current.stop = true;

    try {
      setIsLoaded(true);

      let response = await submitAssignment(
        {
          lpId,
          isSave: false,
          libId: testData.libid,
          template: contentData.template,
          lpConfigId: testData.lpconfigid,
          learnerNotes: learnerNotes ? learnerNotes : "",
          file: {
            file: sandpackFiles,
            contentType: "json",
            name: `${getDataFromStorage("learnerid")}.json`,
          },
        },
        true
      );

      if (response.data.resultCode !== constants.RESULT_STATUS.SUCCESS) {
        Alert.setShowNotify({
          show: true,
          title: "Error",
          msg:
            response.data === ""
              ? "Something went wrong. Please try again later"
              : response.data.message,
        });
      } else {
        let newFiles = [],
          filePath = "";

        if (process.env.REACT_APP_DEPLOYMENT === "AWS")
          filePath = `SandpackFiles/library/submission/${
            testData.libid
          }/${getDataFromStorage("learnerid")}.json`;
        else
          filePath = `library/submission/${getDataFromStorage(
            "learnerid"
          )}/${getDataFromStorage("learnerid")}.json`;

        newFiles.push(filePath);

        let assignmentData = JSON.parse(JSON.stringify(contentData));

        assignmentData.commentsdata = [];
        assignmentData.anslinks = JSON.stringify(newFiles);
        assignmentData.answer = learnerNotes;
        assignmentData.submitteddate = new Date();
        setContentData(assignmentData);

        // need to look
        // setStatus(
        //   isSave
        //     ? constants.PROGRESS_STATUS.TO_BE_EVALUATED
        //     : constants.PROGRESS_STATUS.IN_PROGRESS
        // );

        setIsSubmitted(true);
        setIsEditSubmission(false);
        setIsAddSubmission(false);

        // if (isFullScreensp) {
        //   document.exitFullscreen();
        //   setIsFullScreensp(false);
        // }

        setLpData({});

        // if(isSave){

        navigate("/home");

        // }
      }
    } catch (err) {
      useCommonFunctionHks.getCatchBlockDetails(err);
    } finally {
      setIsLoaded(false);
    }
  };

  const onFocus = () => {
    focusOutSec.current = 0;
    clearInterval(focusOutTimer.current);
    window.removeEventListener("blur", onBlur);
  };

  const onBlur = () => {
    focusOutTimer.current = setInterval(() => {
      let second = focusOutSec.current;
      if (second === 10) {
        clearInterval(focusOutTimer.current);
        closeAssignment();
      } else {
        second++;
        focusOutSec.current = second;
      }
    }, 1000);
  };

  useEffect(() => {
    if (
      !isAdmin &&
      Props.status !== constants.PROGRESS_STATUS.EVALUATED &&
      testData.proctored !== constants.PROCTORING_MODE.NONE
    ) {
      window.addEventListener("blur", onBlur);
      window.addEventListener("focus", onFocus);
      return () => {
        window.removeEventListener("blur", onBlur);
        window.removeEventListener("focus", onFocus);
      };
    }
  }, [onBlur]);

  // useEffect(() => {
  //   if (document.documentElement.requestFullscreen) {
  //     document.documentElement.requestFullscreen();
  //   } else if (document.documentElement.mozRequestFullScreen) {
  //     document.documentElement.mozRequestFullScreen();
  //   } else if (document.documentElement.webkitRequestFullscreen) {
  //     document.documentElement.webkitRequestFullscreen();
  //   } else if (document.documentElement.msRequestFullscreen) {
  //     document.documentElement.msRequestFullscreen();
  //   }
  //   return () => {
  //     if (document.fullscreenElement) {
  //       document.exitFullscreen();
  //     } else if (document.mozFullScreenElement) {
  //       document.mozCancelFullScreen();
  //     } else if (document.webkitFullscreenElement) {
  //       document.webkitExitFullscreen();
  //     } else if (document.msFullscreenElement) {
  //       document.msExitFullscreen();
  //     }
  //   };
  // }, []);

  // useEffect(() => {
  //   const handleFullscreenChange = () => {
  //     if (!document.fullscreenElement) {
  //       if (isAdmin) {
  //         navigate("/assignment");
  //       } else {
  //         if (Props.status !== constants.PROGRESS_STATUS.EVALUATED && testData.proctored!==constants.PROCTORING_MODE.NONE) {
  //           // setTimeout(async ()=>{
  //            closeAssignment();
  //         // },1000)
  //         } else if(testData.proctored!==constants.PROCTORING_MODE.NONE) {
  //           navigate("/home");
  //         }
  //       }
  //     } 
  //   };

  //   document.addEventListener("fullscreenchange", handleFullscreenChange);
  //   return () => {
  //     document.removeEventListener("fullscreenchange", handleFullscreenChange);
  //   };
  // }, []);

  useEffect(() => {
    // When component mounts

    let role = getDataFromStorage("role");

    if (
      role === constants.Roles.learner &&
      ![
        constants.PROGRESS_STATUS.EVALUATED &&
          constants.PROGRESS_STATUS.TO_BE_EVALUATED,
      ].includes(Props.status)
    ) {
      const newStartTime = Date.now();

      let storedTime;
      let learnerId = getDataFromStorage("learnerid");

      async function duration() {
        // storedTime = localStorage.getItem('timeSpent');

        let response = await axios.get(
          `node/learner/content/timespent/${learnerId}/${lpConfigId}`,
          {
            headers: {
              "Content-type": "application/json",
            },
          }
        );

        storedTime = response?.data?.data?.duration;
        storedTime = storedTime * 1000 * 60;
        setStartTime(newStartTime);
      }

      duration();

      // When component unmounts

      return () => {
        async function save() {
          const endTime = Date.now();

          let timeSpent =
            endTime - newStartTime + (storedTime ? storedTime : 0);
          timeSpent = Math.round(timeSpent / (60 * 1000));

          let learnerId = getDataFromStorage("learnerid");
          let requestData = {
            duration: timeSpent,
            learnerid: learnerId,
            lpconfigid: lpConfigId,
          };

          // localStorage.setItem('timeSpent', timeSpent.toString());

          let response = await axios.post(
            `node/learner/content/timespent`,
            requestData,
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
        }

        if (
          role === constants.Roles.learner &&
          ![
            constants.PROGRESS_STATUS.EVALUATED &&
              constants.PROGRESS_STATUS.TO_BE_EVALUATED,
          ].includes(Props.status)
        ) {
          save();
        }
      };
    }
  }, []);

  return (
    <div
      className={
        isFullScreensp ? "sandpackEditorContainer1" : "sandpackEditorContainer2"
      }
    >
      {/* {isTakeTest && !isAdmin && Props.status !== constants.PROGRESS_STATUS.EVALUATED && testData.proctored == constants.PROCTORING_MODE.TAB_CAM && (
        <Profile
          isTakeTest={isTakeTest}
          questionid={testData.libid}
          libtypeid={constants.libTypeIds.ASSIGNMENT}
        />
      )} */}
      {isLoaded && <Loading />}
      <SandpackProvider
        template={Props.selectedTemplate?.value?.toLowerCase()}
        options={{
          autorun: false,
          autoReload: false,
          showConsole: false,
        }}
      >
        <SandPackEditorTools
          status={Props.status}
          learnerId={Props.learnerId}
          libDetails={Props.libDetails}
          isqueReport={Props.isqueReport}
          template={Props.selectedTemplate}
          isAddSubmission={Props.isAddSubmission}
          openSandpackEditor={openSandpackEditor}
          setOpenSandpackEditor={setOpenSandpackEditor}
        />
      </SandpackProvider>
    </div>
  );
});
