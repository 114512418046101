import "./ContentEntry.scss";
import { nanoid } from "nanoid";
import "quill/dist/quill.snow.css";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import { Modal } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import "react-quill/dist/quill.snow.css";
import Modules from "../Question/Modules";
import constants from "../../../constants";
import { getPreSignedUrl } from "../../../util";
import { Editor } from "@tinymce/tinymce-react";
import DataTable from "react-data-table-component";
import AlertContext from "../../../context/AlertContext";
import useAxiosPrivate from "../../../Hooks/useAxiosPrivate";
import Notify from "../../Notification-Loading/Notify/Notify";
import useAddOrUpdateLp from "../../../Hooks/useAddOrUpdateLp";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import Loading from "../../Notification-Loading/Loading/Loading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect, useRef, useContext } from "react";
import useUploadLargeFileToS3 from "../../../Hooks/useUploadLargeFileToS3";

import {
  faPlus,
  faTrash,
  faFileAlt,
  faArrowLeft,
  faArrowUpFromBracket,
} from "@fortawesome/free-solid-svg-icons";

function ContentEntry(Props) {
  const {
    msg,
    show,
    title,
    setMsg,
    lpData2,
    setShow,
    setTitle,
    isUnAuth,
    isFromLp,
    fromList,
    navigate,
    isLoaded,
    setLpData2,
    setIsLoaded,
    setIsUnAuth,
    handleClose,
    isFromBatch,
    setFromList,
    setIsFromLp,
    isOldLpDetail,
    handleUnAuthAlert,
    handleNavigateToLpAfterAddTest,
  } = useContext(AlertContext);
  const axios = useAxiosPrivate();

  const { handleAddOrUpdateLp } = useAddOrUpdateLp();
  const { uploadLargeFileToS3 } = useUploadLargeFileToS3();

  const customStyles = {
    headCells: {
      style: {
        fontSize: "12px",
        color: "#9AA0A6",
        fontWeight: "500",
        lineHeight: "15px",
        position: "sticky",
        fontFamily: "Inter",
        fontStyle: "normal",
        background: "#FFFFFF",
        textTransform: "uppercase",
      },
    },
    cells: {
      style: {
        color: "#202124",
        fontSize: "14px",
        fontWeight: "400",
        lineHeight: "20px",
        fontStyle: "normal",
        fontFamily: "Inter",
        background: "#FFFFFF",
      },
    },
  };

  let [resultCode, setResultCode] = useState("");

  let [languagesOfPassedTestCases, setlanguagesOfPassedTestCases] = useState(
    []
  );

  const [showFromLibrary, setShowFromLibrary] = useState(false);
  const [fileName, setFileName] = useState("Browse Files to upload");

  const [fileLibraryName, setFileLibraryName] = useState(
    "Upload Files from library"
  );

  let [filesFromLib, setfilesFromLip] = useState([]);

  const [selectedRows, setSelectedRows] = useState([]);

  let [libraryLinksData, setLibraryLinksData] = useState(
    Props.isqueReport &&
      Props.tableShowStatus !== constants.libTypeIds.CODING_CHALLENGE
      ? Props.selectque.link
      : []
  );

  let [selectedFile, setSelectedFile] = useState([]);
  let [alreadySelected, setalreadySelected] = useState(null);
  let [isSelectedTopic, setisSelectedTopic] = useState(true);
  let [alreadySelectedCom, setalreadySelectedCom] = useState(null);
  let [isSelectedComplexity, setisSelectedComplexity] = useState(true);

  const editorRef = useRef(null);

  const [mcqAns, setMcqAns] = useState(
    Props.isqueReport && Props.tableShowStatus === constants.libTypeIds.MCQ
      ? Props.selectque.isAnswer
      : [
          {
            id: nanoid(),
            optionText: "",
            isAnswer: false,
          },
          {
            id: nanoid(),
            optionText: "",
            isAnswer: false,
          },
        ]
  );

  let [checkedState, setCheckedState] = useState(
    Props.isqueReport && Props.tableShowStatus === constants.libTypeIds.MCQ
      ? mcqAns.map((data) => (data.isAnswer === 1 ? true : false))
      : []
  );

  let [chooseAnswer, setChooseAnswer] = useState(
    Props.isqueReport && Props.tableShowStatus === constants.libTypeIds.MCQ
      ? Props.selectque.type === 8
        ? 1
        : Props.selectque.isAnswer.length
      : null
  );

  let [isMultiAnsChecked, setIsMultiAnsChecked] = useState(true);
  let [isCheckboxChecked, setIsCheckboxChecked] = useState(false);

  const [selectedCheck, setSelectedCheck] = useState(null);
  const [showOptionsbtn, setShowOptionbtn] = useState(false);

  // descriptive saq & laq

  const [libTypebyParent, setlibTypebyParent] = useState([]);
  const [libTypesIdbyParent, setlibTypeIdbyParent] = useState(0);
  const [selectSaqLaqLength, setSelectSaqLaqLength] = useState(500);
  const [libTypesNamebyParent, setlibTypeNamebyParent] = useState(null);

  // embed Links

  const [embedLink, setEmbedLink] = useState("");

  const [embedLinksList, setEmbedLinksList] = useState(
    Props.isqueReport &&
      Props.tableShowStatus !== constants.libTypeIds.CODING_CHALLENGE
      ? Props.selectque.embedLinks
      : Props.isqueReport &&
        Props.tableShowStatus === constants.libTypeIds.CODING_CHALLENGE
      ? Props.selectque.embedlinks
      : []
  );

  /* making stable state from qnreport */

  const [makeStableState, setMakeStableState] = useState(false);

  let [UserInput, setuserinp] = useState(
    Props.isqueReport &&
      Props.tableShowStatus === constants.libTypeIds.CODING_CHALLENGE
      ? {
          name: Props.selectque.qdata.name,
          topic: Props.selectque.qdata.topic,
          ansexp: Props.selectque.qdata.ansexp,
          inputvars: Props.selectque.cc.inputvars,
          datatypes: Props.selectque.cc.datatypes,
          evalrules: Props.selectque.cc.evalrules,
          maxtime: Props.selectque.qdata.timelimit,
          returntype: Props.selectque.cc.returntype,
          questionid: Props.selectque.qdata.questionid,
          complexity: Props.selectque.qdata.complexity,
          functionname: Props.selectque.cc.functionname,
          description: Props.selectque.qdata.description,
          autoevaluate: Props.selectque.qdata.autoevaluate,
          exampleinput: Props.selectque.io
            ?.filter((data) => data.isexample === 1)
            .map((filteredinput) => filteredinput.input)
            .join("\r\n"),
          exampleoutput: Props.selectque.io
            ?.filter((data) => data.isexample === 1)
            .map((filteredoutput) => filteredoutput.output)
            .join("\r\n"),
        }
      : Props.isqueReport &&
        Props.tableShowStatus !== constants.libTypeIds.CODING_CHALLENGE &&
        Props.tableShowStatus !== constants.libTypeIds.DESCRIPTIVE
      ? {
          name: Props.selectque.name,
          marks: Props.selectque.marks,
          ansexp: Props.selectque.ansexp,
          maxtime: Props.selectque.timelimit,
          description: Props.selectque.description,
        }
      : Props.isqueReport &&
        Props.tableShowStatus === constants.libTypeIds.DESCRIPTIVE
      ? {
          name: Props.selectque.name,
          marks: Props.selectque.marks,
          ansexp: Props.selectque.ansexp,
          maxtime: Props.selectque.timelimit,
          description: Props.selectque.description,
          saqlaqAnswer: Props.selectque.isAnswer[0].optionText,
        }
      : Props.tableShowStatus === constants.libTypeIds.CODING_CHALLENGE
      ? {
          autoevaluate: 1,
        }
      : {}
  );

  const returntypes = [
    { value: "int", label: "Int" },
    { value: "float", label: "Float" },
    { value: "string", label: "String" },
    { value: "boolean", label: "Boolean" },
    { value: "array-1int", label: "Array 1D Number" },
    { value: "array-2int", label: "Array 2D Number" },
    { value: "array-1string", label: "Array 1D String" },
    { value: "array-2string", label: "Array 2D String" },
  ];

  let parsedReturntype;

  try {
    parsedReturntype = UserInput.returntype && JSON.parse(UserInput.returntype);
  } catch (error) {
    // If parsing as JSON fails, it's a regular string

    parsedReturntype = UserInput.returntype && UserInput.returntype;
  }

  const [currenttype, setcurrenttype] = useState(
    Props.isqueReport &&
      Props.tableShowStatus === constants.libTypeIds.CODING_CHALLENGE
      ? parsedReturntype && typeof parsedReturntype === "object"
        ? parsedReturntype
        : returntypes.find((obj) => obj.value === parsedReturntype)
      : {}
  );

  const handleCloselib = () => setShowFromLibrary(false);

  const handleAddbtn = () => {
    setShowFromLibrary(false);

    let temp = [...libraryLinksData];

    for (let t = 0; t < selectedRows.length; t++) {
      temp.push({ link: selectedRows[t].link, checked: false });
    }

    let unique = [];
    let uniqueLinks = [];
    let uniq = temp.filter((ele) => {
      let isduplicate = unique.includes(ele.link);

      if (!isduplicate) {
        unique.push(ele.link);
        uniqueLinks.push(ele);

        return true;
      } else {
        return false;
      }
    });

    setLibraryLinksData(uniqueLinks);
    setSelectedRows([]);
  };

  let response;

  let [showNotification, setShowNotification] = useState(false);

  let [Errors, setErrors] = useState({
    url: "",
    marks: "",
    maxtime: "",
    exampleio: "",
    testcaseio: "",
    oursolution: "",
    boilerplate: "",
    language: "java",
    executioncode: "",
    difficulty: "easy",
    problemstatement: "",
  });

  const Inputvars = UserInput.inputvars?.split(",");
  const Datatypes = UserInput.datatypes?.split(",");

  const inputDataSource = [];

  let obj;

  for (let i = 0; i < Inputvars?.length; i++) {
    obj = {
      key: i + 2,
      varname: Inputvars[i],
      vartype: Datatypes ? Datatypes[i] : null,
    };

    inputDataSource.push(obj);
  }

  useEffect(() => {
    axios
      .get("node/admin/library/gettypes", {
        headers: {
          "Content-type": "application/json",
        },
      })
      .then((res) => {
        let json = res.data.filter((data) => data.parenttype === 0);
        let newdata = [];

        if (Props.fromList.isFromList) {
          for (let i = 0; i < json.length; i++) {
            if (json[i].id === Props.fromList.libType) {
              newdata.push({ label: json[i].name, value: json[i].id });
            }
          }
        } else {
          newdata = json.map((data) => {
            return {
              value: data.id,
              label: data.name,
            };
          });
        }
      })
      .catch((error) => {
        if (error.message.includes("403")) {
          // localStorage.clear();
          localStorage.removeItem('tokenKey');
          localStorage.removeItem('refreshToken');
          navigate("/");
        }
      });
  }, []);

  const handleInputChange = (event, name) => {
    if (event.name === "topicname") {
      let tempusrinp = UserInput;

      if (tempusrinp.hasOwnProperty("topic")) {
        delete tempusrinp.topic;
      }

      setuserinp({
        result: "",
        ...tempusrinp,
        success: true,
        [event.name]: event.value,
      });
    } else if (event.name === "topic") {
      let tempusrinp = UserInput;

      if (tempusrinp.hasOwnProperty("topicname")) {
        delete tempusrinp.topicname;
      }

      setuserinp({
        result: "",
        success: true,
        ...tempusrinp,
        [event.name]: event.value,
      });
    } else if (name === "complexity") {
      setuserinp({
        result: "",
        ...UserInput,
        success: true,
        [name]: event.value,
      });
    } else if (name === "description") {
      setuserinp({
        result: "",
        ...UserInput,
        success: true,
        ["description"]: event,
      });
    } else if (name === "ansexp") {
      setuserinp({
        result: "",
        ...UserInput,
        success: true,
        ["ansexp"]: event,
      });
    } else {
      const target = event.target;

      let value = event.target.value;

      const name = target.name;

      if (name === "maxtime") {
        if (Props.tableShowStatus === constants.libTypeIds.ASSIGNMENT) {
          value = value * 60;
        }

        if (isNaN(parseInt(value))) {
          setErrors({
            ...Errors,
            success: false,
            maxtime: "Please enter only Numbers",
          });
        } else {
          setErrors({ ...Errors, maxtime: "", success: true });
        }
      }

      if (name === "marks") {
        if (isNaN(parseInt(value))) {
          setErrors({
            ...Errors,
            success: false,
            marks: "Please enter only Numbers",
          });
        } else {
          setErrors({ ...Errors, marks: "", success: true });
        }
      }

      setuserinp({ ...UserInput, [name]: value, result: "", success: true });
    }

    /* stable the state from qn report */

    if (Props.isqueReport && !makeStableState) {
      setMakeStableState(true);
    } else if (Props.isqueReport && makeStableState) {
      Props.setIsStableState(false);
    }
  };

  const handleNavigate = () => {
    if (resultCode === 1000) {
      setShow(false);
      navigate(`/library/${constants.libTypeIds.CONTENT}/questionlist`);
    }
  };

  const getfilesfromlibrary = () => {
    setShowFromLibrary((pre) => !pre);

    axios
      .get("node/admin/library/getlinks", {
        headers: {
          "Content-type": "application/json",
        },
      })
      .then((res) => {
        setfilesFromLip(res.data);
      })
      .catch((error) => {
        if (error.message.includes("403")) {
          setShow(true);
          setTitle("Error");
          setIsUnAuth(true);
          setMsg("You have been logged-out due to inactivity. Login again.");
        }
      });
  };

  const addQuestion = async (e) => {
    e.preventDefault();
    setIsLoaded(true);

    let countHiddenTestCases = 0;

    for (let i = 0; i < iodata.length; i++) {
      if (iodata[i].isExample == 0) {
        countHiddenTestCases = countHiddenTestCases + 1;
      }
    }

    if (Props.tableShowStatus === 5 && countHiddenTestCases < 3) {
      setIsLoaded(false);
      setShow(true);
      setTitle("Warning");
      setMsg("TestCases are Not Enough Make Sure add Atleast 3");
      return;
    } else if (Props.tableShowStatus === 5 && countHiddenTestCases > 5) {
      setIsLoaded(false);
      setShow(true);
      setTitle("Warning");
      setMsg("TestCases added More than  Enough Make Sure add AtMax 5");
      return;
    }

    let questionsdata;
    let form = new FormData(),
      isProceed = false;

    if (Props.tableShowStatus === 3) {
      if (Props.isqueReport) {
        UserInput.id = Props.selectque.id;

        if (isSelectedTopic) {
          UserInput.topic = alreadySelected;
        }

        if (
          isSelectedComplexity &&
          Props.tableShowStatus !== constants.libTypeIds.CONTENT
        ) {
          UserInput.complexity = alreadySelectedCom;
        }
      }

      questionsdata = JSON.stringify({
        qdata: UserInput,
      });

      form.append("qdata", questionsdata);
      form.append("libtypeid", libTypesIdbyParent);
      form.append("libtypename", libTypesNamebyParent);

      if (embedLinksList.length > 0) {
        form.append("embedLinks", embedLinksList);
      }

      if (Props.selectque?.testDetails?.length > 0) {
        form.append(
          "testConfigIds",
          Props.selectque.testDetails
            .map((testData) => testData.testconfigid)
            .toString()
        );
      }

      if (selectedFile.length > 0) {
        form.append("hasfiles", "yes");

        for (let i = 0; i < selectedFile.length; i++) {
          form.append(selectedFile[i].file.name, selectedFile[i].file);
        }
      }

      if (libraryLinksData.length > 0) {
        form.append("links", libraryLinksData);
      }

      isProceed = true;
    } else if (Props.tableShowStatus === 2) {
      let count = 0;

      for (let i = 0; i < mcqAns.length; i++) {
        if (mcqAns[i].isAnswer) {
          count++;
        }
      }

      if (count === 0) {
        isCheckboxChecked = false;
        setIsCheckboxChecked(false);
        isMultiAnsChecked = true;
        setIsMultiAnsChecked(true);
      } else if (libTypesNamebyParent === "MCQ_MULTI" && count < 2) {
        isMultiAnsChecked = false;
        setIsMultiAnsChecked(false);
      } else {
        isCheckboxChecked = true;
        setIsCheckboxChecked(true);
        isMultiAnsChecked = true;
        setIsMultiAnsChecked(true);
      }

      if (isCheckboxChecked && isMultiAnsChecked) {
        if (Props.isqueReport) {
          UserInput.id = Props.selectque.id;

          if (isSelectedTopic) {
            UserInput.topic = alreadySelected;
          }

          if (
            isSelectedComplexity &&
            Props.tableShowStatus !== constants.libTypeIds.CONTENT
          ) {
            UserInput.complexity = alreadySelectedCom;
          }
        }

        questionsdata = JSON.stringify({
          qdata: UserInput,
        });

        form.append("qdata", questionsdata);
        form.append("libtypeid", libTypesIdbyParent);
        form.append("mcqAns", JSON.stringify(mcqAns));
        form.append("libtypename", libTypesNamebyParent);

        if (embedLinksList.length > 0) {
          form.append("embedLinks", embedLinksList);
        }

        if (Props.selectque?.testDetails?.length > 0) {
          form.append(
            "testConfigIds",
            Props.selectque.testDetails
              .map((testData) => testData.testconfigid)
              .toString()
          );
        }

        if (selectedFile.length > 0) {
          form.append("hasfiles", "yes");
          for (let i = 0; i < selectedFile.length; i++) {
            form.append(selectedFile[i].file.name, selectedFile[i].file);
          }
        }

        if (libraryLinksData.length > 0) {
          form.append("links", libraryLinksData);
        }
      } else if (!isMultiAnsChecked) {
        setIsLoaded(false);
        setShow(true);
        setTitle("Info");
        setMsg("You should select multiple answers");
      } else {
        setIsLoaded(false);
        setShow(true);
        setTitle("Info");
        setMsg("Options can't be blank!");
      }

      isProceed = true;
    } else {
      if (embedLink === "") {
        if (Props.isqueReport) {
          UserInput.id = Props.selectque.id;

          if (isSelectedTopic) {
            UserInput.topic = alreadySelected;
          }

          if (
            isSelectedComplexity &&
            Props.tableShowStatus !== constants.libTypeIds.CONTENT
          ) {
            UserInput.complexity = alreadySelectedCom;
          }
        }

        questionsdata = JSON.stringify({
          qdata: UserInput,
        });

        if (
          UserInput.name === "" ||
          UserInput.name === null ||
          UserInput.name === undefined
        ) {
          setIsLoaded(false);
          setShow(true);
          setTitle("Warning");
          setMsg("Please Give Name");
          return;
        }
        if (
          UserInput.topic === "" ||
          UserInput.topic === null ||
          UserInput.topic === undefined
        ) {
          if (!UserInput.hasOwnProperty("topicname")) {
            setIsLoaded(false);
            setShow(true);
            setTitle("Warning");
            setMsg("Please Choose Topic");
            return;
          }
        }

        form.append("qdata", questionsdata);
        form.append("libtypeid", Props.tableShowStatus);
        form.append("libtypename", Props.selectedLibType);

        if (embedLinksList.length > 0) {
          form.append("embedLinks", embedLinksList);
        }

        let candown = [],
          fileUploadCount = 0,
          fileNames = [];

        if (selectedFile.length > 0) {
          form.append("hasfiles", "yes");

          for (let i = 0; i < selectedFile.length; i++) {
            fileNames.push(selectedFile[i].file.name);
            candown.push({
              [selectedFile[i].file.name]: selectedFile[i].checked,
            });

            let filePath =
              Props.tableShowStatus === constants.libTypeIds.ASSIGNMENT
                ? "library/misc/" + selectedFile[i].file.name
                : "library/content/" + selectedFile[i].file.name;

            if (process.env.REACT_APP_DEPLOYMENT === "AWS")
              await uploadLargeFileToS3(
                selectedFile[i].file,
                filePath,
                selectedFile[i].file.name.replace(/^.*\./, "")
              );
            else form.append(selectedFile[i].file.name, selectedFile[i].file);

            fileUploadCount++;
          }

          form.append("isfiledownloadable", JSON.stringify(candown));
        }

        form.append("fileNames", JSON.stringify(fileNames));

        if (fileUploadCount === selectedFile.length) isProceed = true;

        if (libraryLinksData.length > 0) {
          form.append("links", JSON.stringify(libraryLinksData));
        }
      } else {
        setIsLoaded(false);
        setShow(true);
        setTitle("Info");
        setMsg("Please add the Embedded link");
      }
    }

    if (isProceed) {
      axios({
        data: form,
        method: "post",
        url: "node/admin/library/add",
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then((res) => {
          setIsLoaded(false);

          if (res.data.resultCode == "1000") {
            if (fromList.isFromList) {
              setFromList({ isFromList: false, libType: "" });

              let newDetails = { ...lpData2 },
                uniqueId = 1,
                libraryData = {
                  isNew: true,
                  published: 0,
                  isEdited: false,
                  title: UserInput.name,
                  id: res.data.data.libid,
                  libTypeId: lpData2.libTypeId,
                  topics: [
                    {
                      topicid: UserInput.topic,
                    },
                  ],
                };

              if (isOldLpDetail) libraryData.status = "new";

              for (let i = 0; i < newDetails.modules.length; i++) {
                if (newDetails.modules[i].id === newDetails.moduleId) {
                  newDetails.modules[i].moduleData.push(libraryData);

                  for (
                    let j = 0;
                    j < newDetails.modules[i].moduleData.length;
                    j++
                  ) {
                    newDetails.modules[i].moduleData[j].moduleDataId = uniqueId;
                    newDetails.modules[i].moduleData[j].key = nanoid();
                    uniqueId++;
                  }
                }

                newDetails.modules[i].id = uniqueId;
                uniqueId++;
              }

              newDetails.isAddTest = false;
              handleNavigateToLpAfterAddTest(newDetails);
            } else if (isOldLpDetail) {
              let newDetails = JSON.parse(JSON.stringify(lpData2));

              for (let i = 0; i < newDetails.modules.length; i++) {
                if (newDetails.modules[i].id === newDetails.moduleId) {
                  for (
                    let j = 0;
                    j < newDetails.modules[i].moduleData.length;
                    j++
                  ) {
                    if (
                      newDetails.modules[i].moduleData[j] &&
                      UserInput.id === newDetails.modules[i].moduleData[j].libId
                    ) {
                      newDetails.modules[i].moduleData[j].libId =
                        res.data.data.libid;
                      newDetails.modules[i].moduleData[j].title =
                        UserInput.name;
                      newDetails.modules[i].moduleData[j].testDuration =
                        UserInput.maxtime;
                      newDetails.modules[i].moduleData[j].moved = "yes";
                    }
                  }
                }
              }

              2(newDetails);

              return (async () => {
                try {
                  let res = await handleAddOrUpdateLp({}, newDetails.modules);

                  if (res.title === "success") {
                    setShow(true);
                    setTitle("Success");
                    setMsg(res.message);
                    handleNavigateToLpAfterAddTest(newDetails);
                  }
                } catch (err) {
                  setIsUnAuth(err.hasOwnProperty("isUnAuth"));
                  setShow(true);
                  setTitle("Error");
                  setMsg(err.message);
                }
              })();
            } else {
              setResultCode(res.data.resultCode);
              setShow(true);
              setTitle("Success");
              setMsg(res.data.message);
            }
          } else {
            setShow(true);
            setTitle("Error");
            setMsg(res.data.message);
          }
        })
        .catch((error) => {
          setIsLoaded(false);

          if (error.message.includes("403")) {
            setShow(true);
            setTitle("Error");
            setIsUnAuth(true);
            setMsg("You have been logged-out due to inactivity. Login again.");
          }
        });
    }
  };

  let [iodata, setiodata] = useState([]);

  useEffect(() => {
    axios
      .get("node/admin/library/gettypes", {
        headers: {
          "Content-type": "application/json",
        },
      })
      .then((res) => {
        let json = res.data;
        let types = json.map((type) => {
          return {
            value: type.id,
            label: type.name,
          };
        });
      })
      .catch((error) => {
        if (error.message.includes("403")) {
          setShow(true);
          setTitle("Error");
          setIsUnAuth(true);
          setMsg("You have been logged-out due to inactivity. Login again.");
        }
      });

    axios
      .get("node/admin/library/gettypes", {
        headers: {
          "Content-type": "application/json",
        },
      })
      .then((res) => {
        let json = res.data.filter(
          (data) => data.parenttype === Props.tableShowStatus
        );

        setlibTypebyParent(json);

        if (Props.isqueReport) {
          let selectedData = json.filter(
            (data) => data.id === Props.selectque.type
          );

          if (selectedData[0].name === "SAQ") {
            setSelectSaqLaqLength(500);
          } else {
            setSelectSaqLaqLength(1000);
          }

          setlibTypeIdbyParent(selectedData[0].id);
          setSelectedCheck(selectedData[0].id);
          setlibTypeNamebyParent(selectedData[0].name);
        } else {
          setlibTypeNamebyParent(json[0].name);
          setlibTypeIdbyParent(json[0].id);
          setSelectedCheck(json[0].id);
          setChooseAnswer(1);
        }

        setlibTypebyParent(json);
        setShowOptionbtn(true);
      })
      .catch((error) => {
        if (error.message.includes("403")) {
          setShow(true);
          setTitle("Error");
          setIsUnAuth(true);
          setMsg("You have been logged-out due to inactivity. Login again.");
        }
      });
  }, []);

  const uploadHandler = (e) => {
    const file = e.target.files;

    let temp = [...selectedFile];

    for (var i = 0; i < file.length; i++) {
      if (Props.isqueReport) {
        if (
          !Props.selectque.link
            .map((i) =>
              i.link.substring(i.link.lastIndexOf("/") + 1, i.link.length)
            )
            .includes(file[i].name)
        ) {
          temp.push({
            file: file[i],
            checked: false,
            url: URL.createObjectURL(file[i]),
          });
        }
      } else {
        if (!temp.map((data) => data.file.name).includes(file[i].name)) {
          temp.push({
            file: file[i],
            checked: false,
            url: URL.createObjectURL(file[i]),
          });
        }
      }
    }

    setSelectedFile(temp);
  };

  const deletelibFile = (filename) => {
    let temp = [];

    for (let fname = 0; fname < libraryLinksData.length; fname++) {
      if (libraryLinksData[fname].link !== filename) {
        temp.push(libraryLinksData[fname]);
      }
    }

    setLibraryLinksData(temp);
  };

  const deleteUploadFile = (filename) => {
    let temp = selectedFile.filter((f) => f.file.name !== filename);

    setSelectedFile(temp);
  };

  const columns = [
    {
      name: "Files",
      selector: (row) => (
        <a
          target="_blank"
          rel="noreferrer"
          className="ctntuploadlibTaps"
          href={process.env.REACT_APP_NODE_API + row.link}
        >
          {row.link.substring(row.link.lastIndexOf("/") + 1, row.link.length)}
        </a>
      ),
    },
  ];

  const handleChange = (state) => {
    setSelectedRows(state.selectedRows);
  };

  const handleFileDownloadChangeforLink = (pos) => {
    let temp = [...libraryLinksData];

    temp[pos].checked = !temp[pos].checked;
    setLibraryLinksData(temp);
  };

  const handleBackBtn = () => {
    Props.setselectque([]);
    setIsFromLp(false);
    Props.setisqueReport(false);
    setFromList({ isFromList: false, libType: "" });

    if (isFromBatch) {
      navigate(`/batches/${sessionStorage.getItem('abatchid')}/courses`);
    } else {
      navigate("/learningpath");
    }
  };

  const handleBackFromQuestion = () => {
    navigate(`/library/${constants.libTypeIds.CONTENT}/questionlist`);
  };

  const handleGetSignedUrl = async (linkData) => {
    getPreSignedUrl(linkData)
      .then((res) => {
        window.open(res, "_blank", "noreferrer");
      })
      .catch((err) => {
        if (err.message.includes("403")) {
          setShow(true);
          setIsUnAuth(true);
          setTitle("Error");
          setMsg("You have been logged-out due to inactivity. Login again.");
        }
      });
  };

  const handleEmbedlinkChange = (e) => {
    setEmbedLink(e.target.value);
  };

  const addEmbedLinks = (e) => {
    e.preventDefault();

    if (embedLink) {
      try {
        setEmbedLinksList([...embedLinksList, embedLink]);
        setEmbedLink("");
        setErrors({
          url: "",
          ...Errors,
        });
      } catch (error) {
        setErrors({
          ...Errors,
          url: "Invalid URL",
        });
      }
    }
  };

  const embedLinkDeleteButtonClick = (event, index) => {
    event.preventDefault();

    const temp = [...embedLinksList];

    temp.splice(index, 1);
    setEmbedLinksList(temp);
  };

  const handleUpdate = (value, editor) => {
    handleInputChange(value, "description");
  };

  return (
    <div>
      <Form id="identifier">
        {fromList.isFromList || isFromBatch || isOldLpDetail || isFromLp ? (
          <div style={{ textAlign: "left" }}>
            <FontAwesomeIcon
              icon={faArrowLeft}
              onClick={handleBackBtn}
              className="adminTestBackBtn"
            />
            &nbsp;
            <span className="adminTestBack" onClick={handleBackBtn}>
              {Props.tableShowStatus === constants.libTypeIds.ASSIGNMENT
                ? "ASSIGNMENT"
                : "CONTENT"}
            </span>
          </div>
        ) : (
          <div style={{ textAlign: "left" }}>
            <FontAwesomeIcon
              icon={faArrowLeft}
              className="adminTestBackBtn"
              onClick={handleBackFromQuestion}
            />
            &nbsp;
            <span className="adminTestBack" onClick={handleBackFromQuestion}>
              {" "}
              Back{" "}
            </span>
          </div>
        )}
        {UserInput.success && <p className="ctnterrors">{UserInput.result}</p>}
        {!UserInput.success && <h3>{UserInput.result}</h3>}
        <br />
        <Modules
          reset={addQuestion}
          fromList={fromList}
          isFromLp={isFromLp}
          isFromBatch={isFromBatch}
          setvalue={handleInputChange}
          isOldLpDetail={isOldLpDetail}
          selectmodule={Props.selectque}
          isqueReport={Props.isqueReport}
          alreadySelected={alreadySelected}
          isSelectedTopic={isSelectedTopic}
          tableShowStatus={Props.tableShowStatus}
          setalreadySelected={setalreadySelected}
          setisSelectedTopic={setisSelectedTopic}
        />{" "}
        <br />
        <div className="content_name">
          <label htmlFor="content_name">
            Name<span style={{ color: "red", width: "4px" }}> *</span>
          </label>
          <br />
          <input
            name="name"
            type="text"
            id="content_name"
            value={UserInput.name}
            className="form-control"
            onChange={handleInputChange}
          />
        </div>{" "}
        <br />
        <div className="content_description">
          <label htmlFor="content_description">
            {Props.tableShowStatus === constants.libTypeIds.ASSIGNMENT
              ? "Instruction"
              : "Description"}
          </label>
          <br />
          <div></div>
        </div>{" "}
        <div className="ctnteditor-tinymce">
          <Editor
          tinymceScriptSrc='/tinymce/tinymce.min.js'
            onEditorChange={handleUpdate}
            value={UserInput.description}
            onInit={(evt, editor) => (editorRef.current = editor)}
            init={{
              license_key: 'gpl',
              readonly: true,
              menubar: false,
              min_height: 150,
              branding: false,
              statusbar: false,
              elementpath: false,
              selector: "textarea",
              allow_html_in_named_anchor: true,
              plugins: [
                "link",
                "help",
                "code",
                "lists",
                "image",
                "table",
                "media",
                "anchor",
                "editor",
                "advlist",
                "preview",
                "charmap",
                "wordcount",
                "fullscreen",
                "autoresize",
                "visualblocks",
                "searchreplace",
                "insertdatetime",
              ],
              toolbar:
                Props.tableShowStatus === constants.libTypeIds.CONTENT ||
                Props.tableShowStatus === constants.libTypeIds.ASSIGNMENT
                  ? "undo redo | blocks | " +
                    "bold italic underline forecolor | link | alignleft aligncenter " +
                    "alignright alignjustify | bullist numlist outdent indent | " +
                    "removeformat | image | table tabledelete | tableprops tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol | help |"
                  : "undo redo | blocks | " +
                    "bold italic underline forecolor | link | alignleft aligncenter " +
                    "alignright alignjustify | bullist numlist outdent indent | " +
                    "removeformat | help |",
              content_style:
                "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
            }}
          />
        </div>
        <br />
        <br />
        <div className="ctntembedLink">
          <p className="errors">{Errors.url}</p>
          <label for="ctntembedLink">Embeded Links</label>
          <br />
          <input
            type="text"
            value={embedLink}
            id="ctntembedLink"
            name="ctntembedLink"
            className="form-control"
            onChange={handleEmbedlinkChange}
            placeholder="Type something here"
          />
          <button className="ctntembedLinkBtn" onClick={addEmbedLinks}>
            <FontAwesomeIcon icon={faPlus} />
          </button>
        </div>
        <div className="ctntembedLinkView">
          <ul className="ctntembed-list">
            {embedLinksList?.map((text, index) => (
              <div className="ctntembededLinkLists">
                <li key={index}>{text}</li>
                <button
                  style={{ border: "none", background: "none" }}
                  onClick={(event) => embedLinkDeleteButtonClick(event, index)}
                >
                  {Props.selectque?.published === 0 && (
                    <FontAwesomeIcon className="faTrashCan" icon={faTrashCan} />
                  )}
                </button>
              </div>
            ))}
          </ul>
        </div>
        <br />
        {isLoaded && <Loading />}
        <div className="content_fileupload">
          <input
            hidden
            multiple
            type="file"
            name="file"
            onChange={uploadHandler}
            key={selectedFile.length}
            className="contentinput-field"
            onClick={(e) => {
              e.target.value = null;
            }} // Reset input value
            onBlur={(e) => {
              e.target.form.dispatchEvent(new Event("submit"));
            }}
          />
          <div
            className="content_fileupload_trigger"
            onClick={() =>
              document.querySelector(".contentinput-field").click()
            }
          >
            <span
              className="contentUploadFileName"
              style={{
                fontSize: "14px",
                color: "#1475cf",
                fontFamily: "Inter",
              }}
            >
              <span className="libUploadIcon">
                <FontAwesomeIcon
                  icon={faArrowUpFromBracket}
                  className="contentUploadIcon"
                />
              </span>
              {fileName}
            </span>
          </div>
        </div>
        <div className="content_fileupload" onClick={getfilesfromlibrary}>
          <span
            className="contentUploadFileName"
            style={{
              fontSize: "14px",
              color: "#1475cf",
              fontFamily: "Inter",
            }}
          >
            <span className="libUploadIcon">
              <FontAwesomeIcon
                icon={faArrowUpFromBracket}
                className="contentUploadIcon"
              />
            </span>
            {fileLibraryName}
          </span>
        </div>
        <Modal show={showFromLibrary} onHide={handleCloselib} centered>
          <div>
            <Tab.Container defaultActiveKey="first">
              <Nav variant="pills">
                <Nav.Item className="content-navItem">
                  <Nav.Link eventKey="first">Content</Nav.Link>
                </Nav.Item>
                <Nav.Item className="content-navItem">
                  <Nav.Link eventKey="second">Misc</Nav.Link>
                </Nav.Item>
              </Nav>

              <Tab.Content>
                <Tab.Pane eventKey="first">
                  <DataTable
                    selectableRows
                    columns={columns}
                    customStyles={customStyles}
                    className="customDatatable"
                    data={filesFromLib.contentlinksdata}
                    onSelectedRowsChange={handleChange}
                  />
                </Tab.Pane>
                <Tab.Pane eventKey="second">
                  <DataTable
                    selectableRows
                    columns={columns}
                    customStyles={customStyles}
                    className="customDatatable"
                    data={filesFromLib.misclinksdata}
                    onSelectedRowsChange={handleChange}
                  />
                </Tab.Pane>
              </Tab.Content>
              <button
                type="button"
                id="addLibrary"
                onClick={handleAddbtn}
                className="btn btn-primary"
              >
                <span>Add</span>
              </button>
            </Tab.Container>
          </div>
        </Modal>
        {/* showing lists */}
        <div className="ctntshowinglist">
          <ul className="ctntfile-lists">
            {selectedFile?.map((f, index) => (
              <>
                <li key={index}>
                  <FontAwesomeIcon icon={faFileAlt} />
                  <a
                    href={f.url}
                    target="_blank"
                    rel="noreferrer"
                    className="ctntlistsName"
                  >
                    {f.file.name}
                  </a>

                  {!Props.isFromBatch &&
                    !Props.isOldLpDetail &&
                    !Props.isFromLp &&
                    Props.selectque.published === 0 && (
                      <div className="actions">
                        <FontAwesomeIcon
                          icon={faTrash}
                          className="ctnttrashIconforDeleteFile"
                          onClick={() => deleteUploadFile(f.file.name)}
                        />
                      </div>
                    )}
                </li>
              </>
            ))}
          </ul>
        </div>
        {/* library upload */}
        <div className="ctntshowinglist">
          <ul className="ctntfile-lists">
            {libraryLinksData?.map((i, index) => (
              <li key={index}>
                <FontAwesomeIcon icon={faFileAlt} />
                {process.env.REACT_APP_DEPLOYMENT !== "AWS" ? (
                  <a
                    target="_blank"
                    rel="noreferrer"
                    className="ctntlistsName"
                    href={String(process.env.REACT_APP_NODE_API) + i.link}
                  >
                    {i.link.substring(
                      i.link.lastIndexOf("/") + 1,
                      i.link.length
                    )}
                  </a>
                ) : (
                  <p
                    className="ctntlistsName"
                    onClick={() => handleGetSignedUrl(i.link)}
                  >
                    {i.link.substring(
                      i.link.lastIndexOf("/") + 1,
                      i.link.length
                    )}
                  </p>
                )}

                <div className="actions">
                  <FontAwesomeIcon
                    icon={faTrash}
                    onClick={() => deletelibFile(i.link)}
                    className="ctnttrashIconforDeleteFile"
                  />
                </div>

                {Props.tableShowStatus === constants.libTypeIds.ASSIGNMENT && (
                  <div className="ctntcheckisDownload">
                    <input
                      type="checkbox"
                      checked={i.checked}
                      onChange={(e) => handleFileDownloadChangeforLink(index)}
                    />
                  </div>
                )}
              </li>
            ))}
          </ul>
        </div>
        <Notify
          show={show}
          title={title}
          message={msg}
          onHide={
            isUnAuth
              ? handleUnAuthAlert
              : resultCode === 1000
              ? handleNavigate
              : handleClose
          }
        />
        <div className="content_savebtn">
          <button
            onClick={(e) => {
              addQuestion(e);
            }}
            disabled={false}
          >
           {Props.isqueReport? "Update":"Save"}
          </button>
        </div>
        <div style={{ height: "100px", float: "left" }}></div>
      </Form>
    </div>
  );
}

export default ContentEntry;
