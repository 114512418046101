import React from 'react';
import Box from '@mui/material/Box';
import { Typography, Tooltip } from '@mui/material';
import constants from "../../constants"

export default function SessionDetail(props) {
  return (
    <div style={{width:'fit-content'}}>
        {/* <Tooltip> */}
            <Box className='tooltip-box-sessions-box'>
                <div className='tooltip-box-sessions-div'>
                    <div className='tooltip-box-sessions-name'>{props.sessionName}</div>
                    <div 
                    className={`tooltip-box-sessions-attendance-status-${props.studentStatus == constants.ATTENDANCE_STATUS.NO_RESPONSE?'noresponse':(props.studentStatus == constants.ATTENDANCE_STATUS.PARTIALLY_PRESENT || props.studentStatus == constants.ATTENDANCE_STATUS.PRESENT)?'present':'absent'}`}
                    >
                        {props.studentStatus == constants.ATTENDANCE_STATUS.NO_RESPONSE?'No Response':(props.studentStatus == constants.ATTENDANCE_STATUS.PARTIALLY_PRESENT || props.studentStatus == constants.ATTENDANCE_STATUS.PRESENT)?'Present':'Absent'}
                    </div> 
                        
                {/* {`${props.startdate.split(',')[1].trim()} - ${props.endtime}`} */}
                </div>
                <div className='tooltip-box-date-time-div'>
                    <div className='tooltip-box-startdate-div'>{props.startdate}</div>
                    <div className='tooltip-box-time-div'>{`(${props.startTime} - ${props.endTime})`}</div>
                </div>
            </Box>
        {/* </Tooltip> */}
    </div>
  )
}
