import React from "react";

export default function RightArrowSvgIcon() {
  return (
    <svg
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        stroke="white"
        stroke-width="1.67"
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M4.16699 10.0013H15.8337M15.8337 10.0013L10.0003 4.16797M15.8337 10.0013L10.0003 15.8346"
      />
    </svg>
  );
}
