import React from "react";

export default function Checkbox({
  checked,
  indeterminate,
  size,
  text,
  supportingText,
  disabled,
  onChange,
}) {
  const handleCheckboxChange = (event) => {
    if (onChange) {
      onChange(disabled ? checked : event.target.checked);
    }
  };

  return (
    <div
      className="checkboxContainer"
      onClick={() => onChange(disabled ? checked : !checked)}
      style={{ cursor: disabled ? "not-allowed" : "pointer" }}
    >
      <input
        className={size === "md" ? "checkboxInputMedium" : "checkboxInputSmall"}
        type="checkbox"
        disabled={disabled}
        checked={checked}
        onChange={handleCheckboxChange}
      />
      <div className={size === "md" ? "checkboxMedium" : "checkboxSmall"}>
        {(checked || checked === 1) &&
          !indeterminate &&
          (size === "md" ? (
            <svg
              id="checkboxIconMedium"
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.6666 3.5L5.24992 9.91667L2.33325 7"
                stroke="#F55533"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          ) : (
            <svg
              id="checkboxIconSmall"
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10 3L4.5 8.5L2 6"
                stroke="#F55533"
                strokeWidth="1.6666"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          ))}
        {(checked || checked === 1) &&
          indeterminate &&
          (size === "md" ? (
            <svg
              id="checkboxIconMedium"
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2.91675 7H11.0834"
                stroke="#F55533"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          ) : (
            <svg
              id="checkboxIconSmall"
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2.5 6H9.5"
                stroke="#F55533"
                strokeWidth="1.66666"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          ))}
      </div>
      <div
        className={
          size === "md"
            ? "checkboxLabelSectionMedium"
            : "checkboxLabelSectionSmall"
        }
      >
        <span
          className={`${
            size === "md" ? "checkboxLabelMedium" : "checkboxLabelSmall"
          }`}
          id={supportingText ? "" : "checkboxLabel"}
        >
          {text}
        </span>
        <span
          className={
            size === "md"
              ? "checkboxSupportTextMedium"
              : "checkboxSupportTextSmall"
          }
        >
          {supportingText}
        </span>
      </div>
    </div>
  );
}
