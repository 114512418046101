import React from "react";

export default function ReviewSvgIcon(Props) {
  return (
    <div className="reviewSvgIcon">
      {Props.mark === "mark" ? (
        <svg
          width="18"
          height="20"
          fill="none"
          viewBox="0 0 14 18"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill="url(#paint0_linear_1271_5019)"
            d="M14 18L7 13L0 18V2C0 1.46957 0.210714 0.960859 0.585786 0.585786C0.960859 0.210714 1.46957 0 2 0H12C12.5304 0 13.0391 0.210714 13.4142 0.585786C13.7893 0.960859 14 1.46957 14 2V18Z"
          />
          <defs>
            <linearGradient
              x2="17.446"
              y1="17.9999"
              y2="4.43057"
              x1="-0.000218022"
              id="paint0_linear_1271_5019"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#101828" />
              <stop offset="1" stop-color="#475467" />
            </linearGradient>
          </defs>
        </svg>
      ) : (
        <svg
          width="16"
          height="20"
          fill="none"
          viewBox="0 0 16 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            stroke="#667085"
            stroke-width="1.2"
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M15 19L8 14L1 19V3C1 2.46957 1.21071 1.96086 1.58579 1.58579C1.96086 1.21071 2.46957 1 3 1H13C13.5304 1 14.0391 1.21071 14.4142 1.58579C14.7893 1.96086 15 2.46957 15 3V19Z"
          />
        </svg>
      )}
    </div>
  );
}
