import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { changeFavicon } from "../../util";
import AlertContext from "../../context/AlertContext";
import GradiousLogo from "../../Svg/GradiousLogo";

const CompanyLogo = ({ hideCompanyName, component }) => {
  const { isAdmin } = useContext(AlertContext);
  const { pathname } = useLocation();
  const [logoComponent, setLogoComponent] = useState(null);

  useEffect(() => {
    if (pathname !== "/batch" && !isAdmin) {
      if (localStorage.getItem("favicon")&&localStorage.getItem("favicon")!==""&&localStorage.getItem("favicon")!=="null") {
        changeFavicon(localStorage.getItem("favicon"));
      }else changeFavicon("/favicon.ico");
      setLogoComponent(
        localStorage.getItem("logo") &&
          localStorage.getItem("logo") !== "" &&
          localStorage.getItem("logo") !== "null" ? (
          <div className="companyLogoContainer">
            <img
              className="companyLogo"
              alt="logo"
              src={localStorage.getItem("logo")}
            />
            <div
              className={
                component === "leftNav"
                  ? "companyLogoTextOnLeftNav"
                  : "companyLogoText"
              }
            >
              Powered by Gradious
            </div>
          </div>
        ) : (
          <GradiousLogo
            hideCompanyName={hideCompanyName}
            component={component}
          />
        )
      );
    } else {
      setLogoComponent(
        <GradiousLogo hideCompanyName={hideCompanyName} component={component} />
      );
    }
  }, [isAdmin, pathname, component, hideCompanyName]);

  return <>{logoComponent}</>;
};

export default CompanyLogo;
