import { Table } from "antd";
import { nanoid } from "nanoid";
import Tooltip from "antd/es/tooltip";
import constants from "../../../../constants";
import { default as ReactSelect } from "react-select";
import PreviewIcon from "@mui/icons-material/Preview";
import useAxiosPrivate from "../../../useAxiosPrivate";
import { MultiSelect } from "react-multi-select-component";
import AlertContext from "../../../../context/AlertContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useCommonFunctionHooks from "../../../useCommonFunctionHooks";
import { MockTestPageContext } from "../../../../context/MockTestPageContext";
import ExpandableTable from "../../../../Components/Admin/MockPage/MockTest/MockTestConfig/ExpandableTable";

import React, {
  useRef,
  useState,
  createRef,
  useContext,
  useCallback,
} from "react";

import {
  faXmark,
  faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons";

export default function MockTestConfigTableHooks() {
  const { setIsLoaded, oldTestConfig, setShowNotify } =
    useContext(AlertContext);

  const {
    focus,
    topics,
    setFocus,
    testData,
    qnsCount,
    isNewTest,
    setIsNext,
    languages,
    testConfig,
    complexity,
    listQnData,
    setQnsCount,
    setTestData,
    setTestConfig,
    setListQnData,
    setShowQnList,
    setSelectedQns,
    setIsBackDetails,
    descriptiveOptions,
    isSufficientQuestions,
    insufficientQuestions,
  } = useContext(MockTestPageContext);

  const axios = useAxiosPrivate();

  const { getCatchBlockDetails, getHeaderDetailsForAxios } =
    useCommonFunctionHooks();

  const sections = [Table.EXPAND_COLUMN];

  const specificField =
    testData.testType.value === constants.libTypeIds.MCQ
      ? 1
      : testData.testType.value === constants.libTypeIds.DESCRIPTIVE
      ? 2
      : 3;

  const customStylesForTopic = {
    control: (base, state) => ({
      ...base,
      border: "none",
      height: "38px",
      overflow: "auto",
      paddingLeft: "7px",
      backgroundColor: "#ffffff",
      cursor: state.isDisabled ? "default" : "pointer",
      "&:hover": {
        outline: "none",
        border: state.isFocused ? "1px solid #BDC1C6" : "none",
      },
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        margin: "0px",
        height: "30px",
        fontSize: "12px",
        textAlign: "left",
        fontWeight: "600",
        lineHeight: "15px",
        fontFamily: "Inter",
        color: isSelected ? "#000000" : undefined,
        backgroundColor: isFocused ? "#e9ecef" : undefined,
      };
    },
    singleValue: (styles, { data }) => ({
      ...styles,
      color: "#202124",
      fontSize: "14px",
      fontWeight: "400",
      lineHeight: "20px",
      fontFamily: "Inter",
      fontStyle: "normal",
      textAlign: "center",
      borderRadius: "20px",
    }),
    menuList: (base) => ({
      ...base,
      maxHeight: "150px",
    }),
  };

  const customStylesForLevel = {
    control: (base, state) => ({
      ...base,
      border: "none",
      height: "38px",
      paddingLeft: "7px",
      "&:hover": {
        outline: "none",
        border: state.isFocused ? "1px solid #BDC1C6" : "none",
      },
      overflow: "auto",
      textAlign: "center",
      backgroundColor: "#ffffff",
      cursor: state.isDisabled ? "default" : "pointer",
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        margin: "0px",
        height: "30px",
        fontSize: "12px",
        textAlign: "left",
        fontWeight: "600",
        lineHeight: "15px",
        fontFamily: "Inter",
        color: isSelected ? "#000000" : undefined,
        backgroundColor: isFocused ? "#e9ecef" : undefined,
      };
    },
    singleValue: (styles, { data }) => ({
      ...styles,
      fontSize: "12px",
      fontWeight: "500",
      marginLeft: "50px",
      lineHeight: "20px",
      fontFamily: "Inter",
      fontStyle: "normal",
      textAlign: "center",
      borderRadius: "20px",
      color: data.value === 6 || data.value === 7 ? "#000000" : "#FFFFFF",
      backgroundColor:
        data.label.toLowerCase() === "easy"
          ? "#04B462"
          : data.label.toLowerCase() === "medium"
          ? "#2B75E4"
          : data.value === 6 || data.value === 7
          ? "#FFFFFF"
          : "#D9435F",
      width:
        data.label.toLowerCase() === "easy"
          ? "53px"
          : data.label.toLowerCase() === "medium"
          ? "72px"
          : "70px",
    }),
    placeholder(base, state) {
      return {
        ...base,
        display: state.isDisabled && "none",
      };
    },
    menuList: (base) => ({
      ...base,
      maxHeight: "150px",
    }),
  };

  const [tooltipData, setTooltipData] = useState(null);
  const [cursorPointer, setCursorPointer] = useState(0);
  const [topicExpandedKey, setTopicExpandedKey] = useState();

  const multiSelectRefs = useRef(testConfig.map(() => createRef()));

  const ref = useCallback(
    (node) => {
      if (node && parseInt(focus) === parseInt(node.id)) {
        if (node) {
          node.selectionStart = cursorPointer;
          node.selectionEnd = cursorPointer;
          node.focus();
        }
      }

      if (
        node !== null &&
        node !== undefined &&
        node.inputRef &&
        node.inputRef !== undefined
      ) {
        if (parseInt(focus) === node.inputRef.id) node.inputRef.focus();
      }
    },
    [focus, cursorPointer]
  );

  const handleDeleteSection = (sectionId) => {
    setTestConfig((testConfig) =>
      testConfig.filter((config) => config.id !== sectionId)
    );
  };

  const handleEnterKey = (event, data) => {
    if (event.keyCode === 13) {
      setTopicExpandedKey([data.key]);
      setFocus(data.id + 1);
    }
  };

  const handleSectionName = (e, sectionId) => {
    setCursorPointer(e.target.selectionStart);
    setFocus(e.target.id);

    let newDetails = [...testConfig];

    setFocus(sectionId);

    for (let i = 0; i < newDetails.length; i++) {
      if (newDetails[i].id === sectionId) {
        newDetails[i][e.target.name] = e.target.value;
      }
    }

    setTestConfig(newDetails);
  };

  const handleBackBtn = () => {
    setIsNext(false);
    setIsBackDetails({ isBack: true, testType: testData.testType });

    let newDetails = JSON.parse(JSON.stringify(testData));

    setQnsCount(qnsCount);
    setTestData(newDetails);
    newDetails.sections = testConfig;
  };

  const handleDeleteLang = (langId, configId, secId) => {
    let newDetails = JSON.parse(JSON.stringify(testConfig));

    if (testData.published === 0 || isNewTest) {
      for (let sec = 0; sec < newDetails.length; sec++) {
        for (
          let secData = 0;
          secData < newDetails[sec].secData.length;
          secData++
        ) {
          if (
            newDetails[sec].id === secId &&
            newDetails[sec].secData[secData].id === configId
          ) {
            newDetails[sec].secData[secData].languages = newDetails[
              sec
            ].secData[secData].languages.filter(
              (lang) => lang.value !== langId
            );

            if (
              oldTestConfig.isOldTestConfig &&
              newDetails[sec].secData[secData].testStatus === "old"
            )
              newDetails[sec].secData[secData].type = "update";
            else if (
              oldTestConfig.isOldTestConfig &&
              newDetails[sec].secData[secData].testStatus === "new"
            )
              newDetails[sec].secData[secData].type = "insert";
          }
        }
      }
    }

    setTestConfig(newDetails);
  };

  const customValueRenderer = (selected, _options, record, secId) => {
    return selected.length
      ? selected.map(({ value, label }) => (
          <>
            <span className="adminTestConfigLabel">
              {label}
              <FontAwesomeIcon
                icon={faXmark}
                id="adminTestConfigLabelXMark"
                onClick={() => handleDeleteLang(value, record.id, secId)}
              />
            </span>
          </>
        ))
      : "Select the language";
  };

  const renderExclamationIcon = (select, record) => {
    const showIcon =
      insufficientQuestions[record.id] &&
      insufficientQuestions[record.id].length > 0;

    return (
      <div
        className="renderExclamation"
        onMouseLeave={() => setTooltipData(null)}
        onMouseEnter={() => setTooltipData(record.key)}
      >
        {!isSufficientQuestions && showIcon && (
          <Tooltip
            title={
              <ExpandableTable topicData={insufficientQuestions[record.id]} />
            }
            placement="rightBottom"
            popupVisible={tooltipData === record.key}
          >
            <span>
              <FontAwesomeIcon
                className="exclamation"
                icon={faTriangleExclamation}
              />
            </span>
          </Tooltip>
        )}
      </div>
    );
  };

  const handleAddSection = () => {
    let section;

    if (specificField === 1) {
      section = {
        id: "",
        secName: "",
        key: nanoid(),
        secData: [
          {
            id: "",
            marks: "",
            key: nanoid(),
            complexity: "",
            fixedQnCount: "",
            randomQnCount: "",
          },
        ],
      };
    } else if (specificField === 2) {
      section = {
        id: "",
        secName: "",
        key: nanoid(),
        secData: [
          {
            id: "",
            marks: "",
            desType: "",
            key: nanoid(),
            complexity: "",
            fixedQnCount: "",
            randomQnCount: "",
          },
        ],
      };
    } else {
      section = {
        id: "",
        secName: "",
        key: nanoid(),
        secData: [
          {
            id: "",
            marks: "",
            key: nanoid(),
            languages: "",
            complexity: "",
            durationNum: "",
            fixedQnCount: "",
            randomQnCount: "",
            durationUnit: "day/s",
          },
        ],
      };
    }

    section.isOld = false;
    section.status = "new";
    section.testStatus = "new";
    section.secData[0].isOld = false;
    section.secData[0].status = "new";
    section.secData[0].testStatus = "new";

    if (oldTestConfig.isOldTestConfig) {
      section.type = "insert";
      section.secData[0].type = "insert";
    }

    let newDetails = [...testConfig],
      uniqueId = 1,
      focusId;

    newDetails.push(section);

    for (let i = 0; i < newDetails.length; i++) {
      newDetails[i].id = uniqueId;

      if (i === newDetails.length - 1) focusId = uniqueId;

      uniqueId++;

      for (let j = 0; j < newDetails[i].secData.length; j++) {
        newDetails[i].secData[j].id = uniqueId;

        let keysLength = 8;

        uniqueId += keysLength;
      }
    }

    setTestConfig(newDetails);
    setFocus(focusId);
  };

  const handleAddTopic = (data) => {
    let sectionData;

    if (specificField === 1) {
      sectionData = {
        id: "",
        marks: "",
        key: nanoid(),
        complexity: "",
        fixedQnCount: "",
        randomQnCount: "",
      };
    } else if (specificField === 2) {
      sectionData = {
        id: "",
        marks: "",
        desType: "",
        key: nanoid(),
        complexity: "",
        fixedQnCount: "",
        randomQnCount: "",
      };

      if (testData.videoQn) {
        delete sectionData.desType;
        sectionData.durationNum = "";
        sectionData.durationUnit = "day/s";
      }
    } else {
      sectionData = {
        id: "",
        marks: "",
        topic: "",
        key: nanoid(),
        languages: "",
        complexity: "",
        durationNum: "",
        fixedQnCount: "",
        randomQnCount: "",
        durationUnit: "day/s",
      };
    }

    sectionData.status = "new";
    sectionData.testStatus = "new";

    if (oldTestConfig.isOldTestConfig) sectionData.type = "insert";

    let newDetails = [...testConfig],
      uniqueId = 1;

    for (let i = 0; i < newDetails.length; i++) {
      newDetails[i].id = uniqueId;
      uniqueId++;

      if (data.id === newDetails[i].id) newDetails[i].secData.push(sectionData);

      for (let j = 0; j < newDetails[i].secData.length; j++) {
        newDetails[i].secData[j].id = uniqueId;

        if (
          data.id === newDetails[i].id &&
          j === newDetails[i].secData.length - 1
        )
          setFocus(uniqueId);

        let keysLength = 8;

        uniqueId += keysLength;
      }
    }

    setTestConfig(newDetails);
  };

  const handleAddQnsFromList = (qnIds) => {
    let newDetails = [...testConfig];

    for (let i = 0; i < newDetails.length; i++) {
      let qnids = "";

      for (let j = 0; j < newDetails[i].secData.length; j++) {
        if (
          newDetails[i].id === listQnData.sectionId &&
          newDetails[i].secData[j].id === listQnData.secDataId
        ) {
          if (qnIds.length > 0) {
            if (
              qnIds.length > parseInt(newDetails[i].secData[j].fixedQnCount)
            ) {
              setShowNotify({
                show: true,
                title: "Warning",
                msg: `You have configured ${
                  newDetails[i].secData[j].fixedQnCount
                } questions but have selected ${qnIds.length} ${
                  qnIds.length === 1 ? "question" : "questions"
                }. Please select accordingly.`,
              });
            } else if (
              qnIds.length < parseInt(newDetails[i].secData[j].fixedQnCount)
            ) {
              setShowNotify({
                show: true,
                title: "Warning",
                msg: `You have configured ${
                  newDetails[i].secData[j].fixedQnCount
                } questions but have selected ${qnIds.length} ${
                  qnIds.length === 1 ? "question" : "questions"
                }. Please select accordingly.`,
              });
            } else {
              for (let k = 0; k < qnIds.length; k++) {
                if (k < qnIds.length - 1) qnids += qnIds[k] + ",";
                else qnids += qnIds[k];
              }
              setShowQnList(false);
              setSelectedQns([]);
            }
          } else {
            setShowNotify({
              show: true,
              title: "Warning",
              msg: "There is no selected question",
            });
          }

          newDetails[i].secData[j].fixedQnId = qnids;
        }
      }
    }
  };

  const handleDeleteTopicConfigData = (
    sectionId,
    tConfigId,
    status,
    testConfigId
  ) => {
    let newDetails = [...testConfig];

    if (status === "old") {
      setIsLoaded(true);
      axios
        .post(
          "node/admin/test/testconfig/delete",
          JSON.stringify({ testConfigId: testConfigId }),
          getHeaderDetailsForAxios()
        )
        .then((res) => {
          if (res.data.resultCode === constants.RESULT_STATUS.SUCCESS) {
            for (let i = 0; i < newDetails.length; i++) {
              for (let j = 0; j < newDetails[i].secData.length; j++) {
                if (
                  newDetails[i].id === sectionId &&
                  newDetails[i].secData[j].id === tConfigId
                ) {
                  newDetails[i].secData.splice(j, 1);
                  setShowNotify({
                    show: true,
                    title: "Success",
                    msg: "The test configuration is deleted successfully.",
                  });
                }
              }
            }
          } else {
            setShowNotify({
              show: true,
              title: "Error",
              msg: "Something went wrong. Please try again later",
            });
          }
        })
        .catch((err) => {
          getCatchBlockDetails(err);
        })
        .finally(() => setIsLoaded(false));
    } else {
      for (let i = 0; i < newDetails.length; i++) {
        for (let j = 0; j < newDetails[i].secData.length; j++) {
          if (
            newDetails[i].id === sectionId &&
            newDetails[i].secData[j].id === tConfigId
          ) {
            newDetails[i].secData.splice(j, 1);
          }
        }
      }
    }

    setTestConfig(newDetails);
  };

  const handleOpenFixedQuestionsLists = async (secData, sectionId) => {
    let libtypeid =
      specificField === 2 ? secData.desType.value : testData.testType.value;

    setIsLoaded(true);

    try {
      let result = await handleGetQuestionList(
        secData.topic.value,
        secData.complexity.value,
        libtypeid,
        secData.languages
      );

      if (result.resultCode === constants.RESULT_STATUS.SUCCESS) {
        let newDetails = JSON.parse(JSON.stringify(testConfig));

        for (const section of newDetails) {
          for (const item of section.secData) {
            if (section.id === sectionId && item.id === secData.id) {
              let fixedQnIds = secData.fixedQnId.split(",");

              for (let fixedQnId of fixedQnIds) {
                for (
                  let question = 0;
                  question < result.data.length;
                  question++
                ) {
                  if (result.data[question].id === parseInt(fixedQnId)) {
                    result.data[question].selected = true;
                  }
                }
              }

              setSelectedQns(
                fixedQnIds.map((fixedQnId) => (fixedQnId = parseInt(fixedQnId)))
              );
            }
          }
        }

        setListQnData({ sectionId, secDataId: secData.id, data: result.data });
        setShowQnList(true);
      } else {
        setShowNotify({
          show: true,
          title: "Warning",
          msg: "Something went wrong. Please try again later",
        });
      }
    } catch (error) {
      getCatchBlockDetails(error);
    } finally {
      setIsLoaded(false);
    }
  };

  const handleGetQuestionList = async (
    topicid,
    complexityid,
    libtypeid,
    languages
  ) => {
    try {
      let response = await axios.post(
        "node/admin/question/getbytopicandcomplexity",
        JSON.stringify({
          topicid,
          languages,
          complexityid,
          libtypeid: libtypeid,
        }),
        getHeaderDetailsForAxios()
      );

      return response.data;
    } catch (error) {
      return error;
    }
  };

  const handleTopicConfigData = async (data, e, fieldName, sectionId) => {
    let newDetails = [...testConfig],
      regexForNum = /^[0-9]\d*$/;

    fieldName === "topic"
      ? setFocus(data.id)
      : fieldName === "desType"
      ? setFocus(data.id + 1)
      : fieldName === "complexity"
      ? setFocus(data.id + 2)
      : fieldName === "languages"
      ? setFocus(data.id + 3)
      : setFocus(e.target.id);

    if (
      fieldName === "topic" ||
      fieldName === "desType" ||
      fieldName === "languages" ||
      fieldName === "complexity"
    ) {
      for (let i = 0; i < newDetails.length; i++) {
        for (let j = 0; j < newDetails[i].secData.length; j++) {
          if (
            newDetails[i].id === sectionId &&
            newDetails[i].secData[j].id === data.id
          ) {
            newDetails[i].secData[j][fieldName] = e;

            if (
              oldTestConfig.isOldTestConfig &&
              newDetails[i].secData[j].testStatus === "old"
            )
              newDetails[i].secData[j].type = "update";
            else if (
              oldTestConfig.isOldTestConfig &&
              newDetails[i].secData[j].testStatus === "new"
            )
              newDetails[i].secData[j].type = "insert";
          }
        }
      }
    } else {
      setCursorPointer(e.target.selectionStart);

      let fieldValue;

      if (fieldName === "durationUnit") fieldValue = e.target.value;
      else {
        if (e.target.value !== "") {
          if (regexForNum.test(e.target.value))
            fieldValue = Number(e.target.value);
          else {
            setShowNotify({
              show: true,
              title: "Warning",
              msg: "Please enter valid number",
            });

            fieldValue = "";
          }
        }
      }

      for (let i = 0; i < newDetails.length; i++) {
        for (let j = 0; j < newDetails[i].secData.length; j++) {
          if (
            newDetails[i].id === sectionId &&
            newDetails[i].secData[j].id === data.id
          ) {
            newDetails[i].secData[j][fieldName] = fieldValue;

            if (
              oldTestConfig.isOldTestConfig &&
              newDetails[i].secData[j].testStatus === "old"
            )
              newDetails[i].secData[j].type = "update";
            else if (
              oldTestConfig.isOldTestConfig &&
              newDetails[i].secData[j].testStatus === "new"
            )
              newDetails[i].secData[j].type = "insert";

            let randomCount = 0,
              fixedCount = 0;

            if (regexForNum.test(newDetails[i].secData[j].fixedQnCount))
              fixedCount = parseInt(newDetails[i].secData[j].fixedQnCount);

            if (regexForNum.test(newDetails[i].secData[j].randomQnCount))
              randomCount = parseInt(newDetails[i].secData[j].randomQnCount);

            setQnsCount(randomCount + fixedCount);

            if (fieldName === "fixedQnCount") {
              if (newDetails[i].secData[j].topic === "") {
                setShowNotify({
                  show: true,
                  title: "Warning",
                  msg: "Please select topic",
                });

                newDetails[i].secData[j][fieldName] = "";
              } else if (newDetails[i].secData[j].complexity === "") {
                setShowNotify({
                  show: true,
                  title: "Warning",
                  msg: "Please select complexity",
                });

                newDetails[i].secData[j][fieldName] = "";
              } else {
                if (
                  specificField === 2 &&
                  !testData.videoQn &&
                  newDetails[i].secData[j].desType === ""
                ) {
                  setShowNotify({
                    show: true,
                    title: "Warning",
                    msg: "Please select descripitive type",
                  });

                  newDetails[i].secData[j][fieldName] = "";
                } else if (
                  specificField === 3 &&
                  newDetails[i].secData[j].languages === ""
                ) {
                  setShowNotify({
                    show: true,
                    title: "Warning",
                    msg: "Please select languages",
                  });

                  newDetails[i].secData[j][fieldName] = "";
                } else {
                  if (
                    fieldValue !== "" &&
                    fieldValue !== undefined &&
                    fieldValue !== null &&
                    fieldValue !== 0
                  ) {
                    let libtypeid =
                      specificField === 2 && !testData.videoQn
                        ? newDetails[i].secData[j].desType.value
                        : testData.testType.value;

                    setIsLoaded(true);

                    try {
                      let result = await handleGetQuestionList(
                        newDetails[i].secData[j].topic.value,
                        newDetails[i].secData[j].complexity.value,
                        libtypeid,
                        newDetails[i].secData[j].languages
                      );

                      if (
                        result.resultCode === constants.RESULT_STATUS.SUCCESS
                      ) {
                        setListQnData({
                          data: result.data,
                          secDataId: data.id,
                          sectionId: sectionId,
                        });
                        setShowQnList(true);
                      } else if (result.message.includes("403")) {
                        setShowNotify({
                          show: true,
                          title: "Error",
                          msg: "You have been logged-out due to inactivity. Login again..",
                        });
                      } else {
                        setShowNotify({
                          show: true,
                          title: "Warning",
                          msg: "Something went wrong. Please try again later",
                        });
                      }
                    } catch (error) {
                      getCatchBlockDetails(error);
                    } finally {
                      setIsLoaded(false);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    const topicsAndComplexities = {};

    for (const section in newDetails) {
      for (const item in newDetails[section].secData) {
        const { topic, complexity, desType } =
          newDetails[section].secData[item];

        // Generate a unique key based on topic and complexity

        if (complexity !== "") {
          const key =
            specificField !== 2
              ? `${topic.value}_${complexity.value}`
              : specificField === 2 && testData.videoQn
              ? `${topic.value}_${complexity.value}`
              : `${topic.value}_${complexity.value}_${desType.value}`;

          if (topicsAndComplexities[key]) {
            // Duplicate found, show alert message

            setShowNotify({
              show: true,
              title: "Warning",
              msg: `The ${complexity.label} level is already configured for ${topic.label} topic.`,
            });

            newDetails[section].secData[item].complexity = "";

            return;
          } else {
            // Store the key to check for duplicates

            topicsAndComplexities[key] = true;
          }
        }
      }
    }

    setTestConfig(newDetails);
  };

  const sectionData = [
    {
      width: "1%",
      key: nanoid(),
      render: renderExclamationIcon,
    },
    {
      width: "15%",
      key: nanoid(),
      title: "TOPIC",
      dataIndex: "topic",
      render: (select, record) => (
        <>
          {testConfig.map((config) =>
            config.secData.map(
              (secData) =>
                secData.id === record.id && (
                  <ReactSelect
                    name="topic"
                    options={topics}
                    inputId={record.id}
                    tabIndex={record.id}
                    value={secData.topic}
                    allowSelectAll={false}
                    closeMenuOnSelect={false}
                    placeholder="Select Topic"
                    hideSelectedOptions={false}
                    styles={customStylesForTopic}
                    className="adminTestConfigData adminTestTopic"
                    ref={focus && parseInt(focus) === record.id ? ref : null}
                    onChange={(selectedTopic) =>
                      handleTopicConfigData(
                        record,
                        selectedTopic,
                        "topic",
                        config.id
                      )
                    }
                    isDisabled={
                      testData.published === 0 || isNewTest ? false : true
                    }
                    components={
                      testData.published === 0 || isNewTest
                        ? { IndicatorSeparator: () => null }
                        : {
                            DropdownIndicator: () => null,
                            IndicatorSeparator: () => null,
                          }
                    }
                  />
                )
            )
          )}
        </>
      ),
    },
    {
      width: "15%",
      key: nanoid(),
      dataindex: "desType",
      title: "DESCRIPTIVE TYPE",
      render: (select, record) => (
        <>
          {testConfig.map((config) =>
            config.secData.map(
              (secData) =>
                secData.id === record.id && (
                  <ReactSelect
                    name="desType"
                    key={nanoid()}
                    allowSelectAll={false}
                    inputId={record.id + 1}
                    value={secData.desType}
                    tabIndex={record.id + 1}
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    options={descriptiveOptions}
                    styles={customStylesForLevel}
                    placeholder="Select descriptive type"
                    className="adminTestConfigData adminTestDesType "
                    ref={
                      focus && parseInt(focus) === record.id + 1 ? ref : null
                    }
                    components={
                      testData.published === 0 || isNewTest
                        ? { IndicatorSeparator: () => null }
                        : {
                            DropdownIndicator: () => null,
                            IndicatorSeparator: () => null,
                          }
                    }
                    onChange={(selectedDesType) =>
                      handleTopicConfigData(
                        record,
                        selectedDesType,
                        "desType",
                        config.id
                      )
                    }
                    isDisabled={
                      testData.published === 0 || isNewTest ? false : true
                    }
                  />
                )
            )
          )}
        </>
      ),
    },
    {
      width: "15%",
      key: nanoid(),
      title: "LEVEL",
      dataindex: "complexity",
      render: (select, record) => (
        <>
          {testConfig.map((config) =>
            config.secData.map(
              (secData) =>
                secData.id === record.id && (
                  <ReactSelect
                    key={nanoid()}
                    name="complexity"
                    options={complexity}
                    allowSelectAll={false}
                    inputId={record.id + 2}
                    tabIndex={record.id + 2}
                    closeMenuOnSelect={false}
                    placeholder="Select Level"
                    value={secData.complexity}
                    hideSelectedOptions={false}
                    styles={customStylesForLevel}
                    className="adminTestConfigData"
                    components={
                      testData.published === 0 || isNewTest
                        ? { IndicatorSeparator: () => null }
                        : {
                            DropdownIndicator: () => null,
                            IndicatorSeparator: () => null,
                          }
                    }
                    ref={
                      focus && parseInt(focus) === record.id + 2 ? ref : null
                    }
                    onChange={(selectedLevel) =>
                      handleTopicConfigData(
                        record,
                        selectedLevel,
                        "complexity",
                        config.id
                      )
                    }
                    isDisabled={
                      testData.published === 0 || isNewTest ? false : true
                    }
                  />
                )
            )
          )}
        </>
      ),
    },
    {
      width: "15%",
      key: nanoid(),
      dataindex: "languages",
      title: "ALLOWED LANGUAGES",
      render: (select, record) => (
        <>
          {testConfig.map((config) =>
            config.secData.map((secData, index) => {
              return (
                secData.id === record.id && (
                  <MultiSelect
                    disableSearch
                    key={nanoid()}
                    name="languages"
                    options={languages}
                    inputId={record.id + 3}
                    tabIndex={record.id + 3}
                    ClearSelectedIcon={null}
                    shouldToggleOnHover={false}
                    closeOnChangedValue={false}
                    labelledBy="Select language"
                    className="adminTestConfigData"
                    ref={multiSelectRefs.current[index]}
                    defaultIsOpen={multiSelectRefs.current[index]?.menuOpen}
                    value={secData.languages === "" ? [] : secData.languages}
                    overrideStrings={{
                      allItemsAreSelected: "All languages are selected",
                    }}
                    valueRenderer={() =>
                      customValueRenderer(
                        secData.languages,
                        languages,
                        record,
                        config.id
                      )
                    }
                    disabled={
                      testData.published === 0 || isNewTest ? false : true
                    }
                    onChange={(selectedLang) =>
                      handleTopicConfigData(
                        record,
                        selectedLang,
                        "languages",
                        config.id
                      )
                    }
                    onMenuToggle={(isOpen) => {
                      // Manually open/close the menu without blinking behavior

                      if (isOpen) {
                        multiSelectRefs.current[index] = { menuOpen: true };
                      } else {
                        multiSelectRefs.current[index] = { menuOpen: false };
                      }
                    }}
                  />
                )
              );
            })
          )}
        </>
      ),
    },
    {
      width: "10%",
      key: nanoid(),
      title: "RANDOM QUESTION",
      dataindex: "randomQnCount",
      render: (value, record) => (
        <>
          {testConfig.map((config) =>
            config.secData.map(
              (secData) =>
                secData.id === record.id && (
                  <>
                    <input
                      type="text"
                      key={nanoid()}
                      id={record.id + 4}
                      name="randomQnCount"
                      tabIndex={record.id + 4}
                      placeholder="Question Count"
                      value={secData.randomQnCount}
                      className="adminTestConfigData"
                      ref={
                        focus && parseInt(focus) === record.id + 4 ? ref : null
                      }
                      onChange={(e) =>
                        handleTopicConfigData(
                          record,
                          e,
                          "randomQnCount",
                          config.id
                        )
                      }
                      disabled={
                        testData.published === 0 || isNewTest ? false : true
                      }
                    />
                  </>
                )
            )
          )}
        </>
      ),
    },
    {
      width: "10%",
      key: nanoid(),
      title: "FIXED QUESTION",
      dataindex: "fixedQnCount",
      render: (value, record) => (
        <>
          {testConfig.map((config) =>
            config.secData.map(
              (secData) =>
                secData.id === record.id && (
                  <input
                    type="text"
                    key={nanoid()}
                    id={record.id + 5}
                    name="fixedQnCount"
                    tabIndex={record.id + 5}
                    placeholder="Question Count"
                    value={secData.fixedQnCount}
                    className="adminTestConfigData"
                    ref={
                      focus && parseInt(focus) === record.id + 5 ? ref : null
                    }
                    onChange={(e) =>
                      handleTopicConfigData(
                        record,
                        e,
                        "fixedQnCount",
                        config.id
                      )
                    }
                    disabled={
                      testData.published === 0 || isNewTest ? false : true
                    }
                  />
                )
            )
          )}
        </>
      ),
    },
    {
      width: "10%",
      key: nanoid(),
      title: "MARKS",
      dataindex: "marks",
      render: (value, record) => (
        <>
          {testConfig.map((config) =>
            config.secData.map(
              (secData) =>
                secData.id === record.id && (
                  <input
                    type="text"
                    name="marks"
                    key={nanoid()}
                    id={record.id + 6}
                    placeholder="Marks"
                    value={secData.marks}
                    tabIndex={record.id + 6}
                    className="adminTestConfigData"
                    ref={
                      focus && parseInt(focus) === record.id + 6 ? ref : null
                    }
                    onChange={(e) =>
                      handleTopicConfigData(record, e, "marks", config.id)
                    }
                    disabled={
                      testData.published === 0 || isNewTest ? false : true
                    }
                  />
                )
            )
          )}
        </>
      ),
    },
    {
      width: "10%",
      key: nanoid(),
      title: "DURATION",
      dataindex: "duration",
      render: (value, record) => (
        <>
          {testConfig.map((config) =>
            config.secData.map(
              (secData) =>
                secData.id === record.id && (
                  <div style={{ display: "flex" }} key={nanoid()}>
                    <input
                      type="test"
                      key={nanoid()}
                      name="durationNum"
                      id={record.id + 7}
                      placeholder="Duration"
                      tabIndex={record.id + 7}
                      value={secData.durationNum}
                      className="adminTestConfigData adminTestConfigDur"
                      ref={
                        focus && parseInt(focus) === record.id + 7 ? ref : null
                      }
                      onChange={(e) =>
                        handleTopicConfigData(
                          record,
                          e,
                          "durationNum",
                          config.id
                        )
                      }
                      disabled={
                        testData.published === 0 || isNewTest ? false : true
                      }
                    />
                    <select
                      key={nanoid()}
                      id={record.id + 8}
                      tabIndex={record.id + 8}
                      value={secData.durationUnit}
                      ref={
                        focus && parseInt(focus) === record.id + 8 ? ref : null
                      }
                      className="adminTestConfigData adminTestConfigDurSelection"
                      onChange={(e) =>
                        handleTopicConfigData(
                          record,
                          e,
                          "durationUnit",
                          config.id
                        )
                      }
                      disabled={
                        testData.published === 0 || isNewTest ? false : true
                      }
                    >
                      <option>min/s</option>
                      <option>hour/s</option>
                      <option>day/s</option>
                    </select>
                  </div>
                )
            )
          )}
        </>
      ),
    },
    {
      width: "3%",
      key: nanoid(),
      dataindex: "action",
      render: (_, record) => (
        <>
          {testConfig.map((config) =>
            config.secData.map(
              (secData) =>
                secData.id === record.id && (
                  <>
                    {(testData.published === 0 || isNewTest) && (
                      <svg
                        width="12"
                        height="12"
                        fill="none"
                        key={nanoid()}
                        viewBox="0 0 12 12"
                        id="adminTestConfigDataTrashIcon"
                        xmlns="http://www.w3.org/2000/svg"
                        onClick={() =>
                          handleDeleteTopicConfigData(
                            config.id,
                            record.id,
                            record.testStatus,
                            record.topicConfigId
                          )
                        }
                      >
                        <path
                          fill="#F55533"
                          d="M5.02196 4.93363C5.29225 4.93363 5.5142 5.14057 5.53803 5.40465L5.54015 5.45182V8.74091C5.54015 9.02709 5.30815 9.25909 5.02196 9.25909C4.75167 9.25909 4.52972 9.05216 4.50589 8.78807L4.50377 8.74091V5.45182C4.50377 5.16563 4.73577 4.93363 5.02196 4.93363Z"
                        />
                        <path
                          fill="#F55533"
                          d="M7.73075 5.40465C7.70693 5.14057 7.48497 4.93363 7.21469 4.93363C6.9285 4.93363 6.6965 5.16563 6.6965 5.45182V8.74091L6.69862 8.78807C6.72244 9.05216 6.9444 9.25909 7.21469 9.25909C7.50087 9.25909 7.73287 9.02709 7.73287 8.74091V5.45182L7.73075 5.40465Z"
                        />
                        <path
                          fill="#F55533"
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M7.21468 0C8.08291 0 8.79107 0.685314 8.82774 1.54451L8.82924 1.61455V2.19273H11.052C11.3381 2.19273 11.5701 2.42473 11.5701 2.71091C11.5701 2.9812 11.3632 3.20315 11.0991 3.22698L11.052 3.2291H10.4738V10.3855C10.4738 11.2537 9.78847 11.9618 8.92927 11.9985L8.85923 12H3.37742C2.50919 12 1.80103 11.3147 1.76436 10.4555L1.76287 10.3855V3.2291H1.18469C0.898504 3.2291 0.666504 2.9971 0.666504 2.71091C0.666504 2.44062 0.873442 2.21867 1.13753 2.19484L1.18469 2.19273H3.40741V1.61455C3.40741 0.746324 4.09272 0.0381621 4.95192 0.00149169L5.02196 0H7.21468ZM2.79924 3.2291V10.3855C2.79924 10.6888 3.03286 10.9376 3.33 10.9617L3.37742 10.9636H8.85923C9.16258 10.9636 9.41137 10.73 9.43549 10.4329L9.4374 10.3855V3.2291H2.79924ZM7.79286 2.19273H4.44378V1.61455L4.4457 1.56713C4.46982 1.26999 4.71861 1.03637 5.02196 1.03637H7.21468L7.2621 1.03829C7.55924 1.06241 7.79286 1.3112 7.79286 1.61455V2.19273Z"
                        />
                      </svg>
                    )}
                    {secData.fixedQnCount > 0 &&
                      secData.fixedQnId?.split(",").length > 0 && (
                        <PreviewIcon
                          titleAccess="Fixed questions"
                          id="adminTestConfigDataPreviewFxQn"
                          onClick={() =>
                            handleOpenFixedQuestionsLists(secData, config.id)
                          }
                        />
                      )}
                  </>
                )
            )
          )}
        </>
      ),
    },
  ];

  return {
    ref,
    sections,
    testConfig,
    sectionData,
    specificField,
    setTestConfig,
    handleBackBtn,
    handleEnterKey,
    handleAddTopic,
    multiSelectRefs,
    handleDeleteLang,
    topicExpandedKey,
    handleAddSection,
    handleSectionName,
    customValueRenderer,
    handleDeleteSection,
    setTopicExpandedKey,
    handleAddQnsFromList,
    handleTopicConfigData,
    handleDeleteTopicConfigData,
    handleOpenFixedQuestionsLists,
  };
}
