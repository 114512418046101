import useAxiosPrivate from "./useAxiosPrivate";
import { getDataFromStorage } from "../util";
import { useContext } from "react";
// import firebaseclient from "../firebaseclient";
import AlertContext from "../context/AlertContext";
// import { getMessaging, getToken } from "firebase/messaging";
// const messaging = getMessaging(firebaseclient);

const useRequestPermissionForNotification = () => {
  const axios = useAxiosPrivate();
  const {setMsg,
    setShow,
    setTitle} = useContext(AlertContext);
  const sendTokenToServer = async (token) => {
    let userId = getDataFromStorage("learnerid");
    if (Notification.permission === "granted") {
      const response = await axios.post(
        "node/learner/pushtoken/save",
        JSON.stringify({
          token,
          userId,
          permissionStatus: Notification.permission === "granted",
        }),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
    }
  };

  const requestPermissionForNotification = () => {
 
      if (!("Notification" in window)) {
        
        alert("This browser does not support desktop notification");
      } else {
        Notification.requestPermission()
          .then((permission) => {
            if (permission === "granted") {
              // Permission granted, now get the FCM token
              // return getToken(messaging);
            } 
          })
          .then((token) => {
            if (token) {
              sendTokenToServer(token).catch((err) => console.error(err));
            } 
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      }
  };

  return { requestPermissionForNotification };
};

export default useRequestPermissionForNotification;
