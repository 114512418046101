import React from "react";
import Card from "react-bootstrap/Card";

const PathCard = ({lp, onClick}) => {
  return (
    <Card key={lp.id} id="adminLpCard" onClick={() => onClick(lp.id, lp.name)}>
      <Card.Body id="adminLpCardBody">
        <Card.Title id="adminLpCardTitle">{lp.name}</Card.Title>
        <div id="adminLpHrLine"></div>
        <Card.Text id="adminLpCardText">{lp.description}</Card.Text>
      </Card.Body>
    </Card>
  );
};

export default PathCard;
