import Resume from "./Resume";
import Button from "../../../Button";
import RejectBulk from "./RejectBulk";
import UserDetails from "./UserDetails";
import UploadGcatFb from "./UploadGcatFb";
import { useParams } from "react-router-dom";
import constants from "../../../../constants";
import Whatsapp from "../../../../Svg/Whatsapp";
import Typography from "@mui/material/Typography";
import Modal from "../../../CommonComponents/Modal";
import Popup from "../../../CommonComponents/Popup";
import UploadSvg from "../../../../Svg/UploadIconsvg";
import ReportHooks from "../../../../Hooks/ReportHooks";
import UserReport from "../../../UserReport/UserReport";
import { Box, IconButton, Tooltip } from "@mui/material";
import AlertContext from "../../../../context/AlertContext";
import useAxiosPrivate from "../../../../Hooks/useAxiosPrivate";
import { mkConfig, generateCsv, download } from "export-to-csv";
import { emailBody, getDataFromStorage } from "../../../../util";
import { UserReportContext } from "../../../../context/UserReportContext";
import useCommonFunctionHooks from "../../../../Hooks/useCommonFunctionHooks";

import {
  useRef,
  useMemo,
  useState,
  useEffect,
  useContext,
  useCallback,
} from "react";

import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";

import {
  QueryClient,
  QueryClientProvider,
  useInfiniteQuery,
} from "@tanstack/react-query";

const fetchSize = 10;

const UsersTable = (props) => {
  const Alert = useContext(AlertContext);
  const UserReportCxt = useContext(UserReportContext);

  const axios = useAxiosPrivate();
  const useCommonFunctionHks = useCommonFunctionHooks();
  const { capitalizeFirstLetterOfEachWord } = ReportHooks();

  const { id } = useParams();

  const tableContainerRef = useRef(null); //we can get access to the underlying TableContainer element and react to its scroll events
  const rowVirtualizerInstanceRef = useRef(null); //we can get access to the underlying Virtualizer instance and call its scrollToIndex method

  const [sorting, setSorting] = useState([]);
  const [active, setActive] = useState(true);
  const [testData, setTestData] = useState([]);
  const [addUser, setAddUser] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [globalFilter, setGlobalFilter] = useState();
  const [pendingList, setPendingList] = useState([]);
  const [showReject, setShowReject] = useState(false);
  const [rowSelection, setRowSelection] = useState([]);
  const [columnFilters, setColumnFilters] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [mouseHoveronSvg, setMouseHoveronSvg] = useState(0);
  const [hoveredRecordId, setHoveredRecordId] = useState(0);
  const [userNameClick, setUserNameClick] = useState(false);
  const [showUserFormPopup, setShowUserFormPopup] = useState(false);

  const [newUserInput, setNewUserInput] = useState({
    batchid: id,
    role: "learner",
    emailBody: emailBody,
  });

  const handleAddNewLearner = () => {
    setAddUser(true);
    setShowUserFormPopup(true);
  };

  const handleUploadFbfile = (e) => {
    Alert.setShowNotify({
      show: true,
      size: "md",
      showClose: true,
      hideButton: true,
      title: "Gradious Evaluation",
      msg: <UploadGcatFb batchId={id} />,
    });
  };

  const handleRejectClick = async () => {
    if (selectedUsers.length > 0) {
      let res = await axios.post(
        "node/admin/batch/pendingtests",
        { batchId: Alert.batchDetails.id, users: selectedUsers },
        useCommonFunctionHks.getHeaderDetailsForAxios()
      );

      setTestData(res.data.data.tests);

      let testNames = res.data.data.tests.map((item) => {
        return { label: item.testname, value: item.testid };
      });

      setPendingList(testNames);
      setShowReject(true);
    } else {
      Alert.setShowNotify({
        show: true,
        title: "Warning",
        msg: "No Learners Selected!",
      });
    }
  };

  const handleEdit = (row) => {
    let AllData = data?.pages.flatMap((page) => page.users);

    const user = AllData?.find((user) => user.email === row._valuesCache.email);

    axios
      .get(
        "node/admin/batch/user/" + user?.userId,
        useCommonFunctionHks.getHeaderDetailsForAxios()
      )
      .then((res) => {
        if (res.data.resultCode === 1000 && res.data.data != null) {
          let userDetails = { ...res.data.data, role: "learner" };

          setNewUserInput(userDetails);
          setShowUserFormPopup(true);
        } else {
          Alert.setShowNotify({
            show: true,
            title: "Warning",
            msg: "No User Exists!",
          });
        }
      })
      .catch((error) => {
        useCommonFunctionHks.getCatchBlockDetails(error);
      });
  };

  const handleUserLpData = (cell) => {
    setUserNameClick(true);

    UserReportCxt.setCurrentLearnerDetails({
      value: cell.original.userId,
      label: cell.original.firstname + " " + cell.original.lastname,
    });

    Alert.setLearnerId(cell.original.userId);
    setShowPopup(true);
    Alert.setClickedMenu(1);
    setUserNameClick(false);
  };

  const ViewResume = (row) => {
    let learnerId = row.original.userId;

    Alert.setShowNotify({
      show: true,
      size: "xl",
      showClose: true,
      hideButton: true,
      msg: <Resume from={"onpopup"} learnerId={learnerId} forLoad={true} />,
    });
  };

  const generatePickleFile = () => {
    let payload = {
      batchid: Alert.batchDetails.id,
      learnerid: getDataFromStorage("learnerid"),
    };

    axios
      .post(
        // process.env.REACT_APP_PROCTOR_API +":5001/api/ml/model/update",
        "node/admin/pic/model",
        payload,
        useCommonFunctionHks.getHeaderDetailsForAxios()
      )
      .then((res) => {
        if (res.data.resultCode !== constants.RESULT_STATUS.SUCCESS) {
          Alert.setShowNotify({
            show: true,
            title: "Info",
            msg: "Something went wrong. Please try again.",
          });
        } else {
          Alert.setShowNotify({
            show: true,
            title: "Success",
            msg: "Successfully generated the face recognition model.",
          });
        }
      })
      .catch((error) => {
        useCommonFunctionHks.getCatchBlockDetails(error);
      });
  };

  const columns = [
    {
      accessorFn: (row) =>
        `${capitalizeFirstLetterOfEachWord(
          row?.firstname + " " + row?.lastname
        )}`, //accessorFn used to join multiple data into a single cell
      size: 180,
      id: "name", //id is still required when using accessorFn instead of accessorKey
      header: "Name",
    },
    {
      size: 150,
      grow: true,
      accessorKey: "email",
      header: "Email Address",
      enableClickToCopy: true,
    },
    {
      size: 75,
      header: "College",
      accessorKey: "college",
    },
    {
      size: 75,
      header: "Branch",
      accessorKey: "branch",
    },
    {
      size: 100,
      accessorKey: "mobile",
      header: "Phone Number",
      Cell: ({ cell }) =>
        cell.getValue() !== "" &&
        cell.getValue() !== undefined &&
        cell.getValue().length >= 10 && (
          <>
            <Typography component="div">
              <Whatsapp />
            </Typography>{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`https://wa.me/${cell.getValue()}`}
            >
              {cell.getValue()}
            </a>
          </>
        ),
      enableClickToCopy: true,
    },
    {
      size: 100,
      id: "completedtests",
      header: "Completed Tests",
      accessorFn: (row) =>
        `${
          row.completedtests == null || row.completedtests == undefined
            ? 0
            : row.completedtests
        }/${row.totaltests}`,
      muiTableHeadCellProps: {
        align: "center",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
    },
    {
      size: 180,
      header: "Last Completed",
      accessorKey: "lastsubmittedtest",
    },
  ];

  const { data, fetchNextPage, isError, isFetching, isLoading, refetch } =
    useInfiniteQuery({
      queryKey: [
        sorting, //refetch when sorting changes
        "table-data",
        globalFilter, //refetch when globalFilter changes
      ],
      queryFn: async ({ pageParam }) => {
        const endPoint = "node/admin/batch/course/userreport";

        try {
          const response = await axios.post(
            endPoint,
            { batchId: id, status: active },
            {
              params: {
                size: fetchSize,
                start: pageParam * fetchSize,
                globalFilter: globalFilter ?? "",
                sorting: JSON.stringify(sorting ?? []),
                filters: JSON.stringify(columnFilters ?? []),
              },
              headers: {
                "Content-type": "application/json",
              },
            }
          );

          if (addUser) {
            setAddUser(false);
          }

          return response.data.data;
        } catch (error) {
          throw error;
        }
      },
      initialPageParam: 0,
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      getNextPageParam: (_lastGroup, groups) => groups.length,
    });

  const handleChangeUserStatus = async () => {
    try {
      if (selectedUsers.length > 0) {
        let res = await axios.post(
          "node/admin/batch/users/status",
          JSON.stringify({
            batchId: id,
            active: !active,
            removeTrainer: false,
            userIds: selectedUsers,
          }),
          useCommonFunctionHks.getHeaderDetailsForAxios()
        );

        if (res.data.resultCode === constants.RESULT_STATUS.SUCCESS) {
          setSelectedUsers([]);
          refetch();
        } else {
          Alert.setShowNotify({
            show: true,
            title: "Error",
            msg: "Something went wrong. Please try again later.",
          });
        }
      } else {
        Alert.setShowNotify({
          show: true,
          title: "Warning",
          msg: "There is no users",
        });
      }
    } catch (error) {
      useCommonFunctionHks.getCatchBlockDetails(error);
    } finally {
      Alert.setIsLoaded(false);
    }
  };

  useEffect(() => {
    refetch();
  }, [active, refetch]);

  const flatData = useMemo(() => {
    return data?.pages.flatMap((page) => page?.users) ?? [];
  }, [data]);

  const totalDBRowCount = data?.pages[0]?.totalUsercount;

  const totalFetched = flatData?.length;

  //called on scroll and possibly on mount to fetch more data as the user scrolls and reaches bottom of table

  const fetchMoreOnBottomReached = useCallback(
    (containerRefElement) => {
      if (containerRefElement) {
        const { scrollHeight, scrollTop, clientHeight } = containerRefElement;

        //once the user has scrolled within 400px of the bottom of the table, fetch more data if we can

        if (
          scrollHeight - scrollTop - clientHeight < 400 &&
          !isFetching &&
          totalFetched < totalDBRowCount
        ) {
          fetchNextPage();
        }
      }
    },
    [fetchNextPage, isFetching, totalFetched, totalDBRowCount]
  );

  const handleSelect = (e, row) => {
    e.stopPropagation();

    let AllData = data?.pages.flatMap((page) => page.users);

    const user = AllData?.find((user) => user.email === row._valuesCache.email);

    let newLearnerids = [];
    let learnerids = [...selectedUsers];

    if (learnerids.length > 0 && learnerids.includes(user.userId)) {
      newLearnerids = learnerids.filter((item) => item !== user.userId);
    } else {
      newLearnerids = [...selectedUsers, user.userId];
    }

    setSelectedUsers(newLearnerids);
  };

  const handleMouseEnter = (val, id) => {
    setHoveredRecordId(id);
    setMouseHoveronSvg(val);
  };

  const handleMouseLeave = () => {
    setMouseHoveronSvg(0);
    setHoveredRecordId(0);
  };

  const handleSelectAll = (e) => {
    e.stopPropagation();

    let AllData = data?.pages.flatMap((page) => page.users);
    let allUserIds = AllData.map((user) => user.userId);

    if (selectedUsers.length === allUserIds.length) {
      setSelectedUsers([]);
    } else {
      setSelectedUsers(allUserIds);
    }
  };

  const csvConfig = mkConfig({
    fieldSeparator: ",",
    decimalSeparator: ".",
    useKeysAsHeaders: true,
    filename: `${Alert.batchDetails.name}`,
  });

  const handleExportRows = (rows) => {
    let AllData = data?.pages.flatMap((page) => page.users);

    const csv = generateCsv(csvConfig)(AllData);

    download(csvConfig)(csv);
  };

  const table = useMaterialReactTable({
    columns,
    data: flatData,
    enableRowActions: true,
    renderRowActions: ({ row }) => (
      <Box
        sx={{ display: "flex", flexWrap: "nowrap", gap: "0px", ml: "-10px" }}
      >
        <Tooltip title="View Resume">
          <IconButton color="primary" onClick={() => ViewResume(row)}>
            <svg
              width="16"
              height="16"
              fill="none"
              viewBox="0 0 20 20"
              onMouseLeave={handleMouseLeave}
              xmlns="http://www.w3.org/2000/svg"
              onMouseEnter={() => handleMouseEnter(1, row.original.userId)}
            >
              <path
                stroke-width="1.6"
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M11.6668 1.89128V5.3334C11.6668 5.80011 11.6668 6.03346 11.7577 6.21172C11.8376 6.36853 11.965 6.49601 12.1218 6.5759C12.3001 6.66673 12.5335 6.66673 13.0002 6.66673H16.4423M13.3335 10.8333H6.66683M13.3335 14.1667H6.66683M8.3335 7.50001H6.66683M11.6668 1.66667H7.3335C5.93336 1.66667 5.2333 1.66667 4.69852 1.93916C4.22811 2.17884 3.84566 2.56129 3.60598 3.0317C3.3335 3.56647 3.3335 4.26654 3.3335 5.66667V14.3333C3.3335 15.7335 3.3335 16.4335 3.60598 16.9683C3.84566 17.4387 4.22811 17.8212 4.69852 18.0609C5.2333 18.3333 5.93336 18.3333 7.3335 18.3333H12.6668C14.067 18.3333 14.767 18.3333 15.3018 18.0609C15.7722 17.8212 16.1547 17.4387 16.3943 16.9683C16.6668 16.4335 16.6668 15.7335 16.6668 14.3333V6.66667L11.6668 1.66667Z"
                stroke={
                  mouseHoveronSvg === 1 &&
                  hoveredRecordId === row.original.userId
                    ? "#f55533"
                    : "#344054"
                }
              />
            </svg>
          </IconButton>
        </Tooltip>
        <Tooltip title="View Report">
          <IconButton color="secondary" onClick={() => handleUserLpData(row)}>
            <svg
              width="16"
              height="16"
              fill="none"
              viewBox="0 0 20 20"
              onMouseLeave={handleMouseLeave}
              xmlns="http://www.w3.org/2000/svg"
              onMouseEnter={() => handleMouseEnter(2, row.original.userId)}
            >
              <path
                stroke-width="1.6"
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M11.6668 9.16667H6.66683M8.3335 12.5H6.66683M13.3335 5.83334H6.66683M16.6668 8.75001V5.66667C16.6668 4.26654 16.6668 3.56647 16.3943 3.0317C16.1547 2.56129 15.7722 2.17884 15.3018 1.93916C14.767 1.66667 14.067 1.66667 12.6668 1.66667H7.3335C5.93336 1.66667 5.2333 1.66667 4.69852 1.93916C4.22811 2.17884 3.84566 2.56129 3.60598 3.0317C3.3335 3.56647 3.3335 4.26654 3.3335 5.66667V14.3333C3.3335 15.7335 3.3335 16.4335 3.60598 16.9683C3.84566 17.4387 4.22811 17.8212 4.69852 18.0609C5.2333 18.3333 5.93336 18.3333 7.3335 18.3333H9.5835M18.3335 18.3333L17.0835 17.0833M17.9168 15C17.9168 16.6108 16.611 17.9167 15.0002 17.9167C13.3893 17.9167 12.0835 16.6108 12.0835 15C12.0835 13.3892 13.3893 12.0833 15.0002 12.0833C16.611 12.0833 17.9168 13.3892 17.9168 15Z"
                stroke={
                  mouseHoveronSvg === 2 &&
                  hoveredRecordId === row.original.userId
                    ? "#f55533"
                    : "#344054"
                }
              />
            </svg>
          </IconButton>
        </Tooltip>
        {Alert.isAdmin && (
          <Tooltip title="Edit">
            <IconButton color="error" onClick={() => handleEdit(row)}>
              <svg
                width="16"
                height="16"
                fill="none"
                viewBox="0 0 20 20"
                onMouseLeave={handleMouseLeave}
                xmlns="http://www.w3.org/2000/svg"
                onMouseEnter={() => handleMouseEnter(3, row.original.userId)}
              >
                <g clip-path="url(#clip0_6374_11614)">
                  <path
                    stroke-width="1.6"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M9.1665 3.33333H3.33317C2.89114 3.33333 2.46722 3.50893 2.15466 3.82149C1.8421 4.13405 1.6665 4.55797 1.6665 5V16.6667C1.6665 17.1087 1.8421 17.5326 2.15466 17.8452C2.46722 18.1577 2.89114 18.3333 3.33317 18.3333H14.9998C15.4419 18.3333 15.8658 18.1577 16.1783 17.8452C16.4909 17.5326 16.6665 17.1087 16.6665 16.6667V10.8333M15.4165 2.08333C15.748 1.75181 16.1977 1.56557 16.6665 1.56557C17.1353 1.56557 17.585 1.75181 17.9165 2.08333C18.248 2.41485 18.4343 2.86449 18.4343 3.33333C18.4343 3.80217 18.248 4.25181 17.9165 4.58333L9.99984 12.5L6.6665 13.3333L7.49984 10L15.4165 2.08333Z"
                    stroke={
                      mouseHoveronSvg === 3 &&
                      hoveredRecordId === row.original.userId
                        ? "#f55533"
                        : "#344054"
                    }
                  />
                </g>
                <defs>
                  <clipPath id="clip0_6374_11614">
                    <rect width="20" height="20" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </IconButton>
          </Tooltip>
        )}
      </Box>
    ),

    initialState: {
      showGlobalFilter: true,
      columnVisibility: { college: false, branch: false, mobile: false },
    },
    manualSorting: true,
    manualFiltering: true,
    enablePagination: false,
    enableStickyFooter: false,
    enableColumnActions: false,
    enableColumnFilters: false,
    positionGlobalFilter: "left",
    positionActionsColumn: "last",
    enableRowVirtualization: true,
    getRowId: (row) => row?.userId,
    positionToolbarAlertBanner: "none",
    enableRowNumbers: getDataFromStorage("role") === constants.Roles.hr,
    enableRowSelection: getDataFromStorage("role") !== constants.Roles.hr,
    enableMultiRowSelection: getDataFromStorage("role") !== constants.Roles.hr,
    muiSelectCheckboxProps: ({ row }) => ({
      onClick: (e) => {
        handleSelect(e, row);
      },
      rowSelection: false,
      defaultChecked: false,
      checked:
        selectedUsers.includes(row?.id) &&
        flatData.map((r) => r?.userId).includes(row?.id),
      sx: {
        cursor: "pointer", //you might want to change the cursor too when adding an onClick
      },
    }),
    muiSelectAllCheckboxProps: ({ table }) => ({
      rowSelection: false,
      defaultChecked: false,
      onClick: handleSelectAll,
      checked: flatData.map((r) => r?.userId)?.length === selectedUsers.length,
      sx: {
        cursor: "pointer", //you might want to change the cursor too when adding an onClick
      },
    }),
    muiTableContainerProps: {
      ref: tableContainerRef, //get access to the table container element
      sx: { maxHeight: "600px", paddingBottom: "140px" }, //give the table a max height
      onScroll: (event) => fetchMoreOnBottomReached(event.target), //add an event listener to the table container element
    },
    muiToolbarAlertBannerProps: isError
      ? {
          color: "error",
          children: "Error loading data",
        }
      : undefined,
    onSortingChange: setSorting,
    onGlobalFilterChange: setGlobalFilter,
    onRowSelectionChange: setRowSelection,
    onColumnFiltersChange: setColumnFilters,

    state: {
      sorting,
      isLoading,
      globalFilter,
      rowSelection,
      columnFilters,
      active: active,
      showAlertBanner: isError,
      showProgressBars: isFetching || userNameClick,
    },
    rowVirtualizerInstanceRef, //get access to the virtualizer instance
    rowVirtualizerOptions: { overscan: 4 },
  });

  return (
    <>
      <div className="noofuserssection">
        <div className="righttools">
          <p className="userstext"> Users </p>
          <div className="noofuserdiv">
            <p className="noofusers">
              {totalFetched} / {data?.pages[0]?.totalUsercount}
            </p>
          </div>
        </div>
        <div className="lefttools">
          {getDataFromStorage("role") !== constants.Roles.hr && (
            <div className="actvendinactive">
              <p
                style={{ cursor: "pointer" }}
                className={active ? "activeusers" : "activeusersdisable"}
                onClick={() => {
                  setActive(true);
                  setSelectedUsers([]);
                }}
              >
                Active
              </p>
              <p
                style={{ cursor: "pointer" }}
                className={!active ? "inactiveusers" : "inactiveusersdisable"}
                s
                onClick={() => {
                  setActive(false);
                  setSelectedUsers([]);
                }}
              >
                Inactive
              </p>
            </div>
          )}

        

          {getDataFromStorage("role") !== constants.Roles.hr && (
             <><Button
              size={"sm"}
              disable={false}
              onClick={generatePickleFile}
              hierarchy={{
                type: "secondaryGrey",
                buttonText: "Build Faces",
              }}
            />
            <div
              className="addusericon"
              style={{ cursor: "pointer" }}
              onClick={handleAddNewLearner}
            >
              <svg
                width="20"
                height="20"
                fill="none"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke="#344054"
                  stroke-width="1.67"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M10.0001 4.16699V15.8337M4.16675 10.0003H15.8334"
                />
              </svg>
            </div>
            </> 
          )}
          <div
            className="usersdownloadicon"
            style={{ cursor: "pointer" }}
            onClick={() =>
              handleExportRows(table.getPrePaginationRowModel().rows)
            }
          >
            <svg
              width="20"
              height="20"
              fill="none"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke="#344054"
                stroke-width="1.67"
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M17.5 12.5V15.8333C17.5 16.2754 17.3244 16.6993 17.0118 17.0118C16.6993 17.3244 16.2754 17.5 15.8333 17.5H4.16667C3.72464 17.5 3.30072 17.3244 2.98816 17.0118C2.67559 16.6993 2.5 16.2754 2.5 15.8333V12.5M5.83333 8.33333L10 12.5M10 12.5L14.1667 8.33333M10 12.5V2.5"
              />
            </svg>
          </div>

          {getDataFromStorage("role") !== constants.Roles.hr ? (
            !active ? (
              <div
                className="userenableicon"
                style={{ cursor: "pointer" }}
                onClick={handleChangeUserStatus}
              >
                <svg
                  width="16"
                  height="11"
                  fill="none"
                  viewBox="0 0 16 11"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    stroke="#12B76A"
                    stroke-width="1.67"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M14.6666 1L5.49992 10.1667L1.33325 6"
                  />
                </svg>
              </div>
            ) : (
              <div
                className="userdisableicon"
                style={{ cursor: "pointer" }}
                onClick={handleChangeUserStatus}
              >
                <svg
                  width="20"
                  height="20"
                  fill="none"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_91_2327)">
                    <path
                      stroke="#D92D20"
                      stroke-width="1.67"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M6.66675 10.0003H13.3334M18.3334 10.0003C18.3334 14.6027 14.6025 18.3337 10.0001 18.3337C5.39771 18.3337 1.66675 14.6027 1.66675 10.0003C1.66675 5.39795 5.39771 1.66699 10.0001 1.66699C14.6025 1.66699 18.3334 5.39795 18.3334 10.0003Z"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_91_2327">
                      <rect width="20" height="20" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </div>
            )
          ) : null}

          {getDataFromStorage("role") !== constants.Roles.hr && (
            <Button
              size={"sm"}
              onClick={handleRejectClick}
              disable={selectedUsers.length === 0}
              hierarchy={{
                buttonText: "Reject",
                type: "secondaryGrey",
              }}
            ></Button>
          )}

          {getDataFromStorage("role") !== constants.Roles.hr && (
            <Button
              size={"sm"}
              disable={false}
              onClick={handleUploadFbfile}
              icon={{
                type: "leading",
                icon: <UploadSvg stroke="#344054" />,
              }}
              hierarchy={{
                type: "secondaryGrey",
                buttonText: "Evaluation",
              }}
            ></Button>
          )}
        </div>
      </div>

      <MaterialReactTable table={table} />
      {Alert.isCommonPopup && !addUser && (
        <div className="popup-overlay">
          <Popup />
        </div>
      )}
      {showReject && (
        <RejectBulk
          testData={testData}
          setShowReject={setShowReject}
          selectedUsers={selectedUsers}
          pendingTestLists={pendingList}
          setSelectedUsers={setSelectedUsers}
        />
      )}
      {showUserFormPopup && (
        <UserDetails
          addUser={addUser}
          refetch={refetch}
          setAddUser={setAddUser}
          newUserInput={newUserInput}
          setNewUserInput={setNewUserInput}
          showUserFormPopup={showUserFormPopup}
          setShowUserFormPopup={setShowUserFormPopup}
        />
      )}
      <Modal
        isOpen={showPopup}
        showCloseIcon={true}
        component={"leaderboard"}
        onClose={() => setShowPopup(false)}
      >
        <UserReport />
      </Modal>
    </>
  );
};

const queryClient = new QueryClient();

const UsersTableQueryProvider = (props) => (
  <QueryClientProvider client={queryClient}>
    <UsersTable {...props} />
  </QueryClientProvider>
);

export default UsersTableQueryProvider;
