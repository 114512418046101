import React from 'react'

export default function GrammerSvg() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="24" height="24" rx="12" fill="#F97256"/>
        <path d="M8.66667 9.66667H12M8.66667 12H14M10.4558 16H14.8C15.9201 16 16.4802 16 16.908 15.782C17.2843 15.5903 17.5903 15.2843 17.782 14.908C18 14.4802 18 13.9201 18 12.8V9.2C18 8.07989 18 7.51984 17.782 7.09202C17.5903 6.71569 17.2843 6.40973 16.908 6.21799C16.4802 6 15.9201 6 14.8 6H9.2C8.0799 6 7.51984 6 7.09202 6.21799C6.71569 6.40973 6.40973 6.71569 6.21799 7.09202C6 7.51984 6 8.0799 6 9.2V17.557C6 17.9122 6 18.0898 6.07282 18.1811C6.13615 18.2604 6.23218 18.3066 6.33369 18.3065C6.45042 18.3063 6.58911 18.1954 6.8665 17.9735L8.45681 16.7012C8.78168 16.4413 8.94411 16.3114 9.12499 16.219C9.28547 16.137 9.45628 16.0771 9.63281 16.0408C9.83178 16 10.0398 16 10.4558 16Z" stroke="white" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  )
}
