import React, { useState, useContext } from "react";
import SideNav from "../Student/Profile/SideNav";
import User01 from "../../Svg/User01";
import GraduationHat01 from "../../Svg/GraduationHat01";
import TrendUp01 from "../../Svg/TrendUp01";
import CheckVerified01 from "../../Svg/CheckVerified01";
import PersonalInfoForm from "../Student/Profile/PersonalInfoForm";
import ParentInfo from "../Student/Profile/ParentInfo";
import EducationForm from "../Student/Profile/EducationForm";
import SocialProfiles from "../Student/Profile/SocialProfiles";
import AcademicWork from "../Student/Profile/AcademicWork";
import AlertContext from "../../context/AlertContext";
import GradiousLogoSvg from "../Student/CodeSection/CodeSection/Svg/GradiousLogo/GradiousLogoSvg";
import TestSvgIcon from "../../Svg/TestSvgIcon";
import Resume from "../Admin/Batch/Users/Resume";
import GradiousEvaluation from "./GradiousEvaluation";
import Loading from "../Notification-Loading/Loading/Loading";


function ProfileUser({learnerId}) {
  const { clcikedMenu, isLoaded } = useContext(AlertContext);

  const menuListItemsStudent = [
    {
      id: 1,
      icon: (
        <User01
          stroke={clcikedMenu == 1 ? "#FCFCFD" : "#667085"}
          width="20"
          height="20"
          strokeWidth="1.6"
        />
      ),
      text: "Personal Info",
      active: clcikedMenu == 1,
      path: "personalinfo",
    },
    {
      id: 2,
      icon: (
        <User01
          stroke={clcikedMenu == 2 ? "#FCFCFD" : "#667085"}
          width="20"
          height="20"
          strokeWidth="1.6"
        />
      ),
      text: "Parent Info",
      active: clcikedMenu == 2,
      path: "parentinfo",
    },
    {
      id: 3,
      icon: (
        <GraduationHat01
          stroke={clcikedMenu == 3 ? "#FCFCFD" : "#667085"}
          width="20"
          height="20"
          strokeWidth="1.6"
        />
      ),
      text: "Education",
      active: clcikedMenu == 3,
      path: "education",
    },
    {
      id: 4,
      icon: (
        <TrendUp01
          stroke={clcikedMenu == 4 ? "#FCFCFD" : "#667085"}
          width="20"
          height="20"
          strokeWidth="1.6"
        />
      ),
      text: "Academic Work",
      active: clcikedMenu == 4,
      path: "academic",
    },
    {
      id: 5,
      icon: (
        <CheckVerified01
          stroke={clcikedMenu == 5 ? "#FCFCFD" : "#667085"}
          width="20"
          height="20"
          strokeWidth="1.6"
        />
      ),
      text: "Social Profiles",
      active: clcikedMenu == 5,
      path: "socialprofile",
    },
    {
      id: 6,
      icon: (
        <GradiousLogoSvg
          fill={clcikedMenu == 6 ? "#FFFFFF" : "#F55533"}
          width="20"
          height="20"
          strokeWidth="1.6"
        />
      ),
      text: "Gradious Evaluation",
      active: clcikedMenu == 6,
      path: "Gradiousevaluation",
    },
    {
      id: 7,
      icon: (
        <TestSvgIcon
          libType={1}
          component = "resume"
          stroke={clcikedMenu == 7 ? "#FFFFFF" : "#344054"}
          width="20"
          height="20"
          strokeWidth="1.6"
        />
      ),
      text: "View Resume",
      active: clcikedMenu == 7,
      path: "viewresume",
    },
  ];

  return (
    <div className="profileOnPopup">
      <SideNav from="forpopup"  menuListItems={menuListItemsStudent} title="" /* ViewResumeFunct={ViewResume} *//>
      <div className="detailCont">
        {/* <div className="download_resume"><IconButton children={<DownloadSvgIcon />}  hierarchy={"secondary"}/></div> */}
        {clcikedMenu == 1 && <PersonalInfoForm role="learner" from="onpopup" learnerId={learnerId} />}
        {clcikedMenu == 2 && <ParentInfo role="learner" from="onpopup" learnerId={learnerId} />}
        {clcikedMenu == 3 && <EducationForm role="learner" from="onpopup" learnerId={learnerId}/>}
        {clcikedMenu == 4 && <AcademicWork role="learner" from="onpopup" learnerId={learnerId}/>}
        {clcikedMenu == 5 && <SocialProfiles role="learner" from="onpopup" learnerId={learnerId}/>}
        {clcikedMenu == 6 && <GradiousEvaluation learnerId={learnerId} from="onpopup" />}
        {clcikedMenu == 7 && <Resume learnerId={learnerId} from="onpopup" fromisLoad={true} />}
      </div>
      {/* {isLoaded && <Loading />} */}
    </div>
  );
}

export default ProfileUser;
