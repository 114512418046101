import React from "react";

const User01 = ({ stroke, width, height, strokeWidth }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.6654 18.4027C16.6654 17.2397 16.6654 16.6583 16.5218 16.1851C16.1987 15.1198 15.365 14.2861 14.2996 13.9629C13.8265 13.8194 13.245 13.8194 12.082 13.8194H7.91537C6.7524 13.8194 6.17091 13.8194 5.69775 13.9629C4.63241 14.2861 3.79873 15.1198 3.47556 16.1851C3.33203 16.6583 3.33203 17.2397 3.33203 18.4027M13.7487 7.15271C13.7487 9.22378 12.0698 10.9027 9.9987 10.9027C7.92763 10.9027 6.2487 9.22378 6.2487 7.15271C6.2487 5.08164 7.92763 3.40271 9.9987 3.40271C12.0698 3.40271 13.7487 5.08164 13.7487 7.15271Z"
        stroke={stroke}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default User01;
