import React from "react";
import constants from "../constants";

export default function AngleRightSvg(Props) {
  return (
    <>
      {Props.component === "codingTable" ? (
        <svg
          width="20"
          height="20"
          fill="none"
          viewBox="0 0 20 20"
          className="codingTableLeftSvgIcon"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            stroke={
              Props.status === constants.PROGRESS_STATUS.UNLOCKED
                ? "#12B76A"
                : Props.status === constants.PROGRESS_STATUS.LOCKED
                ? "#EAECF0"
                : Props.status === constants.PROGRESS_STATUS.QN_CONTINUE
                ? "#E54B2A"
                : Props.status === constants.PROGRESS_STATUS.REATTEMPT
                ? "#B42318"
                : "#667085"
            }
            stroke-width="1.67"
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M7.5 15L12.5 10L7.5 5"
          />
        </svg>
      ) : Props.component === "codingPageLearnerSideBreadCrumbs" ? (
        <svg
          width="6"
          height="10"
          viewBox="0 0 6 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1 9L5 5L1 1"
            stroke="#344054"
            stroke-width="1.4"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      ) : (
        <svg
          width="24"
          height="24"
          fill="none"
          viewBox="0 0 24 24"
          onClick={Props.handleFunction}
          xmlns="http://www.w3.org/2000/svg"
          className={!Props.disabled ? "nextIcon" : "disableNextIcon"}
        >
          <g filter="url(#filter0_d_1929_16774)">
            <rect x="2" y="1" width="20" height="20" rx="10" fill="white" />
            <rect
              x="2.5"
              y="1.5"
              rx="9.5"
              width="19"
              height="19"
              stroke="#D0D5DD"
            />
            <path
              stroke-width="1.33"
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M10.5 14L13.5 11L10.5 8"
              stroke={!Props.disabled ? "#667085" : "#D0D5DD"}
            />
          </g>
          <defs>
            <filter
              x="0"
              y="0"
              width="24"
              height="24"
              id="filter0_d_1929_16774"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                type="matrix"
                in="SourceAlpha"
                result="hardAlpha"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              />
              <feOffset dy="1" />
              <feGaussianBlur stdDeviation="1" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.05 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_1929_16774"
              />
              <feBlend
                mode="normal"
                result="shape"
                in="SourceGraphic"
                in2="effect1_dropShadow_1929_16774"
              />
            </filter>
          </defs>
        </svg>

        // <svg
        //   fill="none"
        //   viewBox="0 0 10 18"
        //   onClick={Props.handleFunction}
        //   xmlns="http://www.w3.org/2000/svg"
        //   width={Props.width ? Props.width : "10"}
        //   height={Props.height ? Props.height : "14"}
        //   className={!Props.disabled ? "nextIcon" : "disableNextIcon"}
        // >
        //   <path
        //     d="M11.6771 15.9999C11.3782 16.0005 11.083 15.9628 10.813 15.8897C10.5429 15.8165 10.3051 15.7097 10.1168 15.5771L0.455058 8.72061C0.160841 8.51613 0 8.25965 0 7.99496C0 7.73027 0.160841 7.47379 0.455058 7.26932L10.4569 0.412821C10.7964 0.179452 11.2843 0.0326955 11.8133 0.00483554C12.3422 -0.0230244 12.8689 0.0702947 13.2774 0.264263C13.6859 0.458232 13.9428 0.736962 13.9915 1.03913C14.0403 1.34131 13.877 1.64217 13.5374 1.87554L4.5958 8.00068L13.2374 14.1258C13.482 14.2936 13.6374 14.4978 13.6851 14.7144C13.7329 14.931 13.671 15.1509 13.5069 15.3481C13.3428 15.5452 13.0832 15.7114 12.7589 15.8269C12.4346 15.9424 12.0592 16.0025 11.6771 15.9999Z"
        //     fill={
        //       !Props.disabled
        //         ? Props.pathfill
        //           ? Props.pathfill
        //           : "#727374"
        //         : "#e6e6eb"
        //     }
        //   />
        // </svg>
      )}
    </>
  );
}
