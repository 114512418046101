import "./Notify.scss";
import React from "react";
import Modal from "react-bootstrap/Modal";
import constants from "../../../constants";
import SuccessImg from "./Images/SuccessImg.png";
import ButtonMedium from "../../Buttons/ButtonMedium/ButtonMedium";

export default function Notify(Props) {
  return (
    <div className="notifyMainContainer">
      <Modal
        centered
        backdrop="static"
        show={Props.show}
        onHide={Props.onHide}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Body>
          <div
            className={
              Props.resultCode === constants.RESULT_STATUS.SUCCESS
                ? "modalSuccessTitle"
                : (Props.title === "Warning" &&
                    Props.svg !== "" &&
                    Props.svg !== undefined) ||
                  Props.resultCode === constants.RESULT_STATUS.FAILURE_ATTEMPT
                ? "modalWarningTitle"
                : Props.title === "Assignment Auto-submitted"
                ? "modalAutoSubmitTitle"
                : "modalTitle"
            }
          >
            {Props.title}
          </div>
          {Props.svg !== "" && Props.svg !== undefined ? (
            <div
              className={
                Props.title === "Assignment Auto-submitted"
                  ? "autoSubmitSvg"
                  : Props.title === "Warning" &&
                    Props.svg !== "" &&
                    Props.svg !== undefined
                  ? "warningSvg"
                  : "timeSvg"
              }
            >
              {Props.svg}
            </div>
          ) : (
            Props.resultCode === constants.RESULT_STATUS.SUCCESS && (
              <img src={SuccessImg} alt="SuccessImg" className="SuccessImg" />
            )
          )}
          <div
            className={
              Props.resultCode === constants.RESULT_STATUS.SUCCESS
                ? "modalMsgForSuccess"
                : Props.title === "Assignment Auto-submitted"
                ? "modalMsgAutoSubmit"
                : Props.resultCode === constants.RESULT_STATUS.REPLACE_QUESTION
                ? "modalMsgForTimeLapsed"
                : Props.resultCode === constants.RESULT_STATUS.FAILURE_ATTEMPT
                ? "modalMsgForTestCasesFailed2000"
                : Props.title === "Warning" &&
                  Props.svg !== "" &&
                  Props.svg !== undefined
                ? "modalMsgWarning"
                : "modalMsg"
            }
          >
            {Props.message}
          </div>

          <div
            className={
              Props.title === "Assignment Auto-submitted"
                ? "modalButtonDivForAutoSubmit"
                : Props.resultCode === constants.RESULT_STATUS.REPLACE_QUESTION
                ? "modalButtonDivForTimeLapsed"
                : Props.title === "Warning" &&
                  Props.svg !== "" &&
                  Props.svg !== undefined
                ? "modalButtonDivForWarning"
                : "modalButtonDiv"
            }
          >
            <div
              className={
                Props.resultCode === constants.RESULT_STATUS.SUCCESS
                  ? "modalButtonSuccess"
                  : Props.resultCode === constants.RESULT_STATUS.FAILURE_ATTEMPT
                  ? "modalButtonDivForTestCasesFailed2000"
                  : Props.title === "Warning" &&
                    Props.svg !== "" &&
                    Props.svg !== undefined
                  ? "modalBtnWarning"
                  : "modalButton"
              }
            >
              <ButtonMedium
                onHide={Props.onHide}
                btnText={
                  (Props.btnText !== "" && Props.btnText !== undefined) ||
                  Props.resultCode === constants.RESULT_STATUS.NETWORK_ISSUE
                    ? Props.btnText
                    : "Ok"
                }
              />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
