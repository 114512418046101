import { useContext, useState } from "react";
import { MockTestPageContext } from "../../../../context/MockTestPageContext";

export default function MockTestConfigHooks() {
  const { setShowQnList, setSelectedQns } = useContext(MockTestPageContext);

  const [menuOpenState, setMenuOpenState] = useState([]);

  const handleCloseListPopup = () => {
    setSelectedQns([]);
    setShowQnList(false);
  };

  return {
    menuOpenState,
    setMenuOpenState,
    handleCloseListPopup,
  };
}
