import React from 'react'

const Error404 = () => {
  return (
    <div style={{position:'absolute',top:'45%',left:'45%',fontSize:'1.5rem'}}>
      <p>Page not found</p>
    </div>
  )
}

export default Error404