import React from "react";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  searchBar: {
    "& .MuiOutlinedInput-root": {
      borderColor: "#D9D9D9",
      outline: "none",
      borderRadius: "8px",
      "&:hover fieldset": {
        borderColor: "#D9D9D9", // Change border color on hover
      },
      "&.Mui-focused fieldset": {
        borderColor: "#D9D9D9", // Change border color when focused
      },
    },
    "& .MuiOutlinedInput-root input::placeholder": {
      fontFamily: "Inter",
      fontSize: "16px",
      fontHeight: "400",
      lineHeight: "24px",
      textAlign: "left",
      color: "#667085",
    },
  },
});

export default function Search({ iconPosition, onChange, value }) {
  const classes = useStyles();

  return (
    <div>
      <TextField
        onChange={onChange}
        value={value}
        placeholder="Search"
        variant="outlined"
        InputProps={{
          [`${iconPosition}Adornment`]: (
            <InputAdornment position={iconPosition}>
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.25 12.25L9.7125 9.7125M11.0833 6.41667C11.0833 8.994 8.994 11.0833 6.41667 11.0833C3.83934 11.0833 1.75 8.994 1.75 6.41667C1.75 3.83934 3.83934 1.75 6.41667 1.75C8.994 1.75 11.0833 3.83934 11.0833 6.41667Z"
                  stroke="#667085"
                  strokeWidth="1.4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </InputAdornment>
          ),
          style: {
            fontFamily: "Inter",
            fontSize: "14px",
            width: "200px",
            height: "36px",
            color: "#101828",
          }, // Customize input styles as needed
        }}
        className={classes.searchBar}
      />
    </div>
  );
}

Search.defaultProps = {
  iconPosition: "start",
};
