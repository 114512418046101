import Button from "../../../Button";
import Edit03 from "../../../../Svg/Edit03";
import constants from "../../../../constants";
import React, { useEffect, useContext } from "react";
import AlertContext from "../../../../context/AlertContext";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MockLearningPathModuleTable from "./MockLearningPathModuleTable";
import { MockTestPageContext } from "../../../../context/MockTestPageContext";
import MockLearningPathModuleHooks from "../../../../Hooks/Mock/Admin/MockLearningPath/MockLearningPathModuleHooks";

export default function MockLearningPathModule() {
  const Alert = useContext(AlertContext);
  const MockTestPageCxt = useContext(MockTestPageContext);

  const MockLearningPathModuleHks = MockLearningPathModuleHooks();

  useEffect(() => {
    MockTestPageCxt.setFocus(1);

    let newDetails = Alert.isOldLpDetail
      ? JSON.parse(JSON.stringify(Alert.lpData2.modules))
      : MockTestPageCxt.newLpDetails !== undefined &&
        MockTestPageCxt.newLpDetails.hasOwnProperty("modules")
      ? JSON.parse(JSON.stringify(MockTestPageCxt.newLpDetails.modules))
      : [];

    if (Alert.isOldLpDetail) {
      newDetails.sort((a, b) => a.moduleorderno - b.moduleorderno);

      for (let i = 0; i < newDetails.length; i++) {
        for (let j = 0; j < newDetails[i].moduleData.length; j++) {
          if (
            newDetails[i].moduleData[j].hasOwnProperty("testId") &&
            newDetails[i].moduleData[j].testId == null &&
            newDetails[i].moduleData[j].hasOwnProperty("libId") &&
            newDetails[i].moduleData[j].libId == null
          ) {
            newDetails[i].moduleData.splice(j, 1);
          }
        }

        newDetails[i].moduleData.sort((a, b) => a.orderno - b.orderno);

        let moduleDuedate = "";

        for (let k = 0; k < newDetails[i].moduleData.length; k++) {
          if (
            newDetails[i].moduleData[k].published === 1 &&
            newDetails[i].moduleData[k].libTypeId !==
              constants.libTypeIds.CONTENT
          )
            moduleDuedate = newDetails[i].moduleData[k].dueDate;
        }

        newDetails[i].dueDate = moduleDuedate;
      }

      MockTestPageCxt.setModules(newDetails);
    } else {
      if (
        MockTestPageCxt.newLpDetails !== undefined &&
        MockTestPageCxt.newLpDetails.hasOwnProperty("modules")
      ) {
        MockTestPageCxt.setModules(MockTestPageCxt.newLpDetails.modules);
      } else {
        MockTestPageCxt.setModules([]);
      }
    }

    if (newDetails.length === 1)
      MockTestPageCxt.setExpandedRecords(newDetails[0]);
    else {
      let record = newDetails.filter(
        (module) => module.key === Alert.moduleExpandKey[0]
      );

      MockTestPageCxt.setExpandedRecords(record[0]);
    }

    Alert.setModuleExpandKey(
      newDetails.length === 0
        ? []
        : newDetails.length === 1
        ? [newDetails[0].key]
        : Alert.moduleExpandKey
    );
  }, [Alert.lpData2, Alert.isOldLpDetail]);

  return (
    <>
      {!Alert.isFromBatch && (
        <div style={{ textAlign: "left", margin: "0 0 20px 0" }}>
          <FontAwesomeIcon
            icon={faArrowLeft}
            className="adminLpBackBtn"
            onClick={() =>
              Alert.isOldLpDetail
                ? MockLearningPathModuleHks.handleBackBtn
                : Alert.setIsAddLpModule(false)
            }
          />
          &nbsp;
          <span className="adminLpBack">Mock Learningpath</span>
        </div>
      )}
      {Alert.isFromBatch && (
        <div className="bdCourseHeadSec">
          <p className="bdCoursesInCourseName">
            {MockTestPageCxt.newLpDetails.lpName}
          </p>
          <Edit03
            width={20}
            height={20}
            stroke="#344054"
            strokeWidth="1.2"
            onClick={MockLearningPathModuleHks.handleBackBtn}
          />
        </div>
      )}

      <MockLearningPathModuleTable />

      <div className="adminLpBtnSec">
        <Button
          size="sm"
          onClick={MockLearningPathModuleHks.handleCancel}
          hierarchy={{
            buttonText: "Cancel",
            type: "secondaryGrey",
          }}
        />
        &nbsp;&nbsp;&nbsp;&nbsp;
        <Button
          size="sm"
          onClick={MockLearningPathModuleHks.handleProceed}
          hierarchy={{
            type: "primary",
            buttonText:
              Alert.isOldLpDetail || Alert.isFromBatch ? "Update" : "Proceed",
          }}
        />
      </div>
    </>
  );
}
