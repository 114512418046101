import React from "react";
import Badge from "../../CommonComponents/Badge";

export default function StudentTestVideoQuestionCard(Props) {
  return (
    <div className="vidCardContainer">
      <div className="qnum">Question{" " + Props.qno}</div>
      <div className="bottomCardContainer">
        <div className="timeContainer">
          <p className="allottedTime">Allotted Time</p>
          <p className="timeInMinutes">{Props.qtime} Minutes</p>
        </div>
        {Props.status ? (
          <div className="questionStatusAnsContainer">
            <Badge size="sm" label="Answered" color="success" />
          </div>
        ) : (
          <div className="questionStatusUnAnsContainer">
            <Badge size="sm" label="Unanswered" color="error" />
          </div>
        )}
      </div>
    </div>
  );
}
