import React from 'react'

export default function Mail() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2.66536 2.66699H13.332C14.0654 2.66699 14.6654 3.26699 14.6654 4.00033V12.0003C14.6654 12.7337 14.0654 13.3337 13.332 13.3337H2.66536C1.93203 13.3337 1.33203 12.7337 1.33203 12.0003V4.00033C1.33203 3.26699 1.93203 2.66699 2.66536 2.66699Z" fill="#667085"/>
        <path d="M14.6654 4.00033L7.9987 8.66699L1.33203 4.00033" fill="#667085"/>
        <path d="M14.6654 4.00033C14.6654 3.26699 14.0654 2.66699 13.332 2.66699H2.66536C1.93203 2.66699 1.33203 3.26699 1.33203 4.00033M14.6654 4.00033V12.0003C14.6654 12.7337 14.0654 13.3337 13.332 13.3337H2.66536C1.93203 13.3337 1.33203 12.7337 1.33203 12.0003V4.00033M14.6654 4.00033L7.9987 8.66699L1.33203 4.00033" stroke="#F2F4F7" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  )
}
