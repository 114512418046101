import { useContext, useState } from "react";
import AlertContext from "../context/AlertContext";

export default function ModuleHooks() {
  const {
    isCommonPopup,
    setIsCommonPopup,
    isWobtnPopup,
    setIsWoBtnPopup,
    isCommonPopupCloseIcon,
    setIsCommonPopupCloseIcon,
    commonPopupTitle,
    setCommonPopupTitle,
    commonPopupTitleDesc,
    setCommonPopupTitleDesc,
    iscommonPopupBodyComponent,
    setIsCommonPopupBodyComponent,
    commonPopupBodyComponent,
    setCommonPopupBodyComponent,
    commonPopupBodyDesc,
    setCommonPopupBodyDesc,
    iscommonPopupSingleBtn,
    setIsCommonPopupSingleBtn,
    commonPopupBtnSize,
    setCommonPopupBtnSize,
    commonPopupSingleBtnTxt,
    setCommonPopupSingleBtnTxt,
    commonPopupSingleBtnFunc,
    setCommonPopupSingleBtnFunc,
    commonPopupPrimaryBtnTxt,
    setCommonPopupPrimaryBtnTxt,
    commonPopupSecBtnTxt,
    setCommonPopupSecBtnTxt,
    commonPopupPrimaryBtnFunc,
    setCommonPopupPrimaryBtnFunc,
    commonPopupSecBtnFunc,
    setCommonPopupSecBtnFunc,
  } = useContext(AlertContext);

  const [isClearedState, setIsClearedState] = useState(false);
  const [isConfirmClearedState, setIsConfirmClearedState] = useState(false);

  const handlePopupStates = (data) => {
    setIsCommonPopup(data.showpopup != undefined && data.showpopup);
    setIsWoBtnPopup(data.wobtn != undefined && data.wobtn);
    setIsCommonPopupCloseIcon(data.closebtn != undefined && data.closebtn);
    setCommonPopupTitle(data.title != undefined && data.title);
    setCommonPopupTitleDesc(data.titledesc != undefined && data.titledesc);
    setIsCommonPopupBodyComponent(
      data.iscomponent != undefined && data.iscomponent
    );
    setCommonPopupBodyComponent(data.component != undefined && data.component);

    setCommonPopupBodyDesc(data.bodydesc != undefined && data.bodydesc);
    setIsCommonPopupSingleBtn(data.singlebtn != undefined && data.singlebtn);
    setCommonPopupBtnSize(
      data.singlebtnsize != undefined && data.singlebtnsize
    );
    setCommonPopupSingleBtnTxt(
      data.singlebtntxt != undefined && data.singlebtntxt
    );
    setCommonPopupSingleBtnFunc(
      data.singlebtnfunt != undefined && (() => data.singlebtnfunt)
    );

    setCommonPopupPrimaryBtnTxt(
      data.primbtntxt != undefined && data.primbtntxt
    );
    setCommonPopupSecBtnTxt(data.secbtntxt != undefined && data.secbtntxt);
    setCommonPopupPrimaryBtnFunc(
      data.primbtnfunt != undefined && (() => data.primbtnfunt)
    );
    setCommonPopupSecBtnFunc(
      data.secbtnfunt != undefined && (() => data.secbtnfunt)
    );
  };

  const clearPopupStates = () => {
    setIsCommonPopup(false);
    setIsWoBtnPopup(false);
    setIsCommonPopupCloseIcon(false);
    setCommonPopupTitle("");
    setCommonPopupTitleDesc("");
    setIsCommonPopupBodyComponent(false);
    setCommonPopupBodyComponent(undefined);
    setCommonPopupBodyDesc("");
    setIsCommonPopupSingleBtn(true);
    setCommonPopupBtnSize("");
    setCommonPopupSingleBtnTxt("");
    setCommonPopupSingleBtnFunc(undefined);
    setCommonPopupPrimaryBtnTxt("");
    setCommonPopupSecBtnTxt("");
    setCommonPopupPrimaryBtnFunc(undefined);
    setCommonPopupSecBtnFunc(undefined);
    return true;
  };

  return {
    isCommonPopup,
    setIsCommonPopup,
    isCommonPopupCloseIcon,
    setIsCommonPopupCloseIcon,
    commonPopupTitle,
    setCommonPopupTitle,
    commonPopupTitleDesc,
    setCommonPopupTitleDesc,
    iscommonPopupBodyComponent,
    setIsCommonPopupBodyComponent,
    commonPopupBodyComponent,
    setCommonPopupBodyComponent,
    commonPopupBodyDesc,
    setCommonPopupBodyDesc,
    iscommonPopupSingleBtn,
    setIsCommonPopupSingleBtn,
    commonPopupBtnSize,
    setCommonPopupBtnSize,
    commonPopupSingleBtnTxt,
    setCommonPopupSingleBtnTxt,
    commonPopupSingleBtnFunc,
    setCommonPopupSingleBtnFunc,
    commonPopupPrimaryBtnTxt,
    setCommonPopupPrimaryBtnTxt,
    commonPopupSecBtnTxt,
    setCommonPopupSecBtnTxt,
    commonPopupPrimaryBtnFunc,
    setCommonPopupPrimaryBtnFunc,
    commonPopupSecBtnFunc,
    setCommonPopupSecBtnFunc,
    isClearedState,
    setIsClearedState,
    isConfirmClearedState,
    setIsConfirmClearedState,
    handlePopupStates,
    clearPopupStates,
  };
}
