import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import CodeEditor from "./CodingPlayground";
// import CodeEditor from "../../Student/CodeSection/CodeEditor/CodeEditor";
import ReactDom from "react-dom";
// const  modules  = {
//     toolbar: [
//         [{ font: [] }],
//         [{ header: [1, 2, 3, 4, 5, 6, false] }],
//         ["bold", "italic", "underline", "strike"],
//         [{ color: [] }, { background: [] }],
//         [{ script:  "sub" }, { script:  "super" }],
//         ["blockquote", "code-block"],
//         [{ list:  "ordered" }, { list:  "bullet" }],
//         [{ indent:  "-1" }, { indent:  "+1" }, { align: [] }],
//         ["link", "image", "video"],
//         ["clean"],
//     ],
// };
const formats = [
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "color",
  "background",
  "script",
  "header",
  "blockquote",
  "code-block",
  "indent",
  "list",
  "direction",
  "align",
  "link",
  "image",
  "video",
  "formula",
];

let langid = 2;

let code = `function boilerPlate () {
  //console.log("Welcome to our code editor")
}`;

export default class Code {
  static get toolbox() {
    return {
      icon: `<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M19 15v4H5v-4h14m1-2H4c-.55 0-1 .45-1 1v6c0 .55.45 1 1 1h16c.55 0 1-.45 1-1v-6c0-.55-.45-1-1-1zM7 18.5c-.82 0-1.5-.67-1.5-1.5s.68-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5zM19 5v4H5V5h14m1-2H4c-.55 0-1 .45-1 1v6c0 .55.45 1 1 1h16c.55 0 1-.45 1-1V4c0-.55-.45-1-1-1zM7 8.5c-.82 0-1.5-.67-1.5-1.5S6.18 5.5 7 5.5s1.5.68 1.5 1.5S7.83 8.5 7 8.5z"/></svg>`,
      title: "CodeEditor",
    };
  }

  static get enableLineBreaks() {
    return true
  }

  

  // onKeyDown(event){
  //   if(event.key === 'Enter'){
  //     event.preventDefault();

  //     const editor = ace.edit('code-editor')
  //   }
  // }

  static get isReadOnlySupported() {
    return true;
  }

  constructor({ data, config, api, readOnly }) {
    this.api = api;
    this.config = config;
    this.readOnly = readOnly;

    //this._data={};
    this.data = {
      // events: data.events || [],
      // blockContent: data && data.blockContent ? data.blockContent : "",
      langid: data && data.langid ?  data.langid: "",
      code: data &&  data.code ? data.code : "",
      output:  data && data.output ? data.output : ""
    };

    // this.state = {
    //   text: ""
    // };

    this.CSS = {
      wrapper: "code-editor",
    };

    this.nodes = {
      holder: null,
    };
  }

  // setText = (newText) =>{
  //   this.setState({text:newText})
  // }



  render() {
    // const {text} = this.state;
    const rootNode = document.createElement("div");
    rootNode.setAttribute("class", this.CSS.wrapper);
    this.nodes.holder = rootNode;

    const onDataChange = (newData,Langid,outPut) => {
      //console.log("PRINTING *************"+newData + langid)
      this.data.code=newData;
      this.data.langid = Langid;
      this.data.output = outPut;

      //console.log(this.data)
    }

    ReactDom.render(
      // <ReactQuill
      // value= {text}
      //   onChange={()=>onDataChange()}

      //   modules={modules}
      //   formats={formats}
      // />

    <CodeEditor onDataChange={onDataChange} langid={this.data.langid} code={this.data.code} output={this.data.output}/>,
      rootNode
    );

    return this.nodes.holder;
  }

  save() {
    // this.data.blockContent = content;
    // return {
    //   blockContent: content, //this.data.blockContent
    // };
    
    return this.data;
  }
}