import React from 'react'

export default function GlopeSvg() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="24" height="24" rx="12" fill="#F96343"/>
    <g clip-path="url(#clip0_5506_10413)">
    <path d="M11.9987 5.33301C13.6662 7.15858 14.6139 9.5277 14.6654 11.9997C14.6139 14.4717 13.6662 16.8408 11.9987 18.6663M11.9987 5.33301C10.3312 7.15858 9.38353 9.5277 9.33203 11.9997C9.38353 14.4717 10.3312 16.8408 11.9987 18.6663M11.9987 5.33301C8.3168 5.33301 5.33203 8.31778 5.33203 11.9997C5.33203 15.6816 8.3168 18.6663 11.9987 18.6663M11.9987 5.33301C15.6806 5.33301 18.6654 8.31778 18.6654 11.9997C18.6654 15.6816 15.6806 18.6663 11.9987 18.6663M5.66538 9.99967H18.332M5.66536 13.9997H18.332" stroke="white" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
    <defs>
    <clipPath id="clip0_5506_10413">
    <rect width="16" height="16" fill="white" transform="translate(4 4)"/>
    </clipPath>
    </defs>
    </svg>
  )
}
