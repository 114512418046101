import React from "react";

export default function ModuleProgressSvgIcon(Props) {
  return (
    <div>
      {Props.currentModuleStatus === 0 ? (
        <svg
          width="20"
          height="20"
          fill="none"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle opacity="0.3" cx="10" cy="10" r="10" fill="#12B76A" />
        </svg>
      ) : Props.currentModuleStatus > 0 && Props.currentModuleStatus <= 25 ? (
        <svg
          width="20"
          height="20"
          fill="none"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill="#12B76A"
            d="M10 0C11.3132 1.566e-08 12.6136 0.258658 13.8268 0.761205C15.0401 1.26375 16.1425 2.00035 17.0711 2.92893C17.9997 3.85752 18.7362 4.95991 19.2388 6.17317C19.7413 7.38642 20 8.68678 20 10L10 10V0Z"
          />
          <circle opacity="0.3" cx="10" cy="10" r="10" fill="#12B76A" />
        </svg>
      ) : Props.currentModuleStatus > 25 && Props.currentModuleStatus <= 50 ? (
        <svg
          width="20"
          height="20"
          fill="none"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill="#12B76A"
            d="M10 0C11.3132 1.566e-08 12.6136 0.258658 13.8268 0.761205C15.0401 1.26375 16.1425 2.00035 17.0711 2.92893C17.9997 3.85752 18.7362 4.95991 19.2388 6.17317C19.7413 7.38642 20 8.68678 20 10C20 11.3132 19.7413 12.6136 19.2388 13.8268C18.7362 15.0401 17.9997 16.1425 17.0711 17.0711C16.1425 17.9997 15.0401 18.7362 13.8268 19.2388C12.6136 19.7413 11.3132 20 10 20L10 10V0Z"
          />
          <circle opacity="0.3" cx="10" cy="10" r="10" fill="#12B76A" />
        </svg>
      ) : Props.currentModuleStatus > 50 && Props.currentModuleStatus <= 75 ? (
        <svg
          width="20"
          height="20"
          fill="none"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill="#12B76A"
            d="M10 0C11.9778 2.35852e-08 13.9112 0.58649 15.5557 1.6853C17.2002 2.78412 18.4819 4.3459 19.2388 6.17317C19.9957 8.00043 20.1937 10.0111 19.8079 11.9509C19.422 13.8907 18.4696 15.6725 17.0711 17.0711C15.6725 18.4696 13.8907 19.422 11.9509 19.8079C10.0111 20.1937 8.00043 19.9957 6.17317 19.2388C4.3459 18.4819 2.78412 17.2002 1.6853 15.5557C0.58649 13.9112 -4.71704e-08 11.9778 0 10H10V0Z"
          />
          <circle opacity="0.3" cx="10" cy="10" r="10" fill="#12B76A" />
        </svg>
      ) : (
        <svg
          width="20"
          height="20"
          fill="none"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="10" cy="10" r="10" fill="#12B76A" />
          <circle opacity="0.3" cx="10" cy="10" r="10" fill="#12B76A" />
          <path
            stroke="#24264F"
            stroke-width="1.33"
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M14 7L8.5 12.5L6 10"
          />
          <path
            stroke="#24264F"
            stroke-width="1.33"
            stroke-linecap="round"
            d="M14 7L8.5 12.5L6 10"
            stroke-linejoin="round"
          />
        </svg>
      )}
    </div>
  );
}
