import { useRef, useState } from "react";

export default function LanguageInputHooks() {
  const textAreaRef = useRef(null);

  let [bCode, setBCode] = useState("");
  let [eCode, setECode] = useState("");
  let [sCode, setSCode] = useState("");
  let [inVaTypeRef, setInVaTypeRef] = useState([]);

  const [langId, setLangId] = useState();
  const [active, setActive] = useState({});
  const [platesChange, setPlatesChange] = useState(true);
  const [showTestButton, setShowTestButton] = useState(false);

  const handleFocus = (event) => {
    textAreaRef.current = event.target;
  };

  return {
    bCode, 
    eCode, 
    sCode, 
    langId,
    active, 
    setSCode,
    setECode,
    setBCode,
    setActive,
    setLangId,
    textAreaRef,
    inVaTypeRef,
    handleFocus,
    platesChange, 
    setInVaTypeRef,
    showTestButton,
    setPlatesChange,
    setShowTestButton,
  };
}
