import React, { createContext, useState } from "react";

export const LibraryContext = createContext();

export const LibraryProvider = ({ children }) => {
  const [sharedTopics, setSharedTopics] = useState([]);
  const [filterSharedTopics, setFilterSharedTopics] = useState([]);
  const [selectedTopic, setSelectedTopic] = useState({
    value: "",
    name: "",
    id: "",
  });
  const [searchText, setSearchText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [topics, setTopics] = useState([]);

  return (
    <LibraryContext.Provider
      value={{
        sharedTopics,
        setSharedTopics,
        selectedTopic,
        setSelectedTopic,
        searchText,
        setSearchText,
        currentPage,
        setCurrentPage,
        topics,
        setTopics,
        filterSharedTopics,
        setFilterSharedTopics,
      }}
    >
      {children}
    </LibraryContext.Provider>
  );
};
