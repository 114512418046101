import Button from "./Button";
import Box from "@mui/material/Box";
import constants from "../constants";
import React, { useContext } from "react";
import PinSvgIcon from "../Svg/PinSvgIcon";
import CloseSvgIcon from "../Svg/CloseSvgIcon";
import ApproveHooks from "../Hooks/ApproveHooks";
import AlertContext from "../context/AlertContext";
import CommentsHooks from "../Hooks/CommentsHooks";
import ImageLogoSvgIcon from "../Svg/ImageLogoSvgIcon";
import { TextareaAutosize } from "@mui/base/TextareaAutosize";

export default function Approve() {
  const {
    score,
    comment,
    testData,
    approveData,
    setShowReject,
    setShowApprove,
    commentAttachment,
    showBreadCrumbsData,
  } = useContext(AlertContext);

  const {
    changeComments,
    handleCommentAttachment,
    handleDeleteCommentAttachment,
  } = CommentsHooks();

  const { addGrade, changeScore, scoreChanged, handleScoreSelect } =
    ApproveHooks();

  return (
    <div className="approveMainContainer">
      <Box
        sx={{
          p: 3,
          width: 360,
          boxShadow: 2,
          maxHeight: 600,
          borderRadius: 2,
          overflow: "auto",
          bgcolor: "#FFFFFF",
          minHeight:
            testData.testtype === constants.libTypeIds.ASSIGNMENT ? 458 : 422,
        }}
      >
        <div className="approveLabel">Mark Score :</div>
        <div className="approveScoreDiv">
          <input
            value={score}
            placeholder={score}
            onChange={changeScore}
            onClick={handleScoreSelect}
            className="approveScoreDivInput"
          />

          <div className="approveScoreDivTotalMarks">/ {approveData.marks}</div>
        </div>
        <div className="approveInstructionDiv">
          <span className="approveInstructionDivLabel">
            You can evaluate by marking the score above
          </span>
        </div>

        <div className="approveCommentsLabel">Comments</div>
        <div>
          <TextareaAutosize
            input={comment}
            value={comment}
            key={"inputs" + 1}
            onChange={changeComments}
            className="approveCommentsTextArea"
            placeholder="Write your comment for the trainee"
          />
          {showBreadCrumbsData.type === constants.libTypeIds.ASSIGNMENT &&
            commentAttachment.map((attachment) => (
              <div
                key={attachment.id}
                className="rejectCommentsForAssignmentFileUpload"
              >
                <div className="rejectCommentsForAssignmentFileUploadInnerDiv">
                  <ImageLogoSvgIcon />
                  <div className="rejectCommentsForAssignmentFileUploadFileName">
                    {attachment.file.name}
                  </div>

                  <div className="rejectCommentsForAssignmentFileUploadFileSize">
                    {attachment.hasOwnProperty("file") &&
                    attachment.file.size !== undefined &&
                    (attachment.file.size / 1e6).toFixed(2) > 1
                      ? `${(attachment.file.size / 1e6).toFixed(2)} MB`
                      : `${(attachment.file.size / 1024).toFixed(2)} KB`}
                  </div>
                </div>
                <div className="rejectCommentsForAssignmentCloseSvgIcon">
                  <CloseSvgIcon
                    onClick={() => handleDeleteCommentAttachment(attachment.id)}
                  />
                </div>
              </div>
            ))}

          {showBreadCrumbsData.type === constants.libTypeIds.ASSIGNMENT && (
            <div className="rejectCommentsForAssignmentAttachmentDiv">
              <PinSvgIcon />
              <input
                multiple
                type="file"
                accept="image/*"
                onChange={handleCommentAttachment}
                className="rejectCommentsForAssignmentAttachmentInput"
              />
            </div>
          )}
        </div>
        <div className="approveButtonDiv">
          <Button
            size={"sm"}
            onClick={() => {
              setShowReject(false);
              setShowApprove(false);
            }}
            hierarchy={{
              buttonText: "Cancel",
              type: "secondaryGrey",
            }}
          />
          <Button
            size={"sm"}
            disabled={!scoreChanged && comment.length === ""}
            onClick={() => {
              addGrade(score);
            }}
            hierarchy={{
              type: "primary",
              buttonText: "Submit",
            }}
          />
        </div>
      </Box>
    </div>
  );
}
