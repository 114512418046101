import { nanoid } from "nanoid";
import constants from "../constants";
import { useState, useContext } from "react";
import useAxiosPrivate from "./useAxiosPrivate";
import AlertContext from "../context/AlertContext";
import useCommonFunctionHooks from "./useCommonFunctionHooks";

const useAddOrUpdateLp = () => {
  const Alert = useContext(AlertContext);

  const axios = useAxiosPrivate();
  const { getHeaderDetailsForAxios } = useCommonFunctionHooks();

  const [successRes, setSuccessRes] = useState({
    msg: "",
    title: "",
    isSuccess: false,
    isProctored: false,
  });

  const handleAddOrUpdateLp = (newLpDetails, modules, setModules, mockuse) => {
    return new Promise((resolve, reject) => {
      let newDetails = Alert.isOldLpDetail
          ? JSON.parse(JSON.stringify(Alert.lpData2))
          : JSON.parse(JSON.stringify(newLpDetails)),
        found = 0,
        orderno = 1,
        typefound = 0,
        moduleorderno = 1;

      if (mockuse === true) {
        newDetails.mockuse = true;
      }

      newDetails.modules = JSON.parse(JSON.stringify(modules ?? []));

      if (newDetails.modules.length > 0) {
        for (let i = 0; i < newDetails.modules.length; i++) {
          if (newDetails.modules[i].moduleName !== "") {
            if (newDetails.modules[i].moduleData.length > 0) {
              for (
                let j = 0;
                j < newDetails.modules[i].moduleData.length;
                j++
              ) {
                newDetails.modules[i].moduleData[j].orderno = orderno;
                orderno++;
              }

              newDetails.modules[i].moduleorderno = moduleorderno;
              moduleorderno++;
            } else {
              newDetails.modules[i].moduleorderno = moduleorderno;
              moduleorderno++;
            }
          } else {
            reject({
              show: true,
              title: "Warning",
              message: "Please give module name",
            });

            found = 1;
            break;
          }
        }
      } else {
        found = 1;
        reject({
          show: true,
          title: "Warning",
          message: "Please add module",
        });
      }

      if (found === 0) {
        Alert.setIsLoaded(true);

        if (!Alert.isOldLpDetail) {
          axios
            .post(
              process.env.REACT_APP_NODE_API + "node/admin/learningpath/create",
              JSON.stringify(newDetails),
              getHeaderDetailsForAxios()
            )
            .then((creatLpRes) => {
              let data = creatLpRes.data;

              if (data.resultCode === constants.RESULT_STATUS.SUCCESS) {
                if (data.msg.includes("created")) {
                  setSuccessRes({
                    isSuccess: true,
                    title: "Success",
                    isProctored: false,
                    msg: "The path is created successfully",
                  });

                  resolve({
                    show: true,
                    title: "success2",
                    message: "Path is updated successfully",
                  });
                } else {
                  reject({ title: "Warning !", message: data.msg, show: true });
                }
              } else if (
                data.resultCode === constants.RESULT_STATUS.TECHNICAL_ERROR &&
                data.msg.sqlMessage.includes("Duplicate entry")
              ) {
                Alert.setIsAddLpModule(false);

                reject({
                  show: true,
                  title: "Warning !",
                  message: "The path name is already taken.",
                });
              } else {
                reject({
                  show: true,
                  title: "Error !",
                  message: "Something went wrong. Please try again later.",
                });
              }
            })
            .catch((createTestErr) => {
              if (createTestErr.message.includes("403")) {
                reject({
                  show: true,
                  isUnAuth: true,
                  title: "Warning !",
                  message:
                    "You have been logged-out due to inactivity. Login again..",
                });
              }
            })
            .finally(() => Alert.setIsLoaded(false));
        } else {
          if (newDetails.status === "" || !newDetails.status) {
            for (let i = 0; i < newDetails.modules.length; i++) {
              if (
                newDetails.modules[i].status === "new" ||
                newDetails.modules[i].moved === "yes"
              ) {
                typefound = 1;
                break;
              }

              for (
                let j = 0;
                j < newDetails.modules[i].moduleData.length;
                j++
              ) {
                if (
                  newDetails.modules[i].moduleData[j].status === "new" ||
                  newDetails.modules[i].moduleData[j].moved === "yes"
                ) {
                  typefound = 1;
                  break;
                }
              }

              if (typefound === 1) break;
            }
          } else if (newDetails.status === "update") typefound = 1;
          newDetails.batchType = Alert.batchDetails.batchtype;

          if (typefound === 1) {
            axios
              .post(
                process.env.REACT_APP_NODE_API +
                  "node/admin/learningpath/update",
                JSON.stringify(newDetails),
                getHeaderDetailsForAxios()
              )
              .then((updateLpRes) => {
                if (
                  updateLpRes.data.resultCode ===
                  constants.RESULT_STATUS.SUCCESS
                ) {
                  let lpDataFromRes = updateLpRes.data.lpData;
                  let newDetails = lpDataFromRes[0],
                    uniqueId = 1;
                  newDetails.batchName =
                    Alert.isOldLpDetail && Alert.isFromBatch
                      ? Alert.lpData2.batchName
                      : Alert.lpData2.lpName;

                  newDetails.batchId =
                    Alert.isOldLpDetail && Alert.isFromBatch
                      ? Alert.lpData2.batchId
                      : Alert.lpData2.lpId;

                  for (let i = 0; i < newDetails.modules.length; i++) {
                    newDetails.modules[i].id = uniqueId;
                    newDetails.modules[i].key = nanoid();
                    newDetails.modules[i].isAddTest = false;
                    newDetails.modules[i].status = "old";
                    uniqueId++;

                    for (
                      let j = 0;
                      j < newDetails.modules[i].moduleData.length;
                      j++
                    ) {
                      newDetails.modules[i].moduleData[j].moduleDataId =
                        uniqueId;
                      newDetails.modules[i].moduleData[j].key = nanoid();
                      newDetails.modules[i].moduleData[j].status = "old";
                      newDetails.modules[i].moduleData[j].testStatus = "old";
                      newDetails.modules[i].moduleData[j].title =
                        newDetails.modules[i].moduleData[j].testName;

                      delete newDetails.modules[i].moduleData[j].testName;
                      uniqueId++;
                    }
                  }

                  let modules = [...newDetails.modules];

                  modules.sort((a, b) => a.maximumorderno - b.maximumorderno);

                  for (let i = 0; i < modules.length; i++) {
                    modules[i].moduleData.sort((a, b) => a.orderno - b.orderno);

                    let moduleDuedate = "";

                    for (let j = 0; j < modules[i].moduleData.length; j++) {
                      if (modules[i].moduleData[j].published === 1)
                        moduleDuedate = modules[i].moduleData[j].dueDate;
                    }

                    modules[i].dueDate = moduleDuedate;
                  }

                  newDetails.modules = modules;
                  Alert.setModuleExpandKey([modules[0].key]);
                  Alert.setLpData2(newDetails);
                  Alert.handleNavigateToLpAfterAddTest(newDetails);
                  resolve({
                    show: true,
                    modules: modules,
                    title: "success",
                    message: "Path is updated successfully",
                  });
                } else {
                  reject({
                    show: true,
                    title: "Warning !",
                    message: "Something went wrong. Please try again later.",
                  });
                }
              })
              .catch((updateLpErr) => {
                if (updateLpErr.message.includes("403")) {
                  reject({
                    show: true,
                    isUnAuth: true,
                    title: "Error !",
                    message:
                      "You have been logged-out due to inactivity. Login again..",
                  });
                }
              })
              .finally(() => Alert.setIsLoaded(false));
          } else {
            Alert.setIsLoaded(false);
            reject({
              show: true,
              title: "Warning !",
              message: "There is no data to update",
            });
          }
        }
      }
    });
  };

  return { handleAddOrUpdateLp, successRes, setSuccessRes };
};

export default useAddOrUpdateLp;
