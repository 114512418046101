import { useState } from "react";

export default function VarTypesHooks() {
  const [showAdd, setShowAdd] = useState(false);
  const [currentType, setCurrentType] = useState({});
  const [currentInp, setCurrentInp] = useState({ varname: "", vartype: "" });

  const inputVarTypes = [
    { value: "int", label: "Int" },
    { value: "float", label: "Float" },
    { value: "String", label: "String" },
    {value: "Char", label: "Char"}, 
    { value: "array-1int", label: "Array 1D Number" },
    { value: "array-2int", label: "Array 2D Number" },
    { value: "array-1string", label: "Array 1D String" },
    { value: "array-2string", label: "Array 2D String" },
  ];

  const customStylesForTopic = {
    control: (base, state) => ({
      ...base,
      marginTop: "20px",
      border: "1px solid #BDC1C6",
      minHeight: "34px !important",
      maxHeight: "34px !important",
    }),
    option: (styles, { isSelected }) => {
      return {
        ...styles,
        background: "white",
        ":hover": {
          color: "white",
          backgroundColor: "#0d6efd",
        },
        width: "150px",
        height: "28px",
        fontSize: "14px",
        fontWeight: "400",
        lineHeight: "20px",
        overflowx: "hidden",
        fontFamily: "Inter",
        color: isSelected ? "#000000" : undefined,
      };
    },
    singleValue: (styles, { data }) => ({
      ...styles,
      color: "#202124",
      fontSize: "14px",
      fontWeight: "400",
      textAlign: "left",
      lineHeight: "20px",
      paddingLeft: "10px",
      fontFamily: "Inter",
      fontStyle: "normal",
      borderRadius: "20px",
    }),
    menuList: (base) => ({
      ...base,
      textAlign: "left",
      maxHeight: "150px",
      borderRadius: "2px",
    }),
  };

  const handleChange = (e) => {
    let temp;

    if (e.hasOwnProperty("value")) {
      temp = { ...currentInp, vartype: e.value };
      checkAndEnableAddButton(currentInp["varname"], e.value);
      setCurrentType(e);
    } else {
      if (e.target.value.includes(" ") === false) {
        temp = { ...currentInp, [e.target.name]: e.target.value };
      } else {
        temp = {
          ...currentInp,
          [e.target.name]: e.target.value.replace(" ", ""),
        };
      }

      checkAndEnableAddButton(e.target.value, currentType.value);
    }

    setCurrentInp(temp);
  };

  const checkAndEnableAddButton = (name, type) => {
    if (name === "" || type === undefined) {
      setShowAdd(false);
    } else {
      setShowAdd(true);
    }
  };

  return {
    showAdd,
    setShowAdd,
    currentInp,
    currentType,
    handleChange,
    setCurrentInp,
    inputVarTypes,
    setCurrentType,
    customStylesForTopic,
  };
}
