import "./Learningpath.scss";
import { nanoid } from "nanoid";
import Modules from "./Modules";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import constants from "../../../constants";
import Button from "../../Button";
import NewLearningPath from "./NewLearningPath";
import AlertContext from "../../../context/AlertContext";
import useAxiosPrivate from "../../../Hooks/useAxiosPrivate";
import { faSearch, faTableList, faTh } from "@fortawesome/free-solid-svg-icons";
import Notify from "../../Notification-Loading/Notify/Notify";
import React, { useState, useEffect, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Loading from "../../Notification-Loading/Loading/Loading";
import LpListView from "../Batch/ListView";
import ToggleButton from "../../CommonComponents/ToggleButton";
import PathCard from "./PathCard";

const AdminLearningPath = (Props) => {
  const {
    msg,
    show,
    title,
    setMsg,
    lpData2,
    setShow,
    isAddLp,
    setTitle,
    isLoaded,
    isUnAuth,
    setLpData2,
    setIsAddLp,
    setIsLoaded,
    setIsUnAuth,
    handleClose,
    setIsFromLp,
    isAddLpModule,
    isOldLpDetail,
    moduleExpandKey,
    setIsAddLpModule,
    setIsOldLpDetail,
    handleUnAuthAlert,
    setModuleExpandKey,
    isFromBatch,
    setLpGridView,
    lpGridView,
  } = useContext(AlertContext);

  const axios = useAxiosPrivate();

  const [filterLP, setFilterLP] = useState([]);
  const [learningPaths, setLearningPaths] = useState([]);
  const [showArchivedLps, setShowArchivedLps] = useState(false);

  useEffect(() => {
    if (!isAddLp) {
      setIsLoaded(true);
      (async () => {
        try {
          let response = await axios.get(
            `node/admin/learningpath/get?archived=${showArchivedLps}`,
            {
              headers: { "Content-type": "application/json" },
            }
          );

          if (response.data.resultCode === constants.RESULT_STATUS.SUCCESS) {
            if (
              response.data.data !== null &&
              response.data.data.length !== 0
            ) {
              setLearningPaths(response.data.data);
              setFilterLP(response.data.data);
            } else {
              setLearningPaths([]);
              setFilterLP([]);
              setShow(true);
              setTitle("Warning !");
              setMsg("There is no path");
            }
          } else {
            setShow(true);
            setTitle("Error !");
            setMsg("Something went wrong. Please try again later");
          }
        } catch (err) {
          if (err.message.includes("403")) {
            setShow(true);
            setIsUnAuth(true);
            setTitle("Error !");
            setMsg("You have been logged-out due to inactivity. Login again.");
          } else {
            setShow(true);
            setTitle("Error !");
            setMsg("Something went wrong. Please try again later");
          }
        } finally {
          setIsLoaded(false);
        }
      })();
    } else return undefined;
  }, [
    isAddLp,
    axios,
    setIsLoaded,
    setIsUnAuth,
    setMsg,
    setTitle,
    setShow,
    showArchivedLps,
  ]);

  const handleSearchLp = (e) => {
    let inputValue = e.target.value.toLowerCase();
    let filtered = learningPaths.filter((lp) =>
      lp.name.toLowerCase().includes(inputValue)
    );

    setFilterLP(filtered);
  };

  const handleSpecificLp = (lpId, lpname) => {
    setIsAddLp(true);
    setIsLoaded(true);
    axios
      .get("node/admin/learningpath/" + lpId, {
        headers: { "Content-type": "application/json" },
      })
      .then((res) => {
        if (res.data.resultCode === 1000) {
          if (res.data.data === null) {
            setLpData2({
              lpDes: "",
              lpId: lpId,
              moduleId: "",
              lpContent: "",
              lpIcon: "",
              iconData: "",
              lpShortDes: "",
              id: nanoid(),
              libTypeId: 2,
              lpName: lpname,
              isAddTest: false,
              archived: 0,
              modules: [
                {
                  id: 1,
                  key: nanoid(),
                  status: "new",
                  moduleName: "",
                  moduleData: [],
                },
              ],
            });
            setIsOldLpDetail(true);
          } else {
            let newDetails = res.data.data[0],
              uniqueId = 1;

            // newDetails.modules.sort(
            //   (a, b) => a.maximumorderno - b.maximumorderno --old
            // );

            newDetails.modules.sort(
              (a, b) => a.moduleorderno - b.moduleorderno
            );

            for (let i = 0; i < newDetails.modules.length; i++) {
              newDetails.modules[i].id = uniqueId;
              newDetails.modules[i].key = nanoid();
              newDetails.modules[i].isAddTest = false;
              newDetails.modules[i].status = "old";
              uniqueId++;

              for (
                let j = 0;
                j < newDetails.modules[i].moduleData.length;
                j++
              ) {
                newDetails.modules[i].moduleData[j].moduleDataId = uniqueId;
                newDetails.modules[i].moduleData[j].key = nanoid();
                newDetails.modules[i].moduleData[j].status = "old";
                newDetails.modules[i].moduleData[j].testStatus = "old";
                newDetails.modules[i].moduleData[j].title =
                  newDetails.modules[i].moduleData[j].testName;
                delete newDetails.modules[i].moduleData[j].testName;
                uniqueId++;
              }
            }

            setLpData2(newDetails);
            setModuleExpandKey([newDetails.modules[0].key]);
            setIsOldLpDetail(true);
          }
        } else {
          setShow(true);
          setTitle("Error !");
          setMsg("Something went wrong. Please try again later");
        }

        setIsLoaded(false);
      })
      .catch((err) => {
        setIsLoaded(false);
        if (err.message.includes("403")) {
          setShow(true);
          setIsUnAuth(true);
          setTitle("Error !");
          setMsg("You have been logged-out due to inactivity. Login again.");
        }
      });
  };

  return (
    <div className={isFromBatch ? "" : "adminLpContainer"}>
      {!isAddLp && !isOldLpDetail ? (
        <>
          <div id="adminLpBtnContainer">
            <div style={{ display: "flex" }}>
              {/* <FontAwesomeIcon icon={faSearch} className="adminLpSearchIcon" /> */}
              <input
                type="text"
                name="search"
                id="adminLpSearch"
                placeholder="Search"
                onChange={handleSearchLp}
                className="adminLpSearchBox"
              />
            </div>
            <div className="allOptionsInBatch">
              <div className="viewOptions">
                {/* <FontAwesomeIcon
                onClick={() => {
                  setLpGridView(false);
                }}
                className="batchesListView"
                icon={faTableList}
              /> */}

                <div
                  className="listViewOfLps"
                  onClick={() => {
                    setLpGridView(false);
                  }}
                >
                  {lpGridView ? (
                    <svg
                      className="disableLpListView"
                      width="18"
                      height="14"
                      viewBox="0 0 18 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M16.5 7.00033L6.5 7.00033M16.5 2.00033L6.5 2.00033M16.5 12.0003L6.5 12.0003M3.16667 7.00033C3.16667 7.46056 2.79357 7.83366 2.33333 7.83366C1.8731 7.83366 1.5 7.46056 1.5 7.00033C1.5 6.54009 1.8731 6.16699 2.33333 6.16699C2.79357 6.16699 3.16667 6.54009 3.16667 7.00033ZM3.16667 2.00033C3.16667 2.46056 2.79357 2.83366 2.33333 2.83366C1.8731 2.83366 1.5 2.46056 1.5 2.00033C1.5 1.54009 1.8731 1.16699 2.33333 1.16699C2.79357 1.16699 3.16667 1.54009 3.16667 2.00033ZM3.16667 12.0003C3.16667 12.4606 2.79357 12.8337 2.33333 12.8337C1.8731 12.8337 1.5 12.4606 1.5 12.0003C1.5 11.5401 1.8731 11.167 2.33333 11.167C2.79357 11.167 3.16667 11.5401 3.16667 12.0003Z"
                        stroke="#667085"
                        strokeWidth="1.4"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  ) : (
                    <svg
                      className="learningPathListView"
                      width="18"
                      height="14"
                      viewBox="0 0 18 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M16.5 7.00033L6.5 7.00033M16.5 2.00033L6.5 2.00033M16.5 12.0003L6.5 12.0003M3.16667 7.00033C3.16667 7.46056 2.79357 7.83366 2.33333 7.83366C1.8731 7.83366 1.5 7.46056 1.5 7.00033C1.5 6.54009 1.8731 6.16699 2.33333 6.16699C2.79357 6.16699 3.16667 6.54009 3.16667 7.00033ZM3.16667 2.00033C3.16667 2.46056 2.79357 2.83366 2.33333 2.83366C1.8731 2.83366 1.5 2.46056 1.5 2.00033C1.5 1.54009 1.8731 1.16699 2.33333 1.16699C2.79357 1.16699 3.16667 1.54009 3.16667 2.00033ZM3.16667 12.0003C3.16667 12.4606 2.79357 12.8337 2.33333 12.8337C1.8731 12.8337 1.5 12.4606 1.5 12.0003C1.5 11.5401 1.8731 11.167 2.33333 11.167C2.79357 11.167 3.16667 11.5401 3.16667 12.0003Z"
                        stroke="#101828"
                        strokeWidth="1.4"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  )}
                </div>

                <div
                  className="gridViewOfLearningPaths"
                  onClick={() => {
                    setLpGridView(true);
                  }}
                >
                  {lpGridView ? (
                    <svg
                      className="lpGridView"
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.33333 1.5H1.5V7.33333H7.33333V1.5Z"
                        stroke="#101828"
                        strokeWidth="1.4"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M16.5 1.5H10.6667V7.33333H16.5V1.5Z"
                        stroke="#101828"
                        strokeWidth="1.4"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M16.5 10.6667H10.6667V16.5H16.5V10.6667Z"
                        stroke="#101828"
                        strokeWidth="1.4"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M7.33333 10.6667H1.5V16.5H7.33333V10.6667Z"
                        stroke="#101828"
                        strokeWidth="1.4"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  ) : (
                    <svg
                      className="disableLpGridView"
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.33333 1.5H1.5V7.33333H7.33333V1.5Z"
                        stroke="#667085"
                        strokeWidth="1.4"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M16.5 1.5H10.6667V7.33333H16.5V1.5Z"
                        stroke="#667085"
                        strokeWidth="1.4"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M16.5 10.6667H10.6667V16.5H16.5V10.6667Z"
                        stroke="#667085"
                        strokeWidth="1.4"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M7.33333 10.6667H1.5V16.5H7.33333V10.6667Z"
                        stroke="#667085"
                        strokeWidth="1.4"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  )}
                </div>
                {/* <FontAwesomeIcon
                onClick={() => {
                  setLpGridView(true);
                }}
                className="batchesGridView"
                icon={faTh}
              /> */}
              </div>
              {/* <div style={{ display: "flex" }}>
              <FontAwesomeIcon icon={faSearch} className="adminLpSearchIcon" />
              <input
                type="text"
                name="search"
                id="adminLpSearch"
                placeholder="Search"
                onChange={handleSearchLp}
                className="adminLpSearchBox"
              />
            </div> */}
              {/* <Button
              variant="primary"
              id="adminAddLpBtn"
              onClick={() => setIsAddLp(true)}
            >
              Add Learning Path
            </Button> */}
              <div className="archiveLpSwitchDiv">
                {/* <Form.Check
                type="switch"
                name="archiveStatus"
                checked={showArchivedLps}
                id="archiveBatchSwitchlps"
                style={{ boxShadow: "none" }}
                onChange={(e) => setShowArchivedLps(!showArchivedLps)}
              />
              View Archive */}
                <ToggleButton
                  size="sm"
                  text="View Archive"
                  supportingText=""
                  pressed={showArchivedLps}
                  theme="Dark"
                  disabled={false}
                  onChange={(e) => setShowArchivedLps(!showArchivedLps)}
                />
              </div>
              <div id="addBatch">
                <Button
                  size="sm"
                  hierarchy={{
                    type: "secondaryGrey",
                    buttonText: "New Path",
                  }}
                  icon={{
                    type: "leading",
                    icon: (
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10.0013 4.16699V15.8337M4.16797 10.0003H15.8346"
                          stroke="#344054"
                          strokeWidth="1.67"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    ),
                  }}
                  onClick={() => setIsAddLp(true)}
                />
              </div>
            </div>
          </div>

          {lpGridView ? (
            <div id="adminLpCardContainer">
              {filterLP.map((lp) => (
                <PathCard key={lp.id} lp={lp} onClick={handleSpecificLp} />
              ))}
            </div>
          ) : (
            <div className="adminLpTableContainer">
              <LpListView
                data={filterLP}
                showArchivedLps={showArchivedLps}
                handleSpecificLp={handleSpecificLp}
                from="lp"
              />
            </div>
          )}
        </>
      ) : isOldLpDetail || isAddLp ? (
        <NewLearningPath
          msg={msg}
          show={show}
          title={title}
          setMsg={setMsg}
          //lpData={lpData2}
          setShow={setShow}
          isUnAuth={isUnAuth}
          setTitle={setTitle}
          isLoaded={isLoaded}
          setLpData={setLpData2}
          setIsAddLp={setIsAddLp}
          setIsLoaded={setIsLoaded}
          setIsUnAuth={setIsUnAuth}
          setIsFromLp={setIsFromLp}
          quereport={Props.quereport}
          isFromBatch={isFromBatch}
          isOldLpDetail={isOldLpDetail}
          isAddLpModule={isAddLpModule}
          moduleExpandKey={moduleExpandKey}
          setIsAddLpModule={setIsAddLpModule}
          setIsOldLpDetail={setIsOldLpDetail}
          handleUnAuthAlert={handleUnAuthAlert}
          setisqueReport={Props.setisqueReport}
          setModuleExpandKey={setModuleExpandKey}
          setTableShowStatus={Props.setTableShowStatus}
        />
      ) : (
        <Modules
          msg={msg}
          show={show}
          title={title}
          lpData={lpData2}
          setMsg={setMsg}
          setShow={setShow}
          setTitle={setTitle}
          isLoaded={isLoaded}
          isUnAuth={isUnAuth}
          setLpData={setLpData2}
          setIsAddLp={setIsAddLp}
          setIsLoaded={setIsLoaded}
          setIsFromLp={setIsFromLp}
          setIsUnAuth={setIsUnAuth}
          quereport={Props.quereport}
          isOldLpDetail={isOldLpDetail}
          moduleExpandKey={moduleExpandKey}
          setIsOldLpDetail={setIsOldLpDetail}
          setIsAddLpModule={setIsAddLpModule}
          setisqueReport={Props.setisqueReport}
          setModuleExpandKey={setModuleExpandKey}
          setTableShowStatus={Props.setTableShowStatus}
        />
      )}
      {isLoaded && <Loading />}
      <Notify
        show={show}
        title={title}
        message={msg}
        onHide={isUnAuth ? handleUnAuthAlert : handleClose}
      />
    </div>
  );
};

export default AdminLearningPath;
