import * as XLSX from 'xlsx';
function ExportToCsv(Props) {
    const downloadDataAsCSV = (data, filename) => {
        const convertArrayOfObjectsToCSV = (data) => {
            const header = Object.keys(data[0]).join(',') + '\n';
    
            const rows = data.map((row) => {
                return Object.values(row).join(',');
            }).join('\n');
    
            return header + rows;
        };
    
        const downloadCSV = (csv, filename) => {
            const csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
            const csvURL = window.URL.createObjectURL(csvData);
            const tempLink = document.createElement('a');
            tempLink.href = csvURL;
            tempLink.setAttribute('download', filename);
            tempLink.click();
        };
    
   
        const csv = convertArrayOfObjectsToCSV(data);
    
     
        downloadCSV(csv, filename);
    };


    const downloadExcel = (data,fileName) => {
        const workbook = XLSX.utils.book_new();
    
        data.forEach((sheetData, index) => {
          if (sheetData.length > 0) {
            // Get headers from the first object in the array
            const headers = Object.keys(sheetData[0]);
    
            // Create worksheet data with headers
            const wsData = [
              headers,
              ...sheetData.map(obj => headers.map(header => obj[header]))
            ];
    
            const worksheet = XLSX.utils.aoa_to_sheet(wsData);
            XLSX.utils.book_append_sheet(workbook, worksheet, `Sheet${index + 1}`);
          }
        });
    
        XLSX.writeFile(workbook, `${fileName}.xlsx`);
      };
    
   
    
    
  return {
    downloadDataAsCSV,downloadExcel

  }
}

export default ExportToCsv