import { nanoid } from "nanoid";
import { useContext } from "react";
import constants from "../constants";
import useAxiosPrivate from "./useAxiosPrivate";
import StudentListHooks from "./StudentListHooks";
import AlertContext from "../context/AlertContext";
import AssignmentPageHooks from "./AssignmentPageHooks";
import useCommonFunctionHooks from "./useCommonFunctionHooks";

export default function BreadCrumbsHooks() {
  const {
    setMsg,
    setShow,
    filccld,
    setTitle,
    setStatus,
    setIsLoaded,
    testCasesRef,
    breadCrumbsData,
    selectedQuestion,
    showBreadCrumbsData,
    assignQuestionToLearner,
    batchLearnerListForEvaluation,
  } = useContext(AlertContext);

  const axios = useAxiosPrivate();
  const { getStudentList } = StudentListHooks();
  const { handleNavContentOrAssignment } = AssignmentPageHooks();

  const { getCatchBlockDetails, getHeaderDetailsForAxios } =
    useCommonFunctionHooks();

  const getCurrentQuestionIdForCurrentLearner = () => {
    if (showBreadCrumbsData.type === constants.libTypeIds.CODING_CHALLENGE) {
      breadCrumbsData.modules.map(
        (modules) =>
          modules.mid === showBreadCrumbsData.moduleId &&
          modules.testdata.map(
            (test) =>
              test.id === showBreadCrumbsData.testId &&
              test.testdata.map(
                (topic) =>
                  topic.topicid === showBreadCrumbsData.topicId &&
                  topic.hasOwnProperty("qndata") &&
                  topic?.qndata.filter(
                    (question) =>
                      question.testqnno === showBreadCrumbsData.questionNo &&
                      ((showBreadCrumbsData.questionId = question.questionid),
                      getCurrentPageDetails(showBreadCrumbsData))
                  )
              )
          )
      );
    }
  };

  const getUrl = (data) => {
    if (data.type === constants.libTypeIds.CODING_CHALLENGE) {
      return "node/learner/report/question";
    } else {
      return "node/learner/course/assignment/report";
    }
  };

  const getPayLoadForCurrentPageDetails = (data) => {
    if (data.type === constants.libTypeIds.CODING_CHALLENGE) {
      return JSON.stringify({
        lpid: data.lpId,
        testid: data.testId,
        testqnno: data.questionNo,
        learnerid: data.learnerId,
        questionid: data.questionId,
      });
    } else {
      return JSON.stringify({
        lpid: data.lpId,
        libid: data.testId,
        learnerid: data.learnerId,
      });
    }
  };

  const getCurrentPageDetailsForCC = (res, data) => {
    let response = res.data.data;
    let testCases =
      response.testcases !== null ? JSON.parse(response.testcases) : [];

    for (let i = 0; i < testCases.length; i++) {
      if (i === 0) testCases[i].isActive = true;
      testCases[i].isSystemTestCase = true;
      testCases[i].id = nanoid();
    }

    testCasesRef.current = testCases;
    response.status = data.status;
    assignQuestionToLearner(response);
    selectedQuestion(
      res.data,
      { secname: data.moduleName },
      {
        topicid: data.topicId,
        topicname: data.topicName,
        // totalcount: currentLearner.questionscount,
      },
      data.learnerId,
      data.status,
      data.srudentName,
      data.questionNo,
      data.lpid,
      data.questionNo,
      batchLearnerListForEvaluation,
      true,
      data.type,
      response.lpconfigid,
      filccld.current,
      null,
      data
    );
    setStatus(data.status);
  };

  const getCurrentPageDetails = (data) => {
    getStudentList(data);

    setIsLoaded(true);
    axios
      .post(
        getUrl(data),
        getPayLoadForCurrentPageDetails(data),
        getHeaderDetailsForAxios()
      )
      .then((res) => {
        if (res.data.resultCode !== constants.RESULT_STATUS.SUCCESS) {
          setShow(true);
          setTitle("Info");
          setMsg(res.data.msg);
        } else {
          if (data.type === constants.libTypeIds.CODING_CHALLENGE) {
            getCurrentPageDetailsForCC(res, data);
          } else {
            handleNavContentOrAssignment(
              res.data.data,
              data.lpId,
              data.type,
              data.lpConfigId,
              data.testId,
              data.status,
              [],
              true,
              data.learnerId,
              data
            );
          }
        }
      })
      .catch((error) => {
        getCatchBlockDetails(error);
      })
      .finally(() => setIsLoaded(false));
  };

  return {
    getCurrentPageDetails,
    getCurrentQuestionIdForCurrentLearner,
  };
}
