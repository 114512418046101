import Button from "../../Button";
import Score from "../../Score/Score";
import constants from "../../../constants";
import ClockSvg from "../../../Svg/ClockSvg";
import { useLocation } from "react-router-dom";
import React, { useContext, useEffect } from "react";
import AlertContext from "../../../context/AlertContext";
import CommentsHooks from "../../../Hooks/CommentsHooks";
import CodingHeaderLearnerSideHooks from "../../../Hooks/CodingPage/CodingHeaderLearnerSideHooks";

const { electronAPI } = window;

export default function CodingHeaderLearnerSide() {
  const Alert = useContext(AlertContext);

  const { toggleDrawer } = CommentsHooks();
  const CodingHeaderLearnerSideHks = CodingHeaderLearnerSideHooks();

  const { pathname } = useLocation();

  const handleScreenChange = (event, screenStatus) => {
    if (screenStatus.type === "added") {
      Alert.setExternalDevices(true);
      Alert.setShowNotify({
        show: true,
        title: "Warning",
        msg: "You are connecting to external display!",
      });
    } else if (screenStatus.type === "removed") {
      Alert.setExternalDevices(false);
      Alert.setShowNotify({
        show: true,
        title: "Warning",
        msg: "You are disconnecting from external display!",
      });
    }
  };

  useEffect(() => {
    // Listen for the 'screen-changed' event and call handleScreenChange when it fires
    electronAPI?.onScreenChanged(handleScreenChange);

    // Cleanup the event listener on component unmount
    return () => {
      electronAPI?.offScreenChanged(handleScreenChange);
    };
  }, []); // No dependencies, runs once to set up listener

  useEffect(() => {
    if (
      !Alert.isAdmin &&
      !Alert.isLoaded &&
      pathname !== "/mcq" &&
      !Alert.showConfirmNotify &&
      (!Alert.showNotify.show || Alert.showNotify.timer) &&
      Alert.testData.testmode === constants.MODE.TEST_MODE &&
      Alert.status !== constants.QUESTION_STATUS.SUBMITTED &&
      Alert.resultCode !== constants.RESULT_STATUS.NETWORK_ISSUE &&
      Alert.status !== constants.QUESTION_STATUS.TO_BE_EVALUATED
    ) {
      const handleTimeLimit = setInterval(() => {
        if (Alert.timeLimit === 0) {
          CodingHeaderLearnerSideHks.handleSubmitLearnerCodeEvaluation(
            true,
            false,
            true,
            false
          );
          clearInterval(handleTimeLimit);
        } else {
          Alert.setTimeLimit((seconds) => seconds - 1);

          if (Alert.timeLimit !== 0 && Alert.timeLimit % 30 === 0) {
            CodingHeaderLearnerSideHks.saveCurrentAnswer();
          }
        }
      }, 1000);

      return () => {
        clearInterval(handleTimeLimit);
      };
    }

    if (
      !Alert.isAdmin &&
      !Alert.isLoaded &&
      !Alert.showNotify.show &&
      Alert.learnerCode !== "" &&
      !Alert.showConfirmNotify &&
      pathname !== "/mcq" &&
      Alert.status !== constants.QUESTION_STATUS.SUBMITTED &&
      Alert.testData.testmode === constants.MODE.PRACTICE_MODE &&
      Alert.resultCode !== constants.RESULT_STATUS.NETWORK_ISSUE &&
      Alert.status !== constants.QUESTION_STATUS.TO_BE_EVALUATED
    ) {
      if (Alert.count === 0) {
        const handleTimeLimit = setInterval(() => {
          Alert.setCount(1);
          CodingHeaderLearnerSideHks.saveCurrentAnswer();
        }, 1000);

        return () => {
          clearInterval(handleTimeLimit);
        };
      } else {
        const handleTimeLimit = setInterval(() => {
          CodingHeaderLearnerSideHks.saveCurrentAnswer();
        }, 30000);

        return () => {
          clearInterval(handleTimeLimit);
        };
      }
    }
  }, [
    Alert.count,
    Alert.status,
    Alert.isAdmin,
    Alert.isLoaded,
    Alert.timeLimit,
    Alert.resultCode,
    Alert.showNotify,
    Alert.learnerCode,
    Alert.showConfirmNotify,
  ]);

  /* mcq and desc */

  const onBlur = () => {
    if (!CodingHeaderLearnerSideHks.hasSubmitted) {
      CodingHeaderLearnerSideHks.switchCount.current =
        CodingHeaderLearnerSideHks.switchCount.current + 1;
      CodingHeaderLearnerSideHks.timeoutId.current = setInterval(() => {
        let tempSec = CodingHeaderLearnerSideHks.focusOutSec.current;

        if (
          tempSec === 10 ||
          CodingHeaderLearnerSideHks.switchCount.current === 10
        ) {
          clearInterval(CodingHeaderLearnerSideHks.timeoutId.current);
          CodingHeaderLearnerSideHks.submitAnswers(true, false);
        } else {
          tempSec++;
          CodingHeaderLearnerSideHks.focusOutSec.current = tempSec;
        }
      }, 1000);
    }
  };

  const onFocus = () => {
    CodingHeaderLearnerSideHks.focusOutSec.current = 0;
    clearInterval(CodingHeaderLearnerSideHks.timeoutId.current);
    window.removeEventListener("blur", onBlur);
  };

  useEffect(() => {
    if (
      !Alert.isAdmin &&
      parseInt(Alert.mcqAndDesTestAndLpId.libtypeid) !==
      constants.libTypeIds.CODING_CHALLENGE &&
      (Alert.status === constants.PROGRESS_STATUS.UNLOCKED ||
        Alert.status === constants.PROGRESS_STATUS.REATTEMPT ||
        Alert.status === constants.PROGRESS_STATUS.QN_CONTINUE) &&
      Alert.mcqAndDesTestAndLpId.testmode === constants.MODE.TEST_MODE
    ) {
      window.addEventListener("blur", onBlur);
      window.addEventListener("focus", onFocus);

      return () => {
        window.removeEventListener("blur", onBlur);
        window.removeEventListener("focus", onFocus);
      };
    }
  }, [Alert.status, onBlur]);

  useEffect(() => {
    if (
      !Alert.isAdmin &&
      parseInt(Alert.mcqAndDesTestAndLpId.libtypeid) !==
      constants.libTypeIds.CODING_CHALLENGE &&
      (Alert.status === constants.PROGRESS_STATUS.UNLOCKED ||
        Alert.status === constants.PROGRESS_STATUS.REATTEMPT ||
        Alert.status === constants.PROGRESS_STATUS.QN_CONTINUE) &&
      Alert.mcqAndDesTestAndLpId.testmode === constants.MODE.TEST_MODE
    ) {
      if (!document.fullscreenElement) {
        if (Alert.comingObj.videoqn !== 1) {
          Alert.setIsLoaded(true);
          CodingHeaderLearnerSideHks.submitAnswers(true, false);
        } else {
          Alert.setIsLoaded(true);
          Alert.exitVdoSubmit.current = true;
        }
      }
    }
  }, [Alert.status, document.fullscreenElement]);

  useEffect(() => {
    if (
      !Alert.isAdmin &&
      (Alert.status === constants.PROGRESS_STATUS.UNLOCKED ||
        Alert.status === constants.PROGRESS_STATUS.REATTEMPT ||
        Alert.status === constants.PROGRESS_STATUS.QN_CONTINUE) &&
      parseInt(Alert.mcqAndDesTestAndLpId.libtypeid) !==
      constants.libTypeIds.CODING_CHALLENGE &&
      Alert.mcqAndDesTestAndLpId.testmode === constants.MODE.TEST_MODE
    ) {

      if (!CodingHeaderLearnerSideHks.hasSubmitted) {
        Alert.interval.current = setInterval(() => {
          if (Alert.duration.current === 0) {
            if (Alert.comingObj.videoqn !== 1) {
              CodingHeaderLearnerSideHks.submitAnswers(false, true);
            } else {
              Alert.setIsLoaded(true);
              Alert.exitVdoSubmit.current = true;
              Alert.timeVdoSubmit.current = true;
            }
            clearInterval(Alert.interval.current);
          } else {
            Alert.duration.current = Alert.duration.current - 1;
            if (
              Alert.duration.current !== 0 &&
              Alert.duration.current % 30 === 0 &&
              Alert.comingObj.videoqn !== 1
            ) {
              CodingHeaderLearnerSideHks.saveCurrentAnswer();
            }

            CodingHeaderLearnerSideHks.setTimer(Alert.duration.current);
          }
        }, 1000);

        return () => {
          clearInterval(Alert.interval.current);
        };
      }
    }

    if (
      Alert.isAdmin &&
      (Alert.status === constants.PROGRESS_STATUS.UNLOCKED ||
        Alert.status === constants.PROGRESS_STATUS.REATTEMPT ||
        Alert.status === constants.PROGRESS_STATUS.QN_CONTINUE) &&
      Alert.mcqAndDesTestAndLpId.testmode !== constants.MODE.TEST_MODE &&
      Alert.comingObj.videoqn !== 1
    ) {
      if (Alert.count === 0) {
        const handleTimeLimit = setInterval(() => {
          Alert.setCount(1);
          CodingHeaderLearnerSideHks.saveCurrentAnswer();
        }, 1000);

        return () => {
          clearInterval(handleTimeLimit);
        };
      } else {
        const handleTimeLimit2 = setInterval(() => {
          CodingHeaderLearnerSideHks.saveCurrentAnswer();
        }, 30000);

        return () => {
          clearInterval(handleTimeLimit2);
        };
      }
    }
  }, [CodingHeaderLearnerSideHks.timer, Alert.count]);

  return (
    <div className="codingHeaderLearnerSideMainContainer">
      {CodingHeaderLearnerSideHks.handleShowSubmitDiv() ? (
        <div className="codingHeaderLearnerSideSubmitDiv">
          {Alert.testData.testmode === constants.MODE.TEST_MODE && (
            <div className="codingHeaderLearnerSideTimerDiv">
              <ClockSvg />
              {pathname !== "/mcq" ? (
                <div className="codingHeaderLearnerSideTimerInnerDiv">
                  <span>
                    {Math.floor((Alert.timeLimit % 3600) / 60).toString()
                      .length === 1
                      ? "0" + Math.floor((Alert.timeLimit % 3600) / 60)
                      : Math.floor((Alert.timeLimit % 3600) / 60)}
                  </span>
                  {" : "}
                  <span>
                    {Math.floor((Alert.timeLimit % 3600) % 60).toString()
                      .length === 1
                      ? "0" + Math.floor((Alert.timeLimit % 3600) % 60)
                      : Math.floor((Alert.timeLimit % 3600) % 60)}
                  </span>
                </div>
              ) : (
                <div className="codingHeaderLearnerSideTimerInnerDiv">
                  {Alert.comingObj.videoqn === 1 && <span>Total time: </span>}
                  <span>
                    {Math.floor(
                      CodingHeaderLearnerSideHks.timer / 3600
                    ).toString().length === 1
                      ? "0" +
                      Math.floor(CodingHeaderLearnerSideHks.timer / 3600)
                      : Math.floor(CodingHeaderLearnerSideHks.timer / 3600)}
                  </span>
                  {" : "}
                  <span>
                    {Math.floor(
                      (CodingHeaderLearnerSideHks.timer % 3600) / 60
                    ).toString().length === 1
                      ? "0" +
                      Math.floor(
                        (CodingHeaderLearnerSideHks.timer % 3600) / 60
                      )
                      : Math.floor(
                        (CodingHeaderLearnerSideHks.timer % 3600) / 60
                      )}
                  </span>
                  {" : "}
                  <span>
                    {Math.floor(
                      (CodingHeaderLearnerSideHks.timer % 3600) % 60
                    ).toString().length === 1
                      ? "0" +
                      Math.floor(
                        (CodingHeaderLearnerSideHks.timer % 3600) % 60
                      )
                      : Math.floor(
                        (CodingHeaderLearnerSideHks.timer % 3600) % 60
                      )}
                  </span>
                </div>
              )}
            </div>
          )}
          {(Alert.hideSubmitVideoQn || pathname !== "/mcq") && (
            <div className="header-learner-submit-btn-div">
              <Button
                size={"sm"}
                onClick={() => {
                  pathname !== "/mcq"
                    ? CodingHeaderLearnerSideHks.handleSubmitLearnerCodeEvaluation(
                      false,
                      false,
                      false,
                      false
                    )
                    : CodingHeaderLearnerSideHks.submitAnswers();
                }}
                hierarchy={{
                  type: "primary",
                  buttonText: "Submit",
                }}
              />
            </div>
          )}
        </div>
      ) : (
        CodingHeaderLearnerSideHks.handleShowCommentsButton() && (
          <div className="codingHeaderLearnerSideSubmissionDiv">
            {CodingHeaderLearnerSideHks.handleShowScore() && <Score />}
            <Button
              size={"sm"}
              onClick={toggleDrawer("right", true)}
              hierarchy={{
                type: "secondaryGrey",
                buttonText: "View Comments",
              }}
            />
          </div>
        )
      )}
    </div>
  );
}
