// import * as React from 'react';
import { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DatePicker from "react-datepicker";
import useAxiosPrivate from "../../../../Hooks/useAxiosPrivate";
import AlertContext from "../../../../context/AlertContext";
import "./Exdialogstyle.scss";
import Loading from "../../../Notification-Loading/Loading/Loading";
import ModuleHooks from "../../../../Hooks/ModuleHooks";
import CommonBtn from "../../../CommonComponents/CommonBtn";

const emails = ["username@gmail.com", "user02@gmail.com"];

export default function Extdialog(props) {
  const {
    setMsg,
    setShow,
    setTitle,
    isLoading,
    setIsLoaded,
    setIsUnAuth,
    setLpId,
  } = useContext(AlertContext);
  const { isClearedState, commonPopupBodyComponent, setCommonPopupBodyComponent, setIsClearedState, handlePopupStates, clearPopupStates, isConfirmClearedState,setIsConfirmClearedState } = ModuleHooks();
  const axios = useAxiosPrivate();
  //   const { onClose, selectedValue, open } = props;
  const { onClose, open } = props;
  const handleClose = () => {
    // props.setextensiondate(null);
    // props.setdateshow(false);
    // setIsLoaded(false);
    clearPopupStates();
  };

  const handleExtension = () => {
    let apiurl, apidata, successmsg;

    const format2 = "YYYY-MM-DD";
    apiurl = "node/admin/duedate/extd";
    apidata = {
      libtypeid: props.cellobj.libtype,
      learnerid: props.cellobj.learnerid,
      testid: props.cellobj.testid,
      exdate: props.extensiondate.toString(),
      lpconfigid: props.cellobj.lpconfigid,
      // questionid: assignQnDetailsForLearner.qnid,
      reset: false,
    };
    successmsg = "Resubmission request successfull!";

    // setIsLoaded(true);
    setIsLoaded(true);
    axios
      .post(apiurl, JSON.stringify(apidata), {
        headers: {
          "Content-type": "application/json",
        },
      })
      .then((res) => {
        if (res.data.resultCode === 1000) {
          // setshowapprove(false);
          // setshowreject(false);
          // if (ccomm !== "") {
          // addComment("Resubmit");
          // } else {
          setIsLoaded(true);

          handleClose();
          setShow(true);
          setTitle("Info ");
          setMsg(res.data.msg);
          setIsLoaded(false);
          // props.navigate("/report");
          // props.tablerendered=false;
          // props.changetablerendered(false);
          // props.handleReport(props.lpid,"extend");
          // props.settableupdated(true);
          props.updateDataAfterExtDate();
          // }
        } else {
          handleClose();
          setShow(true);
          setTitle("Info ");
          setMsg("Something went wrong. Please, try again");
          // setshowapprove(false);
          // setshowreject(false);
        }
      })
      .catch(function (error) {
        if (error.message.includes("403")) {
          handleClose();
          setShow(true);
          setMsg("You have been logged-out due to inactivity. Login again.");
          setIsUnAuth(true);
        } else {
          setShow(true);
          setMsg(error.message);
        }
      })
      .finally(() => setIsLoaded(false));
  };

  return (
    <div className="extDialogContainer">
    {/* // <Dialog onClose={handleClose} open={props.dateshow}>
    //   <DialogTitle>Set new deadline </DialogTitle> */}
      <div>
        {/* <div className="extdialog-details"> */}
        <table>
          {/* <div className="extdialog-details-row"> */}
          <tr>
            <td className="extdialog-details-row-label">Learner:</td>{" "}
            <td className="extdialog-details-row-value">
              {" "}
              &nbsp;{props.cellobj.lname}
            </td>
            {/* </div> */}
          </tr>
          {/* <div className="extdialog-details-row"> */}
          <tr>
            <td className="extdialog-details-row-label">Test:</td>{" "}
            <td className="extdialog-details-row-value">
              {" "}
              &nbsp;{props.cellobj.testname}
            </td>
          </tr>
          {/* </div> */}
          {/* <div className="extdialog-details-row"> */}
          <tr>
            <td className="extdialog-details-row-label">Test Due Date: </td>{" "}
            <td className="extdialog-details-row-value">
              {" "}
              &nbsp;
              {moment(props.cellobj.duedate).format("MMMM Do YYYY, h:mm a")}
            </td>
          </tr>
          {/* <tr>
        <td className="extdialog-details-row-label">Learner Due Date: </td> <td className="extdialog-details-row-value"> &nbsp;{moment(props.cellobj.learnerduedate==null?new Date():props.cellobj.learnerduedate, "YYYY.MM.DD").format('MMMM Do YYYY, h:mm a')}</td>

        </tr> */}
          {/* </div> */}
        </table>
        {/* </div> */}
        </div>
      {/* <br /> */}
      <>
      <div className="extdialog-datepicker-div" /* style={{margin:"10px 0 0 0"}} */>
        <h6>New Deadline:&nbsp; </h6> {/* </span> <span> */}{" "}
        <DatePicker
          showIcon
          selected={new Date(props.extensiondate)}
          className="codingDatePicker"
          // onChange={(date) => props.setextensiondate(date)}
          showTimeInput
          timeInputLabel="Time:"
          dateFormat="dd/MMM/yyyy h:mm aa"
          onChange={(date) =>
            props.setextensiondate(moment(date).format("YYYY-MM-DD HH:mm:ss"))
          }
          minDate={moment().toDate()}
        />
      </div>

      <div className="comments-score-section-score-reject-box-score-button-section-exdialog">
        {/* <button
          className="comments-score-section-score-reject-box-score-button-section-cancel-button-exdialog"
          onClick={() => {
            handleClose();
          }}
        >
          Cancel
        </button>
        <button
          className={
            props.extensiondate == null
              ? "comments-score-section-score-reject-box-score-button-section-reject-button-exdialog-disabled"
              : "comments-score-section-score-reject-box-score-button-section-reject-button-exdialog"
          }
          onClick={() => handleExtension()}
          disabled={props.extensiondate == null}
        >
          Extend
        </button> */}

        <CommonBtn                         
            btnsize = {"small"} 
            primbtntxt={"Extend"}
            secbtntxt={"Cancel"}
            primbtnfunc={handleExtension}
            secbtnfunc={handleClose} 
          />
      </div>
      </>
    {/* //   {isLoading && <Loading />}
    // </Dialog> */}
    </div>
  );
}
