import React from 'react'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';

export default function Datepicker(props) {
  return (
    <div>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              minDate={dayjs()}
              format="DD-MM-YYYY"
              value={props.selectedDate}
              onChange={(newValue) => props.setSelectedDate(newValue)}
            />
        </LocalizationProvider>
    </div>
  )
}
