import React, { useEffect, useState, useRef, useContext } from "react";
import { Table } from "antd";
import { DndProvider } from "react-dnd";
import { default as ReactSelect } from "react-select";
import { HTML5Backend } from "react-dnd-html5-backend";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faXmark } from "@fortawesome/free-solid-svg-icons";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { nanoid } from "nanoid";
import { useNavigate } from "react-router-dom";
import QuestionList from "./QuestionList";
import { Fragment } from "react";
import AlertContext from "../../../context/AlertContext";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import Tooltip from "antd/es/tooltip";
import { MultiSelect } from "react-multi-select-component";
import PreviewIcon from "@mui/icons-material/Preview";
import useAxiosPrivate from "../../../Hooks/useAxiosPrivate";

const constants = require("../../../constants");

const customStylesForTopic = {
  control: (base, state) => ({
    ...base,
    border: "none",
    height: "38px",
    paddingLeft: "7px",
    "&:hover": {
      border: state.isFocused ? "1px solid #BDC1C6" : "none",
      outline: "none",
    },
    backgroundColor: "#ffffff",
    cursor: state.isDisabled ? "default" : "pointer",
    overflow: "auto",
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      textAlign: "left",
      backgroundColor: isFocused ? "#e9ecef" : undefined,
      color: isSelected ? "#000000" : undefined,
      height: "30px",
      margin: "0px",
      fontFamily: "Inter",
      fontWeight: "600",
      fontSize: "12px",
      lineHeight: "15px",
    };
  },
  singleValue: (styles, { data }) => ({
    ...styles,
    color: "#202124",
    borderRadius: "20px",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "20px",
    textAlign: "center",
  }),
  menuList: (base) => ({
    ...base,
    maxHeight: "150px",
  }),
};

const customStylesForLevel = {
  control: (base, state) => ({
    ...base,
    border: "none",
    height: "38px",
    paddingLeft: "7px",
    "&:hover": {
      border: state.isFocused ? "1px solid #BDC1C6" : "none",
      outline: "none",
    },
    backgroundColor: "#ffffff",
    cursor: state.isDisabled ? "default" : "pointer",
    overflow: "auto",
    textAlign: "center",
    justifyContent: "center",
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      textAlign: "left",
      backgroundColor: isFocused ? "#e9ecef" : undefined,
      color: isSelected ? "#000000" : undefined,
      height: "30px",
      margin: "0px",
      fontFamily: "Inter",
      fontWeight: "600",
      fontSize: "12px",
      lineHeight: "15px",
    };
  },
  singleValue: (styles, { data }) => ({
    ...styles,
    backgroundColor:
      data.label.toLowerCase() === "easy"
        ? "#04B462"
        : data.label.toLowerCase() === "medium"
        ? "#2B75E4"
        : data.value === 6 || data.value === 7
        ? "#FFFFFF"
        : "#D9435F",
    color: data.value === 6 || data.value === 7 ? "#000000" : "#FFFFFF",
    borderRadius: "20px",
    width:
      data.label.toLowerCase() === "easy"
        ? "53px"
        : data.label.toLowerCase() === "medium"
        ? "72px"
        : "70px",
    marginLeft: "50px",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "12px",
    lineHeight: "20px",
    textAlign: "center",
    justifyContent: "center",
  }),
  placeholder(base, state) {
    return {
      ...base,
      display: state.isDisabled && "none",
    };
  },
  menuList: (base) => ({
    ...base,
    maxHeight: "150px",
  }),
};

const TestConfig = (props) => {
  const {
    handleNavigateToLpAfterAddTest,
    handleEditedTestConfig,
    setFromMenuToEditTest,
    setOldTestConfig,
  } = useContext(AlertContext);
  const axios = useAxiosPrivate();
  const [testConfig, setTestConfig] = useState(props.testData.sections);
  const [specificField, setSpecificField] = useState(
    props.testData.testType.value === constants.libTypeIds.MCQ
      ? 1
      : props.testData.testType.value === constants.libTypeIds.DESCRIPTIVE
      ? 2
      : 3
  );
  const [topicExpandedKey, setTopicExpandedKey] = useState(
    testConfig.length > 0 && [testConfig[0].key]
  );
  const [successRes, setSuccessRes] = useState({ isSuccess: false, msg: "" });
  const [focus, setFocus] = useState(null);
  const [cursorPointer, setCursorPointer] = useState(0);
  const [topics, setTopics] = useState([]);
  const [complexity, setComplexity] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [showQnList, setShowQnList] = useState(false);
  const [listQnData, setListQnData] = useState({
    sectionId: "",
    secDataId: "",
    data: [],
  });

  /* For Warning */
  const [insufficientQuestions, setInsufficientQuestions] = useState({});
  const [isSufficientQuestions, setIsSufficientQuestions] = useState(true);

  const [tooltipData, setTooltipData] = useState(null);

  // State to manage the menu's open/closed state for each MultiSelect
  const [menuOpenState, setMenuOpenState] = useState([]);

  const [selectedQns, setSelectedQns] = useState([]);

  const multiSelectRefs = useRef(testConfig.map(() => React.createRef()));

  const navigate = useNavigate();

  const handleSuccessResponse = () => {
    if (
      (props.oldTestConfig.isOldTestConfig ||
        !props.oldTestConfig.isOldTestConfig) &&
      props.fromMenu
    ) {
      props.setIsAddTest(false);
      setFromMenuToEditTest(true);
      setOldTestConfig({
        isOldTestConfig: false,
        oldTestData: {},
      });
    } else if (props.oldTestConfig.isOldTestConfig) {
      let lpData = JSON.parse(JSON.stringify(props.lpData));
      for (let i = 0; i < lpData.modules.length; i++) {
        for (let j = 0; j < lpData.modules[i].moduleData.length; j++) {
          if (
            props.testData.testId === lpData.modules[i].moduleData[j].testId
          ) {
            lpData.modules[i].moduleData[j].title = props.testData.testName;
          }
        }
      }
      handleEditedTestConfig(lpData);
    } else {
      let newDetails = JSON.parse(JSON.stringify(props.lpData)),
        uniqueId = 1,
        testData = {
          id: successRes.testId,
          title: props.testData.testName,
          isEdited: false,
          libTypeId: props.lpData.libTypeId,
          published: 0,
        };
      testData.status = "new";
      testData.testStatus = "old";

      for (let i = 0; i < newDetails.modules.length; i++) {
        if (newDetails.modules[i].id === newDetails.moduleId) {
          newDetails.modules[i].moduleData.push(testData);
        }
        for (let j = 0; j < newDetails.modules[i].moduleData.length; j++) {
          newDetails.modules[i].moduleData[j].moduleDataId = uniqueId;
          newDetails.modules[i].moduleData[j].key = nanoid();
          uniqueId++;
        }

        newDetails.modules[i].id = uniqueId;
        uniqueId++;
      }
      newDetails.isAddTest = false;
      if (props.isFromBatch) newDetails.status = "update";
      handleNavigateToLpAfterAddTest(newDetails);
      // props.setIsAddTest(false);
      //  props.setIsOldLpDetail(false);
      // props.setIsFromBatch(false);
    }
    setSuccessRes({ isSuccess: false, msg: "" });
  };

  const handleCloseListPopup = () => {
    setShowQnList(false);
    setSelectedQns([]);
  };

  useEffect(() => {
    /* Topic Api */

    props.setIsLoaded(true);
    axios
      .get(
        process.env.REACT_APP_NODE_API +
          `node/admin/topic/get/${props.testData.testType.value}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        let responseData = JSON.stringify(response);
        responseData = JSON.parse(responseData).data;
        let topicOptions = [];
        for (let i = 0; i < responseData.length; i++) {
          let temp = {
            label: responseData[i].name,
            value: responseData[i].id,
          };
          topicOptions.push(temp);
        }
        setTopics(topicOptions);
      })
      .catch((err) => {
        props.setIsLoaded(false);
        if (err.message.includes("403")) {
          props.setShow(true);
          props.setIsUnAuth(true);
          props.setMsg(
            "You have been logged-out due to inactivity. Login again.."
          );
          props.setTitle("Error !");
        }
      });

    /* Complexity Api */

    axios
      .get("node/admin/complexity/get", {
        headers: { "Content-type": "application/json" },
      })
      .then((response) => {
        let responseData = JSON.stringify(response);
        responseData = JSON.parse(responseData).data;
        let complexityOptions = [];
        for (let i = 0; i < responseData.length; i++) {
          let temp = {
            label: responseData[i].name,
            value: responseData[i].id,
          };
          complexityOptions.push(temp);
        }
        setComplexity(complexityOptions);
      })
      .catch((err) => {
        props.setIsLoaded(false);
        if (err.message.includes("403")) {
          props.setShow(true);
          props.setIsUnAuth(true);
          props.setMsg(
            "You have been logged-out due to inactivity. Login again.."
          );
          props.setTitle("Error !");
        }
      });

    /* Language Api */

    axios
      .get("node/admin/language/get", {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        let responseData = JSON.stringify(response);
        responseData = JSON.parse(responseData).data;
        let languageOptions = [
          /* {label:'All',value:""} */
        ];
        for (let i = 0; i < responseData.length; i++) {
          let temp = {
            label: responseData[i].name,
            value: responseData[i].id,
          };
          languageOptions.push(temp);
        }
        setLanguages(languageOptions);
        props.setIsLoaded(false);
      })
      .catch((err) => {
        props.setIsLoaded(false);
        if (err.message.includes("403")) {
          props.setShow(true);
          props.setIsUnAuth(true);
          props.setMsg(
            "You have been logged-out due to inactivity. Login again.."
          );
          props.setTitle("Error !");
        }
      });
    setFocus(specificField !== 3 ? "1" : "2");
  }, []);

  const handleAddSection = () => {
    let section;
    if (specificField === 1) {
      section = {
        id: "",
        key: nanoid(),
        secName: "",
        secData: [
          {
            id: "",
            key: nanoid(),
            complexity: "",
            randomQnCount: "",
            fixedQnCount: "",
            marks: "",
          },
        ],
      };
    } else if (specificField === 2) {
      section = {
        id: "",
        key: nanoid(),
        secName: "",
        secData: [
          {
            id: "",
            key: nanoid(),
            complexity: "",
            randomQnCount: "",
            fixedQnCount: "",
            marks: "",
            desType: "",
          },
        ],
      };
    } else {
      section = {
        id: "",
        key: nanoid(),
        secName: "",
        secData: [
          {
            id: "",
            key: nanoid(),
            complexity: "",
            randomQnCount: "",
            fixedQnCount: "",
            marks: "",
            durationNum: "",
            durationUnit: "day/s",
            languages: "",
          },
        ],
      };
    }
    section.status = "new";
    section.testStatus = "new";
    section.isOld = false;
    section.secData[0].status = "new";
    section.secData[0].testStatus = "new";
    section.secData[0].isOld = false;
    if (props.oldTestConfig.isOldTestConfig) {
      section.type = "insert";
      section.secData[0].type = "insert";
    }
    let newDetails = [...testConfig],
      uniqueId = 1,
      focusId;
    newDetails.push(section);
    for (let i = 0; i < newDetails.length; i++) {
      newDetails[i].id = uniqueId;
      if (i === newDetails.length - 1) focusId = uniqueId;
      uniqueId++;
      for (let j = 0; j < newDetails[i].secData.length; j++) {
        newDetails[i].secData[j].id = uniqueId;
        let keysLength = 8;
        uniqueId += keysLength;
      }
    }
    setTestConfig(newDetails);
    setFocus(focusId);
  };

  const handleSectionName = (e, sectionId) => {
    setCursorPointer(e.target.selectionStart);
    setFocus(e.target.id);
    let newDetails = [...testConfig];
    setFocus(sectionId);
    for (let i = 0; i < newDetails.length; i++) {
      if (newDetails[i].id === sectionId) {
        newDetails[i][e.target.name] = e.target.value;
      }
    }
    setTestConfig(newDetails);
  };

  const handleDeleteSection = (sectionId) => {
    setTestConfig((testConfig) =>
      testConfig.filter((config) => config.id !== sectionId)
    );
  };

  const handleAddTopic = (data) => {
    let sectionData;
    if (specificField === 1) {
      sectionData = {
        id: "",
        key: nanoid(),
        complexity: "",
        randomQnCount: "",
        fixedQnCount: "",
        marks: "",
      };
    } else if (specificField === 2) {
      sectionData = {
        id: "",
        key: nanoid(),
        complexity: "",
        randomQnCount: "",
        fixedQnCount: "",
        marks: "",
        desType: "",
      };
      if (props.testData.videoQn) {
        delete sectionData.desType;
        sectionData.durationNum = "";
        sectionData.durationUnit = "day/s";
      }
    } else {
      sectionData = {
        id: "",
        key: nanoid(),
        complexity: "",
        randomQnCount: "",
        fixedQnCount: "",
        marks: "",
        durationNum: "",
        durationUnit: "day/s",
        languages: "",
        topic: "",
      };
    }
    sectionData.status = "new";
    sectionData.testStatus = "new";
    if (props.oldTestConfig.isOldTestConfig) sectionData.type = "insert";

    let newDetails = [...testConfig],
      uniqueId = 1;

    for (let i = 0; i < newDetails.length; i++) {
      newDetails[i].id = uniqueId;
      uniqueId++;
      if (data.id === newDetails[i].id) newDetails[i].secData.push(sectionData);
      for (let j = 0; j < newDetails[i].secData.length; j++) {
        newDetails[i].secData[j].id = uniqueId;
        if (
          data.id === newDetails[i].id &&
          j === newDetails[i].secData.length - 1
        )
          setFocus(uniqueId);
        let keysLength = 8;
        uniqueId += keysLength;
      }
    }
    setTestConfig(newDetails);
  };

  const handleGetQuestionList = async (
    topicid,
    complexityid,
    libtypeid,
    languages
  ) => {
    try {
      let response = await axios.post(
        "node/admin/question/getbytopicandcomplexity",
        JSON.stringify({
          topicid,
          complexityid,
          libtypeid: libtypeid,
          languages,
        }),
        { headers: { "Content-type": "application/json" } }
      );

      return response.data;
    } catch (error) {
      return error;
    }
  };

  const handleTopicConfigData = async (data, e, fieldName, sectionId) => {
    let newDetails = [...testConfig],
      regexForNum = /^[0-9]\d*$/;
    fieldName === "topic"
      ? setFocus(data.id)
      : fieldName === "desType"
      ? setFocus(data.id + 1)
      : fieldName === "complexity"
      ? setFocus(data.id + 2)
      : fieldName === "languages"
      ? setFocus(data.id + 3)
      : setFocus(e.target.id);

    if (
      fieldName === "complexity" ||
      fieldName === "languages" ||
      fieldName === "desType" ||
      fieldName === "topic"
    ) {
      for (let i = 0; i < newDetails.length; i++) {
        for (let j = 0; j < newDetails[i].secData.length; j++) {
          if (
            newDetails[i].id === sectionId &&
            newDetails[i].secData[j].id === data.id
          ) {
            /*  if(fieldName === "languages"&&e.length>0){
                            newDetails[i].secData[j][fieldName]=e;
                            let allOption=e.filter(lang=>lang.label==='All');
                            let otherOption=languages.filter(lang=>lang.label!=="All");
                            if(e.some(option=>option.label==='All'))newDetails[i].secData[j][fieldName]=languages;
                            else  if (!e.some((option) => option.label === 'All'))  newDetails[i].secData[j][fieldName]=e;
                        } 
                        else  */ newDetails[i].secData[j][fieldName] = e;
            if (
              props.oldTestConfig.isOldTestConfig &&
              newDetails[i].secData[j].testStatus === "old"
            )
              newDetails[i].secData[j].type = "update";
            else if (
              props.oldTestConfig.isOldTestConfig &&
              newDetails[i].secData[j].testStatus === "new"
            )
              newDetails[i].secData[j].type = "insert";
          }
        }
      }
    } else {
      setCursorPointer(e.target.selectionStart);
      let fieldValue;
      if (fieldName === "durationUnit") fieldValue = e.target.value;
      else {
        if (e.target.value !== "") {
          if (regexForNum.test(e.target.value))
            fieldValue = Number(e.target.value);
          else {
            props.setShow(true);
            props.setMsg("Please enter valid number");
            props.setTitle("Warning !");
            fieldValue = "";
          }
        }
      }

      for (let i = 0; i < newDetails.length; i++) {
        for (let j = 0; j < newDetails[i].secData.length; j++) {
          if (
            newDetails[i].id === sectionId &&
            newDetails[i].secData[j].id === data.id
          ) {
            newDetails[i].secData[j][fieldName] = fieldValue;
            if (
              props.oldTestConfig.isOldTestConfig &&
              newDetails[i].secData[j].testStatus === "old"
            )
              newDetails[i].secData[j].type = "update";
            else if (
              props.oldTestConfig.isOldTestConfig &&
              newDetails[i].secData[j].testStatus === "new"
            )
              newDetails[i].secData[j].type = "insert";
            let randomCount = 0,
              fixedCount = 0;
            if (regexForNum.test(newDetails[i].secData[j].fixedQnCount))
              fixedCount = parseInt(newDetails[i].secData[j].fixedQnCount);
            if (regexForNum.test(newDetails[i].secData[j].randomQnCount))
              randomCount = parseInt(newDetails[i].secData[j].randomQnCount);
            props.setQnsCount(randomCount + fixedCount);
            if (fieldName === "fixedQnCount") {
              if (newDetails[i].secData[j].topic === "") {
                props.setShow(true);
                props.setMsg("Please select topic ");
                props.setTitle("Warning !");
                newDetails[i].secData[j][fieldName] = "";
              } else if (newDetails[i].secData[j].complexity === "") {
                props.setShow(true);
                props.setMsg("Please select complexity");
                props.setTitle("Warning !");
                newDetails[i].secData[j][fieldName] = "";
              } else {
                if (
                  specificField === 2 &&
                  !props.testData.videoQn &&
                  newDetails[i].secData[j].desType === ""
                ) {
                  props.setShow(true);
                  props.setMsg("Please select descripitive type");
                  props.setTitle("Warning !");
                  newDetails[i].secData[j][fieldName] = "";
                } else if (
                  specificField === 3 &&
                  newDetails[i].secData[j].languages === ""
                ) {
                  props.setShow(true);
                  props.setMsg("Please select languages");
                  props.setTitle("Warning !");
                  newDetails[i].secData[j][fieldName] = "";
                } else {
                  if (
                    fieldValue !== "" &&
                    fieldValue !== undefined &&
                    fieldValue !== null &&
                    fieldValue !== 0
                  ) {
                    let libtypeid =
                      specificField === 2 && !props.testData.videoQn
                        ? newDetails[i].secData[j].desType.value
                        : props.testData.testType.value;
                    props.setIsLoaded(true);
                    try {
                      let result = await handleGetQuestionList(
                        newDetails[i].secData[j].topic.value,
                        newDetails[i].secData[j].complexity.value,
                        libtypeid,
                        newDetails[i].secData[j].languages
                      );
                      if (result.resultCode === 1000) {
                        setListQnData({
                          sectionId: sectionId,
                          secDataId: data.id,
                          data: result.data,
                        });
                        setShowQnList(true);
                      } else if (result.message.includes("403")) {
                        props.setShow(true);
                        props.setIsUnAuth(true);
                        props.setMsg(
                          "You have been logged-out due to inactivity. Login again.."
                        );
                        props.setTitle("Error !");
                      } else {
                        props.setShow(true);
                        props.setMsg(
                          "Something went wrong. Please try again later"
                        );
                        props.setTitle("Warning !");
                      }
                    } catch (error) {
                      if (error.message.includes("403")) {
                        props.setShow(true);
                        props.setIsUnAuth(true);
                        props.setMsg(
                          "You have been logged-out due to inactivity. Login again.."
                        );
                        props.setTitle("Error !");
                      }
                    } finally {
                      props.setIsLoaded(false);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    const topicsAndComplexities = {};

    for (const section in newDetails) {
      for (const item in newDetails[section].secData) {
        const { topic, complexity, desType } =
          newDetails[section].secData[item];

        // Generate a unique key based on topic and complexity
        if (complexity !== "") {
          const key =
            specificField !== 2
              ? `${topic.value}_${complexity.value}`
              : specificField === 2 && props.testData.videoQn
              ? `${topic.value}_${complexity.value}`
              : `${topic.value}_${complexity.value}_${desType.value}`;

          if (topicsAndComplexities[key]) {
            // Duplicate found, show alert message
            props.setShow(true);
            props.setMsg(
              `The ${complexity.label} level is already configured for ${topic.label} topic.`
            );
            props.setTitle("Warning !");
            newDetails[section].secData[item].complexity = "";
            return;
          } else {
            // Store the key to check for duplicates
            topicsAndComplexities[key] = true;
          }
        }
      }
    }
    setTestConfig(newDetails);
  };

  const handleDeleteTopicConfigData = (
    sectionId,
    tConfigId,
    status,
    testConfigId
  ) => {
    let newDetails = [...testConfig];
    if (status === "old") {
      props.setIsLoaded(true);
      axios
        .post(
          "node/admin/test/testconfig/delete",
          JSON.stringify({ testConfigId: testConfigId }),
          {
            headers: { "Content-type": "application/json" },
          }
        )
        .then((res) => {
          props.setIsLoaded(false);
          if (res.data.resultCode === 1000) {
            for (let i = 0; i < newDetails.length; i++) {
              for (let j = 0; j < newDetails[i].secData.length; j++) {
                if (
                  newDetails[i].id === sectionId &&
                  newDetails[i].secData[j].id === tConfigId
                ) {
                  newDetails[i].secData.splice(j, 1);
                  props.setShow(true);
                  props.setMsg(
                    "The test configuration is deleted successfully."
                  );
                  props.setTitle("Success");
                }
              }
            }
          } else {
            props.setShow(true);
            props.setMsg("Something went wrong. Please try again later");
            props.setTitle("Error !");
          }
        })
        .catch((err) => {
          props.setIsLoaded(false);
          if (err.message.includes("403")) {
            props.setShow(true);
            props.setIsUnAuth(true);
            props.setMsg(
              "You have been logged-out due to inactivity. Login again.."
            );
            props.setTitle("Error !");
          }
        });
    } else {
      for (let i = 0; i < newDetails.length; i++) {
        for (let j = 0; j < newDetails[i].secData.length; j++) {
          if (
            newDetails[i].id === sectionId &&
            newDetails[i].secData[j].id === tConfigId
          ) {
            newDetails[i].secData.splice(j, 1);
          }
        }
      }
    }

    setTestConfig(newDetails);
  };

  const ref = React.useCallback(
    (node) => {
      if (node && focus == node.id) {
        if (node) {
          node.selectionStart = cursorPointer;
          node.selectionEnd = cursorPointer;
          node.focus();
        }
      }
      if (
        node !== null &&
        node !== undefined &&
        node.inputRef &&
        node.inputRef !== undefined
      ) {
        if (focus == node.inputRef.id) node.inputRef.focus();
      }
    },
    [focus, cursorPointer]
  );

  const handleAddQnsFromList = (qnIds) => {
    let newDetails = [...testConfig];
    for (let i = 0; i < newDetails.length; i++) {
      let qnids = "";
      for (let j = 0; j < newDetails[i].secData.length; j++) {
        if (
          newDetails[i].id === listQnData.sectionId &&
          newDetails[i].secData[j].id === listQnData.secDataId
        ) {
          if (qnIds.length > 0) {
            if (
              qnIds.length > parseInt(newDetails[i].secData[j].fixedQnCount)
            ) {
              props.setShow(true);
              props.setMsg(
                `You have configured ${
                  newDetails[i].secData[j].fixedQnCount
                } questions but have selected ${qnIds.length} ${
                  qnIds.length === 1 ? "question" : "questions"
                }. Please select accordingly.`
              );
              props.setTitle("Warning !");
            } else if (
              qnIds.length < parseInt(newDetails[i].secData[j].fixedQnCount)
            ) {
              props.setShow(true);
              props.setMsg(
                `You have configured ${
                  newDetails[i].secData[j].fixedQnCount
                } questions but have selected ${qnIds.length} ${
                  qnIds.length === 1 ? "question" : "questions"
                }. Please select accordingly.`
              );
              props.setTitle("Warning !");
            } else {
              for (let k = 0; k < qnIds.length; k++) {
                if (k < qnIds.length - 1) qnids += qnIds[k] + ",";
                else qnids += qnIds[k];
              }
              setShowQnList(false);
              setSelectedQns([]);
            }
          } else {
            props.setShow(true);
            props.setMsg("There is no selected question");
            props.setTitle("Warning !");
          }
          newDetails[i].secData[j].fixedQnId = qnids;
        }
      }
    }
  };

  const sections = [Table.EXPAND_COLUMN];

  useEffect(() => {
    // Handle document click to close the menu on outside click
    const handleClickOutside = (event) => {
      if (Object.values(menuOpenState).includes(true)) {
        const targetRef = multiSelectRefs.current.map((ref) => ref.current);
        if (!targetRef.some((ref) => ref && ref.contains(event.target))) {
          setMenuOpenState([]);
        }
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [menuOpenState]);
  const ExpandableTableText = ({ data }) => {
    return (
      <div className="warningData-info">
        {data.map((d) => (
          <div key={d.lang.id}>
            There are {d.count}
            {d.count > 1 ? " questions" : " question"} available in{" "}
            {d.lang.name}
          </div>
        ))}
      </div>
    );
  };

  const ExpandableTable = ({ topicData }) => {
    let showData = Object.values(
      topicData[0].libtypeid === constants.libTypeIds.CODING_CHALLENGE
        ? topicData[0].qnCountJson
        : []
    ).filter((co) => co.isNotEnough === true);

    return (
      <div className="warningData-tooltip">
        {topicData?.map((data, index) => (
          <div key={index}>
            {data.libtypeid === constants.libTypeIds.CODING_CHALLENGE ? (
              // <div className='warningData-info'>There are {data.questioncount}{data.questioncount > 1 ? " questions" : " question"} available in {data.languagename}</div>
              <ExpandableTableText data={showData} />
            ) : (
              <div className="warningData-info">
                There are {data.questioncount}
                {data.questioncount > 1 ? " questions" : " question"} available
                in {data.complexityname}
              </div>
            )}
          </div>
        ))}
      </div>
    );
  };
  const renderExclamationIcon = (select, record) => {
    const showIcon =
      insufficientQuestions[record.id] &&
      insufficientQuestions[record.id].length > 0;
    return (
      <div
        className="renderExclamation"
        onMouseEnter={() => setTooltipData(record.key)}
        onMouseLeave={() => setTooltipData(null)}
      >
        {!isSufficientQuestions && showIcon ? (
          <Tooltip
            title={
              <ExpandableTable topicData={insufficientQuestions[record.id]} />
            }
            placement="rightBottom"
            popupVisible={tooltipData === record.key}
          >
            <span>
              <FontAwesomeIcon
                className="exclamation"
                icon={faTriangleExclamation}
              />
            </span>
          </Tooltip>
        ) : null}
      </div>
    );
  };

  const handleDeleteLang = (langId, configId, secId) => {
    let newDetails = JSON.parse(JSON.stringify(testConfig));
    if (props.testData.published === 0 || props.isNewTest) {
      for (let sec = 0; sec < newDetails.length; sec++) {
        for (
          let secData = 0;
          secData < newDetails[sec].secData.length;
          secData++
        ) {
          if (
            newDetails[sec].id === secId &&
            newDetails[sec].secData[secData].id === configId
          ) {
            newDetails[sec].secData[secData].languages = newDetails[
              sec
            ].secData[secData].languages.filter(
              (lang) => lang.value !== langId
            );
            if (
              props.oldTestConfig.isOldTestConfig &&
              newDetails[sec].secData[secData].testStatus === "old"
            )
              newDetails[sec].secData[secData].type = "update";
            else if (
              props.oldTestConfig.isOldTestConfig &&
              newDetails[sec].secData[secData].testStatus === "new"
            )
              newDetails[sec].secData[secData].type = "insert";
          }
        }
      }
    }
    setTestConfig(newDetails);
  };

  const customValueRenderer = (selected, _options, record, secId) => {
    return selected.length
      ? selected.map(({ value, label }) => (
          <React.Fragment>
            <span className="adminTestConfigLabel">
              {label}{" "}
              <FontAwesomeIcon
                id="adminTestConfigLabelXMark"
                onClick={() => handleDeleteLang(value, record.id, secId)}
                icon={faXmark}
              />
            </span>
            {/*   */}
          </React.Fragment>
        ))
      : "Select the language";
  };

  const handleOpenFixedQuestionsLists = async (secData, sectionId) => {
    let libtypeid =
      specificField === 2
        ? secData.desType.value
        : props.testData.testType.value;
    props.setIsLoaded(true);
    try {
      let result = await handleGetQuestionList(
        secData.topic.value,
        secData.complexity.value,
        libtypeid,
        secData.languages
      );
      if (result.resultCode === 1000) {
        let newDetails = JSON.parse(JSON.stringify(testConfig));
        for (const section of newDetails) {
          for (const item of section.secData) {
            if (section.id === sectionId && item.id === secData.id) {
              let fixedQnIds = secData.fixedQnId.split(",");
              for (let fixedQnId of fixedQnIds) {
                for (
                  let question = 0;
                  question < result.data.length;
                  question++
                ) {
                  if (result.data[question].id === parseInt(fixedQnId)) {
                    result.data[question].selected = true;
                  }
                }
              }
              setSelectedQns(
                fixedQnIds.map((fixedQnId) => (fixedQnId = parseInt(fixedQnId)))
              );
            }
          }
        }
        setListQnData({ sectionId, secDataId: secData.id, data: result.data });
        setShowQnList(true);
      } else {
        props.setShow(true);
        props.setMsg("Something went wrong. Please try again later");
        props.setTitle("Warning !");
      }
    } catch (error) {
      if (error.message.includes("403")) {
        props.setShow(true);
        props.setIsUnAuth(true);
        props.setMsg(
          "You have been logged-out due to inactivity. Login again.."
        );
        props.setTitle("Error !");
      }
    } finally {
      props.setIsLoaded(false);
    }
  };

  const sectionData = [
    {
      key: nanoid(),
      render: renderExclamationIcon,
      width: "1%",
    },
    {
      title: "TOPIC",
      dataIndex: "topic",
      key: nanoid(),
      render: (select, record) => (
        <>
          {testConfig.map((config) =>
            config.secData.map(
              (secData) =>
                secData.id === record.id && (
                  <ReactSelect
                    name="topic"
                    inputId={record.id}
                    tabIndex={record.id}
                    options={topics}
                    placeholder="Select Topic"
                    className="adminTestConfigData adminTestTopic"
                    value={secData.topic}
                    ref={focus && focus == record.id ? ref : null}
                    onChange={(selectedTopic) =>
                      handleTopicConfigData(
                        record,
                        selectedTopic,
                        "topic",
                        config.id
                      )
                    }
                    closeMenuOnSelect={false}
                    isDisabled={
                      /* props.isOldLpDetail  && (record.status === 'old' ) && */ props
                        .testData.published === 0 || props.isNewTest
                        ? false
                        : true
                    }
                    hideSelectedOptions={false}
                    components={
                      /* props.isOldLpDetail  && (record.status === 'old' ) && */ props
                        .testData.published === 0 || props.isNewTest
                        ? { IndicatorSeparator: () => null }
                        : {
                            IndicatorSeparator: () => null,
                            DropdownIndicator: () => null,
                          }
                    }
                    styles={customStylesForTopic}
                    allowSelectAll={false}
                  />
                )
            )
          )}
        </>
      ),
      width: "15%",
    },
    {
      title: "DESCRIPTIVE TYPE",
      dataindex: "desType",
      key: nanoid(),
      render: (select, record) => (
        <>
          {testConfig.map((config) =>
            config.secData.map(
              (secData) =>
                secData.id === record.id && (
                  <ReactSelect
                    name="desType"
                    key={nanoid()}
                    inputId={record.id + 1}
                    tabIndex={record.id + 1}
                    className="adminTestConfigData adminTestDesType "
                    placeholder="Select descriptive type"
                    value={secData.desType}
                    components={
                      /* props.isOldLpDetail  && (record.status === 'old' ) && */ props
                        .testData.published === 0 || props.isNewTest
                        ? { IndicatorSeparator: () => null }
                        : {
                            IndicatorSeparator: () => null,
                            DropdownIndicator: () => null,
                          }
                    }
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    ref={focus && focus == record.id + 1 ? ref : null}
                    onChange={(selectedDesType) =>
                      handleTopicConfigData(
                        record,
                        selectedDesType,
                        "desType",
                        config.id
                      )
                    }
                    isDisabled={
                      /* props.isOldLpDetail  && (record.status === 'old' ) && */ props
                        .testData.published === 0 || props.isNewTest
                        ? false
                        : true
                    }
                    options={props.descriptiveOptions}
                    styles={customStylesForLevel}
                    allowSelectAll={false}
                  />
                )
            )
          )}
        </>
      ),
      width: "15%",
    },
    {
      title: "LEVEL",
      dataindex: "complexity",
      key: nanoid(),
      render: (select, record) => (
        <>
          {testConfig.map((config) =>
            config.secData.map(
              (secData) =>
                secData.id === record.id && (
                  <ReactSelect
                    name="complexity"
                    key={nanoid()}
                    inputId={record.id + 2}
                    tabIndex={record.id + 2}
                    className="adminTestConfigData"
                    placeholder="Select Level"
                    value={secData.complexity}
                    components={
                      /* props.isOldLpDetail  && (record.status === 'old' ) && */ props
                        .testData.published === 0 || props.isNewTest
                        ? { IndicatorSeparator: () => null }
                        : {
                            IndicatorSeparator: () => null,
                            DropdownIndicator: () => null,
                          }
                    }
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    ref={focus && focus == record.id + 2 ? ref : null}
                    onChange={(selectedLevel) =>
                      handleTopicConfigData(
                        record,
                        selectedLevel,
                        "complexity",
                        config.id
                      )
                    }
                    isDisabled={
                      /* props.isOldLpDetail  && (record.status === 'old' ) && */ props
                        .testData.published === 0 || props.isNewTest
                        ? false
                        : true
                    }
                    options={complexity}
                    styles={customStylesForLevel}
                    allowSelectAll={false}
                  />
                )
            )
          )}
        </>
      ),
      width: "15%",
    },
    {
      title: "ALLOWED LANGUAGES",
      dataindex: "languages",
      key: nanoid(),
      render: (select, record) => (
        <>
          {testConfig.map((config) =>
            config.secData.map((secData, index) => {
              return (
                secData.id === record.id && (
                  <MultiSelect
                    name="languages"
                    className="adminTestConfigData"
                    inputId={record.id + 3}
                    tabIndex={record.id + 3}
                    key={nanoid()}
                    options={languages}
                    value={secData.languages === "" ? [] : secData.languages}
                    disableSearch
                    overrideStrings={{
                      allItemsAreSelected: "All languages are selected",
                    }}
                    // ref={focus && focus == record.id + 3 ? ref : null}
                    valueRenderer={() =>
                      customValueRenderer(
                        secData.languages,
                        languages,
                        record,
                        config.id
                      )
                    }
                    disabled={
                      /* props.isOldLpDetail  && (record.status === 'old' ) && */ props
                        .testData.published === 0 || props.isNewTest
                        ? false
                        : true
                    }
                    onChange={(selectedLang) =>
                      handleTopicConfigData(
                        record,
                        selectedLang,
                        "languages",
                        config.id
                      )
                    }
                    labelledBy="Select language"
                    defaultIsOpen={multiSelectRefs.current[index]?.menuOpen}
                    ClearSelectedIcon={null}
                    shouldToggleOnHover={false}
                    closeOnChangedValue={false}
                    ref={multiSelectRefs.current[index]} // Set ref for each instance
                    onMenuToggle={(isOpen) => {
                      // Manually open/close the menu without blinking behavior
                      if (isOpen) {
                        multiSelectRefs.current[index] = { menuOpen: true };
                      } else {
                        multiSelectRefs.current[index] = { menuOpen: false };
                      }
                    }}
                  />
                )
              );
            })
          )}
        </>
      ),
      width: "15%",
    },
    {
      title: "RANDOM QUESTION",
      dataindex: "randomQnCount",
      key: nanoid(),
      render: (value, record) => (
        <>
          {testConfig.map((config) =>
            config.secData.map(
              (secData) =>
                secData.id === record.id && (
                  <input
                    type="text"
                    name="randomQnCount"
                    placeholder="Question Count"
                    onChange={(e) =>
                      handleTopicConfigData(
                        record,
                        e,
                        "randomQnCount",
                        config.id
                      )
                    }
                    key={nanoid()}
                    id={record.id + 4}
                    value={secData.randomQnCount}
                    tabIndex={record.id + 4}
                    className="adminTestConfigData"
                    ref={focus && focus == record.id + 4 ? ref : null}
                    disabled={
                      /* props.isOldLpDetail  && (record.status === 'old' ) && */ props
                        .testData.published === 0 || props.isNewTest
                        ? false
                        : true
                    }
                  />
                )
            )
          )}
        </>
      ),
      width: "10%",
    },
    {
      title: "FIXED QUESTION",
      dataindex: "fixedQnCount",
      key: nanoid(),
      render: (value, record) => (
        <>
          {testConfig.map((config) =>
            config.secData.map(
              (secData) =>
                secData.id === record.id && (
                  <input
                    type="text"
                    name="fixedQnCount"
                    placeholder="Question Count"
                    onChange={(e) =>
                      handleTopicConfigData(
                        record,
                        e,
                        "fixedQnCount",
                        config.id
                      )
                    }
                    key={nanoid()}
                    id={record.id + 5}
                    value={secData.fixedQnCount}
                    tabIndex={record.id + 5}
                    className="adminTestConfigData"
                    ref={focus && focus == record.id + 5 ? ref : null}
                    disabled={
                      /* props.isOldLpDetail  && (record.status === 'old' ) && */ props
                        .testData.published === 0 || props.isNewTest
                        ? false
                        : true
                    }
                  />
                )
            )
          )}
        </>
      ),
      width: "10%",
    },
    {
      title: "MARKS",
      dataindex: "marks",
      key: nanoid(),
      render: (value, record) => (
        <>
          {testConfig.map((config) =>
            config.secData.map(
              (secData) =>
                secData.id === record.id && (
                  <input
                    type="text"
                    name="marks"
                    className="adminTestConfigData"
                    key={nanoid()}
                    id={record.id + 6}
                    tabIndex={record.id + 6}
                    value={secData.marks}
                    placeholder="Marks"
                    onChange={(e) =>
                      handleTopicConfigData(record, e, "marks", config.id)
                    }
                    ref={focus && focus == record.id + 6 ? ref : null}
                    disabled={
                      /* props.isOldLpDetail  && (record.status === 'old' ) && */ props
                        .testData.published === 0 || props.isNewTest
                        ? false
                        : true
                    }
                  />
                )
            )
          )}
        </>
      ),
      width: "10%",
    },
    {
      title: "DURATION",
      dataindex: "duration",
      key: nanoid(),
      render: (value, record) => (
        <>
          {testConfig.map((config) =>
            config.secData.map(
              (secData) =>
                secData.id === record.id && (
                  <div style={{ display: "flex" }} key={nanoid()}>
                    <input
                      type="test"
                      name="durationNum"
                      placeholder="Duration"
                      className="adminTestConfigData adminTestConfigDur"
                      key={nanoid()}
                      id={record.id + 7}
                      value={secData.durationNum}
                      tabIndex={record.id + 7}
                      onChange={(e) =>
                        handleTopicConfigData(
                          record,
                          e,
                          "durationNum",
                          config.id
                        )
                      }
                      ref={focus && focus == record.id + 7 ? ref : null}
                      disabled={
                        /* props.isOldLpDetail  && (record.status === 'old' ) && */ props
                          .testData.published === 0 || props.isNewTest
                          ? false
                          : true
                      }
                    />
                    <select
                      className="adminTestConfigData adminTestConfigDurSelection"
                      key={nanoid()}
                      id={record.id + 8}
                      value={secData.durationUnit}
                      tabIndex={record.id + 8}
                      onChange={(e) =>
                        handleTopicConfigData(
                          record,
                          e,
                          "durationUnit",
                          config.id
                        )
                      }
                      ref={focus && focus == record.id + 8 ? ref : null}
                      disabled={
                        /* props.isOldLpDetail  && (record.status === 'old' ) && */ props
                          .testData.published === 0 || props.isNewTest
                          ? false
                          : true
                      }
                    >
                      <option>min/s</option>
                      <option>hour/s</option>
                      <option>day/s</option>
                    </select>
                  </div>
                )
            )
          )}
        </>
      ),
      width: "10%",
    },
    {
      // title: "Action",
      dataindex: "action",
      key: nanoid(),
      render: (_, record) => (
        <>
          {testConfig.map((config) =>
            config.secData.map(
              (secData) =>
                secData.id === record.id && (
                  <>
                    {
                      /* props.isOldLpDetail  && (record.status === 'old' ) && */ (props
                        .testData.published === 0 ||
                        props.isNewTest) && (
                        <svg
                          width="12"
                          height="12"
                          viewBox="0 0 12 12"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          key={nanoid()}
                          id="adminTestConfigDataTrashIcon"
                          onClick={() =>
                            handleDeleteTopicConfigData(
                              config.id,
                              record.id,
                              record.testStatus,
                              record.topicConfigId
                            )
                          }
                        >
                          <path
                            d="M5.02196 4.93363C5.29225 4.93363 5.5142 5.14057 5.53803 5.40465L5.54015 5.45182V8.74091C5.54015 9.02709 5.30815 9.25909 5.02196 9.25909C4.75167 9.25909 4.52972 9.05216 4.50589 8.78807L4.50377 8.74091V5.45182C4.50377 5.16563 4.73577 4.93363 5.02196 4.93363Z"
                            fill="#F55533"
                          />
                          <path
                            d="M7.73075 5.40465C7.70693 5.14057 7.48497 4.93363 7.21469 4.93363C6.9285 4.93363 6.6965 5.16563 6.6965 5.45182V8.74091L6.69862 8.78807C6.72244 9.05216 6.9444 9.25909 7.21469 9.25909C7.50087 9.25909 7.73287 9.02709 7.73287 8.74091V5.45182L7.73075 5.40465Z"
                            fill="#F55533"
                          />
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M7.21468 0C8.08291 0 8.79107 0.685314 8.82774 1.54451L8.82924 1.61455V2.19273H11.052C11.3381 2.19273 11.5701 2.42473 11.5701 2.71091C11.5701 2.9812 11.3632 3.20315 11.0991 3.22698L11.052 3.2291H10.4738V10.3855C10.4738 11.2537 9.78847 11.9618 8.92927 11.9985L8.85923 12H3.37742C2.50919 12 1.80103 11.3147 1.76436 10.4555L1.76287 10.3855V3.2291H1.18469C0.898504 3.2291 0.666504 2.9971 0.666504 2.71091C0.666504 2.44062 0.873442 2.21867 1.13753 2.19484L1.18469 2.19273H3.40741V1.61455C3.40741 0.746324 4.09272 0.0381621 4.95192 0.00149169L5.02196 0H7.21468ZM2.79924 3.2291V10.3855C2.79924 10.6888 3.03286 10.9376 3.33 10.9617L3.37742 10.9636H8.85923C9.16258 10.9636 9.41137 10.73 9.43549 10.4329L9.4374 10.3855V3.2291H2.79924ZM7.79286 2.19273H4.44378V1.61455L4.4457 1.56713C4.46982 1.26999 4.71861 1.03637 5.02196 1.03637H7.21468L7.2621 1.03829C7.55924 1.06241 7.79286 1.3112 7.79286 1.61455V2.19273Z"
                            fill="#F55533"
                          />
                        </svg>
                      )
                    }
                    {secData.fixedQnCount > 0 &&
                    secData.fixedQnId?.split(",").length > 0 ? (
                      <PreviewIcon
                        titleAccess="Fixed questions"
                        id="adminTestConfigDataPreviewFxQn"
                        onClick={() =>
                          handleOpenFixedQuestionsLists(secData, config.id)
                        }
                      />
                    ) : null}
                  </>
                )
            )
          )}
        </>
      ),
      width: "3%",
    },
  ];

  const handleEnterKey = (event, data) => {
    if (event.keyCode === 13) {
      setTopicExpandedKey([data.key]);
      setFocus(data.id + 1);
    }
  };

  const handleBackBtn = () => {
    props.setIsNext(false);
    props.setIsBackDetails({ isBack: true, testType: props.testData.testType });
    let newDetails = JSON.parse(JSON.stringify(props.testData));
    props.setViewTest(newDetails.viewTest);
    newDetails.sections = testConfig;
    props.setAutoEvaluate(newDetails.autoEvaluate);
    props.setVideoQn(newDetails.videoQn);
    props.setTestData(newDetails);
    props.setQnsCount(props.qnsCount);
  };

  const handleSaveTest = (/* e */) => {
    // e.preventDefault();
    let newDetails = { ...props.testData },
      found = 0,
      totalDuration = 0,
      orderno = 1;
    newDetails.sections = testConfig;
    newDetails.testTypeId = newDetails.testType.value;
    if (newDetails.testTypeId !== constants.libTypeIds.CODING_CHALLENGE) {
      if (newDetails.hasOwnProperty("testDuration") && !newDetails.videoQn) {
        totalDuration = newDetails.testDuration;
      }
    } else newDetails.testDuration = "";
    if (newDetails.sections.length > 0) {
      var uniqueData = [];
      for (let i = 0; i < newDetails.sections.length; i++) {
        if (newDetails.sections[i].secName === "") {
          props.setShow(true);
          props.setMsg(`Please give section name for section ${i + 1}.`);
          props.setTitle("Warning !");
          found = 1;
          break;
        } else {
          if (newDetails.sections[i].secData.length > 0) {
            for (let j = 0; j < newDetails.sections[i].secData.length; j++) {
              newDetails.sections[i].secData[j].orderno = orderno;
              orderno++;
              if (
                newDetails.sections[i].secData[j].hasOwnProperty("desType") &&
                !newDetails.videoQn
              ) {
                if (newDetails.sections[i].secData[j].desType === "") {
                  props.setShow(true);
                  props.setMsg(`Please select the descriptive type .`);
                  props.setTitle("Warning !");
                  found = 1;
                  break;
                } else {
                  newDetails.sections[i].secData[j].desTypeName =
                    newDetails.sections[i].secData[j].desType.label;
                  newDetails.sections[i].secData[j].desTypeId =
                    newDetails.sections[i].secData[j].desType.value;
                }
              }
              if (newDetails.sections[i].secData[j].topic === "") {
                props.setShow(true);
                props.setMsg("Please select the topic.");
                props.setTitle("Warning !");
                found = 1;
                break;
              } else {
                newDetails.sections[i].secData[j].topicName =
                  newDetails.sections[i].secData[j].topic.label;
                newDetails.sections[i].secData[j].topicId =
                  newDetails.sections[i].secData[j].topic.value;
                if (newDetails.sections[i].secData[j].complexity === "") {
                  props.setShow(true);
                  props.setMsg("Please select the level.");
                  props.setTitle("Warning !");
                  found = 1;
                  break;
                } else {
                  newDetails.sections[i].secData[j].complexityId =
                    newDetails.sections[i].secData[j].complexity.value;
                  newDetails.sections[i].secData[j].complexityName =
                    newDetails.sections[i].secData[j].complexity.label;
                }
              }
              if (
                (newDetails.sections[i].secData[j].randomQnCount === "" &&
                  newDetails.sections[i].secData[j].fixedQnCount === "") ||
                (newDetails.sections[i].secData[j].randomQnCount === 0 &&
                  newDetails.sections[i].secData[j].fixedQnCount === 0) ||
                (newDetails.sections[i].secData[j].randomQnCount ===
                  undefined &&
                  newDetails.sections[i].secData[j].fixedQnCount === undefined)
              ) {
                props.setShow(true);
                props.setMsg("Please give random or fixed question count .");
                props.setTitle("Warning !");
                found = 1;
                break;
              }
              if (
                newDetails.sections[i].secData[j].marks === "" ||
                newDetails.sections[i].secData[j].marks === undefined
              ) {
                props.setShow(true);
                props.setMsg("Please give mark.");
                props.setTitle("Warning !");
                found = 1;
                break;
              }
              if (
                newDetails.sections[i].secData[j].hasOwnProperty("durationNum")
              ) {
                if (
                  newDetails.sections[i].secData[j].durationNum === "" ||
                  newDetails.sections[i].secData[j].durationNum === undefined
                ) {
                  props.setShow(true);
                  props.setMsg("Please give duration.");
                  props.setTitle("Warning !");
                  found = 1;
                  break;
                } else {
                  let durationUnit =
                    newDetails.sections[i].secData[j].durationUnit;
                  let durationNum = parseInt(
                    newDetails.sections[i].secData[j].durationNum
                  );

                  let qCount =
                    (newDetails.sections[i].secData[j].randomQnCount === "" ||
                    newDetails.sections[i].secData[j].randomQnCount ===
                      undefined
                      ? 0
                      : parseInt(
                          newDetails.sections[i].secData[j].randomQnCount
                        )) +
                    (newDetails.sections[i].secData[j].fixedQnCount === "" ||
                    newDetails.sections[i].secData[j].fixedQnCount === undefined
                      ? 0
                      : parseInt(
                          newDetails.sections[i].secData[j].fixedQnCount
                        ));
                  newDetails.sections[i].secData[j].duration =
                    qCount * durationNum +
                    (durationUnit === "hour/s"
                      ? "h"
                      : durationUnit === "day/s"
                      ? "d"
                      : "m");
                  totalDuration +=
                    qCount *
                    (durationUnit === "hour/s"
                      ? durationNum * 60
                      : durationUnit === "day/s"
                      ? durationNum * 24 * 60
                      : durationNum);
                }
              }
              if (
                newDetails.sections[i].secData[j].hasOwnProperty("languages")
              ) {
                if (newDetails.sections[i].secData[j].languages === "") {
                  props.setShow(true);
                  props.setMsg("Please select the language.");
                  props.setTitle("Warning !");
                  found = 1;
                  break;
                } else {
                  for (
                    let k = 0;
                    k < newDetails.sections[i].secData[j].languages.length;
                    k++
                  ) {
                    newDetails.sections[i].secData[j].languages[k].name =
                      newDetails.sections[i].secData[j].languages[k].label;
                    newDetails.sections[i].secData[j].languages[k].id =
                      newDetails.sections[i].secData[j].languages[k].value;
                    newDetails.sections[i].secData[j].languages[k].key =
                      nanoid();
                  }
                }
              }
              if (
                newDetails.sections[i].secData[j].fixedQnCount === "" ||
                newDetails.sections[i].secData[j].fixedQnCount === undefined ||
                newDetails.sections[i].secData[j].fixedQnCount === 0
              ) {
                newDetails.sections[i].secData[j].fixedQnCount = 0;
                newDetails.sections[i].secData[j].fixedQnId = "";
              }
              if (
                newDetails.sections[i].secData[j].randomQnCount === "" ||
                newDetails.sections[i].secData[j].randomQnCount === undefined ||
                newDetails.sections[i].secData[j].randomQnCount === 0
              ) {
                newDetails.sections[i].secData[j].randomQnCount = 0;
              }

              if (newDetails.sections[i].secData[j].fixedQnId !== "") {
                uniqueData.push(newDetails.sections[i].secData[j].fixedQnId);
              }
              var allValues = uniqueData.join(",").split(",");
              var uniqueValuesSet = new Set(allValues);
              var allValuesUnique = uniqueValuesSet.size === allValues.length;
              if (!allValuesUnique) {
                props.setShow(true);
                props.setMsg(
                  "There is some common question across the configuration"
                );
                props.setTitle("Warning !");
                found = 1;
                break;
              }
            }
          } else {
            props.setShow(true);
            props.setMsg("Please add the topic configuration.");
            props.setTitle("Warning !");
            found = 1;
            break;
          }
          if (found === 1) break;
        }
      }

      if (newDetails.videoQn && totalDuration > newDetails.testDuration) {
        props.setShow(true);
        props.setMsg(
          "Please give the duration less than or equal to  test duration."
        );
        props.setTitle("Warning !");
        found = 1;
        return;
      }
    } else {
      props.setShow(true);
      props.setMsg("Please add the section.");
      props.setTitle("Warning !");
      found = 1;
    }
    if (found === 0) {
      if (newDetails.testTypeId === constants.libTypeIds.CODING_CHALLENGE)
        newDetails.testDuration = totalDuration;
      if (!props.oldTestConfig.isOldTestConfig) {
        props.setIsLoaded(true);
        axios
          .post("node/admin/test/create", JSON.stringify(newDetails), {
            headers: { "Content-type": "application/json" },
          })
          .then((createTestRes) => {
            let data = createTestRes.data;
            props.setIsLoaded(false);
            if (data.resultCode === 1000) {
              if (data.msg.includes("created")) {
                setIsSufficientQuestions(true);
                // props.setLists(data.data.testList);
                setSuccessRes({
                  isSuccess: true,
                  msg: "The test is created successfully.",
                  testId: data.data.testId,
                });
              } else {
                setInsufficientQuestions(data.data);
                setIsSufficientQuestions(false);
                props.setShow(true);
                props.setMsg(data.msg);
                props.setTitle("Warning !");
              }
            } else if (
              data.resultCode === 2050 &&
              data.msg.sqlMessage.includes("Duplicate entry")
            ) {
              let newDetails = { ...props.testData };
              newDetails.testConfig = testConfig;
              props.setTestData(newDetails);
              props.setIsNext(false);
              props.setIsBackDetails({
                isBack: true,
                testType: props.testData.testType,
              });
              props.setShow(true);
              props.setMsg("The test name is already taken.");
              props.setTitle("Warning !");
            } else if (data.resultCode === 2000) {
              props.setShow(true);
              props.setMsg(data.msg);
              props.setTitle("Warning !");
            } else {
              props.setShow(true);
              props.setMsg("Something went wrong. Please try again later");
              props.setTitle("Error !");
            }
          })
          .catch((createTestErr) => {
            props.setIsLoaded(false);
            if (createTestErr.message.includes("403")) {
              props.setShow(true);
              props.setIsUnAuth(true);
              props.setMsg("You have been logged-out due to inactivity. Login again..");
              props.setTitle("Error !");
            }
          });
      } else {
        let typeFound = 0;
        if (newDetails.type === "update") typeFound = 1;
        if (typeFound === 0) {
          for (let i = 0; i < newDetails.sections.length; i++) {
            if (newDetails.sections[i].testStatus === "new") {
              typeFound = 1;
              break;
            }
            for (let j = 0; j < newDetails.sections[i].secData.length; j++) {
              if (
                newDetails.sections[i].secData[j].type === "update" ||
                newDetails.sections[i].secData[j].type === "insert"
              ) {
                typeFound = 1;
                break;
              }
            }
            if (typeFound === 1) break;
          }
        }
        if (typeFound === 1) {
          props.setIsLoaded(true);
          axios
            .post("node/admin/test/update", JSON.stringify(newDetails), {
              headers: { "Content-type": "application/json" },
            })
            .then((updateTestRes) => {
              let data = updateTestRes.data;
              props.setIsLoaded(false);
              if (data.resultCode === 1000) {
                if (data.msg.includes("updated")) {
                  setIsSufficientQuestions(true);
                  setSuccessRes({
                    isSuccess: true,
                    msg: "The test is updated.",
                  });
                } else {
                  setInsufficientQuestions(data.data);
                  setIsSufficientQuestions(false);
                  props.setShow(true);
                  props.setMsg(data.msg);
                  props.setTitle("Warning !");
                }
              } else if (
                data.resultCode === 2050 &&
                data.msg.sqlMessage.includes("Duplicate entry")
              ) {
                props.setIsNext(false);
                props.setIsBackDetails({
                  isBack: true,
                  testType: props.testData.testType,
                });
                let newDetails = { ...props.testData };
                newDetails.testConfig = testConfig;
                props.setTestData(newDetails);
                props.setShow(true);
                props.setMsg("The test name is already taken.");
                props.setTitle("Warning !");
              } else if (data.resultCode === 2000) {
                props.setShow(true);
                props.setMsg(data.msg);
                props.setTitle("Warning !");
              } else {
                props.setShow(true);
                props.setMsg("Something went wrong. Please try again later");
                props.setTitle("Error !");
              }
            })
            .catch((updateTestErr) => {
              props.setIsLoaded(false);
              if (updateTestErr.message.includes("403")) {
                props.setShow(true);
                props.setIsUnAuth(true);
                props.setMsg(
                  "You have been logged-out due to inactivity. Login again.."
                );
                props.setTitle("Error !");
              }
            });
        } else {
          props.setShow(true);
          props.setMsg("There is no data to update");
          props.setTitle("Warning !");
        }
      }
    }
  };

  const handleCancelTest = () => {
    if (!props.oldTestConfig.isOldTestConfig) {
      props.setIsAddTest(false);
      props.setIsNewTest(false);
    } else if (
      props.oldTestConfig.isOldTestConfig &&
      props.isOldLpDetail &&
      props.isFromBatch
    )
      navigate(`/batches/${sessionStorage.getItem('abatchid')}/courses`);
    else if (props.oldTestConfig.isOldTestConfig && props.fromMenu) {
      props.setIsAddTest(false);
      setFromMenuToEditTest(true);
      setOldTestConfig({
        isOldTestConfig: false,
        oldTestData: {},
      });
    } else navigate("/learningpath");
  };

  return (
    <>
      <div style={{ textAlign: "left" }}>
        <FontAwesomeIcon
          className="adminTestBackBtn"
          onClick={handleBackBtn}
          icon={faArrowLeft}
        />
        &nbsp;
        <span className="adminTestBack" onClick={handleBackBtn}>
          {props.testData.testType.label}
        </span>
      </div>
      <div className="adminTestQnCount adminTestGivenQnCount">
        No.of questions : {props.qnsCount}
      </div>
      <div className="adminTestConfigContainer">
        <div className="adminTestConfigSec">
          <DndProvider backend={HTML5Backend}>
            <Table
              key={nanoid()}
              columns={sections}
              dataSource={
                testConfig
              } /* components={topicComponents} */ /*  onRow={(record, index) => ({
                            index,
                            movetopics
                        })} */
              pagination={false}
              expandable={{
                expandedRowKeys: topicExpandedKey,
                expandedRowRender: (record) => {
                  return (
                    <div className="adminTestTopicConfigData">
                      <DndProvider backend={HTML5Backend}>
                        <Table
                          key={nanoid()}
                          columns={
                            specificField === 1
                              ? sectionData.filter(
                                  (index) =>
                                    index.dataindex !== "duration" &&
                                    index.dataindex !== "languages" &&
                                    index.dataindex !== "desType"
                                )
                              : specificField === 2 && props.testData.videoQn
                              ? sectionData.filter(
                                  (index) =>
                                    index.dataindex !== "languages" &&
                                    index.dataindex !== "desType"
                                )
                              : specificField === 2 && !props.testData.videoQn
                              ? sectionData.filter(
                                  (index) =>
                                    index.dataindex !== "duration" &&
                                    index.dataindex !== "languages"
                                )
                              : sectionData.filter(
                                  (index) => index.dataindex !== "desType"
                                )
                          }
                          dataSource={record.secData}
                          pagination={false}
                        />
                      </DndProvider>
                      {
                        /* props.isOldLpDetail && (record.status === 'old' ) && */ (props
                          .testData.published === 0 ||
                          props.isNewTest) && (
                          <p
                            className="adminTestAddLevelBtn"
                            onClick={() => handleAddTopic(record)}
                          >
                            + Add Topic
                          </p>
                        )
                      }
                    </div>
                  );
                },
                expandIcon: ({ expanded, onExpand, record }) =>
                  expanded ? (
                    <>
                      {testConfig.map(
                        (config) =>
                          config.id === record.id &&
                          (specificField !== 3 ? (
                            <div className="adminTestSection" key={nanoid()}>
                              <div
                                style={{
                                  display: "flex",
                                  padding: "0",
                                  margin: "0",
                                }}
                                key={config.id}
                              >
                                {/* {specificField !== 3 && <svg width="6" height="12" viewBox="0 0 6 12" fill="none" xmlns="http://www.w3.org/2000/svg" className='adminTestMoveIcon'>
                                                <path fillRule="evenodd" clipRule="evenodd" d="M0.857 10.286C1.331 10.286 1.714 10.669 1.714 11.143C1.714 11.616 1.331 12 0.857 12C0.384 12 0 11.616 0 11.143C0 10.669 0.384 10.286 0.857 10.286ZM5.143 10.286C5.616 10.286 6 10.669 6 11.143C6 11.616 5.616 12 5.143 12C4.669 12 4.286 11.616 4.286 11.143C4.286 10.669 4.669 10.286 5.143 10.286ZM0.857 6.857C1.331 6.857 1.714 7.241 1.714 7.714C1.714 8.188 1.331 8.571 0.857 8.571C0.384 8.571 0 8.188 0 7.714C0 7.241 0.384 6.857 0.857 6.857ZM5.143 6.857C5.616 6.857 6 7.241 6 7.714C6 8.188 5.616 8.571 5.143 8.571C4.669 8.571 4.286 8.188 4.286 7.714C4.286 7.241 4.669 6.857 5.143 6.857ZM0.857 3.43C1.331 3.43 1.714 3.813 1.714 4.287C1.714 4.76 1.331 5.144 0.857 5.144C0.384 5.144 0 4.76 0 4.287C0 3.813 0.384 3.43 0.857 3.43ZM5.143 3.43C5.616 3.43 6 3.813 6 4.287C6 4.76 5.616 5.144 5.143 5.144C4.669 5.144 4.286 4.76 4.286 4.287C4.286 3.813 4.669 3.43 5.143 3.43ZM0.857 0C1.331 0 1.714 0.384 1.714 0.857C1.714 1.331 1.331 1.714 0.857 1.714C0.384 1.714 0 1.331 0 0.857C0 0.384 0.384 0 0.857 0ZM5.143 0C5.616 0 6 0.384 6 0.857C6 1.331 5.616 1.714 5.143 1.714C4.669 1.714 4.286 1.331 4.286 0.857C4.286 0.384 4.669 0 5.143 0Z" fill="#26344A" />
                                            </svg>} */}
                                <input
                                  type="text"
                                  className="adminTestSectionName"
                                  name="secName"
                                  placeholder="Enter section name"
                                  id={record.id}
                                  value={record.secName}
                                  maxLength={30}
                                  ref={focus && focus == record.id ? ref : null}
                                  onChange={(e) =>
                                    handleSectionName(e, record.id)
                                  }
                                  disabled={
                                    /* props.isOldLpDetail && */ (record.testStatus ===
                                      "new" &&
                                      props.testData.published === 0) ||
                                    props.isNewTest
                                      ? false
                                      : true
                                  }
                                />
                              </div>
                              <div
                                className="adminTestSectionIconSec adminTestConfig"
                                onClick={(e) => {
                                  onExpand(record, e);
                                  setTopicExpandedKey([undefined]);
                                }}
                              >
                                <FontAwesomeIcon
                                  className="adminTestSectionExpandIcon"
                                  icon={faChevronDown}
                                />
                                {specificField !== 3 &&
                                /* props.isOldLpDetail && */ ((record.testStatus ===
                                  "new" &&
                                  props.testData.published === 0) ||
                                  props.isNewTest) ? (
                                  <svg
                                    width="12"
                                    height="12"
                                    viewBox="0 0 12 12"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    id="adminTestSectionTrashIcon"
                                    onClick={() =>
                                      handleDeleteSection(
                                        record.id,
                                        record.status
                                      )
                                    }
                                  >
                                    <path
                                      d="M5.02196 4.93363C5.29225 4.93363 5.5142 5.14057 5.53803 5.40465L5.54015 5.45182V8.74091C5.54015 9.02709 5.30815 9.25909 5.02196 9.25909C4.75167 9.25909 4.52972 9.05216 4.50589 8.78807L4.50377 8.74091V5.45182C4.50377 5.16563 4.73577 4.93363 5.02196 4.93363Z"
                                      fill="#F55533"
                                    />
                                    <path
                                      d="M7.73075 5.40465C7.70693 5.14057 7.48497 4.93363 7.21469 4.93363C6.9285 4.93363 6.6965 5.16563 6.6965 5.45182V8.74091L6.69862 8.78807C6.72244 9.05216 6.9444 9.25909 7.21469 9.25909C7.50087 9.25909 7.73287 9.02709 7.73287 8.74091V5.45182L7.73075 5.40465Z"
                                      fill="#F55533"
                                    />
                                    <path
                                      fillRule="evenodd"
                                      clipRule="evenodd"
                                      d="M7.21468 0C8.08291 0 8.79107 0.685314 8.82774 1.54451L8.82924 1.61455V2.19273H11.052C11.3381 2.19273 11.5701 2.42473 11.5701 2.71091C11.5701 2.9812 11.3632 3.20315 11.0991 3.22698L11.052 3.2291H10.4738V10.3855C10.4738 11.2537 9.78847 11.9618 8.92927 11.9985L8.85923 12H3.37742C2.50919 12 1.80103 11.3147 1.76436 10.4555L1.76287 10.3855V3.2291H1.18469C0.898504 3.2291 0.666504 2.9971 0.666504 2.71091C0.666504 2.44062 0.873442 2.21867 1.13753 2.19484L1.18469 2.19273H3.40741V1.61455C3.40741 0.746324 4.09272 0.0381621 4.95192 0.00149169L5.02196 0H7.21468ZM2.79924 3.2291V10.3855C2.79924 10.6888 3.03286 10.9376 3.33 10.9617L3.37742 10.9636H8.85923C9.16258 10.9636 9.41137 10.73 9.43549 10.4329L9.4374 10.3855V3.2291H2.79924ZM7.79286 2.19273H4.44378V1.61455L4.4457 1.56713C4.46982 1.26999 4.71861 1.03637 5.02196 1.03637H7.21468L7.2621 1.03829C7.55924 1.06241 7.79286 1.3112 7.79286 1.61455V2.19273Z"
                                      fill="#F55533"
                                    />
                                  </svg>
                                ) : (
                                  <span id="adminTestSectionTrashIcon"></span>
                                )}
                              </div>
                            </div>
                          ) : null)
                      )}
                    </>
                  ) : (
                    <>
                      {testConfig.map(
                        (config) =>
                          config.id === record.id &&
                          (specificField !== 3 ? (
                            <div className="adminTestSection" key={nanoid()}>
                              <div
                                style={{
                                  display: "flex",
                                  padding: "0",
                                  margin: "0",
                                }}
                                key={config.id}
                              >
                                {/* {specificField !== 3 && <svg width="6" height="12" viewBox="0 0 6 12" fill="none" xmlns="http://www.w3.org/2000/svg" className='adminTestMoveIcon'>
                                                <path fillRule="evenodd" clipRule="evenodd" d="M0.857 10.286C1.331 10.286 1.714 10.669 1.714 11.143C1.714 11.616 1.331 12 0.857 12C0.384 12 0 11.616 0 11.143C0 10.669 0.384 10.286 0.857 10.286ZM5.143 10.286C5.616 10.286 6 10.669 6 11.143C6 11.616 5.616 12 5.143 12C4.669 12 4.286 11.616 4.286 11.143C4.286 10.669 4.669 10.286 5.143 10.286ZM0.857 6.857C1.331 6.857 1.714 7.241 1.714 7.714C1.714 8.188 1.331 8.571 0.857 8.571C0.384 8.571 0 8.188 0 7.714C0 7.241 0.384 6.857 0.857 6.857ZM5.143 6.857C5.616 6.857 6 7.241 6 7.714C6 8.188 5.616 8.571 5.143 8.571C4.669 8.571 4.286 8.188 4.286 7.714C4.286 7.241 4.669 6.857 5.143 6.857ZM0.857 3.43C1.331 3.43 1.714 3.813 1.714 4.287C1.714 4.76 1.331 5.144 0.857 5.144C0.384 5.144 0 4.76 0 4.287C0 3.813 0.384 3.43 0.857 3.43ZM5.143 3.43C5.616 3.43 6 3.813 6 4.287C6 4.76 5.616 5.144 5.143 5.144C4.669 5.144 4.286 4.76 4.286 4.287C4.286 3.813 4.669 3.43 5.143 3.43ZM0.857 0C1.331 0 1.714 0.384 1.714 0.857C1.714 1.331 1.331 1.714 0.857 1.714C0.384 1.714 0 1.331 0 0.857C0 0.384 0.384 0 0.857 0ZM5.143 0C5.616 0 6 0.384 6 0.857C6 1.331 5.616 1.714 5.143 1.714C4.669 1.714 4.286 1.331 4.286 0.857C4.286 0.384 4.669 0 5.143 0Z" fill="#26344A" />
                                            </svg>} */}
                                <input
                                  type="text"
                                  className="adminTestSectionName"
                                  name="secName"
                                  placeholder="Enter section name"
                                  maxLength={30}
                                  id={record.id}
                                  value={record.secName}
                                  ref={focus && focus == record.id ? ref : null}
                                  onChange={(e) => {
                                    handleSectionName(e, record.id);
                                  }}
                                  disabled={
                                    /* props.isOldLpDetail  &&*/ (record.testStatus ===
                                      "new" &&
                                      props.testData.published === 0) ||
                                    props.isNewTest
                                      ? false
                                      : true
                                  }
                                  onKeyUp={(event) =>
                                    handleEnterKey(event, record)
                                  }
                                />
                              </div>
                              <div
                                className="adminTestSectionIconSec adminTestConfig"
                                onClick={(e) => {
                                  onExpand(record, e);
                                  setTopicExpandedKey([record.key]);
                                }}
                              >
                                <FontAwesomeIcon
                                  className="adminTestSectionExpandIcon"
                                  icon={faChevronRight}
                                />
                                {specificField !== 3 &&
                                /* props.isOldLpDetail &&*/ ((record.testStatus ===
                                  "new" &&
                                  props.testData.published === 0) ||
                                  props.isNewTest) ? (
                                  <svg
                                    width="12"
                                    height="12"
                                    viewBox="0 0 12 12"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    id="adminTestSectionTrashIcon"
                                    onClick={() =>
                                      handleDeleteSection(
                                        record.id,
                                        record.status
                                      )
                                    }
                                  >
                                    <path
                                      d="M5.02196 4.93363C5.29225 4.93363 5.5142 5.14057 5.53803 5.40465L5.54015 5.45182V8.74091C5.54015 9.02709 5.30815 9.25909 5.02196 9.25909C4.75167 9.25909 4.52972 9.05216 4.50589 8.78807L4.50377 8.74091V5.45182C4.50377 5.16563 4.73577 4.93363 5.02196 4.93363Z"
                                      fill="#F55533"
                                    />
                                    <path
                                      d="M7.73075 5.40465C7.70693 5.14057 7.48497 4.93363 7.21469 4.93363C6.9285 4.93363 6.6965 5.16563 6.6965 5.45182V8.74091L6.69862 8.78807C6.72244 9.05216 6.9444 9.25909 7.21469 9.25909C7.50087 9.25909 7.73287 9.02709 7.73287 8.74091V5.45182L7.73075 5.40465Z"
                                      fill="#F55533"
                                    />
                                    <path
                                      fillRule="evenodd"
                                      clipRule="evenodd"
                                      d="M7.21468 0C8.08291 0 8.79107 0.685314 8.82774 1.54451L8.82924 1.61455V2.19273H11.052C11.3381 2.19273 11.5701 2.42473 11.5701 2.71091C11.5701 2.9812 11.3632 3.20315 11.0991 3.22698L11.052 3.2291H10.4738V10.3855C10.4738 11.2537 9.78847 11.9618 8.92927 11.9985L8.85923 12H3.37742C2.50919 12 1.80103 11.3147 1.76436 10.4555L1.76287 10.3855V3.2291H1.18469C0.898504 3.2291 0.666504 2.9971 0.666504 2.71091C0.666504 2.44062 0.873442 2.21867 1.13753 2.19484L1.18469 2.19273H3.40741V1.61455C3.40741 0.746324 4.09272 0.0381621 4.95192 0.00149169L5.02196 0H7.21468ZM2.79924 3.2291V10.3855C2.79924 10.6888 3.03286 10.9376 3.33 10.9617L3.37742 10.9636H8.85923C9.16258 10.9636 9.41137 10.73 9.43549 10.4329L9.4374 10.3855V3.2291H2.79924ZM7.79286 2.19273H4.44378V1.61455L4.4457 1.56713C4.46982 1.26999 4.71861 1.03637 5.02196 1.03637H7.21468L7.2621 1.03829C7.55924 1.06241 7.79286 1.3112 7.79286 1.61455V2.19273Z"
                                      fill="#F55533"
                                    />
                                  </svg>
                                ) : (
                                  <span id="adminTestSectionTrashIcon"></span>
                                )}
                              </div>
                            </div>
                          ) : null)
                      )}
                    </>
                  ),
              }}
            />
          </DndProvider>
          {specificField !== 3 &&
          /* props.isOldLpDetail && (props.testData.status === 'old' ) && */ (props
            .testData.published === 0 ||
            props.isNewTest) ? (
            <p className="adminTestAddSectionBtn" onClick={handleAddSection}>
              + Add Section
            </p>
          ) : (
            <p className="adminTestAddSectionBtn"></p>
          )}
        </div>
        <div className="adminTestBtnSec">
          {/* <div className='adminTestSecondaryBtn'>
                        <ButtonMedium btnText2='Cancel' secondaryBtn={true} onHideSecondary={handleCancelTest} />
                    </div>
                    {(( props.testData.published === 0) || props.isNewTest) && <div>
                        <ButtonMedium btnText='Save' primaryBtn={true} onHide={handleSaveTest} />
                    </div>} */}
          {
            /* props.isOldLpDetail && (props.testData.status === 'old' ) && */ (props
              .testData.published === 0 ||
              props.isNewTest) && (
              <button className="adminTestNextBtn" onClick={handleSaveTest}>
                Save
              </button>
            )
          }
          <button className="adminTestCancelBtn" onClick={handleCancelTest}>
            Cancel
          </button>
        </div>
      </div>
      <Modal
        show={successRes.isSuccess}
        style={{
          fontFamily: "Inter",
          fontstyle: "normal",
          fontWeight: "700",
          fontSize: "14px",
          lineHeight: "16px",
        }}
        centered
        onHide={handleSuccessResponse}
      >
        <Modal.Header closeButton>
          <Modal.Title>Success</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ height: "50px" }}>{successRes.msg}</Modal.Body>
        <Modal.Footer style={{ margin: "10px", padding: "0px" }}>
          <Button
            variant="danger"
            style={{ margin: "10px 10px 0 0" }}
            onClick={handleSuccessResponse}
          >
            ok
          </Button>
        </Modal.Footer>
      </Modal>
      <QuestionList
        showQnList={showQnList}
        published={props.testData.published}
        listQnData={listQnData}
        handleCloseListPopup={handleCloseListPopup}
        handleAddQnsFromList={handleAddQnsFromList}
        isNewTest={props.isNewTest}
        setSelectedQns={setSelectedQns}
        selectedQns={selectedQns}
      />
    </>
  );
};

export default TestConfig;
