import "./Batch.scss";
import Card from "react-bootstrap/Card";
import constants from "../../../constants";
import BatchListView from "./ListView";
import "react-datepicker/dist/react-datepicker.css";
import React, { useEffect, useContext, useState, useMemo } from "react";
import AlertContext from "../../../context/AlertContext";
import ToggleButton from "../../CommonComponents/ToggleButton";
import _ from "lodash";
import useAxiosPrivate from "../../../Hooks/useAxiosPrivate";
import Loading from "../../Notification-Loading/Loading/Loading";
import { getDataFromStorage } from "../../../util";
import Button from "../../Button";
import BatchForm from "./BatchForm";

export default function Batches() {
  const {
    setShowNotify,
    isLoaded,
    setIsLoaded,
    isBatchDetails,
    navigate,
    batchGridView,
    setBatchGridView,
    setBatchNames,
  } = useContext(AlertContext);

  const axios = useAxiosPrivate();
  let role = useMemo(() => getDataFromStorage("role"), []);

  const [batches, setBatches] = useState([]);
  const [filterBatches, setFilterBatches] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [showPopUp, setShowPopUp] = useState(false);
  const [showArchived, setShowArchived] = useState(false);

  const handleBatchDetails = (batchid, batchdate) => {
    navigate(`/batches/${batchid}/users`);
  };

  useEffect(() => {
    const fetchBatchList = async () => {
      try {
        setIsLoaded(true);
        const url = `node/admin/batch?archived=${showArchived}${
          [constants.Roles.trainer, constants.Roles.hr].includes(role)
            ? `&role=${role}`
            : ""
        }`;
        const response = await axios.get(url, {
          headers: {
            "Content-type": "application/json",
          },
        });

        if (response.data && response.data.length > 0) {
          setBatches(response.data);
          setFilterBatches(response.data);
          setBatchNames(_.map(response.data, (batch) => batch.name));
          setSearchValue("");
        } else {
          setBatches([]);
          setShowNotify({
            show: true,
            title: "Warning !",
            msg: "There is no batch",
          });
        }
      } catch (error) {
        if (error.message.includes("403")) {
          setShowNotify({
            show: true,
            title: "Warning !",
            msg: "You have been logged-out due to inactivity. Login again.",
            isUnAuth: true,
          });
        } else {
          setShowNotify({
            show: true,
            title: "Error !",
            msg: "Something went wrong. Please try again later.",
          });
        }
      } finally {
        setIsLoaded(false);
      }
    };

    if (!showPopUp) {
      fetchBatchList();
    }
  }, [
    axios,
    isBatchDetails,
    showArchived,
    setShowNotify,
    setBatchNames,
    setIsLoaded,
    showPopUp,
    role,
  ]);

  const handleSearchBatch = (event) => {
    event.preventDefault();
    let inputValue = event.target.value.toLowerCase();
    setSearchValue(inputValue);
    let filtered = batches.filter((bat) =>
      bat.name.toLowerCase().includes(inputValue)
    );
    setFilterBatches(filtered);
  };

  return (
    <>
      <div className="adminBatchContainer">
        <div>
          <div className="addBatchBtn">
            <div style={{ display: "flex" }}>
              <input
                type="text"
                name="search"
                id="adminBatchSearchBox"
                placeholder="Search"
                onChange={handleSearchBatch}
                value={searchValue}
                className="adminBatchSearchBox"
              />
            </div>
            {getDataFromStorage("role") !== constants.Roles.hr && (
              <div className="allOptionsInBatch">
                <div className="viewOptions">
                  <div
                    className="listViewOfBatches"
                    onClick={() => {
                      setBatchGridView(false);
                    }}
                  >
                    {batchGridView ? (
                      <svg
                        className="disableBatchesListView"
                        width="18"
                        height="14"
                        viewBox="0 0 18 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M16.5 7.00033L6.5 7.00033M16.5 2.00033L6.5 2.00033M16.5 12.0003L6.5 12.0003M3.16667 7.00033C3.16667 7.46056 2.79357 7.83366 2.33333 7.83366C1.8731 7.83366 1.5 7.46056 1.5 7.00033C1.5 6.54009 1.8731 6.16699 2.33333 6.16699C2.79357 6.16699 3.16667 6.54009 3.16667 7.00033ZM3.16667 2.00033C3.16667 2.46056 2.79357 2.83366 2.33333 2.83366C1.8731 2.83366 1.5 2.46056 1.5 2.00033C1.5 1.54009 1.8731 1.16699 2.33333 1.16699C2.79357 1.16699 3.16667 1.54009 3.16667 2.00033ZM3.16667 12.0003C3.16667 12.4606 2.79357 12.8337 2.33333 12.8337C1.8731 12.8337 1.5 12.4606 1.5 12.0003C1.5 11.5401 1.8731 11.167 2.33333 11.167C2.79357 11.167 3.16667 11.5401 3.16667 12.0003Z"
                          stroke="#667085"
                          strokeWidth="1.4"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    ) : (
                      <svg
                        className="batchesListView"
                        width="18"
                        height="14"
                        viewBox="0 0 18 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M16.5 7.00033L6.5 7.00033M16.5 2.00033L6.5 2.00033M16.5 12.0003L6.5 12.0003M3.16667 7.00033C3.16667 7.46056 2.79357 7.83366 2.33333 7.83366C1.8731 7.83366 1.5 7.46056 1.5 7.00033C1.5 6.54009 1.8731 6.16699 2.33333 6.16699C2.79357 6.16699 3.16667 6.54009 3.16667 7.00033ZM3.16667 2.00033C3.16667 2.46056 2.79357 2.83366 2.33333 2.83366C1.8731 2.83366 1.5 2.46056 1.5 2.00033C1.5 1.54009 1.8731 1.16699 2.33333 1.16699C2.79357 1.16699 3.16667 1.54009 3.16667 2.00033ZM3.16667 12.0003C3.16667 12.4606 2.79357 12.8337 2.33333 12.8337C1.8731 12.8337 1.5 12.4606 1.5 12.0003C1.5 11.5401 1.8731 11.167 2.33333 11.167C2.79357 11.167 3.16667 11.5401 3.16667 12.0003Z"
                          stroke="#101828"
                          strokeWidth="1.4"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    )}
                  </div>
                  <div
                    className="gridViewOfBatches"
                    onClick={() => {
                      setBatchGridView(true);
                    }}
                  >
                    {batchGridView ? (
                      <svg
                        className="batchesGridView"
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.33333 1.5H1.5V7.33333H7.33333V1.5Z"
                          stroke="#101828"
                          strokeWidth="1.4"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M16.5 1.5H10.6667V7.33333H16.5V1.5Z"
                          stroke="#101828"
                          strokeWidth="1.4"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M16.5 10.6667H10.6667V16.5H16.5V10.6667Z"
                          stroke="#101828"
                          strokeWidth="1.4"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M7.33333 10.6667H1.5V16.5H7.33333V10.6667Z"
                          stroke="#101828"
                          strokeWidth="1.4"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    ) : (
                      <svg
                        className="disableBatchesGridView"
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.33333 1.5H1.5V7.33333H7.33333V1.5Z"
                          stroke="#667085"
                          strokeWidth="1.4"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M16.5 1.5H10.6667V7.33333H16.5V1.5Z"
                          stroke="#667085"
                          strokeWidth="1.4"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M16.5 10.6667H10.6667V16.5H16.5V10.6667Z"
                          stroke="#667085"
                          strokeWidth="1.4"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M7.33333 10.6667H1.5V16.5H7.33333V10.6667Z"
                          stroke="#667085"
                          strokeWidth="1.4"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    )}
                  </div>
                </div>
                <div className="archiveBatchSwitchDiv">
                  <ToggleButton
                    size="sm"
                    text="View Archive"
                    supportingText=""
                    pressed={showArchived}
                    theme="Dark"
                    disabled={false}
                    onChange={(e) => setShowArchived(!showArchived)}
                  />
                </div>
                <div id="addBatch">
                  <Button
                    size="sm"
                    hierarchy={{
                      type: "secondaryGrey",
                      buttonText: "New Batch",
                    }}
                    icon={{
                      type: "leading",
                      icon: (
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M10.0013 4.16699V15.8337M4.16797 10.0003H15.8346"
                            stroke="#344054"
                            strokeWidth="1.67"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      ),
                    }}
                    onClick={() => setShowPopUp(true)}
                  />
                </div>
              </div>
            )}
          </div>
          {showPopUp && (
            <BatchForm showPopUp={showPopUp} setShowPopUp={setShowPopUp} />
          )}
        </div>
        {batchGridView ? (
          <div className="adminBatchCardContainer">
            {_.map(filterBatches, (batch) => (
              <Card
                key={batch.id}
                id="adminBatchCard"
                onClick={() => handleBatchDetails(batch.id, batch.startdate)}
              >
                <Card.Img
                  variant="top"
                  id="adminBatchImg"
                  src={batch.thumbnail}
                />
                <Card.Body className="adminBatchCardBody">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div className="adminBatchName">{batch.name}</div>
                    <div className="adminBatchStartDate">
                      Start Date :{" "}
                      {(new Date(batch.startdate).getDate() > 9
                        ? new Date(batch.startdate).getDate()
                        : `0${new Date(batch.startdate).getDate()}`) +
                        "/" +
                        (new Date(batch.startdate).getMonth() + 1 > 9
                          ? new Date(batch.startdate).getMonth() + 1
                          : `0${new Date(batch.startdate).getMonth() + 1}`) +
                        "/" +
                        new Date(batch.startdate).getFullYear()}
                    </div>
                  </div>
                  <Card.Text id="adminBatchDes" title={batch.description}>
                    {batch.description
                      ? batch.description.length > 100
                        ? batch.description.substring(0, 100) + "..."
                        : batch.description.substring(0, 100)
                      : ""}
                  </Card.Text>
                </Card.Body>
              </Card>
            ))}
          </div>
        ) : (
          <div className="adminBatchTableContainer">
            <BatchListView
              data={filterBatches}
              showArchived={showArchived}
              handleBatchDetails={handleBatchDetails}
              from="batch"
            />
          </div>
        )}
      </div>
      {isLoaded && <Loading />}
    </>
  );
}
