import axios from '../api/axios';
import useAuth from './useAuth';

const useRefreshToken = () => {
    const { setAuth } = useAuth();

    const refresh = async () => {
        // try {
            const response = await axios.post('node/auth/accesstoken/new',{refreshToken:localStorage.getItem("refreshToken")}, {
                headers:{"Content-Type":'application/json',Authorization:"abdjcdifouvn" },
                // withCredentials: true
            });
            setAuth(prev => {
                return {
                    ...prev,
                    token: response.data.accessToken
                }
            });
            return response.data.accessToken;
        // } catch (err) {
        //    return err;
        // }
    }
    return refresh;
};

export default useRefreshToken;