import constants from "../../../constants";
import { getDataFromStorage } from "../../../util";
import SandPackEditorTools from "./SandPackEditorTools";
import AlertContext from "../../../context/AlertContext";
import useAxiosPrivate from "../../../Hooks/useAxiosPrivate";
import { SandpackProvider } from "@codesandbox/sandpack-react";
import Loading from "../../Notification-Loading/Loading/Loading";
import React, { memo, useContext, useEffect, useState, useRef } from "react";
import Profile from "../Home/LandingPage/Profile";
import HeaderHooks from "../Header/Hooks/HeaderHooks";
import useSubmitAssignment from "../../../Hooks/useSubmitAssignment";
import "../../utils/sandpackEditor.css";
import SandpackReactEditorTools from "./SandpackReactEditorTools";

export default memo(function SandpackReactEditor(Props) {
  const {
    isLoaded,
    lpConfigId,
    isFullScreensp,
    openSandpackEditor,
    setOpenSandpackEditor,
    isTakeTest,
    setMsg,
    setShow,
    setTitle,
    camAvailable,
    setCamAvailable,
    navigate,
    setIsAddSubmission,
    setIsEditSubmission,
    isAdmin,
    setIsLoaded,
    sandpackFiles,
    testData,
    contentData,
    setContentData,
    learnerNotes,
    lpId,
    setIsSubmitted,
    setLpData,
    setIsUnAuth,
  } = useContext(AlertContext);
  const axios = useAxiosPrivate();
  const focusOutTimer = useRef(null);
  const focusOutSec = useRef(0);

  const submitAssignment = useSubmitAssignment();

  console.log("react template")

  const closeAssignment = async () => {
    try {
      setIsLoaded(true);
      let response = await submitAssignment(
        {
          lpId,
          libId: testData.libid,
          file: {
            name: `${getDataFromStorage("learnerid")}.json`,
            file: sandpackFiles,
            contentType: "json",
          },
          lpConfigId: testData.lpconfigid,
          learnerNotes: learnerNotes ? learnerNotes : "",
          template: contentData.template,
          isSave: false,
        },
        true
      );

      if (response.data.resultCode !== 1000) {
        setShow(true);
        setTitle("Error");
        setMsg(
          response.data === ""
            ? "Something went wrong. Please try again later"
            : response.data.message
        );
      } else {
        let newFiles = [],
          filePath = "";
        if (process.env.REACT_APP_DEPLOYMENT === "AWS")
          filePath = `SandpackFiles/library/submission/${
            testData.libid
          }/${getDataFromStorage("learnerid")}.json`;
        else
          filePath = `library/submission/${getDataFromStorage(
            "learnerid"
          )}/${getDataFromStorage("learnerid")}.json`;

        newFiles.push(filePath);

        let assignmentData = JSON.parse(JSON.stringify(contentData));
        assignmentData.commentsdata = [];
        assignmentData.anslinks = JSON.stringify(newFiles);
        assignmentData.answer = learnerNotes;
        assignmentData.submitteddate = new Date();
        setContentData(assignmentData);
        // need to look
        // setStatus(
        //   isSave
        //     ? constants.PROGRESS_STATUS.TO_BE_EVALUATED
        //     : constants.PROGRESS_STATUS.IN_PROGRESS
        // );
        setIsSubmitted(true);
        setIsEditSubmission(false);
        setIsAddSubmission(false);
        // if (isFullScreensp) {
        //   document.exitFullscreen();
        //   setIsFullScreensp(false);
        // }
        setLpData({});
        // if(isSave){
        navigate("/home");
        // }
      }
    } catch (err) {
      setShow(true);
      setTitle("Warning");

      if (err.hasOwnProperty("message") && err.message.includes("403")) {
        setIsUnAuth(true);
        setMsg("You have been logged-out due to inactivity. Login again..");
      } else setMsg(err.message); //else setMsg("Something went wrong. Please try again later");
    } finally {
      setIsLoaded(false);
    }
  };

  const onFocus = () => {
    focusOutSec.current = 0;
    clearInterval(focusOutTimer.current);
    window.removeEventListener("blur", onBlur);
  };

  const onBlur = () => {
    // tabSwitchCount.current = tabSwitchCount.current + 1;

    focusOutTimer.current = setInterval(() => {
      let second = focusOutSec.current;
      // || tabSwitchCount.current === 10
      if (second === 10) {
        clearInterval(focusOutTimer.current);
        // call new api close assignment
        closeAssignment();
      } else {
        second++;
        focusOutSec.current = second;
      }
    }, 1000);
  };

  useEffect(() => {
    if (!isAdmin && Props.status !== constants.PROGRESS_STATUS.EVALUATED && testData.proctored !==constants.PROCTORING_MODE.NONE) {
      window.addEventListener("blur", onBlur);
      window.addEventListener("focus", onFocus);
      return () => {
        window.removeEventListener("blur", onBlur);
        window.removeEventListener("focus", onFocus);
      };
    }
  }, [onBlur]);

  // useEffect(() => {
  //   console.log("isTakeTest", isTakeTest);
  //   if (document.documentElement.requestFullscreen) {
  //     document.documentElement.requestFullscreen();
  //   } else if (document.documentElement.mozRequestFullScreen) {
  //     document.documentElement.mozRequestFullScreen();
  //   } else if (document.documentElement.webkitRequestFullscreen) {
  //     document.documentElement.webkitRequestFullscreen();
  //   } else if (document.documentElement.msRequestFullscreen) {
  //     document.documentElement.msRequestFullscreen();
  //   }
  //   return () => {
  //     if (document.fullscreenElement) {
  //       document.exitFullscreen();
  //     } else if (document.mozFullScreenElement) {
  //       document.mozCancelFullScreen();
  //     } else if (document.webkitFullscreenElement) {
  //       document.webkitExitFullscreen();
  //     } else if (document.msFullscreenElement) {
  //       document.msExitFullscreen();
  //     }
  //   };
  // }, []);

  // useEffect(() => {
  //   const handleFullscreenChange = () => {
  //     if (!document.fullscreenElement) {
  //       console.log("Exited fullscreen mode");
  //       if (isAdmin) {
  //         navigate("/assignment");
  //       } else {
  //         console.log("closing.........");
  //         if (Props.status !== constants.PROGRESS_STATUS.EVALUATED) {
  //           closeAssignment();
  //         } else {
  //           navigate("/home");
  //         }
  //       }
  //     } else {
  //       console.log("Entered fullscreen mode");
  //     }
  //   };
  //   // Attach the fullscreen change event listener
  //   document.addEventListener("fullscreenchange", handleFullscreenChange);
  //   // Cleanup event listener when component unmounts
  //   return () => {
  //     document.removeEventListener("fullscreenchange", handleFullscreenChange);
  //   };
  // }, []);

  useEffect(() => {

    const handleFullscreenChange = () => {
      if (!document.fullscreenElement) {
        console.log("Exited fullscreen mode");
        if (isAdmin) {
          navigate("/assignment");
        } else {
          console.log("closing.........");
          if (Props.status !== constants.PROGRESS_STATUS.EVALUATED && testData.proctored!==constants.PROCTORING_MODE.NONE) {
            setTimeout(async ()=>{
            console.log("before calling close assignment........")
            await closeAssignment();
          },1000)
          } else if(testData.proctored!==constants.PROCTORING_MODE.NONE) {
            navigate("/home");
          }
        }
      } else {
        console.log("Entered fullscreen mode");
      }
    };
    
    document.addEventListener("fullscreenchange", handleFullscreenChange);
    return () => {
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
    };
  }, []);

  useEffect(() => {
    // When component mounts

    let role = getDataFromStorage("role");

    if (
      role === constants.Roles.learner &&
      ![
        constants.PROGRESS_STATUS.SUBMITTED &&
          constants.PROGRESS_STATUS.TO_BE_EVALUATED,
      ].includes(Props.status)
    ) {
      const newStartTime = Date.now();

      let storedTime;
      let learnerId = getDataFromStorage("learnerid");

      async function duration() {
        // storedTime = localStorage.getItem('timeSpent');

        let response = await axios.get(
          `node/learner/content/timespent/${learnerId}/${lpConfigId}`,
          {
            headers: {
              "Content-type": "application/json",
            },
          }
        );

        storedTime = response?.data?.data?.duration;
        storedTime = storedTime * 1000 * 60;
      }

      duration();

      // When component unmounts

      return () => {
        async function save() {
          const endTime = Date.now();

          let timeSpent =
            endTime - newStartTime + (storedTime ? storedTime : 0);
          timeSpent = Math.round(timeSpent / (60 * 1000));

          let learnerId = getDataFromStorage("learnerid");
          let requestData = {
            duration: timeSpent,
            learnerid: learnerId,
            lpconfigid: lpConfigId,
          };

          // localStorage.setItem('timeSpent', timeSpent.toString());

          await axios.post(`node/learner/content/timespent`, requestData, {
            headers: {
              "Content-Type": "application/json",
            },
          });
        }

        save();
      };
    }
  }, []);

  return (
    <div
      className={
        isFullScreensp ? "sandpackEditorContainer1" : "sandpackEditorContainer2"
      }
    >
      {/* {!isAdmin && Props.status !== constants.PROGRESS_STATUS.EVALUATED && testData.proctored == constants.PROCTORING_MODE.TAB_CAM && (
        <Profile
          isTakeTest={isTakeTest}
          questionid={testData.libid}
          libtypeid={constants.libTypeIds.ASSIGNMENT}
        />
      )} */}
      {isLoaded && <Loading />}
      <SandpackProvider
        template={Props.selectedTemplate?.value?.toLowerCase() ?? "react"}
        options={{
          autorun: true,
          autoReload: true,
          showConsole: true,
        }}
      >
        <SandpackReactEditorTools
          status={Props.status}
          learnerId={Props.learnerId}
          libDetails={Props.libDetails}
          isqueReport={Props.isqueReport}
          template={Props.selectedTemplate}
          isAddSubmission={Props.isAddSubmission}
          openSandpackEditor={openSandpackEditor}
          setOpenSandpackEditor={setOpenSandpackEditor}
        />
      </SandpackProvider>
    </div>
  );
});
