import "./Content.scss";
import { nanoid } from "nanoid";
import { constant } from "lodash";
import Editor from "./ContentEditor";
import { useEffect, useState, memo,useContext } from "react";
import Scrollbars from "react-custom-scrollbars-2";

import AlertContext from "../../context/AlertContext";

//import { getMyMode,setMyMode } from "./editor/State";
//import EditorTextParser from "./editor-parser/EditorTextParser";

function Content(Props) {
  const [data, setData] = useState({});
  const [isEditMode, setIsEditMode] = useState(false);
  const [buttonData, setButtonData] = useState("Click To Changes");

  const {isAdmin} = useContext(AlertContext)

  //const [mode,setMode]= useState(false);
  // const [readMode, setReadMode] = useState(false);

  //var Readmode;

  // useEffect(()=>{
  //   setData(Props.content);

  // })

  // function toggleEditMode() {
  // //setMode(!mode)
  //   if (isEditMode) {
  //   setIsEditMode(false);
  // //   setMyMode();
  //   //   //setReadMode(false);
  //   setButtonData("Click To Changes");
  //   }
  //    else {
  //      setIsEditMode(true);
  //   //   //setReadMode(true)
  //    setButtonData("Click To Save");
  // //   setMyMode();
  //   // }
  //    }
  // //if(getMyMode()){

  // 	//setButtonData("Click To Save");

  // //}
  // // else{
  // // 	setButtonData("Click To Change");
  // // }
  // // setMyMode();

  // }
  //const targetelement = document.getElementsByClassName("ce-toolbar");


  //tooltip.show(targetelement,"HIII");

  // useEffect(() => {
  //   Props.setNewContent(data);
  // }, [data]);
  //<EditorTextParser data={data}/>

  return (
    <div className="Content" style={{ fontFamily: "Inter" }}>
      <div className={!Props.Mode   ? isAdmin ? "student-content" : "student-content1" : "admin-content"}>
        {/* <Scrollbars
        autoHide
        key={nanoid()}
        style={{ width: "100%", height: "100%" }}
      > */}
        {/* {isEditMode? Readmode=false: Readmode=true} */}

        {/* {!isEditMode ? */}
        <Editor
          data={data}
          setData={setData}
          Mode={Props.Mode}
          isNext={Props.isNext}
          UserInput={Props.UserInput}
          setuserinp={Props.setuserinp}
          isqueReport={Props.isqueReport}
          setNewContent={Props.setNewContent}

          // mode={true}
          // Content = {Props.Content}
        />
        {/* </Scrollbars> */}

        {/* <Editor */}
        {/*/ data={data}
          // setData={setData}

  // mode={false}/> */}
      </div>
    </div>
  );
}

export default memo(Content);

// <EditorTextParser data={data}/>
