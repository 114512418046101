import { useContext } from "react";
import constants from "../../constants";
import useAxiosPrivate from "../useAxiosPrivate";
import AlertContext from "../../context/AlertContext";
import StudentTestPageHooks from "./StudentTestPageHooks";
import useCommonFunctionHooks from "../useCommonFunctionHooks";
import { getPreSignedUrl, getDataFromStorage } from "../../util";
import StudentTestPageCalcStatsHooks from "./StudentTestPageCalcStatsHooks";

export default function StudentTestPageAnswerHooks() {
  const Alert = useContext(AlertContext);

  const axios = useAxiosPrivate();
  const { calcStats } = StudentTestPageCalcStatsHooks();
  const { handleVideoQuestions } = StudentTestPageHooks();
  const { handleClose, getHeaderDetailsForAxios, getCatchBlockDetails } =
    useCommonFunctionHooks();

  let tempScoresObj = {};

  const handleShowAnsforPrac = () => {
    Alert.setIsFullScreen(false);
    handleClose();

    if (document.fullscreenElement) {
      document.exitFullscreen();
    }

    axios
      .post(
        "node/learner/course/test/report",
        JSON.stringify({
          lpid: Alert.mcqAndDesTestAndLpId.lpid,
          testid: Alert.mcqAndDesTestAndLpId.testid,
          learnerid: getDataFromStorage("learnerid"),
          libtypeid: Alert.mcqAndDesTestAndLpId.libtypeid,
          lpconfigid: Alert.mcqAndDesTestAndLpId.lpconfigid,
        }),
        getHeaderDetailsForAxios()
      )
      .then((res) => {
        Alert.setButtonDisable(false);
        Alert.hasRendered.current = false;
        Alert.backupTestDataq.current = res.data.data;

        (async () => {
          try {
            if (res.data.resultCode !== constants.RESULT_STATUS.SUCCESS) {
              Alert.setShowNotify({
                show: true,
                title: "Info",
                msg: res.data.message,
              });
            } else {
              Alert.setStatus(constants.PROGRESS_STATUS.EVALUATED);
              Alert.setMcqAndDesTestAndLpId({
                lpid: Alert.mcqAndDesTestAndLpId.lpid,
                testid: Alert.mcqAndDesTestAndLpId.testid,
                learnerid: getDataFromStorage("learnerid"),
                status: constants.PROGRESS_STATUS.EVALUATED,
                testmode: Alert.mcqAndDesTestAndLpId.testmode,
                viewTest: Alert.mcqAndDesTestAndLpId.viewTest,
                autoEval: Alert.mcqAndDesTestAndLpId.autoEval,
                libtypeid: Alert.mcqAndDesTestAndLpId.libtypeid,
                proctored: Alert.mcqAndDesTestAndLpId.proctored,
                configdata: Alert.mcqAndDesTestAndLpId.configdata,
                lpconfigid: Alert.mcqAndDesTestAndLpId.lpconfigid,
              });

              let tempReport = res.data.data;

              if (tempReport.videoqn === 1) {
                handleVideoQuestions(tempReport.sections);
              }

              for (let i = 0; i < res.data.data.sections.length; i++) {
                for (
                  let j = 0;
                  j < res.data.data.sections[i].questions.length;
                  j++
                ) {
                  if (process.env.REACT_APP_DEPLOYMENT === "AWS") {
                    let question = res.data.data.sections[i].questions[j];

                    if (getDataFromStorage("role") === "learner") {
                      question.review = -1;
                    }

                    for (let k = 0; k < question.links.length; k++) {
                      let linkRes = await getPreSignedUrl(
                        question.links[k].link
                      );

                      question.links[k].link = linkRes;
                    }
                  }
                }
              }

              calcStats(tempReport, ["all"]);
              Alert.setTestMode(res.data.data.testmode);
              Alert.setApiLoaded(true);
              Alert.backupTestDataq.current = res.data.data;
              Alert.setDescScores(tempScoresObj);
              Alert.setBackupTestData(res.data.data);
              Alert.setMcqTestData(res.data.data);
              Alert.updateComingObj(res.data.data);
              Alert.updateQuestionContainerSingle(false);
              clearInterval(Alert.interval.current);
              Alert.setTestPageShow(true);
            }
          } catch (err) {
            getCatchBlockDetails(err);
          } finally {
            Alert.setIsLoaded(false);
          }
        })();
      })
      .catch((error) => {
        Alert.setButtonDisable(false);
        getCatchBlockDetails(error);
      })
      .finally(() => Alert.setIsLoaded(false));
  };
  return { handleShowAnsforPrac };
}
