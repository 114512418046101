import React, { useState,useContext } from "react";
import { useNavigate } from "react-router-dom";
import useAxiosPrivate from "../../../../../Hooks/useAxiosPrivate";
import AlertContext from "../../../../../context/AlertContext";
import { getDataFromStorage } from "../../../../../util";
function MainDropDown(Props) {
  const axios = useAxiosPrivate();
  const navigate = useNavigate();
  const {currentEditDashboardMetaData,setCurrentEditDashboardMetaData,isDashBoardEdit,setIsDashBoardEdit} = useContext(AlertContext);

  const [dashBoardName, setDashboardName] = useState(Props.data.name);
  
  const handleEditDashBoard = (id)=>{

    const responseOfEditDashboard = async() =>{
      let response =  await axios.get("node/admin/dashboard/"+id,
      {
        headers:{
          "Content-Type": "application/json",
  
        }
  
      }
    );
     
    console.log("response>>>editable dashboard",response.data.data);
    setCurrentEditDashboardMetaData(response?.data?.data);
    setIsDashBoardEdit(true);
    navigate("/dashboardadd")




    };

    responseOfEditDashboard();

  }

  const handleCopyDashboard = (id) => {
    const responseAfterCopyingDashboard = async () => {
      let response = await axios.patch("node/admin/dashboard/" + id + "/copy", {
        headers: {
          "Content-Type": "application/json",
        },
      });
      // getDashboards();

      console.log("response>>>Deleteable dashboard", response?.data?.data);
    };
    responseAfterCopyingDashboard();
  };







  return (
    <div className="mainDropDown">
      <div className="dashBoardNameindropdown">
        <span className="dashboardNameText">{dashBoardName}</span>
      </div>
      <div className="optionContainer" onClick={()=>{handleCopyDashboard(Props.data.id)}}>
        <p className="option">Copy</p>
        <span className="iconsvgmenu">
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_1025_15559)">
            <path
              d="M5.25 1.0014C4.91247 1.00597 4.70985 1.02551 4.54601 1.10899C4.35785 1.20487 4.20487 1.35785 4.10899 1.54601C4.02551 1.70985 4.00597 1.91247 4.0014 2.25M9.75 1.0014C10.0875 1.00597 10.2902 1.02551 10.454 1.10899C10.6422 1.20487 10.7951 1.35785 10.891 1.54601C10.9745 1.70985 10.994 1.91247 10.9986 2.24999M10.9986 6.74999C10.994 7.08752 10.9745 7.29015 10.891 7.45399C10.7951 7.64215 10.6422 7.79513 10.454 7.89101C10.2902 7.97449 10.0875 7.99403 9.75 7.9986M11 4V5M7.00003 1H7.99998M2.6 11H6.4C6.96005 11 7.24008 11 7.45399 10.891C7.64215 10.7951 7.79513 10.6422 7.89101 10.454C8 10.2401 8 9.96005 8 9.4V5.6C8 5.03995 8 4.75992 7.89101 4.54601C7.79513 4.35785 7.64215 4.20487 7.45399 4.10899C7.24008 4 6.96005 4 6.4 4H2.6C2.03995 4 1.75992 4 1.54601 4.10899C1.35785 4.20487 1.20487 4.35785 1.10899 4.54601C1 4.75992 1 5.03995 1 5.6V9.4C1 9.96005 1 10.2401 1.10899 10.454C1.20487 10.6422 1.35785 10.7951 1.54601 10.891C1.75992 11 2.03995 11 2.6 11Z"
              stroke="#344054"
              stroke-width="1.2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </g>
          <defs>
            <clipPath id="clip0_1025_15559">
              <rect width="12" height="12" fill="white" />
            </clipPath>
          </defs>
        </svg>
        </span>
      </div>
     { Props.data.created_by == getDataFromStorage("learnerid") ? <div className="optionContainer" style={{cursor:"pointer"}} onClick={()=>handleEditDashBoard(Props.data.id)}>
        <p className="option">Edit</p>
        <span className="iconsvgmenu">
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_1025_15568)">
            <path
              d="M5.5 1.99999H3.4C2.55992 1.99999 2.13988 1.99999 1.81901 2.16348C1.53677 2.30729 1.3073 2.53676 1.16349 2.81901C1 3.13987 1 3.55991 1 4.39999V8.59999C1 9.44007 1 9.86011 1.16349 10.181C1.3073 10.4632 1.53677 10.6927 1.81901 10.8365C2.13988 11 2.55992 11 3.4 11H7.6C8.44008 11 8.86012 11 9.18099 10.8365C9.46323 10.6927 9.6927 10.4632 9.83651 10.181C10 9.86011 10 9.44007 10 8.59999V6.49999M3.99999 7.99999H4.83726C5.08185 7.99999 5.20414 7.99999 5.31923 7.97236C5.42127 7.94786 5.51881 7.90746 5.60828 7.85263C5.7092 7.79079 5.79568 7.70431 5.96863 7.53136L10.75 2.74999C11.1642 2.33578 11.1642 1.66421 10.75 1.24999C10.3358 0.835778 9.66421 0.835777 9.25 1.24999L4.46862 6.03136C4.29567 6.20431 4.20919 6.29079 4.14735 6.39171C4.09252 6.48118 4.05211 6.57872 4.02762 6.68076C3.99999 6.79585 3.99999 6.91814 3.99999 7.16273V7.99999Z"
              stroke="#344054"
              stroke-width="1.2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </g>
          <defs>
            <clipPath id="clip0_1025_15568">
              <rect width="12" height="12" fill="white" />
            </clipPath>
          </defs>
        </svg>
        </span>
      </div> : null}
      <div className="optionContainer">
        <p className="option">Star</p>
        <span className="iconsvgmenu">
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.64133 1.72666C5.75657 1.49319 5.81419 1.37645 5.89241 1.33916C5.96047 1.30671 6.03954 1.30671 6.1076 1.33916C6.18583 1.37645 6.24345 1.49319 6.35869 1.72666L7.45203 3.94164C7.48605 4.01057 7.50306 4.04503 7.52792 4.07179C7.54994 4.09548 7.57633 4.11467 7.60566 4.12831C7.63878 4.14371 7.6768 4.14927 7.75286 4.16039L10.1985 4.51785C10.456 4.5555 10.5848 4.57432 10.6444 4.63722C10.6962 4.69195 10.7206 4.76715 10.7108 4.84189C10.6994 4.92779 10.6062 5.01859 10.4197 5.20019L8.65072 6.92321C8.59558 6.97692 8.56801 7.00378 8.55021 7.03573C8.53446 7.06402 8.52435 7.09511 8.52046 7.12725C8.51605 7.16356 8.52256 7.2015 8.53557 7.27737L8.95298 9.71106C8.997 9.96774 9.01902 10.0961 8.97765 10.1722C8.94166 10.2385 8.87768 10.285 8.80353 10.2987C8.71831 10.3145 8.60306 10.2539 8.37256 10.1327L6.18619 8.98292C6.11807 8.94709 6.08401 8.92918 6.04812 8.92214C6.01635 8.91591 5.98367 8.91591 5.9519 8.92214C5.91601 8.92918 5.88195 8.94709 5.81383 8.98292L3.62746 10.1327C3.39696 10.2539 3.28171 10.3145 3.19649 10.2987C3.12234 10.285 3.05836 10.2385 3.02237 10.1722C2.981 10.0961 3.00301 9.96774 3.04704 9.71106L3.46444 7.27737C3.47746 7.2015 3.48396 7.16356 3.47956 7.12725C3.47566 7.09511 3.46556 7.06402 3.4498 7.03573C3.43201 7.00378 3.40444 6.97692 3.34929 6.92321L1.58028 5.20019C1.39383 5.01859 1.30061 4.92779 1.28926 4.84189C1.27939 4.76715 1.30378 4.69195 1.35563 4.63722C1.41522 4.57432 1.54399 4.5555 1.80152 4.51785L4.24716 4.16039C4.32321 4.14927 4.36124 4.14371 4.39436 4.12831C4.42368 4.11467 4.45008 4.09548 4.47209 4.07179C4.49695 4.04503 4.51396 4.01057 4.54799 3.94164L5.64133 1.72666Z"
            stroke="#344054"
            stroke-width="1.2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        </span>
      </div>
    </div>
  );
}

export default MainDropDown;
