import "./LanguageInput.scss";
import Btn from "react-bootstrap/Button";
import constant from "../../../../../constants";
import React, { useState, useEffect } from "react";
import {TextareaAutosize} from "@mui/base/TextareaAutosize";
import LanguageInputHooks from "./Hooks/LanguageInputHooks";
import CodingQuestionEntryHooks from "../Hooks/CodingQuestionEntryHooks";

import {
  generateBoilerPlateCCode,
  generateBoilerPlateJSCode,
  generateBoilerPlateCPPCode,
  generateBoilerPlateJavaCode,
  generateBoilerPlatePythonCode,
} from "../../../../../util";

export default function LanguageInput(Props) {
  const {
    inVaTypes,
    setSelectedLanguage,
    setEnableSaveButton,
    setLanguagesOfPassedTestCases,
  } = CodingQuestionEntryHooks();

  const {
    langId,
    active,
    setSCode,
    setECode,
    setBCode,
    setActive,
    setLangId,
    handleFocus,
    setInVaTypeRef,
    setPlatesChange,
    setShowTestButton,
  } = LanguageInputHooks();

  let [masterLanguageData, setMasterLanguageData] = useState(
    Props.isqueReport ? Props.selectque.qcodes : {}
  );

  const [dataSource, setDataSource] = useState(
    Props.isqueReport ? Props.inputDataSource : []
  );

  const handleBplate = (e, langid) => {
    let updatedBoilerPlate = { ...Props.bplateCode };

    updatedBoilerPlate[active.label] = e.target.value;
    Props.setBplateCode(updatedBoilerPlate);
  };

  const handleChange = (e, activeid) => {
    if (Props.languagesOfPassedTestCases.includes(active.label)) {
      let temp = Props.languagesOfPassedTestCases;

      temp = temp.filter((lang) => lang !== active.label);
      setLanguagesOfPassedTestCases(temp);
      setEnableSaveButton(false);
    }

    setPlatesChange(false);
    e.preventDefault();

    let temp = {};

    temp = masterLanguageData;
    setMasterLanguageData({});
    temp[active.label] = {
      ...temp[active.label],
      [e.target.name]: e.target.value,
      languageid: langId,
      ["boilerplateCode"]: Props.bplateCode[active.label],
    };

    setBCode(Props.bplateCode[active.label]);
    setECode(temp[active.label].executionCode);
    setSCode(temp[active.label].solutionCode);
    setMasterLanguageData(temp);
  };

  const testIoData = (e) => {
    e.preventDefault();
    Props.setIsTest(true);
    Props.onhandleSubmit(masterLanguageData, active.label, langId);
  };

  const handleBoilerPlateCodeGeneration = () => {
    let columns;
    let dataType;
    let variables;
    let boilerPlateCode = { ...Props.bplateCode };
    let tMaster = JSON.parse(JSON.stringify(Props.masterData));

    const langInputLen = Props.LangInputs.length;

    if (langInputLen > 0) {
      dataType = Props.LangInputs.map((ip) => ip.vartype);
      variables = Props.LangInputs.map((ip) => ip.varname);
      columns = Props.LangInputs.map((ip) => ip.col);
    } else {
      dataType = dataSource.map((ip) => ip.vartype);
      variables = dataSource.map((ip) => ip.varname);
      columns = dataSource.map((ip) => parseInt(ip.col));
    }

    if (langId === constant.LANG_ID.Javascript) {
      boilerPlateCode.Javascript = generateBoilerPlateJSCode(
        Props.functionname,
        dataType,
        variables,
        columns,
        Props.returntype,
        Props.isNoTestCases
      );
      tMaster[active.label] = {
        ...tMaster[active.label],
        ["boilerplateCode"]: boilerPlateCode.Javascript,
      };
    } else if (langId === constant.LANG_ID.Python) {
      boilerPlateCode.Python = generateBoilerPlatePythonCode(
        Props.functionname,
        dataType,
        variables,
        columns,
        Props.returntype,
        Props.isNoTestCases
      );
      tMaster[active.label] = {
        ...tMaster[active.label],
        ["boilerplateCode"]: boilerPlateCode.Javascript,
      };
    } else if (langId === constant.LANG_ID.Java) {
      boilerPlateCode.Java = generateBoilerPlateJavaCode(
        Props.functionname,
        dataType,
        variables,
        columns,
        Props.returntype,
        Props.isNoTestCases
      );
      tMaster[active.label] = {
        ...tMaster[active.label],
        ["boilerplateCode"]: boilerPlateCode.Javascript,
      };
    } else if (langId === constant.LANG_ID.C) {
      boilerPlateCode.C = generateBoilerPlateCCode(
        Props.functionname,
        dataType,
        variables,
        columns,
        Props.returntype,
        Props.isNoTestCases
      );
      tMaster[active.label] = {
        ...tMaster[active.label],
        ["boilerplateCode"]: boilerPlateCode.Javascript,
      };
    } else if (langId === constant.LANG_ID.Cpp) {
      boilerPlateCode.CPP = generateBoilerPlateCPPCode(
        Props.functionname,
        dataType,
        variables,
        columns,
        Props.returntype,
        Props.isNoTestCases
      );
      tMaster[active.label] = {
        ...tMaster[active.label],
        ["boilerplateCode"]: boilerPlateCode.Javascript,
      };
    }

    Props.setBplateCode(boilerPlateCode);

    let temp = {};

    temp = masterLanguageData;
    setMasterLanguageData({});
    temp[active.label] = {
      ...temp[active.label],
      languageid: langId,
      ["boilerplateCode"]: boilerPlateCode[active.label],
    };
    setBCode(Props.bplateCode[active.label]);
    setECode(temp[active.label].executionCode);
    setSCode(temp[active.label].solutionCode);
    setMasterLanguageData(temp);
  };

  useEffect(() => {
    setInVaTypeRef(inVaTypes);
  }, [inVaTypes]);

  useEffect(() => {
    setInVaTypeRef(inVaTypes);
  }, []);

  // useEffect(() => {
  //   if (Props.isqueReport) {
  //     setMasterLanguageData(Props.selectque.qcodes);
  //   }
  // });

  useEffect(() => {
    if (Props.languageId.length === 0) {
      setShowTestButton(false);
    }

    let selectCode = Props.selectCode;

    if (selectCode.length > 0) {
      setLangId(selectCode[0].value);
      setActive(selectCode[0]);
    }
  }, [Props.selectCode]);

  useEffect(() => {
    let solutionInputval = "";
    let boilerPlateInputval = "";
    let solution = document.getElementById("questionSolutionCode");
    let boilerPlate = document.getElementById("questionBoilerplate");

    if (boilerPlate) {
      boilerPlateInputval = boilerPlate.value;
    }

    if (solution) {
      solutionInputval = solution.value;
    }

    if (boilerPlateInputval === "") {
      setShowTestButton(false);
    } else if (solutionInputval === "") {
      setShowTestButton(false);
    } else {
      setShowTestButton(true);
    }
  }, [handleChange]);

  useEffect(() => {
    if(Props.languageId.length>0){
      let temp1 = {};
      let temp = masterLanguageData;
  
      for (let i = 0; i < Props.languageId.length; i++) {
        let keys = Object.keys(temp);
  
        for (let j = 0; j < keys.length; j++) {
          if (Props.languageId[i] === temp[keys[j]].languageid) {
            temp1[keys[j]] = temp[keys[j]];
            temp1[keys[j]].boilerplateCode = Props.bplateCode[keys[j]];
          }
        }
      }
  
      Props.gettingLanguagesData(temp1);
      setMasterLanguageData(temp1);
      setBCode("");
      setECode("");
      setSCode("");
    }
  }, [Props.languageId]);

  return (
    <div>
      {!Props.show && (
        <div className="langTaps">
          <div className="languages">
            {Props.selectCode?.map((list, index) => (
              <div
                id={list.value}
                key={list.value}
                onClick={() => {
                  setActive(list);
                  setLangId(list.value);
                  setSelectedLanguage(list.label);
                }}
                className={
                  active !== null
                    ? `langLists ${active.value === list.value && "active"}`
                    : `langLists ${
                        Props.selectCode[0].label === list.label && "active"
                      }`
                }
              >
                {list.label}
              </div>
            ))}
          </div>
          <div className="langLine"></div>
          <div key={langId}>
            <div className="boilerplateCode">
              <div className="labelAndBoilerplate">
                <label htmlFor="questionBoilerplate">
                  Boilerplate Code
                  <span style={{ color: "red", width: "4px" }}> *</span>
                </label>
                <div className="generatetBoilerplate">
                  <Btn
                    className="btnSmall"
                    onClick={handleBoilerPlateCodeGeneration}
                  >
                    {" "}
                    Generate
                  </Btn>{" "}
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </div>
              </div>
              <TextareaAutosize
                minRows={4}
                onFocus={handleFocus}
                name="boilerplateCode"
                style={{ width: 1255 }}
                className="form-control"
                id="questionBoilerplate"
                value={
                  Props.bplateCode.hasOwnProperty(active.label)
                    ? Props.bplateCode[active.label]
                    : null
                }
                onChange={(e) => {
                  handleBplate(e, langId);
                }}
              />
            </div>

            <div className="solutionCode">
              <label htmlFor="questionSolutionCode">Solution Code</label>
              <TextareaAutosize
                minRows={2}
                name="solutionCode"
                onFocus={handleFocus}
                style={{ width: 1255 }}
                className="form-control"
                id="questionSolutionCode"
                value={
                  [masterLanguageData].length > 0 &&
                  [masterLanguageData].map((langData) => {
                    if (active.label in langData) {
                      return langData[active.label].solutionCode;
                    }
                  })
                }
                onChange={(e) => {
                  handleChange(e, langId);
                }}
              />
            </div>

            <div className="testBtn">
              <button onClick={testIoData}>Test</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
