import React from "react";
import constants from "../../constants";

export default function TestLabel(Props) {
  return (
    <div className="testLabel">
      {Props.status === constants.PROGRESS_STATUS.UNLOCKED ? (
        <span className="takeTestLabel">Take Test</span>
      ) : Props.status === constants.PROGRESS_STATUS.IN_PROGRESS ? (
        <span className="conductTrainerLabel">Contact Trainer</span>
      ) : Props.status === constants.PROGRESS_STATUS.QN_CONTINUE ? (
        <span className="continueLabel">Resume Test</span>
      ) : Props.status === constants.PROGRESS_STATUS.REATTEMPT ? (
        <span className="retakeTestLabel">Retake Test</span>
      ) : null}
    </div>
  );
}
