import { useRef, useState } from "react";

export default function AdminHeaderHooks() {
  const ref = useRef(null);
  const details = useRef([]);

  const [libType, setLibType] = useState([]);
  const [isNotifyOpened, setIsNotifyOpened] = useState(false);
  const [notificationDetails, setNotificationDetails] = useState([]);

  const [clearQuestionNotify, setClearQuestionNotify] = useState({
    isClear: false,
    learnerName: "",
  });

  return {
    ref,
    details,
    libType,
    setLibType,
    isNotifyOpened,
    setIsNotifyOpened,
    notificationDetails,
    clearQuestionNotify,
    setNotificationDetails,
    setClearQuestionNotify,
  };
}
