import React from 'react';
import IconButton from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close';

export default function Closeicon(props) {
  return (
    <div>
        <IconButton className={"closebtn"} onClick={()=>props.handleClosePopup()}>
            <CloseIcon/>
        </IconButton>
    </div>
  )
}
