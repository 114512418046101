import "./ButtonMedium.scss";
import React, { useEffect } from "react";
import ButtonHooks from "../ButtonHooks/ButtonHooks";

export default function ButtonMedium(Props) {
  const { onClick, setOnClick, onClickSecondary, setOnClickSecondary } =
    ButtonHooks();

  useEffect(() => {
    if (onClick) {
      setOnClick(false);
      Props.onHide();
    }

    if (onClickSecondary) {
      setOnClick(false);
      Props.onHideSecondary();
      setOnClickSecondary(false);
    }
  }, [onClick, onClickSecondary]);

  return (
    <>
      {(Props.primaryBtn ||
        (Props.primaryBtn === undefined &&
          Props.secondaryBtn === undefined)) && (
        <button
          disabled={Props.disable === true ? true : false}
          className={onClick ? "btnMediumOnClick" : "btnMedium"}
          onClick={() => {
            setOnClick(true);
          }}
        >
          {Props.btnText}
        </button>
      )}
      {Props.secondaryBtn && (
        <button
          className={
            onClickSecondary
              ? "btnMediumOnClickSecondary"
              : "btnMediumSecondary"
          }
          onClick={() => {
            setOnClickSecondary(true);
          }}
        >
          {Props.btnText2}
        </button>
      )}
    </>
  );
}
