import { useState } from "react";

export default function CodingSplitHooks() {
  const [verticalSize, setVerticalSize] = useState(304);
  const [outputWindowHeight, setOutputWindowHeight] = useState("20%");

  /* toggle output window */

  const toggleOutputWindowHeight = () => {
    setOutputWindowHeight((prevHeight) =>
      prevHeight <= "40%" ? "70%" : "20%"
    );
  };

  /* Dragging state */

  const heightFind = (size) => {
    const [editorWindow, opWIndow] = size;
    setOutputWindowHeight(opWIndow);
  };

  /* Dragging vertical Pane */

  const handleProblem = (size) => {
    const [leftside, rightside] = size;
    const percentage = leftside / window.innerWidth;
    const panelSize = `${percentage * 100}%`;

    setVerticalSize(panelSize);
  };

  return {
    heightFind,
    verticalSize,
    handleProblem,
    outputWindowHeight,
    toggleOutputWindowHeight,
  };
}
