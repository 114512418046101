import React from "react";

export default function MaxScoreSvgIcon() {
  return (
    <svg
      width="14"
      height="14"
      fill="none"
      viewBox="0 0 14 14"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_755_18143)">
        <path
          stroke="#B42318"
          stroke-width="1.2"
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M12.8334 6.46797V7.00464C12.8327 8.26255 12.4254 9.48653 11.6722 10.494C10.919 11.5015 9.86033 12.2386 8.65404 12.5952C7.44775 12.9519 6.15848 12.9091 4.97852 12.4731C3.79856 12.0372 2.79113 11.2315 2.10647 10.1763C1.42182 9.12099 1.09663 7.87268 1.17939 6.61749C1.26216 5.3623 1.74845 4.1675 2.56574 3.21127C3.38304 2.25504 4.48754 1.58861 5.71452 1.31139C6.94151 1.03417 8.22524 1.161 9.37425 1.67297M12.8334 2.33464L7.00008 8.1738L5.25008 6.4238"
        />
      </g>
      <defs>
        <clipPath id="clip0_755_18143">
          <rect width="14" height="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
