import useApiCall from "../useApiCall";
import constants from "../../constants";
import { createRef, useContext } from "react";
import { getDataFromStorage } from "../../util";
import useAxiosPrivate from "../useAxiosPrivate";
import CodingSplitHooks from "./CodingSplitHooks";
import AlertContext from "../../context/AlertContext";
import useCommonFunctionHooks from "../useCommonFunctionHooks";
import CodingHeaderLearnerSideHooks from "./CodingHeaderLearnerSideHooks";

export default function CodingEditorRunButtonHooks() {
  const Alert = useContext(AlertContext);

  const axios = useAxiosPrivate();
  const { apiCall } = useApiCall();
  const { toggleOutputWindowHeight } = CodingSplitHooks();
  const { getCatchBlockDetails } = useCommonFunctionHooks();
  const { handleNetworkError } = CodingHeaderLearnerSideHooks();

  const editorContainerRef = createRef();

  const handleFullScreenMode = (element) => {
    element.classList.toggle("expanded");
    Alert.setIsFullScreen(!Alert.isFullScreen);

    if (getDataFromStorage("role") === "learner") {
      if (!Alert.isFullScreen) {
        element.style.padding = 0;
        editorContainerRef.current.style.maxHeight = window.innerHeight + "px";
      } else {
        element.style.paddingTop = "50px";
        editorContainerRef.current.style.maxHeight = 580 + "px";
      }
    }
  };

  const handleRunAllTestCase = async () => {
    let isEmptytestCase = Alert.testCasesRef.current.filter(
      (data) => data.input === false
    );

    if (Alert.testCasesRef.current.length !== isEmptytestCase.length) {
      if (navigator.onLine) {
        if (Alert.outputWindowHeight <= 25) {
          toggleOutputWindowHeight();
        }

        if (Alert.isFullScreen) {
          handleFullScreenMode(editorContainerRef.current);
        }

        let testCaseforTest = [...Alert.testCasesRef.current],
          isUserCodeEMpty = true,
          newTestCaseforTest = [],
          isRunAllTestCases = false;

        /* Checking code is empty or not */

        if (
          ((Alert.status === constants.QUESTION_STATUS.SUBMITTED ||
            Alert.status === constants.QUESTION_STATUS.TO_BE_EVALUATED) &&
            Alert.codeDetails.isChangeCode) ||
          ((Alert.status === constants.QUESTION_STATUS.RESUBMIT ||
            Alert.status === constants.QUESTION_STATUS.CONTINUE ||
            Alert.status === constants.QUESTION_STATUS.IN_PROGRESS ||
            Alert.status === constants.QUESTION_STATUS.TO_BE_EVALUATED) &&
            Alert.codeDetails.isChangeCode)
        ) {
          if (Alert.learnerCode.trim().length === 0) {
            isUserCodeEMpty = false;
          }
        } else if (
          Alert.status === constants.QUESTION_STATUS.SUBMITTED ||
          Alert.status === constants.QUESTION_STATUS.RESUBMIT ||
          Alert.status === constants.QUESTION_STATUS.CONTINUE ||
          Alert.status === constants.QUESTION_STATUS.IN_PROGRESS ||
          Alert.status === constants.QUESTION_STATUS.TO_BE_EVALUATED
        ) {
          if (Alert.isAdmin) {
            if (Alert.codeDetails.answer.trim().length === 0) {
              isUserCodeEMpty = false;
            }
          } else {
            if (Alert.learnerCode === null || Alert.learnerCode === undefined) {
              isUserCodeEMpty = false;
            } else if (Alert.learnerCode.trim().length === 0) {
              isUserCodeEMpty = false;
            }
          }
        } else if (Alert.status === constants.QUESTION_STATUS.ASSIGNED) {
          let gettingLearnerNewCode =
            Alert.status === constants.QUESTION_STATUS.ASSIGNED
              ? Alert.codeDetails.templatecode.filter(
                  (codeDetail) =>
                    codeDetail.langid === Alert.selectedLanguage.value
                )
              : [];

          if (gettingLearnerNewCode[0].userCode.trim().length === 0) {
            isUserCodeEMpty = false;
          }
        } else if (Alert.learnerCode.length > 0) {
          if (Alert.learnerCode[0].userCode.trim().length === 0) {
            isUserCodeEMpty = false;
          }
        } else {
          if (Alert.learnerCode.trim().length === 0) {
            isUserCodeEMpty = false;
          }
        }

        if (isUserCodeEMpty) {
          let userdefinedTestcase = testCaseforTest.filter(
            (user) => !user.hasOwnProperty("isexample")
          );

          /* for getting output for user testcase */

          if (userdefinedTestcase.length > 0) {
            try {
              Alert.setIsLoaded(true);

              let baseurl =
                Alert.solutionLanguageId.current ==
                constants.LANG_ID.Javascript
                  ? process.env.REACT_APP_NODE_API
                  : process.env.REACT_APP_CONTAINER_API;

              let url =
                Alert.solutionLanguageId.current ==
                constants.LANG_ID.Javascript
                  ? "node/learner/code/js/getoutput"
                  : Alert.solutionLanguageId.current == constants.LANG_ID.C
                  ? "node/learner/code/c/getoutput"
                  : Alert.solutionLanguageId.current == constants.LANG_ID.Cpp
                  ? "node/learner/code/cpp/getoutput"
                  : Alert.solutionLanguageId.current ==
                    constants.LANG_ID.Python
                  ? "node/learner/code/python/getoutput"
                  : "node/learner/code/java/getoutput";

              const res = await axios.post(
                baseurl + url,
                JSON.stringify({
                  custominput: userdefinedTestcase,
                  questionid: Alert.codeDetails.qnid,
                  learnerid: getDataFromStorage("learnerid"),
                  languageid: Alert.solutionLanguageId.current,
                }),
                {
                  timeout: 30000,
                  mode: "no-cors",
                  headers: {
                    "Content-Type": "application/json",
                  },
                }
              );

              let gettingId = res.data.testcases;
              let userdefinedTestcaseOutput =
                typeof gettingId == "string"
                  ? JSON.parse(gettingId)
                  : gettingId;

              for (let outsol = 0; outsol < testCaseforTest.length; outsol++) {
                for (
                  let solout = 0;
                  solout < userdefinedTestcaseOutput.length;
                  solout++
                ) {
                  if (
                    testCaseforTest[outsol].id ===
                    userdefinedTestcaseOutput[solout].id
                  ) {
                    testCaseforTest[outsol].output =
                      userdefinedTestcaseOutput[solout].output;
                  }
                }
              }

              isRunAllTestCases = true;
            } catch (error) {
              getCatchBlockDetails(error);
            } finally {
              Alert.setIsLoaded(false);
            }
          } else {
            isRunAllTestCases = true;
          }

          /* for getting actual output for sys/user testcase */

          for (
            let testCase = 0;
            testCase < testCaseforTest.length;
            testCase++
          ) {
            if (
              testCaseforTest[testCase].input !== null &&
              testCaseforTest[testCase].input !== undefined
            ) {
              testCaseforTest[testCase].actualoutput = "";
              newTestCaseforTest.push(testCaseforTest[testCase]);
            }
          }

          if (isRunAllTestCases) {
            let newCodeDetials = JSON.parse(JSON.stringify(Alert.codeDetails));

            let learnerNewCode =
              newCodeDetials.status === constants.QUESTION_STATUS.ASSIGNED ||
              (!Alert.isAdmin &&
                newCodeDetials.status === constants.QUESTION_STATUS.RESUBMIT)
                ? newCodeDetials.templatecode.filter(
                    (codeDetail) =>
                      codeDetail.langid === Alert.selectedLanguage.value
                  )
                : [];

            let specs = {};

            specs.method = "post";
            specs.api =
              Alert.codeDetails.istestcases === constants.ISNOTESTCASES.FALSE
                ? "testcode"
                : "runcode";
            specs.language =
              newCodeDetials.status === constants.QUESTION_STATUS.ASSIGNED ||
              (!Alert.isAdmin &&
                (newCodeDetials.status === constants.QUESTION_STATUS.RESUBMIT ||
                  newCodeDetials.status === constants.QUESTION_STATUS.CONTINUE))
                ? Alert.selectedLanguage.label.toLowerCase()
                : newCodeDetials.langname.toLowerCase();
            specs.body = {
              type:
                Alert.codeDetails.istestcases === constants.ISNOTESTCASES.FALSE
                  ? "testcode"
                  : "runcode",
              language:
                constants.QUESTION_STATUS.ASSIGNED === newCodeDetials.status ||
                (!Alert.isAdmin &&
                  (newCodeDetials.status ===
                    constants.QUESTION_STATUS.RESUBMIT ||
                    newCodeDetials.status ===
                      constants.QUESTION_STATUS.CONTINUE))
                  ? Alert.selectedLanguage.label.toLowerCase()
                  : newCodeDetials.langname.toLowerCase(),
              testCases: newTestCaseforTest,
              questionid: newCodeDetials.qnid,
              learnerid: getDataFromStorage("learnerid"),
              solutionCode:
                (newCodeDetials.status ===
                  constants.QUESTION_STATUS.SUBMITTED ||
                  newCodeDetials.status ===
                    constants.QUESTION_STATUS.RESUBMIT ||
                  newCodeDetials.status ===
                    constants.QUESTION_STATUS.CONTINUE ||
                  Alert.status === constants.QUESTION_STATUS.IN_PROGRESS ||
                  Alert.status === constants.QUESTION_STATUS.TO_BE_EVALUATED) &&
                newCodeDetials.isChangeCode
                  ? Alert.learnerCode
                  : newCodeDetials.status ===
                      constants.QUESTION_STATUS.SUBMITTED ||
                    newCodeDetials.status ===
                      constants.QUESTION_STATUS.CONTINUE ||
                    newCodeDetials.status ===
                      constants.QUESTION_STATUS.IN_PROGRESS ||
                    newCodeDetials.status ===
                      constants.QUESTION_STATUS.TO_BE_EVALUATED
                  ? Alert.learnerCode
                  : newCodeDetials.status ===
                      constants.QUESTION_STATUS.ASSIGNED ||
                    (!Alert.isAdmin &&
                      newCodeDetials.status ===
                        constants.QUESTION_STATUS.RESUBMIT)
                  ? learnerNewCode[0].userCode
                  : !Alert.isAdmin && Alert.learnerCode.length > 0
                  ? Alert.learnerCode[0].userCode
                  : Alert.learnerCode,
            };

            try {
              Alert.setIsLoaded(true);

              let response = await apiCall(specs);

              if (
                response.resultCode === constants.RESULT_STATUS.TECHNICAL_ERROR
              ) {
                Alert.setShowNotify({
                  show: true,
                  title: "Info",
                  msg: response.message,
                });
              } else {
                if (
                  Alert.codeDetails.istestcases ===
                  constants.ISNOTESTCASES.FALSE
                ) {
                  let gettingId =
                    typeof response.result == "string"
                      ? JSON.parse(response.result)
                      : response.result;

                  let responseFromAlltestcase = gettingId.testcases;
                  let currentTestcases = [...newTestCaseforTest];

                  for (let i = 0; i < currentTestcases.length; i++) {
                    for (let j = 0; j < responseFromAlltestcase.length; j++) {
                      if (
                        currentTestcases[i].id === responseFromAlltestcase[j].id
                      ) {
                        if (currentTestcases[i].hasOwnProperty("isexample")) {
                          currentTestcases[i].actualoutput =
                            responseFromAlltestcase[j].actualoutput;
                        } else {
                          currentTestcases[i].actualoutput =
                            responseFromAlltestcase[j].actualoutput;
                          currentTestcases[i].output =
                            responseFromAlltestcase[j].output;
                        }
                      }
                    }
                  }

                  Alert.testCasesRef.current = currentTestcases;

                  const runtestcasedata = gettingId.testcases.map(
                    (testcase) => ({
                      id: testcase.id,
                      passed: testcase.passed,
                    })
                  );

                  Alert.setRunTestResultCode(runtestcasedata);
                } else {
                  Alert.setOutput(response.message);
                }
              }
            } catch (error) {
              let showNotify = {
                show: true,
                title: "Error",
              };
              if (
                (error.message === "timeout of 30000ms exceeded" ||
                  error.message.includes("504")) &&
                navigator.onLine
              ) {
                if (Alert.isAdmin) {
                  Alert.isAdminSubmitTimeout.current = true;
                }

                showNotify.msg =
                  "Something went wrong… program is taking too long to finish. Please, try again";
                Alert.setShowNotify(showNotify);
              } else if (
                JSON.parse(error.config.data).hasOwnProperty("solutionCode") &&
                (JSON.parse(error.config.data)?.solutionCode.includes(
                  "input()"
                ) ||
                  JSON.parse(error.config.data)?.solutionCode.includes(
                    "scanf"
                  ) ||
                  JSON.parse(error.config.data)?.solutionCode.includes("cin") ||
                  JSON.parse(error.config.data)?.solutionCode.includes(
                    "Scanner"
                  ))
              ) {
                showNotify.msg =
                  "Don't use the command line argument as an input";
                Alert.setShowNotify(showNotify);
              } else if (
                !navigator.onLine ||
                error.message === "Network Error"
              ) {
                handleNetworkError();
              } else {
                getCatchBlockDetails(error);
              }
            } finally {
              Alert.setIsLoaded(false);
            }
          }
        } else {
          Alert.setShowNotify({
            size: "sm",
            show: true,
            title: "Error",
            titleSvg: "error",
            msg: "There is no code to test",
          });
        }
      } else {
        handleNetworkError();
      }
    }
  };

  return { editorContainerRef, handleRunAllTestCase, handleFullScreenMode };
}
