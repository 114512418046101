import React, { useEffect, useContext, useCallback } from "react";
import _ from "lodash";
import { LibraryContext } from "../../../context/LibraryContext";
import constants from "../../../constants";
import { useParams } from "react-router-dom";
import AlertContext from "../../../context/AlertContext";
import useAxiosPrivate from "../../../Hooks/useAxiosPrivate";
import Badge from "../../CommonComponents/Badge";

const SharedTopics = () => {
  const { setIsLoaded, setShowNotify } = useContext(AlertContext);
  const { setSharedTopics, setFilterSharedTopics, filterSharedTopics } =
    useContext(LibraryContext);
  const { id } = useParams();
  const axios = useAxiosPrivate();

  const fetchSharedTopics = useCallback(async () => {
    try {
      setIsLoaded(true);
      const response = await axios.get(`node/admin/library/${id}/shared`, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.data.resultCode) {
        let data = response.data.data
          ? typeof response.data.data === "string"
            ? JSON.parse(response.data.data)
            : response.data.data
          : [];
        setSharedTopics(data);
        setFilterSharedTopics(data);
      } else {
        setShowNotify({
          show: true,
          title: "Warning !",
          msg:
            response.data.msg ||
            "Something went wrong. Please try again later.",
        });
      }
    } catch (error) {
      if (error.message.includes("403")) {
        setShowNotify({
          show: true,
          title: "Warning !",
          msg: "You have been logged-out due to inactivity. Login again.",
          isUnAuth: true,
        });
      } else
        setShowNotify({
          show: true,
          title: "Error !",
          msg: "Something went wrong. Please try again later or contact gradious team.",
        });
    } finally {
      setIsLoaded(false);
    }
  }, [
    id,
    axios,
    setShowNotify,
    setIsLoaded,
    setSharedTopics,
    setFilterSharedTopics,
  ]);

  useEffect(() => {
    fetchSharedTopics();
  }, [fetchSharedTopics]);

  return (
    <div className="sharedTopicsContainer">
      {filterSharedTopics?.length > 0 ? (
        _.map(filterSharedTopics, (topic, index) => (
          <SharedTopic key={index} topic={topic} />
        ))
      ) : (
        <div className="sharedTopicsNoDataContainer">
          <div className="sharedTopicsNoDataText">
            You don’t have access to questions created by Gradious.{" "}
          </div>
          <div className="sharedTopicsNoDataText">
            You can create your own questions under “My Questions” tab.
          </div>
        </div>
      )}
    </div>
  );
};

const SharedTopic = ({ topic }) => {
  const { id } = useParams();
  const isContentOrAssignment =
    parseInt(id) === constants.libTypeIds.CONTENT ||
    parseInt(id) === constants.libTypeIds.ASSIGNMENT;

  return (
    <div
      className={`sharedTopicContainer ${
        isContentOrAssignment ? "noTestTypes" : ""
      }`}
      key={topic.tid}
    >
      <div className="sharedTopicTitle">{topic.tname}</div>
      {isContentOrAssignment ? (
        <div className="sharedCount">{topic.count}</div>
      ) : (
        <div className="sharedTopicComplexityContainer">
          {topic.complexities?.length > 0 &&
            _.map(topic.complexities, (comp, index) => (
              <Badge
                key={index}
                size="md"
                label={
                  parseInt(comp.id) === constants.complexityid.EASY
                    ? "Easy"
                    : parseInt(comp.id) === constants.complexityid.MEDIUM
                    ? "Medium"
                    : "Difficult"
                }
                icon={{
                  icon: (
                    <div
                      className={`sharedTopicComplexityCount ${
                        parseInt(comp.id) === constants.complexityid.EASY
                          ? "easy"
                          : parseInt(comp.id) === constants.complexityid.MEDIUM
                          ? "medium"
                          : "difficult"
                      }`}
                    >
                      {comp.count}
                    </div>
                  ),
                  position: "start",
                }}
                color={
                  parseInt(comp.id) === constants.complexityid.EASY
                    ? "success"
                    : parseInt(comp.id) === constants.complexityid.MEDIUM
                    ? "warning"
                    : "error"
                }
              />
              // <div
              //   className={`sharedTopicComplexity ${
              //     parseInt(comp.id) === constants.complexityid.EASY
              //       ? "easy"
              //       : parseInt(comp.id) === constants.complexityid.MEDIUM
              //       ? "medium"
              //       : "difficult"
              //   }`}
              //   key={comp.id}
              // >
              //   <div className="sharedTopicComplexityCount">{comp.count}</div>
              //   <div className="sharedTopicComplexityName">{comp.name}</div>
              // </div>
            ))}
        </div>
      )}
    </div>
  );
};

export default SharedTopics;
