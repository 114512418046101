import React from "react";

export default function TimeSvg() {
  return (
    <div>
      <svg
        width="277"
        fill="none"
        height="245"
        viewBox="0 0 277 245"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_2338_15862)">
          <path
            fill="#F2F2F2"
            d="M256.06 187.433L268.486 189.269C272.692 184.453 275.592 178.738 276.933 172.622C267.847 179.656 254.029 175.918 243.011 179.758C239.584 180.981 236.492 182.917 233.964 185.423C231.437 187.929 229.537 190.941 228.408 194.236L222.335 200.415C227.832 202.473 233.736 203.379 239.639 203.072C245.543 202.765 251.305 201.251 256.531 198.634C259.446 197.139 262.152 195.303 264.582 193.168C260.234 190.579 256.06 187.433 256.06 187.433Z"
          />
          <path
            fill="#F2F2F2"
            d="M251.649 168.638L263.637 165.04C265.13 158.956 265.031 152.618 263.347 146.578C258.531 156.706 244.401 159.188 236.35 167.272C233.86 169.811 232.005 172.848 230.925 176.158C229.844 179.467 229.565 182.964 230.108 186.389L227.586 194.48C233.477 194.004 239.188 192.326 244.327 189.561C249.466 186.796 253.91 183.01 257.353 178.465C259.258 175.897 260.813 173.112 261.982 170.177C256.869 169.693 251.649 168.638 251.649 168.638Z"
          />
          <path
            fill="#CCCCCC"
            d="M277 204.158C277 204.218 276.988 204.278 276.963 204.334C276.939 204.39 276.903 204.441 276.858 204.484C276.812 204.527 276.758 204.561 276.699 204.584C276.64 204.607 276.576 204.619 276.512 204.619H0.487857C0.35847 204.619 0.23438 204.57 0.142889 204.484C0.0513983 204.397 0 204.28 0 204.158C0 204.035 0.0513983 203.918 0.142889 203.831C0.23438 203.745 0.35847 203.696 0.487857 203.696H276.512C276.576 203.696 276.64 203.708 276.699 203.731C276.758 203.754 276.812 203.788 276.858 203.831C276.903 203.874 276.939 203.925 276.963 203.981C276.988 204.037 277 204.097 277 204.158Z"
          />
          <path
            fill="#E6E6E6"
            d="M114.379 184.489C163.059 184.489 202.521 147.171 202.521 101.136C202.521 55.1009 163.059 17.7822 114.379 17.7822C65.6994 17.7822 26.2368 55.1009 26.2368 101.136C26.2368 147.171 65.6994 184.489 114.379 184.489Z"
          />
          <path
            fill="white"
            d="M190.222 101.135C190.231 112.326 187.467 123.364 182.15 133.363C176.834 143.361 169.115 152.043 159.61 158.711C158.975 159.161 158.327 159.599 157.675 160.025C144.966 168.38 129.857 172.858 114.379 172.858C98.9003 172.858 83.7922 168.38 71.0826 160.025C70.4308 159.599 69.783 159.161 69.1476 158.711C59.6498 152.037 51.9348 143.354 46.6194 133.357C41.304 123.36 38.5356 112.325 38.5356 101.135C38.5356 89.945 41.304 78.9103 46.6194 68.9129C51.9348 58.9155 59.6498 50.2328 69.1476 43.5589C69.783 43.1092 70.4308 42.6711 71.0826 42.2447C83.7922 33.89 98.9003 29.4121 114.379 29.4121C129.857 29.4121 144.966 33.89 157.675 42.2447C158.327 42.6711 158.975 43.1092 159.61 43.5589C169.115 50.227 176.834 58.9085 182.15 68.9072C187.467 78.9059 190.231 89.9435 190.222 101.135Z"
          />
          <path
            fill="#F55533"
            d="M114.379 166.655C152.643 166.655 183.663 137.321 183.663 101.135C183.663 64.9494 152.643 35.6152 114.379 35.6152C76.1146 35.6152 45.0952 64.9494 45.0952 101.135C45.0952 137.321 76.1146 166.655 114.379 166.655Z"
          />
          <path
            fill="black"
            opacity="0.2"
            d="M114.56 35.7988C113.509 35.7988 112.465 35.8248 111.427 35.8685C128.938 37.3237 145.209 45.0192 156.944 57.396C168.68 69.7727 175.001 85.9052 174.628 102.525C174.255 119.145 167.215 135.009 154.935 146.904C142.655 158.799 126.053 165.834 108.493 166.585C110.492 166.75 112.515 166.838 114.56 166.838C132.935 166.838 150.557 159.935 163.551 147.648C176.544 135.361 183.843 118.696 183.843 101.319C183.843 83.9417 176.544 67.2765 163.551 54.9891C150.557 42.7018 132.935 35.7988 114.56 35.7988Z"
          />
          <path
            fill="white"
            d="M114.584 30.9629C113.986 30.9639 113.413 31.1889 112.991 31.5885C112.568 31.9882 112.33 32.53 112.329 33.0952V42.3998C112.329 42.9653 112.567 43.5076 112.99 43.9075C113.412 44.3074 113.986 44.5321 114.584 44.5321C115.182 44.5321 115.755 44.3074 116.178 43.9075C116.601 43.5076 116.839 42.9653 116.839 42.3998V33.0952C116.838 32.53 116.6 31.9882 116.177 31.5885C115.754 31.1889 115.182 30.9639 114.584 30.9629Z"
          />
          <path
            fill="white"
            d="M159.61 43.559C158.975 43.1093 158.327 42.6712 157.675 42.2447C157.284 42.2206 156.893 42.2934 156.541 42.4559C156.189 42.6184 155.887 42.865 155.666 43.1713L150.128 50.8592C149.791 51.3266 149.665 51.9012 149.776 52.4566C149.888 53.0121 150.228 53.503 150.722 53.8212C151.216 54.1394 151.824 54.259 152.411 54.1536C152.999 54.0481 153.518 53.7264 153.854 53.259L159.393 45.5711C159.604 45.2795 159.734 44.9426 159.772 44.5912C159.81 44.2398 159.754 43.8849 159.61 43.559Z"
          />
          <path
            fill="white"
            d="M184.774 78.6141C184.663 78.3549 184.498 78.119 184.29 77.9199C184.083 77.7207 183.835 77.5623 183.563 77.4535C183.29 77.3448 182.998 77.2879 182.702 77.2862C182.406 77.2844 182.113 77.3378 181.839 77.4433L172.721 80.9441C172.167 81.1573 171.726 81.5696 171.494 82.0903C171.262 82.6111 171.258 83.1977 171.483 83.7212C171.708 84.2448 172.143 84.6625 172.694 84.8825C173.244 85.1026 173.864 85.1069 174.418 84.8947L183.536 81.3939C184.089 81.1796 184.53 80.7668 184.762 80.2458C184.994 79.7248 184.998 79.1381 184.774 78.6141Z"
          />
          <path
            fill="white"
            d="M78.6298 50.8592L73.0912 43.1713C72.8705 42.865 72.5692 42.6184 72.2169 42.4559C71.8646 42.2934 71.4736 42.2206 71.0824 42.2447C70.4306 42.6712 69.7828 43.1093 69.1474 43.559C69.0033 43.8849 68.9478 44.2398 68.9857 44.5912C69.0237 44.9426 69.1539 45.2795 69.3647 45.5711L74.9033 53.259C75.2398 53.7264 75.7588 54.0481 76.3462 54.1536C76.9336 54.259 77.5412 54.1394 78.0354 53.8212C78.5296 53.503 78.8698 53.0121 78.9813 52.4566C79.0928 51.9012 78.9664 51.3266 78.6298 50.8592Z"
          />
          <path
            fill="white"
            d="M56.0368 80.9446L46.9192 77.4438C46.3653 77.231 45.7447 77.2351 45.1939 77.4551C44.6431 77.675 44.2073 78.0928 43.9822 78.6167C43.7572 79.1405 43.7614 79.7274 43.9938 80.2483C44.2263 80.7692 44.6681 81.1814 45.222 81.3943L54.3395 84.8952C54.8934 85.1074 55.5137 85.103 56.0641 84.883C56.6146 84.6629 57.0501 84.2452 57.275 83.7216C57.5 83.1981 57.4959 82.6115 57.2637 82.0908C57.0315 81.57 56.5902 81.1578 56.0368 80.9446Z"
          />
          <path
            fill="white"
            d="M114.584 157.738C113.986 157.739 113.413 157.964 112.991 158.364C112.568 158.764 112.33 159.305 112.329 159.871V169.175C112.329 169.741 112.567 170.283 112.99 170.683C113.412 171.083 113.986 171.307 114.584 171.307C115.182 171.307 115.755 171.083 116.178 170.683C116.601 170.283 116.839 169.741 116.839 169.175V159.871C116.838 159.305 116.6 158.764 116.177 158.364C115.754 157.964 115.182 157.739 114.584 157.738Z"
          />
          <path
            fill="white"
            d="M159.393 156.699L153.854 149.011C153.688 148.78 153.475 148.582 153.227 148.428C152.979 148.275 152.702 148.169 152.411 148.116C152.121 148.064 151.822 148.067 151.532 148.124C151.242 148.181 150.967 148.291 150.722 148.449C150.478 148.606 150.268 148.808 150.106 149.042C149.943 149.276 149.832 149.538 149.776 149.813C149.721 150.088 149.724 150.371 149.784 150.645C149.844 150.919 149.961 151.179 150.128 151.411L155.666 159.099C155.887 159.405 156.189 159.652 156.541 159.814C156.893 159.977 157.284 160.049 157.675 160.025C158.327 159.599 158.975 159.161 159.61 158.711C159.754 158.385 159.81 158.03 159.772 157.679C159.734 157.327 159.604 156.991 159.393 156.699Z"
          />
          <path
            fill="white"
            d="M183.536 120.876L174.418 117.375C173.864 117.163 173.244 117.167 172.693 117.387C172.142 117.607 171.706 118.024 171.481 118.548C171.256 119.072 171.26 119.659 171.493 120.18C171.725 120.701 172.167 121.113 172.721 121.326L181.839 124.827C182.392 125.039 183.013 125.035 183.563 124.815C184.114 124.595 184.549 124.177 184.774 123.653C184.999 123.13 184.995 122.543 184.763 122.022C184.531 121.502 184.089 121.089 183.536 120.876Z"
          />
          <path
            fill="white"
            d="M78.0354 148.449C77.5412 148.131 76.9336 148.011 76.3462 148.117C75.7589 148.222 75.2399 148.544 74.9033 149.011L69.3647 156.699C69.1539 156.99 69.0237 157.327 68.9857 157.679C68.9478 158.03 69.0033 158.385 69.1474 158.711C69.7828 159.161 70.4306 159.599 71.0824 160.025C71.4736 160.049 71.8646 159.976 72.2169 159.814C72.5692 159.651 72.8705 159.405 73.0912 159.099L78.6298 151.411C78.7965 151.179 78.9133 150.919 78.9736 150.645C79.0339 150.371 79.0366 150.088 78.9814 149.813C78.9262 149.538 78.8142 149.276 78.6519 149.042C78.4896 148.808 78.2801 148.606 78.0354 148.449Z"
          />
          <path
            fill="white"
            d="M57.2748 118.547C57.1635 118.288 56.9992 118.052 56.7914 117.852C56.5836 117.653 56.3363 117.495 56.0637 117.386C55.7911 117.277 55.4985 117.221 55.2027 117.219C54.9068 117.217 54.6135 117.27 54.3395 117.376L45.2219 120.877C44.6685 121.09 44.2272 121.502 43.995 122.023C43.7628 122.544 43.7587 123.13 43.9837 123.654C44.2086 124.177 44.6441 124.595 45.1946 124.815C45.745 125.035 46.3653 125.04 46.9192 124.827L56.0367 121.326C56.5899 121.112 57.0308 120.699 57.2628 120.178C57.4949 119.657 57.4992 119.071 57.2748 118.547Z"
          />
          <path
            fill="#3F3D56"
            d="M117.509 100.077C117.718 101.243 117.428 102.439 116.705 103.403C115.982 104.367 114.884 105.021 113.652 105.22L73.6404 111.656C73.0301 111.755 72.4052 111.738 71.8016 111.608C71.198 111.478 70.6274 111.236 70.1224 110.898C69.6174 110.559 69.1878 110.129 68.8584 109.634C68.5289 109.138 68.3058 108.586 68.202 108.009C68.0982 107.431 68.1156 106.841 68.2533 106.27C68.3909 105.699 68.6461 105.159 69.0043 104.682C69.3625 104.204 69.8166 103.798 70.3408 103.486C70.8649 103.175 71.4489 102.964 72.0593 102.866L112.071 96.4296C113.303 96.2327 114.568 96.5061 115.588 97.19C116.607 97.8738 117.298 98.9122 117.509 100.077Z"
          />
          <path
            fill="#3F3D56"
            d="M159.621 141.999C159.046 142.546 158.265 142.854 157.45 142.857C156.635 142.86 155.852 142.558 155.273 142.015L112.201 101.603C111.623 101.059 111.296 100.321 111.293 99.5506C111.291 98.7799 111.611 98.0397 112.185 97.4926C112.759 96.9455 113.54 96.6362 114.355 96.6328C115.17 96.6295 115.953 96.9322 116.532 97.4745L159.604 137.887C160.182 138.431 160.508 139.169 160.511 139.94C160.515 140.711 160.194 141.451 159.621 141.999Z"
          />
          <path
            fill="#CCCCCC"
            d="M114.379 106.176C117.322 106.176 119.708 103.919 119.708 101.136C119.708 98.3522 117.322 96.0957 114.379 96.0957C111.435 96.0957 109.049 98.3522 109.049 101.136C109.049 103.919 111.435 106.176 114.379 106.176Z"
          />
          <path
            fill="#E6E6E6"
            d="M77.6693 13.7417C69.4885 25.9045 57.0065 33.9763 41.6131 39.279C41.3089 39.4945 40.9628 39.6511 40.5946 39.7401C40.2264 39.8291 39.8433 39.8486 39.4671 39.7975C39.0909 39.7464 38.7291 39.6258 38.4022 39.4425C38.0753 39.2592 37.7898 39.0168 37.562 38.7291C29.2004 28.1714 31.0885 13.0822 42.124 5.01482C44.792 3.06779 47.8424 1.64134 51.0981 0.818284C54.3539 -0.00477487 57.75 -0.208052 61.0895 0.220261C64.429 0.648573 67.6452 1.69994 70.5515 3.31332C73.4578 4.9267 75.9963 7.06997 78.0195 9.61868L78.2508 9.9107C78.4786 10.1983 78.6443 10.5256 78.7383 10.8738C78.8324 11.222 78.853 11.5843 78.799 11.9401C78.745 12.2958 78.6175 12.638 78.4236 12.9471C78.2298 13.2563 77.9735 13.5262 77.6693 13.7417Z"
          />
          <path
            fill="#E6E6E6"
            d="M150.739 9.61868C152.762 7.06997 155.3 4.9267 158.207 3.31332C161.113 1.69993 164.329 0.648575 167.669 0.220262C171.008 -0.20805 174.404 -0.00477912 177.66 0.81828C180.916 1.64134 183.966 3.06779 186.634 5.01481C197.67 13.0822 199.558 28.1714 191.196 38.7291C190.968 39.0168 190.683 39.2592 190.356 39.4425C190.029 39.6258 189.667 39.7464 189.291 39.7975C188.915 39.8486 188.532 39.8291 188.163 39.7401C187.795 39.6511 187.449 39.4945 187.145 39.279C172.855 34.1052 161.136 25.1518 151.089 13.7417C150.474 13.3066 150.068 12.6585 149.959 11.9401C149.85 11.2216 150.047 10.4916 150.507 9.91068L150.739 9.61868Z"
          />
          <path
            fill="#E6E6E6"
            d="M147.153 173.35C146.632 173.627 146.248 174.088 146.085 174.632C145.923 175.176 145.995 175.759 146.286 176.252L159.299 198.243C159.591 198.737 160.079 199.1 160.654 199.254C161.23 199.407 161.846 199.338 162.368 199.062C162.889 198.786 163.274 198.325 163.436 197.781C163.599 197.237 163.526 196.654 163.235 196.161L150.222 174.17C149.929 173.677 149.441 173.314 148.866 173.16C148.291 173.007 147.675 173.075 147.153 173.35Z"
          />
          <path
            fill="#E6E6E6"
            d="M78.5362 174.17L65.5232 196.16C65.2313 196.654 65.1587 197.237 65.3213 197.781C65.4838 198.325 65.8683 198.786 66.39 199.062C66.9118 199.338 67.5282 199.407 68.1036 199.253C68.6791 199.1 69.1664 198.736 69.4585 198.243L82.4715 176.252C82.6162 176.008 82.7086 175.739 82.7434 175.461C82.7781 175.183 82.7546 174.901 82.6742 174.631C82.5937 174.362 82.4579 174.11 82.2745 173.89C82.0911 173.67 81.8637 173.486 81.6052 173.35C81.3468 173.213 81.0624 173.126 80.7683 173.093C80.4742 173.06 80.1761 173.082 79.8911 173.158C79.6061 173.235 79.3398 173.363 79.1073 173.537C78.8749 173.71 78.6808 173.925 78.5362 174.17Z"
          />
          <path
            fill="#E6E6E6"
            d="M161.935 203.873C165.784 203.873 168.904 200.922 168.904 197.282C168.904 193.642 165.784 190.691 161.935 190.691C158.086 190.691 154.965 193.642 154.965 197.282C154.965 200.922 158.086 203.873 161.935 203.873Z"
          />
          <path
            fill="#E6E6E6"
            d="M166.854 35.2284C170.703 35.2284 173.824 32.2776 173.824 28.6376C173.824 24.9977 170.703 22.0469 166.854 22.0469C163.005 22.0469 159.885 24.9977 159.885 28.6376C159.885 32.2776 163.005 35.2284 166.854 35.2284Z"
          />
          <path
            fill="#E6E6E6"
            d="M113.969 19.7206C117.818 19.7206 120.938 16.7698 120.938 13.1298C120.938 9.48984 117.818 6.53906 113.969 6.53906C110.12 6.53906 107 9.48984 107 13.1298C107 16.7698 110.12 19.7206 113.969 19.7206Z"
          />
          <path
            fill="#E6E6E6"
            d="M63.5436 34.453C67.3927 34.453 70.513 31.5022 70.513 27.8622C70.513 24.2223 67.3927 21.2715 63.5436 21.2715C59.6945 21.2715 56.5742 24.2223 56.5742 27.8622C56.5742 31.5022 59.6945 34.453 63.5436 34.453Z"
          />
          <path
            fill="#E6E6E6"
            d="M68.0529 203.873C71.902 203.873 75.0223 200.922 75.0223 197.282C75.0223 193.642 71.902 190.691 68.0529 190.691C64.2038 190.691 61.0835 193.642 61.0835 197.282C61.0835 200.922 64.2038 203.873 68.0529 203.873Z"
          />
          <path
            fill="#FFB7B7"
            d="M156.468 138.292C156.743 138.699 157.108 139.044 157.539 139.303C157.97 139.561 158.456 139.728 158.962 139.79C159.468 139.851 159.983 139.808 160.469 139.661C160.955 139.515 161.402 139.27 161.777 138.942L172.713 144.484L172.127 138.412L161.832 134.081C161.209 133.521 160.39 133.197 159.531 133.17C158.672 133.143 157.833 133.414 157.172 133.934C156.511 134.453 156.074 135.183 155.944 135.987C155.814 136.79 156.001 137.61 156.468 138.292Z"
          />
          <path
            fill="#3F3D56"
            d="M164.436 140.411L168.722 134.816L189.367 144.046L215.058 139.487C216.328 139.262 217.635 139.304 218.886 139.61C220.137 139.916 221.301 140.479 222.296 141.258C223.291 142.037 224.093 143.014 224.644 144.12C225.195 145.225 225.481 146.431 225.484 147.653C225.486 149.86 224.562 151.978 222.915 153.541C221.267 155.104 219.032 155.986 216.698 155.993L187.256 156.044L164.436 140.411Z"
          />
          <path
            fill="#FFB7B7"
            d="M157.836 237.034L153.5 235.073L159.456 217.934L165.855 220.828L157.836 237.034Z"
          />
          <path
            fill="#FFB7B7"
            d="M186.904 234.27C186.589 234.457 186.274 234.641 185.955 234.825C185.321 235.192 184.681 235.554 184.041 235.909C183.719 236.087 183.395 236.264 183.073 236.439V236.448L183.054 236.451L183.047 236.454L182.557 236.569L181.853 234.81L181.528 233.999L181.07 232.852L180.229 230.753L175.802 219.699L182.684 218.094L182.942 219.091H182.945L183.073 219.585L185.477 228.807L185.968 230.692L185.984 230.75L186.28 231.876L186.535 232.852L186.818 233.942L186.904 234.27Z"
          />
          <path
            fill="#2F2E41"
            d="M227.962 195.453C227.525 196.116 227.079 196.776 226.63 197.429C225.796 198.646 224.939 199.845 224.06 201.025C224.06 201.028 224.057 201.028 224.054 201.031C223.541 201.481 222.974 201.872 222.366 202.197C222.34 202.215 222.312 202.227 222.286 202.242C221.838 202.477 221.361 202.658 220.866 202.781C220.687 202.824 220.506 202.866 220.327 202.908C219.684 203.052 219.047 203.176 218.413 203.284C217.286 203.474 216.171 203.61 215.063 203.694C214.974 203.703 214.884 203.709 214.795 203.715C210.238 204.031 205.801 203.522 201.17 202.688C198.883 202.278 196.549 201.787 194.132 201.278C193.342 201.11 192.549 200.944 191.746 200.778C191.686 200.766 191.622 200.751 191.558 200.739C190.412 200.501 189.246 200.266 188.052 200.035C186.548 199.742 184.997 199.459 183.386 199.191L183.5 201.339C183.596 202.991 183.959 204.619 184.577 206.167C185.265 208.07 185.809 209.576 184.66 212.711L184.106 214.229C184.393 214.983 184.491 215.79 184.394 216.586C184.296 217.382 184.004 218.145 183.542 218.817L183.43 218.976L188.052 230.033L188.351 230.75H185.985L183.074 230.753H178.14L171.022 217.247L170.681 216.603V216.57L171.417 190.616C171.426 190.388 171.446 190.156 171.477 189.927C171.607 188.886 171.955 187.88 172.5 186.968C173.045 186.056 173.777 185.256 174.653 184.614C175.492 183.994 176.453 183.537 177.481 183.271C178.508 183.006 179.581 182.936 180.637 183.066L183.074 183.364L188.052 183.972L191.558 184.403L191.628 184.412L201.97 185.677L202.712 182.816L202.849 182.767L207.202 181.259L222.261 176.039L222.299 176.027L223.289 175.684L223.407 175.864C223.442 175.919 223.477 175.973 223.509 176.027C224.488 177.687 225.285 179.437 225.888 181.25C226.902 184.211 227.737 187.225 228.388 190.276C228.417 190.409 228.442 190.529 228.465 190.638L228.474 190.671C228.484 190.722 228.49 190.755 228.493 190.764C228.841 192.131 228.599 193.8 227.962 195.453Z"
          />
          <path
            fill="#2F2E41"
            d="M228.597 185.888C228.549 185.696 228.489 185.5 228.425 185.31C227.92 183.873 227.331 182.463 226.661 181.087C225.909 179.521 225.008 177.844 223.935 176.028C223.415 175.148 222.857 174.233 222.259 173.284C221.96 172.808 221.649 172.323 221.326 171.829C221.314 171.806 221.3 171.784 221.285 171.763L221.192 171.618L206.293 173.504L200.745 174.205L200.414 176.82L200.334 177.467C200.331 177.467 200.331 177.467 200.334 177.47L200.112 179.235V179.238L199.815 181.569L199.79 181.566L191.557 180.557L183.072 179.518L178.515 178.961C177.459 178.831 176.386 178.901 175.358 179.167C174.331 179.432 173.369 179.889 172.53 180.509C171.655 181.151 170.923 181.952 170.378 182.864C169.833 183.776 169.485 184.782 169.355 185.822C169.326 186.033 169.307 186.25 169.298 186.464L164.998 196.099C162.425 201.861 160.787 207.957 160.138 214.182L156.985 223.714L157.434 223.754L164.116 224.341L165.88 224.498L169.766 219.133C170.209 218.522 170.629 217.893 171.021 217.248C171.666 216.195 172.239 215.105 172.737 213.983L181.142 195.069C181.792 195.174 182.435 195.274 183.072 195.37C186.018 195.822 188.847 196.19 191.557 196.475C200.37 197.406 208.001 197.478 214.794 196.689C216.702 196.469 218.546 196.18 220.326 195.822C220.979 195.692 221.623 195.552 222.259 195.403C222.409 195.37 222.556 195.337 222.702 195.3C224.018 194.979 225.228 194.351 226.222 193.474C227.215 192.596 227.959 191.497 228.387 190.277C228.898 188.863 228.971 187.34 228.597 185.888Z"
          />
          <path
            fill="#2F2E41"
            d="M159.221 234.235L154.529 232.109L153.794 231.774L152.618 234.503L144.414 236.211C144.008 236.295 143.642 236.5 143.368 236.795C143.094 237.09 142.927 237.46 142.891 237.851C142.856 238.243 142.954 238.635 143.17 238.97C143.387 239.305 143.711 239.565 144.096 239.714L152.112 242.807L154.657 240.641L154.033 243.548L157.052 244.713L160.361 234.753L159.221 234.235Z"
          />
          <path
            fill="#2F2E41"
            d="M188.051 230.032L188.351 230.749H188.051V230.032Z"
          />
          <path
            fill="#2F2E41"
            d="M186.418 230.902L181.502 232.513L180.731 232.765L181.926 235.486L177.367 242.158C177.141 242.488 177.033 242.877 177.058 243.269C177.083 243.661 177.24 244.036 177.505 244.337C177.771 244.639 178.132 244.852 178.535 244.946C178.939 245.04 179.363 245.01 179.747 244.859L187.744 241.723L187.941 238.491L189.66 240.972L192.673 239.791L187.614 230.512L186.418 230.902Z"
          />
          <path
            fill="#3F3D56"
            d="M227.371 190.252C227.079 190.252 226.792 190.184 226.534 190.056L200.594 177.238L200.294 176.077L199.729 172.405L199.737 172.371L202.204 161.615C202.159 161.523 202.064 161.371 201.957 161.2C200.856 159.436 197.504 154.065 203.901 146.669L215.132 134.287L224.772 133.895L224.881 134.026C227.459 137.085 229.197 140.699 229.942 144.547C230.686 148.394 230.414 152.356 229.149 156.081L228.145 173.223L229.222 176.685C229.851 178.72 229.747 180.894 228.926 182.867L229.173 188.472C229.183 188.702 229.143 188.931 229.057 189.146C228.971 189.361 228.84 189.558 228.672 189.724C228.504 189.89 228.302 190.022 228.078 190.113C227.855 190.204 227.615 190.251 227.371 190.252Z"
          />
          <path
            fill="#FFB7B7"
            d="M204.87 204.39C205.309 204.144 205.686 203.811 205.975 203.413C206.263 203.014 206.456 202.561 206.539 202.085C206.622 201.609 206.594 201.121 206.456 200.656C206.318 200.191 206.074 199.761 205.742 199.395L211.978 189.252L205.541 189.595L200.605 199.174C199.992 199.744 199.621 200.506 199.563 201.317C199.504 202.127 199.762 202.93 200.288 203.573C200.814 204.216 201.57 204.654 202.415 204.805C203.259 204.955 204.133 204.808 204.87 204.39Z"
          />
          <path
            fill="#3F3D56"
            d="M207.387 196.932L201.624 192.688L212.096 173.496L208.172 149.057C207.978 147.849 208.067 146.615 208.434 145.444C208.801 144.272 209.436 143.191 210.295 142.278C211.153 141.365 212.213 140.641 213.4 140.159C214.588 139.677 215.873 139.448 217.164 139.488C219.496 139.563 221.702 140.51 223.297 142.121C224.892 143.732 225.746 145.876 225.672 148.082L224.702 175.909L207.387 196.932Z"
          />
          <path
            fill="#2F2E41"
            d="M208.949 129.141V120.121C208.947 116.916 210.292 113.84 212.687 111.572C215.082 109.304 218.333 108.028 221.722 108.026C225.112 108.024 228.364 109.296 230.763 111.561C233.161 113.826 234.51 116.9 234.512 120.106V120.121C236.765 124.56 235.356 126.671 234.512 129.142C234.511 129.572 234.33 129.984 234.009 130.288C233.687 130.592 233.251 130.764 232.796 130.764H210.666C210.211 130.764 209.775 130.593 209.453 130.289C209.131 129.984 208.95 129.572 208.949 129.141Z"
          />
          <path
            fill="#FFB7B7"
            d="M217.54 130.178C222.823 130.178 227.107 126.127 227.107 121.131C227.107 116.134 222.823 112.083 217.54 112.083C212.256 112.083 207.972 116.134 207.972 121.131C207.972 126.127 212.256 130.178 217.54 130.178Z"
          />
          <path
            fill="#2F2E41"
            d="M227.553 123.502C227.466 123.49 227.379 123.472 227.293 123.459C225.8 123.209 224.308 122.963 222.82 122.708L222.683 119.519L220.88 122.376C216.768 123.666 212.921 122.937 209.302 121.327C208.108 120.791 206.95 120.187 205.833 119.519C206.138 117.755 206.855 116.078 207.933 114.61C209.01 113.141 210.422 111.92 212.063 111.034C212.2 110.961 212.332 110.896 212.469 110.831L212.471 110.831L212.473 110.83L212.474 110.828L212.474 110.827C212.707 110.723 212.944 110.624 213.181 110.533C215.039 109.847 217.065 109.673 219.023 110.032C220.982 110.391 222.792 111.268 224.244 112.562C227.165 115.199 228.479 119.553 227.553 123.502Z"
          />
        </g>
        <defs>
          <clipPath id="clip0_2338_15862">
            <rect width="277" height="245" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
}
