import { useContext } from "react";
import { getDataFromStorage } from "../../util";
import AlertContext from "../../context/AlertContext";

export default function CodingHeaderHooks() {
  const { isAdmin, learnerId } = useContext(AlertContext);

  const getLearnerId = () => {
    if (isAdmin) {
      return learnerId;
    } else {
      return getDataFromStorage("learnerid");
    }
  };

  return { getLearnerId };
}
