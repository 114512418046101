import Reject from "../../Reject";
import Approve from "../../Approve";
import Comments from "../../Comments";
import { Drawer } from "@mui/material";
import React, { useContext } from "react";
import ScoreLog from "../../Score/ScoreLog";
import CommentsHooks from "../../../Hooks/CommentsHooks";
import AlertContext from "../../../context/AlertContext";
import CodingHeaderAdminSide from "./CodingHeaderAdminSide";
import CodingHeaderLearnerSide from "./CodingHeaderLearnerSide";

export default function CodingHeader() {
  const {
    isAdmin,
    showScore,
    showReject,
    showApprove,
    showComments,
  } = useContext(AlertContext);

  const { toggleDrawer } = CommentsHooks();
  return (
    <div>
      {isAdmin ? <CodingHeaderAdminSide /> : <CodingHeaderLearnerSide />}
      {showApprove && <Approve />}
      {showReject && (
        <div className="comments-score-section-score-reject-box">
          <Reject />
        </div>
      )}

      <Drawer
        anchor={"right"}
        className="commentsDrawer"
        open={showComments || showScore}
        onClose={toggleDrawer("right", false)}
      >
        {showScore && <ScoreLog />}
        {showComments && <Comments />}
      </Drawer>
    </div>
  );
}
