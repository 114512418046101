import useAxiosPrivate from "../../../Hooks/useAxiosPrivate";
import CreatableSelect from "react-select/creatable";
import AlertContext from "../../../context/AlertContext";
import React, { useState, useEffect, memo, useContext } from "react";

const customStylesForTopic = {
  control: (base, state) => ({
    ...base,
    marginTop: "20px",
    border: "1px solid #BDC1C6",
    maxHeight: "40px !important",
  }),
  option: (styles, { isSelected }) => {
    return {
      ...styles,
      width: "370px",
      height: "36px",
      fontSize: "14px",
      fontWeight: "400",
      lineHeight: "20px",
      background: "white",
      fontFamily: "Inter",
      color: isSelected ? "#000000" : undefined,
      ":hover": {
        color: "white",
        backgroundColor: "#0d6efd",
      },
    };
  },
  singleValue: (styles, { data }) => ({
    ...styles,
    color: "#202124",
    fontSize: "14px",
    fontWeight: "400",
    textAlign: "left",
    lineHeight: "20px",
    fontStyle: "normal",
    fontFamily: "Inter",
    paddingLeft: "10px",
    borderRadius: "20px",
  }),
  menuList: (base) => ({
    ...base,
    textAlign: "left",
    maxHeight: "150px",
    overflowX: "hidden",
    borderRadius: "2px",
  }),
};

const Modules = (Props) => {
  const { setShow, setIsUnAuth, setMsg, setTitle } = useContext(AlertContext);
  const axios = useAxiosPrivate();

  let [topicList, setTopicList] = useState([]);
  let [runningMaxTopicID, setrunningMaxTopicID] = useState();

  const [selectedtopic, setselectedtopic] = useState();
  const [fromEditQuestion, setfromEditQuestion] = useState(false);

  let currentMax = runningMaxTopicID;

  const createOption = (label) => ({
    label,
    value: getlength(),
  });

  const getlength = () => {
    if (currentMax > 0) {
      return currentMax + 1;
    }

    return runningMaxTopicID + 1;
  };

  const handleCreate = (inputValue) => {
    Props.setisSelectedTopic(false);
    currentMax = runningMaxTopicID;
    setMaxTopicId(currentMax + 1);

    if (
      inputValue.trim().length === 0 ||
      topicList.filter((option) => option.label === inputValue).length > 0
    ) {
    } else {
      const newOption = createOption(inputValue);

      setTopicList((prev) => [...prev, newOption]);
      setselectedtopic(newOption);
      Props.setvalue({ name: "topicname", value: newOption.label });
    }
  };

  const setMaxTopicId = (topics) => {
    let maxid = 0;

    for (var i = 0; i < topics.length; i++) {
      if (topics[i].value > maxid) {
        maxid = topics[i].value;
      }
    }

    setrunningMaxTopicID(maxid);
  };

  const handleChange = (newValue) => {
    Props.setisSelectedTopic(false);
    setselectedtopic(newValue);

    if (newValue !== null && newValue.value > runningMaxTopicID) {
      Props.setvalue({ name: "topicname", value: newValue?.label });
    } else {
      Props.setvalue({ name: "topic", value: newValue?.value });
    }
  };

  useEffect(() => {
    if (
      Props.isqueReport &&
      Props.tableShowStatus !== 5 &&
      Props.isSelectedTopic
    ) {
      Props.setalreadySelected(Props.selectmodule.topicid);
    }
  }, [
    Props.isqueReport,
    Props.tableShowStatus,
    Props.isSelectedTopic,
    Props.setalreadySelected,
  ]);

  useEffect(() => {
    axios
      .get(`node/admin/topic/get/${0}`, {
        headers: {
          "Content-type": "application/json",
        },
      })
      .then((res) => {
        let json = res.data;
        let topics = json.map((top) => {
          return {
            value: top.id,
            label: top.name,
          };
        });

        setTopicList(topics);
        setselectedtopic(topics.find((obj) => obj.value === Props.selectedtop));
        setMaxTopicId(topics);
      })
      .catch((error) => {
        if (error.message.includes("403")) {
          setShow(true);
          setIsUnAuth(true);
          setTitle("Error");
          setMsg("You have been logged-out due to inactivity. Login again.");
        }
      });
    setfromEditQuestion(Props.isqueReport);
  }, []);

  return (
    <>
      <div className="select_modules">
        <label htmlFor="select_module">
          Topic
          <span style={{ color: "red", width: "4px" }}> *</span>
        </label>

        <CreatableSelect
          required
          isClearable
          name="topic"
          options={topicList}
          onCreateOption={handleCreate}
          styles={customStylesForTopic}
          components={{ IndicatorSeparator: () => null }}
          onChange={(newValue) => {
            handleChange(newValue);
          }}
          value={
            fromEditQuestion && selectedtopic === undefined
              ? topicList.filter(
                  Props.tableShowStatus == 5
                    ? (option) =>
                        option.value === Props.selectmodule.qdata.topic
                    : (option) => option.value === Props.selectmodule.topicid
                )
              : selectedtopic
          }
        />
      </div>
    </>
  );
};

export default memo(Modules);
