import React from "react";

const FormTitle = ({ icon, title }) => {
  return (
    <div className="personalInfoTitleSec">
      {icon}
      <p className="personalInfoTitle">{title}</p>
    </div>
  );
};

export default FormTitle;
