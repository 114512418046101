import React, {
  useRef,
  useCallback,
  useState,
  useEffect,
  useContext,
} from "react";
import { Table } from "antd";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import update from "immutability-helper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBook,
  faChevronDown,
  faEye,
  faLaptopCode,
  faLaptopFile,
  faListCheck,
  faSquarePollHorizontal,
} from "@fortawesome/free-solid-svg-icons";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { faCirclePlus } from "@fortawesome/free-solid-svg-icons";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { nanoid } from "nanoid";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import useAddOrUpdateLp from "../../../Hooks/useAddOrUpdateLp";
import AlertContext from "../../../context/AlertContext";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import WarningIcon from "@mui/icons-material/Warning";
import { Divider, IconButton } from "@mui/material";
import Popup from "../../CommonComponents/Popup";
import useAxiosPrivate from "../../../Hooks/useAxiosPrivate";
import PublishCourse from "../../CommonComponents/PublishCourse";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ModuleHooks from "../../../Hooks/ModuleHooks";
import EditIconSvg from "../../../Svg/EditIconSvg";
import DeleteIconSvg from "../../../Svg/DeleteIconSvg";
import CommonBtn from "../../CommonComponents/CommonBtn";
import NoProctoringIcon from "../../../Svg/NoProctoringIcon";
import PartialProctorIcon from "../../../Svg/PartialProctorIcon";
import ProctorIconsvg from "../../../Svg/ProctorIconSvg";
import Tooltip from "@mui/material/Tooltip";
import Edit03 from "../../../Svg/Edit03";

const constants = require("../../../constants");

const DraggableModule = ({
  index,
  movemodules,
  className,
  style,
  ...restProps
}) => {
  const ref = useRef(null);
  const [{ opacity }, dragRef] = useDrag({
    type: "DraggableModule",
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      opacity: monitor.isDragging() ? 0.5 : 1,
    }),
  });

  // useDrop - the list item is also a drop area
  const [, dropRef] = useDrop({
    accept: "DraggableModule",
    hover: (item, monitor) => {
      const dragIndex = item.index;
      const hoverIndex = index;
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const hoverActualY = monitor.getClientOffset().y - hoverBoundingRect.top;

      // if dragging down, continue only when hover is smaller than middle Y
      if (dragIndex < hoverIndex && hoverActualY < hoverMiddleY) return;
      // if dragging up, continue only when hover is bigger than middle Y
      if (dragIndex > hoverIndex && hoverActualY > hoverMiddleY) return;

      movemodules(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });
  const dragDropRef = dragRef(dropRef(ref));

  // Make items being dragged transparent, so it's easier to see where we drop them
  // const opacity = isDragging ? 0.5 : 1
  return (
    <tr
      ref={dragDropRef}
      style={{ cursor: "default", ...style, opacity }}
      {...restProps}
    ></tr>
  );
};

const DraggableModuleDetails = ({
  index,
  movemoduleDetails,
  className,
  style,
  ...restProps
}) => {
  const ref = useRef(null);
  const [{ opacity }, dragRef] = useDrag({
    type: "DraggableModuleDetails",
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      opacity: monitor.isDragging() ? 0.5 : 1,
    }),
  });

  // useDrop - the list item is also a drop area
  const [, dropRef] = useDrop({
    accept: "DraggableModuleDetails",
    hover: (item, monitor) => {
      const dragIndex = item.index;
      const hoverIndex = index;
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const hoverActualY = monitor.getClientOffset().y - hoverBoundingRect.top;

      // if dragging down, continue only when hover is smaller than middle Y
      if (dragIndex < hoverIndex && hoverActualY < hoverMiddleY) return;
      // if dragging up, continue only when hover is bigger than middle Y
      if (dragIndex > hoverIndex && hoverActualY > hoverMiddleY) return;

      movemoduleDetails(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });
  const dragDropRef = dragRef(dropRef(ref));

  // Make items being dragged transparent, so it's easier to see where we drop them
  // const opacity = isDragging ? 0.5 : 1
  return (
    <tr
      ref={dragDropRef}
      style={{ cursor: "default", ...style, opacity }}
      {...restProps}
    ></tr>
  );
};
const moduleComponents = {
  body: {
    row: DraggableModule,
  },
};
const moduleDetailsComponents = {
  body: {
    row: DraggableModuleDetails,
  },
};

const Modules = (props) => {
  const {
    handleGetLibIdFromLp,
    handleEditTestConfig,
    isCommonPopup,
    setIsCommonPopup,
    batchDetails,
  } = useContext(AlertContext);

  const {
    isClearedState,
    commonPopupBodyComponent,
    setCommonPopupBodyComponent,
    setIsClearedState,
    handlePopupStates,
    clearPopupStates,
    isConfirmClearedState,
    setIsConfirmClearedState,
  } = ModuleHooks();

  const { successRes, setSuccessRes, handleAddOrUpdateLp } = useAddOrUpdateLp();
  const axios = useAxiosPrivate();
  let [modules, setModules] = useState([]);
  const [focus, setFocus] = useState(null);
  const [cursorPointer, setCursorPointer] = useState(0);

  // console.log(props.moduleExpandKey,modules)
  // const [moduleExpandKey, setModuleExpandKey] = useState(modules.length > 0 ? [modules[0].key] : []);
  const [expandedRecords, setExpandedRecords] = useState(
    modules?.length > 0 ? modules[0] : []
  );
  // const [successRes, setSuccessRes] = useState({ isSuccess: false, msg: "", title: "", isProctored: false });
  const [proctored, setProctored] = useState({ 1: false, 2: true, 0: false });
  const [showModal, setShowModal] = useState(false);
  const [showOpenDate, setShowOpenDate] = useState(true);
  // const [dataForUpdateDueDate, setDataForUpdateDueDate] = useState({ lpcid: "", dueDateForModule: "", dueDateForLearnerPro: "", oldDueDate: new Date() })
  const [weightageFocus, setWeightageFocus] = useState(null);
  const [modDuedayFocus, setModDuedayFocus] = useState(null);
  const [weightageDetails, setWeightageDetails] = useState({
    lpcId: "",
    weightage: 10,
  });
  const dataForPublish = useRef(null);
  const dataForUpdateDueDate = useRef({
    lpcid: "",
    dueDateForModule: "",
    dueDateForLearnerPro: "",
    oldDueDate: new Date(),
    ispublish: false,
  });

  let popupdata = {};
  const showModDuedays = props.isFromBatch
    ? batchDetails.batchtype !== constants.BATCH_TYPE.TESTING
      ? true
      : false
    : true;

  const handleClosePopup = () => {
    clearPopupStates();
    setSuccessRes({ isSuccess: false, msg: "", title: "", isProctored: false });
  };

  const primaryOnclick = () => {
    if (dataForPublish.current.duedate !== "Invalid Date") {
      if (clearPopupStates()) {
        setIsConfirmClearedState(true);
      }
    } else {
      props.setShow(true);
      props.setMsg("Please select a Date and Time.");
      props.setTitle("Info !");
    }
  };

  const secondPrimaryOnclick = () => {
    if (clearPopupStates()) {
      setIsClearedState(true);
    }
  };

  const confirmpopup = () => {
    /* for popup */
    popupdata.showpopup = true;
    popupdata.title = "Confirm publish";
    popupdata.bodydesc =
      "Are you sure you want to publish the test. Once published this cannot be pulled down";
    popupdata.singlebtnsize = "medium";
    popupdata.primbtntxt = "Confirm";
    popupdata.secbtntxt = "Cancel";
    popupdata.primbtnfunt = secondPrimaryOnclick;
    popupdata.secbtnfunt = handleClosePopup;
    handlePopupStates(popupdata);
  };

  /* popup state cleared or not check */
  useEffect(() => {
    if (isClearedState) {
      if (
        dataForPublish.current.libTypeId === constants.libTypeIds.ASSIGNMENT &&
        dataForPublish.current.ispublish &&
        (dataForPublish.current.templateId === null ||
          dataForPublish.current.templateId === undefined)
      ) {
        handlePublish(false);
        dataForPublish.current.ispublish = false;
      }
      if (
        dataForPublish.current.libTypeId === constants.libTypeIds.ASSIGNMENT &&
        dataForPublish.current.ispublish &&
        dataForPublish.current.templateId !== null &&
        dataForPublish.current.templateId !== undefined
      ) {
        handlePublish(true);
        dataForPublish.current.ispublish = false;
      }
      if (
        dataForPublish.current.libTypeId !== constants.libTypeIds.ASSIGNMENT
      ) {
        handlePublish(true);
      }
      setIsClearedState(false);
    }
    if (
      (dataForPublish.current?.libTypeId !== constants.libTypeIds.ASSIGNMENT &&
        isConfirmClearedState) ||
      (dataForPublish.current?.libTypeId === constants.libTypeIds.ASSIGNMENT &&
        dataForPublish.current.ispublish &&
        dataForPublish.current?.templateId !== null &&
        dataForPublish.current?.templateId !== undefined) ||
      (dataForPublish.current?.libTypeId === constants.libTypeIds.ASSIGNMENT &&
        dataForPublish.current.ispublish &&
        (dataForPublish.current?.templateId === null ||
          dataForPublish.current.templateId === undefined))
    ) {
      confirmpopup();
      setIsConfirmClearedState(false);
    }
  }, [isClearedState, isConfirmClearedState]);

  const handleSuccessResponse = () => {
    props.setLpData(
      props.isOldLpDetail
        ? JSON.parse(JSON.stringify(props.lpData))
        : {
            lpDes: "",
            lpName: "",
            moduleId: "",
            lpContent: "",
            iconData: "",
            lpIcon: "",
            lpShortDes: "",
            id: nanoid(),
            libTypeId: 2,
            isAddTest: false,
            modules: [
              {
                id: 1,
                key: nanoid(),
                status: "new",
                moduleName: "",
                moduleData: [],
              },
            ],
          }
    );
    props.setIsAddLpModule(false);
    props.setIsAddLp(false);
    setSuccessRes({ isSuccess: false, msg: "", title: "", isProctored: false });
    // if (!props.isOldLpDetail) window.location.reload();
  };

  useEffect(() => {
    setFocus("1");
    let newDetails = props.isOldLpDetail
      ? JSON.parse(JSON.stringify(props.lpData.modules))
      : props.newLpDetails !== undefined &&
        props.newLpDetails.hasOwnProperty("modules")
      ? JSON.parse(JSON.stringify(props.newLpDetails.modules))
      : [];
    if (props.isOldLpDetail) {
      // newDetails = [...props.lpData.modules]
      // newDetails.sort((a, b) => a.maximumorderno - b.maximumorderno) ----old
      newDetails.sort((a, b) => a.moduleorderno - b.moduleorderno);

      for (let i = 0; i < newDetails.length; i++) {
        for (let j = 0; j < newDetails[i].moduleData.length; j++) {
          if (
            newDetails[i].moduleData[j].hasOwnProperty("testId") &&
            newDetails[i].moduleData[j].testId == null &&
            newDetails[i].moduleData[j].hasOwnProperty("libId") &&
            newDetails[i].moduleData[j].libId == null
          ) {
            newDetails[i].moduleData.splice(j, 1);
          }
        }

        newDetails[i].moduleData.sort((a, b) => a.orderno - b.orderno);
        let moduleDuedate = "";
        for (let k = 0; k < newDetails[i].moduleData.length; k++) {
          if (
            newDetails[i].moduleData[k].published === 1 &&
            newDetails[i].moduleData[k].libTypeId !==
              constants.libTypeIds.CONTENT
          )
            moduleDuedate = newDetails[i].moduleData[k].dueDate;
        }

        newDetails[i].dueDate = moduleDuedate;
      }

      // handleDueDateCalculation(newDetails, batchStartDate).then(result => {
      setModules(newDetails);

      // }).catch(err => {
      // 	console.log("due date calculation err", err);
      // })
    } else {
      if (
        props.newLpDetails !== undefined &&
        props.newLpDetails.hasOwnProperty("modules")
      ) {
        setModules(props.newLpDetails.modules);
      } else {
        setModules([]);
      }
    }
    if (newDetails.length === 1) setExpandedRecords(newDetails[0]);
    else {
      let record = newDetails.filter(
        (module) => module.key === props.moduleExpandKey[0]
      );
      setExpandedRecords(record[0]);
    }
    props.setModuleExpandKey(
      newDetails.length === 0
        ? []
        : newDetails.length === 1
        ? [newDetails[0].key]
        : props.moduleExpandKey
    );
  }, [props.lpData, props.isOldLpDetail]);

  // useEffect(() => {
  // 	if (props.isOldLpDetail) {
  // 		for (let i = 0; i < props.lpData.modules.length; i++) {
  // 			for (let j = 0; j < props.lpData.modules[i].moduleData.length; j++) {
  // 				if ((props.lpData.modules[i].moduleData[j].hasOwnProperty("testId") && props.lpData.modules[i].moduleData[j].testId == null) && (props.lpData.modules[i].moduleData[j].hasOwnProperty("libId") && props.lpData.modules[i].moduleData[j].libId == null)) {
  // 					props.lpData.modules[i].moduleData.splice(j, 1)
  // 				}
  // 			}
  // 		}
  // 		setModules(props.lpData.modules);
  // 	} else {
  // 		if (props.newLpDetails !== undefined && props.newLpDetails.hasOwnProperty("modules")) {
  // 			setModules(props.newLpDetails.modules)
  // 		} else {
  // 			setModules([])
  // 		}
  // 	}
  // 	//-------- old change ------ setModules(props.isOldLpDetail ? props.lpData.modules : (props.newLpDetails !== undefined && props.newLpDetails.hasOwnProperty("modules")) ? props.newLpDetails.modules : [])
  // }, [props.isOldLpDetail, props.lpData, props.newLpDetails]);

  const handleDueDateCalculation = (
    testduration,
    batchStartDate,
    libTypeId
  ) => {
    let startDate = batchStartDate;
    return new Promise((resolve, reject) => {
      if (libTypeId !== constants.libTypeIds.CONTENT) {
        try {
          let testDuration = testduration / 60;
          let endDate = "";
          // let count = 0;
          // let calculateDate = new Date();
          let endDate_utc = new Date(/* Date.UTC(
						calculateDate.getUTCFullYear(),
						calculateDate.getUTCMonth(),
						calculateDate.getUTCDate(),
						0,
						0,
						0
					) */);
          // while (count < testDuration / 24) {
          endDate_utc.setTime(
            endDate_utc.getTime() + testDuration * 60 * 60 * 1000
          );
          // if (endDate_utc.getDay() !== 0 && endDate_utc.getDay() !== 6) {
          // count++;
          // }
          // }
          endDate =
            new Date(endDate_utc).getUTCFullYear() +
            "-" +
            (new Date(endDate_utc).getUTCMonth() + 1 > 9
              ? new Date(endDate_utc).getUTCMonth() + 1
              : `0${new Date(endDate_utc).getUTCMonth() + 1}`) +
            "-" +
            (new Date(endDate_utc).getUTCDate() > 9
              ? new Date(endDate_utc).getUTCDate()
              : `0${new Date(endDate_utc).getUTCDate()}`);
          startDate = endDate_utc;

          endDate_utc = new Date(
            Date.UTC(
              startDate.getUTCFullYear(),
              startDate.getUTCMonth(),
              startDate.getUTCDate(),
              startDate.getUTCHours(),
              startDate.getUTCMinutes(),
              startDate.getUTCSeconds()
            )
          );

          // endDate = endDate_utc.getUTCDate() + "/" + (endDate_utc.getUTCMonth() + 1) + "/" + endDate_utc.getUTCFullYear();
          let dueDateForLearnerPro = moment(endDate_utc).format(
            "YYYY-MM-DD HH:mm:ss"
          );
          endDate = moment(endDate_utc).format("YYYY-MM-DD HH:mm:ss");
          resolve({ endDate, dueDateForLearnerPro });
        } catch (err) {
          reject(err);
        }
      } else {
        resolve("");
      }
    });
  };

  const handleAddModule = () => {
    let module = {
      id: "",
      key: nanoid(),
      moduleName: "",
      status: "new",
      dueDate: "",
      moduleData: [],
    };
    let newLpDetails = JSON.parse(JSON.stringify(props.lpData));
    if (props.isOldLpDetail) newLpDetails.status = "update";

    let newDetails = [...modules],
      uniqueId = 1;
    newDetails.push(module);
    for (let i = 0; i < newDetails.length; i++) {
      newDetails[i].id = uniqueId;
      if (i === newDetails.length - 1) setFocus(uniqueId);
      uniqueId++;
      if (newDetails[i].hasOwnProperty("moduleData")) {
        for (let j = 0; j < newDetails[i].moduleData.length; j++) {
          newDetails[i].moduleData[j].moduleDataId = uniqueId;
          uniqueId++;
        }
      }
    }
    // newLpDetails.modules=newDetails;
    // props.setLpData(newLpDetails)

    setModules(newDetails);
  };

  const handleModuleName = (event, moduleid, index) => {
    // setModDuedayFocus(null)
    setCursorPointer(event.target.selectionStart);
    // setFocus(moduleid);
    setModules((modules) =>
      modules.map((module) => {
        if (module.id === moduleid) module.moduleName = event.target.value;
        return module;
      })
    );
  };

  const onChangeModDay = (event, mid) => {
    event.preventDefault();
    if (event && event.target.value !== "") {
      let regexForNum = /^\d{0,2}(\.\d{0,2}){0,1}$/;
      if (!regexForNum.test(event.target.value)) {
        props.setShow(true);
        props.setMsg("Please enter valid number");
        props.setTitle("Warning !");
        return;
      }
    }
    setModDuedayFocus(mid);
    setCursorPointer(event.target.selectionStart);
    setModules((modules) =>
      modules.map((module) => {
        if (module.id === mid) {
          module.duedays = event.target.value;
          module.moved = "yes";
        }
        return module;
      })
    );
    setFocus(null);
  };

  const ref = React.useCallback(
    (node) => {
      if (node && focus == node.id) {
        if (node) {
          node.selectionStart = cursorPointer;
          node.selectionEnd = cursorPointer;
          node.focus();
        }
      }
    },
    [focus, cursorPointer]
  );

  const handleEnterKey = (event, data) => {
    if (event.keyCode === 13) {
      props.setModuleExpandKey([data.key]);
      setModules((pre) =>
        pre.map((module) => {
          if (module.id === data.id && module.moduleName === "") {
            module.name = "Untitled Module";
          }
          return module;
        })
      );
      setExpandedRecords(data);
      setFocus(data.id);
    }
  };

  const handleDeleteModule = (record) => {
    setModules((pre) => pre.filter((module) => module.id !== record.id));
  };

  const handleCreatedDeleteModule = (record) => {
    axios
      .post(
        "node/admin/lp/module/delete",
        JSON.stringify({ modid: record.moduleId }),
        {
          headers: { "Content-type": "application/json" },
        }
      )
      .then((res) => {
        if (res.data.resultCode === 1000) {
          props.setShow(true);
          props.setMsg(res.data.data);
          props.setTitle("Success!");
          setModules((pre) => pre.filter((module) => module.id !== record.id));
        } else {
          props.setShow(true);
          props.setMsg("Something went wrong. Please try again later.");
          props.setTitle("Error");
        }
      })
      .catch((err) => {
        props.setShow(true);
        props.setMsg("Something went wrong. Please try again later.");
        props.setTitle("Error");
      });
  };

  const handleAddModuleDetails = (moduleId, libTypeId) => {
    let newDetails = props.isOldLpDetail
      ? JSON.parse(JSON.stringify(props.lpData))
      : JSON.parse(JSON.stringify(props.newLpDetails));
    newDetails.modules = modules;
    newDetails.moduleId = moduleId;
    newDetails.libTypeId = libTypeId;
    newDetails.isAddTest = true;
    handleGetLibIdFromLp(newDetails);
  };

  const handleSetWeightageToTest = (
    moduleId,
    lpcId,
    event,
    optional,
    modData
  ) => {
    if (event != null && event.target.value !== "") {
      let regexForNum = /^[0-9]\d*$/;
      if (!regexForNum.test(event.target.value)) {
        props.setShow(true);
        props.setMsg("Please enter valid number");
        props.setTitle("Warning !");
        return;
      }
    }

    // props.setIsLoaded(true);
    axios
      .patch(
        `node/admin/learningpath/config/${lpcId}`,
        {
          weightage: event != null ? event.target.value : modData.weightage,
          optional: optional,
        },
        {
          headers: { "Content-type": "application/json" },
        }
      )
      .then((response) => {
        if (response.data.resultCode === constants.RESULT_STATUS.SUCCESS) {
        } else {
          props.setShow(true);
          props.setMsg("Something went wrong. Please try again later.");
          props.setTitle("Error!");
        }
      })
      .catch((error) => {
        props.setShow(true);
        props.setTitle("Error !");
        if (error.message.includes("403")) {
          props.setIsUnAuth(true);
          props.setMsg(
            "You have been logged-out due to inactivity. Login again.."
          );
        } else props.setMsg("Something went wrong. Please try again later.");
      }); /* .finally(() => props.setIsLoaded(false)) */
    let newDetails = JSON.parse(JSON.stringify(modules));
    if (event != null) setCursorPointer(event.target.selectionStart);
    for (let module in newDetails) {
      if (newDetails[module].id === moduleId) {
        for (let modData in newDetails[module].moduleData) {
          if (newDetails[module].moduleData[modData].lpcId === lpcId) {
            newDetails[module].moduleData[modData].weightage =
              event != null
                ? event.target.value
                : newDetails[module].moduleData[modData].weightage;
            newDetails[module].moduleData[modData].optional = optional;
            break;
          }
        }
      }
    }
    if (event != null) setWeightageFocus(lpcId);
    setModules(newDetails);
  };

  const weightageRef = React.useCallback(
    (node) => {
      if (node && weightageFocus == node.id) {
        if (node) {
          node.selectionStart = cursorPointer;
          node.selectionEnd = cursorPointer;
          node.focus();
        }
      }
    },
    [weightageFocus, cursorPointer]
  );

  const duedayRef = React.useCallback(
    (node) => {
      if (node && modDuedayFocus == node.id) {
        if (node) {
          node.selectionStart = cursorPointer;
          node.selectionEnd = cursorPointer;
          node.focus();
        }
      }
    },
    [modDuedayFocus, cursorPointer]
  );
  const handleGetTestConfigById = (moduleId, isEdited, modData) => {
    // console.log("???????/testId", props.isOldLpDetail, modData);
    let testId =
      props.isOldLpDetail && modData.hasOwnProperty("testId")
        ? modData.testId
        : modData.id;
    if (
      modData.libTypeId !== constants.libTypeIds.CONTENT &&
      modData.libTypeId !== constants.libTypeIds.ASSIGNMENT
    ) {
      if (!isEdited) {
        props.setIsLoaded(true);
        axios
          .get("node/admin/test/getbyid/" + testId, {
            headers: { "Content-type": "application/json" },
          })
          .then((response) => {
            props.setIsLoaded(false);
            if (response.data.resultCode === 1000) {
              handleEditTestConfig(
                JSON.parse(response.data.data),
                moduleId,
                isEdited,
                modData.status,
                modData.published,
                modData.testStatus
              );
            } else {
              props.setShow(true);
              props.setMsg("Something went wrong. Please try again later.");
              props.setTitle("Error");
            }
          })
          .catch((error) => {
            // console.log("get test details by id err", error);
            props.setIsLoaded(false);
            if (error.message.includes("403")) {
              props.setShow(true);
              props.setIsUnAuth(true);
              props.setMsg(
                "You have been logged-out due to inactivity. Login again.."
              );
              props.setTitle("Error !");
            }
          });
      } else {
        handleEditTestConfig(modData, moduleId, isEdited);
      }
    } else {
      props.setIsLoaded(true);
      let libId =
        props.isOldLpDetail && modData.hasOwnProperty("libId")
          ? modData.libId
          : modData.id;
      axios
        .get("node/admin/content/" + libId, {
          headers: { "Content-type": "application/json" },
        })
        .then((response) => {
          props.setIsLoaded(false);
          let data = response.data.data;
          data.published = modData.published;
          let newDetails = JSON.parse(JSON.stringify(props.lpData));
          newDetails.status = "update";
          newDetails.modules = modules;
          newDetails.moduleId = moduleId;
          props.setLpData(newDetails);
          props.quereport(data);
          props.setTableShowStatus(modData.libTypeId);
          props.setisqueReport(true);
          props.setIsFromLp(true);
        })
        .catch((error) => {
          console.log("get library details by id err", error);
          props.setIsLoaded(false);
          if (error.message.includes("403")) {
            props.setShow(true);
            props.setIsUnAuth(true);
            props.setMsg(
              "You have been logged-out due to inactivity. Login again.."
            );
            props.setTitle("Error !");
          }
        });
    }
  };

  const handleDeleteModuleDetails = (moduleId, modDetailsId, record) => {
    let newDetails = [...modules];
    for (let i = 0; i < newDetails.length; i++) {
      if (newDetails[i].id === moduleId) {
        if (record.status === "old") {
          props.setIsLoaded(true);
          axios
            .post(
              "node/admin/learningpath/module/lpconfig/delete",
              JSON.stringify({ lpcid: record.lpcId, lpid: props.lpData.lpId }),
              {
                headers: { "Content-type": "application/json" },
              }
            )
            .then((res) => {
              props.setIsLoaded(false);
              if (res.data.resultCode === 1000) {
                props.setShow(true);
                props.setMsg(
                  "The learningpath configuration is deleted successfully"
                );
                props.setTitle("Success");
                for (let j = 0; j < newDetails[i].moduleData.length; j++) {
                  if (newDetails[i].moduleData[j].moduleDataId === modDetailsId)
                    newDetails[i].moduleData.splice(j, 1);
                }
              } else {
                props.setShow(true);
                props.setMsg("Something went wrong. Please try again later.");
                props.setTitle("Error!");
              }
            })
            .catch((error) => {
              props.setIsLoaded(false);
              if (error.message.includes("403")) {
                props.setShow(true);
                props.setIsUnAuth(true);
                props.setMsg(
                  "You have been logged-out due to inactivity. Login again.."
                );
                props.setTitle("Error !");
              }
            });
        } else {
          for (let j = 0; j < newDetails[i].moduleData.length; j++) {
            if (newDetails[i].moduleData[j].moduleDataId === modDetailsId)
              newDetails[i].moduleData.splice(j, 1);
          }
        }
      }
    }
    setModules(newDetails);
  };

  const handleAlertForProctored = (record, moduleId) => {
    if (record.libTypeId === constants.libTypeIds.CONTENT) {
      dataForPublish.current = {
        lpcid: record.lpcId,
        moduleId: moduleId,
        published: record.published,
        proctored: record.proctored,
        duedate: "",
        batchId: props.lpData.batchId,
        lpId: props.lpData.lpId,
        libTypeId: record.libTypeId,
        dueDateForLearnerPro: "",
      };
    }
    let batchStartDate = moment(new Date(props.lpData.batchStartDate));
    let newDetails = [...modules],
      found = 0;
    for (let i = 0; i < newDetails.length; i++) {
      // if (newDetails[i].id === moduleId) {
      for (let j = 0; j < newDetails[i].moduleData.length; j++) {
        if (
          newDetails[i].moduleData[j].published === 0 &&
          newDetails[i].moduleData[j].orderno < record.orderno
        ) {
          found = 1;
          break;
        }
      }
      // }
      if (found === 1) break;
    }
    let todayDate = moment(new Date());
    let diff = todayDate.diff(batchStartDate, "days");
    if (diff < 0) {
      found = 2;
    }
    // handleDueDateCalculation(
    //   record.testDuration,
    //   batchStartDate,
    //   record.libTypeId
    // )
    //   .then((dueDateDetails) => {
    if (record.libTypeId !== constants.libTypeIds.CONTENT) {
      dataForPublish.current = {
        lpcid: record.lpcId,
        moduleId: moduleId,
        published: record.published,
        proctored: record.proctored,
        duedate: /* dueDateDetails.endDate */ null,
        batchId: props.lpData.batchId,
        lpId: props.lpData.lpId,
        libTypeId: record.libTypeId,
        dueDateForLearnerPro: /* dueDateDetails.dueDateForLearnerPro */ null,
        templateId:
          record.templateId !== null && record.templateId !== undefined
            ? record.templateId
            : null,
      };
      if (
        record.libTypeId === constants.libTypeIds.ASSIGNMENT &&
        record.templateId !== null &&
        record.templateId !== undefined
      )
        dataForPublish.current.ispublish = true;
      dataForPublish.current.practiceMode = false;
    }

    if (found === 0) {
      console.log(
        "record.testMode === 2",
        record.testMode === 2,
        "record.testMode",
        record.testMode
      );
      if (
        (record.published === 0 &&
          record.libTypeId !== constants.libTypeIds.CONTENT && record.libTypeId !== constants.libTypeIds.ASSIGNMENT) ||
        (record.published === 0 && record.libTypeId === constants.libTypeIds.ASSIGNMENT &&
          record.templateId !== null &&
          record.templateId !== undefined)
        //  && record.testMode === 2
      ) {
        /* for popup */
        if (record.testMode === 1) {
          dataForPublish.current.proctored = 0;
          dataForPublish.current.practiceMode = true;
          // handlePublish(false);
        }
        popupdata.showpopup = true;
        popupdata.title = "Publish the test";
        popupdata.titledesc =
          "Select when and how you would like to publish the test";
        popupdata.closebtn = true;
        popupdata.bodydesc =
          "Once you have published the test, you can not unpublish";
        popupdata.iscomponent = true;
        if (
          record.testMode === 2 ||
          (record.libTypeId === constants.libTypeIds.ASSIGNMENT &&
            record.templateId !== null &&
            record.templateId !== undefined)
        ) {
          popupdata.component = (
            <PublishCourse
              handleChangeProctorDetail={handleChangeProctorDetail}
              dataForPublish={dataForPublish}
            />
          );
        } else {
          popupdata.component = (
            <PublishCourse
              moduleId={moduleId}
              data={{ lpcId: dataForPublish.current.lpcid }}
              dataForPublish={dataForPublish}
              libTypeId={dataForPublish.current.libTypeId}
              dataForUpdateDueDate={dataForUpdateDueDate}
              handleChangeProctorDetail={handleChangeProctorDetail}
              isTestMode={record.testMode === 2 ? true : false}
              onlyDueDate={true}
            />
          );
        }
        popupdata.singlebtnsize = "small";
        popupdata.primbtntxt = "Publish Test";
        popupdata.secbtntxt = "Cancel";
        popupdata.primbtnfunt = primaryOnclick;
        popupdata.secbtnfunt = handleClosePopup;
        handlePopupStates(popupdata);
        if (
          record.testMode === 2 ||
          (record.libTypeId === constants.libTypeIds.ASSIGNMENT &&
            record.templateId !== null &&
            record.templateId !== undefined)
        ) {
          setSuccessRes({
            isSuccess: true,
            isProctored: true,
            msg: "Do you want to allow proctoring for this test with ",
          });
        }
      }
      // else if (
      //   record.published === 0 &&
      //   record.libTypeId !== constants.libTypeIds.CONTENT &&
      //   record.libTypeId !== constants.libTypeIds.ASSIGNMENT
      //   && record.testMode === 1
      // ) {
      //   dataForPublish.current.proctored = 0;
      //   handlePublish(false);
      // }
      else if (
        record.published === 0 &&
        record.libTypeId === constants.libTypeIds.ASSIGNMENT &&
        (record.templateId === null || record.templateId === undefined)
      ) {
        popupdata.showpopup = true;
        popupdata.title = "Set new deadline";
        popupdata.titledesc = (
          <span>
            <WarningIcon id="adminLpModuleExtTimeWarningIcon" /> This duedate
            will apply to all learners
          </span>
        );
        popupdata.closebtn = true;
        popupdata.iscomponent = true;
        popupdata.component = (
          <PublishCourse
            dataForUpdateDueDate={dataForUpdateDueDate}
            onlyDueDate={true}
            moduleId={moduleId}
            data={{ lpcId: dataForPublish.current.lpcid }}
            dataForPublish={dataForPublish}
            libTypeId={dataForPublish.current.libTypeId}
          />
        );
        popupdata.singlebtnsize = "small";
        popupdata.primbtntxt = "Save";
        popupdata.secbtntxt = "Cancel";
        popupdata.primbtnfunt = handleSaveDueDate;
        popupdata.secbtnfunt = handleClosePopup;
        handlePopupStates(popupdata);

        dataForPublish.current.ispublish = true;
        // handlePublish(false);
      } else if (
        record.published === 0 &&
        record.libTypeId === constants.libTypeIds.CONTENT
      ) {
        handlePublish(false);
      } else {
        // props.setShow(true);
        // props.setMsg("Once you have published the test, you can not unpublish");
        // props.setTitle("Warning !");

        /* for popup */
        popupdata.showpopup = true;
        popupdata.title = "Warning !";
        popupdata.bodydesc =
          "Once you have published the test, you can not unpublish";
        popupdata.singlebtn = true;
        popupdata.singlebtntxt = "Ok";
        popupdata.singlebtnsize = "small";
        popupdata.singlebtnfunt = handleClosePopup;
        handlePopupStates(popupdata);
      }
    } else if (found === 2) {
      props.setShow(true);
      props.setMsg(
        "You can not publish the test before batch start date: " +
          batchStartDate.toISOString().split("T")[0]
      );
      props.setTitle("Warning !");
    } else {
      props.setShow(true);
      props.setMsg("You can publish test orderwise");
      props.setTitle("Warning !");
    }
    // })
    // .catch((err) => {
    //   // console.log(err);
    // });
  };

  // const handleChangeProctorDetail = (event) => {
  // 	// console.log(event.target, { ...dataForPublish.current });
  // 	const { name, checked } = event.target;
  // 	if (name === '1') setProctored({ ...proctored, [name]: checked });
  // 	else setProctored({ '1': checked, '2': checked });
  // }

  const handleChangeProctorDetail = (event, mode) => {
    const isCheckbox = event.target.type === "checkbox";
    const updatedProctored = {};
    Object.keys(proctored).forEach((key) => {
      updatedProctored[key] =
        key == mode ? (isCheckbox ? event.target.checked : true) : false;
    });
    setProctored(updatedProctored);
  };

  // const handleClosePopup = () => {
  // 	setSuccessRes({ isSuccess: false, msg: "", title: "", isProctored: false });
  // }

  // const primaryOnclick = () => {
  // 	handlePublish(true);
  // }
  const handlePublish = (value) => {
    // let proctor = false;
    // let proctoredData = 0
    if (dataForPublish.current.published === 0) {
      if (value === true && !dataForPublish.current.practiceMode) {
        dataForPublish.current.proctored = proctored[2]
          ? 2
          : proctored[1]
          ? 1
          : 0;
      }
      // props.setIsLoaded(true);
      let publish = true;
      let publishData = 1;
      props.setIsLoaded(true);
      setSuccessRes({
        isSuccess: false,
        msg: "",
        title: "",
        isProctored: false,
      });
      axios
        .post(
          "node/admin/learningpath/module/lpconfig/publish",
          JSON.stringify({
            lpcid: dataForPublish.current.lpcid,
            publish: publish,
            proctored: dataForPublish.current.proctored,
            duedate: dataForPublish.current.duedate,
            batchId: props.lpData.batchId,
            lpId: dataForPublish.current.lpId,
            dueDateForLearnerPro: dataForPublish.current.dueDateForLearnerPro,
            weightage: dataForPublish.current.weightage,
          }),
          {
            headers: { "Content-type": "application/json" },
          }
        )
        .then((res) => {
          // console.log("Publish topic resposne", res);
          props.setIsLoaded(false);
          // props.setShow(true);
          // props.setMsg(dataForPublish.current.libTypeId === constants.libTypeIds.CONTENT ? "The content is published" : dataForPublish.current.libTypeId === constants.libTypeIds.ASSIGNMENT ? "The assignment is published" : "The test is published");
          // props.setTitle("Success");

          /* popup state set */

          popupdata.showpopup = true;
          popupdata.title = "Success";
          popupdata.bodydesc =
            dataForPublish.current.libTypeId === constants.libTypeIds.CONTENT
              ? "The content is published"
              : dataForPublish.current.libTypeId ===
                constants.libTypeIds.ASSIGNMENT
              ? "The assignment is published"
              : "The test is published";
          popupdata.singlebtn = true;
          popupdata.singlebtntxt = "Ok";
          popupdata.singlebtnsize = "small";
          popupdata.singlebtnfunt = handleClosePopup;
          handlePopupStates(popupdata);

          // setProctored({ 1: false, 2: false, 0: false });
          if (res.data.resultCode === 1000) {
            let newDetails = JSON.parse(JSON.stringify(modules));
            for (let i = 0; i < newDetails.length; i++) {
              let moduleDuedate = "";
              if (newDetails[i].id === dataForPublish.current.moduleId) {
                for (let j = 0; j < newDetails[i].moduleData.length; j++) {
                  if (
                    newDetails[i].moduleData[j].lpcId ===
                    dataForPublish.current.lpcid
                  ) {
                    newDetails[i].moduleData[j].published = publishData;
                    newDetails[i].moduleData[j].proctored =
                      dataForPublish.current.proctored;
                    newDetails[i].moduleData[j].dueDate =
                      dataForPublish.current.duedate;
                    newDetails[i].moduleData[j].weightage =
                      !dataForPublish.current.weightage ||
                      dataForPublish.current.weightage === ""
                        ? 10
                        : dataForPublish.current.weightage;
                  }
                  if (newDetails[i].moduleData[j].published === 1)
                    moduleDuedate = newDetails[i].moduleData[j].dueDate;
                }
              }
              newDetails[i].dueDate = moduleDuedate;
            }
            // handleDueDateCalculation(newDetails, props.lpData.batchStartDate).then(result => {
            let newLpData = JSON.parse(JSON.stringify(props.lpData));
            newLpData.modules = newDetails;
            props.setLpData(newLpData);
            // setModules(newDetails);
            // }).catch(err => {
            // 	console.log("due date calculation err", err);
            // })
          } else {
            props.setIsLoaded(false);
            // props.setShow(true);
            // props.setMsg("Something went wrong. Please try again later.");
            // props.setTitle("Error !");

            popupdata.showpopup = true;
            popupdata.title = "Error !";
            popupdata.bodydesc =
              "Something went wrong. Please try again later.";
            popupdata.singlebtn = true;
            popupdata.singlebtntxt = "Ok";
            popupdata.singlebtnsize = "small";
            popupdata.singlebtnfunt = handleClosePopup;
            handlePopupStates(popupdata);
          }
        })
        .catch((err) => {
          if (err.message.includes("403")) {
            props.setIsLoaded(false);
            props.setIsUnAuth(true);
            // props.setShow(true);
            // props.setMsg("You have been logged-out due to inactivity. Login again.");
            // props.setTitle("Error !");

            popupdata.showpopup = true;
            popupdata.title = "Error !";
            popupdata.bodydesc =
              "You have been logged-out due to inactivity. Login again.";
            popupdata.singlebtn = true;
            popupdata.singlebtntxt = "Ok";
            popupdata.singlebtnsize = "small";
            popupdata.singlebtnfunt = handleClosePopup;
            handlePopupStates(popupdata);
          }
          props.setIsLoaded(false);
        });
    } else {
      // props.setShow(true);
      // props.setMsg("Once you have published the test, you not able to unpublish");
      // props.setTitle("Warning !")

      popupdata.showpopup = true;
      popupdata.title = "Warning !";
      popupdata.bodydesc =
        "Once you have published the test, you not able to unpublish";
      popupdata.singlebtn = true;
      popupdata.singlebtntxt = "Ok";
      popupdata.singlebtnsize = "small";
      popupdata.singlebtnfunt = handleClosePopup;
      handlePopupStates(popupdata);
    }
  };

  const handleModal = (moduleId, data) => {
    // setShowModal(true);

    /* for popup */
    popupdata.showpopup = true;
    popupdata.title = "Set new deadline";
    popupdata.titledesc = (
      <span>
        <WarningIcon id="adminLpModuleExtTimeWarningIcon" /> This duedate will
        apply to all learners
      </span>
    );
    popupdata.closebtn = true;
    popupdata.iscomponent = true;
    popupdata.component = (
      <PublishCourse
        // setDataForUpdateDueDate={setDataForUpdateDueDate}
        dataForUpdateDueDate={dataForUpdateDueDate}
        onlyDueDate={true}
        moduleId={moduleId}
        data={data}
      />
    );
    popupdata.singlebtnsize = "small";
    popupdata.primbtntxt = "Save";
    popupdata.secbtntxt = "Cancel";
    popupdata.primbtnfunt = handleSaveDueDate;
    popupdata.secbtnfunt = handleClosePopup;
    handlePopupStates(popupdata);

    // setDataForUpdateDueDate({ ...dataForUpdateDueDate, lpcid: data.lpcId, oldDueDate: moment().format("YYYY-MM-DD HH:mm:ss"), dueDateForLearnerPro: moment().format("YYYY-MM-DD HH:mm:ss"), dueDateForModule: moment().format("YYYY-MM-DD HH:mm:ss"), moduleId: moduleId })
  };

  const handleUpdateDueDate = (value, lpcId, moduleId) => {
    // setDataForUpdateDueDate({ ...dataForUpdateDueDate, oldDueDate: value, dueDateForLearnerPro: moment(value).format("YYYY-MM-DD HH:mm:ss"), dueDateForModule: moment(value).format("YYYY-MM-DD HH:mm:ss") })
    dataForUpdateDueDate.current.oldDueDate = value;
    dataForUpdateDueDate.current.dueDateForLearnerPro = moment(value).format(
      "YYYY-MM-DD HH:mm:ss"
    );
    dataForUpdateDueDate.current.dueDateForModule = moment(value).format(
      "YYYY-MM-DD HH:mm:ss"
    );
  };

  const handleSaveDueDate = () => {
    const batchStartDate = moment(props.lpData.batchStartDate);
    const date = new Date(
      dataForUpdateDueDate.current.dueDateForLearnerPro
    ).toLocaleDateString("fr-FR");
    let splitArr = date.split("/");
    let formatedDate = splitArr[2] + "-" + splitArr[1] + "-" + splitArr[0];
    const dueDate = moment(formatedDate);
    let found = 0;
    const diff = batchStartDate.diff(dueDate, "days");
    if (diff > 0) {
      found = 1;
    }
    setShowModal(false);
    if (found === 0) {
      axios
        .post(
          "node/admin/learningpath/module/lpconfig/duedate/update",
          JSON.stringify({
            lpcid: dataForUpdateDueDate.current.lpcid,
            dueDateForModule: dataForUpdateDueDate.current.dueDateForModule,
            dueDateForLearnerPro:
              dataForUpdateDueDate.current.dueDateForLearnerPro,
          }),
          {
            headers: { "Content-type": "application/json" },
          }
        )
        .then((res) => {
          // console.log("update duedate res", res.data);
          if (res.data.resultCode === 1000) {
            // props.setShow(true);
            // props.setMsg("The duedate is updated.");
            // props.setTitle("Success");
            let newDetails = JSON.parse(JSON.stringify(modules));
            for (let i = 0; i < newDetails.length; i++) {
              let moduleDuedate = "";
              for (let j = 0; j < newDetails[i].moduleData.length; j++) {
                if (
                  newDetails[i].id === dataForUpdateDueDate.current.moduleId &&
                  newDetails[i].moduleData[j].lpcId ===
                    dataForUpdateDueDate.current.lpcid
                ) {
                  newDetails[i].moduleData[j].dueDate =
                    dataForUpdateDueDate.current.dueDateForModule;
                }
                if (newDetails[i].moduleData[j].published === 1)
                  moduleDuedate = newDetails[i].moduleData[j].dueDate;
              }
              newDetails[i].dueDate = moduleDuedate;
            }
            setModules(newDetails);
            clearPopupStates();

            /* assignment publish */
            if (
              dataForPublish.current.libTypeId ===
                constants.libTypeIds.ASSIGNMENT &&
              dataForPublish.current.ispublish
            ) {
              primaryOnclick();
            }
          } else {
            props.setShow(true);
            props.setMsg("Network error.");
            props.setTitle("Error !");
          }
        })
        .catch((err) => {
          if (err.message.includes("403")) {
            props.setIsUnAuth(true);
            props.setShow(true);
            props.setMsg(
              "You have been logged-out due to inactivity. Login again."
            );
            props.setTitle("Error !");
          }
        })
        .finally(() => setShowModal(false));
    } else {
      props.setShow(true);
      props.setMsg(
        "You can not publish the test before batch start date: " +
          batchStartDate.toISOString().split("T")[0]
      );
      props.setTitle("Warning !");
    }
  };

  // console.log("modules ^^^^^ ", modules);

  const moduleData = [Table.EXPAND_COLUMN];
  const moduleDetails = [
    {
      dataIndex: "topic",
      key: 1,
      render: (select, record) => (
        <>
          {modules.map(
            (module) =>
              module.moduleData.length > 0 &&
              module.moduleData.map(
                (modData) =>
                  modData?.moduleDataId === record.moduleDataId && (
                    <>
                      {" "}
                      <div className="adminModuleDetails" key={record.lpcId}>
                        <div style={{ display: "flex" }}>
                          <svg
                            width="6"
                            height="12"
                            viewBox="0 0 6 12"
                            className="adminModuleDetailMoveIcon"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M0.857 10.286C1.331 10.286 1.714 10.669 1.714 11.143C1.714 11.616 1.331 12 0.857 12C0.384 12 0 11.616 0 11.143C0 10.669 0.384 10.286 0.857 10.286ZM5.143 10.286C5.616 10.286 6 10.669 6 11.143C6 11.616 5.616 12 5.143 12C4.669 12 4.286 11.616 4.286 11.143C4.286 10.669 4.669 10.286 5.143 10.286ZM0.857 6.857C1.331 6.857 1.714 7.241 1.714 7.714C1.714 8.188 1.331 8.571 0.857 8.571C0.384 8.571 0 8.188 0 7.714C0 7.241 0.384 6.857 0.857 6.857ZM5.143 6.857C5.616 6.857 6 7.241 6 7.714C6 8.188 5.616 8.571 5.143 8.571C4.669 8.571 4.286 8.188 4.286 7.714C4.286 7.241 4.669 6.857 5.143 6.857ZM0.857 3.43C1.331 3.43 1.714 3.813 1.714 4.287C1.714 4.76 1.331 5.144 0.857 5.144C0.384 5.144 0 4.76 0 4.287C0 3.813 0.384 3.43 0.857 3.43ZM5.143 3.43C5.616 3.43 6 3.813 6 4.287C6 4.76 5.616 5.144 5.143 5.144C4.669 5.144 4.286 4.76 4.286 4.287C4.286 3.813 4.669 3.43 5.143 3.43ZM0.857 0C1.331 0 1.714 0.384 1.714 0.857C1.714 1.331 1.331 1.714 0.857 1.714C0.384 1.714 0 1.331 0 0.857C0 0.384 0.384 0 0.857 0ZM5.143 0C5.616 0 6 0.384 6 0.857C6 1.331 5.616 1.714 5.143 1.714C4.669 1.714 4.286 1.331 4.286 0.857C4.286 0.384 4.669 0 5.143 0Z"
                              fill="#26344A"
                            />
                          </svg>
                          {modData.libTypeId ===
                          constants.libTypeIds.CONTENT ? (
                            <FontAwesomeIcon
                              className="adminModuleDetailTypeIcon bookIcon"
                              icon={faBook}
                            />
                          ) : modData.libTypeId ===
                            constants.libTypeIds.ASSIGNMENT ? (
                            <FontAwesomeIcon
                              className="adminModuleDetailTypeIcon laptopFileIcon"
                              icon={faLaptopFile}
                            />
                          ) : modData.libTypeId === constants.libTypeIds.MCQ ? (
                            <FontAwesomeIcon
                              className="adminModuleDetailTypeIcon listCheckIcon"
                              icon={faListCheck}
                            />
                          ) : modData.libTypeId ===
                            constants.libTypeIds.DESCRIPTIVE ? (
                            <FontAwesomeIcon
                              className="adminModuleDetailTypeIcon squarePollHorizontalIcon"
                              icon={faSquarePollHorizontal}
                            />
                          ) : (
                            <FontAwesomeIcon
                              className="adminModuleDetailTypeIcon laptopCodeIcon"
                              icon={faLaptopCode}
                            />
                          )}
                          <span className="adminModuleDetailName">
                            {modData.title}
                          </span>
                          {/* <span className='adminModuleDetailType'>{modData.libTypeId === constants.libTypeIds.ASSIGNMENT ?  : modData.libTypeId === constants.libTypeIds.CODING_CHALLENGE ? 'Coding Challenge' : modData.libTypeId === constants.libTypeIds.CONTENT ? 'Content' : modData.libTypeId === constants.libTypeIds.DESCRIPTIVE ? 'Descriptive' : 'Mcq'}</span> */}
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          {props.isOldLpDetail &&
                            props.isFromBatch &&
                            record.published === 1 &&
                            modData.libTypeId !==
                              constants.libTypeIds.CONTENT && (
                              <>
                                <IconButton
                                  className={
                                    modData.optional == false
                                      ? "admin-Module-Details-EditIcon-btn Icons"
                                      : "admin-Module-Details-EditIcon-btn"
                                  }
                                  onClick={() => {
                                    handleSetWeightageToTest(
                                      module.id,
                                      record.lpcId,
                                      null,
                                      modData.optional == false ? true : false,
                                      modData
                                    );
                                    setWeightageFocus(null);
                                    setCursorPointer(0);
                                  }}
                                >
                                  <div
                                    className={
                                      modData.optional == false
                                        ? `admin-Module-Details-Optional-btn`
                                        : `admin-Module-Details-Isoptional-btn`
                                    }
                                  >
                                    Optional
                                  </div>
                                </IconButton>
                                <div className="default-Icons">
                                  <span className="adminModuleDetailName">
                                    WEIGHTAGE :
                                  </span>
                                  <input
                                    className="adminModuleWeightageInput"
                                    id={record.lpcId}
                                    ref={
                                      weightageFocus &&
                                      weightageFocus === record.lpcId
                                        ? weightageRef
                                        : null
                                    }
                                    value={modData.weightage}
                                    onChange={(event) =>
                                      handleSetWeightageToTest(
                                        module.id,
                                        record.lpcId,
                                        event,
                                        modData.optional,
                                        modData
                                      )
                                    }
                                  />
                                </div>
                                {batchDetails.batchtype !==
                                  constants.BATCH_TYPE.SELF && (
                                  <>
                                    <span className="adminModuleDetailName">
                                      DUEDATE :{" "}
                                    </span>
                                    <input
                                      className="adminLpModuleDueDateInput"
                                      onClick={() =>
                                        handleModal(module.id, modData)
                                      }
                                      value={modData.dueDate}
                                      onChange={(date) =>
                                        handleUpdateDueDate(
                                          date,
                                          record.lpcId,
                                          module.id
                                        )
                                      }
                                    />
                                  </>
                                )}
                                {/* <input type='date' className='adminModuleDetailDueDate adminModuleDetailName' value={modData.dueDate} onChange={(event) => handleUpdateDueDate(event.target.value, record.lpcId, module.id)} style={{ marginRight: '10px' }} /> */}
                              </>
                            )}
                          {/* {props.isOldLpDetail && props.isFromBatch && modData.status === 'old' ? <FontAwesomeIcon icon={faEye} className="adminModuleDetailPublishIcon" style={record.published === 1 && record.proctored === 0 ? { color: '#19C00A' } : record.published === 1 && record.proctored > 0 ? { color: '#0D6EFD' } : { color: 'orange' }} onClick={() => { handleAlertForProctored(record, module.id); }} /> : <span></span>} */}
                          {props.isOldLpDetail &&
                          props.isFromBatch &&
                          modData.status === "old" ? (
                            record.published === 1 &&
                            record.proctored === 0 &&
                            ((record.libTypeId ===
                              constants.libTypeIds.ASSIGNMENT &&
                              record.templateId !== null &&
                              record.templateId !== undefined) ||
                              record.libTypeId !==
                                constants.libTypeIds.ASSIGNMENT) &&
                            record.libTypeId !==
                              constants.libTypeIds.CONTENT ? (
                              <Tooltip
                                title={"No Proctoring"}
                                placement="right"
                              >
                                <IconButton
                                  className="course-detail-iconbtn-after-publish"
                                  onClick={() => {
                                    handleAlertForProctored(record, module.id);
                                  }}
                                >
                                  {" "}
                                  <NoProctoringIcon />{" "}
                                </IconButton>
                              </Tooltip>
                            ) : record.published === 1 &&
                              record.proctored === 1 &&
                              ((record.libTypeId ===
                                constants.libTypeIds.ASSIGNMENT &&
                                record.templateId !== null &&
                                record.templateId !== undefined) ||
                                record.libTypeId !==
                                  constants.libTypeIds.ASSIGNMENT) &&
                              record.libTypeId !==
                                constants.libTypeIds.CONTENT ? (
                              <Tooltip
                                title={"Tab Switch Only"}
                                placement="right"
                              >
                                <IconButton
                                  className="course-detail-iconbtn-after-publish"
                                  onClick={() => {
                                    handleAlertForProctored(record, module.id);
                                  }}
                                >
                                  {" "}
                                  <PartialProctorIcon />{" "}
                                </IconButton>
                              </Tooltip>
                            ) : record.published === 1 &&
                              record.proctored === 2 &&
                              ((record.libTypeId ===
                                constants.libTypeIds.ASSIGNMENT &&
                                record.templateId !== null &&
                                record.templateId !== undefined) ||
                                record.libTypeId !==
                                  constants.libTypeIds.ASSIGNMENT) &&
                              record.libTypeId !==
                                constants.libTypeIds.CONTENT ? (
                              <Tooltip title={"Proctoring"} placement="right">
                                <IconButton
                                  className="course-detail-iconbtn-after-publish"
                                  onClick={() => {
                                    handleAlertForProctored(record, module.id);
                                  }}
                                >
                                  {" "}
                                  <ProctorIconsvg />{" "}
                                </IconButton>
                              </Tooltip>
                            ) : (
                              <IconButton
                                onClick={() => {
                                  handleAlertForProctored(record, module.id);
                                }}
                              ></IconButton>
                            )
                          ) : (
                            <span></span>
                          )}
                          {/* <FontAwesomeIcon icon={faEdit} className="adminModuleDetailsEditIcon" onClick={() => handleGetTestConfigById(module.id, modData.isEdited, modData)} /> */}
                          <IconButton
                            className="admin-Module-Details-EditIcon-btn"
                            onClick={() =>
                              handleGetTestConfigById(
                                module.id,
                                modData.isEdited,
                                modData
                              )
                            }
                          >
                            {" "}
                            <EditIconSvg />
                          </IconButton>
                          {record.published === 0 && (
                            <IconButton
                              className="admin-Module-Details-deleteIcon-btn"
                              onClick={() =>
                                handleDeleteModuleDetails(
                                  module.id,
                                  record.moduleDataId,
                                  record
                                )
                              }
                            >
                              <DeleteIconSvg />
                            </IconButton>
                          )}
                          {/* {record.published === 0 && <svg width="20" height="20" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" className="adminModuleDetailsDeleteIcon" onClick={() => handleDeleteModuleDetails(module.id, record.moduleDataId, record)} >
							<path d="M5.02196 4.93363C5.29225 4.93363 5.5142 5.14057 5.53803 5.40465L5.54015 5.45182V8.74091C5.54015 9.02709 5.30815 9.25909 5.02196 9.25909C4.75167 9.25909 4.52972 9.05216 4.50589 8.78807L4.50377 8.74091V5.45182C4.50377 5.16563 4.73577 4.93363 5.02196 4.93363Z" fill="#F55533" />
							<path d="M7.73075 5.40465C7.70693 5.14057 7.48497 4.93363 7.21469 4.93363C6.9285 4.93363 6.6965 5.16563 6.6965 5.45182V8.74091L6.69862 8.78807C6.72244 9.05216 6.9444 9.25909 7.21469 9.25909C7.50087 9.25909 7.73287 9.02709 7.73287 8.74091V5.45182L7.73075 5.40465Z" fill="#F55533" />
							<path fillRule="evenodd" clipRule="evenodd" d="M7.21468 0C8.08291 0 8.79107 0.685314 8.82774 1.54451L8.82924 1.61455V2.19273H11.052C11.3381 2.19273 11.5701 2.42473 11.5701 2.71091C11.5701 2.9812 11.3632 3.20315 11.0991 3.22698L11.052 3.2291H10.4738V10.3855C10.4738 11.2537 9.78847 11.9618 8.92927 11.9985L8.85923 12H3.37742C2.50919 12 1.80103 11.3147 1.76436 10.4555L1.76287 10.3855V3.2291H1.18469C0.898504 3.2291 0.666504 2.9971 0.666504 2.71091C0.666504 2.44062 0.873442 2.21867 1.13753 2.19484L1.18469 2.19273H3.40741V1.61455C3.40741 0.746324 4.09272 0.0381621 4.95192 0.00149169L5.02196 0H7.21468ZM2.79924 3.2291V10.3855C2.79924 10.6888 3.03286 10.9376 3.33 10.9617L3.37742 10.9636H8.85923C9.16258 10.9636 9.41137 10.73 9.43549 10.4329L9.4374 10.3855V3.2291H2.79924ZM7.79286 2.19273H4.44378V1.61455L4.4457 1.56713C4.46982 1.26999 4.71861 1.03637 5.02196 1.03637H7.21468L7.2621 1.03829C7.55924 1.06241 7.79286 1.3112 7.79286 1.61455V2.19273Z" fill="#F55533" />
						</svg>} */}
                        </div>
                      </div>
                      {props.isOldLpDetail &&
                      props.isFromBatch &&
                      modData.status === "old" &&
                      batchDetails.batchtype !== constants.BATCH_TYPE.SELF ? (
                        record.published === 1 && record.proctored === 0 ? (
                          <div></div>
                        ) : record.published === 1 && record.proctored > 0 ? (
                          <div></div>
                        ) : (
                          <div>
                            <CommonBtn
                              btnsize={"small"}
                              singlebtn={true}
                              singlebtntxt={"Publish"}
                              singlebtnfun={() =>
                                handleAlertForProctored(record, module.id)
                              }
                            />
                          </div>
                        )
                      ) : (
                        <span></span>
                      )}
                    </>
                  )
              )
          )}
        </>
      ),
    },
  ];

  const movemodules = useCallback(
    (dragIndex, hoverIndex) => {
      let newLpDetails = JSON.parse(JSON.stringify(props.lpData));
      if (props.isOldLpDetail) newLpDetails.status = "update";
      const dragRow = modules[dragIndex];
      const hoverRow = modules[hoverIndex];
      props.setModuleExpandKey([]);

      // if (dragRow.id !== hoverRow.id) {
      // 	let found = 0;
      // 	if (dragRow.id > hoverRow.id) {
      // 		for (let i = 0; i < hoverRow.moduleData.length; i++) {
      // 			if (hoverRow.moduleData[i].published === 1) {
      // 				props.setShow(true);
      // 				props.setTitle("Warning !");
      // 				props.setMsg(`${hoverRow.moduleName} has published item. You can't change the order. `);
      // 				found = 1;
      // 				break;
      // 			}
      // 		}
      // 		if (found === 0) {
      // 			for (let i = 0; i < dragRow.moduleData.length; i++) {
      // 				if (dragRow.moduleData[i].published === 1) {
      // 					props.setShow(true);
      // 					props.setTitle("Warning !");
      // 					props.setMsg(`${dragRow.moduleName} has published item. You can't change the order. `);
      // 					found = 1;
      // 					break;
      // 				}
      // 			}
      // 		}
      // 	} else if (dragRow.id < hoverRow.id) {
      // 		for (let i = 0; i < dragRow.moduleData.length; i++) {
      // 			if (dragRow.moduleData[i].published === 1) {
      // 				props.setShow(true);
      // 				props.setTitle("Warning !");
      // 				props.setMsg(`${dragRow.moduleName} has published item. You can't change the order. `);
      // 				found = 1;
      // 				break;
      // 			}
      // 		}
      // 		if (found === 0) {
      // 			for (let i = 0; i < hoverRow.moduleData.length; i++) {
      // 				if (hoverRow.moduleData[i].published === 1) {
      // 					props.setShow(true);
      // 					props.setTitle("Warning !");
      // 					props.setMsg(`${hoverRow.moduleName} has published item. You can't change the order. `);
      // 					found = 1;
      // 					break;
      // 				}
      // 			}
      // 		}
      // 	} if (found === 0) {
      let movedModule = update(modules, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragRow],
        ],
      });
      let orderno = 1,
        moduleOrderno = 1;
      for (let i = 0; i < movedModule.length; i++) {
        if (props.isOldLpDetail) {
          movedModule[i].moduleorderno = moduleOrderno;
          moduleOrderno++;
          for (let k = 0; k < movedModule[i].moduleData.length; k++) {
            movedModule[i].moduleData[k].moved = "yes";
            movedModule[i].moduleData[k].orderno = orderno;
            orderno++;
          }
        }
      }
      newLpDetails.modules = movedModule;
      props.setNewLpDetails(newLpDetails);
      props.setLpData(newLpDetails);
      // props.setNewLpDetails(newLpDetails)
      // console.log("move",movedModule)
      // props.setLpData(newLpDetails);
      setModules(movedModule);

      // }
      // }
    },
    [modules]
  );

  const movemoduleDetails = useCallback(
    (dragIndex, hoverIndex) => {
      let newLpDetails = JSON.parse(JSON.stringify(props.lpData));
      if (props.isOldLpDetail) newLpDetails.status = "update";

      let filteredModule = modules.filter(
        (module) => module.id === expandedRecords.id
      );
      const dragRow = filteredModule[0].moduleData[dragIndex];
      const hoverRow = filteredModule[0].moduleData[hoverIndex];

      // if (dragRow.moduleDataId !== hoverRow.moduleDataId) {
      // 	let found = 0
      // 	if (((dragRow.moduleDataId < hoverRow.moduleDataId) && hoverRow.published === 1) || (dragRow.moduleDataId > hoverRow.moduleDataId && hoverRow.published === 1)) {
      // 		props.setShow(true);
      // 		props.setTitle("Warning !");
      // 		props.setMsg(`${hoverRow.title} is already published. You can't change the order`);
      // 		found = 1;
      // 	} else if (((dragRow.moduleDataId < hoverRow.moduleDataId) && dragRow.published === 1) || (dragRow.moduleDataId > hoverRow.moduleDataId && dragRow.published === 1)) {
      // 		props.setShow(true);
      // 		props.setTitle("Warning !");
      // 		props.setMsg(`${dragRow.title} is already published. You can't change the order`);
      // 	} else {
      let movedModuleData = update(filteredModule[0].moduleData, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragRow],
        ],
      });
      let newDetails = [...modules],
        orderno = 1;
      for (let i = 0; i < newDetails.length; i++) {
        if (newDetails[i].id === expandedRecords.id) {
          newDetails[i].moduleData = movedModuleData;
          if (props.isOldLpDetail) newDetails[i].moved = "yes";
        }
        for (let k = 0; k < newDetails[i].moduleData.length; k++) {
          newDetails[i].moduleData[k].orderno = orderno;
          orderno++;
        }
      }
      newLpDetails.modules = newDetails;
      props.setNewLpDetails(newLpDetails);
      props.setLpData(newLpDetails);
      setModules(newDetails);
      // }
      // }
    },
    [modules, expandedRecords]
  );

  const handleProceed = async () => {
    // console.log('lpdata', props.lpData);
    try {
      let res = await handleAddOrUpdateLp(
        props.newLpDetails,
        modules,
        setSuccessRes,
        setModules
      );
      // console.log(res);
      if (res.title === "success") {
        props.setShow(true);
        props.setTitle(res.title);
        props.setMsg(res.message);
        // modules=res.modules;
        // setModules(res.modules);
      }
    } catch (err) {
      props.setIsUnAuth(err.hasOwnProperty("isUnAuth"));
      props.setShow(true);
      props.setTitle(err.title);
      props.setMsg(err.message);
    }
  };

  const handleCancel = () => {
    props.setIsAddLpModule(false);
    if (!props.isFromBatch || !props.isOldLpDetail) {
      props.setIsAddLp(false);
      props.setIsOldLpDetail(false);
      props.setLpData({
        id: nanoid(),
        lpName: "",
        lpDes: "",
        moduleId: "",
        lpContent: "",
        lpIcon: "",
        status: "",
        iconData: "",
        lpShortDes: "",
        libTypeId: 2,
        isAddTest: false,
        modules: [
          {
            id: 1,
            key: nanoid(),
            name: "",
            status: "new",
            moduleData: [],
          },
        ],
      });
    } else {
      let newDetails = JSON.parse(JSON.stringify(props.lpData));
      newDetails.modules = modules;
      props.setLpData(JSON.parse(JSON.stringify(newDetails)));
    }
  };

  const handleBackBtn = () => {
    props.setIsAddLpModule(false);
    props.setLpData({
      ...props.lpData,
      id: nanoid(),
      lpName: props.newLpDetails.lpName,
      lpDes: props.newLpDetails.lpDes,
      lpContent: props.newLpDetails.lpContent,
      lpIcon: props.newLpDetails.lpIcon,
      lpShortDes: props.newLpDetails.lpShortDes,
      iconData: props.newLpDetails.iconData,
      status: props.newLpDetails.status,
      moduleId: "",
      libTypeId: 2,
      isAddTest: false,
      modules: JSON.parse(JSON.stringify(modules)),
    });
    props.setNewLpDetails({
      ...props.newLpDetails,
      id: nanoid(),
      moduleId: "",
      libTypeId: 2,
      isAddTest: false,
      modules: JSON.parse(JSON.stringify(modules)),
    });
  };

  return (
    <>
      {!props.isFromBatch && (
        <div style={{ textAlign: "left", margin: "0 0 20px 0" }}>
          <FontAwesomeIcon
            className="adminLpBackBtn"
            onClick={() =>
              props.isOldLpDetail
                ? handleBackBtn()
                : props.setIsAddLpModule(false)
            }
            icon={faArrowLeft}
          />
          &nbsp;
          <span className="adminLpBack" /* onClick={handleBackBtn} */>
            Path
          </span>
        </div>
      )}
      {props.isFromBatch && (
        <div className="bdCourseHeadSec">
          <p className="bdCoursesInCourseName">{props.newLpDetails.lpName}</p>
          <Edit03
            onClick={handleBackBtn}
            width={20}
            height={20}
            strokeWidth="1.2"
            stroke="#344054"
          />
        </div>
      )}
      <div
        className="adminmodule-container"
        style={
          !props.isOldLpDetail
            ? {
                maxHeight: "calc(100vh - 32.5vh)",
                minHeight: "calc(100vh - 40vh)",
                overflow: "auto",
              }
            : {}
        }
      >
        <DndProvider backend={HTML5Backend}>
          <Table
            key={nanoid()}
            columns={moduleData}
            pagination={false}
            components={moduleComponents}
            onRow={(record, index) => ({
              index,
              movemodules,
              onClick: () => setExpandedRecords(record),
            })}
            expandable={{
              expandedRowRender: (record) => {
                return (
                  <div className="adminModuleDetailsSec" key={record.id}>
                    <DndProvider backend={HTML5Backend}>
                      <Table
                        key={nanoid()}
                        columns={moduleDetails}
                        dataSource={record.moduleData}
                        pagination={false}
                        components={moduleDetailsComponents}
                        onRow={(record, index) => ({
                          index,
                          movemoduleDetails,
                        })}
                      />
                    </DndProvider>
                    <div className="adminModuleAddDetailSec">
                      <div className="adminModuleAddBtns">
                        <p
                          className="adminModuleAddContent"
                          onClick={() =>
                            handleAddModuleDetails(
                              record.id,
                              constants.libTypeIds.CONTENT,
                              record.key
                            )
                          }
                        >
                          <FontAwesomeIcon
                            className="adminModuleAddDetailIcon"
                            icon={faCirclePlus}
                          />
                          &nbsp;Content
                        </p>
                        <p
                          className="adminModuleAddTest"
                          onClick={() =>
                            handleAddModuleDetails(
                              record.id,
                              constants.libTypeIds.MCQ,
                              record.key
                            )
                          }
                        >
                          <FontAwesomeIcon
                            className="adminModuleAddDetailIcon"
                            icon={faCirclePlus}
                          />
                          &nbsp;Mcq Test
                        </p>
                        <p
                          className="adminModuleAddTest"
                          onClick={() =>
                            handleAddModuleDetails(
                              record.id,
                              constants.libTypeIds.DESCRIPTIVE,
                              record.key
                            )
                          }
                        >
                          <FontAwesomeIcon
                            className="adminModuleAddDetailIcon"
                            icon={faCirclePlus}
                          />
                          &nbsp;Descriptive Test
                        </p>
                        <p
                          className="adminModuleAddCoding"
                          onClick={() =>
                            handleAddModuleDetails(
                              record.id,
                              constants.libTypeIds.CODING_CHALLENGE,
                              record.key
                            )
                          }
                        >
                          <FontAwesomeIcon
                            className="adminModuleAddDetailIcon"
                            icon={faCirclePlus}
                          />
                          &nbsp;Coding challenge
                        </p>
                        <p
                          className="adminModuleAddAssignment"
                          onClick={() =>
                            handleAddModuleDetails(
                              record.id,
                              constants.libTypeIds.ASSIGNMENT,
                              record.key
                            )
                          }
                        >
                          <FontAwesomeIcon
                            className="adminModuleAddDetailIcon"
                            icon={faCirclePlus}
                          />
                          &nbsp;Assignment
                        </p>
                      </div>
                    </div>
                  </div>
                );
              },
              expandIcon: ({ expanded, onExpand, record }) =>
                expanded ? (
                  <>
                    {modules.map(
                      (module, index) =>
                        module.id === record.id && (
                          <div className="adminModuleSec" key={nanoid()}>
                            <div className="adminModuleContainer">
                              <div className="adminModuleLeftSec">
                                <svg
                                  width="6"
                                  height="12"
                                  viewBox="0 0 6 12"
                                  className="adminModuleMoveIcon"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M0.857 10.286C1.331 10.286 1.714 10.669 1.714 11.143C1.714 11.616 1.331 12 0.857 12C0.384 12 0 11.616 0 11.143C0 10.669 0.384 10.286 0.857 10.286ZM5.143 10.286C5.616 10.286 6 10.669 6 11.143C6 11.616 5.616 12 5.143 12C4.669 12 4.286 11.616 4.286 11.143C4.286 10.669 4.669 10.286 5.143 10.286ZM0.857 6.857C1.331 6.857 1.714 7.241 1.714 7.714C1.714 8.188 1.331 8.571 0.857 8.571C0.384 8.571 0 8.188 0 7.714C0 7.241 0.384 6.857 0.857 6.857ZM5.143 6.857C5.616 6.857 6 7.241 6 7.714C6 8.188 5.616 8.571 5.143 8.571C4.669 8.571 4.286 8.188 4.286 7.714C4.286 7.241 4.669 6.857 5.143 6.857ZM0.857 3.43C1.331 3.43 1.714 3.813 1.714 4.287C1.714 4.76 1.331 5.144 0.857 5.144C0.384 5.144 0 4.76 0 4.287C0 3.813 0.384 3.43 0.857 3.43ZM5.143 3.43C5.616 3.43 6 3.813 6 4.287C6 4.76 5.616 5.144 5.143 5.144C4.669 5.144 4.286 4.76 4.286 4.287C4.286 3.813 4.669 3.43 5.143 3.43ZM0.857 0C1.331 0 1.714 0.384 1.714 0.857C1.714 1.331 1.331 1.714 0.857 1.714C0.384 1.714 0 1.331 0 0.857C0 0.384 0.384 0 0.857 0ZM5.143 0C5.616 0 6 0.384 6 0.857C6 1.331 5.616 1.714 5.143 1.714C4.669 1.714 4.286 1.331 4.286 0.857C4.286 0.384 4.669 0 5.143 0Z"
                                    fill="#26344A"
                                  />
                                </svg>
                                <input
                                  className="adminModuleName"
                                  name="moduleName"
                                  id={record.id}
                                  maxLength={100}
                                  title={record.moduleName}
                                  placeholder="Enter module name"
                                  ref={focus && focus == record.id ? ref : null}
                                  value={record.moduleName}
                                  onChange={(event) =>
                                    handleModuleName(event, record.id, index)
                                  }
                                  disabled={
                                    record.status === "old" ? true : false
                                  }
                                />
                              </div>
                              <div
                                className="adminModuleIconSec"
                                style={{ cursor: "pointer" }}
                              >
                                {/* {props.isOldLpDetail && props.isFromBatch && <span className='adminModuleDueDate'>{module.dueDate !== "" && `DUEDATE: ${(new Date(module.dueDate).getDate() > 9 ? new Date(module.dueDate).getDate() : `0${new Date(module.dueDate).getDate()}`) + "/" +
														((new Date(module.dueDate).getMonth() + 1) > 9 ? (new Date(module.dueDate).getMonth() + 1) : `0${new Date(module.dueDate).getMonth() + 1}`) +
														"/" +
														new Date(module.dueDate).getFullYear()}`}</span>} */}
                                {showModDuedays && (
                                  <>
                                    {" "}
                                    <input
                                      type="text"
                                      className="adminModuleDuedays"
                                      placeholder="Due"
                                      id={record.id}
                                      value={record.duedays ?? ""}
                                      onChange={(event) =>
                                        onChangeModDay(event, record.id)
                                      }
                                      // onFocus={() => setModDuedayFocus(record.id)}
                                      ref={
                                        modDuedayFocus &&
                                        parseInt(modDuedayFocus) ===
                                          parseInt(record.id)
                                          ? duedayRef
                                          : null
                                      }
                                    />
                                    <span className="adminModuleDuedaysText">
                                      Days
                                    </span>
                                  </>
                                )}
                                <FontAwesomeIcon
                                  className="adminModuleExpandIcon"
                                  icon={faChevronDown}
                                  onClick={(e) => {
                                    onExpand(record, e);
                                    props.setModuleExpandKey([undefined]);
                                  }}
                                />
                                {record.status === "new" && (
                                  <svg
                                    width="20"
                                    height="20"
                                    viewBox="0 0 12 12"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="adminModuleDeleteIcon"
                                    onClick={() => handleDeleteModule(record)}
                                  >
                                    <path
                                      d="M5.02196 4.93363C5.29225 4.93363 5.5142 5.14057 5.53803 5.40465L5.54015 5.45182V8.74091C5.54015 9.02709 5.30815 9.25909 5.02196 9.25909C4.75167 9.25909 4.52972 9.05216 4.50589 8.78807L4.50377 8.74091V5.45182C4.50377 5.16563 4.73577 4.93363 5.02196 4.93363Z"
                                      fill="#F55533"
                                    />
                                    <path
                                      d="M7.73075 5.40465C7.70693 5.14057 7.48497 4.93363 7.21469 4.93363C6.9285 4.93363 6.6965 5.16563 6.6965 5.45182V8.74091L6.69862 8.78807C6.72244 9.05216 6.9444 9.25909 7.21469 9.25909C7.50087 9.25909 7.73287 9.02709 7.73287 8.74091V5.45182L7.73075 5.40465Z"
                                      fill="#F55533"
                                    />
                                    <path
                                      fillRule="evenodd"
                                      clipRule="evenodd"
                                      d="M7.21468 0C8.08291 0 8.79107 0.685314 8.82774 1.54451L8.82924 1.61455V2.19273H11.052C11.3381 2.19273 11.5701 2.42473 11.5701 2.71091C11.5701 2.9812 11.3632 3.20315 11.0991 3.22698L11.052 3.2291H10.4738V10.3855C10.4738 11.2537 9.78847 11.9618 8.92927 11.9985L8.85923 12H3.37742C2.50919 12 1.80103 11.3147 1.76436 10.4555L1.76287 10.3855V3.2291H1.18469C0.898504 3.2291 0.666504 2.9971 0.666504 2.71091C0.666504 2.44062 0.873442 2.21867 1.13753 2.19484L1.18469 2.19273H3.40741V1.61455C3.40741 0.746324 4.09272 0.0381621 4.95192 0.00149169L5.02196 0H7.21468ZM2.79924 3.2291V10.3855C2.79924 10.6888 3.03286 10.9376 3.33 10.9617L3.37742 10.9636H8.85923C9.16258 10.9636 9.41137 10.73 9.43549 10.4329L9.4374 10.3855V3.2291H2.79924ZM7.79286 2.19273H4.44378V1.61455L4.4457 1.56713C4.46982 1.26999 4.71861 1.03637 5.02196 1.03637H7.21468L7.2621 1.03829C7.55924 1.06241 7.79286 1.3112 7.79286 1.61455V2.19273Z"
                                      fill="#F55533"
                                    />
                                  </svg>
                                )}
                                {record.status === "old" &&
                                  module.moduleData.length == 0 && (
                                    <svg
                                      width="20"
                                      height="20"
                                      viewBox="0 0 12 12"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                      className="adminModuleDeleteIcon"
                                      onClick={() =>
                                        handleCreatedDeleteModule(record)
                                      }
                                    >
                                      <path
                                        d="M5.02196 4.93363C5.29225 4.93363 5.5142 5.14057 5.53803 5.40465L5.54015 5.45182V8.74091C5.54015 9.02709 5.30815 9.25909 5.02196 9.25909C4.75167 9.25909 4.52972 9.05216 4.50589 8.78807L4.50377 8.74091V5.45182C4.50377 5.16563 4.73577 4.93363 5.02196 4.93363Z"
                                        fill="#F55533"
                                      />
                                      <path
                                        d="M7.73075 5.40465C7.70693 5.14057 7.48497 4.93363 7.21469 4.93363C6.9285 4.93363 6.6965 5.16563 6.6965 5.45182V8.74091L6.69862 8.78807C6.72244 9.05216 6.9444 9.25909 7.21469 9.25909C7.50087 9.25909 7.73287 9.02709 7.73287 8.74091V5.45182L7.73075 5.40465Z"
                                        fill="#F55533"
                                      />
                                      <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M7.21468 0C8.08291 0 8.79107 0.685314 8.82774 1.54451L8.82924 1.61455V2.19273H11.052C11.3381 2.19273 11.5701 2.42473 11.5701 2.71091C11.5701 2.9812 11.3632 3.20315 11.0991 3.22698L11.052 3.2291H10.4738V10.3855C10.4738 11.2537 9.78847 11.9618 8.92927 11.9985L8.85923 12H3.37742C2.50919 12 1.80103 11.3147 1.76436 10.4555L1.76287 10.3855V3.2291H1.18469C0.898504 3.2291 0.666504 2.9971 0.666504 2.71091C0.666504 2.44062 0.873442 2.21867 1.13753 2.19484L1.18469 2.19273H3.40741V1.61455C3.40741 0.746324 4.09272 0.0381621 4.95192 0.00149169L5.02196 0H7.21468ZM2.79924 3.2291V10.3855C2.79924 10.6888 3.03286 10.9376 3.33 10.9617L3.37742 10.9636H8.85923C9.16258 10.9636 9.41137 10.73 9.43549 10.4329L9.4374 10.3855V3.2291H2.79924ZM7.79286 2.19273H4.44378V1.61455L4.4457 1.56713C4.46982 1.26999 4.71861 1.03637 5.02196 1.03637H7.21468L7.2621 1.03829C7.55924 1.06241 7.79286 1.3112 7.79286 1.61455V2.19273Z"
                                        fill="#F55533"
                                      />
                                    </svg>
                                  )}
                              </div>
                            </div>
                          </div>
                        )
                    )}
                  </>
                ) : (
                  <>
                    {modules.map(
                      (module, index) =>
                        module.id === record.id && (
                          <div className="adminModuleSec" key={nanoid()}>
                            <div className="adminModuleContainer">
                              <div className="adminModuleLeftSec">
                                <svg
                                  width="6"
                                  height="12"
                                  viewBox="0 0 6 12"
                                  className="adminModuleMoveIcon"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M0.857 10.286C1.331 10.286 1.714 10.669 1.714 11.143C1.714 11.616 1.331 12 0.857 12C0.384 12 0 11.616 0 11.143C0 10.669 0.384 10.286 0.857 10.286ZM5.143 10.286C5.616 10.286 6 10.669 6 11.143C6 11.616 5.616 12 5.143 12C4.669 12 4.286 11.616 4.286 11.143C4.286 10.669 4.669 10.286 5.143 10.286ZM0.857 6.857C1.331 6.857 1.714 7.241 1.714 7.714C1.714 8.188 1.331 8.571 0.857 8.571C0.384 8.571 0 8.188 0 7.714C0 7.241 0.384 6.857 0.857 6.857ZM5.143 6.857C5.616 6.857 6 7.241 6 7.714C6 8.188 5.616 8.571 5.143 8.571C4.669 8.571 4.286 8.188 4.286 7.714C4.286 7.241 4.669 6.857 5.143 6.857ZM0.857 3.43C1.331 3.43 1.714 3.813 1.714 4.287C1.714 4.76 1.331 5.144 0.857 5.144C0.384 5.144 0 4.76 0 4.287C0 3.813 0.384 3.43 0.857 3.43ZM5.143 3.43C5.616 3.43 6 3.813 6 4.287C6 4.76 5.616 5.144 5.143 5.144C4.669 5.144 4.286 4.76 4.286 4.287C4.286 3.813 4.669 3.43 5.143 3.43ZM0.857 0C1.331 0 1.714 0.384 1.714 0.857C1.714 1.331 1.331 1.714 0.857 1.714C0.384 1.714 0 1.331 0 0.857C0 0.384 0.384 0 0.857 0ZM5.143 0C5.616 0 6 0.384 6 0.857C6 1.331 5.616 1.714 5.143 1.714C4.669 1.714 4.286 1.331 4.286 0.857C4.286 0.384 4.669 0 5.143 0Z"
                                    fill="#26344A"
                                  />
                                </svg>
                                <input
                                  className="adminModuleName"
                                  name="moduleName"
                                  id={record.id}
                                  placeholder="Enter module name"
                                  maxLength={100}
                                  title={record.moduleName}
                                  ref={focus && focus == record.id ? ref : null}
                                  value={record.moduleName}
                                  onChange={(event) =>
                                    handleModuleName(event, record.id, index)
                                  }
                                  onKeyUp={(event) =>
                                    handleEnterKey(event, record)
                                  }
                                  disabled={
                                    record.status === "old" ? true : false
                                  }
                                />
                              </div>
                              <div
                                className="adminModuleIconSec"
                                style={{ cursor: "pointer" }}
                              >
                                {/* {props.isOldLpDetail && props.isFromBatch && <span className='adminModuleDueDate'>{module.dueDate !== "" && `DUEDATE: ${(new Date(module.dueDate).getDate() > 9 ? new Date(module.dueDate).getDate() : `0${new Date(module.dueDate).getDate()}`) + "/" +
														((new Date(module.dueDate).getMonth() + 1) > 9 ? (new Date(module.dueDate).getMonth() + 1) : `0${new Date(module.dueDate).getMonth() + 1}`) +
														"/" +
														new Date(module.dueDate).getFullYear()}`}</span>}*/}
                                {showModDuedays && (
                                  <>
                                    <input
                                      type="text"
                                      className="adminModuleDuedays"
                                      placeholder="Due"
                                      id={record.id}
                                      value={record.duedays ?? ""}
                                      onChange={(event) =>
                                        onChangeModDay(event, record.id)
                                      }
                                      // onFocus={() => setModDuedayFocus(record.id)}
                                      ref={
                                        modDuedayFocus &&
                                        parseInt(modDuedayFocus) ===
                                          parseInt(record.id)
                                          ? duedayRef
                                          : null
                                      }
                                    />
                                    <span className="adminModuleDuedaysText">
                                      Days
                                    </span>
                                  </>
                                )}{" "}
                                <FontAwesomeIcon
                                  className="adminModuleExpandIcon"
                                  icon={faChevronRight}
                                  onClick={(e) => {
                                    onExpand(record, e);
                                    props.setModuleExpandKey([record.key]);
                                  }}
                                />
                                {record.status === "new" && (
                                  <svg
                                    width="20"
                                    height="20"
                                    viewBox="0 0 12 12"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="adminModuleDeleteIcon"
                                    onClick={() => handleDeleteModule(record)}
                                  >
                                    <path
                                      d="M5.02196 4.93363C5.29225 4.93363 5.5142 5.14057 5.53803 5.40465L5.54015 5.45182V8.74091C5.54015 9.02709 5.30815 9.25909 5.02196 9.25909C4.75167 9.25909 4.52972 9.05216 4.50589 8.78807L4.50377 8.74091V5.45182C4.50377 5.16563 4.73577 4.93363 5.02196 4.93363Z"
                                      fill="#F55533"
                                    />
                                    <path
                                      d="M7.73075 5.40465C7.70693 5.14057 7.48497 4.93363 7.21469 4.93363C6.9285 4.93363 6.6965 5.16563 6.6965 5.45182V8.74091L6.69862 8.78807C6.72244 9.05216 6.9444 9.25909 7.21469 9.25909C7.50087 9.25909 7.73287 9.02709 7.73287 8.74091V5.45182L7.73075 5.40465Z"
                                      fill="#F55533"
                                    />
                                    <path
                                      fillRule="evenodd"
                                      clipRule="evenodd"
                                      d="M7.21468 0C8.08291 0 8.79107 0.685314 8.82774 1.54451L8.82924 1.61455V2.19273H11.052C11.3381 2.19273 11.5701 2.42473 11.5701 2.71091C11.5701 2.9812 11.3632 3.20315 11.0991 3.22698L11.052 3.2291H10.4738V10.3855C10.4738 11.2537 9.78847 11.9618 8.92927 11.9985L8.85923 12H3.37742C2.50919 12 1.80103 11.3147 1.76436 10.4555L1.76287 10.3855V3.2291H1.18469C0.898504 3.2291 0.666504 2.9971 0.666504 2.71091C0.666504 2.44062 0.873442 2.21867 1.13753 2.19484L1.18469 2.19273H3.40741V1.61455C3.40741 0.746324 4.09272 0.0381621 4.95192 0.00149169L5.02196 0H7.21468ZM2.79924 3.2291V10.3855C2.79924 10.6888 3.03286 10.9376 3.33 10.9617L3.37742 10.9636H8.85923C9.16258 10.9636 9.41137 10.73 9.43549 10.4329L9.4374 10.3855V3.2291H2.79924ZM7.79286 2.19273H4.44378V1.61455L4.4457 1.56713C4.46982 1.26999 4.71861 1.03637 5.02196 1.03637H7.21468L7.2621 1.03829C7.55924 1.06241 7.79286 1.3112 7.79286 1.61455V2.19273Z"
                                      fill="#F55533"
                                    />
                                  </svg>
                                )}
                              </div>
                            </div>
                          </div>
                        )
                    )}
                  </>
                ),
              expandedRowKeys: props.moduleExpandKey,
            }}
            dataSource={modules}
            size="small"
          />
        </DndProvider>
        <p className="adminModuleAddModIcon" onClick={handleAddModule}>
          <FontAwesomeIcon
            icon={faCirclePlus}
            className="adminModuleAddDetailIcon"
          />
          &nbsp;Add module
        </p>
      </div>
      <div className="adminLpBtnSec">
        <button className="adminLpCancelBtn" onClick={handleCancel}>
          Cancel
        </button>
        <button className="adminLpProceedBtn" onClick={handleProceed}>
          {props.isOldLpDetail || props.isFromBatch ? "Update" : "Proceed"}
        </button>
        {/* {!props.isFromBatch &&<div className='adminTestSecondaryBtn'>
					<ButtonMedium btnText2='Cancel' secondaryBtn={true} onHideSecondary={handleCancel} />
				</div>}
				<div>
					<ButtonMedium btnText={props.isOldLpDetail || props.isFromBatch ? 'Update' : 'Proceed'} primaryBtn={true} onHide={handleProceed} />
				</div> */}
      </div>

      {/* <Modal show={successRes.isSuccess} style={{
				fontFamily: 'Inter', fontstyle: "normal", fontWeight: "700",
				fontSize: "14px", lineHeight: "16px"
			}} centered onHide={handleSuccessResponse}>
				{successRes.isSuccess && !successRes.isProctored && <Modal.Header closeButton>
					<Modal.Title>{successRes.title}</Modal.Title>
				</Modal.Header>}
				<Modal.Body style={{ height: '60px' }}>{successRes.msg}
					{successRes.isSuccess && successRes.isProctored && <div style={{
						fontFamily: 'Inter', fontstyle: "normal", fontWeight: "500",
						fontSize: "12px", lineHeight: "16px", margin: '5px 0 0 25px'
					}}>
						<input type='checkbox' name='1' id='1' value={1} checked={proctored[1]} onChange={handleChangeProctorDetail} />&nbsp;<label>Tab switch</label>&nbsp;&nbsp;&nbsp;
						<input type='checkbox' name='2' id='2' value={2} checked={proctored[2]} onChange={handleChangeProctorDetail} />&nbsp;<label>Tab switch + Face recognition</label>
					</div>}
				</Modal.Body>
				<Modal.Footer style={{ margin: '10px', padding: '0px' }}>
					{successRes.isSuccess && !successRes.isProctored ? <Button variant="danger" style={{ margin: '10px 10px 0 0' }} onClick={handleSuccessResponse}>
						ok
					</Button> : <><Button variant="primary" style={{ margin: '10px 10px 0 0' }} disabled={(proctored[1] || proctored[2]) ? false : true} onClick={() => handlePublish(true)}>
						Yes
					</Button>
						<Button variant="danger" style={{ margin: '10px 10px 0 0' }} onClick={() => handlePublish(false)}>
							No
						</Button></>}

				</Modal.Footer>
			</Modal> */}

      {
        /* successRes.isProctored */ isCommonPopup && (
          <div className="popup-overlay">
            <Popup
            // isPopup={successRes.isSuccess}
            // primaryOnclick={primaryOnclick}
            // title={"Publish the test"}
            // desc={"Select when and how you would like to publish the test"}
            // setSuccessRes={setSuccessRes}
            // popupBodyComponent={
            // 	<PublishCourse
            // 		handleChangeProctorDetail={handleChangeProctorDetail}
            // 		dataForPublish={dataForPublish}
            // 	/>
            // }
            // btnSize={"small"}
            // primBtnTxt={"Publish Test"}
            // secBtnTxt={"Cancel"}
            // popupCloseIcon={true}
            // secondaryOnclick={handleClosePopup}
            />
          </div>
        )
      }
      {successRes.isSuccess && !successRes.isProctored && (
        <Modal
          show={successRes.isSuccess}
          style={{
            fontFamily: "Inter",
            fontstyle: "normal",
            fontWeight: "700",
            fontSize: "14px",
            lineHeight: "16px",
          }}
          centered
          onHide={handleSuccessResponse}
        >
          <Modal.Header closeButton>
            <Modal.Title>{successRes.title}</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ height: "60px" }}>{successRes.msg}</Modal.Body>
          <Modal.Footer style={{ margin: "10px", padding: "0px" }}>
            <Button
              variant="danger"
              style={{ margin: "10px 10px 0 0" }}
              onClick={handleSuccessResponse}
            >
              ok
            </Button>
          </Modal.Footer>
        </Modal>
      )}

      {/* {(props.isOldLpDetail&&props.isFromBatch&&props.isLoaded)?<Loading/>:null} */}

      {/* <Dialog
        onClose={() => setShowModal(false)}
        open={showModal}
        id="adminLpModuleExtTimeBox"
      >
        <DialogTitle id="adminLpModuleExtTimeTitle">
          Set new deadline{" "}
        </DialogTitle>
        <Divider />
        <div>
          <span className="adminLpModuleExtTimeLabel">
            Select the date : &nbsp;
          </span>
          <DatePicker
            dateFormat="dd/MMM/yyyy h:mm aa"
            name="startDate"
            // selected={new Date(modData.dueDate)}
            minDate={new Date()}
            showTimeInput
            // onFocus={()=>setShowOpenDate(true)}
            // onClickOutside={()=>setShowOpenDate(false)}
            // onBlur={()=>setShowOpenDate(false)}
            // open={showOpenDate}
            timeInputLabel="Time:"
            autoComplete="off"
            placeholderText="Pick the date"
            className="adminModuleDetailDueDate adminModuleDetailName"
            selected={new Date(dataForUpdateDueDate.current.oldDueDate)}
            // value={new Date(modData.dueDate).toLocaleDateString('fr-FR')}
            onChange={(date) =>
              handleUpdateDueDate(
                date,
                dataForUpdateDueDate.current.lpcid,
                dataForUpdateDueDate.current.moduleId
              )
            }
          ></DatePicker>
          <div className="adminLpModuleExtTimeBoxWarningSec">
            <WarningIcon id="adminLpModuleExtTimeWarningIcon" /> This duedate
            will apply to all learners
          </div>
          <div className="adminLpModuleExtTimeBoxBtn">
            <Button
              id="adminLpModuleExtTimeBoxSaveBtn"
              onClick={handleSaveDueDate}
            >
              Save
            </Button>
            <Button
              id="adminLpModuleExtTimeBoxCancelBtn"
              onClick={() => setShowModal(false)}
            >
              Cancel
            </Button>
          </div>
        </div>
      </Dialog> */}

      {/* <Modal show={showModal} style={{
				fontFamily: 'Inter', fontstyle: "normal", fontWeight: "700",
				fontSize: "14px", lineHeight: "16px"
			}} centered >
				<Modal.Body style={{ height: '200px' }}>
					<span>Select the date : &nbsp;</span>
					<DatePicker
						dateFormat="dd/MMM/yyyy h:mm aa"
						name="startDate"
						// selected={new Date(modData.dueDate)}
						minDate={new Date()}
						showTimeInput
						
						timeInputLabel="Time:"
						autoComplete="off"
						placeholderText="Pick the date"
						className='adminModuleDetailDueDate adminModuleDetailName'
						selected={new Date(dataForUpdateDueDate.oldDueDate)}
						// value={new Date(modData.dueDate).toLocaleDateString('fr-FR')}
						onChange={(date) => handleUpdateDueDate(date, dataForUpdateDueDate.lpcid, dataForUpdateDueDate.moduleId)}
					>
					</DatePicker>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="primary" style={{ margin: '10px 10px 0 0' }} onClick={handleSaveDueDate} >
						Save
					</Button>
					<Button variant="danger" style={{ margin: '10px 10px 0 0' }} onClick={() => setShowModal(false)}>
						Cancel
					</Button>
				</Modal.Footer>
			</Modal> */}
    </>
  );
};

export default Modules;
