import React, { useContext } from "react";
import AlertContext from "../../../context/AlertContext";
import AdminLearningPath from "../Learningpath/AdminLearningPath";

const Courses = (props) => {
  const {
    lpData2,
    batchDetails
  } = useContext(AlertContext);

  lpData2.batchStartDate = batchDetails.startdate;

  return (
    <div>
      <div className="bdCourseDetailsSec">
        {/* <Modules
          setIsAddLp={setIsAddLp}
          setIsFromBatch={setIsFromBatch}
          isFromBatch={isFromBatch}
          setIsAddLpModule={setIsAddLpModule}
          lpData={lpData2}
          setLpData={setLpData2}
          show={show}
          setShow={setShow}
          msg={msg}
          setMsg={setMsg}
          title={title}
          setTitle={setTitle}
          isLoaded={isLoaded}
          setIsLoaded={setIsLoaded}
          isUnAuth={isUnAuth}
          setIsUnAuth={setIsUnAuth}
          handleGetLibIdFromLp={handleGetLibIdFromLp}
          handleEditTestConfig={handleEditTestConfig}
          setIsOldLpDetail={setIsOldLpDetail}
          isOldLpDetail={isOldLpDetail}
          quereport={props.quereport}
          setisqueReport={props.setisqueReport}
          setTableShowStatus={props.setTableShowStatus}
          setModuleExpandKey={setModuleExpandKey}
          moduleExpandKey={moduleExpandKey}
          setIsFromLp={setIsFromLp}
        /> */}
        <AdminLearningPath
        quereport={props.quereport}
        setisqueReport={props.setisqueReport}
        setTableShowStatus={props.setTableShowStatus}/>
      </div>
    </div>
  );
};

export default Courses;
