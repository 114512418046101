import React, { useContext } from "react";
import { GlobalFilter } from "./GlobalFilter";
import Container from "./DropdownContainer";
import { Button } from "@mui/material";
import AlertContext from "../../../../context/AlertContext";

const ReportFilterAndSearch = ({
  globalFilter,
  setGlobalFilter,
  // topicFilData,
  // typesAndModules,
  status,
  handleOnChange,
  handleApplyStatusFilters,
  handleApplyBlockFilters,
  getExcel,
  handleGetReport,
}) => {
  const { reportTopics, typesAndModules, lpId } = useContext(AlertContext);

  return (
    <div className="report-options">
      <div
        className="report-options-refresh"
        title="Refresh"
        onClick={() => handleGetReport(lpId)}
      >
        <div className="report-options-refresh-wrapper">
          <svg
            xlinkTitle="Refresh"
            className="report-options-refresh-icon"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.33333 6.66667C1.33333 6.66667 2.66999 4.84548 3.75589 3.75883C4.84179 2.67218 6.3424 2 8 2C11.3137 2 14 4.68629 14 8C14 11.3137 11.3137 14 8 14C5.2646 14 2.95674 12.1695 2.23451 9.66667M1.33333 6.66667V2.66667M1.33333 6.66667L5.33333 6.66667"
              stroke="#667085"
              stroke-width="1.6"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
      </div>
      <div className="report-options-search">
        <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
      </div>
      <div className="report-selection-any">
        <Container
          data={reportTopics}
          onChange={handleOnChange}
          showPartiallySelected={true}
          texts={{ placeholder: "Topics" }}
          // updateContext={setprevreporttopics}
        />
      </div>

      <div className="report-selection-type-module">
        <Container
          data={typesAndModules}
          onChange={handleApplyBlockFilters}
          texts={{ placeholder: "Types" }}
          //  updateContext={
          //   setprevreporttypes
          // }
        />
      </div>

      <div className="report-selection-type-module-status">
        <Container
          data={status}
          onChange={handleApplyStatusFilters}
          mode="radioSelect"
          texts={{ placeholder: "Status" }}
          // updateContext={setprevreportstatus}
        />
      </div>

      <div className="table-options-get-excel-button">
        <Button
          sx={{
            bgcolor: "white",
            color: "#344054",
            "&:hover": {
              backgroundColor: "white",
              color: "orange",
            },
            boxShadow: 0,
            borderRadius: 2,
            // p: 2,
            minWidth: 105,
            height: 40,
            // mt: -1,
            border: 1,
            borderColor: "#D0D5DD",
          }}
          onClick={getExcel}
          variant="contained"
          href="#contained-buttons"
        >
          <span className="report-options-export-button-icon">
            <svg
              width="20"
              height="18"
              viewBox="0 0 20 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.66663 13.1667L9.99996 16.5M9.99996 16.5L13.3333 13.1667M9.99996 16.5V9M16.6666 12.9524C17.6845 12.1117 18.3333 10.8399 18.3333 9.41667C18.3333 6.88536 16.2813 4.83333 13.75 4.83333C13.5679 4.83333 13.3975 4.73833 13.3051 4.58145C12.2183 2.73736 10.212 1.5 7.91663 1.5C4.46485 1.5 1.66663 4.29822 1.66663 7.75C1.66663 9.47175 2.36283 11.0309 3.48908 12.1613"
                stroke="#344054"
                strokeWidth="1.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
          <span className="report-options-export-button-label">Export</span>
        </Button>
      </div>
    </div>
  );
};

export default ReportFilterAndSearch;
