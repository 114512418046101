import React from 'react'

export default function Coding_R_Svg() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="24" height="24" rx="12" fill="#F97256"/>
        <path d="M15.332 15.3333L18.6654 12L15.332 8.66667M8.66536 8.66667L5.33203 12L8.66536 15.3333M13.332 6L10.6654 18" stroke="white" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  )
}
