import React, { useState, useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Loading from "../../Notification-Loading/Loading/Loading";
import { TextField, InputAdornment, IconButton } from "@material-ui/core";
import { Search as SearchIcon } from "@material-ui/icons";
import { getDataFromStorage } from "../../../util";
import { useNavigate } from "react-router-dom";
import useAxiosPrivate from "../../../Hooks/useAxiosPrivate";
import AlertContext from "../../../context/AlertContext";
import constants from "../../../constants";
import ModuleHooks from "../../../Hooks/ModuleHooks";
import Popup from "../../CommonComponents/Popup";
const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  inputRoot: {
    height: "35px",
    background: "none",
    boxShadow: "none",
  },
  dashBoardField: {
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "20px",
    textAlign: "left",
    background: "none",
    boxShadow: "none",
  },
  rowDashboard: {
    transition: "background-color 0.3s",
    "&:hover": {
      backgroundColor: "#F9FAFB",
    },
  },
  headerCol: {
    fontFamily: "Inter",
    fontSize: "12px",
    fontWeight: "500",
    lineHeight: "20px",
    textAlign: "left",
  },
  dashBoardName: {
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "20px",
    textAlign: "left",
    color: "#101828",
    background: "none",
    boxShadow: "none",
  },
});
export default function DashBoardTable() {
  const { handlePopupStates, clearPopupStates } = ModuleHooks();
  const classes = useStyles();
  const [dashBoardData, setDashBoardData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const axios = useAxiosPrivate();
  const navigate = useNavigate();
  const {
    currentEditDashboardMetaData,
    setCurrentEditDashboardMetaData,
    isDashBoardEdit,
    setIsDashBoardEdit,
    setCurrentDashboardMetaData,
    isCommonPopup,
    setIsUnAuth,
    isAdmin,
    isLoaded,
    setIsLoaded,
     setDbStatus,
    dashBoardId
  } = useContext(AlertContext);

  const handleCloseResponsePopup = () => {
    clearPopupStates();
    getDashboards();
  };

  const handleWarningResponsePopupClose = () => {
    clearPopupStates();
  };

  const handleResponse = (title, msg) => {
    let popupdata = {};
    popupdata.showpopup = true;
    popupdata.title = title;
    popupdata.bodydesc = msg;
    popupdata.singlebtn = true;
    popupdata.singlebtntxt = "Ok";
    popupdata.singlebtnsize = "small";
    popupdata.singlebtnfunt =
      title == "Success"
        ? handleCloseResponsePopup
        : handleWarningResponsePopupClose;
    handlePopupStates(popupdata);
  };

  const getDashboards = async () => {
    try {
      setIsLoaded(true);
      let response = await axios.get("node/admin/dashboard", {
        headers: {
          "Content-Type": "application/json",
        },
      });
      setIsLoaded(false);
      setDashBoardData(response?.data?.data);
    } catch (err) {
      setIsLoaded(false);
      if (err.message.includes("403")) {
        handleResponse("Error", "You have been logged-out due to inactivity. Login again.");
        setIsUnAuth(true);
      } else {
        handleResponse("Error", err.message);
      }
    }
  };

  const handleDeleteDashBoard = (id) => {
    try {
      
      const responseOfDeleteDashboard = async () => {
        setIsLoaded(true);
        let response = await axios.patch(
          "node/admin/dashboard/" + id + "/status",
          { status: constants.BOARD_STATUS.DELETED },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        setIsLoaded(false);

        if (response.data.resultCode == constants.RESULT_STATUS.SUCCESS) {
          console.log("coming in iff");
          handleResponse("Success", response.data.msg);
        } else {
          console.log("coming in elseee");
          handleResponse("Warning", response.data.msg);
        }

      // getDashboards();
    };
    responseOfDeleteDashboard();
  }catch(err){
    setIsLoaded(false);
    if (err.message.includes("403")) {
      handleResponse("Error", "You have been logged-out due to inactivity. Login again.");
      setIsUnAuth(true);
    } else {
      handleResponse("Error", err.message);
    }

  }
  };

  const handleCopyDashboard = (id) => {
    try {
      setIsLoaded(true);
      const responseAfterCopyingDashboard = async () => {
        let response = await axios.patch(
          "node/admin/dashboard/" + id + "/copy",
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        setIsLoaded(false);
        if (response.data.resultCode == constants.RESULT_STATUS.SUCCESS) {
          console.log("coming in iff");
          handleResponse("Success", response.data.msg);
        } else {
          console.log("coming in elseee");
          handleResponse("Warning", response.data.msg);
        }

      console.log("response.data",response.data);
      // getDashboards();
    };
    responseAfterCopyingDashboard();
  }catch(err){
    setIsLoaded(false);
    if (err.message.includes("403")) {
      handleResponse("Error", "You have been logged-out due to inactivity. Login again.");
      setIsUnAuth(true);
    } else {
      handleResponse("Error", err.message);
    }
  }
  };

  const filteredData = dashBoardData?.filter((item) =>
    item?.name?.toLowerCase()?.includes(searchQuery?.toLowerCase())
  );

  const handleEditDashBoard = (id) => {
  try{
    const responseOfEditDashboard = async () => {
      setIsLoaded(true);
      let response = await axios.get("node/admin/dashboard/" + id, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      setIsLoaded(false);
      setCurrentEditDashboardMetaData(response?.data?.data);
      setIsDashBoardEdit(true);

      navigate("/dashboardadd");
    };
    responseOfEditDashboard();
  }catch(err){
    setIsLoaded(false);
    if (err.message.includes("403")) {
      handleResponse("Error", "You have been logged-out due to inactivity. Login again.");
      setIsUnAuth(true);
    } else {
      handleResponse("Error", err.message);
    }
  }
  };

  useEffect(() => {
    getDashboards();
  }, []);

  

  const openSelectedDashBoard = async (id) => {
    try{
    setIsLoaded(true);
    let response = await axios.get("node/admin/dashboard/" + id, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    setIsLoaded(false);
    setCurrentDashboardMetaData(response.data.data);
    navigate("/dashboard");
  }catch(err){
    setIsLoaded(false);
    if (err.message.includes("403")) {
      handleResponse("Error", "You have been logged-out due to inactivity. Login again.");
      setIsUnAuth(true);
    } else {
      handleResponse("Error", err.message);
    }
  }
  };

  const handleunPublishDashbaord = async (id) => {
    try {
      setIsLoaded(true);
      const response = await axios.patch(
        "node/admin/dashboard/" + id + "/status",
        { status: constants.BOARD_STATUS.DRAFT },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setIsLoaded(false);
      console.log("response.data", response.data);
      if (response.data.resultCode == constants.RESULT_STATUS.SUCCESS) {
        console.log("coming in iff");
        handleResponse("Success", response.data.msg);
      } else {
        console.log("coming in elseee");
        handleResponse("Warning", response.data.msg);
      }
      // getDashboards();
    } catch (err) {
      setIsLoaded(false);
      if (err.message.includes("403")) {
        handleResponse("Error", "You have been logged-out due to inactivity. Login again.");
        setIsUnAuth(true);
      } else {
        handleResponse("Error", err.message);
      }
    }
  };

  return (
    <div className="maindashboardtable">
      {isCommonPopup && (
        <div className="popup-overlay">
          <Popup />
        </div>
      )}
      <div className="dashbaordsSearch">
        <TextField
          placeholder="Search Dashboards"
          variant="outlined"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          InputProps={{
            classes: {
              root: classes.inputRoot,
            },
            // Add an icon to the start of the input
            startAdornment: (
              <InputAdornment position="start">
                <IconButton>
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
          InputLabelProps={{
            shrink: true,
          }}
          style={{ marginBottom: "16px", width: "300px", height: "40px" }}
        />
      </div>

      <TableContainer>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow style={{ background: "#EAECF0", height: "44px" }}>
              <TableCell className={classes.headerCol}>Name</TableCell>
              <TableCell className={classes.headerCol}>Owner</TableCell>
              <TableCell className={classes.headerCol}>Viewers</TableCell>
              {/* <TableCell>Editors</TableCell> */}
              <TableCell className={classes.headerCol}>Status</TableCell>
              {isAdmin && (
                <TableCell className={classes.headerCol}>Options</TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData.map((row) => (
              <TableRow
                className={classes.rowDashboard}
                key={row.id}
                style={{ cursor: "pointer" }}
              >
                <TableCell
                  onClick={() => {
                    openSelectedDashBoard(row.id);
                  }}
                  className={classes.dashBoardName}
                >
                  {row.name}
                </TableCell>
                <TableCell
                  onClick={() => {
                    openSelectedDashBoard(row.id);
                  }}
                  className={classes.dashBoardField}
                >
                  {row.creator_name}
                </TableCell>
                <TableCell
                  onClick={() => {
                    openSelectedDashBoard(row.id);
                  }}
                  className={classes.dashBoardField}
                >
                  {row.viewers.join(",")}
                </TableCell>
                {/* <TableCell>{dashBoardData.editors}</TableCell> */}
                <TableCell
                  onClick={() => {
                    openSelectedDashBoard(row.id);
                  }}
                  className={classes.dashBoardField}
                >
                  {row.status == 1 ? "Draft" : "Published"}
                </TableCell>
                {isAdmin && (
                  <TableCell>
                    <span title="copy">
                      <svg
                        onClick={() => {
                          handleCopyDashboard(row.id);
                        }}
                        width="37"
                        height="36"
                        viewBox="0 0 37 36"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M12.6666 20.5H11.8333C11.3913 20.5 10.9673 20.3244 10.6548 20.0118C10.3422 19.6993 10.1666 19.2754 10.1666 18.8333V11.3333C10.1666 10.8913 10.3422 10.4674 10.6548 10.1548C10.9673 9.84227 11.3913 9.66667 11.8333 9.66667H19.3333C19.7753 9.66667 20.1992 9.84227 20.5118 10.1548C20.8244 10.4674 21 10.8913 21 11.3333V12.1667M17.6666 15.5H25.1666C26.0871 15.5 26.8333 16.2462 26.8333 17.1667V24.6667C26.8333 25.5871 26.0871 26.3333 25.1666 26.3333H17.6666C16.7462 26.3333 16 25.5871 16 24.6667V17.1667C16 16.2462 16.7462 15.5 17.6666 15.5Z"
                          stroke="#667085"
                          stroke-width="1.66667"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </span>

                    {row.created_by == getDataFromStorage("learnerid") ? (
                      <>
                        <span title="delete">
                          <svg
                            onClick={() => {
                              handleDeleteDashBoard(row.id);
                            }}
                            width="37"
                            height="36"
                            viewBox="0 0 37 36"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M11 13H12.6667M12.6667 13H26M12.6667 13V24.6667C12.6667 25.1087 12.8423 25.5326 13.1548 25.8452C13.4674 26.1577 13.8913 26.3333 14.3333 26.3333H22.6667C23.1087 26.3333 23.5326 26.1577 23.8452 25.8452C24.1577 25.5326 24.3333 25.1087 24.3333 24.6667V13H12.6667ZM15.1667 13V11.3333C15.1667 10.8913 15.3423 10.4674 15.6548 10.1548C15.9674 9.84227 16.3913 9.66667 16.8333 9.66667H20.1667C20.6087 9.66667 21.0326 9.84227 21.3452 10.1548C21.6577 10.4674 21.8333 10.8913 21.8333 11.3333V13M16.8333 17.1667V22.1667M20.1667 17.1667V22.1667"
                              stroke="#667085"
                              stroke-width="1.66667"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </span>
                        <span title="Edit">
                          <svg
                            onClick={() => {
                              handleEditDashBoard(row.id);
                            }}
                            width="37"
                            height="36"
                            viewBox="0 0 37 36"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M22.6667 10.5C22.8856 10.2811 23.1455 10.1075 23.4314 9.98906C23.7174 9.87061 24.0239 9.80964 24.3334 9.80964C24.6429 9.80964 24.9494 9.87061 25.2354 9.98906C25.5214 10.1075 25.7812 10.2811 26.0001 10.5C26.219 10.7189 26.3926 10.9787 26.511 11.2647C26.6295 11.5506 26.6904 11.8571 26.6904 12.1667C26.6904 12.4762 26.6295 12.7827 26.511 13.0687C26.3926 13.3546 26.219 13.6145 26.0001 13.8333L14.7501 25.0833L10.1667 26.3333L11.4167 21.75L22.6667 10.5Z"
                              stroke="#667085"
                              stroke-width="1.66667"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </span>
                      </>
                    ) : null}
                    {row.created_by == getDataFromStorage("learnerid") &&
                    row.status == constants.BOARD_STATUS.PUBLISHED ? (
                      <span title="un publish" className="unPublish">
                        <svg
                          onClick={() => handleunPublishDashbaord(row.id)}
                          width="18"
                          height="18"
                          aria-hidden="true"
                          focusable="false"
                          data-prefix="fas"
                          data-icon="eye"
                          class="svg-inline--fa fa-eye adminModuleDetailPublishIcon"
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 576 512"
                          style={{ color: "#667085" }}
                        >
                          <path
                            fill="currentColor"
                            d="M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM144 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-64c0 35.3-28.7 64-64 64c-7.1 0-13.9-1.2-20.3-3.3c-5.5-1.8-11.9 1.6-11.7 7.4c.3 6.9 1.3 13.8 3.2 20.7c13.7 51.2 66.4 81.6 117.6 67.9s81.6-66.4 67.9-117.6c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3z"
                          ></path>
                        </svg>
                      </span>
                    ) : null}
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {isLoaded && <Loading/>}
    </div>
  );
}
