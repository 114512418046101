import constants from "../../../constants";
import React, { useContext, useEffect } from "react";
import AlertContext from "../../../context/AlertContext";
import StudentTestContainer from "./StudentTestContainer";
import useAxiosPrivate from "../../../Hooks/useAxiosPrivate";
import Notify from "../../Notification-Loading/Notify/Notify";
import { getPreSignedUrl, getDataFromStorage } from "../../../util";
import useCommonFunctionHooks from "../../../Hooks/useCommonFunctionHooks";
import { StudentTestPageContext } from "../../../context/StudentTestPageContext";
import StudentTestPageHooks from "../../../Hooks/StudentTestPage/StudentTestPageHooks";
import CodingHeaderLearnerSideHooks from "../../../Hooks/CodingPage/CodingHeaderLearnerSideHooks";
import StudentTestPageAnswerHooks from "../../../Hooks/StudentTestPage/StudentTestPageAnswerHooks";

export default function StudentTestPage() {
  const Alert = useContext(AlertContext);
  const StudentTestPageCxt = useContext(StudentTestPageContext);

  const axios = useAxiosPrivate();
  const StudentTestPageHks = StudentTestPageHooks();
  const useCommonFunctionHks = useCommonFunctionHooks();
  const { submitAnswers } = CodingHeaderLearnerSideHooks();
  const { handleShowAnsforPrac } = StudentTestPageAnswerHooks();

  let uRole = getDataFromStorage("role");

  const handleSaveTest = () => {
    useCommonFunctionHks.handleClose();
    submitAnswers();
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (!Alert.isAdmin) StudentTestPageHks.preventCopyAndCut(event);
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [Alert.isAdmin, StudentTestPageHks]);

  return (
    <>
      {(!Alert.isAdmin || (Alert.apiLoaded && Alert.isAdmin)) && (
        <StudentTestContainer />
      )}
      {
        <Notify
          svg={Alert.svg}
          show={Alert.show}
          title={Alert.title}
          message={Alert.msg}
          btnText={Alert.btnText}
          resultCode={Alert.resultCode}
          onHide={
            Alert.action === "submitscores"
              ? useCommonFunctionHks.handleClose
              : Alert.resultCode === constants.RESULT_STATUS.NETWORK_ISSUE &&
                !Alert.isAdmin
              ? handleSaveTest
              : Alert.action === "resubmit"
              ? () => {
                  Alert.setShow(false);
                  Alert.navigate("/batches/report");
                }
              : Alert.isAdmin
              ? () => {
                  Alert.setIsLoaded(false);
                  Alert.setShow(false);
                }
              : constants.MODE.TEST_MODE === Alert.testMode
              ? Alert.handleNavigateToHome
              : handleShowAnsforPrac
          }
        />
      }
    </>
  );
}
