import React from "react";
import PropTypes from "prop-types";

const Badge = ({
  size,
  label,
  icon = {
    icon: null,
    position: "start",
    isOnly: false,
  },
  color,
}) => {
  return (
    <div
      className={`badgeContainer ${size?.toLowerCase()} ${color?.toLowerCase()} ${
        icon?.isOnly ? "iconOnly" : ""
      }`}
    >
      {icon?.icon && icon.position?.toLowerCase() === "start"
        ? icon.icon
        : null}
      {(parseInt(label) === 0 || label) && !icon?.isOnly && (
        <label
          className={`badgeLabel ${size?.toLowerCase()} ${color?.toLowerCase()}`}
        >
          {label}
        </label>
      )}
      {icon?.icon && icon.position?.toLowerCase() === "end" ? icon.icon : null}
    </div>
  );
};

export default Badge;

Badge.propTypes = {
  size: PropTypes.oneOf(["sm", "md", "lg"]).isRequired,
  label: PropTypes.any,
  icon: PropTypes.shape({
    icon: PropTypes.element,
    position: PropTypes.oneOf(["start", "end"]),
    isOnly: PropTypes.bool,
  }),
  color: PropTypes.string,
};

Badge.defaultProps = {
  size: "md",
  icon: {
    icon: null,
    position: "start",
    isOnly: false,
  },
  color: "grey",
};
