import { nanoid } from "nanoid";
import Select from "react-select";
import Button from "../../../Button";
import Modal from "react-bootstrap/Modal";
import constants from "../../../../constants";
import { Editor } from "@tinymce/tinymce-react";
import CreatableSelect from "react-select/creatable";
import React, { useContext, useEffect } from "react";
import AlertContext from "../../../../context/AlertContext";
import MockTestConfig from "./MockTestConfig/MockTestConfig";
import useAxiosPrivate from "../../../../Hooks/useAxiosPrivate";
import MockTestHooks from "../../../../Hooks/Mock/Admin/MockTestHooks";
import useCommonFunctionHooks from "../../../../Hooks/useCommonFunctionHooks";
import { MockTestPageContext } from "../../../../context/MockTestPageContext";

export default function MockTest() {
  const { setIsLoaded, oldTestConfig } = useContext(AlertContext);

  const {
    isNext,
    testData,
    isNewTest,
    setQnsCount,
    setTestData,
    setDescriptiveOptions,
  } = useContext(MockTestPageContext);

  const axios = useAxiosPrivate();

  const {
    editorRef,
    testOptions,
    isChangeType,
    testTypeLoad,
    handleClickNext,
    handleCancelTest,
    selectedTestType,
    handleTestDetails,
    setSelectedTestType,
    customStylesForTests,
    handleConfirmChangeType,
  } = MockTestHooks();

  const { getCatchBlockDetails, getHeaderDetailsForAxios } =
    useCommonFunctionHooks();

  useEffect(() => {
    if (!oldTestConfig.isOldTestConfig) {
      setIsLoaded(true);
      axios
        .get("node/admin/library/gettypes", getHeaderDetailsForAxios())
        .then((response) => {
          let responseData = JSON.stringify(response);

          responseData = JSON.parse(responseData).data;

          let descriptiveOptions = [];

          for (let i = 0; i < responseData.length; i++) {
            let temp = {
              value: responseData[i].id,
              label: responseData[i].name,
            };

            if (
              responseData[i].parenttype === constants.libTypeIds.DESCRIPTIVE
            ) {
              descriptiveOptions.push(temp);
            }
          }

          testData.testType = selectedTestType;
          setDescriptiveOptions(descriptiveOptions);
        })
        .catch((error) => {
          getCatchBlockDetails(error);
        })
        .finally(() => setIsLoaded(false));
    } else {
      setDescriptiveOptions([
        { label: "SAQ", value: constants.libTypeIds.SAQ },
        { label: "LAQ", value: constants.libTypeIds.LAQ },
      ]);
    }
  }, [oldTestConfig.isOldTestConfig, oldTestConfig.oldTestData.testType]);

  useEffect(() => {
    setSelectedTestType(
      oldTestConfig.isOldTestConfig
        ? testOptions.filter((type) =>
            oldTestConfig.oldTestData.autoEvaluate === 1 &&
            oldTestConfig.oldTestData.libTypeId ===
              constants.libTypeIds.DESCRIPTIVE
              ? type.id === 10 && type
              : oldTestConfig.oldTestData.videoQn === 1 &&
                oldTestConfig.oldTestData.libTypeId ===
                  constants.libTypeIds.DESCRIPTIVE
              ? type.id === 11 && type
              : type.value === oldTestConfig.oldTestData.libTypeId && type
          )
        : ""
    );

    setTestData(
      oldTestConfig.isOldTestConfig
        ? oldTestConfig.oldTestData
        : {
            testDes: "",
            id: nanoid(),
            testName: "",
            isOld: false,
            key: nanoid(),
            weightage: 10,
            status: "new",
            testDuration: "",
            testStatus: "new",
            testDurationNum: "",
            customInstruction: "",
            testDurationUnit: "min/s",
            testType: selectedTestType,
            testMode: constants.MODE.TEST_MODE,
            ListView: constants.SHOW_LIST_VIEW.NO,
            viewTest: constants.DISPLAY_TEST_RESULT.ALL,
            sections: [
              {
                id: 1,
                secName: "",
                key: nanoid(),
                status: "new",
                testStatus: "new",
                secData: [
                  {
                    id: 2,
                    key: nanoid(),
                    status: "new",
                    testStatus: "new",
                  },
                ],
              },
            ],
          }
    );
  }, []);

  useEffect(() => {
    if (oldTestConfig.isOldTestConfig) {
      let newDetails = JSON.parse(JSON.stringify(oldTestConfig.oldTestData)),
        count = 0,
        regexForNum = /^[0-9]\d*$/;

      for (let sec in newDetails.sections) {
        for (let secData in newDetails.sections[sec].secData) {
          if (
            regexForNum.test(
              newDetails.sections[sec].secData[secData].fixedQnCount
            )
          )
            count += newDetails.sections[sec].secData[secData].fixedQnCount;

          if (newDetails.sections[sec].secData[secData].randomQnCount)
            count += newDetails.sections[sec].secData[secData].randomQnCount;
        }
      }

      setQnsCount(count);
    } else return;
  }, [oldTestConfig]);

  return (
    Object.keys(testData)?.length !== 0 && (
      <div>
        {!isNext && (
          <div className="mockTestCreateMainContainer">
            <div className="mockTestCreateForm">
              <div className="mockTestCreateFormTypeSection">
                <label
                  htmlFor="mockTestCreateType"
                  className="mockTestCreateTypeLabel"
                >
                  Mock Test Type
                </label>
                <Select
                  options={testOptions}
                  onChange={testTypeLoad}
                  value={selectedTestType}
                  styles={customStylesForTests}
                  className="mockTestCreateType"
                  placeholder="Select Mock Test Type"
                  isDisabled={oldTestConfig.isOldTestConfig}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                />
              </div>
              <div className="mockTestCreateNameSection">
                <label
                  htmlFor="mockTestCreateName"
                  className="mockTestCreateNameLabel"
                >
                  Mock Test Name
                </label>
                <input
                  type="text"
                  name="testName"
                  maxLength="100"
                  id="mockTestCreateName"
                  value={testData.testName}
                  onChange={handleTestDetails}
                  className="mockTestCreateName"
                  placeholder="Enter new test name"
                  disabled={
                    testData.published === 0 || isNewTest ? false : true
                  }
                />
              </div>
              <div className="mockTestCreateDesSection">
                <label
                  htmlFor="mockTestCreateDes"
                  className="mockTestCreateDesLabel"
                >
                  Description{" "}
                  <span className="mockTestCreateDesLabelOptionalText">
                    (Optional)
                  </span>
                </label>
                <textarea
                  name="testDes"
                  maxLength="300"
                  id="mockTestCreateDes"
                  placeholder="Description"
                  onChange={handleTestDetails}
                  className="mockTestCreateDes"
                  disabled={
                    testData.published === 0 || isNewTest ? false : true
                  }
                  value={
                    testData.testDes === null ||
                    testData.testDes === "null" ||
                    testData.testDes === undefined
                      ? ""
                      : testData.testDes
                  }
                ></textarea>
              </div>

              <div className="mockTestCreateTagsSection">
                <label
                  className="mockTestCreateTagsLabel"
                  htmlFor="mockTestCreateFormTags"
                >
                  Tags
                </label>
                <CreatableSelect
                  isMulti
                  required
                  isClearable
                  // options={topicList}
                  placeholder="Select Tags"
                  name="mockTestCreateFormTags"
                  styles={customStylesForTests}
                  // onCreateOption={handleCreate}
                  // styles={customStylesForTopic}
                  className="mockTestCreateFormTags"
                  components={{ IndicatorSeparator: () => null }}
                  // onChange={(newValue) => {
                  //   handleChange(newValue);
                  // }}
                  // value={
                  //   fromEditQuestion && selectedTopic === undefined
                  //     ? topicList.filter(
                  //         Props.tableShowStatus ===
                  //           constant.libTypeIds.CODING_CHALLENGE
                  //           ? (option) =>
                  //               option.value === Props?.selectmodule?.qdata?.topic
                  //           : (option) =>
                  //               option.value === Props?.selectmodule?.topicid
                  //       )
                  //     : selectedTopic
                  // }
                />
              </div>
              {testData.testType?.value !==
                constants.libTypeIds.CODING_CHALLENGE && (
                <div className="mockTestCreateDurationSection">
                  <label
                    htmlFor="mockTestCreateDuration"
                    className="mockTestCreateDurationLabel"
                  >
                    Duration{" "}
                  </label>
                  <div>
                    <input
                      type="text"
                      maxLength="100"
                      placeholder="Duration"
                      name="testDurationNum"
                      id="mockTestCreateDuration"
                      onChange={handleTestDetails}
                      value={testData.testDurationNum}
                      className="mockTestCreateDurationNum"
                      disabled={
                        testData.published === 0 || isNewTest ? false : true
                      }
                    />
                    <select
                      placeholder="Duration"
                      name="testDurationUnit"
                      onChange={handleTestDetails}
                      value={testData.testDurationUnit}
                      className="mockTestCreateDurationUnit"
                      disabled={
                        testData.published === 0 || isNewTest ? false : true
                      }
                    >
                      <option>min/s</option>
                      <option>hour/s</option>
                      <option>day/s</option>
                    </select>
                  </div>
                </div>
              )}

              <div className="mockTestCreateInstructionSection">
                <label
                  htmlFor="mockTestCreateInstructionLabel"
                  className="mockTestCreateInstructionLabel"
                >
                  Instructions
                </label>
                <div>
                  <Editor
                    value={testData.customInstruction}
                    id="mockTestCreateInstructionLabel"
                    tinymceScriptSrc="/tinymce/tinymce.min.js"
                    onInit={(evt, editor) => (editorRef.current = editor)}
                    onEditorChange={(event) =>
                      handleTestDetails(event, "customInstruction")
                    }
                    disabled={
                      testData.published === 0 || isNewTest ? false : true
                    }
                    init={{
                      readonly: true,
                      menubar: false,
                      min_height: 150,
                      branding: false,
                      statusbar: false,
                      elementpath: false,
                      selector: "textarea",
                      allow_html_in_named_anchor: true,
                      plugins: [
                        "link",
                        "help",
                        "code",
                        "lists",
                        "image",
                        "table",
                        "media",
                        "anchor",
                        "editor",
                        "advlist",
                        "preview",
                        "charmap",
                        "wordcount",
                        "fullscreen",
                        "autoresize",
                        "visualblocks",
                        "searchreplace",
                        "insertdatetime",
                      ],
                      toolbar:
                        "undo redo | blocks | " +
                        "bold italic underline forecolor | link | alignleft aligncenter " +
                        "alignright alignjustify | bullist numlist outdent indent | " +
                        "removeformat | image | table tabledelete | tableProps tablerowProps tablecellProps | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol | help |",
                      content_style:
                        "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                    }}
                  />
                </div>
              </div>
              <div className="mockTestCreateFooter">
                <Button
                  size={"sm"}
                  onClick={handleCancelTest}
                  hierarchy={{
                    buttonText: "Cancel",
                    type: "secondaryGrey",
                  }}
                />
                <Button
                  size={"sm"}
                  onClick={handleClickNext}
                  hierarchy={{
                    type: "primary",
                    buttonText: "Next",
                  }}
                />
              </div>
            </div>
          </div>
        )}
        {isNext && <MockTestConfig />}
        <Modal
          centered
          show={isChangeType.isChange}
          onHide={handleConfirmChangeType}
          style={{
            fontSize: "14px",
            fontWeight: "700",
            lineHeight: "16px",
            fontFamily: "Inter",
            fontstyle: "normal",
          }}
        >
          <Modal.Body style={{ height: "50px" }}>
            Do you want to change this type.If you change the type ,you will
            lose you configuration ?{" "}
          </Modal.Body>
          <Modal.Footer style={{ margin: "10px", padding: "0px" }}>
            <Button
              size="sm"
              onClick={() => handleConfirmChangeType(true)}
              hierarchy={{
                type: "primary",
                buttonText: "Yes",
              }}
            />
            <Button
              size="sm"
              onClick={() => handleConfirmChangeType(false)}
              hierarchy={{
                type: "secondaryGrey",
                buttonText: "No",
              }}
            />
          </Modal.Footer>
        </Modal>
      </div>
    )
  );
}
