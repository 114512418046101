import React, { useEffect, useRef, useState } from "react";
// import InputDropdown from "./InputDropdown";
import PropTypes from "prop-types";

const InputField = ({
  type,
  name,
  placeholder,
  value,
  leadingIcon,
  label,
  hintText,
  helpIcon,
  destructive,
  disabled,
  onChange,
}) => {
  const [focused, setFocused] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setFocused(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  return (
    <div className={`inputFieldContainer`}>
      {label && <label className="inputFieldLabel">{label}</label>}
      <div
        className={`inputWrapper${focused && !disabled ? " focused" : ""}${
          disabled ? " disabled" : ""
        }`}
      >
        <div className="inputLeftWrapper">
          {leadingIcon && leadingIcon}
          {/* {type === "leadingDropdown"&& <div><InputDropdown type="sm" /></div>} */}
          <input
            ref={ref}
            className="inputField"
            name={name}
            placeholder={placeholder}
            value={value}
            onFocus={() => setFocused(true)}
            disabled={disabled}
            onChange={onChange}
          />
        </div>
        {/* {type === "trailingDropdown"&& <div><InputDropdown type="sm" /></div>} */}
      </div>
      {hintText && <span className="inputFieldHintText">{hintText}</span>}
    </div>
  );
};

export default InputField;

InputField.propTypes = {
  type: PropTypes.string.isRequired,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  leadingIcon: PropTypes.element,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  hintText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  helpIcon: PropTypes.element,
  destructive: PropTypes.bool,
  disabled: PropTypes.bool,
  value: PropTypes.string,
};

InputField.defaultProps = {
  value: "",
  name: "",
  type: "default",
  leadingIcon: null,
  placeholder: "Enter the name",
  destructive: false,
  disabled: false,
  helpIcon: null,
};
