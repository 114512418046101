import { useContext } from "react";
import constants from "../constants";
import useAxiosPrivate from "./useAxiosPrivate";
import AlertContext from "../context/AlertContext";
import useCommonFunctionHooks from "./useCommonFunctionHooks";

export default function MultiLevelDropDownHooks() {
  const {
    setLpId,
    setTestData,
    setIsLoaded,
    setShowNotify,
    breadCrumbsData,
    setBreadCrumbsData,
    setBreadCrumbsOption,
  } = useContext(AlertContext);

  const axios = useAxiosPrivate();

  const { getCatchBlockDetails, getHeaderDetailsForAxios } =
    useCommonFunctionHooks();

  const generateBreadCrumbsDataForLearnerSide = () => {
    let topicsData = [];

    for (let i = 0; i < breadCrumbsData.length; i++) {
      if (breadCrumbsData[i].hasOwnProperty("qndata")) {
        let testData = {
          children: [],
          checked: false,
          disabled: false,
          label: breadCrumbsData[i].topicname,
          value: {
            test: breadCrumbsData[i],
            topicName: breadCrumbsData[i].topicname,
            questions: breadCrumbsData[i].qndata[0],
            type: constants.libTypeIds.CODING_CHALLENGE,
            questionNo: breadCrumbsData[i].qndata[0].qnno,
          },
        };

        let topics = breadCrumbsData[i].qndata.sort(
          (question1, question2) => question1?.qnno - question2?.qnno
        );

        for (let j = 0; j < topics.length; j++) {
          testData.children.push({
            checked: false,
            disabled: false,
            label: `Question ${topics[j].qnno}`,
            value: {
              test: breadCrumbsData[i],
              topicName: breadCrumbsData[i].topicname,
              questions: breadCrumbsData[i].qndata[j],
              type: constants.libTypeIds.CODING_CHALLENGE,
              questionNo: breadCrumbsData[i].qndata[j].qnno,
            },
          });
        }

        topicsData.push(testData);
      }
    }

    setBreadCrumbsOption(topicsData);
  };

  const generateJSON = (data) => {
    return data.modules.map((module) => {
      let result = {
        ...module,
        testdata: module.testdata.map((test) => {
          if (!test.testdata.length || !test.testdata[0].topicid) {
            return test;
          }

          let groupedData = {};

          test.testdata.forEach((item) => {
            if (!item.topicid) return;

            if (!groupedData[item.topicid]) {
              groupedData[item.topicid] = {
                topicid: item.topicid,
                topicname: item.topicname,
                qndata: [],
              };
            }

            groupedData[item.topicid].qndata.push({
              status: item.qnstatus,
              testqnno: item.testqnno,
              qntimeleft: item.qntimeleft,
              questionid: item.questionid,
              testconfigid: item.testconfigid,
            });
          });

          test.testdata = Object.values(groupedData);

          return test;
        }),
      };

      return result;
    });
  };

  const generateBreadCrumbsData = () => {
    let topicsData = [],
      modules = generateJSON(breadCrumbsData);

    modules.map((data) =>
      data.testdata.sort((order1, order2) => order1.orderno - order2.orderno)
    );

    for (let i = 0; i < modules.length; i++) {
      let moduleData = {
          children: [],
          checked: false,
          disabled: false,
          label: modules[i].mname,
          value: {
            moduleId: modules[i].mid,
            lpId: breadCrumbsData.lpid,
            moduleName: modules[i].mname,
            testId: modules[i].testdata[0].id,
            learnerId: breadCrumbsData.userid,
            type: modules[i].testdata[0].libtype,
            testName: modules[i].testdata[0].name,
            testMode: modules[i].testdata[0].testmode,
            lpConfigId: modules[i].testdata[0].lpconfigid,
            testDueDate: modules[i].testdata[0].testduedate,
            topicId: modules[i].testdata[0].testdata[0].topicid,
            learnerDueDate: modules[i].testdata[0].learnerduedate,
            topicName: modules[i].testdata[0].testdata[0].topicname,
            studentName: breadCrumbsData.fname + " " + breadCrumbsData.lastname,
            testConfigId:
              modules[i].testdata[0].libtype ===
                constants.libTypeIds.CODING_CHALLENGE &&
              modules[i].testdata[0].testdata[0]?.qndata[0].testconfigid,
            questionNo:
              modules[i].testdata[0].libtype ===
                constants.libTypeIds.CODING_CHALLENGE &&
              modules[i].testdata[0].testdata[0]?.qndata[0].testqnno,
            questionId:
              modules[i].testdata[0].libtype ===
                constants.libTypeIds.CODING_CHALLENGE &&
              modules[i].testdata[0].testdata[0]?.qndata[0].questionid,
            status:
              modules[i].testdata[0].libtype ===
              constants.libTypeIds.CODING_CHALLENGE
                ? modules[i].testdata[0].testdata[0]?.qndata[0].status
                : modules[i].testdata[0].status,
          },
        },
        test = modules[i].testdata;

      for (let j = 0; j < test.length; j++) {
        let testData = {
          children: [],
          checked: false,
          disabled: false,
          label: test[j].name,
          value: {
            testId: test[j].id,
            type: test[j].libtype,
            testName: test[j].name,
            moduleId: modules[i].mid,
            testMode: test[j].testmode,
            lpId: breadCrumbsData.lpid,
            moduleName: modules[i].mname,
            lpConfigId: test[j].lpconfigid,
            testDueDate: test[j].testduedate,
            learnerId: breadCrumbsData.userid,
            topicId: test[j].testdata[0].topicid,
            learnerDueDate: test[j].learnerduedate,
            topicName: test[j].testdata[0].topicname,
            studentName: breadCrumbsData.fname + " " + breadCrumbsData.lastname,
            testConfigId:
              test[j].libtype === constants.libTypeIds.CODING_CHALLENGE &&
              test[j].testdata[0]?.qndata[0].testconfigid,
            questionNo:
              test[j].libtype === constants.libTypeIds.CODING_CHALLENGE &&
              test[j].testdata[0]?.qndata[0].testqnno,
            questionId:
              test[j].libtype === constants.libTypeIds.CODING_CHALLENGE &&
              test[j].testdata[0]?.qndata[0].questionid,
            status:
              test[j].libtype === constants.libTypeIds.CODING_CHALLENGE
                ? test[j].testdata[0]?.qndata[0].status
                : test[j].status,
          },
        };

        if (test[j].libtype === constants.libTypeIds.CODING_CHALLENGE) {
          let topics = test[j].testdata;

          for (let k = 0; k < topics.length; k++) {
            let topicData = {
              children: [],
              checked: false,
              disabled: false,
              label: topics[k].topicname,
              value: {
                testId: test[j].id,
                type: test[j].libtype,
                testName: test[j].name,
                moduleId: modules[i].mid,
                testMode: test[j].testmode,
                topicId: topics[k].topicid,
                lpId: breadCrumbsData.lpid,
                moduleName: modules[i].mname,
                lpConfigId: test[j].lpconfigid,
                topicName: topics[k].topicname,
                testDueDate: test[j].testduedate,
                learnerId: breadCrumbsData.userid,
                status: topics[k].qndata[0].status,
                learnerDueDate: test[j].learnerduedate,
                questionNo: topics[k].qndata[0].testqnno,
                questionId: topics[k].qndata[0].questionid,
                testConfigId: topics[k].qndata[0].testconfigid,
                studentName:
                  breadCrumbsData.fname + " " + breadCrumbsData.lastname,
              },
            };

            let questionData = topics[k].qndata.sort(
              (question1, question2) =>
                question1?.testqnno - question2?.testqnno
            );

            for (let l = 0; l < questionData.length; l++) {
              topicData.children.push({
                checked: false,
                disabled: false,
                label: `Question ${questionData[l].testqnno}`,
                value: {
                  testId: test[j].id,
                  type: test[j].libtype,
                  testName: test[j].name,
                  moduleId: modules[i].mid,
                  testMode: test[j].testmode,
                  lpId: breadCrumbsData.lpid,
                  topicId: topics[k].topicid,
                  moduleName: modules[i].mname,
                  lpConfigId: test[j].lpconfigid,
                  topicName: topics[k].topicname,
                  testDueDate: test[j].testduedate,
                  learnerId: breadCrumbsData.userid,
                  status: topics[k].qndata[l].status,
                  learnerDueDate: test[j].learnerduedate,
                  questionNo: topics[k].qndata[l].testqnno,
                  questionId: topics[k].qndata[l].questionid,
                  testConfigId: topics[k].qndata[l].testconfigid,
                  studentName:
                    breadCrumbsData.fname + " " + breadCrumbsData.lastname,
                },
              });
            }

            testData.children.push(topicData);
          }

          moduleData.children.push(testData);
        } else {
          delete testData.children;
          moduleData.children.push(testData);
        }
      }

      topicsData.push(moduleData);
    }

    setBreadCrumbsOption(topicsData);
  };

  const getDataForBreadCrumbsData = (data) => {
    setIsLoaded(true);
    setLpId(data.lpId);
    setTestData({ testmode: data.testMode, testtype: data.type });

    axios
      .post(
        "node/admin/report/lp/student",
        JSON.stringify({
          lpid: data.lpId,
          learnerid: data.learnerId,
        }),
        getHeaderDetailsForAxios()
      )
      .then((res) => {
        if (res.data.resultCode !== constants.RESULT_STATUS.SUCCESS) {
          setShowNotify({
            show: true,
            title: "Info",
            msg: res.data.msg,
          });
        } else {
          setBreadCrumbsData(JSON.parse(res.data.data.lpdata));
        }
      })
      .catch((error) => {
        getCatchBlockDetails(error);
      })
      .finally(() => setIsLoaded(false));
  };

  return {
    generateBreadCrumbsData,
    getDataForBreadCrumbsData,
    generateBreadCrumbsDataForLearnerSide,
  };
}
