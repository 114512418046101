import { useContext, useState } from "react";
import constants from "../../constants";
import AlertContext from "../../context/AlertContext";
import StudentTestContainerHooks from "./StudentTestContainerHooks";
import { StudentTestPageContext } from "../../context/StudentTestPageContext";
import StudentTestPageQuestionUpdateHooks from "../../Components/Student/StudentTestPage/StudentTestPageQuestionUpdateHooks";
import StudentTestPageCalcStatsHooks from "./StudentTestPageCalcStatsHooks";

export default function StudentTestBodyPageHooks() {
  const Alert = useContext(AlertContext);
  const StudentTestPageCxt = useContext(StudentTestPageContext);

  const [answers, setAnswers] = useState(Alert.answerRef.current);
  const { setFilters } = StudentTestContainerHooks();
  const { calcStats } = StudentTestPageCalcStatsHooks();
  const { questionUpdate } = StudentTestPageQuestionUpdateHooks();

  const showTranscriptFunc = () => {
    StudentTestPageCxt.setShowTranscript(!StudentTestPageCxt.showTranscript);
  };

  const handleSingleVdoQnSubmit = () => {
    StudentTestPageCxt.setSingleVdoSubmit(true);
  };

  /* Book mark questions */

  const questionObjUpdate = (theObject) => {
    Alert.setQueObj(theObject);
  };

  const showAnswer = () => {
    StudentTestPageCxt.setShowSaqLaqAns(!StudentTestPageCxt.showSaqLaqAns);
  };

  /* change the questions view list and grid */

  const changeQuestionVisible = (visible) => {
    if (visible === "single") {
      if (Alert.questionContainerSingle === false) {
        Alert.updateQuestionContainerSingle(true);
      }
    } else if (visible === "multiple") {
      Alert.comingObj.visible = Alert.comingObj.previsited;

      if (Alert.questionContainerSingle === true) {
        Alert.updateQuestionContainerSingle(false);
      }
    }
  };

  const backToHomePage = () => {
    Alert.isAdmin
      ? Alert.navigate(`/batches/${sessionStorage.getItem("abatchid")}/report`)
      : Alert.backupTestData.mockuse
      ? Alert.navigate("/mock/results")
      : Alert.navigate("/home");

    if (StudentTestPageCxt.showTranscript)
      StudentTestPageCxt.setShowTranscript(!StudentTestPageCxt.showTranscript);
    if (StudentTestPageCxt.showSaqLaqAns)
      StudentTestPageCxt.setShowSaqLaqAns(!StudentTestPageCxt.showSaqLaqAns);

    if (Alert.backupTestData.testmode === constants.MODE.PRACTICE_MODE) {
      Alert.setLpData({});
    }
  };

  /* vdo qn getting the sectionname  */

  const getSectionName = (qnid) => {
    for (let sec of Alert.comingObj.sections) {
      if (sec.questions.find((que) => que.id === qnid)) {
        return sec.sectionname;
      }
    }

    return null;
  };

  /* vdo qn getting the qn no  */

  const getQuestionNo = (qnid) => {
    for (let sec of Alert.comingObj.sections) {
      const questionIndex = sec.questions.findIndex((que) => que.id === qnid);

      if (questionIndex !== -1) {
        return questionIndex + 1;
      }
    }

    return null;
  };

  /* move to previous question */

  const traversePrevious = () => {
    let tempQueInd = Alert.queInd;
    let tempSecInd = Alert.secInd;

    if (
      Alert.comingObj.sections[tempSecInd].questions[tempQueInd].answered === -1
    ) {
      Alert.comingObj.sections[tempSecInd].questions[tempQueInd].visited =
        "not";
      Alert.comingObj.sections[tempSecInd].questions[tempQueInd].previsited =
        "not";
    }

    if (
      Alert.comingObj.sections[tempSecInd].questions[tempQueInd].answered === 0
    ) {
      Alert.comingObj.sections[tempSecInd].questions[tempQueInd].visited =
        "completed";
      Alert.comingObj.sections[tempSecInd].questions[tempQueInd].previsited =
        "completed";
    }

    if (Alert.queLen > 1 && Alert.queInd > 0) {
      tempQueInd--;

      Alert.comingObj.sections[tempSecInd].questions[tempQueInd].visited =
        Alert.comingObj.sections[tempSecInd].questions[tempQueInd].previsited;
      Alert.setQueInd(Alert.queInd - 1);
      Alert.setNextButton(true);
    } else {
      tempSecInd--;
      Alert.setSecInd(Alert.secInd - 1);
      Alert.setNextButton(true);
      tempQueInd = Alert.comingObj.sections[tempSecInd].questions.length - 1;

      Alert.comingObj.sections[tempSecInd].questions[tempQueInd].visited =
        Alert.comingObj.sections[tempSecInd].questions[tempQueInd].previsited;
      Alert.setQueInd(tempQueInd);
    }

    if (tempSecInd === 0 && tempQueInd === 0) {
      Alert.setPrevButton(false);
    }

    questionUpdate(tempSecInd, tempQueInd, Alert.comingObj);

    if (
      Alert.mcqAndDesTestAndLpId.status === constants.PROGRESS_STATUS.EVALUATED
    ) {
      setFilters(null);
    }
  };

  /* move to next question */

  const traverseNext = (key) => {
    let tempSecInd = Alert.secInd;
    let tempQueInd = Alert.queInd;

    if (
      Alert.comingObj.sections[tempSecInd].questions[tempQueInd].answered === -1
    ) {
      Alert.comingObj.sections[tempSecInd].questions[tempQueInd].visited =
        "not";
      Alert.comingObj.sections[tempSecInd].questions[tempQueInd].previsited =
        "not";
    }

    if (
      Alert.comingObj.sections[tempSecInd].questions[tempQueInd].answered === 0
    ) {
      Alert.comingObj.sections[tempSecInd].questions[tempQueInd].visited =
        "completed";
      Alert.comingObj.sections[tempSecInd].questions[tempQueInd].previsited =
        "completed";
    }

    if (
      Alert.comingObj.sections[tempSecInd].questions.length ===
        tempQueInd + 1 &&
      Alert.comingObj.sections?.length >= tempSecInd + 1
    ) {
      tempSecInd++;
      tempQueInd = 0;

      Alert.comingObj.sections[tempSecInd].questions[tempQueInd].visited =
        Alert.comingObj.sections[tempSecInd].questions[tempQueInd].previsited;

      questionUpdate(tempSecInd, tempQueInd, Alert.comingObj);
      Alert.setPrevButton(true);
    } else {
      tempQueInd++;

      Alert.comingObj.sections[tempSecInd].questions[tempQueInd].visited =
        Alert.comingObj.sections[tempSecInd].questions[tempQueInd].previsited;

      questionUpdate(tempSecInd, tempQueInd, Alert.comingObj);
      Alert.setPrevButton(true);
    }

    if (
      Alert.comingObj.sections?.length === tempSecInd + 1 &&
      Alert.comingObj.sections[tempSecInd].questions.length === tempQueInd + 1
    ) {
      Alert.setPrevButton(true);
      Alert.setNextButton(false);
    }

    if (
      Alert.mcqAndDesTestAndLpId.status === constants.PROGRESS_STATUS.EVALUATED
    ) {
      setFilters(null);
    }

    Alert.setSecInd(tempSecInd);
    Alert.setQueInd(tempQueInd);
  };

  /* collect score */

  const collectScores = (qid, scor, maxscore, e) => {
    if (isNaN(scor) === false) {
      let score = Number(scor);
      let tScore = StudentTestPageCxt.currentScore;
      let tempScores = Alert.descScores;

      tempScores = { ...tempScores, [qid]: score };

      let tempErrors = StudentTestPageCxt.scoreErrors;

      if (score > maxscore || score === "" || score < 0) {
        if (Object.keys(tempErrors).length > 0) {
          StudentTestPageCxt.setHasScoreErrors(true);
        } else {
          StudentTestPageCxt.setHasScoreErrors(false);
        }

        if (score === "") {
          if (Object.keys(tempScores).map(Number)?.includes(qid)) {
            tScore = tScore - Alert.tsCoBkup[qid];

            if (Alert.ansStats.evalids?.includes(parseInt(qid))) {
              tScore = tScore + Alert.ansStats.idscore[qid];
            }
          } else {
            if (Alert.ansStats.evalids?.includes(parseInt(qid))) {
              tScore = tScore + Alert.ansStats.idscore[qid];
            }
          }

          Alert.setDescScores(tempScores);
          delete tempScores[qid];

          tempErrors = { ...tempErrors, [qid]: true };
          StudentTestPageCxt.setScoreErrors(tempErrors);
        }

        if (score > maxscore) {
          tempScores = { ...tempScores, [qid]: maxscore };
          Alert.setDescScores(tempScores);

          if (Object.keys(Alert.tsCoBkup).map(Number)?.includes(qid)) {
            tScore = tScore - Alert.tsCoBkup[qid];
          } else if (Alert.ansStats.evalids?.includes(Number(qid))) {
            tScore = tScore - Alert.ansStats.idscore[qid];
          }

          tScore = tScore + maxscore;
          Alert.setDescScores(tempScores);
        }

        if (score < 0) {
          tempScores = { ...tempScores, [qid]: 0 };
          Alert.setDescScores(tempScores);
        }
      } else {
        if (tempErrors.hasOwnProperty(qid)) {
          delete tempErrors[qid];
        }

        if (Object.keys(tempScores).map(Number)?.includes(qid)) {
          if (Object.keys(Alert.tsCoBkup).map(Number)?.includes(qid)) {
            tScore = tScore - Alert.tsCoBkup[qid];
          } else if (Alert.ansStats?.evalids?.includes(Number(qid))) {
            tScore = tScore - Alert.ansStats.idscore[qid];
          }

          tScore = tScore + score;
        } else {
          if (Alert.ansStats.evalids?.includes(parseInt(qid))) {
            tScore = tScore - Alert.ansStats.idscore[qid];
            tScore = tScore + score;
          } else {
            tScore = tScore + score;
          }
        }

        Alert.setDescScores(tempScores);
      }

      StudentTestPageCxt.setCurrentScore(tScore);
      StudentTestPageCxt.setEvalComp(true);

      for (const property in tempScores) {
        if (tempScores[property] == null) {
          StudentTestPageCxt.setEvalComp(false);
        }
      }

      Alert.setTsCoBkup(tempScores);

      for (let i = 0; i < Alert.comingObj.sections?.length; i++) {
        for (let j = 0; j < Alert.comingObj.sections[i].questions.length; j++) {
          if (
            Alert.comingObj.sections[i].questions[j].answer === "" &&
            Alert.comingObj.sections[i].questions[j].id === qid
          ) {
            StudentTestPageCxt.setGiveZeros(false);
            break;
          }
        }
      }
    }
  };

  /* Collect ans and showing error msg */

  const collectAnswers = (
    queid,
    ansid,
    libtypeid,
    secindex,
    queindex,
    datatype
  ) => {
    if (datatype === 1) {
      if (/^[\d.\s\n]*$/.test(ansid)) {
        StudentTestPageCxt.setErrorMessage(false);
      } else {
        StudentTestPageCxt.setErrorMessage(true);
        StudentTestPageCxt.setQuestionIdOffError(queid);
      }
    }

    let tempData = Alert.comingObj;

    /* need to see secindex */

    if (libtypeid === constants.libTypeIds.MCQ_SINGLE) {
      // let temp = Alert.answers;
      let temp = answers;

      temp = { ...temp, [queid]: ansid };
      // Alert.setAnswers(temp);
      setAnswers(temp);
      Alert.answerRef.current = temp;

      if (Alert.questionContainerSingle) {
        Alert.queObj.visited = "completed";
        Alert.queObj.previsited = "completed";
        Alert.queObj.answered = 0;
      } else {
        tempData.sections[Alert.secInd].questions[queindex].answered = 0;
        tempData.sections[Alert.secInd].questions[queindex].visited =
          "completed";
        tempData.sections[Alert.secInd].questions[queindex].previsited =
          "completed";
      }
    }

    if (libtypeid === constants.libTypeIds.MCQ_MULTI) {
      // let temp = Alert.answers;
      let temp = answers;
      let checkedAnswers = [];

      if (temp[queid] !== "") {
        let trimmedAnswer =
          temp[queid]?.length > 0
            ? temp[queid][0] === ","
              ? temp[queid].substring(1)
              : temp[queid]
            : "";

        temp[queid] = trimmedAnswer;
        checkedAnswers = temp[queid].split(",");

        const index = checkedAnswers.indexOf(ansid);

        if (index > -1) {
          // only splice array when item is found

          checkedAnswers.splice(index, 1); // 2nd parameter means remove one item only
        } else {
          checkedAnswers.push(ansid);
        }
      } else {
        checkedAnswers.push(ansid);
      }

      if (checkedAnswers.length === 0) {
        temp = { ...temp, [queid]: "" };
        // Alert.setAnswers(temp);
        setAnswers(temp);
        Alert.answerRef.current = temp;

        if (Alert.questionContainerSingle) {
          Alert.queObj.visited = "seen";
          Alert.queObj.previsited = "seen";
          Alert.queObj.answered = -1;
        } else {
          tempData.sections[Alert.secInd].questions[queindex].visited = "seen";
          tempData.sections[Alert.secInd].questions[queindex].previsited =
            "seen";
          Alert.queObj.answered = -1;
        }
      } else {
        temp = { ...temp, [queid]: checkedAnswers.toString() };
        // Alert.setAnswers(temp);
        setAnswers(temp);
        Alert.answerRef.current = temp;

        if (Alert.questionContainerSingle) {
          Alert.queObj.visited = "completed";
          Alert.queObj.previsited = "completed";
          Alert.queObj.answered = 0;
        } else {
          tempData.sections[Alert.secInd].questions[queindex].visited =
            "completed";
          tempData.sections[Alert.secInd].questions[queindex].previsited =
            "completed";
          tempData.sections[Alert.secInd].questions[queindex].answered = 0;
        }
      }
    }

    if (
      libtypeid === constants.libTypeIds.SAQ ||
      libtypeid === constants.libTypeIds.LAQ
    ) {
      // let temp = Alert.answers;
      let temp = answers;
      temp = { ...temp, [queid]: ansid };
      // Alert.setAnswers(temp);
      setAnswers(temp);
      Alert.answerRef.current = temp;
      if (ansid.length === 0) {
        if (Alert.questionContainerSingle) {
          Alert.queObj.visited = "not";
          Alert.queObj.previsited = "not";
          Alert.queObj.answered = -1;
        } else {
          tempData.sections[Alert.secInd].questions[queindex].visited = "not";
          tempData.sections[Alert.secInd].questions[queindex].previsited =
            "not";
          tempData.sections[Alert.secInd].questions[queindex].answered = -1;
        }
      } else {
        if (Alert.questionContainerSingle) {
          Alert.queObj.visited = "completed";
          Alert.queObj.previsited = "completed";
          Alert.queObj.answered = 0;
        } else {
          tempData.sections[Alert.secInd].questions[queindex].visited =
            "completed";
          tempData.sections[Alert.secInd].questions[queindex].previsited =
            "completed";
          tempData.sections[Alert.secInd].questions[queindex].answered = 0;
        }
      }

      Alert.updateComingObj(tempData);
    }
  };

  const reviewLater = (from, current, obj) => {
    let tempPropObj = Alert.comingObj;
    let temp = obj;

    if (current === "review") {
      StudentTestPageCxt.setShowReview(false);
      tempPropObj.sections[Alert.secInd].questions[
        from === "single" ? Alert.queInd : obj
      ].visited =
        tempPropObj.sections[Alert.secInd].questions[
          from === "single" ? Alert.queInd : obj
        ].previsited;
      tempPropObj.sections[Alert.secInd].questions[
        from === "single" ? Alert.queInd : obj
      ].review = -1;
    } else {
      StudentTestPageCxt.setShowReview(true);
      tempPropObj.sections[Alert.secInd].questions[
        from === "single" ? Alert.queInd : obj
      ].visited = "review";
      tempPropObj.sections[Alert.secInd].questions[
        from === "single" ? Alert.queInd : obj
      ].review = 0;
    }

    if (from === "single") {
      questionUpdate(Alert.secInd, Alert.queInd, tempPropObj);
      Alert.setQueObj(temp);
      questionObjUpdate(Alert.queObj);
    }

    if (from === "multi") {
      StudentTestPageCxt.setSRender(obj);
      questionUpdate(Alert.secInd, Alert.queInd, tempPropObj);
      StudentTestPageCxt.setMulRevch(!StudentTestPageCxt.mulRevch);
    }

    Alert.updateComingObj(tempPropObj);
  };

  /* change section  */

  const handleSecChange = (event) => {
    let newEve;

    if (event.target.value === "all") {
      newEve = 0;
      StudentTestPageCxt.setPrevSec(false);
      StudentTestPageCxt.setNextSec(true);
    } else {
      newEve = event.target.value;
    }

    StudentTestPageCxt.setSelSec(event.target.value);
    StudentTestPageCxt.setSectionObj(Alert.comingObj.sections[newEve]);

    if (event.target.value === 0) {
      if (Alert.comingObj.sections.length === 1) {
        StudentTestPageCxt.setPrevSec(false);
        StudentTestPageCxt.setNextSec(false);
      } else {
        StudentTestPageCxt.setPrevSec(false);
        StudentTestPageCxt.setNextSec(true);
      }
    } else if (event.target.value === Alert.comingObj.sections?.length - 1) {
      StudentTestPageCxt.setPrevSec(true);
      StudentTestPageCxt.setNextSec(false);
    } else {
      if (Alert.comingObj.sections.length === 1) {
        StudentTestPageCxt.setPrevSec(false);
        StudentTestPageCxt.setNextSec(false);
      } else {
        StudentTestPageCxt.setPrevSec(true);
        StudentTestPageCxt.setNextSec(true);
      }
    }

    calcStats(Alert.comingObj, [event.target.value]);

    if (
      Alert.mcqAndDesTestAndLpId.status === constants.PROGRESS_STATUS.EVALUATED
    ) {
      setFilters(null);
    }
  };

  return {
    showAnswer,
    reviewLater,
    traverseNext,
    getQuestionNo,
    collectScores,
    getSectionName,
    collectAnswers,
    backToHomePage,
    handleSecChange,
    traversePrevious,
    showTranscriptFunc,
    changeQuestionVisible,
    handleSingleVdoQnSubmit,
    answers, setAnswers
  };
}
