import "./EditableRow.scss";
import React, { useEffect } from "react";
// import TextareaAutosize from "react-textarea-autosize";
import {TextareaAutosize} from '@mui/base/TextareaAutosize';
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function EditableRow(Props) {
  const addTextarea = () => {
    const updated = { ...Props.editFormData };

    updated?.output?.push("");
    Props.setEditFormData(updated);
  };

  const handleDeleteOutput = (e, index) => {
    let updated = { ...Props.editFormData };
    let opArray = updated.output;

    opArray.splice(index, 1);
    updated.output = opArray;
    Props.setEditFormData(updated);
  };

  useEffect(() => {
    Props.setcuin([...Props.editFormData?.input?.split("\n")]);
  }, []);

  return (
    <tr
      /* className="tablerow" */ style={{ lineHeight: "13px", height: "46px" }}
    >
      <td /* className="tabledata" */>
        {Props.inputvariables.map((inp, indx) => (
          <div key={"insideEditTs" + indx} className="questionInputInputs">
            <span className="questionInputInputsLabel">{inp}:</span>
            <TextareaAutosize
              minRows={1}
              id={"inputs" + indx}
              style={{ width: 260 }}
              value={Props.cuin[indx]}
              key={"editInputVar" + indx}
              onChange={(e) => {
                Props.CustomInputs(e, indx, "edit", Props.index);
              }}

              //   className="customInput"
            />
          </div>
        ))}
      </td>
      <td /* className="tabledata" */>
        <div className="outputsCol">
          <div id="addOutputs">
            <i class="fa fa-plus" onClick={addTextarea} aria-hidden="true"></i>
          </div>
          {Props.editFormData?.output?.map((text, ind) => (
            <div className="outputs">
              <TextareaAutosize
                name="output"
                required="required"
                id="iotable-output-txtbox"
                value={typeof text === "string" ? text : text.output}
                onChange={(e) =>
                  Props.handleEditFormChange(e, "output", ind, Props.index)
                }
              />
              <div
                className="cancelOutput"
                onClick={(e) => {
                  handleDeleteOutput(e, ind);
                }}
              >
                <FontAwesomeIcon className="faXmark" icon={faXmark} />
              </div>
            </div>
          ))}
        </div>
      </td>

      <td style={{ border: "1px solid #EBECED", height: "46px" }}>
        <div className="sampleIo">
          <input
            name="isExample"
            type={"checkbox"}
            checked={Props.editFormData?.isExample}
            onChange={(e) => Props.handleEditFormChange(e, "checkbox")}
            style={{ margin: "10px 0 0 0", height: "20px", width: "15px" }}
          />
        </div>
      </td>

      <td>
        <div className="saveCancel">
          <button
            onClick={Props.handleEditFormSubmit}
            style={{ border: "none", background: "none", margin: "5px" }}
          >
            <FontAwesomeIcon className="faCheck" icon={faCheck} />
          </button>
          <button
            onClick={Props.handleCancelClick}
            style={{ border: "none", background: "none" }}
          >
            <FontAwesomeIcon className="faXmark" icon={faXmark} />
          </button>
        </div>
      </td>
    </tr>
  );
}
