import React, { useEffect, useState, useContext } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TestSvgIcon from "../../Svg/TestSvgIcon";
import constants from "../../constants";
import { useLocation } from "react-router-dom";
import ProfileUser from "./ProfileUser";
import { ProfileProvider } from "../../context/ProfileContext";
import Select from "react-select";
import AngleRightSvg from "../../Svg/AngleRightSvg";
import AngleLeftSvg from "../../Svg/AngleLeftSvg";
import AlertContext from "../../context/AlertContext";
import useAxiosPrivate from "../../Hooks/useAxiosPrivate";
import Loading from "../Notification-Loading/Loading/Loading";
import Tooltip from "./Tooltip";
import { getDataFromStorage } from "../../util";
import UserTestResultReportTable from "../UserReport/UserTestResultReport/UserTestResultReportTable";
import UserTimeSpentReport from "../UserReport/UserTimeSpentReport/UserTimeSpentReport";

const customStyle = {
  control: (provided) => ({
    ...provided,
    minWidth: 150,
    maxWidth: 200,
    outline: "none",
    cursor: "pointer",
    boxShadow: "none",
    border: "0px solid white",
    backgroundColor: "#fafafa",
  }),
  option: (styles) => {
    return {
      ...styles,
      fontSize: "12px",
      textAlign: "left",
      cursor: "pointer",
      padding: "2px 12px",
      color: "black",
      backgroundColor: "white",
    };
  },
};

export default function TableWithTotal(props) {
  const Alert = useContext(AlertContext);

  const [timeSpent, setTimespent] = useState(false);
  const [showProfile, setShowProfile] = useState(false);
  const [currentLearnerDetails, setCurrentLearnerDetails] = useState({
    label: props.name,
    value: props.learnerId,
  });
  const { showPrevBtn, showNextBtn, isLoaded, setIsLoaded, isAdmin } =
    useContext(AlertContext);
  const [learnerList, setLearnerList] = useState([]);
  const [leaderboardTestDetail, setLeaderboardTestDetail] = useState(
    props.from !== "popup" ? props.leaderboardTestDetail : []
  );
  const axios = useAxiosPrivate();

  // useEffect(() => {
  //   if (props.from === "popup") {
  //     setIsLoaded(true);
  //     axios
  //       .get(
  //         `node/learner/${currentLearnerDetails.value}/batch/${props.batchId}/testpoints`,
  //         {
  //           headers: {
  //             "Content-type": "application/json",
  //           },
  //           timeout: 60000,
  //         }
  //       )
  //       .then((res) => {
  //         setIsLoaded(false);
  //         if (res.data.resultCode === constants.RESULT_STATUS.TECHNICAL_ERROR) {
  //         } else {
  //           if (res.data.resultCode === constants.RESULT_STATUS.NO_DATA) {
  //           } else {
  //             setLeaderboardTestDetail(res.data.data);
  //           }
  //         }
  //       })
  //       .catch((error) => {
  //         setIsLoaded(false);
  //       });
  //   }
  // }, [currentLearnerDetails]);

  useEffect(() => {
    let handlegetLearners = async () => {
      let res = await axios.get(`node/admin/getemails/2/${props.batchId}`, {
        headers: {
          "Content-type": "application/json",
        },
      });
      let learnerdetails = res.data.data
        .sort((a, b) => a.firstname.localeCompare(b.firstname))
        .map((i, index) => {
          return {
            label: i.firstname + " " + i.lastname,
            value: i.id,
            ind: index,
          };
        });
      setLearnerList(learnerdetails);
    };
    if (props.from === "popup") {
      handlegetLearners();
    }
  }, []);

  useEffect(() => {
    if (props.from !== "popup") {
      setLeaderboardTestDetail(props.leaderboardTestDetail);
    } else {
      setLeaderboardTestDetail(leaderboardTestDetail);
    }
  }, [leaderboardTestDetail, props.timeSpent, props.leaderboardTestDetail]);

  const { pathname } = useLocation();

  function formatTime(seconds) {
    if (seconds < 60) {
      return seconds + " seconds";
    } else if (seconds < 3600) {
      const minutes = Math.floor(seconds / 60);
      return minutes + " minutes";
    } else {
      const hours = Math.floor(seconds / 3600);
      return hours + " hrs";
    }
  }

  const handleChangeLearnerByBtn = (action) => {
    if (action === "prev") {
      if (currentLearnerDetails.ind > 0) {
        setCurrentLearnerDetails(learnerList[currentLearnerDetails.ind--]);
      } else {
        console.log("No previous item");
      }
    } else {
      if (currentLearnerDetails.ind < learnerList.length - 1) {
        setCurrentLearnerDetails(learnerList[currentLearnerDetails.ind++]);
      } else {
        console.log("No Next item");
      }
    }
  };

  const handlestudentChange = (e) => {
    Alert.setLearnerId(e.value);
    setCurrentLearnerDetails(e);
  };

  return (
    <div
      className={
        pathname.includes("user")
          ? "tablefortimespentcont"
          : "tablefortimespentcontainer"
      }
    >
      {/* <Outlet /> */}
      {isLoaded && <Loading />}
      {props.option == 3 && (
        <div className="timespenttextcont">
          <span className="timespenttext">Time Spent in Learning Path</span>
        </div>
      )}
      {!props.from.includes("options") && (
        <div
          className={
            pathname.includes("user") ? "pointmodalCont" : "pointmodalContainer"
          }
        >
          <div className="leftsideoptions">
            {pathname.includes("user") &&
              getDataFromStorage("role") !== "hr" && (
                <label
                  className={
                    showProfile ? "profileinuser" : "profileinuserDisable"
                  }
                  onClick={() => {
                    setShowProfile(true);
                    setTimespent(false);
                  }}
                >
                  Profile{" "}
                </label>
              )}

            <label
              className={
                !showProfile && !timeSpent
                  ? "testPointlabelEnable"
                  : "testPointlabelDisable"
              }
              onClick={() => {
                setTimespent(false);
                setShowProfile(false);
              }}
            >
              Test Points{" "}
            </label>
            <label
              className={
                timeSpent ? "timeSpentlabelEnable" : "timeSpentlabelDisable"
              }
              onClick={() => {
                setTimespent(true);
                setShowProfile(false);
              }}
            >
              {" "}
              Time Spent
            </label>
          </div>

          {pathname.includes("user") && (
            <div className="evaluationLearnerChange">
              <AngleLeftSvg
                disabled={showPrevBtn}
                handleFunction={() => handleChangeLearnerByBtn("prev")}
              />
              <Select
                isClearable={false}
                styles={customStyle}
                onChange={handlestudentChange}
                options={learnerList}
                value={currentLearnerDetails}
                className="codeEditorLanguageSelectorAdmin"
                isOptionDisabled={(option) => option.isdisabled}
                components={{
                  IndicatorSeparator: () => null,
                }}
              />
              <AngleRightSvg
                disabled={showNextBtn}
                handleFunction={() => handleChangeLearnerByBtn("next")}
              />
            </div>
          )}
        </div>
      )}

      {isAdmin && showProfile && (
        <ProfileProvider>
          <ProfileUser learnerId={currentLearnerDetails.value} />
        </ProfileProvider>
      )}

      {!showProfile &&
      ((!props.from.includes("options") && !timeSpent) ||
        (props.from.includes("options") && props.option == 2)) ? (
        <UserTestResultReportTable />
      ) : (
        // <TableContainer
        //   className={
        //     props.option == 2 || props.option == 3
        //       ? "tablewithtotal-table-container"
        //       : "tablewithtotalpopup-table-container"
        //   }
        // >
        //   <Table className="tablewithtotal-table" aria-label="spanning table">
        //     <TableHead
        //       sx={{ bgcolor: "#f9fafb" }}
        //       className="tablewithtotal-table-th"
        //     >
        //       <TableRow className="tablewithtotal-table-th-tr">
        //         <TableCell className="sticky-header">Test name</TableCell>
        //         {/* <TableCell className="sticky-header" align="center">
        //         Time Spent
        //       </TableCell> */}
        //         <TableCell className="sticky-header" align="center">
        //           Total points
        //         </TableCell>
        //         <TableCell className="sticky-header" align="center">
        //           My score
        //         </TableCell>
        //         <TableCell
        //           align="center"
        //           className="sticky-header tablewithtotal-table-th-tr-pointsscoredcell"
        //         >
        //           Points Scored
        //         </TableCell>
        //       </TableRow>
        //     </TableHead>
        //     <TableBody
        //       sx={{ bgcolor: "#ffffff" }}
        //       className="tablewithtotal-table-tb"
        //     >
        //       {leaderboardTestDetail?.data
        //         ?.filter((i) => i.libtype != constants.libTypeIds.CONTENT)
        //         .map((testdetail, index) => (
        //           <TableRow
        //             key={testdetail?.id}
        //             className="tablewithtotal-table-tb-tr"
        //           >
        //             {/* <Tooltip title={testdetail?.name} placement="right"> */}
        //             <TableCell className="tablewithtotal-table-tb-tr-1sttd">
        //               <div className="tablewithtotal-table-tb-tr-1sttd-totaldiv">
        //                 <div className="tablewithtotal-table-tb-tr-1sttd-totaldiv-testsvg">
        //                   <TestSvgIcon
        //                     libType={testdetail?.libtype}
        //                     component={"learnerDashBoard"}
        //                   />
        //                 </div>
        //                 <div className="tablewithtotal-table-tb-tr-1sttd-totaldiv-name-date-div">
        //                   <Tooltip text={testdetail?.name} position="right">
        //                     <div className="tablewithtotal-table-tb-tr-1sttd-totaldiv-name-date-div-name">
        //                       {testdetail?.name}
        //                     </div>
        //                   </Tooltip>
        //                   <div className="tablewithtotal-table-tb-tr-1sttd-totaldiv-name-date-div-date-lp">
        //                     <span>{testdetail?.assignedDate}</span>
        //                     <span className="line-div"> | </span>
        //                     <span>{testdetail?.lpName}</span>
        //                     <span className="line-div"> | </span>
        //                     <span>{testdetail?.mName}</span>
        //                   </div>
        //                 </div>
        //               </div>
        //             </TableCell>
        //             {/* </Tooltip> */}
        //             <TableCell align="center">
        //               {testdetail?.weightage}
        //             </TableCell>
        //             <TableCell align="center">
        //               {testdetail?.score.replace("null", "-")}
        //             </TableCell>
        //             <TableCell align="center">
        //               {testdetail?.pointsEarned}
        //             </TableCell>
        //           </TableRow>
        //         ))}
        //     </TableBody>
        //     <TableBody className="tablewithtotal-table-tb-total">
        //       <TableRow className="tablewithtotal-table-tb-total-tr">
        //         <TableCell
        //           className="tablewithtotal-table-tb-total-tr-1sttd"
        //           colSpan={3}
        //           align="right"
        //         >
        //           Total
        //         </TableCell>
        //         <TableCell
        //           className="tablewithtotal-table-tb-total-tr-2ndtd"
        //           align="center"
        //         >
        //           {leaderboardTestDetail?.totalPointsEarned}
        //         </TableCell>
        //       </TableRow>
        //     </TableBody>
        //   </Table>
        // </TableContainer>
        !showProfile && (
          <UserTimeSpentReport />
          // <>
          //   <TableContainer
          //     className={
          //       props.option == 2 || props.option == 3
          //         ? "tablewithtotal-table-container"
          //         : "tablewithtotalpopup-table-container"
          //     }
          //   >
          //     <Table
          //       className="tablewithtotal-table"
          //       aria-label="spanning table"
          //     >
          //       <TableHead
          //         sx={{ bgcolor: "#f9fafb" }}
          //         className="tablewithtotal-table-th"
          //       >
          //         <TableRow className="tablewithtotal-table-th-tr">
          //           <TableCell className="sticky-header">Test name</TableCell>
          //           <TableCell className="sticky-header" align="center">
          //             Time Spent
          //           </TableCell>
          //         </TableRow>
          //       </TableHead>
          //       <TableBody
          //         sx={{ bgcolor: "#ffffff" }}
          //         className="tablewithtotal-table-tb"
          //       >
          //         {leaderboardTestDetail?.data?.map((testdetail, index) => (
          //           <TableRow
          //             key={testdetail?.id}
          //             className="tablewithtotal-table-tb-tr"
          //           >
          //             {/* <Tooltip title={testdetail?.name} placement="right"> */}
          //             <TableCell className="tablewithtotal-table-tb-tr-1sttd">
          //               <div className="tablewithtotal-table-tb-tr-1sttd-totaldiv">
          //                 <div className="tablewithtotal-table-tb-tr-1sttd-totaldiv-testsvg">
          //                   <TestSvgIcon
          //                     libType={testdetail?.libtype}
          //                     component={"learnerDashBoard"}
          //                   />
          //                 </div>
          //                 <div className="tablewithtotal-table-tb-tr-1sttd-totaldiv-name-date-div">
          //                   <div className="tablewithtotal-table-tb-tr-1sttd-totaldiv-name-date-div-name">
          //                     {testdetail?.name}
          //                   </div>
          //                   <div className="tablewithtotal-table-tb-tr-1sttd-totaldiv-name-date-div-date-lp">
          //                     <span>{testdetail?.assignedDate}</span>
          //                     <span className="line-div"> | </span>
          //                     <span>{testdetail?.lpName}</span>
          //                     <span className="line-div"> | </span>
          //                     <span>{testdetail?.mName}</span>
          //                   </div>
          //                 </div>
          //               </div>
          //             </TableCell>
          //             {/* </Tooltip> */}
          //             <TableCell align="center">
          //               {formatTime(testdetail?.timespent)}
          //             </TableCell>
          //           </TableRow>
          //         ))}
          //       </TableBody>
          //     </Table>
          //   </TableContainer>
          // </>
        )
      )}
    </div>
  );
}
