import React from "react";

export default function ClockSvg() {
  return (
    <svg
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_2265_12590)">
        <path
          stroke="#344054"
          stroke-width="1.6"
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M9.99935 4.99984V9.99984L13.3327 11.6665M18.3327 9.99984C18.3327 14.6022 14.6017 18.3332 9.99935 18.3332C5.39698 18.3332 1.66602 14.6022 1.66602 9.99984C1.66602 5.39746 5.39698 1.6665 9.99935 1.6665C14.6017 1.6665 18.3327 5.39746 18.3327 9.99984Z"
        />
      </g>
      <defs>
        <clipPath id="clip0_2265_12590">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
