import "./Complexity.scss";
import Select from "react-select";
import constant from "../../../../constants.js";
import AlertContext from "../../../../context/AlertContext.js";
import React, { useState, useEffect, useContext } from "react";
import useAxiosPrivate from "../../../../Hooks/useAxiosPrivate.js";
import CodingQuestionEntryHooks from "../CodingQuestionEntry/Hooks/CodingQuestionEntryHooks.js";

const customStylesForTopic = {
  control: (base, state) => ({
    ...base,
    marginTop: "20px",
    border: "1px solid #BDC1C6",
    maxHeight: "40px !important",
  }),
  option: (styles, { isSelected }) => {
    return {
      ...styles,
      width: "370px",
      height: "36px",
      fontSize: "14px",
      fontWeight: "400",
      lineHeight: "20px",
      background: "white",
      fontFamily: "Inter",
      color: isSelected ? "#000000" : undefined,
      ":hover": {
        color: "white",
        width: "370px",
        backgroundColor: "#0d6efd",
      },
    };
  },
  singleValue: (styles, { data }) => ({
    ...styles,
    color: "#202124",
    fontSize: "14px",
    fontWeight: "400",
    textAlign: "left",
    lineHeight: "20px",
    fontStyle: "normal",
    fontFamily: "Inter",
    paddingLeft: "10px",
    borderRadius: "20px",
  }),
  menuList: (base) => ({
    ...base,
    textAlign: "left",
    maxHeight: "150px",
    overflowX: "hidden",
    borderRadius: "2px",
  }),
};

export default function Complexity(Props) {
  const axios = useAxiosPrivate();
  const { setShow, setMsg, setTitle, setIsUnAuth } = useContext(AlertContext);

  const { setAlreadySelectedCom, setIsSelectedComplexity } =
    CodingQuestionEntryHooks();

  let [ComplexityData, setComplexityData] = useState([]);

  const [changingLevel, setChangingLevel] = useState(true);
  const [complexityValue, setComplexityValue] = useState("");
  const [fromEditQuestion, setFromEditQuestion] = useState(false);

  const handleChange = (event, name) => {
    setIsSelectedComplexity(false);
    setChangingLevel(false);
    setComplexityValue(event);

    Props.setvalue(event, name);
  };

  if (
    Props.isqueReport &&
    Props.tableShowStatus !== constant.libTypeIds.CODING_CHALLENGE &&
    Props.tableShowStatus !== constant.libTypeIds.DESCRIPTIVE &&
    Props.tableShowStatus !== constant.libTypeIds.MCQ &&
    Props.isSelectedComplexity
  ) {
    setAlreadySelectedCom(Props.selectmodule.complexityid);
  }

  useEffect(() => {
    if (
      (Props.tableShowStatus === constant.libTypeIds.CODING_CHALLENGE ||
        Props.tableShowStatus === constant.libTypeIds.DESCRIPTIVE ||
        Props.tableShowStatus === constant.libTypeIds.MCQ) &&
      Props.handleadd
    ) {
      setComplexityValue("");
      Props.setLevel(null);
    }
  }, [Props.handleadd, Props.tableShowStatus]);

  useEffect(() => {
    axios
      .get("node/admin/complexity/get", {
        headers: { "Content-type": "application/json" },
      })
      .then((res) => {
        let json = res.data;
        let newcomplexity = json.map((comp) => {
          return {
            value: comp.id,
            label: comp.name,
          };
        });
        setComplexityData(newcomplexity);
        Props.setComplexities(newcomplexity);
      })
      .catch((error) => {
        if (error.message.includes("403")) {
          setShow(true);
          setIsUnAuth(true);
          setTitle("Error");
          setMsg("You have been logged-out due to inactivity. Login again.");
        }
      });
    setFromEditQuestion(Props.isqueReport);
  }, []);

  return (
    <div className="levels">
      <label htmlFor="levels">
        Level
        <span style={{ color: "red", width: "4px" }}> *</span>
      </label>

      <Select
        id="levels"
        name="complexity"
        options={ComplexityData}
        classNamePrefix="select"
        styles={customStylesForTopic}
        className="basicMultiSelect"
        onChange={(e) => handleChange(e, "complexity")}
        components={{ IndicatorSeparator: () => null }}
        value={
          fromEditQuestion && changingLevel
            ? ComplexityData.filter(
                Props.tableShowStatus === constant.libTypeIds.CODING_CHALLENGE
                  ? (option) =>
                      option.value === Props?.selectmodule?.qdata?.complexity
                  : (option) =>
                      option.value === Props?.selectmodule?.complexityid
              )
            : complexityValue
        }
      />
    </div>
  );
}
