import React from "react";

export default function AutoSubmitSvg() {
  return (
    <svg
      width="300"
      fill="none"
      height="229"
      viewBox="0 0 300 229"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_3108_9689)">
        <path
          fill="#E6E6E6"
          d="M263.191 24.9194H0.463581C0.340631 24.9194 0.222723 24.8705 0.135784 24.7836C0.0488448 24.6966 -3.8147e-06 24.5787 -3.8147e-06 24.4558C-3.8147e-06 24.3328 0.0488448 24.2149 0.135784 24.128C0.222723 24.041 0.340631 23.9922 0.463581 23.9922H263.191C263.314 23.9922 263.432 24.041 263.519 24.128C263.606 24.2149 263.655 24.3328 263.655 24.4558C263.655 24.5787 263.606 24.6966 263.519 24.7836C263.432 24.8705 263.314 24.9194 263.191 24.9194Z"
        />
        <path
          fill="#E6E6E6"
          d="M13.6085 11.0028C15.7736 11.0028 17.5288 9.20844 17.5288 6.99503C17.5288 4.78163 15.7736 2.9873 13.6085 2.9873C11.4433 2.9873 9.68811 4.78163 9.68811 6.99503C9.68811 9.20844 11.4433 11.0028 13.6085 11.0028Z"
        />
        <path
          fill="#E6E6E6"
          d="M27.1515 11.0028C29.3167 11.0028 31.0719 9.20844 31.0719 6.99503C31.0719 4.78163 29.3167 2.9873 27.1515 2.9873C24.9864 2.9873 23.2312 4.78163 23.2312 6.99503C23.2312 9.20844 24.9864 11.0028 27.1515 11.0028Z"
        />
        <path
          fill="#E6E6E6"
          d="M40.6946 11.0028C42.8597 11.0028 44.6149 9.20844 44.6149 6.99503C44.6149 4.78163 42.8597 2.9873 40.6946 2.9873C38.5294 2.9873 36.7742 4.78163 36.7742 6.99503C36.7742 9.20844 38.5294 11.0028 40.6946 11.0028Z"
        />
        <path
          fill="#E6E6E6"
          d="M56.1373 10.162C56.0755 10.1621 56.0144 10.1498 55.9575 10.1258C55.9005 10.1019 55.849 10.0667 55.8059 10.0225L53.1606 7.31845C53.0759 7.23185 53.0284 7.11549 53.0284 6.9943C53.0284 6.87312 53.0759 6.75675 53.1606 6.67015L55.8059 3.96628C55.8922 3.87979 56.0091 3.83084 56.1312 3.83009C56.2534 3.82933 56.3709 3.87683 56.4583 3.96226C56.5456 4.04769 56.5957 4.16413 56.5976 4.28628C56.5996 4.40843 56.5532 4.52642 56.4687 4.61458L54.1405 6.99431L56.4687 9.37425C56.5325 9.43948 56.5756 9.5221 56.5926 9.61174C56.6097 9.70138 56.5999 9.79405 56.5646 9.87816C56.5292 9.96227 56.4697 10.0341 56.3937 10.0846C56.3177 10.135 56.2285 10.162 56.1373 10.162Z"
        />
        <path
          fill="#E6E6E6"
          d="M60.5823 10.1626C60.4911 10.1626 60.4018 10.1356 60.3258 10.0852C60.2498 10.0347 60.1904 9.96287 60.155 9.87876C60.1196 9.79465 60.1099 9.70198 60.1269 9.61234C60.144 9.5227 60.1871 9.44008 60.2509 9.37485L62.5788 6.99491L60.2509 4.61518C60.2077 4.57181 60.1734 4.52029 60.1502 4.46361C60.127 4.40693 60.1152 4.3462 60.1156 4.28494C60.1159 4.22368 60.1285 4.16311 60.1524 4.10672C60.1763 4.05033 60.2112 3.99924 60.255 3.9564C60.2988 3.91357 60.3506 3.87983 60.4075 3.85716C60.4644 3.83448 60.5253 3.8233 60.5865 3.82428C60.6478 3.82525 60.7082 3.83836 60.7644 3.86284C60.8205 3.88731 60.8713 3.92268 60.9137 3.96689L63.5587 6.67076C63.6435 6.75736 63.691 6.87372 63.691 6.99491C63.691 7.11609 63.6435 7.23245 63.5587 7.31905L60.9137 10.0231C60.8706 10.0674 60.819 10.1025 60.7621 10.1264C60.7052 10.1504 60.6441 10.1627 60.5823 10.1626Z"
        />
        <path
          fill="#E6E6E6"
          d="M239.762 3.45117H234.844C234.711 3.45113 234.578 3.47737 234.455 3.52838C234.332 3.57938 234.22 3.65416 234.126 3.74845C234.032 3.84273 233.957 3.95466 233.906 4.07786C233.855 4.20105 233.829 4.33309 233.829 4.46642V9.3897C233.829 9.52304 233.855 9.65507 233.906 9.77826C233.957 9.90146 234.032 10.0134 234.126 10.1077C234.22 10.202 234.332 10.2767 234.455 10.3278C234.578 10.3788 234.711 10.405 234.844 10.4049H239.762C240.032 10.4052 240.291 10.2985 240.482 10.1082C240.673 9.91784 240.781 9.65947 240.782 9.3897V4.46642C240.781 4.19666 240.673 3.93829 240.482 3.74797C240.291 3.55765 240.032 3.45091 239.762 3.45117Z"
        />
        <path
          fill="#E6E6E6"
          d="M227.709 3.45117H222.791C222.657 3.45113 222.525 3.47737 222.402 3.52838C222.279 3.57938 222.167 3.65416 222.073 3.74845C221.978 3.84273 221.904 3.95466 221.853 4.07786C221.802 4.20105 221.775 4.33309 221.775 4.46642V9.3897C221.775 9.52304 221.802 9.65507 221.853 9.77826C221.904 9.90146 221.978 10.0134 222.073 10.1077C222.167 10.202 222.279 10.2767 222.402 10.3278C222.525 10.3788 222.657 10.405 222.791 10.4049H227.709C227.979 10.4052 228.238 10.2985 228.429 10.1082C228.62 9.91784 228.728 9.65947 228.729 9.3897V4.46642C228.728 4.19666 228.62 3.93829 228.429 3.74797C228.238 3.55765 227.979 3.45091 227.709 3.45117Z"
        />
        <path
          fill="#E6E6E6"
          d="M251.12 3.68262H246.202C246.068 3.68258 245.936 3.70881 245.813 3.75982C245.69 3.81083 245.578 3.88561 245.484 3.97989C245.389 4.07417 245.315 4.18611 245.264 4.3093C245.213 4.4325 245.186 4.56453 245.186 4.69787V9.62115C245.186 9.75449 245.213 9.88652 245.264 10.0097C245.315 10.1329 245.389 10.2448 245.484 10.3391C245.578 10.4334 245.69 10.5082 245.813 10.5592C245.936 10.6102 246.068 10.6364 246.202 10.6364H251.12C251.39 10.6367 251.649 10.5299 251.84 10.3396C252.031 10.1493 252.139 9.89092 252.14 9.62115V4.69787C252.139 4.4281 252.031 4.16973 251.84 3.97941C251.649 3.78909 251.39 3.68236 251.12 3.68262Z"
        />
        <path
          fill="#E6E6E6"
          d="M109.367 10.9051H77.38C76.2734 10.9051 75.2122 10.4655 74.4297 9.68305C73.6473 8.9006 73.2077 7.83937 73.2077 6.73281C73.2077 5.62626 73.6473 4.56503 74.4297 3.78258C75.2122 3.00013 76.2734 2.56055 77.38 2.56055H109.367C110.474 2.56055 111.535 3.00013 112.318 3.78258C113.1 4.56503 113.54 5.62626 113.54 6.73281C113.54 7.83937 113.1 8.9006 112.318 9.68305C111.535 10.4655 110.474 10.9051 109.367 10.9051Z"
        />
        <path
          fill="#E6E6E6"
          d="M152.481 10.9051H120.493C119.387 10.9051 118.326 10.4655 117.543 9.68305C116.761 8.9006 116.321 7.83937 116.321 6.73281C116.321 5.62626 116.761 4.56503 117.543 3.78258C118.326 3.00013 119.387 2.56055 120.493 2.56055H152.481C153.587 2.56055 154.649 3.00013 155.431 3.78258C156.213 4.56503 156.653 5.62626 156.653 6.73281C156.653 7.83937 156.213 8.9006 155.431 9.68305C154.649 10.4655 153.587 10.9051 152.481 10.9051Z"
        />
        <path
          fill="#F55533"
          d="M209.033 10.6733H165.003C163.588 10.6733 162.23 10.1111 161.229 9.11025C160.229 8.10943 159.666 6.75203 159.666 5.33666C159.666 3.92129 160.229 2.56389 161.229 1.56307C162.23 0.562254 163.588 8.43627e-08 165.003 0H209.033C210.448 8.43627e-08 211.806 0.562254 212.806 1.56307C213.807 2.56389 214.369 3.92129 214.369 5.33666C214.369 6.75203 213.807 8.10943 212.806 9.11025C211.806 10.1111 210.448 10.6733 209.033 10.6733Z"
        />
        <path
          fill="white"
          d="M209.656 8.51257C209.572 8.51267 209.489 8.49621 209.412 8.46413C209.334 8.43204 209.264 8.38497 209.205 8.32562L204.115 3.23569C204.055 3.17645 204.008 3.1061 203.976 3.02869C203.944 2.95128 203.928 2.86832 203.928 2.78453C203.928 2.70074 203.944 2.61777 203.976 2.54036C204.008 2.46295 204.055 2.39261 204.115 2.33337C204.174 2.27412 204.244 2.22712 204.322 2.19505C204.399 2.16299 204.482 2.14648 204.566 2.14648C204.65 2.14648 204.733 2.16299 204.81 2.19505C204.887 2.22712 204.958 2.27411 205.017 2.33336L210.107 7.42332C210.196 7.51257 210.257 7.62626 210.282 7.75002C210.306 7.87379 210.294 8.00208 210.245 8.11867C210.197 8.23525 210.115 8.33491 210.01 8.40503C209.905 8.47514 209.782 8.51255 209.656 8.51257Z"
        />
        <path
          fill="white"
          d="M204.581 8.51258C204.665 8.51268 204.748 8.49622 204.825 8.46413C204.903 8.43205 204.973 8.38498 205.032 8.32563L210.122 3.2357C210.241 3.11595 210.308 2.9538 210.308 2.78482C210.308 2.61584 210.24 2.45383 210.121 2.33434C210.002 2.21485 209.839 2.14764 209.671 2.14746C209.502 2.14728 209.339 2.21414 209.22 2.33337L204.13 7.42333C204.04 7.51257 203.98 7.62626 203.955 7.75003C203.93 7.8738 203.943 8.00209 203.991 8.11867C204.04 8.23526 204.121 8.33491 204.226 8.40503C204.331 8.47515 204.455 8.51256 204.581 8.51258Z"
        />
        <path
          fill="#F55533"
          d="M156.227 45.3953H107.144C107.005 45.3956 106.867 45.3684 106.739 45.3153C106.61 45.2623 106.493 45.1844 106.395 45.086C106.296 44.9877 106.218 44.8709 106.165 44.7424C106.111 44.6138 106.084 44.476 106.084 44.3368C106.084 44.1976 106.111 44.0598 106.165 43.9312C106.218 43.8027 106.296 43.6859 106.395 43.5876C106.493 43.4892 106.61 43.4113 106.739 43.3583C106.867 43.3052 107.005 43.278 107.144 43.2783H156.227C156.508 43.2789 156.776 43.3907 156.974 43.5891C157.172 43.7876 157.284 44.0565 157.284 44.3368C157.284 44.6171 157.172 44.886 156.974 45.0845C156.776 45.2829 156.508 45.3947 156.227 45.3953Z"
        />
        <path
          fill="#F2F2F2"
          d="M168.651 55.073H94.7211C94.582 55.0733 94.4441 55.0461 94.3154 54.9931C94.1867 54.94 94.0698 54.8621 93.9713 54.7638C93.8727 54.6655 93.7946 54.5487 93.7412 54.4201C93.6879 54.2915 93.6604 54.1537 93.6604 54.0145C93.6604 53.8753 93.6879 53.7375 93.7412 53.609C93.7946 53.4804 93.8727 53.3636 93.9713 53.2653C94.0698 53.167 94.1867 53.0891 94.3154 53.036C94.4441 52.9829 94.582 52.9558 94.7211 52.9561H168.651C168.931 52.9566 169.2 53.0684 169.398 53.2669C169.596 53.4653 169.707 53.7342 169.707 54.0145C169.707 54.2949 169.596 54.5638 169.398 54.7622C169.2 54.9606 168.931 55.0724 168.651 55.073Z"
        />
        <path
          fill="#F2F2F2"
          d="M168.652 62.6309H94.7195C94.4385 62.6309 94.1691 62.7425 93.9704 62.9411C93.7718 63.1398 93.6602 63.4092 93.6602 63.6902C93.6602 63.9711 93.7718 64.2405 93.9704 64.4392C94.1691 64.6378 94.4385 64.7494 94.7195 64.7494H168.652C168.933 64.7494 169.202 64.6378 169.401 64.4392C169.6 64.2405 169.711 63.9711 169.711 63.6902C169.711 63.4092 169.6 63.1398 169.401 62.9411C169.202 62.7425 168.933 62.6309 168.652 62.6309Z"
        />
        <path
          fill="#F2F2F2"
          d="M168.651 74.4265H94.7211C94.582 74.4268 94.4441 74.3996 94.3154 74.3466C94.1867 74.2935 94.0698 74.2156 93.9713 74.1173C93.8727 74.019 93.7946 73.9022 93.7412 73.7736C93.6879 73.645 93.6604 73.5072 93.6604 73.368C93.6604 73.2289 93.6879 73.091 93.7412 72.9625C93.7946 72.8339 93.8727 72.7171 93.9713 72.6188C94.0698 72.5205 94.1867 72.4426 94.3154 72.3895C94.4441 72.3364 94.582 72.3093 94.7211 72.3096H168.651C168.931 72.3102 169.2 72.4219 169.398 72.6204C169.596 72.8188 169.707 73.0877 169.707 73.368C169.707 73.6484 169.596 73.9173 169.398 74.1157C169.2 74.3142 168.931 74.4259 168.651 74.4265Z"
        />
        <path
          fill="#F2F2F2"
          d="M168.651 84.1033H94.7211C94.4404 84.1033 94.1712 83.9917 93.9727 83.7932C93.7742 83.5947 93.6627 83.3255 93.6627 83.0448C93.6627 82.7641 93.7742 82.4948 93.9727 82.2963C94.1712 82.0978 94.4404 81.9863 94.7211 81.9863H168.651C168.931 81.9863 169.201 82.0978 169.399 82.2963C169.598 82.4948 169.709 82.7641 169.709 83.0448C169.709 83.3255 169.598 83.5947 169.399 83.7932C169.201 83.9917 168.931 84.1033 168.651 84.1033Z"
        />
        <path
          fill="#F2F2F2"
          d="M168.651 93.8386H94.7211C94.4404 93.8386 94.1712 93.7271 93.9727 93.5286C93.7742 93.3301 93.6627 93.0609 93.6627 92.7802C93.6627 92.4994 93.7742 92.2302 93.9727 92.0317C94.1712 91.8332 94.4404 91.7217 94.7211 91.7217H168.651C168.931 91.7217 169.201 91.8332 169.399 92.0317C169.598 92.2302 169.709 92.4994 169.709 92.7802C169.709 93.0609 169.598 93.3301 169.399 93.5286C169.201 93.7271 168.931 93.8386 168.651 93.8386Z"
        />
        <path
          fill="#F2F2F2"
          d="M168.651 103.574H94.7211C94.4404 103.574 94.1712 103.462 93.9727 103.264C93.7742 103.065 93.6627 102.796 93.6627 102.516C93.6627 102.235 93.7742 101.966 93.9727 101.767C94.1712 101.569 94.4404 101.457 94.7211 101.457H168.651C168.931 101.457 169.201 101.569 169.399 101.767C169.598 101.966 169.709 102.235 169.709 102.516C169.709 102.796 169.598 103.065 169.399 103.264C169.201 103.462 168.931 103.574 168.651 103.574Z"
        />
        <path
          fill="#F2F2F2"
          d="M168.651 113.309H94.7211C94.4404 113.309 94.1712 113.198 93.9727 112.999C93.7742 112.801 93.6627 112.532 93.6627 112.251C93.6627 111.97 93.7742 111.701 93.9727 111.502C94.1712 111.304 94.4404 111.192 94.7211 111.192H168.651C168.931 111.192 169.201 111.304 169.399 111.502C169.598 111.701 169.709 111.97 169.709 112.251C169.709 112.532 169.598 112.801 169.399 112.999C169.201 113.198 168.931 113.309 168.651 113.309Z"
        />
        <path
          fill="#F2F2F2"
          d="M168.651 123.045H94.7211C94.4404 123.045 94.1712 122.933 93.9727 122.735C93.7742 122.536 93.6627 122.267 93.6627 121.986C93.6627 121.705 93.7742 121.436 93.9727 121.238C94.1712 121.039 94.4404 120.928 94.7211 120.928H168.651C168.931 120.928 169.201 121.039 169.399 121.238C169.598 121.436 169.709 121.705 169.709 121.986C169.709 122.267 169.598 122.536 169.399 122.735C169.201 122.933 168.931 123.045 168.651 123.045Z"
        />
        <path
          fill="#A0616A"
          d="M208.322 9.40483C208.444 10.4313 208.961 11.3698 209.764 12.0207C210.567 12.6716 211.592 12.9835 212.621 12.8901C212.83 12.8678 213.037 12.8283 213.239 12.7719L221.222 24.081L228.386 22.0494L215.776 7.73956C215.477 6.91835 214.914 6.21917 214.176 5.75112C213.438 5.28308 212.566 5.0725 211.696 5.15226C211.201 5.2024 210.721 5.34949 210.283 5.58515C209.845 5.8208 209.458 6.14041 209.144 6.5257C208.83 6.91099 208.594 7.35444 208.451 7.83071C208.308 8.30699 208.26 8.80677 208.31 9.30152C208.314 9.336 208.318 9.37044 208.322 9.40483Z"
        />
        <path
          fill="#A0616A"
          d="M256.338 222.995L251.844 222.994L249.705 205.659L256.339 205.66L256.338 222.995Z"
        />
        <path
          fill="#2F2E41"
          d="M256.496 227.684L242.675 227.683V227.508C242.676 226.082 243.242 224.713 244.251 223.705C245.26 222.696 246.628 222.129 248.055 222.129L250.58 220.214L255.29 222.129L256.496 222.129L256.496 227.684Z"
        />
        <path
          fill="#A0616A"
          d="M241.432 212.343L237.086 213.485L230.61 197.264L237.025 195.577L241.432 212.343Z"
        />
        <path
          fill="#2F2E41"
          d="M242.778 216.837L229.411 220.351L229.367 220.182C229.004 218.802 229.204 217.335 229.923 216.103C230.643 214.87 231.822 213.974 233.201 213.611L235.156 211.117L240.199 211.772L241.365 211.465L242.778 216.837Z"
        />
        <path
          fill="#2F2E41"
          d="M234.535 108.258C234.535 108.258 225.547 116.521 226.621 117.805C227.695 119.088 230.313 118.034 227.695 120.743C225.077 123.452 223.913 117.775 225.077 124.395C226.241 131.016 220.3 165.153 220.3 165.153C220.3 165.153 218.929 171.621 219.821 172.555C220.713 173.49 222.052 172.142 221.092 174.852C220.131 177.563 217.474 176.399 219.821 178.726C222.168 181.054 231.522 205.939 231.522 205.939L239.948 201.337C239.948 201.337 238.168 175.817 235.55 173.199C232.932 170.581 244.745 143.943 244.745 143.943C244.745 143.943 245.744 198.638 246.902 201.337C248.06 204.036 250.096 203.106 248.06 205.171C246.023 207.236 244.734 205.272 246.542 208.29C248.351 211.309 247.732 213.563 247.732 213.563H258.046L267.212 132.642L264.516 110.152L234.535 108.258Z"
        />
        <path
          fill="#E6E6E6"
          d="M227.34 115.89C235.907 105.862 233.014 93.1076 229.613 80.2322L230.26 61.2115C230.292 60.2784 230.548 59.3667 231.006 58.5534C231.464 57.74 232.112 57.0488 232.893 56.5382L241.639 50.8245L243.633 43.2754L254.169 44.5395L259.63 52.3439L265.39 56.7409C266.836 57.8449 267.936 59.3394 268.561 61.0481C269.186 62.7569 269.309 64.6086 268.916 66.3851L263.404 91.3112C263.404 91.3112 265.71 104.233 264.303 105.66C262.896 107.087 259.272 105.661 262.146 107.263C265.596 109.185 268.732 108.831 266.986 110.576C265.241 112.322 267.45 114.748 267.45 114.748L227.34 115.89Z"
        />
        <path
          fill="#E6E6E6"
          d="M262.232 65.9416C262.232 65.9416 270.967 64.7495 268.745 61.5658C266.523 58.3821 263.348 50.5583 253.996 47.8326L226.813 16.2891C226.813 16.2891 217.043 21.6037 219.511 22.7669C221.978 23.9301 222.446 25.0724 222.446 25.0724C222.446 25.0724 221.275 27.2891 223.622 29.1275C225.968 30.966 233.179 43.8662 233.179 43.8662L248.695 64.7867L262.232 65.9416Z"
        />
        <path
          fill="#A0616A"
          d="M244.874 45.1891C250.788 45.1891 255.582 40.3951 255.582 34.4813C255.582 28.5675 250.788 23.7734 244.874 23.7734C238.961 23.7734 234.167 28.5675 234.167 34.4813C234.167 40.3951 238.961 45.1891 244.874 45.1891Z"
        />
        <path
          fill="#2F2E41"
          d="M249.032 44.0414L249.733 44.2653C247.539 44.4494 243.629 45.6733 244.012 44.9967C244.395 44.3201 242.947 41.7679 243.654 41.4445C244.388 41.1868 245.043 40.7428 245.554 40.1559C246.002 39.5291 245.992 38.4943 245.32 38.1112C244.668 37.7331 243.863 38.1411 243.216 38.5241C242.564 38.9023 241.36 33.3402 241.669 31.6039C239.853 30.6089 237.654 32.5143 235.614 32.1412C234.365 31.9123 233.465 30.8875 232.898 29.7233C233.824 28.8444 234.514 27.7458 234.903 26.5294C234.037 26.9656 233.111 27.2723 232.156 27.4398C231.985 26.5364 232.009 25.6069 232.226 24.7134C232.393 24.0002 232.733 23.3392 233.216 22.7889C233.699 22.2387 234.311 21.8163 234.997 21.5593C236.39 21.1115 238.106 21.6638 238.778 22.9622C238.82 22.285 238.646 21.6119 238.282 21.0397C237.917 20.4676 237.38 20.0259 236.748 19.7782C237.209 19.539 237.719 19.4096 238.239 19.4C238.758 19.3904 239.272 19.5009 239.742 19.7229C240.211 19.9449 240.623 20.2724 240.945 20.6798C241.268 21.0873 241.491 21.5636 241.599 22.0717C243.846 20.9458 246.424 20.6701 248.857 21.2956C251.265 21.9275 254.459 24.599 254.459 24.599C259.892 25.798 259.633 46.4543 249.032 44.0414Z"
        />
        <path
          fill="#A0616A"
          d="M220.079 133.724C220.804 132.988 221.213 131.997 221.219 130.964C221.224 129.93 220.827 128.935 220.11 128.19C219.962 128.041 219.802 127.904 219.632 127.782L223.466 114.48L217.401 110.16L214.119 128.949C213.665 129.696 213.471 130.572 213.567 131.44C213.663 132.309 214.044 133.121 214.65 133.75C214.999 134.105 215.414 134.388 215.871 134.582C216.329 134.777 216.821 134.879 217.318 134.883C217.815 134.888 218.308 134.794 218.77 134.608C219.231 134.422 219.651 134.147 220.005 133.798C220.03 133.774 220.054 133.749 220.079 133.724Z"
        />
        <path
          fill="#E6E6E6"
          d="M242.923 58.7415C242.923 58.7415 231.921 55.4721 230.656 59.1427C229.391 62.8133 228.972 65.1442 223.752 79.7945L214.265 120.34C214.265 120.34 224.313 125.107 223.8 122.427C223.287 119.748 223.935 118.697 223.935 118.697C223.935 118.697 226.416 118.338 226.52 115.359C226.624 112.38 232.804 98.9549 232.804 98.9549L240.576 74.0954L242.923 58.7415Z"
        />
        <path
          fill="#CCCCCC"
          d="M181.475 227.793C181.475 227.865 181.489 227.937 181.517 228.004C181.544 228.071 181.585 228.132 181.636 228.183C181.688 228.235 181.748 228.275 181.815 228.303C181.883 228.331 181.954 228.345 182.027 228.345H299.448C299.595 228.345 299.735 228.286 299.838 228.183C299.942 228.08 300 227.939 300 227.793C300 227.647 299.942 227.506 299.838 227.403C299.735 227.299 299.595 227.241 299.448 227.241H182.027C181.954 227.241 181.883 227.255 181.816 227.283C181.748 227.31 181.688 227.351 181.636 227.402C181.585 227.454 181.544 227.514 181.517 227.582C181.489 227.649 181.475 227.72 181.475 227.793Z"
        />
      </g>
      <defs>
        <clipPath id="clip0_3108_9689">
          <rect width="300" height="228.345" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
