// import React, { useState, useRef, useEffect } from 'react';
// import { makeStyles } from '@material-ui/core/styles';
// import { Timeline, TimelineItem, TimelineSeparator, TimelineConnector, TimelineContent, TimelineDot } from '@mui/lab';
// import { IconButton } from '@mui/material';
// import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
// import NavigateNextIcon from '@mui/icons-material/NavigateNext';

// import ProgressCircle from './ProgressCircle';
// import SessionDetail from './SessionDetail';
// import constants from "../../constants"
// import { Typography, Tooltip } from '@mui/material';


// const useStyles = makeStyles((theme) => ({
//     arrow: {
//         "&:before": {
//             border: "1px solid #E6E8ED",
//         },
//         color: theme.palette.common.white,
//     },
//     tooltip: {
//         backgroundColor: theme.palette.common.white,
//         border: "1px solid #E6E8ED", 
//         // color: "#4A4A4A",
//     },
// }));


// export default function Timelinegraph(props) {

//     const classes = useStyles();

//     const timelineRef = useRef(null);



//     var data = {
//         "sessionData": [
//             {
//                 "mode": 0,
//                 "endTime": "12:30 AM",
//                 "sessionId": 14,
//                 "startTime": "12:00 AM",
//                 "startdate": "Tue, 16 Apr",
//                 "absentCount": 0,
//                 "organizedBy": "Abdul",
//                 "sessionName": "HTML",
//                 "presentCount": 1,
//                 "studentStatus": 1,
//                 "recordedStatus": null
//             },
//             {
//                 "mode": 0,
//                 "endTime": "12:30 AM",
//                 "sessionId": 14,
//                 "startTime": "12:00 AM",
//                 "startdate": "Tue, 16 Apr",
//                 "absentCount": 0,
//                 "organizedBy": "Abdul",
//                 "sessionName": "HTML",
//                 "presentCount": 1,
//                 "studentStatus": 1,
//                 "recordedStatus": null
//             },
//             {
//                 "mode": 0,
//                 "endTime": "12:30 AM",
//                 "sessionId": 14,
//                 "startTime": "12:00 AM",
//                 "startdate": "Tue, 16 Apr",
//                 "absentCount": 0,
//                 "organizedBy": "Abdul",
//                 "sessionName": "HTML",
//                 "presentCount": 1,
//                 "studentStatus": 1,
//                 "recordedStatus": null
//             },
//             {
//                 "mode": 0,
//                 "endTime": "12:30 AM",
//                 "sessionId": 14,
//                 "startTime": "12:00 AM",
//                 "startdate": "Tue, 16 Apr",
//                 "absentCount": 0,
//                 "organizedBy": "Abdul",
//                 "sessionName": "HTML",
//                 "presentCount": 1,
//                 "studentStatus": 1,
//                 "recordedStatus": null
//             },
//             {
//                 "mode": 0,
//                 "endTime": "12:30 AM",
//                 "sessionId": 14,
//                 "startTime": "12:00 AM",
//                 "startdate": "Tue, 16 Apr",
//                 "absentCount": 0,
//                 "organizedBy": "Abdul",
//                 "sessionName": "HTML",
//                 "presentCount": 1,
//                 "studentStatus": 1,
//                 "recordedStatus": null
//             },
//             {
//                 "mode": 0,
//                 "endTime": "12:30 AM",
//                 "sessionId": 14,
//                 "startTime": "12:00 AM",
//                 "startdate": "Tue, 16 Apr",
//                 "absentCount": 0,
//                 "organizedBy": "Abdul",
//                 "sessionName": "HTML",
//                 "presentCount": 1,
//                 "studentStatus": 1,
//                 "recordedStatus": null
//             },
//             {
//                 "mode": 0,
//                 "endTime": "12:30 AM",
//                 "sessionId": 14,
//                 "startTime": "12:00 AM",
//                 "startdate": "Tue, 16 Apr",
//                 "absentCount": 0,
//                 "organizedBy": "Abdul",
//                 "sessionName": "HTML",
//                 "presentCount": 1,
//                 "studentStatus": 1,
//                 "recordedStatus": null
//             }

//         ],
//         "overallAttendancePercentage": 100
//     }



// // Function to group sessions by month
//     function groupSessionsByMonth(data) {
//         const sessionsByMonth = {};
//         // Iterate through session data
//         if (data!=null && data?.length>0 ){
//             data.forEach(session => {
//                 const startDate = session.startdate;
//                 // Extract month from startdate
//                 const month = startDate.split(',')[1].trim().toLowerCase().split(' ')[1];
//                 // Create month array if not already exists
//                 if (!sessionsByMonth[month]) {
//                     sessionsByMonth[month] = [];
//                 }
//                 // Push session to corresponding month array
//                 sessionsByMonth[month].push(session);
//             });
//             // Sort sessions within each month by start date
//             for (const month in sessionsByMonth) {
//                 sessionsByMonth[month].sort((a, b) => {
//                     const dateA = new Date(a.startdate.split(',')[1].trim());
//                     const dateB = new Date(b.startdate.split(',')[1].trim());
//                     return dateA - dateB;
//                 });
//             }
//             return sessionsByMonth;
//         }
//         return {};
//     }

// // Order of months
//     const monthOrder = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

// // Group sessions by month
//     const sessionsByMonth = groupSessionsByMonth(data.sessionData);


//     const scrollTimelineLeft = () => {
//         if (timelineRef.current) {
//         timelineRef.current.scrollLeft -= 300; // Adjust scroll distance as needed
//         }
//     };

//   const scrollTimelineRight = () => {
//     if (timelineRef.current) {
//       timelineRef.current.scrollLeft += 200; // Adjust scroll distance as needed
//     }
//   };

// //   useEffect(()=>{
// //     const timelineDiv = timelineRef.current;

// //     console.log("timelineDiv ", timelineDiv)
// //     console.log("timelineDiv.scrollHeight ", timelineDiv.scrollHeight)
// //     console.log("timelineDiv.clientHeigh ", timelineDiv.clientHeight)

// //   },[sessionsByMonth])

//   return (
//     <div className='timeline-div'>
//     <div className='timeline-title-div'>Sessions</div>
//     <div className='timeline-timeline-progresscircle-div'>
//     <div className='timeline-with-navigateicon-div'>
//     <IconButton className='NavigateBeforeIcon-btn' onClick={scrollTimelineLeft}><NavigateBeforeIcon /></IconButton>
//     <div className='timeline-div-rotate' ref={timelineRef}>
//     <Timeline /* position="alternate" */ align="left" className="timeline-div-timeline">
//         {monthOrder?.map((month,index) => (
//         <>
//         {sessionsByMonth[(month.toLowerCase())] && sessionsByMonth[month.toLowerCase()].length > 0 && (
//             <TimelineItem className='timeline-item' key={`month-${month}`}>
//             <TimelineSeparator>
//                 <TimelineDot /* variant="outlined" */ />
//                 <TimelineConnector />
//             </TimelineSeparator>
//             <TimelineContent className='timeline-div-timeline-month'>{month}</TimelineContent>
//             </TimelineItem>
//         )}
//             {sessionsByMonth[(month.toLowerCase())] && sessionsByMonth[month.toLowerCase()]?.map(session => (
//                 <TimelineItem className='timeline-item' key={`session-${session.sessionId}`}>
//                     <TimelineSeparator>
//                     <Tooltip classes={{ arrow: classes.arrow, tooltip: classes.tooltip }} 
//                         title={
//                             <SessionDetail 
//                             sessionName={session.sessionName} 
//                             studentStatus={session.studentStatus}
//                             startdate = {session.startdate}
//                             startTime={session.startTime}
//                             endTime={session.endTime}
//                             />
//                             } arrow>
//                         <TimelineDot className={`session-timeline-dots-${session.studentStatus == constants.ATTENDANCE_STATUS.NO_RESPONSE?'noresponse':(session.studentStatus == constants.ATTENDANCE_STATUS.PARTIALLY_PRESENT || session.studentStatus == constants.ATTENDANCE_STATUS.PRESENT)?'present':'absent'}`} /* variant="outlined" */ 
//                         // color="primary"
//                         />
//                     </Tooltip>
//                     <TimelineConnector />
//                     </TimelineSeparator>
//                     <TimelineContent></TimelineContent>
//                 </TimelineItem>
//             ))}
//         </>
//         ))}
//     </Timeline>
//     </div>
//     <IconButton className='NavigateNextIcon-btn' onClick={scrollTimelineRight}><NavigateNextIcon /></IconButton>
//     </div>
//     <div className='timeline-progresscircle-div'><ProgressCircle overallAttendancePercentage={props.timelineData?.overallAttendancePercentage}/></div>
//     </div>
//     </div>
//   )
// }

import React, { useState, useRef, useEffect } from 'react';
import { Timeline, TimelineItem } from "react-beautiful-horizontal-timeline";
import moment from 'moment';
import SessionDetail from './SessionDetail';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from '@material-ui/core/styles';
import constants from "../../constants";
import ProgressCircle from './ProgressCircle';


const useStyles = makeStyles((theme) => ({
    arrow: {
        "&:before": {
            border: "1px solid #E6E8ED !important",
        },
        color: /* theme.palette.common.white */"#FFFFFF !important",
    },
    tooltip: {
        backgroundColor: /* theme.palette.common.white */"#FFFFFF !important",
        border: "1px solid #E6E8ED !important", 
        // color: "#4A4A4A",
    },
}));


export default function Timelinegraph(props) {
    const classes = useStyles();

    const [labelWidth, setlabelWidth] = useState(140);
    const [amountMove, setamountMove] = useState(350);
    const [lineColor, setlineColor] = useState("#F2F4F7");
    const [darkMode, setdarkMode] = useState(false);
    const [eventTextAlignCenter, seteventTextAlignCenter] = useState(true);
    const [showSlider, setshowSlider] = useState(true);
    const [arrowsSize, setarrowsSize] = useState(false);

    // Function to group sessions by month
    function groupSessionsByMonth(data) {
        const sessionsByMonth = {};
        // Iterate through session data
        if (data != null && data?.length > 0) {

            const sessionsByMonth = {};

            data.forEach(session => {
                const startDate = session.startdate;
                const month = startDate.split(',')[1].trim().toLowerCase().split(' ')[1];

                // Create an object for the session
                const sessionObject = { monthName: month, session: session };

                // If the month already exists, push the session to its sessions array
                if (sessionsByMonth[month]) {
                    sessionsByMonth[month].push(sessionObject);
                } else {
                    // Otherwise, create a new entry for the month
                    sessionsByMonth[month] = [sessionObject];
                }
            });


            // Sort sessions within each month by start date
            for (const month in sessionsByMonth) {
                sessionsByMonth[month].sort((a, b) => {
                    const dateA = new Date(a.session.startdate.split(',')[1].trim());
                    const dateB = new Date(b.session.startdate.split(',')[1].trim());
                    return dateA - dateB;
                });
            }
            return sessionsByMonth;
        }
        return {};
    }

    // Order of months
    const monthOrder = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const monthMapping = {
        'Jan': 'January',
        'Feb': 'February',
        'Mar': 'March',
        'Apr': 'April',
        'May': 'May',
        'Jun': 'June',
        'Jul': 'July',
        'Aug': 'August',
        'Sep': 'September',
        'Oct': 'October',
        'Nov': 'November',
        'Dec': 'December'
      };

    // Group sessions by month
    const sessionsByMonth = groupSessionsByMonth(props.timelineData?.sessionData);
    const monthsWithData = monthOrder.filter(month => sessionsByMonth[month.toLowerCase()] && sessionsByMonth[month.toLowerCase()].length > 0);

    const monthData = [];

    /* mouse enter and mouse leave function */
    // const [hoveredSession, setHoveredSession] = useState(null);

    // const onMouseEnter=(session)=>{
    //     setHoveredSession(session)
    // }

    // const onMouseLeave = () => {
    //     setHoveredSession(null);
    //   };

    

    const timelineItems = monthsWithData?.map((month, index1) => {
        if (sessionsByMonth[(month.toLowerCase())]) {
            // const monthItem = {
            //     title: month,
            //     component: (
            //         <div>
            //             <div key={`session-${index1}`}></div>
            //         </div>
            //     )
            // };
            // montIntem.push(monthItem);

            sessionsByMonth[(month.toLowerCase())] && sessionsByMonth[month.toLowerCase()]?.map((session, index2) => {
                const sessionStatus = session.session.studentStatus;
                const dotColorClass = sessionStatus === constants.ATTENDANCE_STATUS.NO_RESPONSE ? 'noresponse' : sessionStatus === constants.ATTENDANCE_STATUS.ABSENT ? 'absent' : 'present';
                const monthItem = {
                    title: (
                        <Tooltip classes={{ arrow: classes.arrow, tooltip: classes.tooltip }}  title={<SessionDetail
                            sessionName={session.session.sessionName}
                            studentStatus={session.session.studentStatus}
                            startdate={session.session.startdate}
                            startTime={session.session.startTime}
                            endTime={session.session.endTime}
                            />} arrow>
                        <div className={`header-for-onhover-${dotColorClass}`} key={`title-${index1}-${index2}`} /* onMouseEnter={()=>onMouseEnter(session)} onMouseLeave={onMouseLeave} */>
                                {''}
                        </div>
                        </Tooltip>),
                    component: (
                        <>
                        {index2 === 0 && <div className='tool-tip'>
                            { monthMapping[month] }
                            
                            {/* {(hoveredSession != null && hoveredSession.session.sessionId === session.session.sessionId) && ( */}
                                {/* <SessionDetail
                                    sessionName={session.session.sessionName}
                                    studentStatus={session.session.studentStatus}
                                    startdate={session.session.startdate}
                                    startTime={session.session.startTime}
                                    endTime={session.session.endTime}
                                /> */}
                        {/* )} */}
                        </div> }
                        </>
                    )
                };
                monthData.push(monthItem);
            })
        } else {
            const monthItem = {
                title: month,
                component: (
                    <React.Fragment>
                        <div className="timeline-dot" />
                    </React.Fragment>
                )
            };
            monthData.push(monthItem);;
        }
    })

    return (
        // <div className='timeline-div'>
        // <div className='timeline-title-div'>Sessions</div>
        // <div className='timeline-timeline-progresscircle-div'>
        // <div className='timeline-with-navigateicon-div'>
        // <Chrono className='react-crono-timeline' items={items} mode="HORIZONTAL" flipLayout={true} enableQuickJump={false} enableLayoutSwitch={false} enableBreakPoint={false} cardLess={true}/>
        // </div>
        // <div className='timeline-progresscircle-div'></div>
        // </div>
        // </div>

        <div className='timeline-div'>
            <div className='timeline-title-div'>Sessions</div>
            <div className='timeline-timeline-progresscircle-div'>
                <div className='timeline-with-navigateicon-div'>
                    <Timeline
                        className='react-beautiful-timeline'
                        myList={monthData}
                        labelWidth={labelWidth}
                        amountMove={amountMove}
                        onClick={() => console.log("click")}
                        lineColor={lineColor}
                        darkMode={darkMode}
                        eventTextAlignCenter={eventTextAlignCenter}
                        // showSlider={showSlider}
                        arrowsSize={arrowsSize}
                    />
                </div>
                <div className='timeline-progresscircle-div'><ProgressCircle overallAttendancePercentage={props.timelineData?.overallAttendancePercentage}/></div>
            </div>
        </div>
    )
}
