import React, { useContext } from 'react';
import Closeicon from './Closeicon';
import AlertContext from '../../context/AlertContext';

export default function PopupCloseIcon(props) {
    const { commonPopupSecBtnFunc } = useContext(AlertContext);
  return (
    <div>
        <Closeicon handleClosePopup={commonPopupSecBtnFunc}/>
    </div>
  )
}
