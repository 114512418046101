import { useContext } from "react";
import AlertContext from "../../context/AlertContext";
import { UserReportContext } from "../../context/UserReportContext";

export default function UserReportHooks() {
  const Alert = useContext(AlertContext);
  const UserReportCxt = useContext(UserReportContext);

  const customStyle = {
    control: (provided) => ({
      ...provided,
      minWidth: 150,
      maxWidth: 200,
      outline: "none",
      cursor: "pointer",
      boxShadow: "none",
      border: "0px solid white",
      backgroundColor: "#fafafa",
    }),
    option: (styles) => {
      return {
        ...styles,
        color: "black",
        fontSize: "12px",
        textAlign: "left",
        cursor: "pointer",
        padding: "2px 12px",
        backgroundColor: "white",
      };
    },
  };

  const handleChangeLearnerByBtn = (action) => {
    if (action === "prev") {
      if (UserReportCxt.currentLearnerDetails.ind > 0) {
        UserReportCxt.setCurrentLearnerDetails(
          UserReportCxt.learnerList[UserReportCxt.currentLearnerDetails.ind--]
        );
      }
    } else {
      if (
        UserReportCxt.currentLearnerDetails.ind <
        UserReportCxt.learnerList.length - 1
      ) {
        UserReportCxt.setCurrentLearnerDetails(
          UserReportCxt.learnerList[UserReportCxt.currentLearnerDetails.ind++]
        );
      }
    }
  };

  const handlestudentChange = (e) => {
    Alert.setLearnerId(e.value);
    UserReportCxt.setCurrentLearnerDetails(e);
  };

  return { customStyle, handlestudentChange, handleChangeLearnerByBtn };
}
