import constants from "../constants";
import CommentsHooks from "./CommentsHooks";
import { useContext, useState } from "react";
import useAxiosPrivate from "./useAxiosPrivate";
import AlertContext from "../context/AlertContext";
import useCommonFunctionHooks from "./useCommonFunctionHooks";

export default function ApproveHooks() {
  const {
    comment,
    setScore,
    setIsScore,
    setIsLoaded,
    approveData,
    setShowNotify,
    setShowReject,
    setShowApprove,
    showBreadCrumbsData,
  } = useContext(AlertContext);

  const axios = useAxiosPrivate();
  const { handleAddComments } = CommentsHooks();
  const { getCatchBlockDetails, getHeaderDetailsForAxios } =
    useCommonFunctionHooks();

  const [scoreChanged, setScoreChanged] = useState(false);

  const handleScoreSelect = (e) => {
    setScoreChanged(false);
  };

  const getPayLoadForAddGrade = (score) => {
    let payLoad = {
      newscore: score,
      libtypeid: showBreadCrumbsData.type,
      learnerid: showBreadCrumbsData.learnerId,
      lpconfigid: showBreadCrumbsData.lpConfigId,
    };

    if (showBreadCrumbsData.type === constants.libTypeIds.CODING_CHALLENGE) {
      payLoad.commentdata = comment;
      payLoad.testid = showBreadCrumbsData.testId;
      payLoad.questionid = showBreadCrumbsData.questionId;
    } else {
      payLoad.comments = comment;
      payLoad.questionid = showBreadCrumbsData.testId;
    }

    return JSON.stringify(payLoad);
  };

  const addGrade = (score) => {
    if (score >= 0 && score <= approveData.marks) {
      setIsLoaded(true);
      axios
        .post(
          "node/admin/test/score",
          getPayLoadForAddGrade(score),
          getHeaderDetailsForAxios()
        )
        .then((res) => {
          if (res.data.resultCode === constants.RESULT_STATUS.SUCCESS) {
            setShowReject(false);
            setShowApprove(false);
            approveData.actualscore = score;

            if (comment !== "") {
              handleAddComments("", "score");
            } else {
              setShowNotify({
                show: true,
                title: "Info",
                msg: "Score added successfully",
              });
            }
          }
        })
        .catch(function (error) {
          getCatchBlockDetails(error);
        })
        .finally(() => {
          setIsLoaded(false);
        });
    } else {
      setShowNotify({
        show: true,
        title: "Info",
        msg: "Invalid marks",
      });
    }
  };

  const changeScore = (e) => {
    if (e.target.value === "") {
      setScoreChanged(false);
    }

    if ((e.target.value + "").length > (approveData.actualscore + "").length) {
      setScoreChanged(false);
    }

    if (isNaN(e.target.value) || e.target.value === "") {
      setScore(0);
      setScoreChanged(false);
    } else {
      setScore(Number(e.target.value));

      if (
        Number(e.target.value) >= 0 &&
        Number(e.target.value) <= approveData.marks
      ) {
        setIsScore(true);

        if (Number(e.target.value) !== approveData.actualscore) {
          setScoreChanged(true);
          setScore(Number(e.target.value));
        } else {
          setScoreChanged(false);
        }
      } else {
        setIsScore(false);
        setScoreChanged(false);
      }
    }
  };

  return { addGrade, changeScore, scoreChanged, handleScoreSelect };
}
