import "./QuestionReport.scss";
import { Pagination } from "antd";
import constants from "../../../constants";
import AlertContext from "../../../context/AlertContext";
import useAxiosPrivate from "../../../Hooks/useAxiosPrivate";
import QuestionReportHooks from "./Hooks/QuestionReportHooks";
import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import Loading from "../../Notification-Loading/Loading/Loading";
import { getDataFromStorage } from "../../../util";
import LibraryUtils from "./LibraryUtils";
import ToggleButton from "../../CommonComponents/ToggleButton";
import { LibraryContext } from "../../../context/LibraryContext";
import Notify from "../../Notify/Notify";

const QuestionReport = (Props) => {
  const {
    isLoaded,
    setIsLoaded,
    setIsFixedQn,
    setTableShowStatus,
    setShowNotify,
  } = useContext(AlertContext);

  const {
    error,
    minIndex,
    maxIndex,
    sortTable,
    setLibType,
    setMinIndex,
    setMaxIndex,
    setTotalPage,
    totalPage,
    filteredData,
    setQueLoading,
    setFilteredData,
    setQuestionsData,
    updateFilterData,
    sortColumn,
    setSortColumn,
  } = QuestionReportHooks();

  const { id } = useParams();
  const axios = useAxiosPrivate();
  const { selectedTopic, searchText, currentPage, setCurrentPage, setTopics } =
    useContext(LibraryContext);

  const pageSize = 10;
  const onChangePagination = (page) => {
    Props.setIsStableState(true);
    setCurrentPage(page);
  };

  const handleToggleChange = async (value, data) => {
    if (data.isFixedqn) {
      setShowNotify({
        show: true,
        title: "Info",
        msg: "This question has already been assigned as a fixed question, so you can't make it inactive.",
      });

      return;
    }

    try {
      setIsLoaded(true);
      const requestData = {
        id: data.id,
        active: value,
        libtype: parseInt(id),
      };
      const response = await axios.post(
        "node/admin/questions/status",
        JSON.stringify(requestData),
        {
          headers: {
            "Content-type": "application/json",
          },
        }
      );
      let dataOfResponse = typeof response.data ==="string" ? JSON.parse(response.data) : response.data;
      setQuestionsData(dataOfResponse);
      updateFilterData(dataOfResponse);
    } catch (error) {
      if (error.message.includes("403")) {
        setShowNotify({
          show: true,
          title: "Warning !",
          msg: "You have been logged-out due to inactivity. Login again.",
          isUnAuth: true,
        });
      } else{
        console.log("error.message",error.message);
        setShowNotify({
          show: true,
          title: "Error !",
          msg: "Something went wrong. Please try again later or contact gradious team.",
        });
      }
    } finally {
      setIsLoaded(false);
    }
  };

  const editQuestions = async (qnid, data) => {
    try {
      setIsLoaded(true);

      // Determine the endpoint based on the `id` value
      const endpoint =
        parseInt(id) === 5
          ? `node/admin/question/${qnid}`
          : `node/admin/content/${qnid}`;

      // Perform the API request
      const response = await axios.get(endpoint, {
        headers: {
          "Content-type": "application/json",
        },
      });

      // Process the response data
      let json = response.data;
      let newData;

      if (parseInt(id) === constants.libTypeIds.CODING_CHALLENGE) {
        newData = JSON.parse(json[0].data);
      } else {
        newData = json.data;
      }

      // Update the data and props
      newData.published = 0;
      setIsFixedQn(data.isFixedqn);
      Props.que(newData);
      Props.setisqueReport(true);
    } catch (error) {
      // Handle errors appropriately
      if (error.message.includes("403")) {
        setShowNotify({
          show: true,
          title: "Error !",
          msg: "You have been logged-out due to inactivity. Login again.",
          isUnAuth: true,
        });
      } else {
        setShowNotify({
          show: true,
          title: "Error !",
          msg: "Something went wrong. Please try again later or contact gradious team.",
        });
      }
      setQueLoading(true);
    } finally {
      setIsLoaded(false);
    }
  };

  const questionentryPagenav = () => {
    Props.que({ published: 0 });
    Props.setisqueReport(false);
  };

  const handleSelectTopic = (event) => {
    Props.setStableStateforBackbtn(event.label);
    Props.setIsStableState(true);
    setSortColumn({});
  };

  const useDebouncedValue = (value, delay) => {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
      const timer = setTimeout(() => {
        setDebouncedValue(value);
      }, 500);

      return () => clearTimeout(timer);
    }, [value, delay]);

    return debouncedValue;
  };
  const debounceDelay = 500;
  const debouncedSearchText = useDebouncedValue(searchText, debounceDelay);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoaded(true);
        const [libTypesResponse, topicsResponse] = await Promise.all([
          axios.get("node/admin/library/gettypes", {
            headers: {
              "Content-type": "application/json",
            },
          }),
          axios.get(`node/admin/topic/get/${id}`, {
            headers: {
              "Content-type": "application/json",
            },
          }),
        ]);

        const libTypesJson = libTypesResponse.data.filter(
          (data) => data.parenttype === 0
        );
        const newLibTypes = libTypesJson.map((data) => ({
          value: data.id,
          label: data.name,
        }));

        setTableShowStatus(parseInt(id));
        setLibType(newLibTypes);

        const topicsJson = topicsResponse.data;
        const newTopics = topicsJson.map((data) => ({
          value: data.id,
          label: data.name,
        }));

        newTopics.unshift({ value: "", label: "All", id: "" });
        setTopics(newTopics);
      } catch (error) {
        if (error.message.includes("403")) {
          setShowNotify({
            show: true,
            title: "Warning !",
            msg: "You have been logged-out due to inactivity. Login again.",
            isUnAuth: true,
          });
        } else
          setShowNotify({
            show: true,
            title: "Error !",
            msg: "Something went wrong. Please try again later or contact gradious team.",
          });
      } finally {
        setIsLoaded(false);
      }
    };

    fetchData();
  }, [
    id,
    setShowNotify,
    axios,
    setIsLoaded,
    setLibType,
    setTableShowStatus,
    setTopics,
  ]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoaded(true);
        const response = await axios.post(
          "node/admin/questions/get",
          JSON.stringify({
            libtype: parseInt(id),
          }),
          {
            params: {
              topicName: selectedTopic.id || "",
              orderBy: sortColumn.order,
              sortColumn: sortColumn.columnName,
              searchText: debouncedSearchText ?? "",
              pageNumber: currentPage,
            },
            headers: {
              "Content-type": "application/json",
            },
          }
        );

        const data =
          typeof response.data.data === "string"
            ? JSON.parse(response.data.data)
            : response.data.data;
        setQuestionsData(data);
        setFilteredData(data);
        setTotalPage(response.data.count);
        setMinIndex(0);
        setMaxIndex(pageSize);
      } catch (error) {
        if (error.message.includes("403")) {
          setShowNotify({
            show: true,
            title: "Warning !",
            msg: "You have been logged-out due to inactivity. Login again.",
            isUnAuth: true,
          });
        } else
          setShowNotify({
            show: true,
            title: "Error !",
            msg: "Something went wrong. Please try again later or contact gradious team.",
          });
      } finally {
        setIsLoaded(false);
      }
    };

    if (debouncedSearchText === "" || debouncedSearchText.length > 2) {
      fetchData();
    }
  }, [
    currentPage,
    debouncedSearchText,
    sortColumn,
    selectedTopic.id,
    id,
    axios,
    setShowNotify,
    setIsLoaded,
    setQuestionsData,
    setFilteredData,
    setTotalPage,
    setMinIndex,
    setMaxIndex,
  ]);

  if (Object.keys(error).length > 0) {
    return <>{<Notify />}</>;
  } else {
    return (
      <main>
        {isLoaded && <Loading />}
        {/* {!isLoaded && ( */}
          <div className="containerFluid">
            <div className="newUi">
              {parseInt(getDataFromStorage("accountType")) ===
              constants.ACCOUNT_TYPE.LMS ? (
                <React.Fragment>
                  <LibraryUtils
                    onSearch={(event) => {
                      Props.setIsStableState(true);
                      Props.setStableTextforBackbtn(event.target.value);
                    }}
                    onTopicSelect={handleSelectTopic}
                    onAdd={questionentryPagenav}
                  />
                </React.Fragment>
              ) : null}
              <table className="questionReportAdmin" id="myTable">
                <thead>
                  <tr>
                    <th>
                      <p>S.NO</p>
                    </th>
                    <th
                      onClick={() => {
                        sortTable("question", parseInt(id));
                      }}
                    >
                      <p>Question</p>
                    </th>
                    <th onClick={() => sortTable("topic", parseInt(id))}>
                      <p>Topic</p>
                    </th>

                    {Props.selectedLibType !== "Content" &&
                      Props.selectedLibType !== "Assignment" && (
                        <th onClick={() => sortTable("level", parseInt(id))}>
                          <p>Level</p>
                        </th>
                      )}
                    <th onClick={() => sortTable("createdby", parseInt(id))}>
                      <p>Createdby</p>
                    </th>

                    <th>
                      <p>Active</p>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {filteredData?.map(
                    (data, index) =>
                      index >= minIndex &&
                      index < maxIndex && (
                        <tr
                          key={index}
                          style={{
                            height: "43px",
                            width: "1316px",
                            cursor: "pointer",
                            boxShadow: "0 1px 0 0 #E4E5E7",
                          }}
                        >
                          <td>{currentPage * 10 - 10 + index + 1} </td>
                          <td
                            className="qnReportQnNameSec"
                            onClick={() => editQuestions(data.id, data)}
                          >
                            {data.name}
                          </td>
                          <td onClick={() => editQuestions(data.id, data)}>
                            {parseInt(id) ===
                              constants.libTypeIds.CODING_CHALLENGE ||
                            parseInt(id) === constants.libTypeIds.DESCRIPTIVE ||
                            parseInt(id) === constants.libTypeIds.MCQ
                              ? data?.topics
                                  ?.map((item) => item.topicname)
                                  .join(",")
                              : data.topicname}{" "}
                          </td>

                          {Props.selectedLibType !== "Content" &&
                            Props.selectedLibType !== "Assignment" && (
                              <td onClick={() => editQuestions(data.id, data)}>
                                {parseInt(id) ===
                                  constants.libTypeIds.CODING_CHALLENGE ||
                                parseInt(id) ===
                                  constants.libTypeIds.DESCRIPTIVE ||
                                parseInt(id) === constants.libTypeIds.MCQ
                                  ? data?.topics
                                      ?.map((item) => item.complexityname)
                                      .join(",")
                                  : data.complexity}
                              </td>
                            )}
                          <td onClick={() => editQuestions(data.id, data)}>
                            {data.createdby}
                          </td>

                          <td>
                            <ToggleButton
                              name="autoevaluate"
                              size="md"
                              pressed={data.active === 1}
                              onChange={(value) =>
                                handleToggleChange(value, data)
                              }
                            />
                          </td>
                        </tr>
                      )
                  )}
                </tbody>
              </table>
            </div>
            <div
              style={{
                width: "100%",
                height: "35px",
                display: "flex",
                marginTop: "2%",
                justifyContent: "center",
              }}
            >
              <Pagination
                pageSize={pageSize}
                current={currentPage}
                total={totalPage}
                onChange={onChangePagination}
                style={{
                  margin: "0",
                  display: "flex",
                  listStyle: "none",
                }}
              />
            </div>
          </div>
        {/* )} */}
      </main>
    );
  }
};

export default QuestionReport;
