import constants from "../../constants";
import { useContext, useState } from "react";
import useAxiosPrivate from "../useAxiosPrivate";
import AlertContext from "../../context/AlertContext";
import useCommonFunctionHooks from "../useCommonFunctionHooks";
import CodingHeaderHooks from "../CodingPage/CodingHeaderHooks";

export default function ScoreLogHooks() {
  const { isAdmin, testData, approveData } = useContext(AlertContext);

  const axios = useAxiosPrivate();
  const { getLearnerId } = CodingHeaderHooks();

  const { getCatchBlockDetails, getHeaderDetailsForAxios } =
    useCommonFunctionHooks();

  const [scoreLogData, setScoreLogData] = useState([]);

  const getScoreData = () => {
    if (testData.testtype === constants.libTypeIds.CODING_CHALLENGE) {
      return {
        qnId: approveData.qnId,
        marks: approveData.marks,
        testId: approveData.testId,
        actualScore: approveData.actualscore,
      };
    }
  };

  const getReviewScorePayLoad = () => {
    if (isAdmin) {
      return {
        tag: "SCORE_LOG",
        userid: getLearnerId(),
        qnid: getScoreData().qnId,
        testid: getScoreData().testId,
      };
    } else {
      return {
        tag: "SCORE_LOG",
        userid: getLearnerId(),
        qnid: getScoreData().qnId,
        testid: approveData.testId,
      };
    }
  };

  const getScoreLogScore = () => {
    let actualScore = getScoreData()?.actualScore
        ? getScoreData()?.actualScore
        : 0,
      marks = getScoreData()?.marks ? getScoreData()?.marks : 0,
      score = actualScore + "/" + marks;
    return score;
  };

  const getScoreLogData = (details) => {
    let date = new Date(details.createdTimeStamp),
      hours = date.getHours(),
      AmOrPm = hours >= 12 ? "PM" : "AM";

    hours = hours % 12 || 12;

    return (
      (date.getDate().toString().length === 1 && "0") +
      date.getDate() +
      "/" +
      (date.getMonth().toString().length === 1 ? 0 : "") +
      (date.getMonth() + 1) +
      "/" +
      date.getFullYear() +
      " - " +
      (hours.toString().length === 1 ? 0 : "") +
      hours +
      ":" +
      (date.getMinutes().toString().length === 1 ? 0 : "") +
      date.getMinutes() +
      ":" +
      (date.getSeconds().toString().length === 1 ? 0 : "") +
      date.getSeconds() +
      " " +
      AmOrPm
    );
  };

  const getScoreLog = () => {
    axios
      .post(
        "node/learner/reviewscore/get",
        JSON.stringify(getReviewScorePayLoad()),
        getHeaderDetailsForAxios()
      )
      .then((res) => {
        if (res.data.resultCode === constants.RESULT_STATUS.SUCCESS) {
          setScoreLogData(res.data.msg);
        }
      })
      .catch(function (error) {
        getCatchBlockDetails(error);
      });
  };

  return {
    getScoreLog,
    getScoreData,
    scoreLogData,
    setScoreLogData,
    getScoreLogData,
    getScoreLogScore,
    getReviewScorePayLoad,
  };
}
