import React from "react";
import Card from "@mui/material/Card";
import constants from "../../constants";
import CardMedia from "@mui/material/CardMedia";
import { getDataFromStorage } from "../../util";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import MockBanner from "../Student/MockTestPage/MockBanner";

export default function Batch(props) {
  return (
    <div>
      <div className="batch-div">
        <div className="batch-div-batches">
          {props.batch.data
            .filter(
              (data) =>
                (getDataFromStorage("role") === constants.Roles.parent &&
                  data.type !== constants.BATCH_TYPE.TESTING) ||
                getDataFromStorage("role") !== constants.Roles.parent
            )
            .map((data) => (
              <div key={data.id} className="card-container">
                <Card
                  className="Batch-card-div"
                  sx={{ width: 310, height: 359 }}
                  onClick={() => {
                    props.navigatehome(data.id);
                    props.handleSetLocalStorage(data);
                  }}
                >
                  <CardMedia
                    alt="Logo"
                    component="img"
                    image={data.thumbnail}
                    style={{ objectFit: "fill" }}
                    className="batch-div-batchimage"
                    sx={{ width: 310, height: 191 }}
                  />
                  <CardContent className="card-condainer-content">
                    <Typography
                      component="div"
                      className="card-condainer-batchname"
                    >
                      {data.name}
                    </Typography>
                    <Typography
                      component="div"
                      className="card-condainer-batch-type"
                    >
                      {data.type !== constants.BATCH_TYPE.TESTING
                        ? "Learning Batch"
                        : "Evaluation Batch"}
                    </Typography>
                    <Typography
                      variant="body2"
                      className="card-condainer-batch-desc"
                    >
                      {data.description}
                    </Typography>
                  </CardContent>
                </Card>
              </div>
            ))}
        </div>
        {parseInt(localStorage.getItem("batchType")) !==
          constants.BATCH_TYPE.TESTING &&
          getDataFromStorage("role") === constants.Roles.learner && (
            <MockBanner />
          )}
        {/* <div className="make-height-div"></div> */}
      </div>
    </div>
  );
}
