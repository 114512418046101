import ReactDom from 'react-dom';
import { getPreSignedUrl } from '../../../util';
import moment from "moment";
export default class InstantVideoTool {
    constructor({ data, config, api }) {
      this.api = api;
      this.config = config;
      this.data = {
        url: data && data.url ? data.url : ''
        
        // caption: data && data.caption ? data.caption : '',
      };

     // console.log("###############constructor##############",JSON.stringify(data));
      
       this.CSS = {
      wrapper: 'video',
    };

    this.state = {
      url : this.data.url,
    }

    this.nodes = {
      holder: null,
    };

    }
  
    static get toolbox() {

      
      return {
        title: 'Video',
        icon: `<svg xmlns="http://www.w3.org/2000/svg" width="11" height="12" viewBox="0 0 11 12" fill="none">
        <path d="M1.88477 11.1637C1.53667 11.1637 1.20283 11.0254 0.956689 10.7793C0.710547 10.5331 0.572266 10.1993 0.572266 9.85118V2.15119C0.572466 1.92429 0.631485 1.70132 0.743561 1.50403C0.855638 1.30675 1.01694 1.14189 1.21174 1.02554C1.40654 0.909188 1.62817 0.845326 1.85501 0.840181C2.08185 0.835037 2.30615 0.888786 2.50602 0.996184L9.68539 4.84619C9.78651 4.90077 9.87589 4.97473 9.94842 5.06385C10.021 5.15297 10.0752 5.25551 10.1081 5.3656C10.141 5.4757 10.1519 5.5912 10.1402 5.70551C10.1285 5.81981 10.0943 5.93069 10.0398 6.03181C9.98519 6.13293 9.91122 6.2223 9.8221 6.29484C9.73298 6.36737 9.63044 6.42164 9.52035 6.45455C9.41025 6.48745 9.29475 6.49835 9.18044 6.48662C9.06614 6.4749 8.95526 6.44077 8.85414 6.38619L2.32227 2.88618V9.12056L6.25977 7.00743C6.36131 6.9464 6.47419 6.90663 6.59156 6.89052C6.70894 6.87441 6.82836 6.88231 6.94259 6.91373C7.05682 6.94515 7.16348 6.99944 7.2561 7.07331C7.34873 7.14718 7.42538 7.23909 7.48143 7.34347C7.53747 7.44785 7.57174 7.56253 7.58214 7.68054C7.59255 7.79856 7.57888 7.91746 7.54197 8.03003C7.50505 8.14261 7.44566 8.24651 7.36739 8.33545C7.28912 8.42439 7.19361 8.49651 7.08664 8.54743L2.50602 11.0062C2.31513 11.1092 2.10168 11.1633 1.88477 11.1637Z" fill="#373739" fill-opacity="0.7"/>
      </svg>`, // Replace with the icon markup for the image tool
      };
    }

    static get isReadOnlySupported() {
      return true;
    }


    

   
    
    
    render() {

      this.wrapper = document.createElement('div');
      this.wrapper.classList.add('instant-video-tool');
  

      console.log(this.data.url)
      if(this.data.url===""){

        
     
      const fileInput = document.createElement('input');
      fileInput.setAttribute('type', 'file');
      fileInput.setAttribute('title', 'click to upload Video');
      fileInput.addEventListener('change', () => this.handleImageSelection(fileInput.files[0]));
      this.wrapper.appendChild(fileInput);
      this.nodes.holder=this.wrapper;
      //let tag = "";
      // if(fileInput.files[0].type.startsWith('image/')){
      //   tag="img";
      // }
      // if(fileInput.files[0].type.startsWith('image/')){
       // ReactDom.render( - ,this.wrapper)
      //}
      
      }
      else{

        var response;
        var  Video = document.createElement('video');

        (async function getUrl(url){

          response = await getPreSignedUrl(url)
          Video.setAttribute('src',response);
          Video.setAttribute('width','100%');
          Video.setAttribute('height','400px');
          Video.setAttribute('controls','auto')
          Video.setAttribute('controlsList','nodownload')
         
        })(this.data.url)
      
        this.wrapper.appendChild(Video);
        this.nodes.holder=this.wrapper;

      

        
      } 
      return this.nodes.holder;
    }

    
    handleImageSelection(file) {
       // || !file.type.startsWith('image/') || !file.type.startsWith('video/')
       console.log("**************")
      if (!file  ) {
        console.error('Invalid file format. Please select an appropriate file.');
        return;
      }
     // else if(file.type.startsWith('image/')){
    //     const reader = new FileReader();
    //     reader.onload = (event) => {
    //     const imageUrl = event.target.result;
    //     this.data.url = imageUrl;
    //     this.api.blocks.update(this.data);
    //     this.displayImage(imageUrl);
    //     this.setState({
    //       url:imageUrl,
    //     })
    //   };

    //   reader.readAsDataURL(file);

      //}

    //   else if (file.type.startsWith('video/')){ moment().valueOf()

        const reader = new FileReader();
        reader.onload=(event) => {
            const videoUrl = event.target.result;
            //this.data.url = videoUrl;
            this.data.file = file;
            this.api.blocks.update(this.data);
            this.displayVideo(videoUrl);
            this.data.url = "library/content/"+file.name;
        }
        reader.readAsDataURL(file);
    //   }
      
    //   else if (file.type.startsWith('application/pdf')){
    //     const reader = new FileReader();
    //     reader.onload=(event) =>{
    //       const pdfUrl = event.target.result;
    //       this.data.url = pdfUrl;
          
    //       this.api.blocks.update(this.data);
    //       this.displayPdf(pdfUrl);
    //     }
    //     reader.readAsDataURL(file);
    //   }
    }

    // displayImage(imageUrl) {
    //   const imageElement = document.createElement('img');
    //   imageElement.setAttribute('src', imageUrl);
    //   imageElement.setAttribute('width','300px')
    //   imageElement.classList.add('uploaded-image');
  
    //   this.wrapper.innerHTML = '';
    //   this.wrapper.appendChild(imageElement);
    //   //ReactDom.render(imageElement,this.wrapper)

    //   //ReactDom.render(imageElement,);

      
    // }


    displayVideo(videoUrl){
        const videoElement = document.createElement('video');
        videoElement.setAttribute('src',videoUrl);
        videoElement.setAttribute('width','80%');
        videoElement.setAttribute('height','400px');
        videoElement.setAttribute('controls','auto')
        videoElement.classList.add('uploaded-video');
  
      this.wrapper.innerHTML = '';
      this.wrapper.appendChild(videoElement);

    }

//     displayPdf(pdfUrl){
//       const pdfElement = document.createElement('iframe');
//       pdfElement.setAttribute('src',pdfUrl);
//       pdfElement.setAttribute('width','300px')
//       pdfElement.classList.add('uploaded-pdf');

//     this.wrapper.innerHTML = '';
//     this.wrapper.appendChild(pdfElement);

//   }
  
    save() {
      return {
        url: this.data.url,
        file : this.data.file
        // caption: this.data.caption,
      };
    }
  }  