import React from "react";

export default function DownloadSvgIcon(Props) {
  return (
    <div>
      {Props.component === "uploadAssignment" ? (
        <svg
          width="56"
          height="56"
          fill="none"
          viewBox="0 0 56 56"
          onClick={Props.onClick}
          xmlns="http://www.w3.org/2000/svg"
          className="downloadSvgIconForUploadAssignment"
        >
          <rect width="56" height="56" rx="12" fill="#F2F4F7" />
          <path
            stroke="#344054"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M37 31V32.2C37 33.8802 37 34.7202 36.673 35.362C36.3854 35.9265 35.9265 36.3854 35.362 36.673C34.7202 37 33.8802 37 32.2 37H23.8C22.1198 37 21.2798 37 20.638 36.673C20.0735 36.3854 19.6146 35.9265 19.327 35.362C19 34.7202 19 33.8802 19 32.2V31M33 24L28 19M28 19L23 24M28 19V31"
          />
        </svg>
      ) : (
        <svg
          width="40"
          height="40"
          fill="none"
          viewBox="0 0 40 40"
          className="downloadSvgIcon"
          onClick={Props.onClick}
          xmlns="http://www.w3.org/2000/svg"
        >
          <g filter="url(#filter0_d_768_28600)">
            <rect x="2" y="1" width="36" height="36" rx="8" fill="white" />
            <rect
              x="2.5"
              y="1.5"
              rx="7.5"
              width="35"
              height="35"
              stroke="#D0D5DD"
            />
            <path
              stroke="#344054"
              stroke-width="1.67"
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M27.5 21.5V24.8333C27.5 25.2754 27.3244 25.6993 27.0118 26.0118C26.6993 26.3244 26.2754 26.5 25.8333 26.5H14.1667C13.7246 26.5 13.3007 26.3244 12.9882 26.0118C12.6756 25.6993 12.5 25.2754 12.5 24.8333V21.5M15.8333 17.3333L20 21.5M20 21.5L24.1667 17.3333M20 21.5V11.5"
            />
          </g>
          <defs>
            <filter
              x="0"
              y="0"
              width="40"
              height="40"
              id="filter0_d_768_28600"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                type="matrix"
                in="SourceAlpha"
                result="hardAlpha"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              />
              <feOffset dy="1" />
              <feGaussianBlur stdDeviation="1" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.05 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_768_28600"
              />
              <feBlend
                mode="normal"
                result="shape"
                in="SourceGraphic"
                in2="effect1_dropShadow_768_28600"
              />
            </filter>
          </defs>
        </svg>
      )}
    </div>
  );
}
