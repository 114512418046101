import React, { useContext } from "react";
import TestSvgIcon from "../../../Svg/TestSvgIcon";
import AlertContext from "../../../context/AlertContext";
import { UserReportContext } from "../../../context/UserReportContext";
import UserTimeSpentReportTableHooks from "../../../Hooks/UserReport/UserTimeSpentReport/UserTimeSpentReportTableHooks";

export default function UserTimeSpentReportTable() {
  const Alert = useContext(AlertContext);
  const UserReportCxt = useContext(UserReportContext);

  const UserTimeSpentReportTableHks = UserTimeSpentReportTableHooks();

  return UserReportCxt.userTimeSpentTableData.map((lp, index) => (
    <div
      key={index}
      className={
        !Alert.isAdmin
          ? "userTimeSpentReportTableMainContainer"
          : "userTimeSpentReportTableMainContainerForAdmin"
      }
    >
      <div className="userTimeSpentReportTableLearningPathName">
        {lp.lpname}
      </div>
      <div className="userTimeSpentReportTableTestHeader">
        <div>Test Name</div>
        <div className="userTimeSpentReportTableTestHeaderLabelForTimeSpent">
          Time Spent
        </div>
      </div>
      {lp.modules.map((module, index) => (
        <div className="userTimeSpentReportTableTestBody">
          <div
            key={index}
            className="userTimeSpentReportTableTestBodyModuleName"
          >
            {module.mname}
          </div>

          {module.test.map((test, index) => (
            <div key={index} className="userTimeSpentReportTableTestBodyData">
              <div className="userTimeSpentReportTableTestBodyDataTestLabelDiv">
                <div className="userTimeSpentReportTableTestBodyDataTestSvgIcon">
                  <TestSvgIcon
                    active={true}
                    disable={false}
                    libType={test.libtype}
                  />
                </div>

                <div className="userTimeSpentReportTableTestBodyDataTestName">
                  {test.name}
                  <div className="userTimeSpentReportTableTestBodyDataTestPublishedDate">
                    {test.publisheddate}
                  </div>
                </div>
              </div>
              <div className="userTimeSpentReportTableTestBodyDataTestTimeSpent">
                {UserTimeSpentReportTableHks.convertTimeSpent(test.timespent)}
              </div>
            </div>
          ))}
        </div>
      ))}
    </div>
  ));
}
