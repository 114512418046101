import "./Modules.scss";
import constant from "../../../../constants";
import CreatableSelect from "react-select/creatable";
import AlertContext from "../../../../context/AlertContext";
import useAxiosPrivate from "../../../../Hooks/useAxiosPrivate";
import React, { useState, useEffect, memo, useContext } from "react";
import CodingQuestionEntryHooks from "../CodingQuestionEntry/Hooks/CodingQuestionEntryHooks";

const customStylesForTopic = {
  control: (base, state) => ({
    ...base,
    marginTop: "20px",
    border: "1px solid #BDC1C6",
    maxHeight: "40px !important",
  }),
  option: (styles, { isSelected }) => {
    return {
      ...styles,
      width: "370px",
      height: "36px",
      fontSize: "14px",
      fontWeight: "400",
      lineHeight: "20px",
      background: "white",
      fontFamily: "Inter",
      color: isSelected ? "#000000" : undefined,
      ":hover": {
        color: "white",
        backgroundColor: "#0d6efd",
      },
    };
  },
  singleValue: (styles, { data }) => ({
    ...styles,
    color: "#202124",
    fontSize: "14px",
    fontWeight: "400",
    textAlign: "left",
    lineHeight: "20px",
    fontStyle: "normal",
    fontFamily: "Inter",
    paddingLeft: "10px",
    borderRadius: "20px",
  }),
  menuList: (base) => ({
    ...base,
    textAlign: "left",
    maxHeight: "150px",
    overflowX: "hidden",
    borderRadius: "2px",
  }),
};

const Modules = (Props) => {
  const axios = useAxiosPrivate();
  const { setShow, setIsUnAuth, setMsg, setTitle } = useContext(AlertContext);

  const { isSelectedTopic, setIsSelectedTopic, setAlreadySelected } =
    CodingQuestionEntryHooks();

  let [topicList, setTopicList] = useState([]);
  let [runningMaxTopicID, setRunningMaxTopicID] = useState();

  const [selectedTopic, setSelectedTopic] = useState();
  const [fromEditQuestion, setFromEditQuestion] = useState(false);

  let currentMax = runningMaxTopicID;

  const createOption = (label) => ({
    label,
    value: getLength(),
  });

  const getLength = () => {
    if (currentMax > 0) {
      return currentMax + 1;
    }

    return runningMaxTopicID + 1;
  };

  const handleCreate = (inputValue) => {
    setIsSelectedTopic(false);
    currentMax = runningMaxTopicID;
    setMaxTopicId(currentMax + 1);

    if (
      inputValue.trim().length === 0 ||
      topicList.filter((option) => option.label === inputValue).length > 0
    ) {
    } else {
      const newOption = createOption(inputValue);

      setTopicList((prev) => [...prev, newOption]);
      setSelectedTopic(newOption);

      if (
        Props.tableShowStatus === constant.libTypeIds.CODING_CHALLENGE ||
        Props.tableShowStatus === constant.libTypeIds.DESCRIPTIVE ||
        Props.tableShowStatus === constant.libTypeIds.MCQ
      ) {
        Props.setTopic({ type: "name", name: newOption.label, value: null });
      }

      Props.setvalue({ name: "topicname", value: newOption.label });
    }
  };

  const setMaxTopicId = (topics) => {
    let maxId = 0;

    for (let i = 0; i < topics.length; i++) {
      if (topics[i].value > maxId) {
        maxId = topics[i].value;
      }
    }

    setRunningMaxTopicID(maxId);
  };

  useEffect(() => {
    if (
      (Props.tableShowStatus === constant.libTypeIds.CODING_CHALLENGE ||
        Props.tableShowStatus === constant.libTypeIds.DESCRIPTIVE ||
        Props.tableShowStatus === constant.libTypeIds.MCQ) &&
      Props.handleadd
    ) {
      setSelectedTopic(null);
      Props.setTopic(null);
    }
  }, [Props.handleadd, Props.tableShowStatus]);

  const handleChange = (newValue) => {
    setIsSelectedTopic(false);

    if (
      Props.tableShowStatus === constant.libTypeIds.CODING_CHALLENGE ||
      Props.tableShowStatus === constant.libTypeIds.DESCRIPTIVE ||
      Props.tableShowStatus === constant.libTypeIds.MCQ
    ) {
      Props.setTopic({
        type: "id",
        name: newValue?.label,
        value: newValue?.value,
      });
    }

    setSelectedTopic(newValue);

    if (newValue !== null && newValue.value > runningMaxTopicID) {
      Props.setvalue({ name: "topicname", value: newValue?.label });
    } else {
      Props.setvalue({ name: "topic", value: newValue?.value });
    }
  };

  if (
    Props.isqueReport &&
    Props.tableShowStatus !== constant.libTypeIds.CODING_CHALLENGE &&
    isSelectedTopic &&
    Props.tableShowStatus !== constant.libTypeIds.DESCRIPTIVE &&
    Props.tableShowStatus !== constant.libTypeIds.MCQ
  ) {
    setAlreadySelected(Props.selectmodule?.topicid);
  }

  useEffect(() => {
    axios
      .get(`node/admin/topic/get/${0}`, {
        headers: {
          "Content-type": "application/json",
        },
      })
      .then((res) => {
        let json = res.data;
        let topics = json.map((top) => {
          return {
            value: top.id,
            label: top.name,
          };
        });

        setTopicList(topics);
        Props.setTopics(topics);
        setSelectedTopic(topics.find((obj) => obj.value === Props.selectedtop));
        setMaxTopicId(topics);
      })
      .catch((error) => {
        if (error.message.includes("403")) {
          setShow(true);
          setIsUnAuth(true);
          setTitle("Error");
          setMsg("You have been logged-out due to inactivity. Login again.");
        }
      });

    setFromEditQuestion(Props.isqueReport);
  }, []);

  return (
    <div className="selectModules">
      <label htmlFor="selectModule">
        Topic
        <span style={{ color: "red", width: "4px" }}> *</span>
      </label>

      <CreatableSelect
        required
        isClearable
        name="topic"
        options={topicList}
        onCreateOption={handleCreate}
        styles={customStylesForTopic}
        components={{ IndicatorSeparator: () => null }}
        onChange={(newValue) => {
          handleChange(newValue);
        }}
        value={
          fromEditQuestion && selectedTopic === undefined
            ? topicList.filter(
                Props.tableShowStatus === constant.libTypeIds.CODING_CHALLENGE
                  ? (option) =>
                      option.value === Props?.selectmodule?.qdata?.topic
                  : (option) => option.value === Props?.selectmodule?.topicid
              )
            : selectedTopic
        }
      />
    </div>
  );
};

export default memo(Modules);
