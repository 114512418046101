import React, { useState } from 'react';
import Box from '@mui/material/Box';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import IconButton from '@mui/material/IconButton';

export default function Pagination(props) {

    // Pagination control
//    const itemsPerPage = 3;
//    const [currentPage, setCurrentPage] = useState(1);
 
//    // Calculate the index range for the current page
//    const startIndex = (currentPage - 1) * itemsPerPage;
//    const endIndex = startIndex + itemsPerPage;
   
//    // Get the current page's events
//    const currentEvents = props.dueList.slice(startIndex, endIndex);
 
//    // Total number of pages
//    const totalPages = Math.ceil(props.dueList.length / itemsPerPage);
 
//    // Handle pagination click
//    const handlePaginationClick = (pageIndex) => {
//      setCurrentPage(pageIndex);
//    };

  return (
    <div>
        {/* <Box className='assignment-due-pagination-box'> */}
            <IconButton disabled={props.currentPage === 1}>
                <ArrowBackIosIcon 
                    className={`assignment-due-pagination-box-arrowback ${props.currentPage === 1 ? ' disabled': ''}`} 
                    onClick={() =>props. handlePaginationClick(props.currentPage - 1)}
                />
            </IconButton>
                {[...Array(props.totalPages)].map((_, index) => (
                    <span className={`assignment-due-pagination-box-dots${props.currentPage === index + 1 ? ' selected' : ''}`} key={index} onClick={() => props.handlePaginationClick(index + 1)}>• </span>
                ))}
            <IconButton disabled={props.currentPage === props.totalPages}>
                <ArrowForwardIosIcon 
                    className={`assignment-due-pagination-box-arrowforward ${props.currentPage === props.totalPages ? ' disabled': ''}`} 
                    onClick={() => props.handlePaginationClick(props.currentPage + 1)} 
                />
            </IconButton>
        {/* </Box> */}
    </div>
  )
}
