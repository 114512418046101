import React from "react";

export default function WarningNoticeBodySvgIcon(Props) {
  return Props.component === "rightMain" ? (
    <svg
      width="236"
      fill="none"
      height="186"
      viewBox="0 0 236 186"
      xmlns="http://www.w3.org/2000/svg"
      className="warningNoticeBodySvgIconForRightMain"
    >
      <g clip-path="url(#clip0_1406_25137)">
        <path
          fill="#F2F2F2"
          d="M144.928 165.228L138.63 171.026L143.636 162.628C139.692 155.476 133.242 149.296 133.242 149.296C133.242 149.296 119.861 162.116 119.861 172.193C119.861 182.27 125.852 185.789 133.242 185.789C140.632 185.789 146.623 182.27 146.623 172.193C146.623 169.95 145.959 167.571 144.928 165.228Z"
        />
        <path
          fill="white"
          d="M137.009 171.34V171.837C136.98 177.611 136.006 182.116 134.096 185.288C134.068 185.335 134.039 185.379 134.012 185.426L133.798 185.295L133.594 185.168C135.714 181.742 136.464 176.9 136.491 171.864C136.493 171.702 136.496 171.537 136.493 171.372C136.486 169.24 136.355 167.084 136.146 164.989C136.132 164.826 136.114 164.662 136.097 164.497C135.807 161.733 135.389 159.092 134.961 156.798C134.932 156.635 134.9 156.473 134.868 156.313C134.128 152.433 133.373 149.622 133.2 149C133.181 148.924 133.168 148.882 133.166 148.872L133.4 148.806L133.402 148.803L133.638 148.737C133.641 148.747 133.682 148.887 133.751 149.148C134.014 150.121 134.698 152.765 135.369 156.252C135.399 156.409 135.431 156.571 135.46 156.734C135.809 158.595 136.151 160.676 136.422 162.859C136.491 163.408 136.552 163.949 136.606 164.482C136.626 164.647 136.643 164.812 136.658 164.974C136.884 167.239 137.001 169.361 137.009 171.34Z"
        />
        <path
          fill="white"
          d="M135.371 156.252C135.206 156.274 135.039 156.296 134.869 156.313C134.408 156.361 133.945 156.384 133.482 156.385C131.508 156.387 129.558 155.947 127.777 155.096C127.674 155.226 127.571 155.357 127.465 155.489C129.339 156.404 131.397 156.879 133.482 156.876C133.977 156.876 134.471 156.85 134.962 156.798C135.13 156.78 135.297 156.758 135.462 156.734C136.822 156.539 138.144 156.138 139.384 155.546C139.278 155.411 139.175 155.278 139.074 155.148C137.901 155.696 136.653 156.068 135.371 156.252Z"
        />
        <path
          fill="white"
          d="M136.607 164.482C136.438 164.492 136.268 164.497 136.098 164.497C136.047 164.499 135.993 164.499 135.941 164.499C133.802 164.499 131.695 163.982 129.799 162.992C127.903 162.001 126.274 160.567 125.052 158.811C124.954 158.957 124.856 159.102 124.76 159.249C126.033 161.026 127.712 162.473 129.656 163.472C131.601 164.47 133.755 164.991 135.941 164.991C136.01 164.991 136.079 164.991 136.148 164.989C136.32 164.986 136.489 164.981 136.659 164.974C139.285 164.839 141.816 163.951 143.95 162.416C143.871 162.271 143.793 162.126 143.712 161.981C141.637 163.488 139.168 164.357 136.607 164.482Z"
        />
        <path
          fill="white"
          d="M137.011 171.34C136.839 171.355 136.666 171.365 136.494 171.372C136.31 171.38 136.125 171.385 135.941 171.385C133.067 171.381 130.271 170.447 127.972 168.722C125.673 166.996 123.995 164.573 123.188 161.814C123.078 162.013 122.967 162.21 122.861 162.409C123.77 165.162 125.523 167.558 127.871 169.257C130.219 170.957 133.042 171.873 135.941 171.876C136.125 171.876 136.31 171.874 136.492 171.864C136.666 171.859 136.839 171.849 137.011 171.837C140.487 171.565 143.731 169.982 146.085 167.408C146.033 167.226 145.974 167.047 145.915 166.865C143.642 169.458 140.448 171.063 137.011 171.34Z"
        />
        <path
          fill="#E4E4E4"
          d="M32.2796 65.7936C50.1072 65.7936 64.5592 51.3415 64.5592 33.514C64.5592 15.6865 50.1072 1.23438 32.2796 1.23438C14.4521 1.23438 0 15.6865 0 33.514C0 51.3415 14.4521 65.7936 32.2796 65.7936Z"
        />
        <path
          fill="#F96343"
          d="M32.2806 60.4137C47.1368 60.4137 59.1802 48.3703 59.1802 33.514C59.1802 18.6577 47.1368 6.61429 32.2806 6.61429C17.4243 6.61429 5.38086 18.6577 5.38086 33.514C5.38086 48.3703 17.4243 60.4137 32.2806 60.4137Z"
        />
        <path
          fill="#E4E4E4"
          d="M118 65.7936C135.828 65.7936 150.28 51.3415 150.28 33.514C150.28 15.6865 135.828 1.23438 118 1.23438C100.173 1.23438 85.7207 15.6865 85.7207 33.514C85.7207 51.3415 100.173 65.7936 118 65.7936Z"
        />
        <path
          fill="white"
          d="M117.999 60.4137C132.856 60.4137 144.899 48.3703 144.899 33.514C144.899 18.6577 132.856 6.61429 117.999 6.61429C103.143 6.61429 91.0996 18.6577 91.0996 33.514C91.0996 48.3703 103.143 60.4137 117.999 60.4137Z"
        />
        <path
          fill="#E4E4E4"
          d="M203.721 65.7936C221.549 65.7936 236.001 51.3415 236.001 33.514C236.001 15.6865 221.549 1.23438 203.721 1.23438C185.893 1.23438 171.441 15.6865 171.441 33.514C171.441 51.3415 185.893 65.7936 203.721 65.7936Z"
        />
        <path
          fill="white"
          d="M203.72 60.4137C218.576 60.4137 230.62 48.3703 230.62 33.514C230.62 18.6577 218.576 6.61429 203.72 6.61429C188.864 6.61429 176.82 18.6577 176.82 33.514C176.82 48.3703 188.864 60.4137 203.72 60.4137Z"
        />
        <path
          fill="white"
          d="M29.9939 43.2416C29.9458 43.2416 29.8977 43.2404 29.8494 43.2381C29.424 43.2176 29.0081 43.1053 28.6301 42.9089C28.2521 42.7125 27.9211 42.4367 27.6598 42.1004L23.5335 36.7952C23.053 36.176 22.8378 35.3915 22.935 34.6138C23.0321 33.8362 23.4338 33.1288 24.0519 32.6469L24.2003 32.5313C24.8196 32.0509 25.6041 31.8357 26.3818 31.9328C27.1595 32.03 27.8669 32.4317 28.3489 33.0497C28.5814 33.3488 28.8759 33.594 29.212 33.7687C29.5481 33.9433 29.918 34.0433 30.2964 34.0618C30.6748 34.0802 31.0526 34.0167 31.4042 33.8755C31.7557 33.7344 32.0726 33.519 32.3331 33.244L40.7116 24.3996C40.9786 24.1178 41.2985 23.8913 41.653 23.7331C42.0075 23.5749 42.3898 23.4881 42.7778 23.4776C43.1659 23.4671 43.5523 23.5332 43.9148 23.672C44.2774 23.8109 44.609 24.0198 44.8908 24.2868L45.0276 24.4164C45.5967 24.9556 45.9283 25.6988 45.9495 26.4825C45.9706 27.2662 45.6796 28.0262 45.1404 28.5953L32.1392 42.3184C31.8632 42.6101 31.5306 42.8424 31.1617 43.0011C30.7928 43.1599 30.3955 43.2417 29.9939 43.2416Z"
        />
        <path
          fill="#2F2E41"
          d="M103.832 26.9779H82.5859V8.50007C82.6078 7.35596 83.0824 6.2672 83.9057 5.47246C84.7291 4.67773 85.8339 4.24187 86.9781 4.26043H94.0405C99.4398 4.26043 103.832 8.50053 103.832 13.7123V26.9779Z"
        />
        <path
          fill="#FFB6B6"
          d="M111.698 181.599L107.665 181.599L105.746 166.042L111.699 166.042L111.698 181.599Z"
        />
        <path
          fill="#2F2E41"
          d="M112.728 185.509L99.7227 185.509V185.344C99.7227 184.002 100.256 182.714 101.205 181.765C102.155 180.815 103.442 180.282 104.785 180.282L112.728 180.282L112.728 185.509Z"
        />
        <path
          fill="#FFB6B6"
          d="M74.5226 181.599L70.489 181.599L68.5703 166.042L74.5233 166.042L74.5226 181.599Z"
        />
        <path
          fill="#2F2E41"
          d="M75.5504 185.509L62.5449 185.509V185.344C62.545 184.002 63.0784 182.714 64.0277 181.765C64.977 180.815 66.2645 180.282 67.607 180.282L75.5507 180.282L75.5504 185.509Z"
        />
        <path
          fill="#FFB6B6"
          d="M79.3917 56.6138L76.7598 73.3927L101.763 73.7215L98.4734 57.601L79.3917 56.6138Z"
        />
        <path
          fill="#2F2E41"
          d="M77.4857 69.2958L69.5221 90.5005L68.5352 178.376H75.2796L91.4004 107.773L106.205 176.533H112.785L108.179 99.2189C108.179 99.2189 109.537 83.701 102.586 74.5442L100.894 69.6559L77.4857 69.2958Z"
        />
        <path
          fill="#FFB6B6"
          d="M111.645 85.8259C110.949 85.6362 110.303 85.2961 109.752 84.8297C109.202 84.3632 108.76 83.7817 108.459 83.1262C108.157 82.4706 108.003 81.7569 108.007 81.0353C108.011 80.3138 108.174 79.6019 108.483 78.9499L97.8457 44.2708L105.67 42.7199L115.398 76.7126C116.459 77.3044 117.266 78.2648 117.666 79.4119C118.067 80.5589 118.032 81.8129 117.569 82.9363C117.107 84.0596 116.248 84.9742 115.156 85.5068C114.064 86.0394 112.815 86.1529 111.645 85.8259Z"
        />
        <path
          fill="#E4E4E4"
          d="M97.5672 65.8801L80.3315 65.5474C79.8389 65.5369 79.3633 65.3653 78.9775 65.0587C78.5918 64.7521 78.3172 64.3275 78.1957 63.85C77.2717 60.1917 75.2389 50.6577 76.3172 41.1619C76.53 39.3223 77.1096 37.5441 78.0217 35.9325C78.9338 34.3209 80.1598 32.9086 81.6273 31.7792C83.0948 30.6497 84.774 29.8261 86.5654 29.357C88.3568 28.8879 90.2241 28.7828 92.0569 29.0481C93.9902 29.3248 95.8431 30.0073 97.4942 31.0506C99.1452 32.0938 100.557 33.4744 101.637 35.1017C102.717 36.729 103.441 38.5663 103.76 40.4929C104.08 42.4196 103.989 44.3921 103.493 46.2811C101.324 54.5202 100.098 60.4145 99.8509 63.8008C99.8143 64.2904 99.6184 64.7545 99.2932 65.1223C98.968 65.4902 98.5314 65.7414 98.0499 65.8378C97.9068 65.8662 97.7611 65.8806 97.6152 65.8807C97.5991 65.8807 97.5832 65.8805 97.5672 65.8801Z"
        />
        <path
          fill="#FFB6B6"
          d="M45.2127 35.4186C45.3162 35.6508 45.4013 35.8908 45.4671 36.1364L67.0413 43.4675L71.21 39.8256L77.5285 45.3987L70.7751 52.563C70.2295 53.1418 69.5109 53.528 68.7272 53.6636C67.9434 53.7992 67.1368 53.677 66.4285 53.3151L43.3427 41.523C42.5079 42.0351 41.5369 42.2809 40.559 42.2277C39.581 42.1745 38.6425 41.8248 37.8681 41.2252C37.0937 40.6255 36.5203 39.8043 36.224 38.8708C35.9277 37.9373 35.9227 36.9357 36.2096 35.9993C36.4964 35.0628 37.0616 34.2359 37.8299 33.6285C38.5982 33.0211 39.5332 32.662 40.5105 32.599C41.4879 32.5359 42.4613 32.7719 43.3012 33.2756C44.1412 33.7793 44.8079 34.5267 45.2127 35.4186Z"
        />
        <path
          fill="#E4E4E4"
          d="M67.086 42.2983C67.0877 42.0012 67.1534 41.708 67.2788 41.4386C67.4041 41.1692 67.5861 40.9301 67.8123 40.7374L75.7119 34.0085C76.7115 32.8629 78.1247 32.1606 79.6415 32.0555C81.1582 31.9505 82.6548 32.4513 83.8028 33.4481C84.9509 34.4449 85.6567 35.8564 85.7655 37.3729C85.8743 38.8894 85.3772 40.3872 84.3832 41.5377L78.7578 50.2975C78.5972 50.5475 78.3847 50.76 78.1346 50.9204C77.8845 51.0808 77.6028 51.1855 77.3087 51.2272C77.0145 51.269 76.7148 51.2468 76.43 51.1623C76.1451 51.0778 75.8819 50.9329 75.6581 50.7374L67.7934 43.8682C67.5695 43.6728 67.3904 43.4314 67.2683 43.1605C67.1462 42.8896 67.084 42.5955 67.086 42.2983Z"
        />
        <path
          fill="#E4E4E4"
          d="M97.3689 53.0552C97.1059 52.9169 96.8759 52.7235 96.6945 52.4882C96.5131 52.2529 96.3846 51.9812 96.3178 51.6917L93.9822 41.581C93.4259 40.1662 93.4534 38.5885 94.0586 37.1939C94.6638 35.7994 95.7974 34.7017 97.2107 34.1417C98.6241 33.5817 100.202 33.605 101.598 34.2065C102.994 34.808 104.095 35.9387 104.659 37.3506L109.844 46.3778C109.992 46.6354 110.083 46.9219 110.11 47.2178C110.137 47.5136 110.1 47.8119 110.002 48.0922C109.904 48.3726 109.746 48.6284 109.54 48.8423C109.334 49.0562 109.084 49.2232 108.807 49.3319L99.0881 53.1501C98.8116 53.2589 98.5148 53.3067 98.2181 53.2903C97.9214 53.2739 97.6317 53.1937 97.3689 53.0552Z"
        />
        <path
          fill="#2F2E41"
          d="M105.729 6.06601C106.527 4.19777 105.155 1.82069 102.664 0.756637C100.173 -0.30742 97.5067 0.344483 96.7087 2.21272C95.9106 4.08095 97.283 6.45805 99.774 7.5221C102.265 8.58616 104.931 7.93424 105.729 6.06601Z"
        />
        <path
          fill="#FFB6B6"
          d="M98.0036 19.9528C100.668 15.078 98.8768 8.96599 94.002 6.3012C89.1273 3.6364 83.0152 5.42795 80.3504 10.3027C77.6856 15.1775 79.4772 21.2895 84.352 23.9543C89.2267 26.6191 95.3388 24.8276 98.0036 19.9528Z"
        />
        <path
          fill="#2F2E41"
          d="M94.6935 3.60669H83.1875C82.3439 3.59578 81.5274 3.90418 80.9018 4.47004C80.2761 5.03591 79.8875 5.8174 79.8139 6.65779C79.7841 7.10439 79.8464 7.55234 79.9968 7.97388C80.1473 8.39543 80.3827 8.78159 80.6885 9.10844C80.9943 9.43528 81.3639 9.69585 81.7745 9.874C82.1851 10.0521 82.6279 10.1441 83.0755 10.1441C83.9424 10.1441 84.7738 10.4885 85.3868 11.1015C85.9998 11.7145 86.3442 12.5459 86.3442 13.4128V26.8144H104.322V13.2352C104.322 11.9708 104.073 10.7187 103.589 9.55052C103.105 8.38234 102.396 7.3209 101.502 6.42681C100.608 5.53273 99.5464 4.82349 98.3782 4.33962C97.21 3.85574 95.958 3.60669 94.6935 3.60669Z"
        />
        <path
          fill="#CACACA"
          d="M151.413 186H26.8757C26.789 186 26.7059 185.965 26.6446 185.904C26.5833 185.843 26.5488 185.76 26.5488 185.673C26.5488 185.586 26.5833 185.503 26.6446 185.442C26.7059 185.381 26.789 185.346 26.8757 185.346H151.413C151.5 185.346 151.583 185.381 151.644 185.442C151.705 185.503 151.74 185.586 151.74 185.673C151.74 185.76 151.705 185.843 151.644 185.904C151.583 185.965 151.5 186 151.413 186Z"
        />
      </g>
      <defs>
        <clipPath id="clip0_1406_25137">
          <rect
            width="236"
            fill="white"
            height="185.754"
            transform="translate(0 0.245789)"
          />
        </clipPath>
      </defs>
    </svg>
  ) : (
    <svg
      width="145"
      fill="none"
      height="145"
      viewBox="0 0 145 145"
      xmlns="http://www.w3.org/2000/svg"
      className="warningNoticeBodySvgIcon"
    >
      <path d="M145 110.895H0V110.967H145V110.895Z" fill="#EBEBEB" />
      <path
        fill="#EBEBEB"
        d="M130.471 115.562H120.866V115.635H130.471V115.562Z"
      />
      <path
        fill="#EBEBEB"
        d="M96.0538 116.352H93.5337V116.424H96.0538V116.352Z"
      />
      <path
        fill="#EBEBEB"
        d="M120.576 112.871H115.011V112.944H120.576V112.871Z"
      />
      <path
        fill="#EBEBEB"
        d="M27.7385 113.355H15.2134V113.428H27.7385V113.355Z"
      />
      <path
        fill="#EBEBEB"
        d="M32.158 113.355H30.3223V113.428H32.158V113.355Z"
      />
      <path
        fill="#EBEBEB"
        d="M65.2937 114.582H38.1265V114.655H65.2937V114.582Z"
      />
      <path
        fill="#EBEBEB"
        d="M68.7302 97.9612H12.7341C12.2954 97.9604 11.8749 97.7857 11.565 97.4752C11.2551 97.1647 11.0811 96.744 11.0811 96.3053V17.5906C11.0849 17.1545 11.2606 16.7374 11.5701 16.4301C11.8796 16.1228 12.2979 15.95 12.7341 15.9492H68.7302C69.1693 15.9492 69.5905 16.1237 69.9011 16.4342C70.2116 16.7448 70.3861 17.1659 70.3861 17.6051V96.3053C70.3861 96.7445 70.2116 97.1657 69.9011 97.4762C69.5905 97.7868 69.1693 97.9612 68.7302 97.9612ZM12.7341 16.0072C12.3146 16.008 11.9126 16.1751 11.6163 16.472C11.32 16.7689 11.1536 17.1712 11.1536 17.5906V96.3053C11.1536 96.7248 11.32 97.1271 11.6163 97.4239C11.9126 97.7208 12.3146 97.888 12.7341 97.8887H68.7302C69.1499 97.888 69.5522 97.7209 69.8489 97.4241C70.1457 97.1273 70.3128 96.725 70.3136 96.3053V17.5906C70.3128 17.1709 70.1457 16.7686 69.8489 16.4718C69.5522 16.1751 69.1499 16.008 68.7302 16.0072H12.7341Z"
      />
      <path
        fill="#EBEBEB"
        d="M131.46 97.9612H75.4611C75.0221 97.9605 74.6014 97.7858 74.291 97.4754C73.9807 97.165 73.8059 96.7443 73.8052 96.3053V17.5906C73.8098 17.1542 73.9861 16.7372 74.2961 16.4299C74.6061 16.1227 75.0246 15.95 75.4611 15.9492H131.46C131.896 15.9507 132.313 16.1239 132.622 16.4311C132.931 16.7384 133.106 17.155 133.11 17.5906V96.3053C133.11 96.7435 132.937 97.1638 132.627 97.4742C132.318 97.7845 131.898 97.9597 131.46 97.9612ZM75.4611 16.0072C75.0414 16.008 74.6391 16.1751 74.3423 16.4718C74.0455 16.7686 73.8784 17.1709 73.8777 17.5906V96.3053C73.8784 96.725 74.0455 97.1273 74.3423 97.4241C74.6391 97.7209 75.0414 97.888 75.4611 97.8887H131.46C131.88 97.888 132.282 97.7209 132.579 97.4241C132.876 97.1273 133.043 96.725 133.043 96.3053V17.5906C133.043 17.1709 132.876 16.7686 132.579 16.4718C132.282 16.1751 131.88 16.008 131.46 16.0072H75.4611Z"
      />
      <path
        fill="#E6E6E6"
        d="M113.993 22.0859H29.374V61.6767H113.993V22.0859Z"
      />
      <path
        fill="#F0F0F0"
        d="M115.266 22.0859H29.9688V61.6767H115.266V22.0859Z"
      />
      <path
        fill="#E6E6E6"
        d="M113.993 61.6758H29.374V63.6942H113.993V61.6758Z"
      />
      <path
        fill="#F0F0F0"
        d="M117.389 61.6758H32.0913V63.6942H117.389V61.6758Z"
      />
      <path
        fill="#FAFAFA"
        d="M113.483 59.892V23.8711L31.7551 23.8711V59.892L113.483 59.892Z"
      />
      <path
        fill="white"
        d="M40.5215 59.892L45.4892 23.8711H53.2409L48.2732 59.892H40.5215Z"
      />
      <path
        fill="#F0F0F0"
        d="M32.5788 58.117C32.5449 58.117 32.5125 58.1036 32.4885 58.0797C32.4646 58.0557 32.4512 58.0233 32.4512 57.9894V25.0339C32.4512 25 32.4646 24.9676 32.4885 24.9436C32.5125 24.9197 32.5449 24.9063 32.5788 24.9063C32.5955 24.9062 32.6121 24.9096 32.6276 24.916C32.6431 24.9224 32.6572 24.9318 32.669 24.9436C32.6808 24.9555 32.6902 24.9695 32.6967 24.985C32.7031 25.0005 32.7064 25.0171 32.7064 25.0339V58.001C32.7041 58.0331 32.6896 58.063 32.6659 58.0847C32.6421 58.1063 32.6109 58.1179 32.5788 58.117Z"
      />
      <path
        fill="white"
        d="M49.938 59.892L54.9057 23.8711H57.9275L52.9598 59.892H49.938Z"
      />
      <path
        fill="#E6E6E6"
        d="M113.483 59.892V23.8711H113.257V59.892H113.483Z"
      />
      <path
        opacity="0.6"
        fill="#EBEBEB"
        d="M115.687 26.5645H31.0706L30.9053 24.5664H115.524L115.687 26.5645Z"
      />
      <path
        opacity="0.6"
        fill="#EBEBEB"
        d="M115.687 29.8379H31.0706L30.9053 27.8398H115.524L115.687 29.8379Z"
      />
      <path
        opacity="0.6"
        fill="#EBEBEB"
        d="M115.687 33.1153H31.0706L30.9053 31.1172H115.524L115.687 33.1153Z"
      />
      <path
        opacity="0.6"
        fill="#EBEBEB"
        d="M115.687 36.3887H31.0706L30.9053 34.3906H115.524L115.687 36.3887Z"
      />
      <path
        opacity="0.6"
        fill="#EBEBEB"
        d="M115.687 39.6661H31.0706L30.9053 37.668H115.524L115.687 39.6661Z"
      />
      <path
        opacity="0.6"
        fill="#EBEBEB"
        d="M115.687 42.9395H31.0706L30.9053 40.9414H115.524L115.687 42.9395Z"
      />
      <path
        fill="#E6E6E6"
        d="M127.31 71.6289H114.396V110.895H127.31V71.6289Z"
      />
      <path
        fill="#FAFAFA"
        d="M123.862 110.897H127.31V105.477H120.246L123.862 110.897Z"
      />
      <path
        fill="#E6E6E6"
        d="M92.1012 71.6289H79.1875V110.895H92.1012V71.6289Z"
      />
      <path d="M127.31 71.6289H89.0908V109.03H127.31V71.6289Z" fill="#FAFAFA" />
      <path
        fill="#FAFAFA"
        d="M92.5389 110.897H89.0908V105.477H96.1552L92.5389 110.897Z"
      />
      <path
        fill="#F0F0F0"
        d="M124.633 85.5664H91.7646V94.9247H124.633V85.5664Z"
      />
      <path
        fill="#F0F0F0"
        d="M124.633 96.9258H91.7646V106.284H124.633V96.9258Z"
      />
      <path
        fill="#FAFAFA"
        d="M96.9297 84.7539H119.48V85.2092C119.48 85.5684 119.337 85.9129 119.083 86.1668C118.829 86.4208 118.485 86.5635 118.126 86.5635H98.2869C97.9277 86.5635 97.5832 86.4208 97.3293 86.1668C97.0753 85.9129 96.9326 85.5684 96.9326 85.2092V84.7539H96.9297Z"
      />
      <path
        fill="#F0F0F0"
        d="M124.633 74.2031H91.7646V83.5614H124.633V74.2031Z"
      />
      <path
        fill="#FAFAFA"
        d="M96.9297 73.3945H119.48V73.8498C119.48 74.209 119.337 74.5535 119.083 74.8075C118.829 75.0614 118.485 75.2041 118.126 75.2041H98.2869C97.9277 75.2041 97.5832 75.0614 97.3293 74.8075C97.0753 74.5535 96.9326 74.209 96.9326 73.8498V73.3945H96.9297Z"
      />
      <path
        fill="#FAFAFA"
        d="M96.9297 96.1133H119.48V96.5686C119.48 96.9278 119.337 97.2722 119.083 97.5262C118.829 97.7802 118.485 97.9229 118.126 97.9229H98.2869C97.9277 97.9229 97.5832 97.7802 97.3293 97.5262C97.0753 97.2722 96.9326 96.9278 96.9326 96.5686V96.1133H96.9297Z"
      />
      <path
        fill="#E6E6E6"
        d="M15.4917 80.0023C16.2109 77.9346 21.8717 80.7447 24.1917 84.1667C25.7258 86.4461 26.6625 88.8792 27.0917 92.1475C27.0395 88.4181 26.9815 84.4132 26.9235 80.6316C26.4566 79.6891 25.3923 77.5141 24.6296 75.7915C22.0776 70.0437 16.0108 67.9035 13.8996 64.2756C11.1533 59.5196 11.1707 52.3856 13.5081 49.8162C15.5381 47.5774 22.2661 62.7009 23.0259 64.3916C25.6359 70.1916 26.3899 74.5155 26.8771 78.6944C26.822 75.1419 26.7698 71.8823 26.7263 69.3651C26.5842 61.1581 26.4044 58.5191 26.3232 57.7071C26.0332 57.3098 25.7113 56.8371 25.517 56.5993C23.8901 54.5026 20.2767 52.0086 18.4729 48.9607C15.8368 44.5121 16.2631 39.1877 18.1104 37.2041C20.0592 35.1306 23.4899 40.02 25.2908 44.8862C27.0917 49.7524 26.329 54.3866 26.8394 58.4205C26.9467 59.2093 27.054 62.2137 27.1584 66.381C27.7094 61.8976 28.8056 58.8932 30.8356 56.0947C33.3354 52.6524 35.493 48.4619 37.552 49.0042C39.611 49.5465 38.5032 55.0942 36.8734 58.986C35.4234 62.4515 28.2662 65.9112 27.2106 68.6981C27.3353 74.3473 27.4455 81.5016 27.5267 88.1629C28.0893 84.7206 27.7007 84.4335 30.0149 79.1729C31.3895 76.0409 37.0242 72.181 37.697 74.3328C38.3698 76.4846 37.3461 80.5504 35.2204 83.3982C33.5036 85.6979 29.7104 85.695 28.8143 87.1189C28.6084 87.4524 27.9443 88.7284 27.5412 89.4592C27.6311 97.5937 27.6746 104.771 27.6282 107.219L27.257 107.245C27.2773 106.613 27.2309 102.474 27.1584 96.9992C26.2333 94.0992 20.0099 92.3882 18.0872 89.3577C16.0746 86.1793 14.6275 82.4789 15.4917 80.0023Z"
      />
      <path
        fill="#F0F0F0"
        d="M21.3441 110.894H33.2921C33.6087 110.894 33.9154 110.784 34.1606 110.584C34.4058 110.384 34.5743 110.105 34.6377 109.795L35.4845 105.613H19.1401L20.0101 109.795C20.0732 110.103 20.24 110.381 20.4828 110.581C20.7255 110.781 21.0296 110.891 21.3441 110.894Z"
      />
      <path
        fill="#F0F0F0"
        d="M18.8094 107.234H35.8266C36.024 107.234 36.2143 107.161 36.3614 107.03C36.5085 106.898 36.602 106.717 36.6241 106.521L36.9721 103.429C36.9843 103.317 36.9728 103.204 36.9383 103.097C36.9038 102.989 36.8471 102.89 36.7719 102.806C36.6967 102.722 36.6046 102.655 36.5017 102.609C36.3988 102.563 36.2873 102.539 36.1746 102.539H18.4614C18.3487 102.539 18.2372 102.563 18.1343 102.609C18.0314 102.655 17.9394 102.722 17.8641 102.806C17.7889 102.89 17.7322 102.989 17.6977 103.097C17.6632 103.204 17.6517 103.317 17.6639 103.429L18.0119 106.521C18.034 106.717 18.1275 106.898 18.2746 107.03C18.4217 107.161 18.6121 107.234 18.8094 107.234Z"
      />
      <path
        fill="#E0E0E0"
        d="M51.5737 92.1094H41.3628V110.896H51.5737V92.1094Z"
      />
      <path
        fill="#E6E6E6"
        d="M77.5981 110.893H43.41L39.8169 85.4023H74.005L77.5981 110.893Z"
      />
      <path
        fill="#F0F0F0"
        d="M82.5863 110.893H44.7674L41.1772 85.4023H78.9932L82.5863 110.893Z"
      />
      <path
        fill="white"
        d="M48.4676 106.912L45.4429 89.3906H75.2926L78.3173 106.912H48.4676Z"
      />
      <path
        fill="#FAFAFA"
        d="M61.2946 106.912L51.1504 89.3906H54.3317L64.4788 106.912H61.2946Z"
      />
      <path
        fill="#FAFAFA"
        d="M65.8273 106.912L55.6802 89.3906H61.109L71.2532 106.912H65.8273Z"
      />
      <path
        fill="#F5F5F5"
        d="M72.5001 123.991C103.554 123.991 128.728 122.522 128.728 120.709C128.728 118.896 103.554 117.426 72.5001 117.426C41.4461 117.426 16.272 118.896 16.272 120.709C16.272 122.522 41.4461 123.991 72.5001 123.991Z"
      />
      <path
        fill="#F55533"
        d="M22.4083 64.2458C22.3772 64.2459 22.3471 64.2344 22.324 64.2134C22.3009 64.1925 22.2865 64.1637 22.2836 64.1327L21.6398 56.3897C21.6382 56.3583 21.6487 56.3275 21.6693 56.3037C21.6898 56.2799 21.7187 56.265 21.75 56.2621C21.7826 56.2596 21.8149 56.2701 21.8399 56.2913C21.8648 56.3125 21.8805 56.3426 21.8834 56.3752L22.5272 64.1182C22.5296 64.1508 22.5191 64.1831 22.498 64.208C22.4768 64.233 22.4467 64.2486 22.4141 64.2516L22.4083 64.2458Z"
      />
      <path
        fill="#F55533"
        d="M22.7913 68.8442C22.7601 68.8443 22.73 68.8328 22.7069 68.8119C22.6839 68.7909 22.6695 68.7621 22.6666 68.7311L22.4723 66.3879C22.4707 66.3716 22.4724 66.3553 22.4772 66.3397C22.4821 66.3241 22.49 66.3097 22.5006 66.2972C22.5111 66.2848 22.5241 66.2746 22.5386 66.2673C22.5532 66.2599 22.5691 66.2556 22.5854 66.2545C22.6016 66.2529 22.618 66.2546 22.6336 66.2594C22.6491 66.2643 22.6636 66.2723 22.676 66.2828C22.6884 66.2934 22.6986 66.3063 22.706 66.3209C22.7133 66.3354 22.7177 66.3513 22.7188 66.3676L22.9131 68.7108C22.9155 68.7434 22.905 68.7757 22.8838 68.8006C22.8627 68.8256 22.8325 68.8412 22.8 68.8442H22.7913Z"
      />
      <path
        fill="white"
        d="M88.0324 90.877H28.1532C27.3988 90.8622 26.676 90.5713 26.1218 90.0592C25.5675 89.5472 25.2203 88.8497 25.1459 88.0988L21.7732 47.5597C21.7372 47.2056 21.7765 46.8479 21.8885 46.5101C22.0005 46.1723 22.1828 45.862 22.4233 45.5996C22.6637 45.3372 22.957 45.1286 23.2838 44.9877C23.6106 44.8467 23.9635 44.7764 24.3194 44.7815H84.1957C84.9505 44.7962 85.6736 45.0871 86.2283 45.599C86.7831 46.111 87.1309 46.8086 87.2059 47.5597L90.5786 88.093C90.6151 88.4475 90.5761 88.8056 90.4643 89.1439C90.3525 89.4823 90.1703 89.7931 89.9299 90.056C89.6894 90.319 89.396 90.5281 89.069 90.6695C88.742 90.811 88.3887 90.8817 88.0324 90.877Z"
      />
      <path
        fill="#F55533"
        d="M88.0315 90.9935H28.1523C27.3684 90.9807 26.6167 90.6798 26.0406 90.1481C25.4645 89.6164 25.1044 88.8912 25.029 88.1109L21.6563 47.5747C21.6222 47.2049 21.6657 46.832 21.7841 46.4799C21.9024 46.1278 22.093 45.8044 22.3436 45.5302C22.5929 45.2542 22.8977 45.034 23.238 44.8841C23.5784 44.7342 23.9466 44.6579 24.3185 44.6602H84.1948C84.9818 44.6727 85.7365 44.9758 86.3136 45.5111C86.8907 46.0465 87.2495 46.7763 87.321 47.5602L90.6908 88.0935C90.728 88.4632 90.6867 88.8365 90.5695 89.1891C90.4523 89.5416 90.2619 89.8655 90.0108 90.1393C89.7597 90.4131 89.4536 90.6308 89.1125 90.778C88.7713 90.9252 88.403 90.9986 88.0315 90.9935ZM24.3185 44.9038C23.9781 44.8983 23.6404 44.965 23.3276 45.0996C23.0149 45.2343 22.7343 45.4337 22.5044 45.6847C22.2744 45.9358 22.1004 46.2328 21.9937 46.556C21.887 46.8793 21.85 47.2216 21.8854 47.5602L25.2581 88.0906C25.3298 88.814 25.6649 89.4857 26.1996 89.9782C26.7342 90.4706 27.4313 90.7494 28.1581 90.7615H88.0315C88.3711 90.7665 88.7079 90.6996 89.0198 90.5652C89.3317 90.4307 89.6117 90.2318 89.8412 89.9814C90.0708 89.7311 90.2447 89.4349 90.3516 89.1126C90.4586 88.7902 90.4961 88.4488 90.4617 88.1109L87.089 47.5747C87.0178 46.8511 86.683 46.179 86.1481 45.6864C85.6133 45.1938 84.916 44.9153 84.189 44.9038H24.3185Z"
      />
      <path
        fill="#F55533"
        d="M84.1956 44.7893H24.3193C23.964 44.7843 23.6116 44.8544 23.2852 44.9951C22.9589 45.1357 22.6659 45.3437 22.4255 45.6054C22.1851 45.8672 22.0028 46.1768 21.8903 46.5139C21.7779 46.851 21.738 47.2081 21.7731 47.5617H87.2058C87.1294 46.8116 86.781 46.1155 86.2264 45.6047C85.6719 45.0939 84.9494 44.8039 84.1956 44.7893Z"
      />
      <path
        fill="white"
        d="M25.72 46.1785C25.7283 46.2549 25.7201 46.3322 25.6962 46.4052C25.6723 46.4783 25.6332 46.5454 25.5813 46.6022C25.5295 46.6589 25.4662 46.704 25.3956 46.7345C25.325 46.7649 25.2488 46.78 25.1719 46.7788C25.009 46.7753 24.853 46.7124 24.7333 46.6018C24.6136 46.4912 24.5386 46.3406 24.5223 46.1785C24.5146 46.1021 24.523 46.025 24.5471 45.9522C24.5712 45.8793 24.6104 45.8124 24.6621 45.7557C24.7138 45.6991 24.777 45.6539 24.8473 45.6234C24.9177 45.5928 24.9937 45.5774 25.0704 45.5782C25.2337 45.5803 25.3904 45.6429 25.5104 45.7537C25.6303 45.8645 25.705 46.0158 25.72 46.1785Z"
      />
      <path
        fill="white"
        d="M28.1562 46.1785C28.1644 46.2549 28.1563 46.3322 28.1324 46.4052C28.1085 46.4783 28.0693 46.5454 28.0175 46.6022C27.9657 46.6589 27.9023 46.704 27.8318 46.7345C27.7612 46.7649 27.6849 46.78 27.6081 46.7788C27.4472 46.7725 27.294 46.7084 27.1767 46.598C27.0595 46.4877 26.9861 46.3387 26.9701 46.1785C26.9623 46.1021 26.9708 46.025 26.9948 45.9522C27.0189 45.8793 27.0581 45.8124 27.1099 45.7557C27.1616 45.6991 27.2247 45.6539 27.2951 45.6234C27.3654 45.5928 27.4415 45.5774 27.5182 45.5782C27.6795 45.5831 27.8333 45.6469 27.9509 45.7575C28.0684 45.8681 28.1414 46.0178 28.1562 46.1785Z"
      />
      <path
        fill="white"
        d="M30.5921 46.1785C30.6003 46.2549 30.5922 46.3322 30.5683 46.4052C30.5444 46.4783 30.5052 46.5454 30.4534 46.6022C30.4016 46.6589 30.3383 46.704 30.2677 46.7345C30.1971 46.7649 30.1209 46.78 30.044 46.7788C29.8811 46.7753 29.7251 46.7124 29.6054 46.6018C29.4857 46.4912 29.4107 46.3406 29.3944 46.1785C29.3866 46.1021 29.3951 46.025 29.4192 45.9522C29.4433 45.8793 29.4824 45.8124 29.5342 45.7557C29.5859 45.6991 29.649 45.6539 29.7194 45.6234C29.7897 45.5928 29.8658 45.5774 29.9425 45.5782C30.1058 45.5803 30.2625 45.6429 30.3824 45.7537C30.5024 45.8645 30.5771 46.0158 30.5921 46.1785Z"
      />
      <path
        fill="#F0F0F0"
        d="M82.8355 85.5662H32.4799C31.8801 85.555 31.3052 85.3241 30.8643 84.9174C30.4233 84.5106 30.1469 83.9562 30.0874 83.3593L27.721 55.1017C27.6927 54.8199 27.7244 54.5353 27.814 54.2666C27.9036 53.9979 28.049 53.7512 28.2408 53.5427C28.4325 53.3343 28.6662 53.1688 28.9265 53.0571C29.1868 52.9454 29.4678 52.8901 29.751 52.8948H80.1153C80.7151 52.906 81.2899 53.1369 81.7309 53.5437C82.1718 53.9504 82.4482 54.5048 82.5078 55.1017L84.8597 83.3738C84.8857 83.654 84.8525 83.9365 84.7623 84.2031C84.6721 84.4696 84.5268 84.7142 84.3359 84.9209C84.145 85.1277 83.9128 85.292 83.6543 85.4031C83.3958 85.5143 83.1168 85.5699 82.8355 85.5662Z"
      />
      <path
        opacity="0.1"
        fill="#263238"
        d="M67.257 57.8306L51.655 76.6168C51.1646 77.2067 50.8346 77.9131 50.6967 78.6677C50.5588 79.4223 50.6178 80.1997 50.8679 80.9249C51.118 81.6501 51.5507 82.2986 52.1245 82.8078C52.6982 83.3171 53.3935 83.6698 54.1432 83.832L79.4602 89.3043C80.2521 89.4761 81.0757 89.4291 81.8428 89.1683C82.6099 88.9075 83.2916 88.4428 83.8147 87.824C84.3378 87.2053 84.6826 86.4558 84.812 85.6559C84.9415 84.8561 84.8509 84.0361 84.5497 83.2839L74.8434 59.0225C74.5567 58.3026 74.0875 57.6698 73.4819 57.1863C72.8763 56.7028 72.1553 56.3853 71.3898 56.265C70.6243 56.1448 69.8407 56.2259 69.116 56.5004C68.3914 56.7749 67.7507 57.2334 67.257 57.8306Z"
      />
      <path
        opacity="0.1"
        fill="#263238"
        d="M32.5264 60.4604L27.0918 75.1083C26.9291 75.5555 26.8822 76.0367 26.9556 76.5069C27.0289 76.9771 27.2202 77.4211 27.5114 77.7975C27.8027 78.1738 28.1845 78.4703 28.6212 78.6593C29.058 78.8483 29.5355 78.9237 30.0092 78.8783L45.8809 77.321C46.386 77.2717 46.8684 77.0869 47.2772 76.7862C47.686 76.4855 48.006 76.0801 48.2035 75.6126C48.401 75.1451 48.4686 74.633 48.3993 74.1303C48.3299 73.6276 48.1261 73.1529 47.8094 72.7564L37.3694 59.6774C37.0575 59.2881 36.6479 58.9886 36.1824 58.8093C35.7169 58.6301 35.2121 58.5776 34.7197 58.6572C34.2273 58.7368 33.7648 58.9457 33.3795 59.2625C32.9941 59.5793 32.6998 59.9927 32.5264 60.4604Z"
      />
      <path
        fill="#F55533"
        d="M72.6395 53.099L58.0525 74.269C57.5969 74.9303 57.3186 75.6974 57.2443 76.4969C57.1699 77.2965 57.3021 78.1017 57.628 78.8356C57.9539 79.5695 58.4627 80.2075 59.1057 80.6885C59.7487 81.1695 60.5044 81.4774 61.3005 81.5828L88.1138 85.1527C88.9571 85.265 89.8151 85.146 90.5959 84.8083C91.3768 84.4706 92.0511 83.927 92.5468 83.2356C93.0425 82.5442 93.3408 81.731 93.41 80.8831C93.4792 80.0352 93.3165 79.1844 92.9395 78.4218L80.7131 53.6732C80.3502 52.94 79.8019 52.3145 79.1226 51.8586C78.4433 51.4027 77.6567 51.1324 76.8407 51.0743C76.0247 51.0163 75.2077 51.1726 74.4707 51.5278C73.7338 51.8829 73.1025 52.4246 72.6395 53.099Z"
      />
      <path
        fill="white"
        d="M61.9355 79.6914C61.4512 79.6255 60.9908 79.441 60.5951 79.1541C60.1994 78.8672 59.8808 78.4869 59.6677 78.0471C59.448 77.6314 59.3446 77.164 59.3686 76.6943C59.3926 76.2247 59.543 75.7703 59.804 75.3791L74.4287 54.1453C74.6931 53.7665 75.0589 53.4699 75.484 53.2894C75.9092 53.1088 76.3767 53.0517 76.8328 53.1245C77.3172 53.1898 77.7779 53.3742 78.1737 53.6612C78.5694 53.9481 78.8879 54.3287 79.1006 54.7688L91.3502 79.5783C91.5699 79.994 91.6733 80.4614 91.6493 80.9311C91.6253 81.4007 91.4749 81.8551 91.2139 82.2463C90.9501 82.6258 90.5846 82.9233 90.1593 83.1043C89.734 83.2854 89.2663 83.3429 88.8098 83.27L61.9355 79.6914Z"
      />
      <path
        fill="#F55533"
        d="M75.2054 61.0234L76.9135 61.2496C77.4476 61.3249 77.9352 61.5942 78.2834 62.006C78.6316 62.4178 78.816 62.9435 78.8014 63.4826L78.6854 65.7098C78.6826 65.7905 78.6739 65.871 78.6593 65.9505L77.4442 72.664C77.3572 73.1267 77.093 73.5371 76.7079 73.808C76.3228 74.0788 75.8472 74.1886 75.3823 74.114C74.8889 74.0438 74.4338 73.8088 74.091 73.447C73.7482 73.0853 73.5379 72.6181 73.4944 72.1217L73.0043 65.1849C72.9986 65.1047 72.9986 65.0243 73.0043 64.9442L73.1174 62.7141C73.1297 62.4607 73.1953 62.2128 73.31 61.9866C73.4246 61.7604 73.5858 61.5609 73.7829 61.4013C73.9801 61.2417 74.2087 61.1255 74.4538 61.0604C74.699 60.9953 74.9551 60.9826 75.2054 61.0234ZM75.0691 75.9758L75.5331 76.0367C76.0111 76.1041 76.4475 76.3451 76.7593 76.7135C77.071 77.082 77.2364 77.5523 77.2238 78.0348C77.2136 78.2623 77.1555 78.4851 77.0531 78.6886C76.9507 78.892 76.8065 79.0715 76.6298 79.2153C76.4532 79.3591 76.2482 79.4639 76.0282 79.5228C75.8082 79.5817 75.5782 79.5935 75.3533 79.5573L74.8893 79.4935C74.4116 79.4267 73.9753 79.1863 73.6634 78.8183C73.3516 78.4504 73.1861 77.9805 73.1986 77.4983C73.2084 77.2706 73.2663 77.0476 73.3685 76.8439C73.4707 76.6402 73.615 76.4605 73.7917 76.3166C73.9684 76.1728 74.1737 76.068 74.3939 76.0092C74.6141 75.9505 74.8442 75.9391 75.0691 75.9758Z"
      />
      <path
        fill="#F55533"
        d="M38.1348 56.3354L34.1299 71.5227C34.0091 71.9841 34.0075 72.4687 34.125 72.9311C34.2426 73.3934 34.4756 73.8183 34.8022 74.166C35.1287 74.5138 35.5382 74.7729 35.9923 74.9192C36.4463 75.0656 36.9301 75.0943 37.3982 75.0027L52.9335 71.9722C53.4264 71.8738 53.8841 71.6455 54.2591 71.3109C54.6341 70.9762 54.9127 70.5474 55.0663 70.0688C55.2198 69.5902 55.2425 69.0792 55.1322 68.5889C55.0218 68.0986 54.7824 67.6466 54.4386 67.28L42.9198 55.1145C42.5744 54.7474 42.1368 54.4795 41.6528 54.3388C41.1687 54.198 40.6558 54.1895 40.1674 54.3142C39.6789 54.4388 39.2328 54.692 38.8753 55.0474C38.5179 55.4029 38.2622 55.8476 38.1348 56.3354Z"
      />
      <path
        fill="white"
        d="M37.3666 73.7779C37.0814 73.8295 36.7877 73.8082 36.5129 73.7159C36.2381 73.6237 35.9911 73.4634 35.7948 73.2501C35.5923 73.0446 35.446 72.7906 35.3698 72.5123C35.2937 72.234 35.2902 71.9409 35.3598 71.6609L39.3734 56.433C39.443 56.1669 39.5869 55.9261 39.7882 55.7387C39.9894 55.5513 40.2399 55.425 40.5102 55.3745C40.7957 55.3235 41.0895 55.3454 41.3642 55.4382C41.639 55.5309 41.8859 55.6916 42.082 55.9052L53.6414 68.0852C53.8442 68.2906 53.9909 68.5445 54.0676 68.8227C54.1443 69.1009 54.1483 69.3942 54.0793 69.6744C54.0093 69.94 53.8652 70.1801 53.664 70.367C53.4627 70.5539 53.2126 70.6798 52.9425 70.73L37.3666 73.7779Z"
      />
      <path
        fill="#F55533"
        d="M41.264 60.0118L42.2529 59.8175C42.5722 59.766 42.8993 59.8343 43.1712 60.0094C43.4432 60.1844 43.6408 60.4539 43.7261 60.7658L44.0799 62.0911C44.0933 62.1398 44.104 62.1892 44.1118 62.239L44.7411 66.4121C44.7902 66.6931 44.7263 66.982 44.5634 67.2161C44.4004 67.4502 44.1516 67.6105 43.8711 67.662C43.577 67.7109 43.275 67.6575 43.0154 67.5108C42.7558 67.3641 42.5543 67.133 42.4443 66.8558L40.8377 62.8886C40.8182 62.8424 40.8018 62.7949 40.7884 62.7465L40.423 61.4183C40.3755 61.2707 40.36 61.1146 40.3777 60.9605C40.3953 60.8064 40.4455 60.6578 40.5251 60.5247C40.6047 60.3916 40.7118 60.277 40.8392 60.1885C40.9667 60.1001 41.1115 60.0398 41.264 60.0118ZM44.0625 68.793L44.3322 68.7408C44.6182 68.6913 44.9124 68.7508 45.1567 68.9076C45.401 69.0643 45.5776 69.307 45.6517 69.5876C45.6926 69.7183 45.7055 69.8562 45.6895 69.9922C45.6735 70.1282 45.629 70.2593 45.559 70.3769C45.489 70.4946 45.3949 70.5961 45.2829 70.675C45.171 70.7539 45.0437 70.8083 44.9093 70.8346L44.6396 70.8868C44.354 70.9365 44.0602 70.8774 43.8159 70.7212C43.5717 70.565 43.3948 70.323 43.3201 70.0429C43.2793 69.9121 43.2664 69.774 43.2823 69.6379C43.2983 69.5017 43.3427 69.3704 43.4127 69.2525C43.4828 69.1346 43.5768 69.0328 43.6887 68.9536C43.8007 68.8745 43.9281 68.8197 44.0625 68.793Z"
      />
      <path
        fill="#7F3E3B"
        d="M112.624 59.7383L110.374 60.4691L112.184 62.6093C112.697 62.1642 113.142 61.6453 113.503 61.0694C113.597 60.9063 113.622 60.7131 113.574 60.5314C113.527 60.3497 113.409 60.194 113.248 60.0979L112.624 59.7383Z"
      />
      <path
        fill="#7F3E3B"
        d="M109.339 62.2418L110.687 63.8803L112.187 62.6246L110.374 60.4844L109.339 62.2418Z"
      />
      <path
        fill="#7F3E3B"
        d="M113.352 42.2461C113.901 43.7541 114.405 45.2621 114.884 46.7904C115.362 48.3187 115.803 49.8528 116.189 51.4594L116.325 52.0771L116.389 52.399L116.421 52.5614V52.602V52.6629L116.438 52.7847C116.501 53.3364 116.474 53.8948 116.357 54.4377C116.172 55.3087 115.892 56.1567 115.522 56.9665C114.846 58.4797 114.014 59.9181 113.039 61.2585L111.534 60.4262C112.068 58.9762 112.596 57.4943 112.984 56.0588C113.176 55.3861 113.31 54.6982 113.384 54.0027C113.409 53.7626 113.397 53.5202 113.35 53.2835L113.225 52.8311L113.062 52.2946C112.607 50.8446 112.088 49.3511 111.575 47.8663C111.061 46.3815 110.548 44.8822 110.055 43.3945L113.352 42.2461Z"
      />
      <path
        fill="#263238"
        d="M100.827 33.2754C100.865 33.4581 100.801 33.6263 100.685 33.6582C100.569 33.6901 100.441 33.5625 100.395 33.3682C100.348 33.1739 100.418 33.0173 100.537 32.9883C100.656 32.9593 100.789 33.0956 100.827 33.2754Z"
      />
      <path
        fill="#630F0F"
        d="M100.818 33.6211C100.72 34.2285 100.543 34.8203 100.291 35.3814C100.433 35.4389 100.587 35.4641 100.74 35.4551C100.894 35.446 101.043 35.4031 101.178 35.3292L100.818 33.6211Z"
      />
      <path
        fill="#263238"
        d="M100.34 32.1897C100.322 32.1897 100.305 32.1855 100.289 32.1774C100.274 32.1693 100.26 32.1576 100.25 32.1433C100.242 32.1317 100.236 32.1186 100.233 32.1047C100.23 32.0908 100.229 32.0764 100.232 32.0624C100.234 32.0484 100.239 32.035 100.247 32.023C100.255 32.0109 100.264 32.0006 100.276 31.9925C100.422 31.9022 100.588 31.8483 100.759 31.8352C100.93 31.8221 101.101 31.8501 101.259 31.9171C101.273 31.9225 101.285 31.9305 101.295 31.9406C101.305 31.9507 101.313 31.9627 101.318 31.9759C101.324 31.9892 101.326 32.0033 101.326 32.0176C101.326 32.0319 101.323 32.0461 101.317 32.0592C101.306 32.086 101.285 32.1073 101.259 32.1187C101.232 32.1301 101.202 32.1306 101.175 32.1201C101.052 32.0655 100.917 32.0415 100.782 32.0501C100.647 32.0587 100.517 32.0996 100.401 32.1694C100.384 32.183 100.362 32.1901 100.34 32.1897Z"
      />
      <path
        fill="#7F3E3B"
        d="M107.042 33.8477C107.245 35.9531 106.946 37.9918 107.416 39.8217C107.416 39.8217 106.894 41.5211 103.782 41.5211C100.36 41.5211 102.213 39.8217 102.213 39.8217C104.098 39.378 104.101 37.9918 103.829 36.6897L107.042 33.8477Z"
      />
      <path
        fill="#263238"
        d="M108.08 40.4066C108.347 39.8701 108.524 39.1074 107.973 38.916C107.367 38.7043 104.127 38.626 102.953 39.032C102.64 39.1148 102.371 39.3167 102.205 39.5947C102.039 39.8726 101.988 40.2046 102.063 40.5197L108.08 40.4066Z"
      />
      <path
        fill="#F55533"
        d="M115.072 116.78C115.286 116.725 115.492 116.645 115.687 116.542C115.697 116.537 115.706 116.53 115.712 116.52C115.719 116.51 115.722 116.499 115.722 116.487C115.722 116.475 115.718 116.464 115.71 116.454C115.703 116.445 115.693 116.438 115.681 116.435C115.58 116.4 114.681 116.102 114.443 116.305C114.417 116.327 114.398 116.356 114.387 116.389C114.376 116.422 114.375 116.457 114.382 116.49C114.387 116.551 114.406 116.609 114.44 116.66C114.473 116.711 114.519 116.752 114.573 116.78C114.735 116.836 114.911 116.836 115.072 116.78ZM115.501 116.49C115.107 116.67 114.788 116.731 114.631 116.65C114.595 116.632 114.564 116.605 114.542 116.571C114.519 116.537 114.506 116.499 114.504 116.458C114.499 116.445 114.499 116.431 114.502 116.417C114.506 116.404 114.514 116.392 114.524 116.383C114.643 116.29 115.13 116.392 115.501 116.502V116.49Z"
      />
      <path
        fill="#F55533"
        d="M115.675 116.549C115.683 116.547 115.69 116.544 115.697 116.539C115.703 116.534 115.709 116.528 115.713 116.521C115.717 116.514 115.719 116.506 115.72 116.498C115.721 116.49 115.721 116.482 115.719 116.474C115.719 116.445 115.484 115.755 115.139 115.604C115.098 115.583 115.054 115.57 115.008 115.567C114.963 115.564 114.917 115.571 114.875 115.586C114.831 115.597 114.793 115.622 114.766 115.657C114.739 115.692 114.725 115.736 114.727 115.781C114.727 116.071 115.353 116.483 115.637 116.561L115.675 116.549ZM114.95 115.679C114.999 115.668 115.05 115.674 115.095 115.697C115.317 115.877 115.479 116.119 115.562 116.393C115.272 116.262 114.86 115.932 114.854 115.763C114.854 115.749 114.854 115.714 114.924 115.685L114.95 115.679Z"
      />
      <path
        fill="#F55533"
        d="M99.2322 118.552C99.4523 118.546 99.6711 118.516 99.8847 118.463C99.8961 118.459 99.9065 118.453 99.9146 118.444C99.9228 118.436 99.9285 118.425 99.9311 118.413C99.9321 118.401 99.93 118.39 99.9249 118.379C99.9198 118.368 99.9119 118.359 99.9021 118.352C99.8122 118.297 99.006 117.801 98.7276 117.943C98.6967 117.959 98.6707 117.983 98.6528 118.013C98.6348 118.043 98.6256 118.077 98.6261 118.112C98.6199 118.17 98.6277 118.229 98.6489 118.283C98.6701 118.338 98.704 118.386 98.7479 118.425C98.891 118.519 99.0612 118.564 99.2322 118.552ZM99.7136 118.378C99.2902 118.463 98.9625 118.448 98.8262 118.332C98.7957 118.306 98.7722 118.273 98.758 118.235C98.7438 118.198 98.7393 118.157 98.745 118.117C98.745 118.071 98.7653 118.057 98.7827 118.048C98.9248 117.975 99.3859 118.187 99.7136 118.378Z"
      />
      <path
        fill="#F55533"
        d="M99.8703 118.462C99.8818 118.465 99.8937 118.465 99.9051 118.462C99.9137 118.456 99.9206 118.448 99.9252 118.438C99.9297 118.429 99.9318 118.418 99.9312 118.407C99.9312 118.378 99.8674 117.656 99.5774 117.418C99.5433 117.388 99.5031 117.366 99.4596 117.353C99.416 117.34 99.3701 117.337 99.3251 117.343C99.2807 117.343 99.2377 117.358 99.2033 117.386C99.1689 117.414 99.1453 117.453 99.1366 117.497C99.0844 117.787 99.5832 118.323 99.8442 118.465L99.8703 118.462ZM99.3657 117.45C99.4157 117.449 99.4642 117.467 99.502 117.5C99.6756 117.726 99.7767 118 99.792 118.286C99.5339 118.088 99.212 117.677 99.2468 117.511C99.2468 117.511 99.2468 117.459 99.3338 117.45H99.3599H99.3657Z"
      />
      <path
        fill="#7F3E3B"
        d="M102.564 118.372H100.273L100.25 113.07H102.541L102.564 118.372Z"
      />
      <path
        fill="#7F3E3B"
        d="M118.512 116.412L116.308 116.816L114.727 111.598L116.931 111.195L118.512 116.412Z"
      />
      <path
        fill="#263238"
        d="M115.89 116.23L118.392 115.65C118.436 115.639 118.482 115.644 118.522 115.664C118.562 115.683 118.594 115.717 118.613 115.757L119.442 117.651C119.462 117.698 119.471 117.75 119.469 117.801C119.468 117.852 119.454 117.902 119.431 117.948C119.407 117.994 119.374 118.033 119.333 118.065C119.293 118.096 119.246 118.118 119.196 118.13C118.326 118.321 117.885 118.367 116.783 118.628C116.107 118.788 114.727 119.191 113.79 119.411C112.853 119.632 112.552 118.727 112.92 118.541C114.562 117.756 115.107 117.091 115.556 116.453C115.637 116.342 115.756 116.262 115.89 116.23Z"
      />
      <path
        fill="#263238"
        d="M100.3 118.105H102.799C102.844 118.105 102.888 118.121 102.922 118.15C102.956 118.178 102.98 118.218 102.988 118.262L103.362 120.292C103.371 120.342 103.369 120.394 103.356 120.443C103.342 120.492 103.318 120.538 103.285 120.577C103.252 120.615 103.211 120.646 103.165 120.667C103.118 120.688 103.068 120.699 103.017 120.698C102.115 120.684 100.816 120.631 99.6819 120.631C98.3566 120.631 97.8839 120.704 96.3295 120.704C95.3899 120.704 95.1695 119.753 95.5639 119.669C97.3677 119.274 98.1565 119.234 99.7428 118.279C99.909 118.172 100.102 118.111 100.3 118.105Z"
      />
      <path
        fill="#F55533"
        d="M112.52 40.4126C114.657 41.9177 115.301 45.7921 115.301 45.7921L110.458 48.2455C109.692 46.7142 109.099 45.1026 108.689 43.4402C108.17 41.1492 111 39.3454 112.52 40.4126Z"
      />
      <path
        fill="black"
        opacity="0.2"
        d="M110.098 42.8867L112.006 47.4571L110.458 48.2488C109.978 47.2785 109.559 46.2789 109.205 45.256L110.098 42.8867Z"
      />
      <path
        fill="#F55533"
        d="M98.278 50.4612C98.2954 53.0161 98.4491 56.1945 98.829 60.1385H111.07C111.218 58.3405 110.467 49.8638 112.291 41.3755C112.323 41.226 112.324 41.0716 112.295 40.9215C112.265 40.7715 112.205 40.6291 112.119 40.5031C112.032 40.3771 111.92 40.2702 111.791 40.1888C111.662 40.1074 111.517 40.0533 111.366 40.0299C110.405 39.8762 109.437 39.7726 108.466 39.7196C106.732 39.6195 104.994 39.6195 103.26 39.7196C102.561 39.7803 101.865 39.877 101.175 40.0096C100.662 40.1138 100.189 40.3607 99.8108 40.7219C99.4321 41.0831 99.1632 41.5439 99.0349 42.0512C98.654 43.6152 98.4296 45.2133 98.365 46.8217C98.3099 47.8657 98.2693 49.075 98.278 50.4612Z"
      />
      <path
        fill="black"
        opacity="0.2"
        d="M102.141 45.6626C101.332 46.2426 99.7834 46.5326 98.3711 46.6515C98.3943 46.2774 98.4233 45.9236 98.4552 45.5901C98.8351 45.39 99.2005 45.2073 99.5456 45.0391L102.141 45.6626Z"
      />
      <g opacity="0.2">
        <path
          fill="black"
          d="M100.25 113.07L100.264 116.017H102.553L102.541 113.07H100.25Z"
        />
        <path
          fill="black"
          d="M116.931 111.195L114.727 111.601L115.544 114.29L117.748 113.884L116.931 111.195Z"
        />
      </g>
      <path
        fill="#7F3E3B"
        d="M106.72 31.3859C107.054 33.6392 107.364 34.9384 106.494 36.3594C106.218 36.8215 105.82 37.1992 105.345 37.4518C104.869 37.7043 104.334 37.822 103.796 37.7921C103.259 37.7623 102.74 37.586 102.295 37.2823C101.85 36.9787 101.497 36.5592 101.274 36.0694C100.378 34.196 99.9806 30.8233 101.944 29.3994C102.36 29.0809 102.853 28.8777 103.373 28.8101C103.893 28.7425 104.421 28.8129 104.905 29.0142C105.389 29.2155 105.812 29.5406 106.13 29.9569C106.449 30.3732 106.652 30.8661 106.72 31.3859Z"
      />
      <path
        fill="#263238"
        d="M102.034 33.0311C101.184 32.657 101.265 31.3201 101.523 30.7575C100.54 31.0939 99.1193 31.2882 98.8641 30.2877C98.6089 29.2872 99.476 28.9682 98.9453 28.1127C98.4146 27.2572 100.105 26.3263 100.894 27.5327C101.683 28.7391 103.632 28.1997 104.89 27.0484C105.807 26.2103 107.147 27.292 106.616 28.3853C106.616 28.3853 109.371 28.2084 108.823 30.0789C108.275 31.9494 107.5 32.0132 107.677 33.3443C107.799 34.2433 107.903 36.0703 106.825 36.3284C105.52 36.6271 102.266 35.47 102.034 33.0311Z"
      />
      <path
        opacity="0.2"
        fill="black"
        d="M102.016 35.47C101.767 35.6498 101.509 35.8412 101.245 36.05L101.259 36.0819C101.517 36.6253 101.929 37.0804 102.445 37.3898C102.611 37.2303 102.703 36.6938 103.011 36.7025C103.93 36.7286 105.708 36.3429 106.004 35.3047L102.016 35.47Z"
      />
      <path
        fill="#7F3E3B"
        d="M102.733 32.9987C102.796 33.4998 102.72 34.0086 102.512 34.469C102.251 34.9997 101.839 34.6604 101.68 34.0079C101.535 33.4279 101.497 32.439 101.874 32.149C102.251 31.859 102.66 32.3375 102.733 32.9987Z"
      />
      <path
        fill="#F55533"
        d="M103.173 60.1445C103.173 60.1445 105.096 77.6373 106.268 84.1913C107.552 91.3688 114.205 113.896 114.205 113.896L118.306 113.026C118.306 113.026 113.726 91.9488 113.1 84.8438C112.43 77.1298 111.07 60.1445 111.07 60.1445H103.173Z"
      />
      <path
        opacity="0.3"
        fill="black"
        d="M103.173 60.1445C103.173 60.1445 105.096 77.6373 106.268 84.1913C107.552 91.3688 114.205 113.896 114.205 113.896L118.306 113.026C118.306 113.026 113.726 91.9488 113.1 84.8438C112.462 77.5126 112.462 77.5271 111.07 60.1445H103.173Z"
      />
      <path
        fill="#263238"
        d="M113.288 112.519C113.271 112.519 113.984 114.016 113.984 114.016L118.61 113.027L118.282 111.629L113.288 112.519Z"
      />
      <path
        opacity="0.2"
        fill="black"
        d="M104.574 66.3801C107.555 66.9601 106.804 78.1686 106.221 83.9309C105.514 79.8709 104.548 72.0206 103.901 66.4613C104.11 66.3578 104.347 66.3291 104.574 66.3801Z"
      />
      <path
        fill="#F55533"
        d="M98.8289 60.1474C98.8289 60.1474 97.8545 77.2574 97.8342 84.2435C97.811 91.4935 99.4292 115.274 99.4292 115.274H103.309C103.309 115.274 104.353 91.9256 104.8 84.7945C105.287 77.0196 106.879 60.1445 106.879 60.1445L98.8289 60.1474Z"
      />
      <path
        opacity="0.3"
        fill="black"
        d="M98.8289 60.1474C98.8289 60.1474 97.8545 77.2574 97.8342 84.2435C97.811 91.4935 99.4292 115.274 99.4292 115.274H103.309C103.309 115.274 104.353 91.9256 104.8 84.7945C105.287 77.0196 106.879 60.1445 106.879 60.1445L98.8289 60.1474Z"
      />
      <path
        fill="#263238"
        d="M98.7449 113.73C98.7275 113.73 98.9015 115.34 98.9015 115.34H103.617L103.8 113.89L98.7449 113.73Z"
      />
      <path
        fill="#7F3E3B"
        d="M103.681 44.0057C103.217 44.0492 102.825 44.0753 102.399 44.0869C101.973 44.0985 101.567 44.1072 101.152 44.1043C100.317 44.1043 99.4816 44.0521 98.6406 43.9593C97.7869 43.8713 96.9402 43.726 96.106 43.5243C95.2226 43.3167 94.3648 43.0123 93.5482 42.6166L93.4525 42.5673C93.2734 42.4799 93.1134 42.3579 92.9817 42.2083C92.8499 42.0588 92.7491 41.8847 92.6849 41.696C92.6208 41.5073 92.5946 41.3078 92.6079 41.1089C92.6211 40.9101 92.6736 40.7158 92.7623 40.5373C92.8028 40.455 92.8503 40.3765 92.9044 40.3024C93.2506 39.8437 93.6443 39.4228 94.0789 39.0467C94.4704 38.6987 94.8619 38.3884 95.2563 38.0926C96.0451 37.4981 96.8513 36.9732 97.6604 36.4628C98.4695 35.9524 99.296 35.4797 100.128 35.0128C100.961 34.5459 101.799 34.1254 102.669 33.7223C102.86 33.6459 103.072 33.6397 103.267 33.705C103.463 33.7703 103.629 33.9028 103.735 34.0789C103.842 34.255 103.883 34.4632 103.851 34.6667C103.819 34.8701 103.715 35.0554 103.559 35.1897C102.831 35.7494 102.08 36.3033 101.352 36.8717C100.624 37.4401 99.9021 37.9998 99.18 38.574C98.4579 39.1482 97.7706 39.734 97.1123 40.314C96.7846 40.604 96.4685 40.894 96.1872 41.2101C95.9342 41.4672 95.7051 41.7467 95.5028 42.0453L94.8619 39.7253C95.4936 39.9597 96.1461 40.1336 96.8107 40.2444C97.5221 40.3653 98.2394 40.4485 98.9596 40.4938C99.6933 40.5402 100.436 40.5663 101.181 40.5663C101.555 40.5663 101.929 40.5663 102.3 40.5663C102.672 40.5663 103.063 40.5373 103.388 40.5199H103.426C103.864 40.5195 104.287 40.6847 104.609 40.9825C104.931 41.2804 105.129 41.6888 105.162 42.1261C105.196 42.5635 105.063 42.9974 104.791 43.3411C104.518 43.6848 104.126 43.9128 103.692 43.9796L103.681 44.0057Z"
      />
      <path
        fill="#7F3E3B"
        d="M105.305 33.2702L103.855 33.5921C103.855 33.5921 101.915 33.3514 101.471 34.813L102.631 35.7932L104.406 36.0832C104.687 36.1269 104.974 36.0801 105.227 35.9498L105.679 35.7149C105.782 35.6619 105.87 35.584 105.935 35.4882C106 35.3924 106.039 35.2819 106.05 35.1668L106.158 34.0068C106.168 33.8969 106.152 33.7861 106.111 33.6837C106.07 33.5812 106.005 33.4901 105.922 33.4179C105.838 33.3458 105.739 33.2947 105.631 33.269C105.524 33.2433 105.412 33.2437 105.305 33.2702Z"
      />
      <path
        fill="#F55533"
        d="M102.474 45.6971C100.035 46.3757 97.2544 45.5289 97.2544 45.5289L98.2781 40.0682C98.2781 40.0682 100.7 39.8536 102.213 39.8217C106.108 39.7289 106.871 44.4791 102.474 45.6971Z"
      />
    </svg>
  );
}
