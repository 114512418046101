import { useContext } from "react";
import constants from "../../constants";
import useAxiosPrivate from "../useAxiosPrivate";
import AlertContext from "../../context/AlertContext";
import useCommonFunctionHooks from "../useCommonFunctionHooks";
import { getPreSignedUrl, getDataFromStorage } from "../../util";
import StudentTestPageCalcStatsHooks from "./StudentTestPageCalcStatsHooks";
import { StudentTestPageContext } from "../../context/StudentTestPageContext";
import StudentTestPageQuestionUpdateHooks from "../../Components/Student/StudentTestPage/StudentTestPageQuestionUpdateHooks";

export default function StudentTestPageHooks() {
  const Alert = useContext(AlertContext);
  const StudentTestPageCxt = useContext(StudentTestPageContext);

  const axios = useAxiosPrivate();
  const { calcStats } = StudentTestPageCalcStatsHooks();
  const { questionUpdate } = StudentTestPageQuestionUpdateHooks();

  const { getCatchBlockDetails, getHeaderDetailsForAxios } =
    useCommonFunctionHooks();

  let tempReport,
    tempTestData,
    answerObject = {},
    tempScoresObj = {};

  const handleCPC = (e) => {
    e.preventDefault();
  };

  const checkIfObjectExistsInArray = (obj, array) => {
    for (let i = 0; i < array.length; i++) {
      if (JSON.stringify(obj) === JSON.stringify(array[i])) {
        return true;
      }
    }

    return false;
  };

  const handleScoreKeyDown = (e) => {
    if (e.keyCode === "38") {
      e.target.blur();
      e.preventDefault();
    } else if (e.keyCode === "40") {
      e.target.blur();
      e.preventDefault();
    }
  };

  const traverseInitialize = () => {
    Alert.setPrevButton(false);
    Alert.setNextButton(false);

    if (Alert.queLen > 1 || Alert.secLen > 1) {
      Alert.setNextButton(true);
    }
  };

  const getSectionTotal = (section) => {
    let total = 0;

    for (let i = 0; i < section.questions.length; i++) {
      total = total + section.questions[i].targetscore;
    }

    return total;
  };

  const preventCopyAndCut = (event) => {
    if (
      (event.ctrlKey || event.metaKey) &&
      (event.key === "c" || event.key === "x")
    ) {
      event.preventDefault();
      event.stopPropagation();
    }
  };

  const assignQuestionIds = (dataArr, sections1) => {
    return dataArr.map((section) => {
      const currentSection = {
        secName: section.secName,
        secId: section.secId,
        qnsObj: { ...section.qnsObj },
      };

      const relevantSection = sections1.find(
        (s) => s.sectionid === section.secId
      );
      if (relevantSection) {
        Object.keys(currentSection.qnsObj).forEach((key, index) => {
          const obj = currentSection.qnsObj[key];
          const matchedQuestion = relevantSection.questions[index];

          if (matchedQuestion) {
            obj.qId = matchedQuestion.id;
          }
        });
      }

      return currentSection;
    });
  };

  const handleVideoQuestions = (dataOfSections) => {
    let mArr = [];

    dataOfSections.map((item, ind) => {
      let cc = 1;
      let MqObj = {
        qnsObj: {},
        secId: item.sectionid,
        secName: item.sectionname,
      };

      item.qnconfig.map((i) => {
        for (let j = 0; j < i.qncount + i.fixedqncount; j++) {
          let qObj = {};
          qObj.qId = item.questions[j] ? item.questions[j].id : "";
          qObj.isAnswered = item.questions[j] && item.questions[j].answer ? true : false;
          qObj.qnduration = i.qnduration / (i.qncount + i.fixedqncount);
          qObj.marks = i.marks;
          qObj.complexityId = i.complexityid;
          qObj.topicId = i.topicid;
          if(item.questions[j] && item.questions[j].answer){
            qObj.answer = item.questions[j].answer;
          }
          MqObj.qnsObj[cc] = qObj;
          cc++;
        }

        return "";
      });

      mArr.push(MqObj);

      return "";
    });

    let videoQuestionsObj = assignQuestionIds(mArr, dataOfSections);
    Alert.questionListObj.current = videoQuestionsObj;
    Alert.sectionWithQuestions.current = dataOfSections;
  };

  const startTest = () => {
    Alert.setLpConfigId(Alert.testData.lpconfigid);
    Alert.setMcqAndDesTestAndLpId({
      lpid: Alert.lpId,
      status: Alert.status,
      testid: Alert.testData.testid,
      cellobj: Alert.testData.libtype,
      autoEval: Alert.testData.autoeval,
      testmode: Alert.testData.testmode,
      viewTest: Alert.testData.viewTest,
      libtypeid: Alert.testData.testtype,
      proctored: Alert.testData.proctored,
      configdata: Alert.testData.configdata,
      lpconfigid: Alert.testData.lpconfigid,
      learnerid: getDataFromStorage("learnerid"),
    });

    Alert.setTestPageShow(true);
    Alert.setTakeMcqAndDesTest(true);

    if (
      Alert.status === constants.PROGRESS_STATUS.UNLOCKED ||
      Alert.status === constants.PROGRESS_STATUS.REATTEMPT
    ) {
      Alert.setIsLoaded(true);
      Alert.setButtonDisable(true);
      axios
        .post(
          "node/learner/course/test/get",
          JSON.stringify({
            lpid: Alert.lpId,
            isTakingTest: true,
            testid: Alert.testData.testid,
            mockuse: Alert.testData.mockuse,
            libtypeid: Alert.testData.testtype,
            lpconfigid: Alert.testData.lpconfigid,
            mocklp: localStorage.getItem("mockLp"),
            learnerid: getDataFromStorage("learnerid"),
          }),
          getHeaderDetailsForAxios()
        )
        .then((res) => {
          (async () => {
            try {
              Alert.setResultCode(res.data.resultCode);

              if (res.data.resultCode !== constants.RESULT_STATUS.SUCCESS) {
                Alert.setShowNotify({
                  show: true,
                  title: "Info",
                  msg: res.data.msg,
                });
              } else {
                tempTestData = res.data.data;

                if (tempTestData.videoqn === 1) {
                  handleVideoQuestions(tempTestData.sections);
                }

                for (let i = 0; i < tempTestData.sections.length; i++) {
                  for (
                    let j = 0;
                    j < tempTestData.sections[i].questions.length;
                    j++
                  ) {
                    if (
                      Alert.status === constants.PROGRESS_STATUS.QN_CONTINUE
                    ) {
                      if (
                        tempTestData.sections[i].questions[j].answer === "" ||
                        tempTestData.sections[i].questions[j].answer === null ||
                        tempTestData.sections[i].questions[j].answer ===
                          undefined
                      ) {
                        tempTestData.sections[i].questions[j].choosed = -1;
                        tempTestData.sections[i].questions[j].answered = -1;
                        tempTestData.sections[i].questions[j].visited =
                          i === 0 && j === 0 ? "this" : "new";
                        tempTestData.sections[i].questions[j].review = -1;
                        tempTestData.sections[i].questions[j].previsited =
                          i === 0 && j === 0 ? "this" : "new";
                      } else {
                        tempTestData.sections[i].questions[j].visited =
                          "completed";
                        tempTestData.sections[i].questions[j].previsited =
                          "completed";
                        tempTestData.sections[i].questions[j].answered = 0;
                      }

                      answerObject = {
                        ...answerObject,
                        [tempTestData.sections[i].questions[j].id]:
                          tempTestData.sections[i].questions[j].answer,
                      };
                    } else {
                      tempTestData.sections[i].questions[j].choosed = -1;
                      tempTestData.sections[i].questions[j].answered = -1;
                      tempTestData.sections[i].questions[j].visited =
                        i === 0 && j === 0 ? "this" : "new";
                      tempTestData.sections[i].questions[j].review = -1;
                      tempTestData.sections[i].questions[j].previsited =
                        i === 0 && j === 0 ? "this" : "new";

                      answerObject = {
                        ...answerObject,
                        [tempTestData.sections[i].questions[j].id]: "",
                      };
                    }

                    if (process.env.REACT_APP_DEPLOYMENT === "AWS") {
                      let question = tempTestData.sections[i].questions[j];

                      for (let k = 0; k < question.links.length; k++) {
                        let linkRes = await getPreSignedUrl(
                          question.links[k].link
                        );

                        question.links[k].link = linkRes;
                      }
                    }
                  }
                }

                // Alert.setAnswers(answerObject);
                Alert.answerRef.current = answerObject;
                Alert.setMcqTestData(tempTestData);
                Alert.updateComingObj(tempTestData);
                questionUpdate(0, 0, tempTestData);
                Alert.updateQuestionContainerSingle(true);
                traverseInitialize();
                Alert.setTestMode(res.data.data.testmode);
                Alert.duration.current = tempTestData.duration;

                if (
                  Alert.testData.proctored ===
                    constants.PROCTORING_MODE.TAB_CAM &&
                  Alert.testData.testmode === constants.MODE.TEST_MODE
                ) {
                  Alert.setIsTakeTest(true);
                }

                Alert.navigate("/mcq");
              }
            } catch (err) {
              getCatchBlockDetails(err);
            } finally {
              Alert.setIsLoaded(false);
              Alert.setButtonDisable(false);
            }
          })();
        })
        .catch((error) => {
          getCatchBlockDetails(error);
        })
        .finally(() => {
          Alert.setIsLoaded(false);
          Alert.setButtonDisable(false);
        });
    }

    if (Alert.status === constants.PROGRESS_STATUS.QN_CONTINUE) {
      Alert.hasRendered.current = true;
      Alert.setIsLoaded(true);
      Alert.setButtonDisable(true);
      axios
        .post(
          "node/learner/course/test/report",
          JSON.stringify({
            lpid: Alert.lpId,
            isTakingTest: true,
            lmpid: Alert.testData.lmpid,
            testid: Alert.testData.testid,
            mockuse: Alert.testData.mockuse,
            libtypeid: Alert.testData.testtype,
            lpconfigid: Alert.testData.lpconfigid,
            mocklp: localStorage.getItem("mockLp"),
            learnerid: getDataFromStorage("learnerid"),
          }),
          getHeaderDetailsForAxios()
        )
        .then((res) => {
          Alert.hasRendered.current = false;
          Alert.backupTestDataq.current = res.data.data;

          (async () => {
            try {
              if (res.data.resultCode !== constants.RESULT_STATUS.SUCCESS) {
                Alert.setShowNotify({
                  show: true,
                  title: "Info",
                  msg: res.data.message,
                });
              } else {
                tempTestData = res.data.data;

                if (tempTestData.videoqn === 1) {
                  handleVideoQuestions(tempTestData.sections);
                }

                for (let i = 0; i < tempTestData.sections.length; i++) {
                  for (
                    let j = 0;
                    j < tempTestData.sections[i].questions.length;
                    j++
                  ) {
                    if (
                      Alert.status === constants.PROGRESS_STATUS.QN_CONTINUE
                    ) {
                      tempTestData.sections[i].questions[j].review = -1;

                      if (
                        tempTestData.sections[i].questions[j].answer === "" ||
                        tempTestData.sections[i].questions[j].answer === null ||
                        tempTestData.sections[i].questions[j].answer ===
                          undefined
                      ) {
                        tempTestData.sections[i].questions[j].choosed = -1;
                        tempTestData.sections[i].questions[j].answered = -1;
                        tempTestData.sections[i].questions[j].visited =
                          i === 0 && j === 0 ? "this" : "new";
                        tempTestData.sections[i].questions[j].review = -1;
                        tempTestData.sections[i].questions[j].previsited =
                          i === 0 && j === 0 ? "this" : "new";
                      } else {
                        tempTestData.sections[i].questions[j].visited =
                          "completed";
                        tempTestData.sections[i].questions[j].previsited =
                          "completed";
                        tempTestData.sections[i].questions[j].answered = 0;
                      }

                      answerObject = {
                        ...answerObject,
                        [tempTestData.sections[i].questions[j].id]:
                          tempTestData.sections[i].questions[j].answer,
                      };
                    } else {
                      tempTestData.sections[i].questions[j].choosed = -1;
                      tempTestData.sections[i].questions[j].answered = -1;
                      tempTestData.sections[i].questions[j].visited =
                        i === 0 && j === 0 ? "this" : "new";
                      tempTestData.sections[i].questions[j].review = -1;
                      tempTestData.sections[i].questions[j].previsited =
                        i === 0 && j === 0 ? "this" : "new";

                      answerObject = {
                        ...answerObject,
                        [tempTestData.sections[i].questions[j].id]: "",
                      };
                    }

                    if (process.env.REACT_APP_DEPLOYMENT === "AWS") {
                      let question = tempTestData.sections[i].questions[j];

                      for (let k = 0; k < question.links.length; k++) {
                        let linkRes = await getPreSignedUrl(
                          question.links[k].link
                        );

                        question.links[k].link = linkRes;
                      }
                    }
                  }
                }

                // Alert.setAnswers(answerObject);
                Alert.answerRef.current = answerObject;
                Alert.setMcqTestData(tempTestData);
                Alert.updateComingObj(tempTestData);
                questionUpdate(0, 0, tempTestData);
                Alert.updateQuestionContainerSingle(true);
                traverseInitialize();
                Alert.setTestMode(res.data.data.testmode);
                Alert.duration.current = tempTestData.timeleft;

                if (
                  Alert.testData.proctored ===
                    constants.PROCTORING_MODE.TAB_CAM &&
                  Alert.testData.testmode === constants.MODE.TEST_MODE
                ) {
                  Alert.setIsTakeTest(true);
                }

                Alert.navigate("/mcq");
              }
            } catch (err) {
              getCatchBlockDetails(err);
            } finally {
              Alert.setIsLoaded(false);
              Alert.setButtonDisable(false);
            }
          })();
        })
        .catch((error) => {
          getCatchBlockDetails(error);
        })
        .finally(() => {
          Alert.setIsLoaded(false);
          Alert.setButtonDisable(false);
        });
    }
  };

  const viewTest = () => {
    Alert.setTakeMcqAndDesTest(true);

    if (
      Alert.status === constants.PROGRESS_STATUS.EVALUATED ||
      Alert.status === constants.PROGRESS_STATUS.TO_BE_EVALUATED ||
      Alert.status === constants.PROGRESS_STATUS.PARTIAL_EVALUATED
    ) {
      Alert.setMcqAndDesTestAndLpId({
        lpid: Alert.lpId,
        status: Alert.status,
        testid: Alert.testData.testid,
        cellobj: Alert.testData.libtype,
        autoEval: Alert.testData.autoeval,
        testmode: Alert.testData.testmode,
        viewTest: Alert.testData.viewtest,
        libtypeid: Alert.testData.testtype,
        proctored: Alert.testData.proctored,
        configdata: Alert.testData.configdata,
        lpconfigid: Alert.testData.lpconfigid,
        learnerid: getDataFromStorage("learnerid"),
      });

      if (Alert.hasRendered.current === false) {
        Alert.hasRendered.current = true;

        Alert.setButtonDisable(true);
        Alert.setIsLoaded(true);
        axios
          .post(
            "node/learner/course/test/report",
            JSON.stringify({
              lpid: Alert.lpId,
              lmpid: Alert.testData.lmpid,
              testid: Alert.testData.testid,
              mockuse: Alert.testData.mockuse,
              libtypeid: Alert.testData.libtypeid,
              lpconfigid: Alert.testData.lpconfigid,
              mocklp: localStorage.getItem("mockLp"),
              learnerid: getDataFromStorage("learnerid"),
            }),
            getHeaderDetailsForAxios()
          )
          .then((res) => {
            Alert.setButtonDisable(false);
            Alert.hasRendered.current = false;
            Alert.backupTestDataq.current = res.data.data;
            res.data.data.mockuse = Alert.testData.mockuse;

            (async () => {
              try {
                if (res.data.resultCode !== constants.RESULT_STATUS.SUCCESS) {
                  Alert.setShowNotify({
                    show: true,
                    title: "Info",
                    msg: res.data.message,
                  });
                } else {
                  tempReport = res.data.data;

                  if (tempReport.videoqn === 1) {
                    handleVideoQuestions(tempReport.sections);
                  }

                  for (let i = 0; i < res.data.data.sections.length; i++) {
                    for (
                      let j = 0;
                      j < res.data.data.sections[i].questions.length;
                      j++
                    ) {
                      if (process.env.REACT_APP_DEPLOYMENT === "AWS") {
                        let question = res.data.data.sections[i].questions[j];

                        if (getDataFromStorage("role") === "learner") {
                          question.review = -1;
                        }

                        for (let k = 0; k < question.links.length; k++) {
                          let linkRes = await getPreSignedUrl(
                            question.links[k].link
                          );

                          question.links[k].link = linkRes;
                        }

                        if (tempReport.videoqn === 1) {
                          if (
                            res.data.data.sections[i].questions[j].answer !==
                              "" &&
                            res.data.data.sections[i].questions[j].answer !=
                              null
                          ) {
                            let videoRes = await getPreSignedUrl(
                              res.data.data.sections[i].questions[j].answer
                            );
                            res.data.data.sections[i].questions[j].answer =
                              videoRes;
                          }
                        }
                      }
                    }
                  }

                  calcStats(tempReport, ["all"]);
                  Alert.setTestMode(res.data.data.testmode);
                  Alert.setApiLoaded(true);
                  Alert.backupTestDataq.current = res.data.data;
                  Alert.setDescScores(tempScoresObj);
                  Alert.setBackupTestData(res.data.data);
                  Alert.setMcqTestData(res.data.data);
                  Alert.updateComingObj(res.data.data);
                  Alert.updateQuestionContainerSingle(false);
                  clearInterval(Alert.interval.current);
                  Alert.setIsLoaded(false);
                  Alert.setTestPageShow(true);
                  Alert.navigate("/mcq");
                }
              } catch (err) {
                getCatchBlockDetails(err);
              } finally {
                Alert.setIsLoaded(false);
              }
            })();
          })
          .catch((error) => {
            getCatchBlockDetails(error);
          })
          .finally(() => Alert.setIsLoaded(false));
      }
    }
  };

  /* qn navigate */
  const scrollToQuestion = (index) => {
    if (StudentTestPageCxt.questionRefs.current[index]) {
      StudentTestPageCxt.questionRefs.current[index].scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  };

  return {
    viewTest,
    startTest,
    handleCPC,
    questionUpdate,
    getSectionTotal,
    scrollToQuestion,
    preventCopyAndCut,
    handleScoreKeyDown,
    handleVideoQuestions,
    checkIfObjectExistsInArray,
  };
}
