import React, { useContext, useEffect, useState } from "react";
import { Divider, TextField } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import FormTitle from "./FormTitle";
import Button from "../../Button";
import Checkbox from "../../CommonComponents/Checkbox";
import { ProfileContext } from "../../../context/ProfileContext";
import AlertContext from "../../../context/AlertContext";
import useAxiosPrivate from "../../../Hooks/useAxiosPrivate";
import { getDataFromStorage, isEnableProfileDetailsEdit } from "../../../util";
import constants from "../../../constants";

const EducationForm = ({ from, learnerId }) => {
  const axios = useAxiosPrivate();
  const { profileDetails, setProfileDetails, navigate, onSaveProfileDetails } =
    useContext(ProfileContext);
  const { setIsLoaded, setShowNotify,setClickedMenu } = useContext(AlertContext);

  const [class10FocusStates, setClass10FocusStates] = useState(() =>
    Object.keys(profileDetails.education.class10Detail).reduce(
      (acc, key) => ({ ...acc, [key]: false }),
      {}
    )
  );
  const [class12FocusStates, setClass12FocusStates] = useState(() =>
    Object.keys(profileDetails.education.class12Detail).reduce(
      (acc, key) => ({ ...acc, [key]: false }),
      {}
    )
  );
  const [ugFocusStates, setUgFocusStates] = useState(() =>
    Object.keys(profileDetails.education.ugDetail).reduce(
      (acc, key) => ({ ...acc, [key]: false }),
      {}
    )
  );
  const [pgFocusStates, setPgFocusStates] = useState(() =>
    Object.keys(profileDetails.education.pgDetail).reduce(
      (acc, key) => ({ ...acc, [key]: false }),
      {}
    )
  );

  useEffect(() => {
    // const abortController = new AbortController();
    const handleGetEducation = async () => {
      try {
        setIsLoaded(true);

        let id = getDataFromStorage("learnerid");
        if (from === "onpopup") {
          id = learnerId;
        }

        let response = await axios.get(`node/learner/profile/${id}/education`, {
          headers: {
            "Content-Type": "application/json",
          },
          // signal: abortController.signal,
        });
        if (response.data.resultCode === constants.RESULT_STATUS.SUCCESS) {
          let details = response.data.data.result;
          if (details && details !== null) {
            setProfileDetails((prevState) => ({
              ...prevState,
              education: JSON.parse(details),
            }));
          }
        } else
          setShowNotify({
            show: true,
            title: "Warning !",
            msg: response.data.msg,
          });
      } catch (error) {
        if (error.message.includes("403")) {
          setShowNotify({
            show: true,
            title: "Warning !",
            msg: "You have been logged-out due to inactivity. Login again.",
            isUnAuth: true,
          });
        } else
          setShowNotify({
            show: true,
            title: "Error !",
            msg: "Something went wrong. Please try again later or contact gradious team.",
          });
      } finally {
        setIsLoaded(false);
      }
    };
    handleGetEducation();
    // return () => abortController.abort();
  }, [axios, setIsLoaded, setProfileDetails, setShowNotify, from, learnerId]);

  const onChangeDetails = (level, event) => {
    setProfileDetails((prev) => ({
      ...prev,
      education: {
        ...prev.education,
        [level]: {
          ...prev.education[level],
          [event.target.name]: event.target.value,
        },
      },
    }));
  };

  const handleNext = (event) => {
    event.preventDefault();
    if(from ==="onpopup"){
      onSaveProfileDetails("education",false,learnerId);
      setClickedMenu(4);
    }
    else{
      onSaveProfileDetails("",false,learnerId);
      navigate("/profile/academic");
    }
  };
  const handlePrevious = () => {
    if(from ==="onpopup"){
        setClickedMenu(2);
    }
    else{
    navigate("/profile/parentinfo");
    }
  };

  return (
    <React.Fragment>
      <FormTitle
        icon={
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5 10V16.0111C5 16.3701 5 16.5496 5.05465 16.708C5.10299 16.8482 5.18187 16.9758 5.28558 17.0817C5.40287 17.2014 5.5634 17.2817 5.88446 17.4422L11.2845 20.1422C11.5468 20.2734 11.678 20.339 11.8156 20.3648C11.9375 20.3877 12.0625 20.3877 12.1844 20.3648C12.322 20.339 12.4532 20.2734 12.7155 20.1422L18.1155 17.4422C18.4366 17.2817 18.5971 17.2014 18.7144 17.0817C18.8181 16.9758 18.897 16.8482 18.9453 16.708C19 16.5496 19 16.3701 19 16.0111V10M2 8.5L11.6422 3.67889C11.7734 3.6133 11.839 3.5805 11.9078 3.5676C11.9687 3.55616 12.0313 3.55616 12.0922 3.5676C12.161 3.5805 12.2266 3.6133 12.3578 3.67889L22 8.5L12.3578 13.3211C12.2266 13.3867 12.161 13.4195 12.0922 13.4324C12.0313 13.4438 11.9687 13.4438 11.9078 13.4324C11.839 13.4195 11.7734 13.3867 11.6422 13.3211L2 8.5Z"
              stroke="#101828"
              strokeWidth="1.8"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        }
        title="Education"
      />
      <form className="educationForm">
        <div className="educationFormContainer educationFormClass10Container">
          <div className="educationFormSection educationFormClass10Sec">
            <div className="educationFormCourseTitle educationFormClass10Title">
              Class 10
            </div>
            <div className="educationFormCourse educationFormClass10">
              <div className="educationFormCourseFieldSec educationFormCourseNameAndCgpaFieldSec educationFormClass10NameAndCgpaSec">
                <TextField
                  disabled={false}
                  label="School Name"
                  id="educationFormClass10Name"
                  className="educationFormField"
                  name="schoolName"
                  placeholder="School Name"
                  InputLabelProps={{
                    shrink:
                      class10FocusStates["schoolName"] ||
                      Boolean(
                        profileDetails.education.class10Detail["schoolName"]
                      ),
                  }}
                  InputProps={{
                    onFocus: (event) =>
                      setClass10FocusStates({
                        ...class10FocusStates,
                        [event.target.name]: true,
                      }),
                    onBlur: (event) =>
                      setClass10FocusStates({
                        ...class10FocusStates,
                        [event.target.name]: false,
                      }),
                  }}
                  variant="outlined"
                  size="small"
                  value={profileDetails.education.class10Detail.schoolName}
                  onChange={(event) => onChangeDetails("class10Detail", event)}
                  fullWidth
                />
                <TextField
                  disabled={false}
                  label="CGPA"
                  id="educationFormClass10Cgpa"
                  className="educationFormField"
                  name="cgpa"
                  placeholder="CGPA"
                  InputLabelProps={{
                    shrink:
                      class10FocusStates["cgpa"] ||
                      Boolean(profileDetails.education.class10Detail["cgpa"]),
                  }}
                  InputProps={{
                    onFocus: (event) =>
                      setClass10FocusStates({
                        ...class10FocusStates,
                        [event.target.name]: true,
                      }),
                    onBlur: (event) =>
                      setClass10FocusStates({
                        ...class10FocusStates,
                        [event.target.name]: false,
                      }),
                  }}
                  variant="outlined"
                  size="small"
                  value={profileDetails.education.class10Detail.cgpa}
                  onChange={(event) => onChangeDetails("class10Detail", event)}
                />
              </div>
              <div className="educationFormCourseFieldSec educationFormCourseDateFieldSec educationFormClass10DateSec">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DesktopDatePicker
                    disabled={false}
                    label="Start Date"
                    id="educationFormClass10Startdate"
                    className="educationFormField"
                    name="startdate"
                    value={
                      profileDetails.education.class10Detail.startdate
                        ? dayjs(
                            profileDetails.education.class10Detail.startdate
                          )
                        : null
                    }
                    minDate={dayjs("1900-01-01")}
                    maxDate={dayjs()}
                    onChange={(value) =>
                      onChangeDetails("class10Detail", {
                        target: { name: "startdate", value },
                      })
                    }
                  />
                </LocalizationProvider>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DesktopDatePicker
                    disabled={false}
                    label="End Date"
                    id="educationFormClass10Enddate"
                    className="educationFormField"
                    name="enddate"
                    value={
                      profileDetails.education.class10Detail.enddate
                        ? dayjs(profileDetails.education.class10Detail.enddate)
                        : null
                    }
                    minDate={dayjs(
                      profileDetails.education.class10Detail.startdate
                    )}
                    // maxDate={dayjs()}
                    onChange={(value) =>
                      onChangeDetails("class10Detail", {
                        target: { name: "enddate", value },
                      })
                    }
                  />
                </LocalizationProvider>
              </div>
            </div>
          </div>
        </div>
        <Divider className="educationFormDivider" />
        <div className="educationFormContainer educationFormClass12Container">
          <div className="educationFormSection educationFormClass10Sec">
            <div className="educationFormCourseTitle educationFormClass10Title">
              Class 12 / Diploma
            </div>
            <div className="educationFormCourse educationFormClass10">
              <div className="educationFormCourseFieldSec educationFormCourseNameAndCgpaFieldSec educationFormClass10NameAndCgpaSec">
                <TextField
                  disabled={false}
                  label="Institution Name"
                  id="educationFormClass12Name"
                  name="schoolName"
                  className="educationFormField"
                  placeholder="Institution Name"
                  variant="outlined"
                  InputLabelProps={{
                    shrink:
                      class12FocusStates["schoolName"] ||
                      Boolean(
                        profileDetails.education.class12Detail["schoolName"]
                      ),
                  }}
                  InputProps={{
                    onFocus: (event) =>
                      setClass12FocusStates({
                        ...class12FocusStates,
                        [event.target.name]: true,
                      }),
                    onBlur: (event) =>
                      setClass12FocusStates({
                        ...class12FocusStates,
                        [event.target.name]: false,
                      }),
                  }}
                  size="small"
                  value={profileDetails.education.class12Detail.schoolName}
                  onChange={(event) => onChangeDetails("class12Detail", event)}
                  fullWidth
                />
                <TextField
                  disabled={false}
                  label="CGPA"
                  id="educationFormClass12Cgpa"
                  name="cgpa"
                  className="educationFormField"
                  placeholder="CGPA"
                  variant="outlined"
                  size="small"
                  value={profileDetails.education.class12Detail.cgpa}
                  InputLabelProps={{
                    shrink:
                      class12FocusStates["cgpa"] ||
                      Boolean(profileDetails.education.class12Detail["cgpa"]),
                  }}
                  InputProps={{
                    onFocus: (event) =>
                      setClass12FocusStates({
                        ...class12FocusStates,
                        [event.target.name]: true,
                      }),
                    onBlur: (event) =>
                      setClass12FocusStates({
                        ...class12FocusStates,
                        [event.target.name]: false,
                      }),
                  }}
                  onChange={(event) => onChangeDetails("class12Detail", event)}
                />
              </div>
              <div className="educationFormCourseFieldSec educationFormClass12BranchSec">
                <TextField
                  disabled={false}
                  label="Branch"
                  id="educationFormClass12Branch"
                  name="branch"
                  className="educationFormField"
                  placeholder="Branch"
                  variant="outlined"
                  fullWidth
                  size="small"
                  value={profileDetails.education.class12Detail.branch}
                  InputLabelProps={{
                    shrink:
                      class12FocusStates["branch"] ||
                      Boolean(profileDetails.education.class12Detail["branch"]),
                  }}
                  InputProps={{
                    onFocus: (event) =>
                      setClass12FocusStates({
                        ...class12FocusStates,
                        [event.target.name]: true,
                      }),
                    onBlur: (event) =>
                      setClass12FocusStates({
                        ...class12FocusStates,
                        [event.target.name]: false,
                      }),
                  }}
                  onChange={(event) => onChangeDetails("class12Detail", event)}
                />
              </div>
              <div className="educationFormCourseFieldSec educationFormCourseDateFieldSec educationFormClass12DateSec">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DesktopDatePicker
                    disabled={false}
                    label="Start Date"
                    id="educationFormClass12Startdate"
                    name="startdate"
                    className="educationFormField"
                    value={
                      profileDetails.education.class12Detail.startdate
                        ? dayjs(
                            profileDetails.education.class12Detail.startdate
                          )
                        : null
                    }
                    minDate={dayjs("1900-01-01")}
                    maxDate={dayjs()}
                    onChange={(value) =>
                      onChangeDetails("class12Detail", {
                        target: { name: "startdate", value },
                      })
                    }
                  />
                </LocalizationProvider>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DesktopDatePicker
                    disabled={false}
                    label="End Date"
                    id="educationFormClass12Enddate"
                    name="enddate"
                    className="educationFormField"
                    value={
                      profileDetails.education.class12Detail.enddate
                        ? dayjs(profileDetails.education.class12Detail.enddate)
                        : null
                    }
                    minDate={dayjs(
                      profileDetails.education.class12Detail.startdate
                    )}
                    // maxDate={dayjs()}
                    onChange={(value) =>
                      onChangeDetails("class12Detail", {
                        target: { name: "enddate", value },
                      })
                    }
                  />
                </LocalizationProvider>
              </div>
              <div>
                <Checkbox
                  disabled={false}
                  checked={profileDetails.education.class12Detail.isDiploma}
                  size="sm"
                  text="I have done diploma"
                  indeterminate={false}
                  onChange={(checked) =>
                    onChangeDetails("class12Detail", {
                      target: { name: "isDiploma", value: checked },
                    })
                  }
                />
              </div>
            </div>
          </div>
        </div>
        <Divider className="educationFormDivider" />
        <div className="educationFormContainer educationFormUgContainer">
          <div className="educationFormSection educationFormUgSec">
            <div className="educationFormCourseTitle educationFormUgTitle">
              Undergraduate Degree
            </div>
            <div className="educationFormCourse educationFormUg">
              <div className="educationFormCourseFieldSec educationFormCourseNameAndCgpaFieldSec educationFormUgNameAndCgpaSec">
                <TextField
                  disabled={false}
                  label="College Name "
                  id="educationFormUgName"
                  name="schoolName"
                  placeholder="College Name"
                  variant="outlined"
                  size="small"
                  InputLabelProps={{
                    shrink:
                      ugFocusStates["schoolName"] ||
                      Boolean(profileDetails.education.ugDetail["schoolName"]),
                  }}
                  InputProps={{
                    onFocus: (event) =>
                      setUgFocusStates({
                        ...ugFocusStates,
                        [event.target.name]: true,
                      }),
                    onBlur: (event) =>
                      setUgFocusStates({
                        ...ugFocusStates,
                        [event.target.name]: false,
                      }),
                  }}
                  className="educationFormField"
                  value={profileDetails.education.ugDetail.schoolName}
                  onChange={(event) => onChangeDetails("ugDetail", event)}
                  fullWidth
                />
                <TextField
                  disabled={false}
                  label="CGPA"
                  id="educationFormUgCgpa"
                  name="cgpa"
                  className="educationFormField"
                  placeholder="CGPA"
                  variant="outlined"
                  InputLabelProps={{
                    shrink:
                      ugFocusStates["cgpa"] ||
                      Boolean(profileDetails.education.ugDetail["cgpa"]),
                  }}
                  InputProps={{
                    onFocus: (event) =>
                      setUgFocusStates({
                        ...ugFocusStates,
                        [event.target.name]: true,
                      }),
                    onBlur: (event) =>
                      setUgFocusStates({
                        ...ugFocusStates,
                        [event.target.name]: false,
                      }),
                  }}
                  size="small"
                  value={profileDetails.education.ugDetail.cgpa}
                  onChange={(event) => onChangeDetails("ugDetail", event)}
                />
              </div>
              <div className="educationFormCourseFieldSec educationFormUgBranchSec">
                <TextField
                  disabled={false}
                  InputLabelProps={{
                    shrink:
                      ugFocusStates["branch"] ||
                      Boolean(profileDetails.education.ugDetail["branch"]),
                  }}
                  InputProps={{
                    onFocus: (event) =>
                      setUgFocusStates({
                        ...ugFocusStates,
                        [event.target.name]: true,
                      }),
                    onBlur: (event) =>
                      setUgFocusStates({
                        ...ugFocusStates,
                        [event.target.name]: false,
                      }),
                  }}
                  label="Branch"
                  id="educationFormUgBranch"
                  name="branch"
                  className="educationFormField"
                  placeholder="Branch"
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={profileDetails.education.ugDetail.branch}
                  onChange={(event) => onChangeDetails("ugDetail", event)}
                />
              </div>
              <div className="educationFormCourseFieldSec educationFormCourseDateFieldSec educationFormUgDateSec">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DesktopDatePicker
                    disabled={false}
                    label="Start Date"
                    className="educationFormField"
                    id="educationFormUgStartdate"
                    name="startdate"
                    value={
                      profileDetails.education.ugDetail.startdate
                        ? dayjs(profileDetails.education.ugDetail.startdate)
                        : null
                    }
                    minDate={dayjs("1900-01-01")}
                    maxDate={dayjs()}
                    onChange={(value) =>
                      onChangeDetails("ugDetail", {
                        target: { name: "startdate", value },
                      })
                    }
                  />
                </LocalizationProvider>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DesktopDatePicker
                    disabled={false}
                    label="End Date"
                    id="educationFormUgEnddate"
                    className="educationFormField"
                    name="enddate"
                    value={
                      profileDetails.education.ugDetail.enddate
                        ? dayjs(profileDetails.education.ugDetail.enddate)
                        : null
                    }
                    minDate={dayjs(profileDetails.education.ugDetail.startdate)}
                    // maxDate={dayjs()}
                    onChange={(value) =>
                      onChangeDetails("ugDetail", {
                        target: { name: "enddate", value },
                      })
                    }
                  />
                </LocalizationProvider>
              </div>
            </div>
          </div>
        </div>
        <Divider className="educationFormDivider" />
        <div className="educationFormContainer educationFormPgContainer">
          <div className="educationFormSection educationFormPgSec">
            <div className="educationFormCourseTitle educationFormPgTitle">
              Postgraduate Degree
            </div>
            <div className="educationFormCourse educationFormPg">
              <div className="educationFormCourseFieldSec educationFormCourseNameAndCgpaFieldSec educationFormPgNameAndCgpaSec">
                <TextField
                  disabled={false}
                  InputLabelProps={{
                    shrink:
                      pgFocusStates["schoolName"] ||
                      Boolean(profileDetails.education.pgDetail["schoolName"]),
                  }}
                  InputProps={{
                    onFocus: (event) =>
                      setPgFocusStates({
                        ...pgFocusStates,
                        [event.target.name]: true,
                      }),
                    onBlur: (event) =>
                      setPgFocusStates({
                        ...pgFocusStates,
                        [event.target.name]: false,
                      }),
                  }}
                  label="College Name"
                  id="educationFormPgName"
                  name="schoolName"
                  placeholder="College Name"
                  className="educationFormField"
                  variant="outlined"
                  size="small"
                  value={profileDetails.education.pgDetail.schoolName}
                  onChange={(event) => onChangeDetails("pgDetail", event)}
                  fullWidth
                />
                <TextField
                  disabled={false}
                  InputLabelProps={{
                    shrink:
                      pgFocusStates["cgpa"] ||
                      Boolean(profileDetails.education.pgDetail["cgpa"]),
                  }}
                  InputProps={{
                    onFocus: (event) =>
                      setPgFocusStates({
                        ...pgFocusStates,
                        [event.target.name]: true,
                      }),
                    onBlur: (event) =>
                      setPgFocusStates({
                        ...pgFocusStates,
                        [event.target.name]: false,
                      }),
                  }}
                  label="CGPA"
                  id="educationFormPgCgpa"
                  name="cgpa"
                  className="educationFormField"
                  placeholder="CGPA"
                  variant="outlined"
                  size="small"
                  value={profileDetails.education.pgDetail.cgpa}
                  onChange={(event) => onChangeDetails("pgDetail", event)}
                />
              </div>
              <div className="educationFormCourseFieldSec educationFormPgBranchSec">
                <TextField
                  disabled={false}
                  InputLabelProps={{
                    shrink:
                      pgFocusStates["branch"] ||
                      Boolean(profileDetails.education.pgDetail["branch"]),
                  }}
                  InputProps={{
                    onFocus: (event) =>
                      setPgFocusStates({
                        ...pgFocusStates,
                        [event.target.name]: true,
                      }),
                    onBlur: (event) =>
                      setPgFocusStates({
                        ...pgFocusStates,
                        [event.target.name]: false,
                      }),
                  }}
                  label="Branch"
                  id="educationFormPgBranch"
                  name="branch"
                  fullWidth
                  className="educationFormField"
                  placeholder="Branch"
                  variant="outlined"
                  size="small"
                  value={profileDetails.education.pgDetail.branch}
                  onChange={(event) => onChangeDetails("pgDetail", event)}
                />
              </div>
              <div className="educationFormCourseFieldSec educationFormCourseDateFieldSec educationFormPgDateSec">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DesktopDatePicker
                    disabled={false}
                    label="Start Date"
                    className="educationFormField"
                    id="educationFormPgStartdate"
                    name="startdate"
                    value={
                      profileDetails.education.pgDetail.startdate
                        ? dayjs(profileDetails.education.pgDetail.startdate)
                        : null
                    }
                    minDate={dayjs("1900-01-01")}
                    maxDate={dayjs()}
                    onChange={(value) =>
                      onChangeDetails("pgDetail", {
                        target: { name: "startdate", value },
                      })
                    }
                  />
                </LocalizationProvider>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DesktopDatePicker
                    disabled={false}
                    label="End Date"
                    className="educationFormField"
                    id="educationFormPgEnddate"
                    name="enddate"
                    value={
                      profileDetails.education.pgDetail.enddate
                        ? dayjs(profileDetails.education.pgDetail.enddate)
                        : null
                    }
                    minDate={dayjs(profileDetails.education.pgDetail.startdate)}
                    // maxDate={dayjs()}
                    onChange={(value) =>
                      onChangeDetails("pgDetail", {
                        target: { name: "enddate", value },
                      })
                    }
                  />
                </LocalizationProvider>
              </div>
            </div>
          </div>
        </div>
        {isEnableProfileDetailsEdit && (
          <div className="educationFormBtnSec">
            <Button
              onClick={handlePrevious}
              size="sm"
              hierarchy={{ type: "secondaryGrey", buttonText: "Previous" }}
            />
            <Button
              onClick={handleNext}
              size="sm"
              hierarchy={{ type: "primary", buttonText: "Next" }}
            />
          </div>
        )}
      </form>
    </React.Fragment>
  );
};

export default EducationForm;
