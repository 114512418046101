import React from 'react'

export default function ProctorIconsvg() {
  return (
    <div>
        <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="44" height="44" rx="12" fill="#FBEFED"/>
            <path d="M12.8334 22.0007C12.8334 22.0007 16.1667 15.334 22 15.334C27.8334 15.334 31.1667 22.0007 31.1667 22.0007C31.1667 22.0007 27.8334 28.6673 22 28.6673C16.1667 28.6673 12.8334 22.0007 12.8334 22.0007Z" stroke="#667085" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M22 24.5007C23.3808 24.5007 24.5 23.3814 24.5 22.0007C24.5 20.6199 23.3808 19.5007 22 19.5007C20.6193 19.5007 19.5 20.6199 19.5 22.0007C19.5 23.3814 20.6193 24.5007 22 24.5007Z" stroke="#667085" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    </div>
  )
}
