import React from "react";
import FormTitle from "../../../Student/Profile/FormTitle";
import Proptypes from "prop-types";

const SettingsRightHeader = ({ button, title, titleIcon }) => {
  return (
    <div className="settingsRightHeadSec">
      <FormTitle icon={titleIcon} title={title} />
      {button}
    </div>
  );
};

export default SettingsRightHeader;

SettingsRightHeader.propTypes = {
  button: Proptypes.node,
  title: Proptypes.oneOfType([Proptypes.string, Proptypes.node]).isRequired,
  titleIcon: Proptypes.node,
};

SettingsRightHeader.defaultProps = {
  button: null,
  title: "Title",
  titleIcon: null,
};
