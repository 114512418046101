import Select from "react-select";
import AceEditor from "react-ace";
import Button from "../../Button";
import constants from "../../../constants";
import PlayIconSvg from "../../../Svg/PlayIconSvg";
import React, { useContext, useEffect } from "react";
import ThemeSvgIcon from "../../../Svg/ThemeSvgIcon";
import ResetSvgIcon from "../../../Svg/ResetSvgIcon";
import AlertContext from "../../../context/AlertContext";
import FullScreenSvgIcon from "../../../Svg/FullScreenSvgIcon";
import CodingEditorHooks from "../../../Hooks/CodingPage/CodingEditorHooks";
import CodingEditorRunButtonHooks from "../../../Hooks/CodingPage/CodingEditorRunButtonHooks";

export default function CodingEditor() {
  const Alert = useContext(AlertContext);

  const CodingEditorHks = CodingEditorHooks();
  const CodingEditorRunButtonHks = CodingEditorRunButtonHooks();

  useEffect(() => {
    if (Alert.callAllTestCases.current && Alert.makeRunTestCase) {
      CodingEditorRunButtonHks.handleRunAllTestCase();
      Alert.callAllTestCases.current = false;
      Alert.setMakeRunTestCase(false);
    }
  }, [Alert.makeRunTestCase, Alert.callAllTestCases.current]);

  return (
    <div
      ref={CodingEditorRunButtonHks.editorContainerRef}
      className={
        !Alert.isFullScreen
          ? "codingEditorMainContainer"
          : "codingEditorMainContainerFullScreen"
      }
    >
      <div className="codingEditorHeader">
        <div className="codingEditorLanguage">
          <Select
            isSearchable={false}
            options={Alert.language}
            value={Alert.selectedLanguage}
            styles={CodingEditorHks.customStyle}
            onChange={CodingEditorHks.handleChangeLanguage}
            isDisabled={CodingEditorHks.handleDisableLanguage()}
            components={{
              IndicatorSeparator: () => null,
            }}
          />
        </div>
        <div className="codingEditorHeaderRightDiv">
          <ResetSvgIcon onClick={CodingEditorHks.handleResetLearnerCode} />
          <ThemeSvgIcon />
          {!Alert.isAdmin && (
            <FullScreenSvgIcon
              onClick={() =>
                CodingEditorRunButtonHks.handleFullScreenMode(
                  CodingEditorRunButtonHks.editorContainerRef.current
                )
              }
            />
          )}
        </div>
      </div>
      <div>
        <AceEditor
          width="100%"
          maxHeight="93vh"
          wrapEnabled={true}
          showPrintMargin={false}
          className="codingEditor"
          theme={Alert.editorTheme}
          value={Alert.learnerCode}
          highlightActiveLine={true}
          mode={CodingEditorHks.editorLanguage}
          editorProps={{ $blockScrolling: false }}
          onChange={CodingEditorHks.handleLearnerCode}
          commands={[
            {
              // commands is array of key bindings.

              name: "pastline", //name for the key binding.
              bindKey: !Alert.isAdmin && { win: "Ctrl-V", mac: "Command-V" }, //key combination used for the command.
              exec: function (editor) {
                const editorEvents = [
                  "drop",
                  "dragend",
                  "dragover",
                  "dragenter",
                  "dragstart",
                  "dragleave",
                ];
                for (const events of editorEvents) {
                  editor.selection.getCursor();
                  editor.container.addEventListener(
                    events,
                    function (e) {
                      e.stopPropagation();
                    },
                    true
                  );
                }
              },
            },
          ]}
          setOptions={{
            tabSize: 4,
            fontSize: 16,
            enableSnippets: true,
            showLineNumbers: true,
            wrapBehavioursEnabled: true,
            autoScrollEditorIntoView: true,
            enableLiveAutocompletion: false,
            enableBasicAutocompletion: true,
          }}
        />
        {Alert.codeDetails.istestcases === constants.ISNOTESTCASES.FALSE && (
          <div className="codingEditorButtonDiv">
            <Button
              size={"sm"}
              onClick={CodingEditorRunButtonHks.handleRunAllTestCase}
              icon={{ type: "leading", icon: <PlayIconSvg /> }}
              hierarchy={{
                type: "primary",
                buttonText: "Try Code",
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
}
