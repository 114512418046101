import React from "react";
import constants from "../constants";

export default function WarningSvgIcon(Props) {
  return (
    <div
      className={
        Props.component === "assignmentSubmission"
          ? "warningSvgIconForAssignmentSubmission"
          : Props.component === "codingTable"
          ? "warningSvgIconForCodingTable"
          : "warningSvgIcon"
      }
    >
      {Props.due !== "Past Due" &&
      ((Props.component === "codingTable" &&
        Props.status === constants.PROGRESS_STATUS.IN_PROGRESS) ||
        Props.due === "Due Today") ? (
        <svg
          width="16"
          height="16"
          fill="none"
          viewBox="0 0 16 16"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke={Props.component === "codingTable" ? "#D92D20" : "#F04438"}
            d="M7.99992 5.33334V8M7.99992 10.6667H8.00659M14.6666 8C14.6666 11.6819 11.6818 14.6667 7.99992 14.6667C4.31802 14.6667 1.33325 11.6819 1.33325 8C1.33325 4.3181 4.31802 1.33334 7.99992 1.33334C11.6818 1.33334 14.6666 4.3181 14.6666 8Z"
          />
        </svg>
      ) : (Props.due !== "Past Due" &&
          Props.status === constants.PROGRESS_STATUS.IN_PROGRESS) ||
        Props.component === "assignmentSubmission" ? (
        <svg
          width="16"
          height="16"
          fill="none"
          viewBox="0 0 16 16"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill={Props.component !== "assignmentSubmission" && "#FDB022"}
            d={
              Props.component === "assignmentSubmission"
                ? "M7.99982 6.00015V8.66682M7.99982 11.3335H8.00648M7.0767 2.59464L1.59344 12.0657C1.2893 12.591 1.13723 12.8537 1.15971 13.0693C1.17931 13.2573 1.27783 13.4282 1.43073 13.5394C1.60604 13.6668 1.90954 13.6668 2.51656 13.6668H13.4831C14.0901 13.6668 14.3936 13.6668 14.5689 13.5394C14.7218 13.4282 14.8203 13.2573 14.8399 13.0693C14.8624 12.8537 14.7103 12.591 14.4062 12.0657L8.92294 2.59463C8.61989 2.07119 8.46837 1.80947 8.27068 1.72157C8.09824 1.64489 7.90139 1.64489 7.72895 1.72157C7.53126 1.80947 7.37974 2.07119 7.0767 2.59464Z"
                : "M1.59362 12.0656L7.07688 2.59448C7.37992 2.07103 7.53145 1.80931 7.72913 1.72141C7.90157 1.64473 8.09843 1.64473 8.27087 1.72141C8.46855 1.80931 8.62007 2.07103 8.92312 2.59447L14.4064 12.0656C14.7105 12.5909 14.8626 12.8535 14.8401 13.0691C14.8205 13.2572 14.722 13.428 14.5691 13.5392C14.3938 13.6667 14.0903 13.6667 13.4833 13.6667H2.51674C1.90973 13.6667 1.60622 13.6667 1.43091 13.5392C1.27801 13.428 1.1795 13.2572 1.15989 13.0691C1.13742 12.8535 1.28948 12.5909 1.59362 12.0656Z"
            }
          />
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke={
              Props.component === "assignmentSubmission" ? "#F04438" : "black"
            }
            stroke-width={
              Props.component === "assignmentSubmission" ? "1.33333" : "1.4"
            }
            d={
              Props.component === "assignmentSubmission"
                ? "M7.99982 6.00015V8.66682M7.99982 11.3335H8.00648M7.0767 2.59464L1.59344 12.0657C1.2893 12.591 1.13723 12.8537 1.15971 13.0693C1.17931 13.2573 1.27783 13.4282 1.43073 13.5394C1.60604 13.6668 1.90954 13.6668 2.51656 13.6668H13.4831C14.0901 13.6668 14.3936 13.6668 14.5689 13.5394C14.7218 13.4282 14.8203 13.2573 14.8399 13.0693C14.8624 12.8537 14.7103 12.591 14.4062 12.0657L8.92294 2.59463C8.61989 2.07119 8.46837 1.80947 8.27068 1.72157C8.09824 1.64489 7.90139 1.64489 7.72895 1.72157C7.53126 1.80947 7.37974 2.07119 7.0767 2.59464Z"
                : "M8 5.99999V8.66666M8 11.3333H8.00667M7.07688 2.59448L1.59362 12.0656C1.28948 12.5909 1.13742 12.8535 1.15989 13.0691C1.1795 13.2572 1.27801 13.428 1.43091 13.5392C1.60622 13.6667 1.90973 13.6667 2.51674 13.6667H13.4833C14.0903 13.6667 14.3938 13.6667 14.5691 13.5392C14.722 13.428 14.8205 13.2572 14.8401 13.0691C14.8626 12.8535 14.7105 12.5909 14.4064 12.0656L8.92312 2.59447C8.62007 2.07103 8.46855 1.80931 8.27087 1.72141C8.09843 1.64473 7.90157 1.64473 7.72913 1.72141C7.53145 1.80931 7.37992 2.07103 7.07688 2.59448Z"
            }
          />
        </svg>
      ) : (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.99982 6.00015V8.66682M7.99982 11.3335H8.00648M7.0767 2.59464L1.59344 12.0657C1.2893 12.591 1.13723 12.8537 1.15971 13.0693C1.17931 13.2573 1.27783 13.4282 1.43073 13.5394C1.60604 13.6668 1.90954 13.6668 2.51656 13.6668H13.4831C14.0901 13.6668 14.3936 13.6668 14.5689 13.5394C14.7218 13.4282 14.8203 13.2573 14.8399 13.0693C14.8624 12.8537 14.7103 12.591 14.4062 12.0657L8.92294 2.59463C8.61989 2.07119 8.46837 1.80947 8.27068 1.72157C8.09824 1.64489 7.90139 1.64489 7.72895 1.72157C7.53126 1.80947 7.37974 2.07119 7.0767 2.59464Z"
            stroke="#F04438"
            stroke-width="1.33333"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      )}
    </div>
  );
}
