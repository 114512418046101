import React, { useContext } from "react";
import IconButton from "@mui/material/IconButton";
import AlertContext from "../../../context/AlertContext";
import { StudentTestPageContext } from "../../../context/StudentTestPageContext";

export default function StudentTestQuestionBox(Props) {
  const Alert = useContext(AlertContext);
  const StudentTestPageCxt = useContext(StudentTestPageContext);

  let style = { background: "#F2F4F7", color: "#344054" };

  const getPreVisitColor = (previsit) => {
    if (previsit === "this") {
      return { background: "#F2F4F7", color: "#344054" };
    } else if (previsit === "not") {
      return {
        color: "#FFFFFF",
        background:
          "linear-gradient(220.31deg, #FDC622 15.37%, #9B8A36 92.13%)",
      };
    } else if (previsit === "seen") {
      return {
        color: "#FFFFFF",
        background:
          "linear-gradient(220.31deg, #FDC622 15.37%, #9B8A36 92.13%)",
      };
    } else if (previsit === "review") {
      return {
        color: "#FFFFFF",
        background:
          "linear-gradient(223.96deg, #FB6514 15.05%, #95670C 83.82%)",
      };
    } else if (previsit === "completed") {
      return {
        color: "#FFFFFF",
        background: "linear-gradient(45deg, #1FA064 0%, #01E078 100%)",
      };
    } else if (previsit === "visited") {
    }
  };

  if (Props.visited === "this") {
    !Props.hasOwnProperty("quedivid")
      ? getPreVisitColor(Props.previsited)
      : (style = getPreVisitColor(Props.previsited));
  } else if (Props.visited === "completed") {
    !Props.hasOwnProperty("quedivid")
      ? (style = {
          color: "#FFFFFF",
          background: "linear-gradient(45deg, #1FA064 0%, #01E078 100%)",
        })
      : (style = {
          color: "#FFFFFF",
          background: "linear-gradient(45deg, #1FA064 0%, #01E078 100%)",
        });
  } else if (Props.visited === "not") {
    !Props.hasOwnProperty("quedivid")
      ? (style = { background: "#F2F4F7", color: "#344054" })
      : (style = getPreVisitColor(Props.previsited));
  } else if (Props.visited === "seen") {
    style = {
      color: "#FFFFFF",
      background: "linear-gradient(220.31deg, #FDC622 15.37%, #9B8A36 92.13%)",
    };
  } else if (Props.visited === "review") {
    style = {
      color: "#FFFFFF",
      background: "linear-gradient(223.96deg, #FB6514 15.05%, #95670C 83.82%)",
    };
  }

  if (Props.review === 0) {
    if (Props.visited !== "this") {
      style = {
        color: "#FFFFFF",
        background:
          "linear-gradient(223.96deg, #FB6514 15.05%, #95670C 83.82%)",
      };
    }
  }

  return (
    <div
      className={
        !Props.hasOwnProperty("quedivid") &&
        Alert.queInd === Props.index &&
        Props.section === Alert.secInd
          ? "question-Box-Show-This-Yes"
          : "question-Box-Show-This-No"
      }
    >
      <IconButton
        className="mcq-Question-Number-Box"
        key={Props.section + "" + Props.index}
        onClick={() => {
          if (
            Props.hasOwnProperty("quedivid") &&
            !Alert.questionContainerSingle
          ) {
            Props.multiTraverse(Props.quedivid, Props.section, Props.index);
          } else {
            Props.handleClick(Props.section, Props.index, Alert.comingObj);
          }
        }}
        style={{
          background:
            Alert.questionContainerSingle &&
            Alert.queInd === Props.index &&
            Props.section === Alert.secInd
              ? "#F2F4F7"
              : style.background,
          color:
            Alert.questionContainerSingle &&
            Alert.queInd === Props.index &&
            Props.section === Alert.secInd
              ? "#344054"
              : style.color,
          border:
            Alert.questionContainerSingle &&
            Alert.queInd === Props.index &&
            Props.section === Alert.secInd
              ? "2px solid #F96343"
              : "none",
        }}
      >
        <span>{Props.value}</span>
      </IconButton>
    </div>
  );
}
