import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";
import IconButton from "./IconButton";
import Tooltip from "./Tooltip";

export default function Collapse({
  title,
  children,
  open,
  onToggle,
  isDeletable,
  onDelete,
}) {
  const contentRef = useRef(null);

  useEffect(() => {
    if (open && contentRef.current) {
      contentRef.current.style.maxHeight = `${contentRef.current.scrollHeight}px`;
    } else if (contentRef.current) {
      contentRef.current.style.maxHeight = "0px";
    }
  }, [open]);

  return (
    <div className={`collapseContainer ${open ? "open" : ""}`}>
      <div className={`collapseHeader`} onClick={onToggle}>
        <div className="collapseTitle">{title}</div>
        <div className="collapseRightContainer">
          {isDeletable && (
            <Tooltip text="Delete" position="bottomCenter">
              <IconButton size="sm" hierarchy="tertiaryGrey" onClick={onDelete}>
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.5 4.99996H4.16667M4.16667 4.99996H17.5M4.16667 4.99996V16.6666C4.16667 17.1087 4.34226 17.5326 4.65482 17.8451C4.96738 18.1577 5.39131 18.3333 5.83333 18.3333H14.1667C14.6087 18.3333 15.0326 18.1577 15.3452 17.8451C15.6577 17.5326 15.8333 17.1087 15.8333 16.6666V4.99996H4.16667ZM6.66667 4.99996V3.33329C6.66667 2.89127 6.84226 2.46734 7.15482 2.15478C7.46738 1.84222 7.89131 1.66663 8.33333 1.66663H11.6667C12.1087 1.66663 12.5326 1.84222 12.8452 2.15478C13.1577 2.46734 13.3333 2.89127 13.3333 3.33329V4.99996M8.33333 9.16663V14.1666M11.6667 9.16663V14.1666"
                    stroke="#667085"
                    strokeWidth="1.66667"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </IconButton>
            </Tooltip>
          )}
          <IconButton size="sm" hierarchy="tertiaryGrey">
            {open ? (
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15 12.5L10 7.5L5 12.5"
                  stroke="#667085"
                  strokeWidth="1.66667"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            ) : (
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5 7.5L10 12.5L15 7.5"
                  stroke="#667085"
                  strokeWidth="1.66667"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            )}
          </IconButton>
        </div>
      </div>
      <div className={`collapseContent ${open ? "open" : ""}`} ref={contentRef}>
        {children}
      </div>
    </div>
  );
}

Collapse.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  children: PropTypes.node.isRequired,
  open: PropTypes.bool,
  onToggle: PropTypes.func,
  isDeletable: PropTypes.bool,
  onDelete: PropTypes.func,
};

Collapse.defaultProps = {
  title: "Title",
  children: null,
  open: false,
  isDeletable: false,
};
