import React from 'react'

export default function LeetcodeProfileSvg() {
  return (
    <svg width="86" height="86" viewBox="0 0 86 86" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_dd_7312_25841)">
        <rect x="3" y="2" width="80" height="80" rx="10" fill="#FBF5F3"/>
        <path d="M57 57.75V54.25C57 52.3935 56.2625 50.613 54.9497 49.3003C53.637 47.9875 51.8565 47.25 50 47.25H36C34.1435 47.25 32.363 47.9875 31.0503 49.3003C29.7375 50.613 29 52.3935 29 54.25V57.75M50 33.25C50 37.116 46.866 40.25 43 40.25C39.134 40.25 36 37.116 36 33.25C36 29.384 39.134 26.25 43 26.25C46.866 26.25 50 29.384 50 33.25Z" stroke="#F96343" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
        </g>
        <defs>
        <filter id="filter0_dd_7312_25841" x="0" y="0" width="86" height="86" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="1"/>
        <feGaussianBlur stdDeviation="1"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.06 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_7312_25841"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="1"/>
        <feGaussianBlur stdDeviation="1.5"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.1 0"/>
        <feBlend mode="normal" in2="effect1_dropShadow_7312_25841" result="effect2_dropShadow_7312_25841"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_7312_25841" result="shape"/>
        </filter>
        </defs>
    </svg>
  )
}
