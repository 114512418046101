import constants from "../../constants";
import { changeFavicon } from "../../util";
import Tooltip from "@mui/material/Tooltip";
import { useLocation } from "react-router-dom";
import { getDataFromStorage } from "../../util";
import CheckIcon from "@mui/icons-material/Check";
import React, { useContext, useState } from "react";
import AlertContext from "../../context/AlertContext";
import { Select, MenuItem, ListItemIcon } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

export default function BatchDropDown(props) {
  const Alert = useContext(AlertContext);

  const { pathname } = useLocation();
  const [selectedOption, setSelectedOption] = useState("");

  /* for get specific selected batchname */

  const selectedBatch = props.batchdetails?.find(
    (item) => item.id == props.batchId
  );

  const handleChange = (event) => {
    if (event.target.value === "" || event.target.value === undefined) {
      Alert.navigate("batch");
      props.setBatchId(0);
      props.setNavhome(false);
      localStorage.removeItem("batchid");
      localStorage.removeItem("batchname");
    } else {
      setSelectedOption(event.target.value);

      /* for get specific selected batchid */

      const selectedBatchId = props.batchdetails.find(
        (item) => item.name === event.target.value
      );

      props.setBatchId(selectedBatchId.id);

      localStorage.setItem("logo", selectedBatchId.logo);
      localStorage.setItem("batchid", selectedBatchId.id);
      localStorage.setItem("batchType", selectedBatchId.type);
      localStorage.setItem("batchname", selectedBatchId.name);
      localStorage.setItem("favicon", selectedBatchId.favicon);
      if (
        selectedBatchId.favicon &&
        selectedBatchId.favicon !== "" &&
        selectedBatchId.favicon !== "null"
      ) {
        changeFavicon(selectedBatchId.favicon);
      }

      if (
        selectedBatchId.logo &&
        selectedBatchId.logo !== "" &&
        selectedBatchId.logo !== "null"
      ) {
        let element = document.getElementsByClassName(
          "headerDivLogoInnerDiv"
        )[0];

        element.innerHTML = ` <div className="companyLogoContainer">
            <img
            alt="logo"
            height= 28px
            width= 28.15px
            className="companyLogo"
            src=${selectedBatchId.logo}
            />
            <div
              className= "companyLogoText" 
            >
              Powered by Gradious
            </div>`;
      }
    }
  };

  // useEffect(() => {
  //   let from =
  //     props.option == 2
  //       ? "optionsscore"
  //       : props.option == 3
  //       ? "optionstimespent"
  //       : null;
  //   if (from != null) {
  //     props.handleLeaderboardDetail(from);
  //   }
  // }, [props.batchId]);

  return (
    <div className="dashboard-batch-dropdown-div">
      <div className="naviagtorfortestscorestimespent">
        <div className="testscorestimespentcontainer">
          <span
            onClick={() => {
              props.setOption(1);
              Alert.navigate("landingpage");
            }}
            className={
              pathname === "/landingpage"
                ? "optionsfornavigatecurrent"
                : "optionsfornavigate"
            }
          >
            <span
              className={
                pathname === "/landingpage"
                  ? "optiontextfornavigatecurrent"
                  : "optiontextfornavigate"
              }
            >
              Overview
            </span>
          </span>
          <span
            onClick={() => {
              Alert.navigate("landingpage/testresults");
            }}
            className={
              pathname.includes("testresults")
                ? "optionsfornavigatecurrent"
                : "optionsfornavigate"
            }
          >
            <span
              className={
                pathname.includes("testresults")
                  ? "optiontextfornavigatecurrent"
                  : "optiontextfornavigate"
              }
            >
              Test Results
            </span>
          </span>
          <span
            onClick={() => {
              Alert.navigate("landingpage/timespent");
            }}
            className={
              pathname.includes("timespent")
                ? "optionsfornavigatecurrent"
                : "optionsfornavigate"
            }
          >
            <span
              className={
                pathname.includes("timespent")
                  ? "optiontextfornavigatecurrent"
                  : "optiontextfornavigate"
              }
            >
              Time Spent{" "}
            </span>
          </span>
        </div>
      </div>
      <div className="dashboard-batch-dropdown">
        <Select
          className="batch-dropdown-selectbox"
          value={selectedOption}
          onChange={handleChange}
          displayEmpty
          renderValue={(value) =>
            value
              ? value
              : selectedBatch
              ? selectedBatch.name
              : "Select an option"
          }
          sx={{
            // '& .MuiOutlinedInput-notchedOutline': {
            //   borderColor: '#F9B9AC',
            // },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "#F9B9AC",
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: "#F9B9AC",
            },
          }}
        >
          {props.batchdetails
            ?.filter(
              (data) =>
                (getDataFromStorage("role") === constants.Roles.parent &&
                  data.type !== constants.BATCH_TYPE.TESTING) ||
                getDataFromStorage("role") !== constants.Roles.parent
            )
            .map((item) => (
              <MenuItem
                className="batch-dropdown-selectbox-menuitem"
                /* className={classes.menuItem} */ key={item.id}
                value={item.name} /* title={item.name} */
              >
                {/* <span className='batch-dropdown-item-name'>{item.name}</span> */}
                <Tooltip title={item.name} placement="right">
                  <span className="batch-dropdown-item-name">{item.name}</span>
                </Tooltip>
                {selectedOption !== "" && selectedOption === item.name ? (
                  <ListItemIcon
                    className="batch-dropdown-selectbox-listitemicon" /* className={classes.listItemIcon} */
                  >
                    <CheckIcon />
                  </ListItemIcon>
                ) : (
                  selectedBatch &&
                  selectedBatch.name === item.name && (
                    <ListItemIcon
                      className="batch-dropdown-selectbox-listitemicon" /* className={classes.listItemIcon} */
                    >
                      <CheckIcon />
                    </ListItemIcon>
                  )
                )}
              </MenuItem>
            ))}
          <MenuItem
            className="batch-dropdown-selectbox-menuitemback"
            /* className={classes.menuItemBack} */ onClick={() =>
              handleChange({ target: { value: "" } })
            }
          >
            <ListItemIcon
              className="batch-dropdown-selectbox-listitemarrowicon" /* className={classes.listItemArrowIcon} */
            >
              <ArrowBackIosIcon
                className="batch-dropdown-selectbox-svg" /* className={classes.svg} */
              />
            </ListItemIcon>
            View all batches
          </MenuItem>
        </Select>
      </div>
    </div>
  );
}
