import React from 'react'

export default function Git() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.61585 10.8832C1.12805 10.3954 1.12805 9.60456 1.61585 9.11676L9.11676 1.61585C9.60456 1.12805 10.3954 1.12805 10.8832 1.61585L18.3842 9.11676C18.872 9.60456 18.872 10.3954 18.3841 10.8832L10.8832 18.3842C10.3954 18.872 9.60456 18.872 9.11676 18.3841L1.61585 10.8832Z" fill="#667085"/>
        <path d="M7.59162 3.16309L6.83203 3.92267L8.79385 5.88449C8.71449 6.05475 8.67018 6.24464 8.67018 6.44487C8.67018 7.01337 9.02737 7.49844 9.52956 7.68791V12.487C9.02737 12.6765 8.67018 13.1615 8.67018 13.73C8.67018 14.4635 9.26481 15.0582 9.99831 15.0582C10.7318 15.0582 11.3264 14.4635 11.3264 13.73C11.3264 13.2146 11.0328 12.7678 10.6038 12.5476V7.69442L12.4791 9.56975C12.4036 9.73656 12.3616 9.92174 12.3616 10.1167C12.3616 10.8503 12.9562 11.4449 13.6897 11.4449C14.4232 11.4449 15.0178 10.8503 15.0178 10.1167C15.0178 9.38324 14.4232 8.78862 13.6897 8.78862C13.5472 8.78862 13.4099 8.81108 13.2812 8.85264L11.2671 6.83858C11.3057 6.71417 11.3264 6.58195 11.3264 6.44487C11.3264 5.71137 10.7318 5.11675 9.99831 5.11675C9.86123 5.11675 9.72901 5.13751 9.6046 5.17607L7.59162 3.16309Z" fill="white"/>
    </svg>
  )
}
