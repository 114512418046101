import React from "react";

const ModalBody = ({ header, content, footer }) => {
  return (
    <div className="modalBodyContainer">
      <div className="modalBodyContent">
        <header className="modalBodyHeader">{header}</header>
        <main className="modalBodyMain">{content}</main>
      </div>
      <footer className="modalBodyFooter">{footer}</footer>
    </div>
  );
};

export default ModalBody;
