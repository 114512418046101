import constants from "../../../constants";
import ContentEntry from "../ContentEntry/ContentEntry";
import McqQuestionEntry from "../QuestionEntry/McqQuestionEntry/McqQuestionEntry";
import CodingQuestionEntry from "../QuestionEntry/CodingQuestionEntry/CodingQuestionEntry";
import DescriptiveQuestionEntry from "../QuestionEntry/DescriptiveQuestionEntry/DescriptiveQuestionEntry";

function QuestionsEntry(Props) {
  return (
    <div style={{ width: "1320px" }}>
      {Props.tableShowStatus === constants.libTypeIds.CODING_CHALLENGE ? (
        <CodingQuestionEntry {...Props} />
      ) : null}
      {Props.tableShowStatus === constants.libTypeIds.MCQ ? (
        <McqQuestionEntry {...Props} />
      ) : null}
      {Props.tableShowStatus === constants.libTypeIds.DESCRIPTIVE ? (
        <DescriptiveQuestionEntry {...Props} />
      ) : null}
      {Props.tableShowStatus === constants.libTypeIds.CONTENT ? (
        <ContentEntry {...Props} />
      ) : null}
    </div>
  );
}
export default QuestionsEntry;
