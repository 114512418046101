import constants from "../../constants";
import React, { useContext } from "react";
import TestDueHooks from "../../Hooks/TestDueHooks";
import AlertContext from "../../context/AlertContext";

export default function StatusLabel(Props) {
  const Alert = useContext(AlertContext);

  const { currentDueLabel } = TestDueHooks();

  return (
    <div
      className={
        Props.component === "rightMain"
          ? "codingStatusLabelForCodingRightMain"
          : Props.component === "codingTable"
          ? "codingStatusLabelForCodingTable"
          : "codingStatusLabel"
      }
    >
      {(currentDueLabel(Alert.testData.duedate) === "Past Due" &&
        Alert.status !== constants.PROGRESS_STATUS.EVALUATED &&
        Alert.status !== constants.PROGRESS_STATUS.TO_BE_EVALUATED &&
        Alert.status !== constants.PROGRESS_STATUS.PARTIAL_EVALUATED) ||
      Alert.status === constants.PROGRESS_STATUS.UNLOCKED ||
      Props.status === constants.PROGRESS_STATUS.UNLOCKED ? (
        <span className="testPending">
          {!localStorage.getItem("mockLp") &&
          Alert.lpData.batchType !== constants.BATCH_TYPE.SELF &&
          currentDueLabel(Alert.testData.duedate) === "Past Due"
            ? currentDueLabel(Alert.testData.duedate)
            : "Pending"}
        </span>
      ) : Alert.status === constants.PROGRESS_STATUS.REATTEMPT ||
        Alert.status === constants.PROGRESS_STATUS.IN_PROGRESS ||
        (Props.component !== "codingTable" &&
          Alert.status === constants.PROGRESS_STATUS.QN_CONTINUE) ||
        (Props.component === "codingTable" &&
          Props.status === constants.PROGRESS_STATUS.QN_CONTINUE) ||
        Props.status === constants.PROGRESS_STATUS.IN_PROGRESS ||
        Props.status === constants.PROGRESS_STATUS.QN_CONTINUE ? (
        <span className="testInProgress">
          {(Props.component === "rightMain" ||
            Props.component === "codingTable" ||
            Props.status === constants.PROGRESS_STATUS.IN_PROGRESS) &&
            (Alert.isAdmin
              ? "Resubmission"
              : Alert.status === constants.PROGRESS_STATUS.REATTEMPT ||
                Alert.status === constants.PROGRESS_STATUS.IN_PROGRESS
              ? "In Progress"
              : "Ongoing")}
        </span>
      ) : Props.status === constants.PROGRESS_STATUS.REATTEMPT ? (
        <span className="testEvaluated">Evaluated</span>
      ) : (
        <span className="testSubmitted">
          {Alert.status === constants.PROGRESS_STATUS.TO_BE_EVALUATED &&
          Alert.approveData.hasOwnProperty("actualscore") &&
          (Alert.approveData.actualscore === null ||
            Alert.approveData.actualscore === undefined)
            ? "To be evaluated"
            : "Submitted"}
        </span>
      )}
    </div>
  );
}
