import React from "react";

export default function Button(Props) {
  const getButtonClassName = () => {
    if (Props.destructive) {
      if (Props.hierarchy.type === "primary") {
        if (Props.size === "sm") {
          return "buttonDestructivesm";
        } else if (Props.size === "md") {
          return "buttonDestructivemd";
        } else if (Props.size === "lg") {
          return "buttonDestructivelg";
        } else if (Props.size === "xl") {
          return "buttonDestructivexl";
        } else if (Props.size === "2xl") {
          return "buttonDestructive2xl";
        }
      }
      if (Props.hierarchy.type === "secondaryColor") {
        if (Props.size === "sm") {
          return "buttonSecondaryDestructivesm";
        } else if (Props.size === "md") {
          return "buttonSecondaryDestructivemd";
        } else if (Props.size === "lg") {
          return "buttonSecondaryDestructivelg";
        } else if (Props.size === "xl") {
          return "buttonSecondaryDestructivexl";
        } else if (Props.size === "2xl") {
          return "buttonSecondaryDestructive2xl";
        }
      } else if (Props.hierarchy.type === "secondaryGrey") {
        if (Props.size === "sm") {
          return "buttonSecondaryGreyDestructivesm";
        } else if (Props.size === "md") {
          return "buttonSecondaryGreyDestructivemd";
        } else if (Props.size === "lg") {
          return "buttonSecondaryGreyDestructivelg";
        } else if (Props.size === "xl") {
          return "buttonSecondaryGreyDestructivexl";
        } else if (Props.size === "2xl") {
          return "buttonSecondaryGreyDestructive2xl";
        }
      } else if (Props.hierarchy.type === "tertiaryColor") {
        if (Props.size === "sm") {
          return "buttonTertiaryColorDestructivesm";
        } else if (Props.size === "md") {
          return "buttonTertiaryColorDestructivemd";
        } else if (Props.size === "lg") {
          return "buttonTertiaryColorDestructivelg";
        } else if (Props.size === "xl") {
          return "buttonTertiaryColorDestructivexl";
        } else if (Props.size === "2xl") {
          return "buttonTertiaryColorDestructive2xl";
        }
      } else if (Props.hierarchy.type === "tertiaryGrey") {
        if (Props.size === "sm") {
          return "buttonTertiaryGreyDestructivesm";
        } else if (Props.size === "md") {
          return "buttonTertiaryGreyDestructivemd";
        } else if (Props.size === "lg") {
          return "buttonTertiaryGreyDestructivelg";
        } else if (Props.size === "xl") {
          return "buttonTertiaryGreyDestructivexl";
        } else if (Props.size === "2xl") {
          return "buttonTertiaryGreyDestructive2xl";
        }
      } else if (Props.hierarchy.type === "linkColor") {
        if (Props.size === "sm") {
          return "buttonLinkColorDestructivelsm";
        } else if (Props.size === "md") {
          return "buttonLinkColorDestructivelmd";
        } else if (Props.size === "lg") {
          return "buttonLinkColorDestructivellg";
        } else if (Props.size === "xl") {
          return "buttonLinkColorDestructivelxl";
        } else if (Props.size === "2xl") {
          return "buttonLinkColorDestructivel2xl";
        }
        return "buttonTertiaryColorDestructivexl";
      } else if (Props.hierarchy.type === "linkGrey") {
        if (Props.size === "sm") {
          return "buttonLinkGreyDestructivelsm";
        } else if (Props.size === "md") {
          return "buttonLinkGreyDestructivelmd";
        } else if (Props.size === "lg") {
          return "buttonLinkGreyDestructivellg";
        } else if (Props.size === "xl") {
          return "buttonLinkGreyDestructivelxl";
        } else if (Props.size === "2xl") {
          return "buttonLinkGreyDestructivel2xl";
        }
      }
    } else if (Props.size === "sm" && Props.hierarchy.type === "primary") {
      return "buttonsm";
    } else if (Props.size === "md" && Props.hierarchy.type === "primary") {
      return "buttonmd";
    } else if (Props.size === "lg" && Props.hierarchy.type === "primary") {
      return "buttonlg";
    } else if (Props.size === "xl" && Props.hierarchy.type === "primary") {
      return "buttonxl";
    } else if (Props.size === "2xl" && Props.hierarchy.type === "primary") {
      return "button2xl";
    } else if (
      Props.size === "sm" &&
      Props.hierarchy.type === "secondaryColor"
    ) {
      return "buttonSecondarysm";
    } else if (
      Props.size === "md" &&
      Props.hierarchy.type === "secondaryColor"
    ) {
      return "buttonSecondarymd";
    } else if (
      Props.size === "lg" &&
      Props.hierarchy.type === "secondaryColor"
    ) {
      return "buttonSecondarylg";
    } else if (
      Props.size === "xl" &&
      Props.hierarchy.type === "secondaryColor"
    ) {
      return "buttonSecondaryxl";
    } else if (
      Props.size === "2xl" &&
      Props.hierarchy.type === "secondaryColor"
    ) {
      return "buttonSecondary2xl";
    } else if (
      Props.size === "sm" &&
      Props.hierarchy.type === "secondaryGrey"
    ) {
      return "buttonSecondaryGreysm";
    } else if (
      Props.size === "md" &&
      Props.hierarchy.type === "secondaryGrey"
    ) {
      return "buttonSecondaryGreymd";
    } else if (
      Props.size === "lg" &&
      Props.hierarchy.type === "secondaryGrey"
    ) {
      return "buttonSecondaryGreylg";
    } else if (
      Props.size === "xl" &&
      Props.hierarchy.type === "secondaryGrey"
    ) {
      return "buttonSecondaryGreyxl";
    } else if (
      Props.size === "2xl" &&
      Props.hierarchy.type === "secondaryGrey"
    ) {
      return "buttonSecondaryGrey2xl";
    } else if (
      Props.size === "sm" &&
      Props.hierarchy.type === "tertiaryColor"
    ) {
      return "buttonTertiaryColorsm";
    } else if (
      Props.size === "md" &&
      Props.hierarchy.type === "tertiaryColor"
    ) {
      return "buttonTertiaryColormd";
    } else if (
      Props.size === "lg" &&
      Props.hierarchy.type === "tertiaryColor"
    ) {
      return "buttonTertiaryColorlg";
    } else if (
      Props.size === "xl" &&
      Props.hierarchy.type === "tertiaryColor"
    ) {
      return "buttonTertiaryColorxl";
    } else if (
      Props.size === "2xl" &&
      Props.hierarchy.type === "tertiaryColor"
    ) {
      return "buttonTertiaryColor2xl";
    } else if (Props.size === "sm" && Props.hierarchy.type === "tertiaryGrey") {
      return "buttonTertiaryGreysm";
    } else if (Props.size === "md" && Props.hierarchy.type === "tertiaryGrey") {
      return "buttonTertiaryGreymd";
    } else if (Props.size === "lg" && Props.hierarchy.type === "tertiaryGrey") {
      return "buttonTertiaryGreylg";
    } else if (Props.size === "xl" && Props.hierarchy.type === "tertiaryGrey") {
      return "buttonTertiaryGreyxl";
    } else if (
      Props.size === "2xl" &&
      Props.hierarchy.type === "tertiaryGrey"
    ) {
      return "buttonTertiaryGrey2xl";
    } else if (Props.size === "sm" && Props.hierarchy.type === "linkColor") {
      return "buttonLinkColorlsm";
    } else if (Props.size === "md" && Props.hierarchy.type === "linkColor") {
      return "buttonLinkColorlmd";
    } else if (Props.size === "lg" && Props.hierarchy.type === "linkColor") {
      return "buttonLinkColorllg";
    } else if (Props.size === "xl" && Props.hierarchy.type === "linkColor") {
      return "buttonLinkColorlxl";
    } else if (Props.size === "2xl" && Props.hierarchy.type === "linkColor") {
      return "buttonLinkColorl2xl";
    } else if (Props.size === "sm" && Props.hierarchy.type === "linkGrey") {
      return "buttonLinkGreylsm";
    } else if (Props.size === "md" && Props.hierarchy.type === "linkGrey") {
      return "buttonLinkGreylmd";
    } else if (Props.size === "lg" && Props.hierarchy.type === "linkGrey") {
      return "buttonLinkGreyllg";
    } else if (Props.size === "xl" && Props.hierarchy.type === "linkGrey") {
      return "buttonLinkGreylxl";
    } else if (Props.size === "2xl" && Props.hierarchy.type === "linkGrey") {
      return "buttonLinkGreyl2xl";
    }
  };

  return (
    <>
      {Props.hierarchy.type === "primary" && (
        <button
          onClick={Props.onClick}
          disabled={Props.disable}
          className={getButtonClassName()}
        >
          {Props.icon?.type === "leading" && (
            <span className={Props.icon?.nospace ?"buttonLeadingIconNoSpace":"buttonLeadingIcon"}>{Props.icon.icon}</span>
          )}
          {Props.hierarchy.buttonText}
          {Props.icon?.type === "trailing" && Props.icon.icon}
        </button>
      )}
      {(Props.hierarchy.type === "secondaryColor" ||
        Props.hierarchy.type === "secondaryGrey") && (
        <button
          onClick={Props.onClick}
          disabled={Props.disable}
          className={getButtonClassName()}
        >
          {Props.icon?.type === "leading" && (
            <span className={Props.icon?.nospace ?"buttonLeadingIconNoSpace":"buttonLeadingIcon"}>{Props.icon.icon}</span>
          )}
          {Props.hierarchy.buttonText}
          {Props.icon?.type === "trailing" && Props.icon.icon}
        </button>
      )}
      {(Props.hierarchy.type === "linkGrey" ||
        Props.hierarchy.type === "linkColor" ||
        Props.hierarchy.type === "tertiaryGrey" ||
        Props.hierarchy.type === "tertiaryColor") && (
        <button
          onClick={Props.onClick}
          disabled={Props.disable}
          className={getButtonClassName()}
        >
          {Props.icon?.type === "leading" && (
            <span className={Props.icon?.nospace ?"buttonLeadingIconNoSpace":"buttonLeadingIcon"}>{Props.icon.icon}</span>
          )}
          {Props.hierarchy.buttonText}
          {Props.icon?.type === "trailing" && Props.icon.icon}
        </button>
      )}
    </>
  );
}
