import { useContext } from "react";
import constants from "../../constants";
import AlertContext from "../../context/AlertContext";

export default function StudentTestPageCalcStatsHooks() {
  const Alert = useContext(AlertContext);

  let tempTq = 0,
    tempQa = 0,
    tempNa = 0,
    tempCa = 0,
    tempWa = 0,
    totalTS = 0,
    totalSS = 0,
    totalLS = 0,
    tempScoresObj = {};

  const calcStats = (tempReport, sec) => {
    let actSec = 0;
    let secArr = [];
    let evalIds = [];
    let idScore = {};
    let allQIids = [];
    let allSec = false;
    let notEvalIds = [];

    for (let i = 0; i < tempReport.sections.length; i++) {
      secArr.push(i);
    }

    if (sec[0] === "all") {
      allSec = true;
      sec = secArr;
      actSec = sec.length;
    }

    for (let i = 0; i < tempReport.sections.length; i++) {
      if (sec.includes(i)) {
        for (let j = 0; j < tempReport.sections[i].questions.length; j++) {
          let questOnJ = tempReport.sections[i].questions[j];
          let score = questOnJ.actualscore;

          tempScoresObj = {
            ...tempScoresObj,
            [tempReport.sections[i].questions[j].id]: score,
          };

          tempTq++;
          allQIids.push(tempReport.sections[i].questions[j].id);

          if (
            tempReport.sections[i].questions[j].actualscore == null ||
            tempReport.sections[i].questions[j].hasOwnProperty(
              "actualscore"
            ) === false
          ) {
            notEvalIds.push(tempReport.sections[i].questions[j].id);
          } else if (tempReport.sections[i].questions[j].actualscore != null) {
            evalIds.push(tempReport.sections[i].questions[j].id);
            idScore = {
              ...idScore,
              [tempReport.sections[i].questions[j].id]:
                tempReport.sections[i].questions[j].actualscore,
            };
          }

          if (allSec) {
            totalTS = totalTS + tempReport.sections[i].questions[j].targetscore;
          }

          totalSS = totalSS + tempReport.sections[i].questions[j].targetscore;
          totalLS = totalLS + tempReport.sections[i].questions[j].actualscore;

          if (
            tempReport.sections[i].questions[j].answer === "" ||
            tempReport.sections[i].questions[j].answer == null
          ) {
            tempNa++;
          } else {
            tempQa++;
          }

          if (
            tempReport.sections[i].questions[j].targetscore ===
            tempReport.sections[i].questions[j].actualscore
          ) {
            tempCa++;
          } else {
            tempWa++;
          }
        }
      }
    }

    Alert.setTestStats({
      tq: tempTq,
      qa: tempQa,
      na: tempNa,
      ca: tempCa,
      wa: tempWa,
    });

    if (allSec) {
      Alert.setTotalTestScore(totalTS);
    }

    Alert.setFilterQuestionCount(tempTq);
    Alert.setTotalSecScore(totalSS);
    Alert.setTotalLearnerScore(totalLS);

    if (
      (sec[0] === "all" && sec.length > 1) ||
      actSec === 1 ||
      Alert.status === constants.PROGRESS_STATUS.TO_BE_EVALUATED ||
      Alert.status === constants.PROGRESS_STATUS.PARTIAL_EVALUATED
    ) {
      Alert.setAnsStats({
        allids: allQIids,
        evalids: evalIds,
        idscore: idScore,
        notevalids: notEvalIds,
      });
    }
  };

  return { calcStats };
}
