import constants from "../../constants";
import { useContext, useState } from "react";
import AlertContext from "../../context/AlertContext";

export default function CodingEditorHooks() {
  const Alert = useContext(AlertContext);

  let templateCodes = JSON.parse(
    JSON.stringify(
      Alert.codeDetails?.hasOwnProperty("templatecode")
        ? typeof Alert.codeDetails.templatecode !== "string"
          ? Alert.codeDetails.templatecode
          : JSON.parse(Alert.codeDetails.templatecode)
        : []
    )
  );

  const [editorLanguage, setEditorLanguage] = useState(
    Alert.status !== constants.QUESTION_STATUS.ASSIGNED &&
      Alert.status !== constants.QUESTION_STATUS.RESUBMIT
      ? Alert.codeDetails?.langname?.toLowerCase() === "c" ||
        Alert.codeDetails?.langname?.toLowerCase() === "cpp"
        ? "java"
        : Alert.codeDetails?.langname?.toLowerCase()
      : Alert.codeDetails?.templatecode?.length > 0
      ? Alert.codeDetails.templatecode[0].langname?.toLowerCase() === "c" ||
        Alert.codeDetails.templatecode[0].langname?.toLowerCase() === "cpp"
        ? "java"
        : Alert.codeDetails.templatecode[0].langname?.toLowerCase()
      : ""
  );

  const customStyle = {
    control: (provided) => ({
      ...provided,
      border: "none",
      outline: "none",
      cursor: "pointer",
      minHeight: "24px",
      maxHeight: "24px",
      boxShadow: "none",
      alignContent: "center",
    }),
    option: (styles, { isDisabled, isFocused }) => {
      return {
        ...styles,
        color: "#344054",
        cursor: isDisabled ? "not-allowed" : "pointer",
        backgroundColor: isFocused ? "white" : "#F2F3F5",
      };
    },
  };

  const handleDisableLanguage = () => {
    if (
      Alert.isAdmin ||
      Alert.status === constants.PROGRESS_STATUS.EVALUATED ||
      Alert.status === constants.PROGRESS_STATUS.TO_BE_EVALUATED
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleChangeLanguage = (e) => {
    setEditorLanguage(
      e.label.toLowerCase() === "c" || e.label.toLowerCase() === "cpp"
        ? "java"
        : e.label.toLowerCase()
    );

    for (let i = 0; i < templateCodes.length; i++) {
      if (e.label.toLowerCase() === templateCodes[i].langname.toLowerCase()) {
        Alert.setSelectedLanguage(e);
        Alert.setLearnerCode(
          templateCodes[i].userCode === undefined &&
            templateCodes[i].usercode === undefined
            ? templateCodes[i].code
            : templateCodes[i].usercode === undefined
            ? templateCodes[i].userCode
            : templateCodes[i].usercode
        );
      }
    }

    let newCodeDetails = JSON.parse(JSON.stringify(Alert.codeDetails));

    newCodeDetails.templatecode = templateCodes;
    Alert.setCodeDetails(newCodeDetails);
  };

  const handleResetLearnerCode = () => {
    if (
      Alert.status === constants.QUESTION_STATUS.ASSIGNED ||
      Alert.status === constants.QUESTION_STATUS.CONTINUE ||
      (!Alert.isAdmin && Alert.status === constants.QUESTION_STATUS.RESUBMIT)
    ) {
      for (let i = 0; i < templateCodes.length; i++) {
        if (
          Alert.selectedLanguage.label.toLowerCase() ===
          templateCodes[i].langname.toLowerCase()
        ) {
          templateCodes[i].userCode = templateCodes[i].code;
          Alert.setLearnerCode(templateCodes[i].userCode);
        }
      }
    } else {
      Alert.setLearnerCode(Alert.codeDetails.answer);
    }

    let newCodeDetails = JSON.parse(JSON.stringify(Alert.codeDetails));

    newCodeDetails.templatecode = templateCodes;
    Alert.setCodeDetails(newCodeDetails);
  };

  const handleLearnerCode = (value) => {
    if (
      Alert.status === constants.QUESTION_STATUS.ASSIGNED ||
      Alert.status === constants.QUESTION_STATUS.RESUBMIT ||
      Alert.status === constants.QUESTION_STATUS.CONTINUE
    ) {
      for (let i = 0; i < templateCodes.length; i++) {
        if (
          Alert.selectedLanguage.label.toLowerCase() ===
          templateCodes[i].langname.toLowerCase()
        ) {
          templateCodes[i].userCode = value;
          templateCodes[i].usercode = value;
          Alert.setLearnerCode(value);
        }
      }
    } else {
      Alert.setLearnerCode(value);
    }

    let newCodeDetails = JSON.parse(JSON.stringify(Alert.codeDetails));

    newCodeDetails.isChangeCode = true;
    newCodeDetails.templatecode = templateCodes;
    Alert.setCodeDetails(newCodeDetails);
  };

  return {
    customStyle,
    editorLanguage,
    handleLearnerCode,
    setEditorLanguage,
    handleChangeLanguage,
    handleDisableLanguage,
    handleResetLearnerCode,
  };
}
