import Avatar from "@mui/material/Avatar";
import Popover from "@mui/material/Popover";
import { useContext, useState } from "react";
import useLogout from "../../Hooks/useLogout";
import { useLocation } from "react-router-dom";
import { getDataFromStorage } from "../../util";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import AlertContext from "../../context/AlertContext";
import User01 from "../../Svg/User01";
import Logout02 from "../../Svg/Logout02";
import constants from "../../constants";

export default function ProfileAvatar() {
  const { navigate, setNavhome, setLoginStatus, isAdmin, userProfilePic} =
    useContext(AlertContext);

  const logout = useLogout();
  const location = useLocation();

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const handleLogOut = async () => {
    setNavhome(false);
    // localStorage.clear();
    localStorage.removeItem("tokenKey");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("tokenKey");
    localStorage.removeItem("refreshToken");
    setLoginStatus(false);
    await logout();
    navigate("/", { state: { from: location }, replace: true });
    window.location.reload();
  };

  return (
    <div className="headerAvatarContainer">
      <IconButton
        className="headerAvatarIcon"
        onClick={handleClick} /* size="small" */
      >
        <Avatar
          alt={getDataFromStorage("name")}
          src={userProfilePic ?? localStorage.getItem("picture")}
          style={{ width: "32px", height: "32px", padding: 0 }}
        />
      </IconButton>
      <Popover
        id="headerAvatarPopover"
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <div className="headerAvatarUserNameContainer">
          <div className="headerAvatarUserNameSec headerAvatarDetailSec">
            <Avatar
              alt={getDataFromStorage("name")}
              src={localStorage.getItem("picture")}
            />
            <Typography className="headerAvatarUserName">
              {getDataFromStorage("name")}
            </Typography>
          </div>
        </div>
        {getDataFromStorage("role") !== constants.Roles.parent &&
          getDataFromStorage("role") !== constants.Roles.hr && (
            <div
              className="headerAvatarViewProfileContainer"
              onClick={() => {
                navigate("/profile/personalinfo");
                setAnchorEl(null);
              }}
            >
              <div className="headerAvatarViewProfileSec headerAvatarDetailSec">
                <User01
                  width="20"
                  height="20"
                  stroke="#344054"
                  strokeWidth="1.6"
                />
                <Typography className="headerAvatarViewProfile">
                  View Profile
                </Typography>
              </div>
            </div>
          )}
        {isAdmin && (
          <div
            className="headerAvatarCompanyProfileContainer"
            onClick={() => {
              navigate("/profile/companyinfo");
              setAnchorEl(null);
            }}
          >
            <div className="headerAvatarCompanyProfileSec headerAvatarDetailSec">
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M22 21V19C22 17.1362 20.7252 15.5701 19 15.126M15.5 3.29076C16.9659 3.88415 18 5.32131 18 7C18 8.67869 16.9659 10.1159 15.5 10.7092M17 21C17 19.1362 17 18.2044 16.6955 17.4693C16.2895 16.4892 15.5108 15.7105 14.5307 15.3045C13.7956 15 12.8638 15 11 15H8C6.13623 15 5.20435 15 4.46927 15.3045C3.48915 15.7105 2.71046 16.4892 2.30448 17.4693C2 18.2044 2 19.1362 2 21M13.5 7C13.5 9.20914 11.7091 11 9.5 11C7.29086 11 5.5 9.20914 5.5 7C5.5 4.79086 7.29086 3 9.5 3C11.7091 3 13.5 4.79086 13.5 7Z"
                  stroke="#344054"
                  strokeWidth="1.6"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>

              <Typography className="headerAvatarCompanyProfile">
                Company Profile
              </Typography>
            </div>
          </div>
        )}
        <div className="headerAvatarLogoutContainer" onClick={handleLogOut}>
          <div className="headerAvatarLogoutSec headerAvatarDetailSec">
            <Logout02
              width="20"
              height="20"
              stroke="#344054"
              strokeWidth="1.6"
            />
            <Typography className="headerAvatarLogout">Log Out</Typography>
          </div>
        </div>
        {isAdmin && <div className="versionum"> <Typography>{process.env.REACT_APP_VERSION}</Typography></div>}
      </Popover>
    </div>
  );
}
