import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Pagination from './Pagination';

export default function UpcomingEvents(props) {
  // Dummy events data
  // const events = [
  //   { id: 1, date: '18', month: 'Mar', title: 'Event 1', time: '6pm - 7pm' },
  //   { id: 2, date: '20', month: 'Mar', title: 'Event 2', time: '7pm - 8pm' },
  //   { id: 3, date: '25', month: 'Mar', title: 'Event 3', time: '8:30 pm - 9pm' },
  //   { id: 3, date: '25', month: 'Mar', title: 'Event 3', time: '8:30 pm - 9pm' },
  //   { id: 3, date: '25', month: 'Mar', title: 'Event 3', time: '8:30 pm - 9pm' },
  // ];

// const formatDate = (dateString) => {
//     const months = [
//         "Jan", "Feb", "Mar", "Apr", "May", "Jun",
//         "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
//     ];

//     const dateObj = new Date(dateString);
//     const date = dateObj.getDate(); // Get the day of the month
//     const monthIndex = dateObj.getMonth(); // Get the month index (0-11)
//     const month = months[monthIndex]; // Get the abbreviated month name

//     return `date ${date} and month ${month}`;
// }

const getMonth = (dateString) => {
  const months = [
    "Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
  ];
  const dateObj = new Date(dateString);
  const monthIndex = dateObj.getMonth(); // Get the month index (0-11)
  const month = months[monthIndex];
  return month;
}

const gettingDate =(dateString) =>{
  const dateObj = new Date(dateString);
  const date = dateObj.getDate();
  return date;
}

const itemsPerPage = 3;
const [currentPage, setCurrentPage] = useState(1);

// Calculate the index range for the current page
const startIndex = (currentPage - 1) * itemsPerPage;
const endIndex = startIndex + itemsPerPage;

// Get the current page's events
const currentEvents = props.UpcomingEvents?.slice(startIndex, endIndex);

// Total number of pages
const totalPages = Math.ceil(props.UpcomingEvents?.length / itemsPerPage);

// Handle pagination click
const handlePaginationClick = (pageIndex) => {
  setCurrentPage(pageIndex);
};


  return (
    <Box className='upcoming-box-div'>
      <Typography className='upcoming-box-header' gutterBottom>
        Upcoming Events
      </Typography>
      {currentEvents?.length>0 ? (
      <>
      <Box width={392} height={170} margin="0 0 0 24px">
      {currentEvents.map((event,index) => (
        <Grid className='upcoming-box-date-month-grid' container key={event.sessionId} spacing={1}>
          <Grid item>
            <Box className='upcoming-box-date-month' bgcolor="#F2F4F7" textAlign="center">
              <Typography className='upcoming-box-date'>{gettingDate(event.startdate)}</Typography>
              <Typography className='upcoming-box-month'>{getMonth(event.startdate)}</Typography>
            </Box>
          </Grid>
          <Grid className='upcoming-box-title-time-grid'>
            <Box>
              <Typography className='upcoming-box-event'>{event.sessionName}</Typography>
              <Typography className='upcoming-box-time'>{event.startTime}-{event.endTime}</Typography>
            </Box>
          </Grid>
        </Grid>
      ))}
      </Box>
      <Box className='upcoming-event-pagination-box'>
      <Pagination handlePaginationClick={handlePaginationClick} totalPages={totalPages} currentPage={currentPage}/>
    </Box>
    </>) : (<>
      <Box className='upcoming-event-empty-box'>
        <div>
           No Upcoming Events
        </div>
      </Box>
    </>)}
    </Box>
  );
}
