import _ from "lodash";
import { useState } from "react";

export default function SandPackEditorSplitHooks() {
  const [outputWindowHeight, setOutputWindowHeight] = useState(9);
  const [problemWindowHeight, setProblemWindowHeight] = useState(0);

  const handleProblemDragFinished = (size) => {
    const [widthPercentage, heightPercentage] = size;
    const parsedWidth = parseFloat(widthPercentage.replace("%", ""));

    setProblemWindowHeight(parsedWidth);

    const newSize = `${widthPercentage},${heightPercentage}`;

    localStorage.setItem("splitWidthPos", newSize);
  };

  const handleDragFinished = (size) => {
    const [widthPercentage, heightPercentage] = size; // Extract the width and height percentage values
    const parsedHeight = parseFloat(heightPercentage.replace("%", "")); // Convert the height value to a float

    setOutputWindowHeight(parsedHeight);

    const newSize = `${widthPercentage},${heightPercentage}`; // Store the new size in localStorage

    const parsedNewSize = parseFloat(newSize.split(",")[1].replace("%", ""));
    const editorHeightdiv = document.getElementById("sandpackCodeEditor");

    if (editorHeightdiv) {
      editorHeightdiv.style.height = `${100 - parsedNewSize}vh`;
    }

    localStorage.setItem("splitPos", newSize);
  };

  return {
    handleDragFinished,
    outputWindowHeight,
    problemWindowHeight,
    setOutputWindowHeight,
    setProblemWindowHeight,
    handleProblemDragFinished,
  };
}
