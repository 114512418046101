import React from "react";

export default function TimeSvgIcon() {
  return (
    <svg
      width="14"
      height="14"
      fill="none"
      viewBox="0 0 14 14"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        stroke="#E54B2A"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M2.9165 1.75L1.1665 3.5M12.8332 3.5L11.0832 1.75M3.49984 11.0833L2.33317 12.25M10.4998 11.0833L11.6665 12.25M6.99984 5.25V7.58333L8.1665 8.75M6.99984 12.25C8.23751 12.25 9.4245 11.7583 10.2997 10.8832C11.1748 10.008 11.6665 8.82101 11.6665 7.58333C11.6665 6.34566 11.1748 5.15867 10.2997 4.2835C9.4245 3.40833 8.23751 2.91667 6.99984 2.91667C5.76216 2.91667 4.57518 3.40833 3.70001 4.2835C2.82484 5.15867 2.33317 6.34566 2.33317 7.58333C2.33317 8.82101 2.82484 10.008 3.70001 10.8832C4.57518 11.7583 5.76216 12.25 6.99984 12.25Z"
      />
    </svg>
  );
}
