import React from "react";

export default function PlayIconSvg() {
  return (
    <svg
      width="14"
      height="18"
      fill="none"
      viewBox="0 0 14 18"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        stroke="white"
        stroke-width="1.67"
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M1.16699 1.5L12.8337 9L1.16699 16.5V1.5Z"
      />
    </svg>
  );
}
