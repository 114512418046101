import React, { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";

export default function MockLearningPathDraggableModuleHooks() {
  const DraggableModule = ({
    index,
    style,
    className,
    movemodules,
    ...restProps
  }) => {
    const ref = useRef(null);

    const [{ opacity }, dragRef] = useDrag({
      type: "DraggableModule",
      item: { index },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
        opacity: monitor.isDragging() ? 0.5 : 1,
      }),
    });

    // useDrop - the list item is also a drop area

    const [, dropRef] = useDrop({
      accept: "DraggableModule",
      hover: (item, monitor) => {
        const hoverIndex = index;
        const dragIndex = item.index;
        const hoverBoundingRect = ref.current?.getBoundingClientRect();
        const hoverActualY =
          monitor.getClientOffset().y - hoverBoundingRect.top;

        const hoverMiddleY =
          (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

        // if dragging down, continue only when hover is smaller than middle Y

        if (dragIndex < hoverIndex && hoverActualY < hoverMiddleY) return;

        // if dragging up, continue only when hover is bigger than middle Y

        if (dragIndex > hoverIndex && hoverActualY > hoverMiddleY) return;

        movemodules(dragIndex, hoverIndex);
        item.index = hoverIndex;
      },
    });

    const dragDropRef = dragRef(dropRef(ref));

    // Make items being dragged transparent, so it's easier to see where we drop them

    return (
      <tr
        {...restProps}
        ref={dragDropRef}
        style={{ cursor: "default", ...style, opacity }}
      ></tr>
    );
  };

  const moduleComponents = {
    body: {
      row: DraggableModule,
    },
  };

  return moduleComponents;
}
