import React from "react";

export default function CommentSvgIcon(Props) {
  return (
    <svg
      width="40"
      height="40"
      fill="none"
      viewBox="0 0 40 40"
      onClick={Props.onClick}
      className="commentSvgIcon"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_2755_13431)">
        <rect x="2" y="1" width="36" height="36" rx="8" fill="#F9FAFB" />
        <rect
          x="2.5"
          y="1.5"
          rx="7.5"
          width="35"
          height="35"
          stroke="#D0D5DD"
        />
        <path
          stroke="#1D2939"
          stroke-width="1.67"
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M27.5 21.5C27.5 21.942 27.3244 22.366 27.0118 22.6785C26.6993 22.9911 26.2754 23.1667 25.8333 23.1667H15.8333L12.5 26.5V13.1667C12.5 12.7246 12.6756 12.3007 12.9882 11.9882C13.3007 11.6756 13.7246 11.5 14.1667 11.5H25.8333C26.2754 11.5 26.6993 11.6756 27.0118 11.9882C27.3244 12.3007 27.5 12.7246 27.5 13.1667V21.5Z"
        />
      </g>
      <defs>
        <filter
          x="0"
          y="0"
          width="40"
          height="40"
          id="filter0_d_2755_13431"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            type="matrix"
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.05 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_2755_13431"
          />
          <feBlend
            mode="normal"
            result="shape"
            in="SourceGraphic"
            in2="effect1_dropShadow_2755_13431"
          />
        </filter>
      </defs>
    </svg>
  );
}
