import React, { useContext } from "react";
import UserTimeSpentReportChart from "./UserTimeSpentReportChart";
import UserTimeSpentReportTable from "./UserTimeSpentReportTable";
import AlertContext from "../../../context/AlertContext";

export default function UserTimeSpentReport() {
  const Alert = useContext(AlertContext);

  return (
    <div
      className={
        !Alert.isAdmin
          ? "userTimeSpentReportMainContainer"
          : "userTimeSpentReportMainContainerForAdmin"
      }
    >
      <div
        className={
          !Alert.isAdmin
            ? "userTimeSpentReportContainer"
            : "userTimeSpentReportContainerForAdmin"
        }
      >
        <UserTimeSpentReportChart />
        <UserTimeSpentReportTable />
      </div>
    </div>
  );
}
