import LeetCode from "./LeetCode";
import Trainers from "./Trainers";
import Card from "@mui/material/Card";
import Timelinegraph from "./Timeline";
import Progressbar from "./Progressbar";
import Leaderboard from "./Leaderboard";
import constants from "../../constants";
import Avatar from "@mui/material/Avatar";
import AssignmentDue from "./AssignmentDue";
import BatchDropDown from "./BatchDropDown";
import Divider from "@mui/material/Divider";
import UpcomingEvents from "./UpcomingEvents";
import { styled } from "@mui/material/styles";
import Collapse from "@mui/material/Collapse";
import TableWithTotal from "./TableWithTotal";
import CardHeader from "@mui/material/CardHeader";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import AlertContext from "../../context/AlertContext";
import { Outlet, useLocation } from "react-router-dom";
import useAxiosPrivate from "../../Hooks/useAxiosPrivate";
import MockBanner from "../Student/MockTestPage/MockBanner";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Loading from "../Notification-Loading/Loading/Loading";
import { getDataFromStorage, getMinutesGap } from "../../util";
import React, { useContext, useEffect, useState } from "react";
import useCommonFunctionHooks from "../../Hooks/useCommonFunctionHooks";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;

  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: expand ? "rotate(180deg)" : "rotate(0deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function Dashboard(props) {
  const {
    setLpId,
    batchId,
    navigate,
    isLoaded,
    showBlink,
    batchName,
    setNavhome,
    setBatchId,
    setIsLoaded,
    setShowBlink,
    setBatchName,
    setShowNotify,
    leaderBoardData,
    dashBoardDetails,
    setLeaderBoardData,
    setDashBoardDetails,
    setLeaderBoardTestDetail,
  } = useContext(AlertContext);

  const axios = useAxiosPrivate();
  const { pathname } = useLocation();

  const { getCatchBlockDetails, getHeaderDetailsForAxios } =
    useCommonFunctionHooks();

  const [option, setOption] = useState(1);
  const [expanded, setExpanded] = useState([]);
  const [subExpanded, setSubExpanded] = useState([]);
  const [showHeaderChapter, setShowHeaderChapter] = useState(true);
  const [loadingLeaderboard, setLoadingLeaderboard] = useState(false);
  const [showSubHeaderChapter, setShowSubheaderChapter] = useState(true);

  const getSpecificBatchDashboard = () => {
    setIsLoaded(true);
    axios
      .post(
        "node/learner/dashboard",
        JSON.stringify({
          batchId: localStorage.getItem("batchid"),
          learnerId: getDataFromStorage("learnerid"),
        }),
        getHeaderDetailsForAxios()
      )
      .then((res) => {
        if (res.data.resultCode === constants.RESULT_STATUS.TECHNICAL_ERROR) {
          setShowNotify({
            show: true,
            title: "Info",
            msg: res.data.msg,
          });
        } else {
          if (res.data.resultCode === constants.RESULT_STATUS.NO_DATA) {
            setShowNotify({
              show: true,
              title: "Info",
              msg: "No data to display",
            });
          } else {
            setDashBoardDetails(res.data.data);

            const givenTime = res.data.data.attendanceExpiry;
            const minutesGap = getMinutesGap(givenTime);

            let mss = minutesGap * 60 * 1000;

            if (showBlink && !res.data.data.markAttendance) {
              setShowBlink(res.data.data.markAttendance);
            }

            if (!showBlink && res.data.data.markAttendance && minutesGap >= 0) {
              setShowBlink(res.data.data.markAttendance);
              setTimeout(() => {
                document
                  .getElementById("svg-element")
                  .classList.remove("blinking");
                setShowBlink(false);
              }, [mss]);
            }

            let batchIdToFind =
              batchId === 0 ? localStorage.getItem("batchid") : batchId;

            let batname = res.data.data.batchDetails.find(
              (item) => item.id == batchIdToFind
            );

            localStorage.setItem(
              "isTestBatch",
              parseInt(batname?.type) === constants.BATCH_TYPE.TESTING
            );

            if (batname?.type === constants.BATCH_TYPE.TESTING) {
              if (res.data.data?.lpData[0]?.lpId)
                naviageHomeFunction(res.data.data?.lpData[0]?.lpId);
            }

            setBatchId(batchIdToFind);
            setBatchName(batname?.name);
          }
        }
      })
      .catch((error) => {
        getCatchBlockDetails(error);
      })
      .finally(() => setIsLoaded(false));

    /* Leaderboard API */

    setLoadingLeaderboard(true);
    setLeaderBoardData([]);

    axios
      .get(
        `node/learner/${getDataFromStorage("learnerid")}/batch/${batchId === 0 ? localStorage.getItem("batchid") : batchId
        }/leaderboard`,
        getHeaderDetailsForAxios()
      )
      .then((res) => {
        if (res.data.resultCode === constants.RESULT_STATUS.TECHNICAL_ERROR) {
          setLoadingLeaderboard(false);
        } else {
          if (res.data.resultCode === constants.RESULT_STATUS.NO_DATA) {
            setLoadingLeaderboard(false);
          } else {
            setLeaderBoardData(res.data.data.data);
            setLoadingLeaderboard(false);
          }
        }
      })
      .catch((error) => {
        getCatchBlockDetails(error);
        setLoadingLeaderboard(false);
      })
      .finally(() => setIsLoaded(false));
  };

  useEffect(() => {
    if (pathname === "/landingpage") {
      getSpecificBatchDashboard();
    }
  }, [batchId, pathname]);

  useEffect(() => {
    if (dashBoardDetails && dashBoardDetails.lpData) {
      setShowHeaderChapter(Array(dashBoardDetails.lpData.length).fill(true));
    }

    if (dashBoardDetails && dashBoardDetails.lpData) {
      setExpanded(Array(dashBoardDetails.lpData.length).fill(false));
    }
  }, [dashBoardDetails]);

  const handleExpandClick = (index) => {
    if (dashBoardDetails && dashBoardDetails.lpData) {
      setSubExpanded(
        Array(dashBoardDetails.lpData[index].lpContent.length).fill(false)
      );
      setShowSubheaderChapter(
        Array(dashBoardDetails.lpData[index].lpContent.length).fill(true)
      );
    }

    setShowHeaderChapter((prevState) => {
      const newState = [...prevState];

      newState[index] = !prevState[index];

      return newState;
    });

    setExpanded((prevState) => {
      const newState = [...prevState];

      newState[index] = !prevState[index];

      return newState;
    });
  };

  const handleSubExpandClick = (index) => {
    setShowSubheaderChapter((prevState) => {
      const newState = [...prevState];

      newState[index] = !prevState[index];

      return newState;
    });

    setSubExpanded((prevState) => {
      const newState = [...prevState];

      newState[index] = !prevState[index];

      return newState;
    });
  };

  /* navigate to home page */

  const naviageHomeFunction = (lpId) => {
    setLpId(lpId);
    sessionStorage.setItem("lpId", lpId);
    navigate("/home");
  };

  const completedPercenteageFuncton = (data) => {
    let finalPercentage;

    if (data.chapterCount !== 0) {
      finalPercentage = (data.completedCount / data.chapterCount) * 100;
    } else {
      finalPercentage = 0;
    }

    return parseFloat(finalPercentage.toFixed());
  };

  const handleLeaderboardDetail = (from) => {
    axios
      .get(
        `node/learner/${getDataFromStorage("learnerid")}/batch/${batchId === 0 ? localStorage.getItem("batchid") : batchId
        }/testpoints`,
        getHeaderDetailsForAxios()
      )
      .then((res) => {
        if (res.data.resultCode === constants.RESULT_STATUS.TECHNICAL_ERROR) {
          setShowNotify({
            show: true,
            title: "Info",
            msg: res.data.msg,
          });
        } else {
          if (res.data.resultCode === constants.RESULT_STATUS.NO_DATA) {
            setShowNotify({
              show: true,
              title: "Info",
              msg: "No data to display",
            });
          } else if (typeof from != "object" && from?.includes("options")) {
            setLeaderBoardTestDetail(res.data.data);
            if (from == "optionsscore") {
              setOption(2);
            } else {
              setOption(3);
            }
          } else {
            setShowNotify({
              show: true,
              size: "xl",
              showClose: true,
              hideButton: true,
              component: "leaderBoard",
              msg: (
                <TableWithTotal
                  option={0}
                  from="popup"
                />
              ),
            });
          }
        }
      })
      .catch((error) => {
        getCatchBlockDetails(error);
      })
      .finally(() => setIsLoaded(false));
  };

  return (
    <div className="dashBoardMainContainer">
      <div className="Dashboard-div">
        {getDataFromStorage("role") == constants.Roles.parent && (
          <div className="parentGreetContainer">
            <div className="parentGreet">
              <span className="greet">{`Hello, ${getDataFromStorage(
                "name"
              )}’s parent!`}</span>
              <span className="instruct">
                You can track your child’s progress here...
              </span>
            </div>
          </div>
        )}
        {isLoaded && <Loading />}
        <div className="Dashboard-div-batch-change">
          <BatchDropDown
            option={option}
            setOption={setOption}
            batchId={batchId}
            setBatchId={setBatchId}
            setNavhome={setNavhome}
            batchdetails={dashBoardDetails.batchDetails}
            getSpecificBatchDashboard={getSpecificBatchDashboard}
            handleLeaderboardDetail={handleLeaderboardDetail}
          />
        </div>
        <Outlet />
        {pathname === "/landingpage" && (
          <div className="dashboard-content-div">
            {dashBoardDetails.lpData?.map((data, index) => (
              <div className="dashboard-batchdetail-div">
                <Card
                  className={`dashboard-batchdetail-card ${expanded ? "dashboard-batchdetail-card-expanded" : ""
                    }`}
                >
                  <CardHeader
                    className="dashboard-batchdetail-card-header"
                    avatar={
                      <Avatar
                        alt={"Icon"}
                        src={data.lpIcon}
                        style={{ width: "28px", height: "30px", padding: 0 }}
                      />
                    }
                    action={
                      <>
                        {data.chapterCount > 0 &&
                          getDataFromStorage("role") != "parent" && (
                            <button
                              onClick={() => {
                                naviageHomeFunction(data.lpId);
                                localStorage.setItem("lpname", data.lpName);
                              }}
                              className="dashboard-batchdetail-card-header-test-button"
                            >
                              Resume
                            </button>
                          )}

                        <ExpandMore
                          aria-label="show more"
                          expand={expanded[index]}
                          aria-expanded={expanded[index]}
                          onClick={() => handleExpandClick(index)}
                          className="dashboard-batchdetail-card-header-expand-button"
                        >
                          <ExpandMoreIcon />
                        </ExpandMore>
                      </>
                    }
                    title={data.lpName}
                    titleTypographyProps={{
                      style: {
                        fontSize: "24px",
                        color: "#344054",
                        fontWeight: "600",
                        fontFamily: "Inter",
                      },
                    }}
                  />
                  {showHeaderChapter[index] && (
                    <CardContent className="dashboard-batchdetail-card-content">
                      <Typography className="dashboard-batchdetail-card-content-batchdetail">
                        <Typography
                          component="div"
                          className="dashboard-batchdetail-card-content-coursedetail"
                        >
                          {data.lpContent.length > 0
                            ? `${data.lpContent.length} modules . ${data.lpContent.length > 0
                              ? data.lpContent.reduce((total, item) => {
                                return total + item.subtopics.length;
                              }, 0)
                              : 0
                            } chapters`
                            : `${data.moduleCount} modules . ${data.chapterCount} chapters`}
                        </Typography>
                        <Typography
                          component="div"
                          className="dashboard-batchdetail-card-content-percentage"
                        >
                          {completedPercenteageFuncton(data) + "%"}
                        </Typography>
                      </Typography>
                      <Progressbar
                        bgColor="#EAECF0"
                        barColor="#F55533"
                        percentage={completedPercenteageFuncton(data)}
                      />
                    </CardContent>
                  )}

                  <Collapse in={expanded[index]} timeout="auto" unmountOnExit>
                    <div className="dashboard-batchdetail-collapse-desc">
                      {data.lpDes}
                    </div>
                    {data.lpContent.length > 0 && (
                      <div className="dashboard-batchdetail-collapse-content">
                        <p>Content</p>
                      </div>
                    )}

                    {data.lpContent.length > 0 &&
                      data.lpContent.map((topic, index) => (
                        <div
                          key={index}
                          className="dashboard-batchdetail-collapse-div"
                        >
                          <CardContent
                            onClick={() => handleSubExpandClick(index)}
                            className="dashboard-batchdetail-collapse-lpname-div"
                          >
                            <div>
                              <Typography className="dashboard-batchdetail-collapse-lpname">
                                <div className="dashboard-batchdetail-index-div">
                                  <div className="dashboard-batchdetail-index">
                                    {index + 1}
                                  </div>
                                  <div>{topic.name}</div>
                                </div>
                              </Typography>
                              {showSubHeaderChapter[index] && (
                                <Typography className="dashboard-batchdetail-collapse-chapter-cout">
                                  {topic.subtopics.length} Chapters
                                </Typography>
                              )}
                            </div>
                            <IconButton
                              aria-label="show more"
                              aria-expanded={subExpanded[index]}
                              className="dashboard-batchdetail-collapse-expandicon"
                            >
                              <ExpandMoreIcon
                                className={`expand-icon ${subExpanded[index] ? "rotated" : ""
                                  }`}
                              />
                            </IconButton>
                          </CardContent>
                          <Collapse
                            unmountOnExit
                            timeout="auto"
                            in={subExpanded[index]}
                          >
                            {topic.subtopics.map((subtopic, subIndex) => (
                              <CardContent className="dashboard-batchdetail-subcollapse-cardcontent">
                                <Typography
                                  key={subIndex}
                                  className="dashboard-batchdetail-subcollapse-subheader"
                                >
                                  {subtopic}
                                </Typography>
                                <Divider className="dashboard-batchdetail-subcollapse-divider" />
                              </CardContent>
                            ))}
                          </Collapse>
                        </div>
                      ))}
                  </Collapse>
                </Card>
              </div>
            ))}

            {dashBoardDetails?.batchDetails?.find((data) => data.id == batchId)
              ?.type !== constants.BATCH_TYPE.TESTING && (
                <div>
                  {dashBoardDetails.attendanceDetails?.sessionData != null &&
                    dashBoardDetails.attendanceDetails?.sessionData?.length > 0 && (
                      <div className="Dashboard-timeline-div">
                        <Timelinegraph
                          timelineData={dashBoardDetails.attendanceDetails}
                        />
                      </div>
                    )}

                  <div className="event-asssignmentdue-leaderboard-container">
                    <UpcomingEvents
                      UpcomingEvents={dashBoardDetails.upComingEvents}
                    />
                    <AssignmentDue
                      batchname={batchName}
                      dueList={dashBoardDetails.taskDueLists}
                      naviageHomeFunction={naviageHomeFunction}
                    />
                  </div>

                  <div className="dashboard-leaderboard-div">
                    <Leaderboard
                      isLoaded={isLoaded}
                      leaderBoardData={leaderBoardData}
                      loadingLeaderboard={loadingLeaderboard}
                      handleLeaderboardDetail={handleLeaderboardDetail}
                    />
                  </div>

                  <div className="leetcode-container">
                    <LeetCode />
                  </div>

                  {dashBoardDetails.trainerList?.length > 0 && (
                    <div className="trainer-header">
                      <span>Trainers</span>
                    </div>
                  )}
                  <div className="Trainers-container">
                    <Trainers trainerData={dashBoardDetails.trainerList} />
                  </div>
                </div>
              )}
          </div>
        )}
      </div>

      {parseInt(localStorage.getItem("batchType")) !==
        constants.BATCH_TYPE.TESTING &&
        getDataFromStorage("role") === constants.Roles.learner && (
          <MockBanner />
        )}
    </div>
  );
}
