import React from "react";
import {
  Typography,
  Card,
  CardActions,
  CardContent,
  CardMedia,
} from "@mui/material";
import Button from "../../Button";
import { isEnableProfileDetailsEdit } from "../../../util";

const ProfileUpload = ({
  profilePic,
  setProfilePic,
  handleResponse,
  from,
  buttonText,
  size,
}) => {
  const handleChangePic = (event) => {
    let file = event.target.files[0];

    if (!file.size) return;
    if (file.size > size) {
      handleResponse(
        "Warning",
        `Image size exceeds the limit of ${size} bytes`
      );
      return;
    }

    let reader = new FileReader();

    reader.onloadend = async () => {
      setProfilePic(reader.result);
    };
    reader.readAsDataURL(file);
  };
  return (
    <Card id="profileUploadProfileCard">
      <CardMedia id="profileUploadProfile" image={profilePic} title="Profile" />
      {isEnableProfileDetailsEdit && (
        <div className="profileUploadActionContainer">
          <CardActions id="profileUploadProfileActionSec">
            <input
              type="file"
              className="profileUploadProfileUploadBtn"
              accept="image/*"
              onChange={handleChangePic}
            />

            <Button
              size="sm"
              onClick={(event) => event.preventDefault()}
              hierarchy={{
                type: "linkGrey",
                buttonText,
              }}
            />
          </CardActions>
          <CardContent id="profileUploadProfileContentSec">
            <Typography
              id="profileUploadProfileCaption"
              gutterBottom
              variant="caption"
              component="p"
            >
              {size}px recommended
            </Typography>
          </CardContent>
        </div>
      )}
    </Card>
  );
};

export default ProfileUpload;
