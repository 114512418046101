import React from "react";
import { styled } from "@mui/material/styles";

import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";

export default function Progressbar(Props) {
  const BorderLinearProgress = styled(LinearProgress)(() => ({
    height: Props.from != undefined && Props.from == "resume" ? 4 : 8,
    borderRadius: 4,
    // [`&.${linearProgressClasses.colorPrimary}`]: {
    //   backgroundColor: theme.palette.grey[theme.palette.mode === '#EAECF0' ? 200 : 200],
    // },
    // [`& .${linearProgressClasses.bar}`]: {
    //   borderRadius: 4,
    //   backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#F55533',
    // },
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: Props.bgColor || "#EAECF0",
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: Props.barColor || "#F55533",
    },
  }));

  return (
    <div
      className={
        Props.component === "leftNav" && "leftNavLearningPathProgressBar"
      }
    >
      <BorderLinearProgress variant="determinate" value={Props.percentage} />
    </div>
  );
}
