import React from "react";

export default function InwardSvgIcon({stroke}) {
  return (
    <svg
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        stroke={stroke ?stroke:"#667085"}
        stroke-width="1.6"
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M6.66667 13.3333V9.33333M6.66667 9.33333H2.66667M6.66667 9.33333L2 14M9.33333 2.66667V6.66667M9.33333 6.66667H13.3333M9.33333 6.66667L14 2"
      />
    </svg>
  );
}
