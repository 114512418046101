import React from "react";
import constants from "../../constants";

export default function ComplexityLabels(Props) {
  return (
    <div className="complexityLabel">
      {Props.complexityid === constants.complexityid.EASY ? (
        <div className="complexityLabelEasy">Easy</div>
      ) : Props.complexityid === constants.complexityid.MEDIUM ? (
        <div className="complexityLabelMedium">Medium</div>
      ) : (
        <div className="complexityLabelHard">Hard</div>
      )}
    </div>
  );
}
