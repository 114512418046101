import React, { useContext, useState } from "react";
import { useLocation } from "react-router-dom";
import IssueForm from "./IssueForm";
import useAxiosPrivate from "../../Hooks/useAxiosPrivate";
import ModuleHooks from "../../Hooks/ModuleHooks";
import AlertContext from "../../context/AlertContext";
import constants from "../../constants";
import { getDataFromStorage } from "../../util";
import Popup from "./Popup";
import Loading from "../Notification-Loading/Loading/Loading";
import Tooltip from "./Tooltip";

const FloatingActionButton = () => {
  const { pathname } = useLocation();
  const axios = useAxiosPrivate();
  const { handleUnAuthAlert, setIsLoaded, isLoaded } = useContext(AlertContext);
  const { handlePopupStates, clearPopupStates } = ModuleHooks();

  const [dialogOpen, setDialogOpen] = useState(false);

  const handleNavFun = () => {
    clearPopupStates();
  };
  const handlePopupOkFunc = () => {
    handleUnAuthAlert();
    clearPopupStates();
  };

  const handleResponse = (title, msg, isUnAuth) => {
    let popupdata = {};
    popupdata.showpopup = true;
    popupdata.title = title;
    popupdata.bodydesc = msg;
    popupdata.singlebtn = true;
    popupdata.singlebtntxt = "Ok";
    popupdata.singlebtnsize = "small";
    popupdata.singlebtnfunt = isUnAuth ? handlePopupOkFunc : handleNavFun;
    handlePopupStates(popupdata);
  };

  const handleButtonClick = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleFormSubmit = async (data) => {
    console.log("User Data:", data);
    setIsLoaded(true);
    let payload = {
      ...data,
      learnerId: getDataFromStorage("learnerid"),
      learnerName: getDataFromStorage("name"),
    };
    if (pathname === "/landingpage" || pathname === "/home") {
      payload.batchid = parseInt(localStorage.getItem("batchid")) || null;
      payload.batchname = localStorage.getItem("batchname") || null;
    }
    if (pathname === "/home") {
      // payload.lpid = parseInt(localStorage.getItem("lpId")) || null;
      payload.lpid = parseInt(sessionStorage.getItem("lpId")) || null;
      payload.lpname = localStorage.getItem("lpname") || null;
    }

    try {
      const response = await axios.post("node/learner/issue", payload, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      console.log("response", response.data);
      if (response.data.resultCode === constants.RESULT_STATUS.SUCCESS) {
        handleResponse("Success", response.data.msg);
        return "success";
      } else handleResponse("Warning", response.data.msg);
      return "failure";
    } catch (error) {
      console.error("Error creating Jira issue:", error);
      if (error.message.includes("403")) {
        handleResponse(
          "Warning",
          "You have been logged-out due to inactivity. Login again..",
          true
        );
      } else
        handleResponse(
          "Error",
          "Something went wrong. Please try again later or contact our team"
        );
      return "failure";
    } finally {
      setIsLoaded(false);
    }
  };

  return (
    <div className="floatingActionBtnContainer">
      <Popup />
      <Tooltip
        text={`If you encounter any issues, please report them using this "Issues" button.`}
        position="left"
      >
        <div id="floatingActionBtn" onClick={handleButtonClick}>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4 15C4 15 5 14 8 14C11 14 13 16 16 16C19 16 20 15 20 15V3C20 3 19 4 16 4C13 4 11 2 8 2C5 2 4 3 4 3V15Z"
              fill="white"
            />
            <path
              d="M4 15C4 15 5 14 8 14C11 14 13 16 16 16C19 16 20 15 20 15V3C20 3 19 4 16 4C13 4 11 2 8 2C5 2 4 3 4 3V15ZM4 15V22"
              stroke="white"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      </Tooltip>
      <IssueForm
        open={dialogOpen}
        handleClose={handleDialogClose}
        handleSubmit={handleFormSubmit}
      />
      {isLoaded ? <Loading /> : null}
    </div>
  );
};

export default FloatingActionButton;
