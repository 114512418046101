import React from "react";

export default function QuestionInfoSvgIcon() {
  return (
    <svg
      width="14"
      height="14"
      fill="none"
      viewBox="0 0 14 14"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_755_18139)">
        <path
          stroke="#B42318"
          stroke-width="1.2"
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M5.30258 5.2513C5.43973 4.86144 5.71042 4.5327 6.06672 4.3233C6.42302 4.11389 6.84194 4.03735 7.24927 4.10722C7.6566 4.17709 8.02606 4.38886 8.29221 4.70503C8.55836 5.0212 8.70403 5.42136 8.70341 5.83464C8.70341 7.0013 6.95341 7.58464 6.95341 7.58464M7.00008 9.91797H7.00591M12.8334 7.0013C12.8334 10.223 10.2217 12.8346 7.00008 12.8346C3.77842 12.8346 1.16675 10.223 1.16675 7.0013C1.16675 3.77964 3.77842 1.16797 7.00008 1.16797C10.2217 1.16797 12.8334 3.77964 12.8334 7.0013Z"
        />
      </g>
      <defs>
        <clipPath id="clip0_755_18139">
          <rect width="14" height="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
