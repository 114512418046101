import { useContext } from "react";
import { UserReportContext } from "../../../context/UserReportContext";

export default function UserTestResultReportTableHooks() {
  const UserReportCxt = useContext(UserReportContext);

  const calculateTotalEarnedPointsPerLearningpath = (lp) =>
    lp.modules.reduce((totalAcc, module) => {
      const modulePoints = module.test.reduce(
        (acc, curr) => acc + curr.pointsEarned,
        0
      );
      return totalAcc + modulePoints;
    }, 0);

  const generateDataForUserTestResultReportTable = (data) => {
    const result = [];

    data.forEach((item) => {
      let lp = result.find((lp) => lp.lpId === item.lpId);

      if (!lp) {
        lp = { lpId: item.lpId, lpName: item.lpName, modules: [] };
        result.push(lp);
      }

      let module = lp.modules.find((m) => m.mName === item.mName);

      if (!module) {
        module = { mName: item.mName, test: [] };
        lp.modules.push(module);
      }

      module.test.push({
        id: item.id,
        name: item.name,
        score: item.score,
        libtype: item.libtype,
        orderno: item.orderno,
        weightage: item.weightage,
        pointsEarned: item.pointsEarned,
        assignedDate: item.assignedDate,
      });
    });

    UserReportCxt.setUserTestResultTableData(result);
  };

  return {
    generateDataForUserTestResultReportTable,
    calculateTotalEarnedPointsPerLearningpath,
  };
}
