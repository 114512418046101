import React, { useContext, useEffect } from 'react';
import FormTitle from '../Student/Profile/FormTitle';
import GradiousLogoSvg from '../Student/CodeSection/CodeSection/Svg/GradiousLogo/GradiousLogoSvg';
import Skills from './Skills';
import Button from '../Button';
import constants from '../../constants';
import ResumeHooks from '../Admin/Batch/Users/ResumeHooks';

export default function GradiousEvaluation(props) {
    const {resumeData, getResumeData, getValue, updateResume, handleGcatChange, handleSkillChange, handleOverallFb,removedFunction} = ResumeHooks();

    useEffect(()=>{
        getResumeData(props.from,props.learnerId);
    },[props.learnerId]);

    let GCAT = typeof resumeData?.GCAT == "string"? JSON.parse(resumeData?.GCAT) : resumeData?.GCAT;

  return (
    <div>
        {resumeData && <div className='gradiousevaluation_parent'>
            <FormTitle icon={<GradiousLogoSvg />} title={"Gradious Evaluation"} />
            <div className='gcat_skill_div'>
                <div className='gcat_div'>
                    <div className='agc_div'>
                        <div className='label_gact'>{"Aptitude"}</div>
                        <div className='gcat_input_div'>
                            <input
                                className='editable_input_span'
                                type='number'
                                value={removedFunction(GCAT[constants.PROFILE_ATTRIBUTES.APTITUDE_SCORE]?.overrideValue,GCAT[constants.PROFILE_ATTRIBUTES.APTITUDE_SCORE]?.value)}
                                placeholder={"Enter mark"}
                                name="aptitude"
                                onChange={(e)=>handleGcatChange(e,constants.PROFILE_ATTRIBUTES.APTITUDE_SCORE)}
                            />
                        </div>
                    </div> 
                    <div className='agc_div'>
                        <div className='label_gact'>{"Grammer"}</div>
                        <div className='gcat_input_div'>
                            <input
                                className='editable_input_span'
                                type='number'
                                value={removedFunction(GCAT[constants.PROFILE_ATTRIBUTES.GRAMMAR_SCORE]?.overrideValue,GCAT[constants.PROFILE_ATTRIBUTES.GRAMMAR_SCORE]?.value)}
                                placeholder={"Enter mark"}
                                name="aptitude"
                                onChange={(e)=>handleGcatChange(e,constants.PROFILE_ATTRIBUTES.GRAMMAR_SCORE)}
                            />
                        </div>
                    </div> 
                    <div className='agc_div'>
                        <div className='label_gact'>{"Coding"}</div>
                        <div className='gcat_input_div'>
                            <input
                                className='editable_input_span'
                                type='number'
                                value={removedFunction(GCAT[constants.PROFILE_ATTRIBUTES.CODING_SCORE]?.overrideValue,GCAT[constants.PROFILE_ATTRIBUTES.CODING_SCORE]?.value)}
                                placeholder={"Enter mark"}
                                name="aptitude"
                                onChange={(e)=>handleGcatChange(e,constants.PROFILE_ATTRIBUTES.CODING_SCORE)}
                            />
                        </div>
                    </div>
                </div>
                <div className='skill_div'>
                    <Skills skill = {resumeData?.skills && resumeData?.skills} getValue={getValue} handleSkillChange={handleSkillChange}/>
                </div>
                <div className='overallfeedback_div'>
                    <label className='overallfeedback_label'>Overall Feedback</label>
                    <textarea className='overallfeedback_textarea' placeholder='Enter overall feedback, using # for new points' maxLength={"500"} name='overallfeedback' value={resumeData?.overallFeedback} onChange={(e)=>handleOverallFb(e)}>

                    </textarea>
                </div>
            </div>
            <div className='save_resumebtn_div'>
                <Button
                size={"sm"}
                onClick={()=>updateResume(props.learnerId)}
                disable={false}
                hierarchy={{
                    type: "primary",
                    buttonText: "Save",
                }}
                ></Button>
            </div>
        </div>}
    </div>
  )
}
