import React, { useContext, useEffect } from "react";
import FormTitle from "./FormTitle";
import Button from "../../Button";
import AcademicDetail from "./AcademicDetail";
import { ProfileContext } from "../../../context/ProfileContext";
import useAxiosPrivate from "../../../Hooks/useAxiosPrivate";
import { getDataFromStorage, isEnableProfileDetailsEdit } from "../../../util";
import constants from "../../../constants";
import AlertContext from "../../../context/AlertContext";

const AcademicWork = ({ from, learnerId }) => {
  const axios = useAxiosPrivate();
  const { setProfileDetails, navigate, onSaveProfileDetails } =
    useContext(ProfileContext);
  const { setIsLoaded, setShowNotify,setClickedMenu } = useContext(AlertContext);

  useEffect(() => {
    // const abortController = new AbortController();
    const handleGetAcademicWork = async () => {
      try {
        setIsLoaded(true);
        let id = getDataFromStorage("learnerid");
        if (from === "onpopup") {
          id = learnerId;
        }
        let response = await axios.get(`node/learner/profile/${id}/academic`, {
          headers: {
            "Content-Type": "application/json",
          },
          // signal: abortController.signal,
        });
        console.log("response", response);
        if (response.data.resultCode === constants.RESULT_STATUS.SUCCESS) {
          let details = response.data.data.result;
          if (
            details &&
            details !== null &&
            JSON.parse(details ?? "[]").length
          ) {
            setProfileDetails((prev) => ({
              ...prev,
              academic: JSON.parse(details),
            }));
          }
        } else
          setShowNotify({
            show: true,
            title: "Error !",
            msg: "Something went Wrong. Please try again later.",
          });
      } catch (error) {
        console.error("error in get academic work", error);
        if (error.message.includes("403")) {
          setShowNotify({
            show: true,
            title: "Warning !",
            msg: "You have been logged-out due to inactivity. Login again.",
            isUnAuth: true,
          });
        } else {
          setShowNotify({
            show: true,
            title: "Error !",
            msg: "Something went wrong. Please try again later or contact gradious team.",
          });
        }
      } finally {
        setIsLoaded(false);
      }
    };
    handleGetAcademicWork();
    // return () => abortController.abort();
  }, [axios, setIsLoaded, learnerId, from, setShowNotify, setProfileDetails]);

  const handleNext = async () => {
    if(from === "onpopup"){
      onSaveProfileDetails("academic",false,learnerId);
      setClickedMenu(5)
    }else{
      onSaveProfileDetails("",false,learnerId);
      navigate("/profile/socialprofile");
    }
  };

  const handlePrevious = () => {
    if(from ==="onpopup"){
       setClickedMenu(3);
    }else{
      navigate("/profile/education");
    }
    
  };

  return (
    <div className="academicContainer">
      <FormTitle
        icon={
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M22 7L14.1314 14.8686C13.7354 15.2646 13.5373 15.4627 13.309 15.5368C13.1082 15.6021 12.8918 15.6021 12.691 15.5368C12.4627 15.4627 12.2646 15.2646 11.8686 14.8686L9.13137 12.1314C8.73535 11.7354 8.53735 11.5373 8.30902 11.4632C8.10817 11.3979 7.89183 11.3979 7.69098 11.4632C7.46265 11.5373 7.26465 11.7354 6.86863 12.1314L2 17M22 7H15M22 7V14"
              stroke="#101828"
              strokeWidth="1.8"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        }
        title="Academic Work"
      />
      <div className="academicSection">
        <div className="academicDetailsSection">
          <AcademicDetail from={from} />
        </div>
      </div>
      {isEnableProfileDetailsEdit && (
        <div className="academicWorkBtnSec">
          <Button
            size="sm"
            hierarchy={{ type: "secondaryGrey", buttonText: "Previous" }}
            onClick={handlePrevious}
          />
          <Button
            size="sm"
            hierarchy={{ type: "primary", buttonText: "Next" }}
            onClick={handleNext}
          />
        </div>
      )}
    </div>
  );
};

export default AcademicWork;
