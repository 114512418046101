import { useContext } from "react";
import AlertContext from "../../../context/AlertContext";

export default function StudentTestPageQuestionUpdateHooks() {
  const Alert = useContext(AlertContext);

  const questionUpdate = (secIndex, quesIndex, theObject) => {
    Alert.setSecLen(theObject.sections.length);
    Alert.setSecInd(secIndex);
    Alert.setSecObj(theObject.sections[secIndex]);
    Alert.setQueLen(theObject.sections[secIndex].questions.length);
    Alert.setQueInd(quesIndex);
    Alert.setQueObj(theObject.sections[secIndex].questions[quesIndex]);
    Alert.setOptObj(theObject.sections[secIndex].questions[quesIndex]?.options);

    if (
      Alert.comingObj.sections?.length === 1 &&
      Alert.comingObj.sections[0].questions.length === 1
    ) {
      Alert.setPrevButton(false);
      Alert.setNextButton(false);
    } else {
      if (secIndex === 0 && quesIndex === 0) {
        Alert.setPrevButton(false);
        Alert.setNextButton(true);
      } else if (
        secIndex === Alert.mcqTestData.sections.length - 1 &&
        quesIndex ===
          Alert.mcqTestData.sections[Alert.mcqTestData.sections.length - 1]
            .questions.length -
            1
      ) {
        Alert.setPrevButton(true);
        Alert.setNextButton(false);
      } else {
        Alert.setNextButton(true);
        Alert.setPrevButton(true);
        return;
      }
    }
  };

  return { questionUpdate };
}
