import { nanoid } from "nanoid";
import Button from "../../Button";
import constants from "../../../constants";
import React, { useContext, useEffect, useState } from "react";
import CloseSvgIcon from "../../../Svg/CloseSvgIcon";
import AlertContext from "../../../context/AlertContext";
import DownloadSvgIcon from "../../../Svg/DownloadSvgIcon";
import AssignmentPageHooks from "../../../Hooks/AssignmentPageHooks";
import InputGroupField from "../../CommonComponents/InputGroupField";
import { getDataFromStorage } from "../../../util";
import useCommonFunctionHooks from "../../../Hooks/useCommonFunctionHooks";
import useSubmitAssignment from "../../../Hooks/useSubmitAssignment";
import { TextField } from "@mui/material";

// import Button from "../../Button";
export default function UploadAssignment() {
  const { getCatchBlockDetails, handleClose } = useCommonFunctionHooks();
  const submitAssignment = useSubmitAssignment();

  const [focusStates, setFocusStates] = useState({ learnernotes: false });
  const {
    lpId,
    status,
    testData,
    setStatus,
    setIsLoaded,
    learnerNotes,
    setShowNotify,
    setIsSubmitted,
    setLearnerNotes,
    setUpdateLeftNav,
    isEditSubmission,
    assignmentTestData,
    setIsAddSubmission,
    setSubmissionFiles,
    setIsEditSubmission,
    setAssignmentTestData,
    assignmentSubmissionFiles,
    setMsg,
    setShow,
    setTitle,
    learnerVideoLink,
    setLearnerVideoLink,
    submissionFiles,
  } = useContext(AlertContext);

  const { showPopUp } = AssignmentPageHooks();

  const [isFileChanged, setIsFileChanged] = useState(false);

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleSetSubmissionFile = () => {
    setSubmissionFiles(
      status === constants.PROGRESS_STATUS.UNLOCKED
        ? []
        : assignmentTestData?.anslinks !== null &&
          assignmentTestData?.anslinks !== undefined &&
          assignmentTestData?.anslinks !== "" &&
          JSON.parse(assignmentTestData?.anslinks).length !== 0
        ? JSON.parse(assignmentTestData.anslinks).map((link) => {
            let linkJson = {
              link: link,
              id: nanoid(),
              fileStatus: "old",
              isUploading: false,
              lastModified: assignmentTestData?.submitteddate,
              submissionDate: assignmentTestData?.submitteddate,
              name:
                link !== null ? link.substring(link.lastIndexOf("/") + 1) : "",
            };

            return linkJson;
          })
        : []
    );

    if (status === constants.PROGRESS_STATUS.UNLOCKED) setLearnerNotes("");
  };

  const handleCancel = () => {
    // clearPopupStates();
    handleClose();

    if (!isEditSubmission) {
      handleSetSubmissionFile();
      setIsAddSubmission(false);
    } else {
      setIsEditSubmission(false);
      setIsSubmitted(true);
      handleSetSubmissionFile();
      setIsAddSubmission(false);
      setStatus(constants.PROGRESS_STATUS.TO_BE_EVALUATED);
    }
  };

  const uploadFiles = async (files) => {
    const updatedFiles = files.map((file) => {
      let fileDetail = {
        file: file,
        progress: 0,
        id: nanoid(),
        isUploading: true,
        fileStatus: "new",
      };

      return fileDetail;
    });

    assignmentSubmissionFiles.current = [
      ...assignmentSubmissionFiles.current,
      ...updatedFiles,
    ];

    setSubmissionFiles((prevFiles) => [...prevFiles, ...updatedFiles]);

    showPopUp();
  };

  const handleDrop = (event) => {
    event.preventDefault();

    const fileExtentsion = [
      "image/png",
      "image/gif",
      "image/jpg",
      "video/mp4",
      "image/webp",
      "image/avif",
      "image/apng",
      "image/jpeg",
      "image/svg+xml",
      "application/pdf",
      "application/zip",
      "application/x-zip-compressed",
    ];

    const files = Array.from(event.dataTransfer.files);

    let isUploadable = true;

    for (let i = 0; i < files.length; i++) {
      if (!fileExtentsion.includes(files[i].type)) {
        isUploadable = false;
        break;
      }
    }

    if (isUploadable) {
      let filterFile = submissionFiles.filter(
        (file) => file.fileStatus !== "remove"
      );

      if (files.length > 1 || filterFile.length > 0) {
        setShow(true);
        setMsg("Multiple files are not allowed.");
        setTitle("Warning");
      } else uploadFiles(files);
    } else {
      setShow(true);
      setMsg("Please upload Supported formats(jpg,png,pdf,zip,video)");
      setTitle("Warning");
    }
    if (isEditSubmission || status === constants.PROGRESS_STATUS.REATTEMPT) {
      setIsFileChanged(true);
    }
  };

  const handleInputFile = (event) => {
    event.preventDefault();

    const files = Array.from(event.target.files);

    let filterFile = submissionFiles.filter(
      (file) => file.fileStatus !== "remove"
    );

    if (files.length > 1 || filterFile.length > 0) {
      setShow(true);
      setTitle("Warning");
      setMsg("Multiple files are not allowed.");
    } else uploadFiles(files);
    event.target.value = "";

    if (isEditSubmission || status === constants.PROGRESS_STATUS.REATTEMPT) {
      setIsFileChanged(true);
    }
  };

  const handleSubmitAssignment = async () => {
    try {
      setIsLoaded(true);

      let filterFile = assignmentSubmissionFiles.current.filter(
        (file) => file.fileStatus !== "remove"
      );

      console.log("cALLING apiii", learnerNotes, learnerVideoLink);

      if (
        filterFile.length === 1 ||
        (learnerVideoLink !== "" && learnerVideoLink != null)
      ) {
        console.log("learnerVideoLink-----???", learnerVideoLink);

        let payloadArg = {
          lpId,
          isSave: true,
          libId: testData.libid,
          // file: filterFile[0].file,
          lpConfigId: testData.lpconfigid,
          learnerNotes: learnerNotes ? learnerNotes : "",
          uploadedlink: learnerVideoLink ? learnerVideoLink : "",
          // filePath:
          //   "library/submission/" +
          //   getDataFromStorage("learnerid") +
          //   "/" +
          //   filterFile[0].file.name,
        };
        if (filterFile.length === 1) {
          payloadArg.file = filterFile[0].file;
          payloadArg.filePath =
            "library/submission/" +
            getDataFromStorage("learnerid") +
            "/" +
            filterFile[0].file.name;
        }

        let response = await submitAssignment(payloadArg);

        if (response.data.resultCode !== constants.RESULT_STATUS.SUCCESS) {
          setShowNotify({
            show: true,
            title: "Error",
            msg:
              response.data === ""
                ? "Something went wrong. Please try again later"
                : response.data.message,
          });
        } else {
          let newFiles = [...filterFile];

          for (let file = 0; file < newFiles.length; file++) {
            newFiles[file] =
              "library/submission/" +
              getDataFromStorage("learnerid") +
              "/" +
              newFiles[file].file.name;
          }

          let assignmentData = JSON.parse(JSON.stringify(assignmentTestData));

          setUpdateLeftNav(true);
          assignmentData.commentsdata = [];
          assignmentData.answer = learnerNotes;
          assignmentData.submitteddate = new Date();
          assignmentData.anslinks = JSON.stringify(newFiles);
          assignmentData.uploadedlink = learnerVideoLink;
          setAssignmentTestData(assignmentData);
          setStatus(constants.PROGRESS_STATUS.TO_BE_EVALUATED);
          setIsSubmitted(true);
          setIsAddSubmission(false);
          setIsEditSubmission(false);
          assignmentSubmissionFiles.current = [];
          // clearPopupStates();
          handleClose();
        }
      } else if (
        filterFile.length === 0 &&
        (learnerVideoLink == "" ||
          learnerVideoLink == undefined ||
          learnerVideoLink == null)
      ) {
        setShowNotify({
          show: true,
          title: "Warning",
          msg: "No files selected. Please choose a file to upload.",
        });
      } else if (filterFile.length > 0) {
        setShowNotify({
          show: true,
          title: "Warning",
          msg: "Multiple files are not allowed.",
        });
      }
    } catch (err) {
      getCatchBlockDetails(err);
    } finally {
      setIsLoaded(false);
    }
  };

  const handleFieldFocus = (name) => {
    setFocusStates({ ...focusStates, [name]: true });
  };

  const handleFieldBlur = (name) => {
    setFocusStates({ ...focusStates, [name]: false });
  };

  const handlClearUploadedFile = (fileId) => {
    let newFiles = [...submissionFiles];

    for (let i = 0; i < newFiles.length; i++) {
      if (newFiles[i].id === fileId) newFiles[i].fileStatus = "remove";
    }

    assignmentSubmissionFiles.current = newFiles;
    setSubmissionFiles([...newFiles]);
  };

  return (
    <div className="uploadAssignmentMainContainer">
      <div
        className={
          submissionFiles.length === 0
            ? "uploadAssignment"
            : "uploadAssignmentSubmitted"
        }
      >
        <div
          onDrop={handleDrop}
          onDragOver={handleDragOver}
          className="uploadAssignmentSection"
        >
          Drop your assignment
          <div className="uploadAssignmentSupportedFormat">
            Supported formats (jpg, png, pdf, zip, video)
          </div>
          <input
            type="file"
            onChange={handleInputFile}
            accept="video/*,image/*,.docs,.zip"
            className="uploadAssignmentFileInput"
          />
          <DownloadSvgIcon component="uploadAssignment" />
          <div className="uploadAssignmentButton">
            <Button
              size={"sm"}
              hierarchy={{
                type: "linkColor",
                buttonText: "Upload File",
              }}
            />
          </div>
        </div>
      </div>
      {
        // (submissionFiles.length > 0 && submissionFiles[0].link != null &&  submissionFiles[0].link != "null") &&
        submissionFiles.map(
          (file) =>
            (file.fileStatus === "new" || file.fileStatus === "old") && (
              <div key={file.id} className="uploadAssignmentSubmittedFile">
                <div className="uploadAssignmentSubmittedFileDiv">
                  <div className="uploadAssignmentSubmittedFileDivForNameAndSize">
                    {file.hasOwnProperty("file") ? file.file.name : file.name}
                    <span className="uploadAssignmentSubmittedFileDivForNameAndSizeValue">
                      {file.hasOwnProperty("file") &&
                      file.file.size !== undefined
                        ? (file.file.size / 1e6).toFixed(2) > 1
                          ? `${(file.file.size / 1e6).toFixed(2)} Mb`
                          : `${(file.file.size / 1024).toFixed(2)} Kb`
                        : ""}
                    </span>
                  </div>
                  <CloseSvgIcon
                    onClick={() => handlClearUploadedFile(file.id)}
                  />
                </div>
              </div>
            )
        )
      }

      {/* <textarea
        value={learnerVideoLink}
        disabled={submissionFiles[0].fileStatus!=="remove"}
        className="uploadAssignmentVideoLink"
        placeholder="Type your link here."
        onChange={(event) => setLearnerVideoLink(event.target.value)}
      /> */}
      <InputGroupField
        disabled={false}
        key={1}
        title={"Or provide a link for your submission"}
        // groupText="https://"
        placeholder={"www.untitledui.com"}
        value={learnerVideoLink}
        onChange={(event) => {
          setLearnerVideoLink(event.target.value);
        }}
        name={"learnervideolink"}
      />
      {/* <textarea
        value={learnerNotes}
        className="uploadAssignmentNotes"
        placeholder="Type your note here."
        onChange={(event) => setLearnerNotes(event.target.value)}
      /> */}
      <TextField
        id="personalInfoCareerObj"
        label="Type your note here"
        name="learnernotes"
        InputLabelProps={{
          shrink: focusStates["learnernotes"] || Boolean(learnerNotes),
        }}
        variant="outlined"
        size="small"
        multiline={true}
        // fullWidth
        value={learnerNotes}
        inputProps={{
          onFocus: (event) => handleFieldFocus(event.target.name),
          onBlur: (event) => handleFieldBlur(event.target.name),
          maxLength: 1000,
        }}
        onChange={(event) => setLearnerNotes(event.target.value)}
      />
      {/* <InputGroupField
        disabled={false}
        key={2}
        multiline={true}
        // title={"Or provide a link for your submission"}
        // groupText="https://"
        placeholder="Add note for trainer here."
        value={learnerNotes}
        onChange={(event) => setLearnerNotes(event.target.value)}
        name={"learnernotes"}
      /> */}

      <div className="btnCont">
        <Button
          disable={false}
          size={"lg"}
          onClick={handleCancel}
          hierarchy={{
            buttonText: "Cancel",
            type: "secondaryGrey",
          }}
        />
        <Button
          disable={false}
          size={"lg"}
          onClick={handleSubmitAssignment}
          hierarchy={{
            buttonText: "Submit",
            type: "primary",
          }}
        />
      </div>
    </div>
  );
}
