// src/IssueForm.js
import React, { useState } from "react";
import {
  Paper,
  Typography,
  TextField,
  IconButton,
  MenuItem,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Button from "../Button";

const issueCategories = ["Platform/app", "Learning"];

const IssueForm = ({ open, handleClose, handleSubmit }) => {
  const [formData, setFormData] = useState({
    category: "",
    summary: "",
    description: "",
  });
  const isDisabled = Boolean(formData.category && formData.summary);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleFormSubmit = async () => {
    try {
      let res = await handleSubmit(formData);
      if (res === "success") {
        setFormData({ category: "", summary: "", description: "" });
        handleClose();
      }
    } catch (error) {
      console.error("Error while submit form", error);
    }
  };

  if (!open) return null;

  return (
    <Paper elevation={3} className="issueFormContainer">
      <div className="issueFormTitleSec">
        <Typography variant="h6" id="issueFormTitle">
          Raise an Issue
        </Typography>
        <IconButton
          size="small"
          id="issueFormCloseBtnSec"
          onClick={() => {
            handleClose();
            setFormData({ category: "", summary: "", description: "" });
          }}
        >
          <CloseIcon id="issueFormCloseBtn" />
        </IconButton>
      </div>
      <div className="issueFormFieldSec">
        <TextField
          id="issueFormCategoryField"
          select
          margin="dense"
          name="category"
          label="Category"
          fullWidth
          variant="outlined"
          value={formData.category}
          onChange={handleChange}
        >
          {issueCategories.map((option) => (
            <MenuItem key={option} value={option} id="issueFormCategoryItem">
              {option}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          id="issueFormSummaryField"
          margin="dense"
          name="summary"
          label="Summary"
          type="text"
          inputProps={{
            maxLength: 250,
          }}
          fullWidth
          variant="outlined"
          value={formData.summary}
          onChange={handleChange}
        />
        <TextField
          id="issueFormDescriptionField"
          margin="dense"
          name="description"
          label="Description"
          type="text"
          fullWidth
          rows={4}
          multiline
          inputProps={{
            maxLength: 1000,
          }}
          variant="outlined"
          value={formData.description}
          onChange={handleChange}
        />
      </div>
      <div className="issueFormButtonSec">
        <Button
          disable={!isDisabled}
          size="sm"
          hierarchy={{ type: "primary", buttonText: "Submit" }}
          onClick={handleFormSubmit}
        />
      </div>
    </Paper>
  );
};

export default IssueForm;
