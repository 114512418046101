import React, { createContext, useState } from "react";

export const UserReportContext = createContext();

export const UserReportProvider = ({ children }) => {
  const [labels, setLabels] = useState([]);
  const [material, setMaterial] = useState([]);
  const [assignment, setAssignment] = useState([]);
  const [assessment, setAssessment] = useState([]);
  const [learnerList, setLearnerList] = useState([]);
  const [showPage, setShowPage] = useState("testresults");
  const [selectedMonth, setSelectedMonth] = useState(new Date());
  const [userTimeSpentDetails, setUserTimeSpentDetails] = useState([]);
  const [userTimeSpentTableData, setUserTimeSpentTableData] = useState([]);
  const [userTestResultTableData, setUserTestResultTableData] = useState([]);

  const [userTimeSpentModuleData, setUserTimeSpentModuleData] = useState({
    lpIds: "",
    moduleIds: "",
  });

  const [currentLearnerDetails, setCurrentLearnerDetails] = useState({
    value: 0,
    label: "",
  });

  const [userTimeSpentModuleOption, setUserTimeSpentModuleOption] = useState(
    []
  );

  return (
    <UserReportContext.Provider
      value={{
        labels,
        showPage, 
        material,
        setLabels,
        assignment,
        assessment,
        learnerList,
        setMaterial,
        setShowPage,
        setAssignment,
        setAssessment,
        selectedMonth,
        setLearnerList,
        setSelectedMonth,
        userTimeSpentDetails,
        currentLearnerDetails,
        userTimeSpentTableData,
        setUserTimeSpentDetails,
        userTestResultTableData,
        userTimeSpentModuleData,
        setCurrentLearnerDetails,
        userTimeSpentModuleOption,
        setUserTimeSpentTableData,
        setUserTestResultTableData,
        setUserTimeSpentModuleData,
        setUserTimeSpentModuleOption,
      }}
    >
      {children}
    </UserReportContext.Provider>
  );
};
