import Button from "../../Button";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import AddSvg from "../../../Svg/AddSvg";
import React, { useContext } from "react";
import IconButton from "@mui/material/IconButton";
import PlayIconSvg from "../../../Svg/PlayIconSvg";
import ChevronUpSvg from "../../../Svg/ChevronUpSvg";
import CloseSvgIcon from "../../../Svg/CloseSvgIcon";
import AlertContext from "../../../context/AlertContext";
import CodingOutputHooks from "../../../Hooks/CodingPage/CodingOutputHooks";

export default function CodingOutput(Props) {
  const Alert = useContext(AlertContext);

  const CodingOutputHks = CodingOutputHooks();

  Alert.testCasesRef.current.sort((testcase1, testcase2) => {
    const array1 = testcase1.isexample;
    const array2 = testcase2.isexample;

    return array1 - array2;
  });

  return (
    <div className="codingOutputMainContainer">
      <div
        className="codingOutputHeader"
        onClick={Props.toggleOutputWindowHeight}
      >
        <div className="codingOutputHeaderTitle">Testcases</div>
        <ChevronUpSvg />
      </div>
      <div className="codingOutputTestcasesDiv">
        <div className="codingOutputTestcasesDivBox">
          <Box
            sx={{
              overflowX: "auto",
              bgcolor: "background.paper",
              maxWidth: { xs: 320, sm: 900 },
            }}
          >
            <Tabs
              value={CodingOutputHks.value}
              variant="scrollable"
              scrollButtons={false}
              onChange={CodingOutputHks.handleTabChange}
              className="codingOutputTestcasesDivBoxTab"
              TabIndicatorProps={{ style: { display: "none" } }}
            >
              {Alert.testCasesRef.current.length > 0
                ? Alert.testCasesRef.current.map((tab, index) => (
                    <Tab
                      key={index}
                      label={`Case ${index + 1}`}
                      className={CodingOutputHks.handleCodingOutputTestcasesBoxTab(
                        tab,
                        index
                      )}
                      icon={
                        !tab.hasOwnProperty("isexample") && (
                          <IconButton
                            onClick={() => CodingOutputHks.deleteTab(index)}
                            className="codingOutputTestcasesBoxTabCloseSvgIcon"
                          >
                            <CloseSvgIcon component="codingOutput" />
                          </IconButton>
                        )
                      }
                    />
                  ))
                : CodingOutputHks.addTab()}
            </Tabs>
          </Box>
        </div>
        <div className="codingOutputTestcasesBoxTabAddSvgIcon">
          <IconButton onClick={CodingOutputHks.addTab}>
            <AddSvg />
          </IconButton>
        </div>
      </div>
      <div className="codingOutputTestcasesContainer">
        {Alert.testCasesRef.current.length > 0 &&
          Alert.testCasesRef.current.map((tab, index) => (
            <>
              <div
                key={index}
                hidden={CodingOutputHks.value !== index}
                className="codingOutputTestcasesInputContainer"
              >
                <div className="codingOutputTestcasesInnerInputContainer">
                  {tab.input.split("\n").map((input, ipIndex) => (
                    <div
                      key={ipIndex}
                      className="codingOutputTestcasesInnerInputContainerDiv"
                    >
                      <label className="codingOutputTestcasesInputVariable">
                        {Alert.isInputVariable.current
                          ? Alert.inputVariable[ipIndex] + " : "
                          : "Input " + (ipIndex + 1) + ""}
                      </label>
                      <input
                        type="text"
                        value={input === "false" ? "" : input}
                        className="codingOutputTestcasesInputValue"
                        disabled={tab.hasOwnProperty("isexample")}
                        onChange={(event) =>
                          CodingOutputHks.handleTestCaseInputChange(
                            event,
                            tab.id,
                            ipIndex
                          )
                        }
                      />
                    </div>
                  ))}
                </div>
                <div className="codingOutputTestcasesOutputContainer">
                  <div className="codingOutputTestcasesActualOutputContainerDiv">
                    <label
                      htmlFor="codingOutputTestcasesActualOutput"
                      className="codingOutputTestcasesActualOutputName"
                    >
                      Actual Output
                    </label>
                    <div className="codingOutputTestcasesActualOutputValue">
                      {tab.actualoutput}
                    </div>
                  </div>
                  <div className="codingOutputTestcasesExpectedOutputContainerDiv">
                    <label
                      htmlFor="codingOutputTestcasesExpectedOutput"
                      className="codingOutputTestcasesExpectedOutputName"
                    >
                      Expected Output
                    </label>
                    <div className="codingOutputTestcasesExpectedOutputValue">
                      {tab.output}
                    </div>
                  </div>
                </div>
              </div>
            </>
          ))}
        <div className="codingOutputButtonDiv">
          <Button
            size={"md"}
            onClick={CodingOutputHks.handleRunCurrentTestCase}
            icon={{ type: "leading", icon: <PlayIconSvg /> }}
            hierarchy={{
              buttonText: "Run",
              type: "secondaryColor",
            }}
          />
        </div>
      </div>
    </div>
  );
}
