export default function TestDueHooks() {
  const currentDueLabel = (date) => {
    const dueDate = new Date(date);
    const currentDate = new Date();

    const timeDifference = dueDate.getTime() - currentDate.getTime();
    const dayDifference = Math.floor(timeDifference / (1000 * 3600 * 24));

    if (dayDifference === 0) {
      return "Due Today";
    } else if (
      dayDifference === 1 ||
      (dayDifference === 0 && dueDate !== currentDate)
    ) {
      return "Due Tomorrow";
    } else if (dayDifference < 0) {
      return "Past Due";
    } else {
      return "Upcoming";
    }
  };

  return { currentDueLabel };
}
