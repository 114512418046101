import React from "react";

export default function ImageLogoSvgIcon() {
  return (
    <svg
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="#D8D8FF"
        d="M13.5 9C13.1022 9 12.7206 8.84196 12.4393 8.56066C12.158 8.27936 12 7.89782 12 7.5C12 7.10218 12.158 6.72064 12.4393 6.43934C12.7206 6.15804 13.1022 6 13.5 6C13.8978 6 14.2794 6.15804 14.5607 6.43934C14.842 6.72064 15 7.10218 15 7.5C15 7.89782 14.842 8.27936 14.5607 8.56066C14.2794 8.84196 13.8978 9 13.5 9Z"
      />
      <path
        fill="#B2B1FF"
        d="M19 2H5C4.20508 2.00237 3.4434 2.31921 2.8813 2.8813C2.31921 3.4434 2.00237 4.20508 2 5V13.86L5.88 9.98C6.4517 9.43586 7.21074 9.13238 8 9.13238C8.78926 9.13238 9.5483 9.43586 10.12 9.98L12.991 12.867L13.879 11.979C14.4426 11.4185 15.2052 11.1039 16 11.1039C16.7948 11.1039 17.5574 11.4185 18.121 11.979L22 15.86V5C21.9976 4.20508 21.6808 3.4434 21.1187 2.8813C20.5566 2.31921 19.7949 2.00237 19 2ZM13.5 9C13.1022 9 12.7206 8.84196 12.4393 8.56066C12.158 8.27936 12 7.89782 12 7.5C12 7.10218 12.158 6.72064 12.4393 6.43934C12.7206 6.15804 13.1022 6 13.5 6C13.8978 6 14.2794 6.15804 14.5607 6.43934C14.842 6.72064 15 7.10218 15 7.5C15 7.89782 14.842 8.27936 14.5607 8.56066C14.2794 8.84196 13.8978 9 13.5 9Z"
      />
      <path
        fill="#6563FF"
        d="M10.12 9.97994C9.5483 9.4358 8.78926 9.13232 8 9.13232C7.21074 9.13232 6.4517 9.4358 5.88 9.97994L2 13.8599V18.9999C2.00237 19.7949 2.31921 20.5565 2.8813 21.1186C3.4434 21.6807 4.20508 21.9976 5 21.9999H19C19.815 21.9999 20.595 21.6669 21.16 21.0799L10.12 9.97994Z"
      />
      <path
        fill="#D8D8FF"
        d="M22.0012 15.8581L18.1222 11.9791C17.5586 11.4186 16.796 11.104 16.0012 11.104C15.2063 11.104 14.4438 11.4186 13.8802 11.9791L12.9922 12.8671L21.1572 21.0761C21.6992 20.5211 22.0022 19.7761 22.0012 19.0001V15.8581Z"
      />
    </svg>
  );
}
