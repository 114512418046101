import React from "react";

export default function RightSvgIcon(Props) {
  return (
    <svg
      width="36"
      height="36"
      fill="none"
      viewBox="0 0 36 36"
      onClick={Props.onClick}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        stroke="#12B76A"
        stroke-width="1.66667"
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M24.6673 13L15.5007 22.1667L11.334 18"
      />
    </svg>
  );
}
