import constants from "../../../../../constants";
import { useContext, useRef, useState } from "react";
import AlertContext from "../../../../../context/AlertContext";

export default function CodingQuestionEntryHooks() {
  const { setShow, navigate, resultCode,tableShowStatus } = useContext(AlertContext);

  let [inva, setInVa] = useState([]);
  let [cuin, setCuin] = useState([]);
  let [init, setInit] = useState(false);
  let [inVaTypes, setInVaTypes] = useState([]);
  let [ioVarsData, setIoVarsData] = useState([]);
  let [masterData, setMasterData] = useState({});
  let [evaluationRules, setEvaluationRules] = useState([]);
  let [alreadySelected, setAlreadySelected] = useState(null);
  let [isSelectedTopic, setIsSelectedTopic] = useState(true);
  let [enableTestButton, setEnableTestButton] = useState(false);
  let [enableSaveButton, setEnableSaveButton] = useState(false);
  let [showNotification, setShowNotification] = useState(false);
  let [alreadySelectedCom, setAlreadySelectedCom] = useState(null);
  let [languageSelectedList, setLanguageSelectedList] = useState([]);
  let [isSelectedComplexity, setIsSelectedComplexity] = useState(true);

  let [languagesOfPassedTestCases, setLanguagesOfPassedTestCases] = useState(
    []
  );

  const [role, setRole] = useState("");
  const [testMsg, setTestMsg] = useState();
  const [isTest, setIsTest] = useState(false);
  const [evalRule, setEvalRule] = useState([]);
  const [langLabel, setLangLabel] = useState([]);
  const [isChange, setIsChange] = useState(false);
  const [isChecked, setIsChecked] = useState(true);
  const [inputVarsObj, setInputVarsObj] = useState([]);
  const [languageData, setLanguageData] = useState([]);
  const [active, setActive] = useState(languageData[0]);
  const [languagesData, setLanguagesData] = useState([]);
  const [activeLanguage, setActiveLanguage] = useState("");
  const [isNoTestCases, setIsNoTestCases] = useState(false);
  const [libTypeIdByParent, setLibTypeIdByParent] = useState(0);
  const [fromEditQuestion, setFromEditQuestion] = useState(false);
  const [libTypeNameByParent, setLibTypeNameByParent] = useState(null);

  let varTypeRef = useRef([]);

  const editorRef = useRef(null);

  const settingCuin = (usin) => {
    let temp = [...usin];

    setCuin(temp);
  };

  const customStylesForTopic = {
    control: (base, state) => ({
      ...base,
      marginTop: "20px",
      border: "1px solid #BDC1C6",
      minHeight: "34px !important",
      maxHeight: "34px !important",
    }),
    option: (styles, { isSelected }) => {
      return {
        ...styles,
        width: "200px",
        height: "28px",
        fontSize: "14px",
        fontWeight: "400",
        lineHeight: "20px",
        background: "white",
        fontFamily: "Inter",
        color: isSelected ? "#000000" : undefined,
        ":hover": {
          color: "white",
          backgroundColor: "#0d6efd",
        },
      };
    },
    singleValue: (styles, { data }) => ({
      ...styles,
      color: "#202124",
      fontSize: "14px",
      fontWeight: "400",
      textAlign: "left",
      lineHeight: "20px",
      fontFamily: "Inter",
      paddingLeft: "10px",
      fontStyle: "normal",
      borderRadius: "20px",
    }),
    menuList: (base) => ({
      ...base,
      textAlign: "left",
      maxHeight: "150px",
      borderRadius: "2px",
    }),
  };

  const cancelClikedInConfirmBox = () => {
    setShowNotification(false);
    navigate(`/library/${tableShowStatus}/questionlist`);
  };

  const handleNavigate = () => {
    if (resultCode === constants.RESULT_STATUS.SUCCESS) {
      setShow(false);
      navigate(`/library/${tableShowStatus}/questionlist`);
    }
  };

  const handleEvaluationChange = (e) => {
    setEvalRule(e);
  };

  const isOptionDisabled = (option) => {
    const selectedValues = evalRule.map(
      (selectedOption) => selectedOption.value
    );

    // If options 1 or 2 are selected, disable options 3 to 8

    if (selectedValues.includes(1) || selectedValues.includes(2)) {
      return option.value >= 3 && option.value <= 8;
    }

    // If an option between 3 and 8 is selected, disable all other options

    if (selectedValues.some((value) => value >= 3 && value <= 8)) {
      return option.value !== selectedValues[0];
    }

    return false;
  };

  const removeLanguageFromPassedTestCases = (languageName) => {
    let temp = languagesOfPassedTestCases;

    temp = temp.filter((lang) => lang !== languageName);
    setLanguagesOfPassedTestCases(temp);
  };

  const setSelectedLanguage = (language) => {
    setActiveLanguage(language);
  };

  const handleBackFromQuestion = () => {
    navigate(`/library/${tableShowStatus}/questionlist`);
  };

  const handleNoTestCases = () => {
    setIsNoTestCases(!isNoTestCases);
  };

  return {
    inva,
    cuin,
    role,
    init,
    active,
    isTest, 
    setInit,
    setRole,
    setInVa,
    setCuin,
    testMsg,
    evalRule,
    isChange,
    setIsTest,
    setActive,
    inVaTypes,
    editorRef,
    isChecked,
    langLabel,
    setTestMsg,
    varTypeRef,
    ioVarsData,
    masterData,
    setIsChange,
    settingCuin,
    setEvalRule,
    inputVarsObj,
    setLangLabel,
    setIsChecked,
    setInVaTypes,
    languageData,
    isNoTestCases,
    setMasterData,
    languagesData,
    setIoVarsData,
    handleNavigate,
    activeLanguage,
    setLanguageData,
    setInputVarsObj,
    evaluationRules,
    alreadySelected,
    isSelectedTopic,
    setLanguagesData,
    setIsNoTestCases,
    isOptionDisabled,
    enableTestButton,
    enableSaveButton,
    fromEditQuestion,
    showNotification,
    setActiveLanguage,
    handleNoTestCases,
    libTypeIdByParent,
    setEvaluationRules,
    setAlreadySelected,
    alreadySelectedCom,
    setIsSelectedTopic,
    setSelectedLanguage,
    setShowNotification,
    setFromEditQuestion,
    setEnableTestButton,
    setEnableSaveButton,
    libTypeNameByParent,
    setLibTypeIdByParent,
    customStylesForTopic,
    languageSelectedList,
    isSelectedComplexity,
    setAlreadySelectedCom,
    setLibTypeNameByParent,
    handleBackFromQuestion,
    handleEvaluationChange,
    setLanguageSelectedList,
    setIsSelectedComplexity,
    cancelClikedInConfirmBox,
    languagesOfPassedTestCases,
    setLanguagesOfPassedTestCases,
    removeLanguageFromPassedTestCases,
  };
}
