import React from 'react'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { MobileTimePicker } from '@mui/x-date-pickers';

export default function Timepicker(props) {
  return (
    <div>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <MobileTimePicker
              value={props.selectedTime}
              onChange={(newTime) => props.setSelectedTime(newTime)}
            />
        </LocalizationProvider>
    </div>
  )
}
