import "moment-timezone";
import { nanoid } from "nanoid";
import Button from "../../Button";
import Comments from "../../Comments";
import { Drawer } from "@mui/material";
import constants from "../../../constants";
import { Editor } from "@tinymce/tinymce-react";
import Popup from "../../CommonComponents/Popup";
import React, { useEffect, useContext } from "react";
import TestDueHooks from "../../../Hooks/TestDueHooks";
import AlertContext from "../../../context/AlertContext";
import CommentsHooks from "../../../Hooks/CommentsHooks";
import PdfViewer from "../../CommonComponents/PdfViewer";
import { faLink } from "@fortawesome/free-solid-svg-icons";
import DownloadSvgIcon from "../../../Svg/DownloadSvgIcon";
import RightArrowSvgIcon from "../../../Svg/RightArrowSvgIcon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AssignmentSubmissionTable from "./AssignmentSubmissionTable";
import AssignmentPageHooks from "../../../Hooks/AssignmentPageHooks";

let numPages = [];

// pdfjs.GlobalWorkerOptions.workerSrc = new URL(
//   "pdfjs-dist/build/pdf.worker.min.js",
//   import.meta.url
// ).toString();

export default function AssignmentPage() {
  const {
    status,
    isAdmin,
    navigate,
    testData,
    learnerId,
    isSubmitted,
    setLearnerId,
    showComments,
    changelearner,
    isCommonPopup,
    setCommentsData,
    setLearnerNotes,
    setChangeLearner,
    setSubmissionFiles,
    assignmentTestData,
    setIsAddSubmission,
    learnerVideoLink,
    setLearnerVideoLink,
  } = useContext(AlertContext);

  const {
    diff,
    referenceLinks,
    handleGetLinks,
    setReferenceLinks,
    openSandPackEditor,
    handleAddAssignment,
    handleEditSubmission,
    handleExitFullScreen,
    assignmentReferenceLinkRef,
    handleRequestFullScreenMode,
    handleDownloadReferenceFileForLearner,
  } = AssignmentPageHooks();

  const { toggleDrawer } = CommentsHooks();
  const { currentDueLabel } = TestDueHooks();

  const imgExtensions = ["png", "jpeg", "jpg", "jfif", "gif"];

  useEffect(() => {
    if (
      assignmentTestData.hasOwnProperty("answer") &&
      assignmentTestData?.answer !== "" &&
      assignmentTestData?.answer !== null
    ) {
      setLearnerNotes(assignmentTestData.answer);
    }
    if (
      assignmentTestData.hasOwnProperty("uploadedlink") &&
      assignmentTestData?.uploadedlink !== "" &&
      assignmentTestData?.uploadedlink !== null
    ) {
      setLearnerVideoLink(assignmentTestData.uploadedlink);
    }
  }, [assignmentTestData, setLearnerNotes]);


  /* comment state */

  /* changing learner set comment data useeffect */

  useEffect(() => {
    if (changelearner) {
      if (assignmentTestData?.commentsdata.length > 0) {
        setCommentsData(assignmentTestData.commentsdata);
      } else {
        setCommentsData([]);
      }
      setChangeLearner(false);
    } else {
      setCommentsData(assignmentTestData.commentsdata);
    }
  }, [changelearner, assignmentTestData, setChangeLearner, setCommentsData]);

  useEffect(() => {
    setSubmissionFiles(
      status === constants.PROGRESS_STATUS.UNLOCKED
        ? []
        : assignmentTestData?.anslinks !== "" &&
          assignmentTestData?.anslinks !== null &&
          assignmentTestData?.anslinks !== undefined &&
          JSON.parse(assignmentTestData?.anslinks).length !== 0
        ? JSON.parse(assignmentTestData.anslinks)
            .filter((link) => link !== null && link !== "null") // Filter out null links
            .map((link) => {
              let linkjson = {
                link: link,
                id: nanoid(),
                fileStatus: "old",
                isUploading: false,
                lastModified: assignmentTestData?.submitteddate,
                submissionDate: assignmentTestData?.submitteddate,
                name:
                link !== null ? link.substring(link.lastIndexOf("/") + 1) : "", // No need to check for null again
              };

              return linkjson;
            })
        : []
    );
    setLearnerId(learnerId);
  }, [isAdmin, assignmentTestData, status]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const links = await handleGetLinks();

        setReferenceLinks(links);
      } catch (error) {
        setReferenceLinks([]);
      }
    };

    fetchData();
  }, [handleGetLinks]);

  const onDocumentLoadSuccess = (numPageDetail, index) =>
    (numPages[index] = numPageDetail._pdfInfo.numPages);

  window.onload = () => (isAdmin ? navigate("/batches") : navigate("/home"));

  useEffect(() => {
    setIsAddSubmission(false);
    document.addEventListener("fullscreenchange", handleExitFullScreen);

    return () => {
      document.removeEventListener("fullscreenchange", handleExitFullScreen);
    };
  }, []);

  return (
    <div className={isAdmin ? "assignmentPageForAdmin" : "assignmentPage"}>
      {isCommonPopup && (
        <div className="popup-overlay">
          <Popup />
        </div>
      )}
      <div className="assignmentPageSubmissionButtonDiv">
        <div className="assignmentPageSubmissionButton">
          {!isAdmin &&
            assignmentTestData.hasOwnProperty("commentsdata") &&
            assignmentTestData.commentsdata.length !== 0 && (
              <Button
                size="sm"
                hierarchy={{
                  type: "secondaryGrey",
                  buttonText: "View Comments",
                }}
                onClick={toggleDrawer("right", true)}
              />
            )}
          {(status === constants.PROGRESS_STATUS.EVALUATED ||
            status === constants.PROGRESS_STATUS.TO_BE_EVALUATED) &&
            ((!isAdmin &&
              currentDueLabel(testData.duedate) !== "Past Due" &&
              status !== constants.PROGRESS_STATUS.EVALUATED) ||
              assignmentTestData.hasOwnProperty("template")) && (
              <Button
                size={"sm"}
                hierarchy={{
                  type: "secondaryColor",
                  buttonText:
                    assignmentTestData.hasOwnProperty("template") &&
                    assignmentTestData.template !== "" &&
                    assignmentTestData.template !== null
                      ? !isAdmin &&
                        currentDueLabel(testData.duedate) !== "Past Due" &&
                        status === constants.PROGRESS_STATUS.TO_BE_EVALUATED
                        ? "Edit Code  "
                        : "View Code"
                      : !isAdmin &&
                        status !== constants.PROGRESS_STATUS.EVALUATED &&
                        currentDueLabel(testData.duedate) !== "Past Due" &&
                        "Edit Submission  ",
                }}
                onClick={
                  assignmentTestData.hasOwnProperty("template") &&
                  assignmentTestData.template !== null &&
                  assignmentTestData.template !== ""
                    ? openSandPackEditor
                    : !isAdmin &&
                      status !== constants.PROGRESS_STATUS.EVALUATED &&
                      (!isSubmitted
                        ? handleAddAssignment
                        : diff > 0 && handleEditSubmission)
                }
              />
            )}
        </div>
        {(status === constants.PROGRESS_STATUS.EVALUATED ||
          status === constants.PROGRESS_STATUS.TO_BE_EVALUATED ||
          status === constants.PROGRESS_STATUS.REATTEMPT) && (
          <AssignmentSubmissionTable />
        )}
      </div>
      <div className="assignmentPageDescription">
        {(status === constants.PROGRESS_STATUS.EVALUATED ||
          status === constants.PROGRESS_STATUS.TO_BE_EVALUATED) && (
          <div className="assignmentPageSubmissionHeading">Questions :</div>
        )}
        {!isCommonPopup &&
          assignmentTestData?.hasOwnProperty("description") &&
          assignmentTestData.description !== "" &&
          assignmentTestData.description !== null && (
            <Editor
              tinymceScriptSrc="/tinymce/tinymce.min.js"
              disabled={true}
              toolbar={false}
              menubar={false}
              branding={false}
              statusbar={false}
              value={
                assignmentTestData?.hasOwnProperty("description")
                  ? assignmentTestData.description
                  : ""
              }
              init={{
                resize: false,
                readonly: true,
                menubar: false,
                statusbar: false,
                license_key: "gpl",
                selector: "textarea",
                plugins: "autoresize",
              }}
            />
          )}
      </div>
      {referenceLinks?.map((link, index) => (
        <div key={index}>
          <div onContextMenu={(event) => event.preventDefault()}>
            <div className="assignmentPageReferenceLink">
              {link.extension === "pdf" &&
              link.type !== constants.LINK_TYPE.EMBEDED_LINK ? (
                <PdfViewer
                  path={link.path}
                  isDownloadable={link.download === 1}
                  name={link.name}
                />
              ) : imgExtensions.includes(link.extension) ? (
                <div className="assignmentPageReferenceLinkImageDownloadDiv">
                  <img
                    alt=""
                    src={link.link}
                    className="assignmentPageReferenceLinkImage"
                    onClick={() => handleRequestFullScreenMode(index)}
                    ref={(ref) =>
                      (assignmentReferenceLinkRef.current[index] = ref)
                    }
                  />
                  {link.download === 1 && (
                    <DownloadSvgIcon
                      onClick={() =>
                        handleDownloadReferenceFileForLearner(link)
                      }
                    />
                  )}
                </div>
              ) : link.extension === "mp4" ? (
                <video
                  controls
                  controlsList="nodownload"
                  src={`${link.link}#t=2.0`}
                  id="assignmentReferenceLinkPdf"
                  className="assignmentReferenceLinkVideo"
                  ref={(ref) =>
                    (assignmentReferenceLinkRef.current[index] = ref)
                  }
                ></video>
              ) : (
                link.type === constants.LINK_TYPE.EMBEDED_LINK && (
                  <iframe
                    allowFullScreen
                    title={"PPTX-Viewer"}
                    controlsList="nodownload"
                    id="assignmentReferenceLinkPdf"
                    src={link.link + "#toolbar=0&view=FitH"}
                    style={{ height: "211px", width: "372px" }}
                    ref={(ref) =>
                      (assignmentReferenceLinkRef.current[index] = ref)
                    }
                  ></iframe>
                )
              )}
              {link.type === constants.LINK_TYPE.EMBEDED_LINK && (
                <FontAwesomeIcon
                  icon={faLink}
                  id="assignmentReferenceLinkEmbedLink"
                  className="assignmentReferenceLinkEmbedLink"
                />
              )}
            </div>
          </div>
        </div>
      ))}
      {(isAdmin && status !== constants.PROGRESS_STATUS.REATTEMPT) ||
        (!isAdmin &&
          status !== constants.PROGRESS_STATUS.EVALUATED &&
          status !== constants.PROGRESS_STATUS.TO_BE_EVALUATED && (
            <div className="assignmentPageFooter">
              <Button
                size={"sm"}
                icon={{ type: "trailing", icon: <RightArrowSvgIcon /> }}
                hierarchy={{
                  type: "primary",
                  buttonText:
                    assignmentTestData.hasOwnProperty("template") &&
                    assignmentTestData.template !== null &&
                    assignmentTestData.template !== ""
                      ? !isSubmitted ||
                        status === constants.PROGRESS_STATUS.IN_PROGRESS
                        ? "Open Code Editor  "
                        : isAdmin &&
                          (status === constants.PROGRESS_STATUS.EVALUATED ||
                            status ===
                              constants.PROGRESS_STATUS.TO_BE_EVALUATED) &&
                          "View Submission  "
                      : !isAdmin &&
                        status !== constants.PROGRESS_STATUS.EVALUATED &&
                        (!isSubmitted
                          ? "Upload Assignment  "
                          : diff > 0 && "Edit Submission  "),
                }}
                onClick={
                  assignmentTestData.hasOwnProperty("template") &&
                  assignmentTestData.template !== null &&
                  assignmentTestData.template !== ""
                    ? openSandPackEditor
                    : !isAdmin &&
                      status !== constants.PROGRESS_STATUS.EVALUATED &&
                      (!isSubmitted
                        ? handleAddAssignment
                        : diff > 0 && handleEditSubmission)
                }
              />
            </div>
          ))}
      <Drawer
        anchor={"right"}
        open={showComments}
        className="commentsDrawer"
        onClose={toggleDrawer("right", false)}
      >
        {showComments && <Comments />}
      </Drawer>
    </div>
  );
}

export const AssignmentButton = (Props) => {
  const { status } = useContext(AlertContext);

  return (
    <div className="assignmentSubmissionUploadBtnSec">
      <button
        variant="contained"
        disabled={Props.disable}
        onClick={() => Props.handleSubmit(true)}
        id="assignmentSubmissionUploadSubmitBtn"
      >
        Submit
      </button>
      {Props.isSandpack && status !== constants.PROGRESS_STATUS.EVALUATED ? (
        <button
          variant="contained"
          disabled={Props.disable}
          id="assignmentSubmissionUploadSubmitBtn"
          onClick={() => Props.handleSubmit(false)}
        >
          Save
        </button>
      ) : (
        <button
          variant="contained"
          onClick={Props.handleCancel}
          id="assignmentSubmissionUploadCancelBtn"
        >
          Cancel
        </button>
      )}
    </div>
  );
};
