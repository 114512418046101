import "./VarTypes.scss";
import { Form, Input, Table } from "antd";
import VarTypesHooks from "./Hooks/VarTypesHooks";
import CreatableSelect from "react-select/creatable";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import React, {
  useRef,
  useState,
  useEffect,
  useContext,
  createContext,
} from "react";

const EditableContext = createContext(null);

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();

  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({
  title,
  record,
  editable,
  children,
  dataIndex,
  handleSave,
  ...restProps
}) => {
  const form = useContext(EditableContext);

  const [editing, setEditing] = useState(false);

  const inputRef = useRef(null);

  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();

      toggleEdit();
      handleSave({
        ...record,
        ...values,
      });
    } catch (errInfo) {}
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        name={dataIndex}
        style={{
          margin: 0,
        }}
        rules={[
          {
            required: true,
            message: `${title} is required.`,
          },
        ]}
      >
        <Input ref={inputRef} onPressEnter={save} onBlur={save} />
      </Form.Item>
    ) : (
      <div
        onClick={toggleEdit}
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 24,
        }}
      >
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

export default function VarTypes(Props) {
  const {
    showAdd,
    setShowAdd,
    currentInp,
    currentType,
    handleChange,
    setCurrentInp,
    inputVarTypes,
    setCurrentType,
    customStylesForTopic,
  } = VarTypesHooks();

  const [dataSource, setDataSource] = useState(
    Props.isqueReport
      ? Props.inputDataSource.filter(
          (obj) => obj.varname !== "" || obj.vartype !== null
        )
      : []
  );

  const [count, setCount] = useState(
    Props.isqueReport ? Props.inputDataSource.length + 2 : 2
  );

  const handleDelete = (key) => {
    const newData = dataSource.filter((item) => item.key !== key);

    Props.setInVatypes(newData);
    setDataSource(newData);
  };

  const defaultColumns = [
    {
      title: "Name",
      width: "100px",
      editable: true,
      dataIndex: "varname",
    },
    {
      title: "Type",
      width: "100px",
      editable: true,
      dataIndex: "vartype",
    },
    {
      width: "100px",
      title: "Action",
      dataIndex: "Action",
      render: (_, record) =>
        dataSource.length >= 1 ? (
          <button
            onClick={() => handleDelete(record.key)}
            style={{ border: "none", background: "none" }}
          >
            <FontAwesomeIcon className="faTrashCan" icon={faTrashCan} />
          </button>
        ) : null,
    },
  ];

  const handleAdd = () => {
    setShowAdd(false);

    let newData;

    newData = {
      key: count,
      varname: currentInp.varname,
      vartype: currentType.value,
    };

    setDataSource([...dataSource, newData]);
    setCount(count + 1);
    setCurrentInp({ varname: "", vartype: "" });
    Props.setInVatypes([...dataSource, newData]);
    setCurrentType({});
    setCurrentInp({ varname: "", vartype: "" });
  };

  const handleSave = (row) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];

    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    setDataSource(newData);
    Props.setInVatypes(newData);
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        handleSave,
        title: col.title,
        editable: col.editable,
        dataIndex: col.dataIndex,
      }),
    };
  });

  useEffect(() => {
    setShowAdd(false);
  }, []);

  return (
    <div>
      <div className="queEntInpParLab">
        Add Input Parameters
        <span style={{ color: "red", width: "4px" }}> *</span>
      </div>
      <div className="queEntInpParSec">
        <div className="queEntInpParSecVarNam">
          <input
            required
            type="text"
            name="varname"
            onChange={handleChange}
            value={currentInp["varname"]}
            placeholder="Enter Parameter"
            className="queEntInpParInpBox"
          />
        </div>

        <div className="queEntInpParSecVarTyp">
          <CreatableSelect
            name="varType"
            value={currentType}
            options={inputVarTypes}
            styles={customStylesForTopic}
            components={{ IndicatorSeparator: () => null }}
            onChange={(newValue) => {
              handleChange(newValue);
            }}
          />
        </div>

        <div className="queEntInpParSecAddButDiv">
          <button
            type="primary"
            onClick={handleAdd}
            disabled={!showAdd}
            className={
              showAdd ? "queEntInpParSecAddButDis" : "queEntInpParSecAddButAct"
            }
          >
            Add
          </button>
        </div>
      </div>

      <div className="queEntInpVarTab">
        <Table
          bordered
          columns={columns}
          pagination={false}
          dataSource={dataSource}
          components={components}
          rowClassName={() => "editable-row"}
        />
      </div>
    </div>
  );
}
