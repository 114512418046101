import React, { useContext, useEffect, useState } from "react";
import FormTitle from "./FormTitle";
import { FormHelperText, Paper, TextField } from "@mui/material";
import ChevronLeft from "../../../Svg/ChevronLeft";
import AlertContext from "../../../context/AlertContext";
import ProfileUpload from "./ProfileUpload";
import _ from "lodash";
import InputGroupField from "../../CommonComponents/InputGroupField";
import Button from "../../Button";
import useAxiosPrivate from "../../../Hooks/useAxiosPrivate";
import constants from "../../../constants";
import { getDataFromStorage } from "../../../util";
import Popup from "../../CommonComponents/Popup";
import ModuleHooks from "../../../Hooks/ModuleHooks";
import Loading from "../../Notification-Loading/Loading/Loading";

const paperStyle = {
  border: "1px solid #EAECF0",
  display: "block",
  boxShadow: "none",
  borderRadius: "10px",
  overflow: "auto",
  paddingBottom: "10px",
};

const CompanyProfile = () => {
  const { navigate, isCommonPopup, handleUnAuthAlert, setIsLoaded, isLoaded } =
    useContext(AlertContext);
  const axios = useAxiosPrivate();
  const { clearPopupStates, handlePopupStates } = ModuleHooks();
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [contactInfo, setContactInfo] = useState({});
  const [logo, setLogo] = useState("");
  const [favicon, setFavicon] = useState("");
  const [focusStates, setFocusStates] = useState(
    _.reduce(
      [...Object.keys(contactInfo), "name", "address"],
      (acc, key) => ({ ...acc, [key]: false }),
      {}
    )
  );

  const handleNavFun = () => {
    clearPopupStates();
    navigate("/batches");
  };
  const handlePopupOkFunc = () => {
    handleUnAuthAlert();
    clearPopupStates();
  };

  const handleResponse = (title, msg, isUnAuth) => {
    let popupdata = {};
    popupdata.showpopup = true;
    popupdata.title = title;
    popupdata.bodydesc = msg;
    popupdata.singlebtn = true;
    popupdata.singlebtntxt = "Ok";
    popupdata.singlebtnsize = "small";
    popupdata.singlebtnfunt = isUnAuth
      ? handlePopupOkFunc
      : title === "Success"
      ? handleNavFun
      : clearPopupStates;
    handlePopupStates(popupdata);
  };

  useEffect(() => {
    let isIgnore = false;
    const getCompanyInfo = async () => {
      setIsLoaded(true);
      try {
        let response = await axios.get(
          `node/admin/company/profile/${getDataFromStorage("companyId")}`,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (!isIgnore) {
          if (response.data.resultCode === constants.RESULT_STATUS.SUCCESS) {
            let data = response.data.data[0];
            setName(data?.name ?? "");
            setAddress(data?.address ?? "");
            setLogo(data?.logo ?? "");
            setFavicon(data?.favicon ?? "");
            setContactInfo(
              data?.contact_info ? JSON.parse(data.contact_info) : {}
            );
          } else {
            handleResponse(
              "Error !",
              response.data.msg ??
                "Something went wrong. Please try again later or contact gradious team",
              false
            );
          }
        }
      } catch (error) {
        console.error("error", error);
        if (error.message.includes("403")) {
          handleResponse(
            "Warning !",
            "You have been logged-out due to inactivity. Login again.",
            true
          );
        } else {
          handleResponse(
            "Error !",
            "Something went wrong. Please try again later or contact gradious team",
            false
          );
        }
      } finally {
        setIsLoaded(false);
      }
    };
    getCompanyInfo();
    return () => (isIgnore = true);
  }, []);

  const handleFieldFocus = (name) => {
    setFocusStates({ ...focusStates, [name]: true });
  };

  const handleFieldBlur = (name) => {
    setFocusStates({ ...focusStates, [name]: false });
  };
  const handleUploadFavicon = (event) => {
    let file = event.target.files[0];
    console.log("file", file);
    if (!file.size) {
      event.target.value = "";
      return;
    }
    // if (file.type === "image/svg+xml") {
    //   event.target.value = "";
    //   handleResponse("Warning", "Wrong file type - SVG only.");
    //   return
    // }
    // if (file.size > 2048) {
    //   event.target.value = "";
    //   handleResponse(
    //     "Warning",
    //     `Image size exceeds the limit of 2048 bytes`
    //   );
    //   return;
    // }

    let reader = new FileReader();

    reader.onloadend = async () => {
      setFavicon(reader.result);
      event.target.value = "";
    };
    reader.readAsDataURL(file);
  };

  const handleSave = async (event) => {
    event.preventDefault();
    const payload = {
      id: getDataFromStorage("companyId"),
      name,
      address,
      favicon,
      logo,
      contact_info: { ...contactInfo },
    };
    setIsLoaded(true);
    try {
      let response = await axios.put(
        `node/admin/company/profile/${getDataFromStorage("companyId")}`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.data.resultCode === constants.RESULT_STATUS.SUCCESS) {
        handleResponse(
          "Success",
          response.data.msg ?? "The company details are update successfully.",
          false
        );
      } else {
        handleResponse(
          "Error !",
          response.data.msg ??
            "Something went wrong. Please try again later or contact gradious team",
          false
        );
      }
    } catch (error) {
      console.error("error", error);
      if (error.msg.includes("403")) {
        handleResponse(
          "Warning !",
          "You have been logged-out due to inactivity. Login again.",
          true
        );
      } else {
        handleResponse(
          "Error !",
          "Something went wrong. Please try again later or contact gradious team",
          false
        );
      }
    } finally {
      setIsLoaded(false);
    }
  };

  return (
    <div className="companyProfileContainer">
      <div className="companyProfileContainer2">
        <div className="companyProfileBtnSec">
          <div
            className="companyProfileBtn"
            onClick={() => {
              navigate("/batches");
            }}
          >
            <ChevronLeft />
            <p className="companyProfileBtnText">Back</p>
          </div>
        </div>
        <Paper sx={paperStyle} className="companyProfileSec">
          {isCommonPopup && (
            <div className="popup-overlay">
              <Popup />
            </div>
          )}
          {isLoaded && <Loading />}
          <FormTitle
            icon={
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M22 21V19C22 17.1362 20.7252 15.5701 19 15.126M15.5 3.29076C16.9659 3.88415 18 5.32131 18 7C18 8.67869 16.9659 10.1159 15.5 10.7092M17 21C17 19.1362 17 18.2044 16.6955 17.4693C16.2895 16.4892 15.5108 15.7105 14.5307 15.3045C13.7956 15 12.8638 15 11 15H8C6.13623 15 5.20435 15 4.46927 15.3045C3.48915 15.7105 2.71046 16.4892 2.30448 17.4693C2 18.2044 2 19.1362 2 21M13.5 7C13.5 9.20914 11.7091 11 9.5 11C7.29086 11 5.5 9.20914 5.5 7C5.5 4.79086 7.29086 3 9.5 3C11.7091 3 13.5 4.79086 13.5 7Z"
                  stroke="#101828"
                  strokeWidth="1.8"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            }
            title="Company Info"
          />
          <div className="companyProfileFormContainer">
            <div className="companyProfileFormSec">
              <form className="compnayProfileForm">
                <div className="companyProfileLeftForm">
                  <div className="companyProfileNameSec">
                    <TextField
                      id="companyProfileName"
                      className="companyProfileDetail"
                      fullWidth
                      variant="outlined"
                      name="name"
                      value={name}
                      size="small"
                      label="Company Name"
                      inputProps={{
                        onFocus: (event) => handleFieldFocus(event.target.name),
                        onBlur: (event) => handleFieldBlur(event.target.name),
                      }}
                      InputLabelProps={{
                        shrink: focusStates["name"] || Boolean(name),
                      }}
                      onChange={(event) => setName(event.target.value)}
                    />
                  </div>
                  <div className="companyProfileEmailSec">
                    <TextField
                      id="companyProfileEmail"
                      className="companyProfileDetail"
                      fullWidth
                      variant="outlined"
                      name="email"
                      value={contactInfo.email}
                      size="small"
                      label="Email Address"
                      inputProps={{
                        onFocus: (event) => handleFieldFocus(event.target.name),
                        onBlur: (event) => handleFieldBlur(event.target.name),
                      }}
                      InputLabelProps={{
                        shrink:
                          focusStates["email"] || Boolean(contactInfo["email"]),
                      }}
                      onChange={(event) =>
                        setContactInfo({
                          ...contactInfo,
                          [event.target.name]: event.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="companyProfileMobileAndDateSec">
                    <div className="companyProfileMobileSec">
                      <InputGroupField
                        groupText="+91"
                        placeholder="Phone Number"
                        name="phone"
                        value={contactInfo.phone}
                        disabled={false}
                        onChange={(event) =>
                          setContactInfo({
                            ...contactInfo,
                            [event.target.name]: event.target.value,
                          })
                        }
                      />
                    </div>
                    {/* <div className="companyProfileDateSec">
                      <TextField
                        id="companyProfileDate"
                        className="companyProfileDetail"
                        fullWidth
                        variant="outlined"
                        name="foundedDate"
                        value={contactInfo.foundedDate}
                        size="small"
                        label="Founded Date"
                        inputProps={{
                          onFocus: (event) =>
                            handleFieldFocus(event.target.name),
                          onBlur: (event) => handleFieldBlur(event.target.name),
                        }}
                        InputLabelProps={{
                          shrink:
                            focusStates["foundedDate"] ||
                            Boolean(contactInfo["foundedDate"]),
                        }}
                        onChange={(event) =>
                          setContactInfo({
                            ...contactInfo,
                            [event.target.name]: event.target.value,
                          })
                        }
                      />
                    </div> */}
                  </div>
                  <div className="companyProfileAddressSec">
                    <TextField
                      id="companyProfileAddress"
                      className="companyProfileDetail"
                      fullWidth
                      multiline
                      variant="outlined"
                      name="address"
                      value={address}
                      size="small"
                      label="Address"
                      InputLabelProps={{
                        shrink: focusStates["address"] || Boolean(address),
                      }}
                      inputProps={{
                        onFocus: (event) => handleFieldFocus(event.target.name),
                        onBlur: (event) => handleFieldBlur(event.target.name),
                        maxLength: 250,
                      }}
                      onChange={(event) => setAddress(event.target.value)}
                    />
                  </div>
                  <div className="companyProfileLinkSec">
                    <InputGroupField
                      disabled={false}
                      title="Website"
                      groupText="http://"
                      placeholder="www.gradious.com"
                      name="link"
                      value={contactInfo.link}
                      onChange={(event) =>
                        setContactInfo({
                          ...contactInfo,
                          [event.target.name]: event.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="companyProfileCompanyObjSec">
                    <TextField
                      id="companyProfileCompanyObj"
                      className="companyProfileDetail"
                      label="Company Objective"
                      name="companyObj"
                      InputLabelProps={{
                        shrink:
                          focusStates["companyObj"] ||
                          Boolean(contactInfo["companyObj"]),
                      }}
                      variant="outlined"
                      size="small"
                      multiline={true}
                      fullWidth
                      value={contactInfo.companyObj}
                      inputProps={{
                        onFocus: (event) => handleFieldFocus(event.target.name),
                        onBlur: (event) => handleFieldBlur(event.target.name),
                        maxLength: 1000,
                      }}
                      onChange={(event) =>
                        setContactInfo({
                          ...contactInfo,
                          [event.target.name]: event.target.value,
                        })
                      }
                    />
                    <FormHelperText id="companyProfileCompanyObjHText">
                      Write a short summary of your company's morals
                    </FormHelperText>
                  </div>
                </div>
                <div className="companyProfileRightForm">
                  <ProfileUpload
                    profilePic={logo}
                    setProfilePic={setLogo}
                    handleResponse={handleResponse}
                    buttonText="Upload a new Photo"
                    size={"800 * 800"}
                  />
                </div>
              </form>
              <div className="companyProfileUploadFaviconContainer">
                <div className="companyProfileUploadFavicon">
                  <div className="companyProfileUploadFaviconBtnSec">
                    <input
                      type="file"
                      className="companyProfileUploadFaviconBtn"
                      accept="image/*"
                      onChange={handleUploadFavicon}
                    />
                    <Button
                      size="lg"
                      onClick={(event) => event.preventDefault()}
                      hierarchy={{
                        type: "secondaryGrey",
                        buttonText: "Upload Favicon",
                      }}
                      icon={{
                        icon: (
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M17.5 12.5V15.8333C17.5 16.2754 17.3244 16.6993 17.0118 17.0118C16.6993 17.3244 16.2754 17.5 15.8333 17.5H4.16667C3.72464 17.5 3.30072 17.3244 2.98816 17.0118C2.67559 16.6993 2.5 16.2754 2.5 15.8333V12.5M14.1667 6.66667L10 2.5M10 2.5L5.83333 6.66667M10 2.5V12.5"
                              stroke="#344054"
                              strokeWidth="1.67"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        ),
                        type: "leading",
                      }}
                    />
                  </div>
                  {favicon && favicon !== "" && (
                    <div className="faviconFileContainer">
                      <div className="faviconFileSec">
                        <p className="faviconFileName">{`${name} favicon.svg`}</p>
                      </div>
                      <div
                        className="faviconFileBtnsec"
                        onClick={() => setFavicon(null)}
                      >
                        <svg
                          className="faviconFileXmarkBtn"
                          width="12"
                          height="12"
                          viewBox="0 0 12 12"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M8.5 3.5L3.5 8.5M3.5 3.5L8.5 8.5"
                            stroke="white"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                    </div>
                  )}
                </div>
                <FormHelperText id="companyProfileUploadFaviconBtnHText">
                  Favicon should be 16px x 16px size.
                </FormHelperText>
              </div>
              <div className="companyProfileBtnContainer">
                <div className="companyProfileBtnSec">
                  <Button
                    size="sm"
                    onClick={handleSave}
                    hierarchy={{
                      type: "primary",
                      buttonText: "Save",
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </Paper>
      </div>
    </div>
  );
};

export default CompanyProfile;
