import React from "react";

export default function PlusIcon() {
  return (
    <svg
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="#373739"
        fill-opacity="0.8"
        d="M12.008 3.09752C11.7435 3.09722 11.4897 3.20217 11.3027 3.38921C11.1156 3.57625 11.0107 3.83002 11.011 4.09454L11.0039 11.003L4.0884 11.003C3.82388 11.0027 3.57012 11.1076 3.38307 11.2947C3.19603 11.4817 3.09108 11.7355 3.09138 12C3.09138 12.5515 3.53686 12.997 4.0884 12.997L11.0039 12.997L11.0039 19.9125C11.0039 20.4641 11.4494 20.9095 12.0009 20.9095C12.5525 20.9095 12.9979 20.4641 12.9979 19.9125L12.9979 12.997L19.9135 12.997C20.465 12.997 20.9105 12.5515 20.9105 12C20.9105 11.4484 20.465 11.003 19.9135 11.003H12.9979L12.9979 4.08747C12.9979 3.55006 12.5454 3.09752 12.008 3.09752Z"
      />
    </svg>
  );
}
