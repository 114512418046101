import Button from "../../Button";
import constants from "../../../constants";
import MockSvgIcon from "../../../Svg/MockSvgIcon";
import { getDataFromStorage } from "../../../util";
import React, { useContext, useEffect } from "react";
import AlertContext from "../../../context/AlertContext";
import useAxiosPrivate from "../../../Hooks/useAxiosPrivate";
import RightArrowSvgIcon from "../../../Svg/RightArrowSvgIcon";
import useCommonFunctionHooks from "../../../Hooks/useCommonFunctionHooks";
import MockTestLibraryHooks from "../../../Hooks/Mock/Student/MockTestLibraryHooks";

export default function MockBanner() {
  const Alert = useContext(AlertContext);

  const axios = useAxiosPrivate();
  const MockTestLibraryHks = MockTestLibraryHooks();
  const useCommonFunctionHks = useCommonFunctionHooks();

  useEffect(() => {
    Alert.setIsLoaded(true);
    axios
      .post(
        "node/learner/mock/lib",
        JSON.stringify({
          learnerid: getDataFromStorage("learnerid"),
        }),
        useCommonFunctionHks.getHeaderDetailsForAxios()
      )
      .then((res) => {
        if (res.data.resultCode !== constants.RESULT_STATUS.SUCCESS) {
          Alert.setShowNotify({
            show: true,
            title: "Info",
            msg: res.data.message,
          });
        } else {
          Alert.setBannerData(res.data.data.tests.slice(0, 3));
        }
      })
      .catch((error) => {
        useCommonFunctionHks.getCatchBlockDetails(error);
      })
      .finally(() => {
        Alert.setIsLoaded(false);
      });
  }, []);

  return (
    <div className="mockBannerMainContainer">
      <div className="mockBannerContent">
        <div className="mockBannerHeader">
          <div className="mockBannerHeaderLabel">
            Improve your skills with our Mock Tests!
          </div>
          <div className="mockBannerHeaderDescription">
            Boost your preparation and confidence for upcoming exams by
            practicing with our comprehensive mock tests, designed to mirror
            real test conditions and challenge your knowledge.
          </div>
        </div>
        <div className="mockBannerTestList">
          {Alert.bannerData.map((data) => <div className="mockBannerTest" onClick={() => MockTestLibraryHks.handleTest(data)}>
            <div className="mockBannerTestName">{data.name}</div>
            <div className="mockBannerTestContent">
              <div>Type &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: {MockTestLibraryHks.getTestType(data)}</div>
              <div>Duration &nbsp;&nbsp;&nbsp;: {MockTestLibraryHks.getTime(data.testduration)}</div>
              <div>Questions : {data.questionscount}</div>
            </div>
          </div>)}
        </div>
        <Button
          size="md"
          onClick={() => Alert.navigate("/mock/library")}
          icon={{ type: "trailing", icon: <RightArrowSvgIcon /> }}
          hierarchy={{
            type: "primary",
            buttonText: "Start Practicing ",
          }}
        />
      </div>
      <MockSvgIcon />
    </div>
  );
}
