import React from 'react'

export default function RankThirdSvg() {
  return (
        <svg width="30" height="33" viewBox="0 0 30 33" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15 30.9199C23.2843 30.9199 30 24.2042 30 15.9199C30 7.63565 23.2843 0.919922 15 0.919922C6.71573 0.919922 0 7.63565 0 15.9199C0 24.2042 6.71573 30.9199 15 30.9199Z" fill="url(#paint0_linear_1665_14818)"/>
        <g filter="url(#filter0_i_1665_14818)">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M15.0005 5C21.075 5 26 9.92359 26 15.9991C26 22.0751 21.075 27 15.0005 27C8.92488 27 4 22.0751 4 15.9991C4 9.92396 8.92488 5 15.0005 5Z" fill="url(#paint1_linear_1665_14818)"/>
        </g>
        <g filter="url(#filter1_dd_1665_14818)">
        <path d="M15.4993 22.1591C14.6811 22.1591 13.9538 22.0189 13.3175 21.7386C12.6849 21.4583 12.1849 21.0682 11.8175 20.5682C11.45 20.0682 11.255 19.4905 11.2322 18.8352H13.3686C13.3875 19.1496 13.4917 19.4242 13.6811 19.6591C13.8705 19.8902 14.1224 20.0701 14.4368 20.1989C14.7512 20.3277 15.1035 20.392 15.4936 20.392C15.9103 20.392 16.2796 20.3201 16.6016 20.1761C16.9235 20.0284 17.1754 19.8239 17.3572 19.5625C17.5391 19.3011 17.6281 19 17.6243 18.6591C17.6281 18.3068 17.5372 17.9962 17.3516 17.7273C17.166 17.4583 16.897 17.2481 16.5447 17.0966C16.1963 16.9451 15.7758 16.8693 15.2834 16.8693H14.255V15.2443H15.2834C15.6887 15.2443 16.0429 15.1742 16.3459 15.0341C16.6527 14.8939 16.8932 14.697 17.0675 14.4432C17.2417 14.1856 17.3269 13.8883 17.3232 13.5511C17.3269 13.2216 17.2531 12.9356 17.1016 12.6932C16.9538 12.447 16.7436 12.2557 16.4709 12.1193C16.2019 11.983 15.8857 11.9148 15.522 11.9148C15.166 11.9148 14.8364 11.9792 14.5334 12.108C14.2304 12.2367 13.986 12.4205 13.8004 12.6591C13.6148 12.8939 13.5163 13.1742 13.505 13.5H11.4766C11.4917 12.8485 11.6792 12.2765 12.0391 11.7841C12.4027 11.2879 12.8875 10.9015 13.4936 10.625C14.0997 10.3447 14.7796 10.2045 15.5334 10.2045C16.3099 10.2045 16.9841 10.3504 17.5561 10.642C18.1319 10.9299 18.5769 11.3182 18.8913 11.8068C19.2057 12.2955 19.3629 12.8352 19.3629 13.4261C19.3667 14.0814 19.1735 14.6307 18.7834 15.0739C18.397 15.517 17.8894 15.8068 17.2607 15.9432V16.0341C18.0788 16.1477 18.7057 16.4508 19.1413 16.9432C19.5807 17.4318 19.7985 18.0398 19.7947 18.767C19.7947 19.4186 19.6091 20.0019 19.2379 20.517C18.8705 21.0284 18.3629 21.4299 17.7152 21.7216C17.0713 22.0133 16.3326 22.1591 15.4993 22.1591Z" fill="url(#paint2_linear_1665_14818)"/>
        </g>
        <defs>
        <filter id="filter0_i_1665_14818" x="4" y="5" width="22" height="22" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset/>
        <feGaussianBlur stdDeviation="2"/>
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.18 0"/>
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_1665_14818"/>
        </filter>
        <filter id="filter1_dd_1665_14818" x="5.23218" y="8.20459" width="20.5684" height="23.9546" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feMorphology radius="2" operator="erode" in="SourceAlpha" result="effect1_dropShadow_1665_14818"/>
        <feOffset dy="2"/>
        <feGaussianBlur stdDeviation="2"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.06 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1665_14818"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feMorphology radius="2" operator="erode" in="SourceAlpha" result="effect2_dropShadow_1665_14818"/>
        <feOffset dy="4"/>
        <feGaussianBlur stdDeviation="4"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.1 0"/>
        <feBlend mode="normal" in2="effect1_dropShadow_1665_14818" result="effect2_dropShadow_1665_14818"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_1665_14818" result="shape"/>
        </filter>
        <linearGradient id="paint0_linear_1665_14818" x1="5.5" y1="4.41992" x2="24.5" y2="27.4199" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FDBE9F"/>
        <stop offset="1" stop-color="#FE905F"/>
        </linearGradient>
        <linearGradient id="paint1_linear_1665_14818" x1="2.5" y1="2.1672e-07" x2="20.5626" y2="24.6571" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FF9971"/>
        <stop offset="1" stop-color="#FF8C5F"/>
        </linearGradient>
        <linearGradient id="paint2_linear_1665_14818" x1="12.2" y1="11" x2="18.8614" y2="21.7825" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FFDEC2"/>
        <stop offset="1" stop-color="#FFAC70"/>
        </linearGradient>
        </defs>
        </svg>
  )
}
