import _ from "lodash";
import { useContext } from "react";
import constants from "../constants";
import useAxiosPrivate from "./useAxiosPrivate";
import AlertContext from "../context/AlertContext";
import AssignmentPageHooks from "./AssignmentPageHooks";
import useCommonFunctionHooks from "./useCommonFunctionHooks";
import MultiLevelDropDownHooks from "./MultiLevelDropDownHooks";
import StudentTestAdminHooks from "./StudentTestPage/StudentTestAdminHooks";

export default function StudentListHooks() {
  const Alert = useContext(AlertContext);

  const axios = useAxiosPrivate();
  const { handleMcq } = StudentTestAdminHooks();
  const { handleNavContentOrAssignment } = AssignmentPageHooks();
  const { getDataForBreadCrumbsData } = MultiLevelDropDownHooks();

  const { getCatchBlockDetails, getHeaderDetailsForAxios } =
    useCommonFunctionHooks();

  const currentLearnerName = Alert.batchLearnerListForEvaluation?.map(
    (learner) =>
      learner.userid === Alert.showBreadCrumbsData.learnerId &&
      learner.fname + " " + learner.lastname
  );

  const customStyle = {
    control: (provided) => ({
      ...provided,
      minWidth: 150,
      maxWidth: 200,
      outline: "none",
      cursor: "pointer",
      boxShadow: "none",
      border: "0px solid white",
      backgroundColor: "#fafafa",
    }),
    option: (styles, { isDisabled, isFocused, data }) => {
      return {
        ...styles,
        fontSize: "12px",
        textAlign: "left",
        cursor: "pointer",
        padding: "2px 12px",
        color:
          data.status === 10
            ? "#027A48"
            : data.status === 11 || data.status === 12
            ? "#FDB022"
            : data.status === 13
            ? "#E54B2A"
            : data.status === 14
            ? "#B42318"
            : data.status === 15
            ? "#175CD3"
            : data.status === 16
            ? "#344054"
            : isDisabled
            ? "#grey"
            : "black",
        backgroundColor:
          data.status === 10
            ? "#ECFDF3"
            : data.status === 11 || data.status === 12
            ? "#FFFAEB"
            : data.status === 13
            ? "#FCEBE8"
            : data.status === 14
            ? "#FEF3F2"
            : data.status === 15
            ? "#EFF8FF"
            : data.status === 16
            ? "#F2F4F7"
            : "white",
      };
    },
  };

  let statusSet = new Set();

  const filterStudentListStatus = [],
    customFilterStatus = [10, 2, 11, 5, 12, 4, 13, 7, 14, 3, 15, 6, 16, 0, 1];

  Alert.batchLearnerListForEvaluation?.forEach((learner) => {
    if (learner.status === 2 && !statusSet.has(10)) {
      filterStudentListStatus.push(learner.status);
      filterStudentListStatus.push(10);
      statusSet.add(10);
    }

    if (learner.status === 5 && !statusSet.has(11)) {
      filterStudentListStatus.push(11);
      filterStudentListStatus.push(learner.status);
      statusSet.add(11);
    }

    if (learner.status === 4 && !statusSet.has(12)) {
      filterStudentListStatus.push(12);
      filterStudentListStatus.push(learner.status);
      statusSet.add(12);
    }

    if (learner.status === 7 && !statusSet.has(13)) {
      filterStudentListStatus.push(13);
      filterStudentListStatus.push(learner.status);
      statusSet.add(13);
    }

    if (learner.status === 3 && !statusSet.has(14)) {
      filterStudentListStatus.push(14);
      filterStudentListStatus.push(learner.status);
      statusSet.add(14);
    }

    if (learner.status === 6 && !statusSet.has(15)) {
      filterStudentListStatus.push(15);
      filterStudentListStatus.push(learner.status);
      statusSet.add(15);
    }

    if ((learner.status === 0 || learner.status === 1) && !statusSet.has(16)) {
      filterStudentListStatus.push(16);
      filterStudentListStatus.push(learner.status);
      statusSet.add(16);
    }
  });

  let StudentList = [
    ...Alert.batchLearnerListForEvaluation?.map((learner) => ({
      learner: learner,
      value: learner.userid,
      status: learner.status,
      label: learner.fname + " " + learner.lastname,
      isdisabled:
        learner.status === constants.PROGRESS_STATUS.LOCKED ||
        learner.status === constants.PROGRESS_STATUS.UNLOCKED
          ? true
          : false,
    })),
    {
      value: 10,
      status: 10,
      isdisabled: true,
      label: "Submitted",
    },
    {
      value: 11,
      status: 11,
      isdisabled: true,
      label: "Partial Evaluation",
    },
    {
      value: 11,
      status: 12,
      isdisabled: true,
      label: "To be Evaluated",
    },
    {
      value: 11,
      status: 13,
      isdisabled: true,
      label: "Continue",
    },
    {
      value: 11,
      status: 14,
      isdisabled: true,
      label: "Resubmit",
    },
    {
      value: 11,
      status: 15,
      isdisabled: true,
      label: "In Progress",
    },
    {
      value: 11,
      status: 16,
      isdisabled: true,
      label: "Yet to start",
    },
  ];

  StudentList = StudentList.filter((item) =>
    filterStudentListStatus.includes(item.status)
  );

  const sortByCustomFilterStatus = (learnerList) => {
    StudentList.sort((a, b) => {
      return (
        customFilterStatus.indexOf(a.status) -
        customFilterStatus.indexOf(b.status)
      );
    });
    return learnerList;
  };

  const getCustomFilterStatusStudentList = (array1) => {
    const sortedLearnerList = sortByCustomFilterStatus(array1);

    return sortedLearnerList;
  };

  const sortedLearnerList = getCustomFilterStatusStudentList(StudentList);

  const handleChangeLearnerByBtn = (action) => {
    let currentLearnerList = sortedLearnerList?.filter(
      (learner) =>
        learner.status !== 10 &&
        learner.status !== 11 &&
        learner.status !== 12 &&
        learner.status !== 13 &&
        learner.status !== 14 &&
        learner.status !== 15 &&
        learner.status !== 16 &&
        learner.status !== constants.PROGRESS_STATUS.LOCKED &&
        learner.status !== constants.PROGRESS_STATUS.UNLOCKED &&
        learner
    );

    let i = _.findIndex(currentLearnerList, (learner) => {
      return learner.learner.userid === Alert.showBreadCrumbsData.learnerId;
    });

    do {
      if (action === "next") {
        i++;
      } else {
        i--;
      }

      if (
        i >= 0 &&
        currentLearnerList[i].status !== 10 &&
        currentLearnerList[i].status !== 11 &&
        currentLearnerList[i].status !== 12 &&
        currentLearnerList[i].status !== 13 &&
        currentLearnerList[i].status !== 14 &&
        currentLearnerList[i].status !== 15 &&
        currentLearnerList[i].status !== 16 &&
        currentLearnerList[i].status !== constants.PROGRESS_STATUS.LOCKED &&
        currentLearnerList[i].status !== constants.PROGRESS_STATUS.UNLOCKED
      ) {
        break;
      }
    } while (i <= currentLearnerList.length && i >= 0);

    if (action === "next") {
      if (i >= 0) {
        Alert.setShowPrevBtn(false);
      }

      if (i === currentLearnerList.length - 1) {
        Alert.setShowNextBtn(true);
      } else {
        updateStudentList(currentLearnerList[i]);
        Alert.setShowNextBtn(false);
      }

      if (i === currentLearnerList.length - 1) {
        updateStudentList(currentLearnerList[i]);
      }
    } else {
      if (i !== currentLearnerList.length - 1) {
        Alert.setShowNextBtn(false);
      }

      if (i <= 0) {
        Alert.setShowPrevBtn(true);
      } else {
        Alert.setShowPrevBtn(false);
        updateStudentList(currentLearnerList[i]);
      }

      if (i === 0) {
        updateStudentList(currentLearnerList[i]);
      }
    }
  };

  const changeLearner = (currentLearner) => {
    if (
      Alert.showBreadCrumbsData.type === constants.libTypeIds.MCQ ||
      Alert.showBreadCrumbsData.type === constants.libTypeIds.DESCRIPTIVE
    ) {
      handleMcq(
        Alert.showBreadCrumbsData.testId,
        Alert.showBreadCrumbsData.lpId,
        Alert.showBreadCrumbsData.lpConfigId,
        currentLearner.status,
        currentLearner.userid,
        0,
        Alert.showBreadCrumbsData.testMode,
        null,
        Alert.batchLearnerListForEvaluation,
        true,
        currentLearner,
        Alert.showBreadCrumbsData.type
      );
    } else {
      Alert.setIsLoaded(true);
      axios
        .post(
          "node/learner/course/assignment/report",
          JSON.stringify({
            lpid: Alert.showBreadCrumbsData.lpId,
            libid: Alert.showBreadCrumbsData.testId,
            learnerid: Alert.showBreadCrumbsData.learnerId,
          }),
          getHeaderDetailsForAxios()
        )
        .then((res) => {
          let status =
            res.data.data.status === null
              ? constants.PROGRESS_STATUS.UNLOCKED
              : res.data.data.status;

          if (res.data.resultCode === constants.RESULT_STATUS.SUCCESS) {
            Alert.setChangeLearner(true);

            currentLearner.type = Alert.showBreadCrumbsData.type;
            currentLearner.testName = Alert.showBreadCrumbsData.testName;
            handleNavContentOrAssignment(
              res.data.data,
              Alert.showBreadCrumbsData.lpId,
              Alert.showBreadCrumbsData.type,
              Alert.showBreadCrumbsData.lpConfigId,
              Alert.showBreadCrumbsData.testId,
              status,
              Alert.batchLearnerListForEvaluation,
              true,
              currentLearner.userid,
              currentLearner
            );
          } else {
            Alert.setShowNotify({
              show: true,
              title: "Warning",
              msg:
                res.data.data !== "" && res.data.data !== undefined
                  ? res.data.data
                  : "Something went wrong. Please try again later",
            });
          }
        })
        .catch((error) => {
          getCatchBlockDetails(error);
        })
        .finally(() => {
          Alert.setIsLoaded(false);
        });
    }
  };

  const getPayLoadForStudentList = (data) => {
    let payload = { lpid: data.lpId, libtype: data.type };

    if (data.type === constants.libTypeIds.CODING_CHALLENGE) {
      payload.testid = data.testId;
      payload.testqnno = data.questionNo;
      payload.testconfigid = data.testConfigId;
    } else {
      payload.lpconfigid = data.lpConfigId;
    }

    return JSON.stringify(payload);
  };

  const getStudentList = (data) => {
    Alert.setIsLoaded(true);
    axios
      .post(
        "node/admin/report/lp/test",
        getPayLoadForStudentList(data),
        getHeaderDetailsForAxios()
      )
      .then((res) => {
        if (res.data.resultCode !== constants.RESULT_STATUS.SUCCESS) {
          Alert.setShowNotify({
            show: true,
            title: "Info",
            msg: res.data.msg,
          });
        } else {
          let response = JSON.parse(res.data.data).sort((a, b) =>
            a.fname.localeCompare(b.fname)
          );

          Alert.setBatchLearnerListForEvaluation(response);
        }
      })
      .catch((error) => {
        getCatchBlockDetails(error);
      })
      .finally(() => Alert.setIsLoaded(false));
  };

  const updateStudentList = (currentLearnerList) => {
    let data = {
      lpId: Alert.showBreadCrumbsData.lpId,
      learnerId: currentLearnerList.learner.userid,
    };

    Alert.showBreadCrumbsData.component = "studentList";
    Alert.showBreadCrumbsData.status = currentLearnerList.learner.status;
    Alert.showBreadCrumbsData.learnerId = currentLearnerList.learner.userid;
    Alert.showBreadCrumbsData.studentName =
      currentLearnerList.learner.fname +
      " " +
      currentLearnerList.learner.lastname;

    getDataForBreadCrumbsData(data);

    if (
      Alert.showBreadCrumbsData.type !== constants.libTypeIds.CODING_CHALLENGE
    ) {
      getStudentList(Alert.showBreadCrumbsData);
      changeLearner(currentLearnerList.learner);
    }
  };

  const studentListLoad = (e, from) => {
    let currentLearnerList = sortedLearnerList?.filter(
      (learner) =>
        learner.status !== 10 &&
        learner.status !== 11 &&
        learner.status !== 12 &&
        learner.status !== 13 &&
        learner.status !== 14 &&
        learner.status !== 15 &&
        learner.status !== 16 &&
        learner.status !== constants.PROGRESS_STATUS.LOCKED &&
        learner.status !== constants.PROGRESS_STATUS.UNLOCKED &&
        learner
    );

    let i = _.findIndex(currentLearnerList, (learner) => {
      return (
        learner.learner.userid ===
        (from !== "first" ? e.value : Alert.showBreadCrumbsData.learnerId)
      );
    });

    if (currentLearnerList.length === 1) {
      Alert.setShowNextBtn(true);
      Alert.setShowPrevBtn(true);
    } else if (i === currentLearnerList.length - 1) {
      Alert.setShowNextBtn(true);
      Alert.setShowPrevBtn(false);
    } else if (i === 0) {
      Alert.setShowPrevBtn(true);
      Alert.setShowNextBtn(false);
    } else {
      Alert.setShowPrevBtn(false);
      Alert.setShowNextBtn(false);
    }

    if (from !== "first") {
      updateStudentList(currentLearnerList[i]);
    }
  };

  return {
    customStyle,
    getStudentList,
    studentListLoad,
    sortedLearnerList,
    currentLearnerName,
    handleChangeLearnerByBtn,
  };
}
