import React from "react";

export default function TopicSvgIcon() {
  return (
    <svg
      width="14"
      height="14"
      fill="none"
      viewBox="0 0 14 14"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        stroke="#E54B2A"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M4.6665 9.33268V10.966C4.6665 11.6194 4.6665 11.9461 4.79366 12.1957C4.90552 12.4152 5.08399 12.5937 5.30351 12.7055C5.55308 12.8327 5.87978 12.8327 6.53317 12.8327H10.9665C11.6199 12.8327 11.9466 12.8327 12.1962 12.7055C12.4157 12.5937 12.5942 12.4152 12.706 12.1957C12.8332 11.9461 12.8332 11.6194 12.8332 10.966V6.53268C12.8332 5.87929 12.8332 5.55259 12.706 5.30303C12.5942 5.0835 12.4157 4.90503 12.1962 4.79317C11.9466 4.66602 11.6199 4.66602 10.9665 4.66602H9.33317M3.03317 9.33268H7.4665C8.1199 9.33268 8.4466 9.33268 8.69616 9.20552C8.91568 9.09367 9.09416 8.91519 9.20601 8.69567C9.33317 8.44611 9.33317 8.11941 9.33317 7.46602V3.03268C9.33317 2.37929 9.33317 2.05259 9.20601 1.80303C9.09416 1.5835 8.91568 1.40503 8.69616 1.29317C8.4466 1.16602 8.1199 1.16602 7.4665 1.16602H3.03317C2.37978 1.16602 2.05308 1.16602 1.80351 1.29317C1.58399 1.40503 1.40552 1.5835 1.29366 1.80303C1.1665 2.05259 1.1665 2.37929 1.1665 3.03268V7.46602C1.1665 8.11941 1.1665 8.44611 1.29366 8.69567C1.40552 8.91519 1.58399 9.09367 1.80351 9.20552C2.05308 9.33268 2.37978 9.33268 3.03317 9.33268Z"
      />
    </svg>
  );
}
