import ReactQuill from "react-quill";
import constants from "../../../constants";
import BackSvgIcon from "../../../Svg/BackSvgIcon";
import AlertContext from "../../../context/AlertContext";
import React, { useContext, useEffect, useState } from "react";
import ComplexityLabels from "../../CommonComponents/ComplexityLabels";

export default function CodingProblem() {
  const {
    lpId,
    status,
    isAdmin,
    setLpId,
    navigate,
    testData,
    setLpData,
    codeDetails,
    inputVariable,
    setCodeDetails,
    isInputVariable,
    setFromCodeEditor,
  } = useContext(AlertContext);

  const handleBack = () => {
    setCodeDetails({});
    setFromCodeEditor(true);

    if (document.fullscreenElement) {
      document.exitFullscreen();
    }

    if (isAdmin) {
      localStorage.removeItem("splitPos");
      localStorage.removeItem("splitWidthPos");
      setLpId(lpId);
      navigate(`batches/${sessionStorage.getItem('abatchid')}/report#`);
    } else if (testData.mockuse) {
      navigate("/mock/results");
    } else {
      setLpData({});
      localStorage.removeItem("splitPos");
      localStorage.removeItem("splitWidthPos");
      navigate("/home");
    }
  };

  const [learnerTestCases, setLearnerTestCases] = useState([]);

  useEffect(() => {
    const testCases =
      codeDetails.testcases !== null
        ? JSON.parse(
            codeDetails.testcases !== null ? codeDetails.testcases : []
          ).filter((testcase) => testcase.isexample === 1)
        : [];

    setLearnerTestCases(testCases);
  }, [codeDetails]);

  return (
    <div className="codingPageProblemMainContainer">
      {(isAdmin ||
        (!isAdmin &&
          (status === constants.QUESTION_STATUS.SUBMITTED ||
            status === constants.QUESTION_STATUS.TO_BE_EVALUATED ||
            testData.testmode === constants.MODE.PRACTICE_MODE))) && (
        <BackSvgIcon component="codingProblem" onClick={handleBack} />
      )}
      <div className="codingPageProblemHeader">
        <div className="codingPageProblemQuestionDiv">
          <div className="codingPageProblemQuestionName">
            {codeDetails?.name}
          </div>
          <ComplexityLabels complexityid={codeDetails?.complexityid} />
        </div>
        <ReactQuill
          readOnly={true}
          value={codeDetails?.description}
          className="codingPageProblemReactQuill"
        />
      </div>
      <div className="codingPageProblemTestCasesDiv">
        <div className="codingPageProblemTestCasesDivHeader">
          Go through the following examples below to understand the problem
          better:
        </div>
        <div className="codingPageProblemTestCasesDivBody">
          {codeDetails.istestcases === constants.ISNOTESTCASES.FALSE && (
            <>
              {learnerTestCases.length !== 0 &&
                learnerTestCases.map((testcase, index) => (
                  <div
                    key={index}
                    className="codingPageProblemTestCasesDivBodyContainer"
                  >
                    <div className="codingPageProblemTestCasesDivBodyContainerExampleDiv">
                      {" "}
                      Example {index + 1} :
                    </div>
                    <div className="codingPageProblemTestCasesDivBodyContainerInputDiv">
                      <div className="codingPageProblemTestCasesDivBodyContainerInputTitleDiv">
                        {testcase?.input !== "" &&
                          testcase?.input !== null &&
                          testcase?.input !== undefined &&
                          testcase.input.split("\n").map((input, ind) => (
                            <div
                              className="codingPageProblemTestCasesDivBodyContainerInputTitle"
                              key={ind}
                            >
                              <p className="codingPageProblemTestCasesDivBodyContainerInputVariable">
                                {isInputVariable.current
                                  ? inputVariable[ind] + " : "
                                  : "Input" + (ind + 1) + " : "}
                              </p>
                              <p className="codingPageProblemTestCasesDivBodyContainerInputValue">
                                {input}
                              </p>
                            </div>
                          ))}
                      </div>
                    </div>
                    <div className="codingPageProblemTestCasesDivBodyContainerOutputVariable">
                      Output {index + 1} :
                    </div>
                    <div className="codingPageProblemTestCasesDivBodyContainerOutputValue">
                      {testcase?.output !== "" &&
                        testcase?.output !== null &&
                        testcase?.output !== undefined &&
                        testcase.output}
                    </div>
                  </div>
                ))}
            </>
          )}
        </div>
      </div>
    </div>
  );
}
