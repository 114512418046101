import React, { useContext, useCallback } from "react";
import { Outlet, useLocation, useParams } from "react-router-dom";
import constants from "../../../constants";
import { LibraryContext } from "../../../context/LibraryContext";
import ModuleHooks from "../../../Hooks/ModuleHooks";
import Popup from "../../CommonComponents/Popup";
import { getDataFromStorage } from "../../../util";
import LibraryUtils, { libTypeName } from "./LibraryUtils";
import TestSvgIcon from "../../../Svg/TestSvgIcon";
import _ from "lodash";

const LibraryDetails = (props) => {
  const { id } = useParams();
  const { pathname } = useLocation();
  const { sharedTopics, setFilterSharedTopics } = useContext(LibraryContext);
  const { isCommonPopup } = ModuleHooks();
  const view = [`/library/${id}/questionlist`, `/library/${id}/shared`];

  const handleOnSearch = (event) => {
    let newDetails = JSON.parse(JSON.stringify(sharedTopics));
    setFilterSharedTopics(
      _.filter(newDetails, (topic) => topic.tname.includes(event.target.value))
    );
  };

  const onAdd = () => {
    props.que({ published: 0 });
    props.setisqueReport(false);
  };

  return (
    <div
      className="libdContainer"
      style={{
        marginTop: [
          "/library/questions",
          "/library/contententry",
          "/library/testlist",
          "/library/assignment",
        ].includes(pathname)
          ? "0px"
          : "50px",
        padding: [
          "/library/questions",
          "/library/contententry",
          "/library/testlist",
          "/library/assignment",
        ].includes(pathname)
          ? "0px"
          : "24px",
      }}
    >
      {parseInt(getDataFromStorage("accountType")) ===
        constants.ACCOUNT_TYPE.CLIENT_EVAL && view.includes(pathname) ? (
        <React.Fragment>
          <div className="libdTitle">
            <TestSvgIcon active={true} disable={false} libType={parseInt(id)} />
            {libTypeName[id]}
          </div>
          <LibraryUtils onSearch={handleOnSearch} onAdd={onAdd} />
        </React.Fragment>
      ) : null}
      <Outlet />
      {isCommonPopup && <Popup />}
    </div>
  );
};

export default LibraryDetails;
