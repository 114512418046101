import React from "react";

export default function CodingPageTestCasesFailedNotify(Props) {
  return (
    <ul className="modalInstructionBodyunorderedListWithTestCasesFailed">
      <div>
        We evaluated your code, against our test cases and found issues.
      </div>
      <div className="modalInstructionBodyTestCasesFailedTextList">
        As a next step
      </div>
      <li className="modalInstructionBodyTestCasesFailedTextList">
        {Props.response.data}
      </li>
      <li className="modalInstructionBodyTestCasesFailedTextList">
        We suggest, you read the challenge again and try your code against
        multiple test scenarios.
      </li>
    </ul>
  );
}
