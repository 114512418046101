import React from "react";

export default function ExpandSvgIcon({stroke}) {
  return (
    <svg
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        stroke={stroke ?stroke:"#667085"}
        stroke-width="1.6"
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M2 14L14 2M2 14V10M2 14L6 14M14 2V6M14 2H10"
      />
    </svg>
  );
}
