import Button from "../../../Button";
import Form from "react-bootstrap/Form";
import React, { useContext, useEffect } from "react";
import AlertContext from "../../../../context/AlertContext";
import MockLearningPathModule from "./MockLearningPathModule";
import Loading from "../../../Notification-Loading/Loading/Loading";
import { MockTestPageContext } from "../../../../context/MockTestPageContext";
import MockLearningPathHooks from "../../../../Hooks/Mock/Admin/MockLearningPath/MockLearningPathHooks";

export default function MockLearningPath(Props) {
  const Alert = useContext(AlertContext);
  const MockTestPageCxt = useContext(MockTestPageContext);

  const MockLearningPathHks = MockLearningPathHooks();

  useEffect(() => {
    MockTestPageCxt.setNewLpDetails(JSON.parse(JSON.stringify(Alert.lpData2)));
    MockLearningPathHks.setLpArchived(Alert.lpData2.archive);
  }, [Alert.lpData2]);

  return (
    <div className="adminLpContainer">
      {Alert.isOldLpDetail || Alert.isAddLp ? (
        <div id="adminAddLpContainer">
          {!Alert.isAddLpModule ? (
            <div style={{ marginTop: "20px", width: "50%" }}>
              <div className="adminLpInputSection">
                <label className="adminNewLpNameLabel" htmlFor="adminNewLpName">
                  Name
                </label>
                <input
                  type="text"
                  name="lpName"
                  maxLength="100"
                  id="adminNewLpName"
                  className="adminNewLpName"
                  value={MockTestPageCxt.newLpDetails.lpName}
                  onChange={MockLearningPathHks.handleLpDetails}
                  placeholder="Enter new mock learningpath name"
                />
              </div>
              <div className="adminLpDesSection">
                <label className="adminLpDesLabel" htmlFor="adminLpDes">
                  Description{" "}
                  <span className="adminOptionalText">(Optional)</span>
                </label>
                <textarea
                  name="lpDes"
                  id="adminLpDes"
                  className="adminLpDes"
                  placeholder="Enter the description"
                  onChange={MockLearningPathHks.handleLpDetails}
                  value={
                    MockTestPageCxt.newLpDetails.lpDes === undefined
                      ? ""
                      : MockTestPageCxt.newLpDetails.lpDes === null
                      ? ""
                      : MockTestPageCxt.newLpDetails.lpDes
                  }
                ></textarea>
              </div>
              <div className="adminLpIconSection">
                <label className="adminLpIconLabel" htmlFor="adminLpIcon">
                  Thumbnail
                </label>
                <input
                  type="file"
                  name="lpIcon"
                  accept="image/*"
                  id="adminLpIcon"
                  onChange={MockLearningPathHks.handleLpDetails}
                />
                {MockTestPageCxt.newLpDetails.lpIcon &&
                  MockTestPageCxt.newLpDetails.lpIcon !== "" && (
                    <img
                      alt="icon"
                      className="adminLpIconPreview"
                      src={MockTestPageCxt.newLpDetails.lpIcon}
                    />
                  )}
              </div>
              {!Alert.isFromBatch && Alert.isOldLpDetail && (
                <div className="archivebatchswitch-div">
                  <Form.Check
                    type="switch"
                    name="archived"
                    id="archive-batch-switch"
                    style={{ boxShadow: "none" }}
                    className="archivebatchswitch"
                    onChange={MockLearningPathHks.handleLpArchive}
                    checked={Boolean(MockLearningPathHks.lpArchived)}
                  />
                  Archive
                </div>
              )}
              <div className="adminLpBtnSec">
                {!Alert.isFromBatch && (
                  <div className="adminTestSecondaryBtn">
                    <Button
                      size="sm"
                      onClick={MockLearningPathHks.handleCancel}
                      hierarchy={{
                        buttonText: "Cancel",
                        type: "secondaryGrey",
                      }}
                    />
                  </div>
                )}
                <div>
                  <Button
                    size="sm"
                    onClick={MockLearningPathHks.handleClickNext}
                    hierarchy={{
                      type: "primary",
                      buttonText: "Next",
                    }}
                  />
                </div>
              </div>
            </div>
          ) : (
            <MockLearningPathModule
              lpData={Alert.lpData2}
              isLoaded={Alert.isLoaded}
              isUnAuth={Alert.isUnAuth}
              setLpData={Alert.setLpData2}
              setIsAddLp={Alert.setIsAddLp}
              setIsLoaded={Alert.setIsLoaded}
              setIsUnAuth={Alert.setIsUnAuth}
              isFromBatch={Alert.isFromBatch}
              setIsFromLp={Alert.setIsFromLp}
              isOldLpDetail={Alert.isOldLpDetail}
              setisqueReport={Alert.setisqueReport}
              moduleExpandKey={Alert.moduleExpandKey}
              setIsAddLpModule={Alert.setIsAddLpModule}
              setIsOldLpDetail={Alert.setIsOldLpDetail}
              quereport={MockLearningPathHks.quereport}
              newLpDetails={MockTestPageCxt.newLpDetails}
              setModuleExpandKey={Alert.setModuleExpandKey}
              setTableShowStatus={Alert.setTableShowStatus}
              setNewLpDetails={MockTestPageCxt.setNewLpDetails}
            />
          )}
        </div>
      ) : (
        <MockLearningPathModule
          lpData={Alert.lpData2}
          isLoaded={Alert.isLoaded}
          isUnAuth={Alert.isUnAuth}
          setLpData={Alert.setLpData2}
          setIsAddLp={Alert.setIsAddLp}
          setIsLoaded={Alert.setIsLoaded}
          setIsFromLp={Alert.setIsFromLp}
          setIsUnAuth={Alert.setIsUnAuth}
          isOldLpDetail={Alert.isOldLpDetail}
          setisqueReport={Alert.setisqueReport}
          moduleExpandKey={Alert.moduleExpandKey}
          setIsOldLpDetail={Alert.setIsOldLpDetail}
          setIsAddLpModule={Alert.setIsAddLpModule}
          quereport={MockLearningPathHks.quereport}
          setModuleExpandKey={Alert.setModuleExpandKey}
          setTableShowStatus={Alert.setTableShowStatus}
        />
      )}
      {Alert.isLoaded && <Loading />}
    </div>
  );
}
