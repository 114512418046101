import React, {  useContext } from "react";
import { useLocation, Link, useParams } from "react-router-dom";
import Button from "../../Button";
import InputDropdown from "../../CommonComponents/InputDropdown";
import Search from "../../CommonComponents/Search";
import { getDataFromStorage } from "../../../util";
import constants from "../../../constants";
import AlertContext from "../../../context/AlertContext";
import { LibraryContext } from "../../../context/LibraryContext";
import TestSvgIcon from "../../../Svg/TestSvgIcon";

export const libTypeName = {
  1: "Content",
  2: "MCQ",
  3: "Descriptive",
  4: "Assignment",
  5: "Coding Challenge",
};

const LibraryUtils = ({ onSearch, onAdd, onTopicSelect }) => {
  const { pathname } = useLocation();
  const { id } = useParams();
  const { navigate } = useContext(AlertContext);
  const {
    selectedTopic,
    setSelectedTopic,
    searchText,
    setSearchText,
    setCurrentPage,
    topics,
  } = useContext(LibraryContext);

  const onChange = (event) => {
    if (onSearch) onSearch(event);
    setSearchText(event.target.value);
  };
  const handleOnChangeTopic = (event) => {
    if (onTopicSelect) onTopicSelect(event);
    setSelectedTopic(event.target);
  };

  const handleOnClickAdd = () => {
    if (onAdd) onAdd();
    if (parseInt(id) === constants.libTypeIds.CONTENT) {
      navigate("/library/contententry");
    } else if (parseInt(id) === constants.libTypeIds.ASSIGNMENT)
      navigate("/library/assignment");
    else {
      navigate("/library/questions");
    }
  };

  const clearState = () => {
    setSelectedTopic({ value: "", label: "", id: null });
    setSearchText("");
    setCurrentPage(1);
  };

  return (
    <div className="libdTabsAndUtilsContainter">
      {parseInt(getDataFromStorage("accountType")) ===
      constants.ACCOUNT_TYPE.CLIENT_EVAL ? (
        <div className="libdTabs">
          <Link
            className={`libdSharedQues libdTab ${
              pathname === `/library/${id}/shared` ? "active" : ""
            }`}
            to={`/library/${id}/shared`}
            onClick={clearState}
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M24 0H0V10.5882C0 15.9017 3.27815 20.665 8.24164 22.5634L12 24L15.7584 22.5634C20.7219 20.665 24 15.9017 24 10.5882V0ZM16.9412 8.47059C16.9412 11.199 14.7289 13.4118 12 13.4118C9.27108 13.4118 7.05882 11.199 7.05882 8.47059C7.05882 5.74219 9.27108 3.52941 12 3.52941H16.9412V8.47059ZM12 10.5882C13.1695 10.5882 14.1176 9.63971 14.1176 8.47059C14.1176 7.30147 13.1695 6.35294 12 6.35294C10.8305 6.35294 9.88235 7.30147 9.88235 8.47059C9.88235 9.63971 10.8305 10.5882 12 10.5882ZM7.05882 17.6471C7.05882 16.0878 8.32298 14.8235 9.88235 14.8235H16.9412C16.9412 16.3828 15.677 17.6471 14.1176 17.6471H7.05882Z"
                fill="#F55533"
              />
            </svg>
            GRADIOUS
          </Link>
          <Link
            className={`libdMyQuesContainer libdTab ${
              pathname === `/library/${id}/questionlist` ? "active" : ""
            }`}
            to={`/library/${id}/questionlist`}
            onClick={clearState}
          >
            {`MY ${
              parseInt(id) === constants.libTypeIds.CONTENT
                ? "CONTENT"
                : parseInt(id) === constants.libTypeIds.ASSIGNMENT
                ? "ASSIGNMENTS"
                : "QUESTIONS"
            }`}
          </Link>
        </div>
      ) : (
        <div className="libdTitle" style={{ padding: "9px 16px" }}>
          <TestSvgIcon active={true} disable={false} libType={parseInt(id)} />
          {libTypeName[id]}
        </div>
      )}
      <div className="libdUtils">
        <Search iconPosition="end" onChange={onChange} value={searchText} />
        {pathname === `/library/${id}/questionlist` ? (
          <React.Fragment>
            <InputDropdown
              type="default"
              placeholder="Choose topics"
              onChange={handleOnChangeTopic}
              value={selectedTopic.value}
              menuLists={topics}
            />
            <Button
              size="sm"
              hierarchy={{ type: "primary", buttonText: "Add" }}
              onClick={handleOnClickAdd}
            />
          </React.Fragment>
        ) : null}
      </div>
    </div>
  );
};

export default LibraryUtils;
