import React from 'react'

export default function RankSecondSvg() {
  return (
    <svg width="30" height="32" viewBox="0 0 30 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15 30.9199C23.2843 30.9199 30 24.2042 30 15.9199C30 7.63565 23.2843 0.919922 15 0.919922C6.71573 0.919922 0 7.63565 0 15.9199C0 24.2042 6.71573 30.9199 15 30.9199Z" fill="url(#paint0_linear_1665_14805)"/>
        <g filter="url(#filter0_i_1665_14805)">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M15.0005 5C21.075 5 26 9.92359 26 15.9991C26 22.0751 21.075 27 15.0005 27C8.92488 27 4 22.0751 4 15.9991C4 9.92396 8.92488 5 15.0005 5Z" fill="url(#paint1_linear_1665_14805)"/>
        </g>
        <g filter="url(#filter1_dd_1665_14805)">
        <path d="M11.0653 22V20.4773L15.1051 16.517C15.4915 16.1269 15.8134 15.7803 16.071 15.4773C16.3286 15.1742 16.5218 14.8807 16.6506 14.5966C16.7794 14.3125 16.8438 14.0095 16.8438 13.6875C16.8438 13.3201 16.7604 13.0057 16.5938 12.7443C16.4271 12.4792 16.1979 12.2746 15.9062 12.1307C15.6146 11.9867 15.2831 11.9148 14.9119 11.9148C14.5294 11.9148 14.1941 11.9943 13.9062 12.1534C13.6184 12.3087 13.3949 12.5303 13.2358 12.8182C13.0805 13.1061 13.0028 13.4489 13.0028 13.8466H10.9972C10.9972 13.108 11.1657 12.4659 11.5028 11.9205C11.84 11.375 12.304 10.9527 12.8949 10.6534C13.4896 10.3542 14.1714 10.2045 14.9403 10.2045C15.7206 10.2045 16.4063 10.3504 16.9972 10.642C17.5881 10.9337 18.0464 11.3333 18.3722 11.8409C18.7017 12.3485 18.8665 12.928 18.8665 13.5795C18.8665 14.0152 18.7831 14.4432 18.6165 14.8636C18.4498 15.2841 18.1563 15.75 17.7358 16.2614C17.3191 16.7727 16.7339 17.392 15.9801 18.1193L13.9744 20.1591V20.2386H19.0426V22H11.0653Z" fill="url(#paint2_linear_1665_14805)"/>
        </g>
        <defs>
        <filter id="filter0_i_1665_14805" x="4" y="5" width="22" height="22" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset/>
        <feGaussianBlur stdDeviation="2"/>
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.18 0"/>
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_1665_14805"/>
        </filter>
        <filter id="filter1_dd_1665_14805" x="4.99707" y="8.20459" width="20.0457" height="23.7954" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feMorphology radius="2" operator="erode" in="SourceAlpha" result="effect1_dropShadow_1665_14805"/>
        <feOffset dy="2"/>
        <feGaussianBlur stdDeviation="2"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.06 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1665_14805"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feMorphology radius="2" operator="erode" in="SourceAlpha" result="effect2_dropShadow_1665_14805"/>
        <feOffset dy="4"/>
        <feGaussianBlur stdDeviation="4"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.1 0"/>
        <feBlend mode="normal" in2="effect1_dropShadow_1665_14805" result="effect2_dropShadow_1665_14805"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_1665_14805" result="shape"/>
        </filter>
        <linearGradient id="paint0_linear_1665_14805" x1="5.5" y1="4.41992" x2="24.5" y2="27.4199" gradientUnits="userSpaceOnUse">
        <stop stop-color="#D3CBCC"/>
        <stop offset="1" stop-color="#BFB5B5"/>
        </linearGradient>
        <linearGradient id="paint1_linear_1665_14805" x1="8.78137" y1="7.18835" x2="20.5626" y2="24.6571" gradientUnits="userSpaceOnUse">
        <stop stop-color="#A19797" stop-opacity="0.5"/>
        <stop offset="1" stop-color="#948989" stop-opacity="0.8"/>
        </linearGradient>
        <linearGradient id="paint2_linear_1665_14805" x1="12" y1="11" x2="18.9257" y2="21.1913" gradientUnits="userSpaceOnUse">
        <stop stop-color="#EEEAEA"/>
        <stop offset="1" stop-color="#C2B4B4"/>
        </linearGradient>
        </defs>
    </svg>

)
}
