import React from "react";

export default function ProctorEyeSvg(Props) {
  return (
    <svg
      width="40"
      height="40"
      fill="none"
      viewBox="0 0 40 40"
      onClick={Props.onClick}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_2786_14069)">
        <rect x="2" y="1" width="36" height="36" rx="8" fill="white" />
        <rect
          x="2.5"
          y="1.5"
          rx="7.5"
          width="35"
          height="35"
          stroke="#D0D5DD"
        />
        <g clip-path="url(#clip0_2786_14069)">
          <path
            stroke="#344054"
            stroke-width="1.67"
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M10.834 18.9997C10.834 18.9997 14.1673 12.333 20.0007 12.333C25.834 12.333 29.1673 18.9997 29.1673 18.9997C29.1673 18.9997 25.834 25.6663 20.0007 25.6663C14.1673 25.6663 10.834 18.9997 10.834 18.9997Z"
          />
          <path
            stroke="#344054"
            stroke-width="1.67"
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M20.0007 21.4997C21.3814 21.4997 22.5006 20.3804 22.5006 18.9997C22.5006 17.619 21.3814 16.4997 20.0007 16.4997C18.6199 16.4997 17.5007 17.619 17.5007 18.9997C17.5007 20.3804 18.6199 21.4997 20.0007 21.4997Z"
          />
        </g>
      </g>
      <defs>
        <filter
          x="0"
          y="0"
          width="40"
          height="40"
          id="filter0_d_2786_14069"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            type="matrix"
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.05 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_2786_14069"
          />
          <feBlend
            mode="normal"
            result="shape"
            in="SourceGraphic"
            in2="effect1_dropShadow_2786_14069"
          />
        </filter>
        <clipPath id="clip0_2786_14069">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(10 9)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
