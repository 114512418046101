import React from 'react'

export default function Slack() {
  return (
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M19.8752 11.2497C20.9106 11.2497 21.75 10.4103 21.75 9.37484C21.75 8.33939 20.9105 7.5 19.8751 7.5C18.8395 7.5 18 8.33947 18 9.375V11.2497H19.8752ZM14.625 11.2497C15.6605 11.2497 16.5 10.4102 16.5 9.37468V4.125C16.5 3.08947 15.6605 2.25 14.625 2.25C13.5895 2.25 12.75 3.08947 12.75 4.125V9.37468C12.75 10.4102 13.5895 11.2497 14.625 11.2497Z" fill="#2EB67D"/>
      <path d="M4.12484 12.7503C3.08939 12.7503 2.25 13.5897 2.25 14.6252C2.25 15.6606 3.08947 16.5 4.12492 16.5C5.16045 16.5 6 15.6605 6 14.625V12.7503H4.12484ZM9.375 12.7503C8.33947 12.7503 7.5 13.5898 7.5 14.6253V19.875C7.5 20.9105 8.33947 21.75 9.375 21.75C10.4105 21.75 11.25 20.9105 11.25 19.875V14.6253C11.25 13.5898 10.4105 12.7503 9.375 12.7503Z" fill="#E01E5A"/>
      <path d="M12.7501 19.8752C12.7501 20.9106 13.5895 21.75 14.625 21.75C15.6604 21.75 16.4998 20.9105 16.4998 19.8751C16.4998 18.8395 15.6603 18 14.6248 18L12.7501 18L12.7501 19.8752ZM12.7501 14.625C12.7501 15.6605 13.5896 16.5 14.6251 16.5L19.8748 16.5C20.9103 16.5 21.7498 15.6605 21.7498 14.625C21.7498 13.5895 20.9104 12.75 19.8748 12.75L14.6251 12.75C13.5896 12.75 12.7501 13.5895 12.7501 14.625Z" fill="#ECB22E"/>
      <path d="M11.2498 4.12484C11.2498 3.08939 10.4104 2.25 9.37496 2.25C8.33952 2.25 7.50012 3.08947 7.50012 4.12492C7.50012 5.16045 8.33959 6 9.37512 6L11.2498 6L11.2498 4.12484ZM11.2498 9.375C11.2498 8.33947 10.4103 7.5 9.3748 7.5L4.12512 7.5C3.08959 7.5 2.25012 8.33947 2.25012 9.375C2.25012 10.4105 3.08959 11.25 4.12512 11.25L9.3748 11.25C10.4103 11.25 11.2498 10.4105 11.2498 9.375Z" fill="#36C5F0"/>
      </svg>
  )
}
