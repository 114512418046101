import React from 'react'
import './QuestionBox.css'
import { nanoid } from "nanoid";

export default function QuestionBox(props) {
    var style = "rgb(218, 215, 235)"


    if(props.visited=='this'){
        (!props.hasOwnProperty('quedivid'))?getprevisitcolor(props.previsited):style = getprevisitcolor(props.previsited);
    }else 
    
    if(props.visited=='completed'){
    //    style="green";

    (!props.hasOwnProperty('quedivid'))?style="#2AC769":style = "#2AC769";
    }else if(props.visited=='not'){
        (!props.hasOwnProperty('quedivid'))?  style="#FFBC1F":style = getprevisitcolor(props.previsited);
    }else if(props.visited=='seen'){
        style="#FFBC1F";
    } else if(props.visited=='review')
    {
        style="#F55533";
    }

    if(props.review==0)
    {
        if(props.visited!="this")
        {
        console.log("this ine is review");
        style="#F55533"
    }
}

function getprevisitcolor(previsit)
{
   if( previsit=='this')
   {
    return "rgb(218, 215, 235)";
   }else if(previsit=='not')
   {
    return "#FFBC1F";
   }else if(previsit=='seen'){
    return "#FFBC1F"
   }else if (previsit=='review')
   {
    return "#F55533";
   }else if(previsit=='completed'){
   return "#2AC769";
   }else if(previsit=='visited')
   {

   }

}

    return (
        <div className= {((!props.hasOwnProperty('quedivid'))&&((props.propqi==props.index)&&(props.section==props.propsi)))?"questionbox-showthis-yes":"questionbox-showthis-no"}>
        <button key={props.section+""+props.index}  className="mcq-question-number-box"
        
        onClick={() => {
            if(props.hasOwnProperty('quedivid'))
            {
                props.multitraverse(props.quedivid,props.sindex,props.index)
            }else{
            props.handleClick(props.section,props.index,props.testdata)}
        }
        
        } 


        style={{backgroundColor:style, color:((!props.hasOwnProperty('quedivid'))&&((props.propqi==props.index)&&(props.section==props.propsi)))?"black":"white"}}>
            {
                props.value
            }
        </button>

        </div>
)
}
