import React from "react";
import "./GradiousLogoSvg.css";

export default function GradiousLogoSvg(props) {
  return (
    <div  className="logoForCodingPage">
    <svg
      // width="175"
      // height="35"
      // fill="none"
      // viewBox="0 0 175 35"
      // className="logoForCodingPage"
      // xmlns="http://www.w3.org/2000/svg"
      width={props.height? props.height : "4.9vh"}
      height={props.width?props.width:"4.9vh"}
      fill="none"
      viewBox="0 0 35 35"
      // className="logoForCodingPage"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill={props.fill ? props.fill : "#F55533"}
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M35 0H0V15.4412C0 23.1899 4.78063 30.1364 12.0191 32.905L17.5 35L22.9809 32.905C30.2194 30.1364 35 23.1899 35 15.4412V0ZM24.7059 12.3529C24.7059 16.3319 21.4797 19.5588 17.5 19.5588C13.5203 19.5588 10.2941 16.3319 10.2941 12.3529C10.2941 8.37402 13.5203 5.14706 17.5 5.14706H24.7059V12.3529ZM17.5 15.4412C19.2056 15.4412 20.5882 14.0579 20.5882 12.3529C20.5882 10.648 19.2056 9.26471 17.5 9.26471C15.7944 9.26471 14.4118 10.648 14.4118 12.3529C14.4118 14.0579 15.7944 15.4412 17.5 15.4412ZM10.2941 25.7353C10.2941 23.4613 12.1377 21.6176 14.4118 21.6176H24.7059C24.7059 23.8916 22.8623 25.7353 20.5882 25.7353H10.2941Z"
      />
      <path
        fill="white"
        d="M48.7713 6.84733C51.1435 6.84733 53.1204 7.42578 54.7019 8.5827C56.2834 9.73961 57.3283 11.3164 57.8366 13.313H54.4477C54.0147 12.2494 53.2993 11.4003 52.3014 10.7659C51.3224 10.1315 50.1551 9.81425 48.7996 9.81425C47.557 9.81425 46.4367 10.1035 45.4389 10.6819C44.4599 11.2604 43.688 12.0814 43.1232 13.145C42.5772 14.2087 42.3042 15.4589 42.3042 16.8957C42.3042 18.4071 42.5866 19.704 43.1514 20.7863C43.7162 21.8685 44.507 22.6989 45.5236 23.2774C46.5591 23.8371 47.7546 24.117 49.1102 24.117C50.767 24.117 52.1885 23.6132 53.3746 22.6056C54.5607 21.5793 55.2761 20.1798 55.5209 18.4071H48.2065V16.056H58.232V19.1069C58.0061 20.5623 57.4789 21.8872 56.6505 23.0814C55.8409 24.257 54.7584 25.1993 53.4028 25.9084C52.0661 26.6175 50.5317 26.972 48.7996 26.972C46.8604 26.972 45.1471 26.5428 43.6597 25.6845C42.1724 24.8261 41.0239 23.6412 40.2144 22.1298C39.4048 20.5997 39 18.855 39 16.8957C39 14.9364 39.4048 13.201 40.2144 11.6896C41.0239 10.1595 42.163 8.97455 43.6315 8.13486C45.1188 7.27651 46.8321 6.84733 48.7713 6.84733Z"
      />
      <path
        fill="white"
        d="M65.9951 14.3766C66.5223 13.369 67.2754 12.5759 68.2544 11.9975C69.2334 11.4003 70.3631 11.1018 71.6433 11.1018V14.5725H70.6831C69.2711 14.5725 68.132 14.9177 67.266 15.6081C66.4188 16.2799 65.9951 17.3995 65.9951 18.9669V26.7761H62.7757V11.3537H65.9951V14.3766Z"
      />
      <path
        fill="white"
        d="M81.5804 11.1578C82.9924 11.1578 84.1973 11.503 85.1952 12.1934C86.193 12.8838 86.899 13.7981 87.3132 14.9364V11.3537H90.5327V26.7761H87.3132V23.1934C86.899 24.3316 86.193 25.246 85.1952 25.9364C84.1973 26.6268 82.9924 26.972 81.5804 26.972C80.2248 26.972 79.0104 26.6548 77.9373 26.0204C76.883 25.3859 76.0546 24.4716 75.4521 23.2774C74.8496 22.0831 74.5484 20.6743 74.5484 19.0509C74.5484 17.4461 74.8496 16.0466 75.4521 14.8524C76.0546 13.6582 76.883 12.7439 77.9373 12.1094C79.0104 11.475 80.2248 11.1578 81.5804 11.1578ZM82.5688 13.9567C81.1379 13.9567 79.9895 14.4139 79.1234 15.3282C78.2762 16.2239 77.8526 17.4648 77.8526 19.0509C77.8526 20.637 78.2762 21.8872 79.1234 22.8015C79.9895 23.6972 81.1379 24.145 82.5688 24.145C83.4725 24.145 84.2821 23.9398 84.9975 23.5293C85.7129 23.1001 86.2777 22.503 86.6919 21.7379C87.1061 20.9729 87.3132 20.0772 87.3132 19.0509C87.3132 18.0433 87.1061 17.1569 86.6919 16.3919C86.2777 15.6081 85.7129 15.011 84.9975 14.6005C84.2821 14.1713 83.4725 13.9567 82.5688 13.9567Z"
      />
      <path
        fill="white"
        d="M101.921 11.1578C103.333 11.1578 104.538 11.503 105.536 12.1934C106.534 12.8838 107.23 13.7981 107.626 14.9364V6.06361H110.873V26.7761H107.626V23.1934C107.23 24.3316 106.534 25.246 105.536 25.9364C104.538 26.6268 103.333 26.972 101.921 26.972C100.565 26.972 99.3511 26.6548 98.278 26.0204C97.2237 25.3859 96.3953 24.4716 95.7928 23.2774C95.1903 22.0831 94.8891 20.6743 94.8891 19.0509C94.8891 17.4461 95.1903 16.0466 95.7928 14.8524C96.3953 13.6582 97.2237 12.7439 98.278 12.1094C99.3511 11.475 100.565 11.1578 101.921 11.1578ZM102.909 13.9567C101.479 13.9567 100.33 14.4139 99.4641 15.3282C98.6169 16.2239 98.1933 17.4648 98.1933 19.0509C98.1933 20.637 98.6169 21.8872 99.4641 22.8015C100.33 23.6972 101.479 24.145 102.909 24.145C103.813 24.145 104.623 23.9398 105.338 23.5293C106.054 23.1001 106.618 22.503 107.033 21.7379C107.447 20.9729 107.654 20.0772 107.654 19.0509C107.654 18.0433 107.447 17.1569 107.033 16.3919C106.618 15.6081 106.054 15.011 105.338 14.6005C104.623 14.1713 103.813 13.9567 102.909 13.9567Z"
      />
      <path
        fill="white"
        d="M115.569 6.87532C115.569 6.33418 115.757 5.88634 116.133 5.53181C116.51 5.17727 117.009 5 117.63 5C118.252 5 118.75 5.17727 119.127 5.53181C119.522 5.88634 119.72 6.33418 119.72 6.87532C119.72 7.41646 119.522 7.85496 119.127 8.19084C118.75 8.52672 118.252 8.69466 117.63 8.69466C117.009 8.69466 116.51 8.52672 116.133 8.19084C115.757 7.85496 115.569 7.41646 115.569 6.87532ZM119.24 11.3537V26.7761H116.021V11.3537H119.24Z"
      />
      <path
        fill="white"
        d="M131.536 11.1578C133.042 11.1578 134.398 11.475 135.603 12.1094C136.808 12.7439 137.749 13.6582 138.427 14.8524C139.105 16.0466 139.444 17.4461 139.444 19.0509C139.444 20.6743 139.105 22.0831 138.427 23.2774C137.749 24.4716 136.808 25.3859 135.603 26.0204C134.398 26.6548 133.042 26.972 131.536 26.972C130.03 26.972 128.674 26.6548 127.47 26.0204C126.283 25.3859 125.342 24.4716 124.645 23.2774C123.968 22.0645 123.629 20.6556 123.629 19.0509C123.629 17.4461 123.968 16.0466 124.645 14.8524C125.342 13.6582 126.283 12.7439 127.47 12.1094C128.674 11.475 130.03 11.1578 131.536 11.1578ZM131.536 13.9288C130.708 13.9288 129.945 14.1154 129.249 14.4886C128.552 14.8617 127.987 15.4402 127.554 16.2239C127.14 16.989 126.933 17.9313 126.933 19.0509C126.933 20.1891 127.14 21.1408 127.554 21.9059C127.987 22.6709 128.552 23.24 129.249 23.6132C129.945 23.9864 130.708 24.173 131.536 24.173C132.365 24.173 133.127 23.9864 133.824 23.6132C134.52 23.24 135.076 22.6709 135.49 21.9059C135.923 21.1408 136.139 20.1891 136.139 19.0509C136.139 17.9313 135.923 16.989 135.49 16.2239C135.076 15.4402 134.52 14.8617 133.824 14.4886C133.127 14.1154 132.365 13.9288 131.536 13.9288Z"
      />
      <path
        fill="white"
        d="M158.169 11.3537V26.7761H154.921V23.4173C154.526 24.5556 153.839 25.4419 152.86 26.0763C151.881 26.6921 150.742 27 149.442 27C147.635 27 146.185 26.4215 145.093 25.2646C144.001 24.1077 143.455 22.4563 143.455 20.3104V11.3537H146.675V19.9746C146.675 21.374 147.033 22.4563 147.748 23.2214C148.463 23.9678 149.442 24.341 150.685 24.341C151.965 24.341 152.991 23.9304 153.763 23.1094C154.535 22.2884 154.921 21.0941 154.921 19.5267V11.3537H158.169Z"
      />
      <path
        fill="white"
        d="M168.42 11.1578C170.246 11.1578 171.705 11.6243 172.797 12.5573C173.908 13.4716 174.586 14.7031 174.831 16.2519H171.781C171.649 15.4122 171.282 14.7405 170.679 14.2366C170.096 13.7142 169.314 13.4529 168.335 13.4529C167.526 13.4529 166.895 13.6489 166.443 14.0407C166.01 14.4139 165.794 14.9271 165.794 15.5802C165.794 16.0653 165.954 16.4572 166.274 16.7557C166.594 17.0543 166.989 17.2875 167.46 17.4555C167.949 17.6234 168.636 17.81 169.521 18.0153C170.689 18.2765 171.63 18.5471 172.345 18.827C173.08 19.1069 173.701 19.5547 174.209 20.1705C174.736 20.7676 175 21.5886 175 22.6336C175 23.9211 174.501 24.9661 173.503 25.7684C172.524 26.5708 171.197 26.972 169.521 26.972C167.582 26.972 166.01 26.5242 164.805 25.6285C163.619 24.7328 162.904 23.4826 162.659 21.8779H165.794C165.888 22.7176 166.264 23.3986 166.923 23.9211C167.582 24.4436 168.448 24.7048 169.521 24.7048C170.293 24.7048 170.896 24.5089 171.329 24.117C171.781 23.7065 172.006 23.1934 172.006 22.5776C172.006 22.0365 171.837 21.6073 171.498 21.2901C171.159 20.9729 170.736 20.7303 170.227 20.5623C169.719 20.3757 169.022 20.1891 168.137 20.0025C167.008 19.7413 166.085 19.4801 165.37 19.2188C164.673 18.9576 164.071 18.5377 163.562 17.9593C163.073 17.3622 162.828 16.5598 162.828 15.5522C162.828 14.246 163.318 13.1917 164.297 12.3893C165.295 11.5683 166.669 11.1578 168.42 11.1578Z"
      />
    </svg>
    </div>
  );
}
