import { nanoid } from "nanoid";
import { IconButton } from "@mui/material";
import constants from "../../../../constants";
import React, { useContext, useState } from "react";
import EditIconSvg from "../../../../Svg/EditIconSvg";
import TestSvgIcon from "../../../../Svg/TestSvgIcon";
import useAxiosPrivate from "../../../useAxiosPrivate";
import DeleteIconSvg from "../../../../Svg/DeleteIconSvg";
import AlertContext from "../../../../context/AlertContext";
import useCommonFunctionHooks from "../../../useCommonFunctionHooks";
import { MockTestPageContext } from "../../../../context/MockTestPageContext";

export default function MockLearningPathModuleDetailsHooks() {
  const Alert = useContext(AlertContext);
  const MockTestPageCxt = useContext(MockTestPageContext);

  const axios = useAxiosPrivate();

  const { getCatchBlockDetails, getHeaderDetailsForAxios } =
    useCommonFunctionHooks();

  const [cursorPointer, setCursorPointer] = useState(0);

  const quereport = (que) => {
    Alert.setselectque(que);

    if (que.type === constants.libTypeIds.ASSIGNMENT)
      Alert.navigate("/library/assignment");
    else Alert.navigate("/library/questions");
  };

  const handleGetTestConfigById = (moduleId, isEdited, modData) => {
    let testId =
      Alert.isOldLpDetail && modData.hasOwnProperty("testId")
        ? modData.testId
        : modData.id;

    if (
      modData.libTypeId !== constants.libTypeIds.CONTENT &&
      modData.libTypeId !== constants.libTypeIds.ASSIGNMENT
    ) {
      if (!isEdited) {
        Alert.setIsLoaded(true);
        axios
          .get("node/admin/test/getbyid/" + testId, getHeaderDetailsForAxios())
          .then((response) => {
            if (response.data.resultCode === constants.RESULT_STATUS.SUCCESS) {
              handleEditTestConfig(
                JSON.parse(response.data.data),
                moduleId,
                isEdited,
                modData.status,
                modData.published,
                modData.testStatus
              );
            } else {
              Alert.setShowNotify({
                show: true,
                title: "Error",
                msg: "Something went wrong. Please try again later.",
              });
            }
          })
          .catch((error) => {
            getCatchBlockDetails(error);
          })
          .finally(() => Alert.setIsLoaded(false));
      } else {
        handleEditTestConfig(modData, moduleId, isEdited);
      }
    } else {
      Alert.setIsLoaded(true);

      let libId =
        Alert.isOldLpDetail && modData.hasOwnProperty("libId")
          ? modData.libId
          : modData.id;

      axios
        .get("node/admin/content/" + libId, getHeaderDetailsForAxios())
        .then((response) => {
          let data = response.data.data;

          data.published = modData.published;

          let newDetails = JSON.parse(JSON.stringify(Alert.lpData2));

          newDetails.status = "update";
          newDetails.moduleId = moduleId;
          newDetails.modules = MockTestPageCxt.modules;
          Alert.setLpData2(newDetails);
          quereport(data);
          Alert.setTableShowStatus(modData.libTypeId);
          Alert.setisqueReport(true);
          Alert.setIsFromLp(true);
        })
        .catch((error) => {
          getCatchBlockDetails(error);
        })
        .finally(() => Alert.setIsLoaded(false));
    }
  };

  const handleEditTestConfig = (
    oldTestConfigData,
    moduleId,
    isEdited,
    status,
    published,
    testStatus
  ) => {
    Alert.setFromMenu(false);

    if (!isEdited) {
      let data = oldTestConfigData[0],
        count = 0,
        uniqueId = 1;

      data.isOld = true;
      data.status = status;
      data.published = published;
      data.testStatus = testStatus;
      data.libTypeId = data.libType;
      data.testDurationUnit = "min/s";
      data.testDurationNum = data.testDuration;
      data.testDuration = data.testDuration + "m";
      data.testType = { label: data.libName, value: data.libType };

      for (let i = 0; i < data.sections.length; i++) {
        count++;
        data.sections[i].id = uniqueId;
        data.sections[i].key = nanoid();
        data.sections[i].status = status;
        data.sections[i].testStatus = testStatus;
        data.sections[i].type = testStatus === "new" ? "insert" : testStatus;
        data.sections[i].isOld = testStatus === "new" ? false : true;
        uniqueId++;

        for (let j = 0; j < data.sections[i].secData.length; j++) {
          data.sections[i].secData[j].id = uniqueId;
          data.sections[i].secData[j].key = nanoid();
          data.sections[i].secData[j].type =
            testStatus === "new" ? "insert" : testStatus;
          data.sections[i].secData[j].isOld =
            testStatus === "new" ? false : true;
          data.sections[i].secData[j].topic = {
            label: data.sections[i].secData[j].topicName,
            value: data.sections[i].secData[j].topicId,
          };
          data.sections[i].secData[j].status = status;
          data.sections[i].secData[j].desType = {
            label: data.sections[i].secData[j].configLibName,
            value: data.sections[i].secData[j].configLibType,
          };
          data.sections[i].secData[j].complexity = {
            label: data.sections[i].secData[j].complexityName,
            value: data.sections[i].secData[j].complexityId,
          };
          data.sections[i].secData[j].testStatus = testStatus;
          if (
            data.libTypeId === constants.libTypeIds.DESCRIPTIVE &&
            data.videoQn
          ) {
            data.sections[i].secData[j].durationNum =
              data.sections[i].secData[j].duration /
              (data.sections[i].secData[j].randomQnCount +
                data.sections[i].secData[j].fixedQnCount);
            data.sections[i].secData[j].durationUnit = "min/s";
          }

          if (data.libTypeId === constants.libTypeIds.CODING_CHALLENGE) {
            let languages = [];

            for (
              let k = 0;
              k < data.sections[i].secData[j].languages.length;
              k++
            ) {
              languages.push({
                value: data.sections[i].secData[j].languages[k].id,
                label: data.sections[i].secData[j].languages[k].name,
                key: nanoid(),
              });
            }

            data.sections[i].secData[j].languages = languages;
            data.sections[i].secData[j].durationNum =
              data.sections[i].secData[j].duration /
              (data.sections[i].secData[j].randomQnCount +
                data.sections[i].secData[j].fixedQnCount);
            data.sections[i].secData[j].durationUnit = "min/s";
          }

          uniqueId += 8;
        }

        Alert.setOldTestConfig({
          oldTestData: data,
          moduleId: moduleId,
          isOldTestConfig: true,
        });

        // setLpData2(data);

        if (count === data.sections.length) Alert.navigate("/library/testlist");
      }
    } else {
      Alert.setOldTestConfig({
        moduleId: moduleId,
        isOldTestConfig: true,
        oldTestData: Alert.oldTestConfig.oldTestData,
      });
      Alert.navigate("/library/testlist");
    }
  };

  const handleDeleteModuleDetails = (moduleId, modDetailsId, record) => {
    let newDetails = [...MockTestPageCxt.modules];

    for (let i = 0; i < newDetails.length; i++) {
      if (newDetails[i].id === moduleId) {
        if (record.status === "old") {
          Alert.setIsLoaded(true);
          axios
            .post(
              "node/admin/learningpath/module/lpconfig/delete",
              JSON.stringify({ lpcid: record.lpcId, lpid: Alert.lpData2.lpId }),
              getHeaderDetailsForAxios()
            )
            .then((res) => {
              if (res.data.resultCode === constants.RESULT_STATUS.SUCCESS) {
                Alert.setShowNotify({
                  show: true,
                  title: "Success",
                  msg: "The learningpath configuration is deleted successfully",
                });

                for (let j = 0; j < newDetails[i].moduleData.length; j++) {
                  if (newDetails[i].moduleData[j].moduleDataId === modDetailsId)
                    newDetails[i].moduleData.splice(j, 1);
                }
              } else {
                Alert.setShowNotify({
                  show: true,
                  title: "Error",
                  msg: "Something went wrong. Please try again later.",
                });
              }
            })
            .catch((error) => {
              getCatchBlockDetails(error);
            })
            .finally(() => Alert.setIsLoaded(false));
        } else {
          for (let j = 0; j < newDetails[i].moduleData.length; j++) {
            if (newDetails[i].moduleData[j].moduleDataId === modDetailsId)
              newDetails[i].moduleData.splice(j, 1);
          }
        }
      }
    }

    MockTestPageCxt.setModules(newDetails);
  };

  const moduleDetails = [
    {
      key: 1,
      dataIndex: "topic",
      render: (select, record) => (
        <>
          {MockTestPageCxt.modules.map(
            (module) =>
              module.moduleData.length > 0 &&
              module.moduleData.map(
                (modData) =>
                  modData?.moduleDataId === record.moduleDataId && (
                    <>
                      <div className="adminModuleDetails" key={record.lpcId}>
                        <div style={{ display: "flex" }}>
                          <svg
                            width="6"
                            height="12"
                            fill="none"
                            viewBox="0 0 6 12"
                            xmlns="http://www.w3.org/2000/svg"
                            className="adminModuleDetailMoveIcon"
                          >
                            <path
                              fill="#26344A"
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M0.857 10.286C1.331 10.286 1.714 10.669 1.714 11.143C1.714 11.616 1.331 12 0.857 12C0.384 12 0 11.616 0 11.143C0 10.669 0.384 10.286 0.857 10.286ZM5.143 10.286C5.616 10.286 6 10.669 6 11.143C6 11.616 5.616 12 5.143 12C4.669 12 4.286 11.616 4.286 11.143C4.286 10.669 4.669 10.286 5.143 10.286ZM0.857 6.857C1.331 6.857 1.714 7.241 1.714 7.714C1.714 8.188 1.331 8.571 0.857 8.571C0.384 8.571 0 8.188 0 7.714C0 7.241 0.384 6.857 0.857 6.857ZM5.143 6.857C5.616 6.857 6 7.241 6 7.714C6 8.188 5.616 8.571 5.143 8.571C4.669 8.571 4.286 8.188 4.286 7.714C4.286 7.241 4.669 6.857 5.143 6.857ZM0.857 3.43C1.331 3.43 1.714 3.813 1.714 4.287C1.714 4.76 1.331 5.144 0.857 5.144C0.384 5.144 0 4.76 0 4.287C0 3.813 0.384 3.43 0.857 3.43ZM5.143 3.43C5.616 3.43 6 3.813 6 4.287C6 4.76 5.616 5.144 5.143 5.144C4.669 5.144 4.286 4.76 4.286 4.287C4.286 3.813 4.669 3.43 5.143 3.43ZM0.857 0C1.331 0 1.714 0.384 1.714 0.857C1.714 1.331 1.331 1.714 0.857 1.714C0.384 1.714 0 1.331 0 0.857C0 0.384 0.384 0 0.857 0ZM5.143 0C5.616 0 6 0.384 6 0.857C6 1.331 5.616 1.714 5.143 1.714C4.669 1.714 4.286 1.331 4.286 0.857C4.286 0.384 4.669 0 5.143 0Z"
                            />
                          </svg>
                          {modData.libTypeId ===
                          constants.libTypeIds.CONTENT ? (
                            <TestSvgIcon
                              active
                              libType={constants.libTypeIds.CONTENT}
                            />
                          ) : modData.libTypeId ===
                            constants.libTypeIds.ASSIGNMENT ? (
                            <TestSvgIcon
                              active
                              libType={constants.libTypeIds.ASSIGNMENT}
                            />
                          ) : modData.libTypeId === constants.libTypeIds.MCQ ? (
                            <TestSvgIcon
                              active
                              libType={constants.libTypeIds.MCQ}
                            />
                          ) : modData.libTypeId ===
                            constants.libTypeIds.DESCRIPTIVE ? (
                            <TestSvgIcon
                              active
                              libType={constants.libTypeIds.DESCRIPTIVE}
                            />
                          ) : (
                            <TestSvgIcon
                              active
                              libType={constants.libTypeIds.CODING_CHALLENGE}
                            />
                          )}
                          <span className="adminModuleDetailName">
                            {modData.title}
                          </span>
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <IconButton
                            className="admin-Module-Details-EditIcon-btn"
                            onClick={() =>
                              handleGetTestConfigById(
                                module.id,
                                modData.isEdited,
                                modData
                              )
                            }
                          >
                            <EditIconSvg />
                          </IconButton>
                          {record.published === 0 && (
                            <IconButton
                              className="admin-Module-Details-deleteIcon-btn"
                              onClick={() =>
                                handleDeleteModuleDetails(
                                  module.id,
                                  record.moduleDataId,
                                  record
                                )
                              }
                            >
                              <DeleteIconSvg />
                            </IconButton>
                          )}
                        </div>
                      </div>
                    </>
                  )
              )
          )}
        </>
      ),
    },
  ];
  
  return {
    quereport,
    moduleDetails,
    cursorPointer,
    setCursorPointer,
  };
}
