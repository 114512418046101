import { TextField } from "@mui/material";
import React, { useState } from "react";

const CertificateForm = ({ detail, setDetail }) => {
  const [focusStates, setFocusStates] = useState(() =>
    Object.keys(detail).reduce((acc, key) => ({ ...acc, [key]: false }), {})
  );

  // const handleOnChange = (event) => {
  //   if (
  //     event.target.name === "year" &&
  //     event.target.value !== "" &&
  //     !/^[0-4]$/.test(event.target.value)
  //   ) {
  //     return;
  //   }
  //   setDetail({
  //     ...detail,
  //     [event.target.name]: event.target.value,
  //   });
  // };


  const handleOnChange = (event) => {
    if (event.target.name === "year") {
      const value = event.target.value;
      // Allow only numbers and limit to 4 digits
      if (!/^\d{0,4}$/.test(value)) {
        return;
      }
    }
    
    setDetail({
      ...detail,
      [event.target.name]: event.target.value,
    });
  };

  const handleFieldFocus = (name) => {
    setFocusStates({ ...focusStates, [name]: true });
  };

  const handleFieldBlur = (name) => {
    setFocusStates({ ...focusStates, [name]: false });
  };

  return (
    <form className="certificateFormContainer">
      <TextField
        label="Certificate Name"
        id="certificateFormName"
        className="certificateFormField"
        name="title"
        InputLabelProps={{
          shrink: focusStates["title"] || Boolean(detail["title"]),
        }}
        InputProps={{
          onFocus: (event) => handleFieldFocus(event.target.name),
          onBlur: (event) => handleFieldBlur(event.target.name),
        }}
        placeholder="Certificate Name"
        variant="outlined"
        size="small"
        value={detail.title}
        onChange={handleOnChange}
        fullWidth
      />
      <TextField
        label="Organisation Name"
        id="certificateFormOrganisation"
        className="certificateFormField certificateFormOrganisationField"
        name="organisation"
        InputLabelProps={{
          shrink:
            focusStates["organisation"] || Boolean(detail["organisation"]),
        }}
        InputProps={{
          onFocus: (event) => handleFieldFocus(event.target.name),
          onBlur: (event) => handleFieldBlur(event.target.name),
        }}
        placeholder="Organisation Name"
        variant="outlined"
        size="small"
        value={detail.organisation}
        onChange={handleOnChange}
        fullWidth
      />
      <TextField
        label="Year of Completion"
        id="certificateFormYear"
        className="certificateFormField certificateFormYearField"
        name="year"
        InputLabelProps={{
          shrink: focusStates["year"] || Boolean(detail["year"]),
        }}
        inputProps={{
          onFocus: (event) => handleFieldFocus(event.target.name),
          onBlur: (event) => handleFieldBlur(event.target.name),
          type: "number",
          pattern: "[0-9]*",
        }}
        placeholder="Year of Completion"
        variant="outlined"
        size="small"
        value={detail.year}
        onChange={handleOnChange}
        fullWidth
      />
    </form>
  );
};

export default CertificateForm;
