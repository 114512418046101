import React from "react";
import constants from "../../../../constants";

export default function WarningMsg(Props) {
  return (
    <div className="warningMsg">
      {Props.due === "Due Today" ? (
        <div className="dueTodayBody">{Props.due}</div>
      ) : (
        <div className="reTakeWarningMsg">
          <span className="reTakeWarningMsgHeader">
            {Props.status === constants.PROGRESS_STATUS.REATTEMPT
              ? "Retake Test:"
              : Props.status === constants.PROGRESS_STATUS.QN_CONTINUE
              ? "Resume Test:"
              : "Report to Trainer:"}
          </span>
          <span className="reTakeWarningMsgBody">
            {Props.status === constants.PROGRESS_STATUS.REATTEMPT
              ? "The submitted assignment had a problem, you will need to retake it."
              : Props.status === constants.PROGRESS_STATUS.QN_CONTINUE
              ? "The test had a problem, you have been asked to resume the test from where you left off."
              : "The test had a problem and you will need to inform your trainer to re-enable it."}
          </span>
        </div>
      )}
    </div>
  );
}
