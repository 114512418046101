import React from "react";

export default function LeftNavSvgIcon(Props) {
  return (
    <div
      className={
        !Props.collapsed ? "leftNavOpenSvgIcon" : "leftNavCloseSvgIcon"
      }
    >
      {!Props.collapsed ? (
        <svg
          width="108"
          fill="none"
          height="108"
          viewBox="0 0 108 108"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g filter="url(#filter0_d_1_518)" onClick={Props.onClick}>
            <rect
              x="36"
              y="12"
              rx="8"
              width="36"
              height="36"
              fill="url(#paint0_linear_1_518)"
            />
            <path
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M60 35L55 30L60 25M53 35L48 30L53 25"
            />
          </g>
          <defs>
            <filter
              x="0"
              y="0"
              width="108"
              height="108"
              id="filter0_d_1_518"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                type="matrix"
                in="SourceAlpha"
                result="hardAlpha"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              />
              <feMorphology
                radius="12"
                operator="erode"
                in="SourceAlpha"
                result="effect1_dropShadow_1_518"
              />
              <feOffset dy="24" />
              <feGaussianBlur stdDeviation="24" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.0625882 0 0 0 0 0.0926346 0 0 0 0 0.15702 0 0 0 0.18 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_1_518"
              />
              <feBlend
                mode="normal"
                result="shape"
                in="SourceGraphic"
                in2="effect1_dropShadow_1_518"
              />
            </filter>
            <linearGradient
              y1="12"
              x1="38.25"
              x2="70.65"
              y2="49.35"
              id="paint0_linear_1_518"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#32335F" stopOpacity="0.96" />
              <stop offset="1"  stopColor="#0B0C26" />
            </linearGradient>
          </defs>
        </svg>
      ) : (
        <svg
          width="72"
          fill="none"
          height="108"
          viewBox="0 0 72 108"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g filter="url(#filter0_d_27_30025)" onClick={Props.onClick}>
            <path
              fill="url(#paint0_linear_27_30025)"
              d="M0 12H28C32.4183 12 36 15.5817 36 20V40C36 44.4183 32.4183 48 28 48H0V12Z"
            />
            <path
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M19 35L24 30L19 25M12 35L17 30L12 25"
            />
          </g>
          <defs>
            <filter
              y="0"
              x="-36"
              width="108"
              height="108"
              id="filter0_d_27_30025"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                type="matrix"
                in="SourceAlpha"
                result="hardAlpha"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              />
              <feMorphology
                radius="12"
                operator="erode"
                in="SourceAlpha"
                result="effect1_dropShadow_27_30025"
              />
              <feOffset dy="24" />
              <feGaussianBlur stdDeviation="24" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.0625882 0 0 0 0 0.0926346 0 0 0 0 0.15702 0 0 0 0.18 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_27_30025"
              />
              <feBlend
                mode="normal"
                result="shape"
                in="SourceGraphic"
                in2="effect1_dropShadow_27_30025"
              />
            </filter>
            <linearGradient
              y1="12"
              x1="2.25"
              x2="34.65"
              y2="49.35"
              id="paint0_linear_27_30025"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#32335F" stopOpacity="0.96" />
              <stop offset="1" stopColor="#0B0C26" />
            </linearGradient>
          </defs>
        </svg>
      )}
    </div>
  );
}
