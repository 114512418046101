import CloseSvgIcon from "../../Svg/CloseSvgIcon";
import React, { useContext, useEffect } from "react";
import AlertContext from "../../context/AlertContext";
import ScoreHooks from "../../Hooks/Score/ScoreHooks";
import DiamondPointSvg from "../../Svg/DiamondPointSvg";
import "react-vertical-timeline-component/style.min.css";
import ScoreLogHooks from "../../Hooks/Score/ScoreLogHooks";

import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";

export default function ScoreLog() {
  const { showScore } = useContext(AlertContext);

  const { toggleDrawerScore } = ScoreHooks();

  const { getScoreLog, scoreLogData, getScoreLogData, getScoreLogScore } =
    ScoreLogHooks();

  useEffect(() => {
    if (showScore) {
      getScoreLog();
    }
  }, [showScore]);

  return (
    <div className="scoreLogMainContainer">
      <div className="scoreLogHeader">
        <div className="scoreLogHeaderTitle">Modifications of score :</div>
        <CloseSvgIcon
          component="score"
          onClick={toggleDrawerScore("right", false)}
        />
      </div>
      <div className="scoreLogBody">
        <div className="scoreLogBodyTitle">Updated Score :</div>
        <div className="scoreLogBodyScore">{getScoreLogScore()}</div>
      </div>
      <VerticalTimeline layout="1-column-left">
        {scoreLogData.length > 0 &&
          scoreLogData.map((details) => (
            <VerticalTimelineElement
              className="verticalTimeLineElement"
              dateClassName="verticalTimeLineDateStyle"
              date={
                <p className="verticalTimeLineElementDateAndTime">
                  {getScoreLogData(details)}
                </p>
              }
              icon={<DiamondPointSvg />}
            >
              <>
                <p className="verticalTimeLineElementScoreLog">
                  {details.eventtext}
                </p>
              </>
            </VerticalTimelineElement>
          ))}
      </VerticalTimeline>
    </div>
  );
}
