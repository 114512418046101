import React from "react";
import Box from "@mui/material/Box";
import Typography from "antd/lib/typography/Typography";

export default function CustomTabPanel(Props) {
  const { children, value, index, ...other } = Props;

  return (
    <div
      {...other}
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
