import React from "react";
import { Dialog } from "@mui/material";
import Button from "../../../Button";

const WarningPopup = ({ open, title, body, icon, setOpenPopup }) => {
  return (
    <Dialog open={open} id="waringPopupContainer" scroll="paper">
      {icon}
      <div className="waringPopupContentSec">
        <div className="waringPopupContent">
          <div className="waringPopupContentTitle">{title}</div>
          <div className="waringPopupContentBody">{body}</div>
        </div>
        <div className="waringPopupContentBtnSec">
          <Button
            size="lg"
            hierarchy={{ type: "primary", buttonText: "Ok" }}
            onClick={setOpenPopup}
          />
        </div>
      </div>
    </Dialog>
  );
};

export default WarningPopup;
