import SplitPane from "react-split-pane";
import CodingEditor from "./CodingEditor";
import CodingOutput from "./CodingOutput";
import React, { useContext } from "react";
import CodingProblem from "./CodingProblem";
import Pane from "react-split-pane/lib/Pane";
import Scrollbars from "react-custom-scrollbars-2";
import AlertContext from "../../../context/AlertContext";
import CodingSplitHooks from "../../../Hooks/CodingPage/CodingSplitHooks";

export default function CodingSplit() {
  const { theme, isFullScreen } = useContext(AlertContext);

  const {
    heightFind,
    verticalSize,
    handleProblem,
    outputWindowHeight,
    toggleOutputWindowHeight,
  } = CodingSplitHooks();

  return (
    <SplitPane
      minSize={50}
      split="vertical"
      primary="second"
      size={verticalSize}
      onChange={handleProblem}
      className="codingpageVerticalSplitPane"
    >
      {!isFullScreen && (
        <Pane
          minSize="304px"
          maxSize="504px"
          initialSize={verticalSize}
          className="codingProblemPane"
        >
          <Scrollbars autoHide>
            <CodingProblem />
          </Scrollbars>
        </Pane>
      )}
      <SplitPane
        split="horizontal"
        onChange={heightFind}
        className="codingpageHorizontalSplitPane"
      >
        <Pane
          className={!theme ? "codingEditorPane" : "codingEditorPaneDarkTheme"}
        >
          <Scrollbars autoHide>
            <CodingEditor />
          </Scrollbars>
        </Pane>
        {!isFullScreen && (
          <Pane className="codingOutputPane" initialSize={outputWindowHeight}>
            <Scrollbars autoHide>
              <CodingOutput
                toggleOutputWindowHeight={toggleOutputWindowHeight}
              />
            </Scrollbars>
          </Pane>
        )}
      </SplitPane>
    </SplitPane>
  );
}
