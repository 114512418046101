import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";

const ButtonGroup = ({ buttons, onClick, iconOnly, value }) => {
  const [selectedValue, setSelectedValue] = useState(value);

  useEffect(() => {
    setSelectedValue(value);
  }, [value]);

  const handleClick = (event, value) => {
    event.preventDefault();
    setSelectedValue(parseInt(value));
    onClick(parseInt(value));
  };

  return (
    <div className="buttonGroupContainer">
      {_.map(buttons, (button, index) => (
        <button
          className={`buttonGroupBtn${
            index < buttons.length - 1 ? " styledBtn" : ""
          }${selectedValue !== button.value ? " unPressed" : ""}`}
          key={index}
          name={button.name}
          value={button.value}
          disabled={button.disabled}
          onClick={(event) => handleClick(event, button.value)}
        >
          {button.icon && (
            <span dangerouslySetInnerHTML={{ __html: button.icon }} />
          )}
          {button.label && !iconOnly ? button.label : null}
        </button>
      ))}
    </div>
  );
};

export default ButtonGroup;

ButtonGroup.propTypes = {
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      name: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      icon: PropTypes.element || PropTypes.string,
      disabled: PropTypes.bool,
    })
  ),
  onClick: PropTypes.func,
  iconOnly: PropTypes.bool,
};

ButtonGroup.defaultProps = {
  buttons: {
    label: "Button",
    name: "",
    value: "",
    icon: null,
    disabled: false,
  },
  iconOnly: false,
};
