import React from 'react'

export default function StarSvg(props) {
  return (
    <svg width={props.width ? props.width :"16"} height={props.height ? props.height :"15"} viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_dd_1738_4133)">
        <path d="M7.53834 0.509868C7.70914 0.0992216 8.29086 0.0992202 8.46166 0.509866L9.99874 4.20546C10.0707 4.37858 10.2336 4.49686 10.4204 4.51185L14.4102 4.8317C14.8535 4.86724 15.0332 5.42049 14.6955 5.70983L11.6557 8.31368C11.5133 8.43566 11.4512 8.62705 11.4947 8.80943L12.4234 12.7027C12.5265 13.1353 12.0559 13.4772 11.6764 13.2454L8.26063 11.1591C8.10062 11.0614 7.89938 11.0614 7.73937 11.1591L4.32363 13.2454C3.94408 13.4772 3.47345 13.1353 3.57665 12.7027L4.50534 8.80943C4.54884 8.62705 4.48665 8.43566 4.34426 8.31368L1.30453 5.70983C0.966758 5.4205 1.14652 4.86724 1.58985 4.8317L5.57955 4.51185C5.76645 4.49686 5.92925 4.37858 6.00126 4.20546L7.53834 0.509868Z" fill="#FDDB61"/>
        </g>
        <defs>
        <filter id="filter0_dd_1738_4133" x="-10.8708" y="0.20166" width="37.7417" height="37.1187" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feMorphology radius="2" operator="erode" in="SourceAlpha" result="effect1_dropShadow_1738_4133"/>
        <feOffset dy="4"/>
        <feGaussianBlur stdDeviation="3"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.03 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1738_4133"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feMorphology radius="4" operator="erode" in="SourceAlpha" result="effect2_dropShadow_1738_4133"/>
        <feOffset dy="12"/>
        <feGaussianBlur stdDeviation="8"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.08 0"/>
        <feBlend mode="normal" in2="effect1_dropShadow_1738_4133" result="effect2_dropShadow_1738_4133"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_1738_4133" result="shape"/>
        </filter>
        </defs>
    </svg>
  )
}
