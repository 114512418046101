import React from 'react'

export default function Gmail() {
  return (
      <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_496_30530)">
      <path d="M2.5 8.96667C2.5 6.35309 2.5 5.0463 3.00864 4.04804C3.45605 3.16995 4.16995 2.45605 5.04804 2.00864C6.0463 1.5 7.35309 1.5 9.96667 1.5H16.0333C18.6469 1.5 19.9537 1.5 20.952 2.00864C21.83 2.45605 22.544 3.16995 22.9914 4.04804C23.5 5.0463 23.5 6.35309 23.5 8.96667V15.0333C23.5 17.6469 23.5 18.9537 22.9914 19.952C22.544 20.83 21.83 21.544 20.952 21.9914C19.9537 22.5 18.6469 22.5 16.0333 22.5H9.96667C7.35309 22.5 6.0463 22.5 5.04804 21.9914C4.16995 21.544 3.45605 20.83 3.00864 19.952C2.5 18.9537 2.5 17.6469 2.5 15.0333V8.96667Z" fill="white"/>
      <path d="M17.5385 6.39258L13.0481 9.89689L8.45514 6.39258V6.39353L8.46069 6.39826V11.3052L12.9963 14.8853L17.5385 11.4435V6.39258Z" fill="#EA4335"/>
      <path d="M18.7176 5.53979L17.5384 6.39219V11.4431L21.249 8.59423V6.87806C21.249 6.87806 20.7986 4.42693 18.7176 5.53979Z" fill="#FBBC05"/>
      <path d="M17.5384 11.4427V17.9938H20.3824C20.3824 17.9938 21.1917 17.9105 21.25 16.988V8.59375L17.5384 11.4427Z" fill="#34A853"/>
      <path d="M8.4609 18.0007V11.3055L8.45514 11.3008L8.4609 18.0007Z" fill="#C5221F"/>
      <path d="M8.4551 6.3934L7.28234 5.54573C5.20135 4.43288 4.75 6.88306 4.75 6.88306V8.59922L8.4551 11.3004V6.3934Z" fill="#C5221F"/>
      <path d="M8.45514 6.39258V11.2996L8.4609 11.3043V6.39731L8.45514 6.39258Z" fill="#C5221F"/>
      <path d="M4.75 8.59961V16.9939C4.80734 17.9173 5.61754 17.9997 5.61754 17.9997H8.46158L8.4551 11.2998L4.75 8.59961Z" fill="#4285F4"/>
      </g>
      <defs>
      <filter id="filter0_d_496_30530" x="-1" y="-1" width="28" height="28" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset dy="1"/>
      <feGaussianBlur stdDeviation="1"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.05 0"/>
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_496_30530"/>
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_496_30530" result="shape"/>
      </filter>
      </defs>
      </svg>
  )
}
