import React, { useContext } from 'react';
import AlertContext from '../../context/AlertContext';

export default function CommonBtn(props) {

  const {isCommonPopup,setIsCommonPopup,
    isWobtnPopup,setIsWoBtnPopup,
    isCommonPopupCloseIcon,setIsCommonPopupCloseIcon,
    commonPopupTitle,setCommonPopupTitle,
    commonPopupTitleDesc,setCommonPopupTitleDesc,
    iscommonPopupBodyComponent,setIsCommonPopupBodyComponent,
    commonPopupBodyComponent,setCommonPopupBodyComponent,
    commonPopupBodyDesc,setCommonPopupBodyDesc,
    iscommonPopupSingleBtn,setIsCommonPopupSingleBtn,
    commonPopupBtnSize,setCommonPopupBtnSize,
    commonPopupSingleBtnTxt,setCommonPopupSingleBtnTxt,
    commonPopupSingleBtnFunc,setCommonPopupSingleBtnFunc,
    commonPopupPrimaryBtnTxt,setCommonPopupPrimaryBtnTxt,
    commonPopupSecBtnTxt,setCommonPopupSecBtnTxt,
    commonPopupPrimaryBtnFunc,setCommonPopupPrimaryBtnFunc,
    commonPopupSecBtnFunc,setCommonPopupSecBtnFunc } = useContext(AlertContext);

  let btnClassDiv = 'common-btn-div';
  let btnClass = 'common-btn';

  
  switch (props.btnsize!=undefined?props.btnsize:commonPopupBtnSize) {
    case 'small' :
      btnClass += ' small';
      btnClassDiv += ' small';
      break;
    case 'medium' :
      btnClass += ' medium';
      btnClassDiv += ' medium';
      break;
    case 'large' :
      btnClass += ' large';
      btnClassDiv += ' large';
      break;
  }


  return (
    <>
    {(props.singlebtn || iscommonPopupSingleBtn) ? (
      <div className={btnClassDiv}>
        {(props.singlebtnsec!=undefined && props.singlebtnsec)?
        (<button className={btnClass+" sec"} onClick={props.singlebtnfun!=undefined? props.singlebtnfun :commonPopupSingleBtnFunc}>{props.singlebtntxt!=undefined?props.singlebtntxt:commonPopupSingleBtnTxt}</button>)
        :
        (<button className={btnClass+" prim"} onClick={props.singlebtnfun!=undefined? props.singlebtnfun :commonPopupSingleBtnFunc}>{props.singlebtntxt!=undefined?props.singlebtntxt:commonPopupSingleBtnTxt}</button>)
      }
        
    </div>
    ) : (
      <div className={btnClassDiv}>
        <button className={btnClass+" sec"} onClick={props.secbtnfunc!=undefined?props.secbtnfunc:commonPopupSecBtnFunc}>{props.secbtntxt!=undefined?props.secbtntxt:commonPopupSecBtnTxt}</button>
        <button className={btnClass+" prim"} onClick={props.primbtnfunc!=undefined?props.primbtnfunc:commonPopupPrimaryBtnFunc}>{props.primbtntxt!=undefined?props.primbtntxt:commonPopupPrimaryBtnTxt}</button>
    </div>
    )}
    </>
  )
}