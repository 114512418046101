import { nanoid } from "nanoid";
import Form from "react-bootstrap/Form";
import React, { useContext } from "react";
import constants from "../../../../constants";
import useAxiosPrivate from "../../../useAxiosPrivate";
import AlertContext from "../../../../context/AlertContext";
import useCommonFunctionHooks from "../../../useCommonFunctionHooks";
import { MockTestPageContext } from "../../../../context/MockTestPageContext";

export default function MockLearningPathListHooks() {
  const {
    lists,
    lpData2,
    setLists,
    navigate,
    setLpData2,
    setIsAddLp,
    setIsLoaded,
    setShowNotify,
    setIsOldLpDetail,
    setModuleExpandKey,
    handleNavigateToLpAfterAddTest,
  } = useContext(AlertContext);

  const { searchText, setSearchText } = useContext(MockTestPageContext);

  const axios = useAxiosPrivate();

  const { getCatchBlockDetails, getHeaderDetailsForAxios } =
    useCommonFunctionHooks();

  let topicData;

  const columns = [
    {
      width: "25%",
      name: "Name",
      sortable: true,
      selector: (row) => row.name,
    },
    {
      width: "25%",
      sortable: true,
      name: "Created By",
      selector: (row) => row.createdby,
    },
    {
      width: "12%",
      sortable: true,
      name: "Created Date",
      selector: (row) => row.createddate,
    },
    {
      width: "10%",
      sortable: true,
      name: "Modified By",
      selector: (row) => row.modifiedby,
    },
    {
      width: "12%",
      sortable: true,
      name: "Modified Date",
      selector: (row) => row.modifieddate,
    },
    {
      width: "10%",
      sortable: true,
      name: "Active",
      selector: (row) => (
        <Form.Check
          reverse
          type="switch"
          checked={row.mockuse === 2}
          onChange={() => handleActiveMockLearningPath(row)}
        />
      ),
    },
  ];

  const customStyles = {
    headCells: {
      style: {
        fontSize: "12px",
        color: "#9AA0A6",
        marginTop: "10px",
        fontWeight: "500",
        lineHeight: "15px",
        position: "sticky",
        fontFamily: "Inter",
        fontStyle: "normal",
        background: "#FFFFFF",
        textTransform: "uppercase",
      },
    },
    cells: {
      style: {
        color: "#202124",
        fontSize: "14px",
        fontWeight: "400",
        lineHeight: "20px",
        fontStyle: "normal",
        fontFamily: "Inter",
        background: "#FFFFFF",
      },
    },
  };

  const handleSearchTitle = (e) => {
    setSearchText(e.target.value);
  };

  const handleSelectTopicAddToLp = (state) => {
    topicData = state.selectedRows.reverse();
  };

  const handleBackBtn = () => {
    let newDetails = JSON.parse(JSON.stringify(lpData2));

    newDetails.isAddTest = false;
    handleNavigateToLpAfterAddTest(newDetails);
  };

  const filteredData = lists.filter((item) => {
    const includesText = item.name
      .toLowerCase()
      .includes(searchText.toLowerCase());

    return includesText;
  });

  const handleCreateTestBtn = () => {
    setIsAddLp(true);
    navigate("mock/create/learningpath");
  };

  const handleActiveMockLearningPath = async (row) => {
    try {
      let response = await axios.put(
        `node/admin/lp/mock/status`,
        JSON.stringify({ lpid: row.id, active: row.mockuse === 1 }),
        getHeaderDetailsForAxios()
      );

      if (response.data.resultCode !== constants.RESULT_STATUS.SUCCESS) {
        setShowNotify({
          show: true,
          title: "Warning",
          msg: "Something went wrong. Please try again later.",
        });
      } else {
        getMockLearningPathList();
      }
    } catch (error) {
      getCatchBlockDetails(error);
    } finally {
      setIsLoaded(false);
    }
  };

  const getMockLearningPathList = () => {
    setIsLoaded(true);

    (async () => {
      try {
        let response = await axios.get(
          "node/admin/learningpath/get?mockuse=1",
          getHeaderDetailsForAxios()
        );

        if (response.data.resultCode === constants.RESULT_STATUS.SUCCESS) {
          if (response.data.data !== null && response.data.data.length !== 0) {
            setLists(response.data.data);
          } else {
            setShowNotify({
              show: true,
              title: "Warning",
              msg: "There is no learningpath",
            });
          }
        } else {
          setShowNotify({
            show: true,
            title: "Error",
            msg: "Something went wrong. Please try again later.",
          });
        }
      } catch (err) {
        getCatchBlockDetails(err);
      } finally {
        setIsLoaded(false);
      }
    })();
  };

  const handleGetLearningPathDetails = (data) => {
    setIsAddLp(true);
    setIsLoaded(true);
    axios
      .get("node/admin/learningpath/" + data.id, getHeaderDetailsForAxios())
      .then((res) => {
        if (res.data.resultCode === constants.RESULT_STATUS.SUCCESS) {
          if (res.data.data === null) {
            setLpData2({
              lpDes: "",
              lpIcon: "",
              archived: 0,
              moduleId: "",
              iconData: "",
              id: nanoid(),
              libTypeId: 2,
              lpId: data.id,
              lpContent: "",
              lpShortDes: "",
              isAddTest: false,
              lpName: data.name,
              modules: [
                {
                  id: 1,
                  key: nanoid(),
                  status: "new",
                  moduleName: "",
                  moduleData: [],
                },
              ],
            });

            setIsOldLpDetail(true);
          } else {
            let newDetails = res.data.data[0],
              uniqueId = 1;

            newDetails.modules.sort(
              (a, b) => a.moduleorderno - b.moduleorderno
            );

            for (let i = 0; i < newDetails.modules.length; i++) {
              newDetails.modules[i].id = uniqueId;
              newDetails.modules[i].key = nanoid();
              newDetails.modules[i].isAddTest = false;
              newDetails.modules[i].status = "old";
              uniqueId++;

              for (
                let j = 0;
                j < newDetails.modules[i].moduleData.length;
                j++
              ) {
                newDetails.modules[i].moduleData[j].moduleDataId = uniqueId;
                newDetails.modules[i].moduleData[j].key = nanoid();
                newDetails.modules[i].moduleData[j].status = "old";
                newDetails.modules[i].moduleData[j].testStatus = "old";
                newDetails.modules[i].moduleData[j].title =
                  newDetails.modules[i].moduleData[j].testName;
                delete newDetails.modules[i].moduleData[j].testName;
                uniqueId++;
              }
            }

            setLpData2(newDetails);
            setModuleExpandKey([newDetails.modules[0].key]);
            setIsOldLpDetail(true);
          }

          navigate("mock/create/learningpath");
        } else {
          setShowNotify({
            show: true,
            title: "Error",
            msg: "Something went wrong. Please try again later",
          });
        }
      })
      .catch((err) => {
        getCatchBlockDetails(err);
      })
      .finally(() => setIsLoaded(false));
  };

  return {
    columns,
    topicData,
    filteredData,
    customStyles,
    handleBackBtn,
    handleSearchTitle,
    handleCreateTestBtn,
    getMockLearningPathList,
    handleSelectTopicAddToLp,
    handleGetLearningPathDetails,
  };
}
