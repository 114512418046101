import React, { useContext } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { UserReportContext } from "../../../context/UserReportContext";

export default function MonthPicker() {
  const UserReportContxt = useContext(UserReportContext);

  const handleDateChange = (date) => {
    UserReportContxt.setSelectedMonth(
      date /* .toLocaleString("default", { month: "long" }) */
    );
  };

  const renderMonthContent = (month, shortMonth, longMonth, day) => {
    const fullYear = new Date(day).getFullYear();
    const tooltipText = `${longMonth} ${fullYear}`;

    return <span title={tooltipText}>{shortMonth}</span>;
  };

  return (
    <div className="monthPickerMainContainer">
      <DatePicker
        dateFormat="MMMM"
        showMonthYearPicker
        onChange={handleDateChange}
        className="monthPickerInputBox"
        renderMonthContent={renderMonthContent}
        selected={UserReportContxt.selectedMonth}
      />
    </div>
  );
}
