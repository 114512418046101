import { Outlet } from "react-router-dom";
import { useState, useEffect } from "react";
import useRefreshToken from "../Hooks/useRefreshToken";
import useAuth from "../Hooks/useAuth";
import Loading from "./Notification-Loading/Loading/Loading";

const PresistLogin = () => {
    const [isLoading, setIsLoading] = useState(true);
    const refresh = useRefreshToken();
    const { auth } = useAuth();
    
    useEffect(() => {
        let isMounted = true;
        const verifyRefreshToken = async () => {
            try {
               await refresh();
            } catch (err) {
                console.log("presistLogin err", err);
                

            } finally {
                isMounted && setIsLoading(false);
            }
        }

        !localStorage.getItem('tokenKey') ? verifyRefreshToken() : setIsLoading(false);
        return () => isMounted = false;
    }, [])


    return (
        <>
            {isLoading ? <Loading /> : <Outlet />}
        </>
    )
}

export default PresistLogin;