import React from 'react'

export default function CircleChecked() {
  return (
    <div>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="24" height="24" rx="12" fill="#F55533"/>
            <path d="M18 7.5L9.75 15.75L6 12" stroke="#FCFCFD" stroke-width="1.6666" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    </div>
  )
}
