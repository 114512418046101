import { InputBase, Typography } from "@mui/material";

const InputGroupField = ({
  title,
  groupText,
  placeholder,
  name,
  value,
  onChange,
  titleIcon,
  disabled
}) => {
  return (
    <div className="inputGroupFieldSec">
      {(title || titleIcon) && (
        <div className="inputGroupFieldTitleSec">
          <div dangerouslySetInnerHTML={{ __html: titleIcon }}/>
          {title && <Typography id="inputGroupFieldTitle">{title}</Typography>}
        </div>
      )}
      <div className="inputGroupFieldFieldSec">
      {groupText &&<div className="inputGroupFieldCode">{groupText}</div>}
        <InputBase
          id="inputGroupField"
          placeholder={placeholder}
          name={name}
          fullWidth
          disabled={disabled}
          value={value || ""}
          onChange={onChange}
        />
      </div>
    </div>
  );
};

export default InputGroupField;
