import InfoSvg from "../../Svg/InfoSvg";
import constants from "../../constants";
import React, { useContext } from "react";
import IconButton from "@mui/material/IconButton";
import ScoreHooks from "../../Hooks/Score/ScoreHooks";
import AlertContext from "../../context/AlertContext";

export default function Score() {
  const { testData } = useContext(AlertContext);
  const { toggleDrawerScore, getScore } = ScoreHooks();

  return (
    <div className="scoreMainContainer">
      <div className="scoreLabel">Score:</div>
      <div className="scoreValue">{getScore()}</div>
      <IconButton
        className="scoreInfoSvgIcon"
        onClick={toggleDrawerScore("right", true)}
      >
        {testData.testtype === constants.libTypeIds.CODING_CHALLENGE && (
          <InfoSvg />
        )}
      </IconButton>
    </div>
  );
}
