import React from "react";

export default function RejectSvg(Props) {
  return (
    <svg
      width="40"
      height="40"
      fill="none"
      viewBox="0 0 40 40"
      onClick={Props.onClick}
      className="rejectSvgIcon"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_1929_16933)">
        <rect x="2" y="1" width="36" height="36" rx="8" fill="#D92D20" />
        <rect
          x="2.5"
          y="1.5"
          rx="7.5"
          width="35"
          height="35"
          stroke="#D92D20"
        />
        <path
          stroke="white"
          stroke-width="1.67"
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M25 14L15 24M15 14L25 24"
        />
      </g>
      <defs>
        <filter
          x="0"
          y="0"
          width="40"
          height="40"
          id="filter0_d_1929_16933"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            type="matrix"
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.05 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1929_16933"
          />
          <feBlend
            mode="normal"
            result="shape"
            in="SourceGraphic"
            in2="effect1_dropShadow_1929_16933"
          />
        </filter>
      </defs>
    </svg>
  );
}
