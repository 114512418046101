import React, { useState } from "react";
import DropdownTreeSelect from "react-dropdown-tree-select";
// import "react-dropdown-tree-select/dist/styles.css";

const DropdownTreeselect = (Props) => {


  // const onChange = (current, selected, fullColumns, typesAndModulesFilData) => {
  //   console.log("selected>>>>1", selected);
  //   console.log("fullColumns>>>>2", fullColumns);
  //   console.log("typesAndModulesFilData>>>>3", typesAndModulesFilData);

  //   //setSelectedItems(selected);
  // };

  return (
    <DropdownTreeSelect
      data={Props.data}
      onChange={(current, selected, fullColumns, typesAndModulesFilData)=>{Props.onChange(current, selected, fullColumns, typesAndModulesFilData)}}
      texts={{ placeholder: "Select Tests" }}
      showPartiallySelected={true}
      
    />
  );
};

export default DropdownTreeselect;

const data = [
  {
    checked: false,
    disabled: false,
    label: "Basic Java",
    value: "1",
    children: [
      {
        checked: false,
        disabled: false,
        label: "Java 6",
        value: "1.1",
      },
      {
        checked: false,
        disabled: false,
        label: "Java - 5",
        value: "1.2",
      },
    ],
  },
  {
    checked: false,
    disabled: false,
    label: "Assignments",
    value: "2",
    children: [
      {
        checked: false,
        disabled: false,
        label: "Java Assignment - 2",
        value: "2.1",
      },
      {
        checked: false,
        disabled: false,
        label: "Java Assignment - 1",
        value: "2.2",
      },
    ],
  },
];
