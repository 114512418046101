import React, {
  useEffect,
  useRef,
  useState,
  useContext,
  useCallback,
} from "react";
import Form from "react-bootstrap/Form";
import DatePicker from "react-datepicker";
import Select from "react-select";
import Checkbox from "@mui/material/Checkbox";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import constants from "../../../constants";
import useAxiosPrivate from "../../../Hooks/useAxiosPrivate";
import moment from "moment";
import DropdownTreeselect from "../../CommonComponents/DropdownTreeSelect";
import ModuleHooks from "../../../Hooks/ModuleHooks";
import AlertContext from "../../../context/AlertContext";
import { makeTestLists } from "../Batch/Settings/SkillMapping";
import {
  findNameById,
  getTests,
  markCheckedNodes,
} from "../Batch/Settings/SkillBody";
import Container from "../Batch/Report/DropdownContainer";
function WidgetConfiguration(Props) {
  const axios = useAxiosPrivate();
  const { handlePopupStates, clearPopupStates } = ModuleHooks();
  const [batchName, setbatchName] = useState("");
  const [batchOptions, setBatchOptions] = useState([]);
  const [dateRange, setDateRange] = useState("");
  const [dateRanges, setDateRanges] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [college, setCollege] = useState("");
  const [orientation, setOrientation] = useState(
    Props.isEdit
      ? Props.dataOfCurrentWidget.orientation
      : constants.ORIENTATION.PORTRAIT
  );
  const [percentageDuration, setPercentageDuration] = useState(
    Props.isEdit
      ? Props.dataOfCurrentWidget.widgetConfig.percentage_duration ==
        constants.PERCENTAGE_DURATION.BOTH
        ? [
            constants.PERCENTAGE_DURATION.BATCH_START_DATE,
            constants.PERCENTAGE_DURATION.GIVEN_PERIOD,
          ]
        : [Props.dataOfCurrentWidget.widgetConfig.percentage_duration]
      : []
  );

  const { setIsUnAuth } = useContext(AlertContext);

  const [listOfColumns, setListOfColumns] = useState(
    Props.isEdit
      ? {
          name: 1,
          email: Props.dataOfCurrentWidget.listOfColumns.email,
          college: Props.dataOfCurrentWidget.listOfColumns.college,
          branch: Props.dataOfCurrentWidget.listOfColumns.branch,
          date: 1,
          attendance_status:
            Props.dataOfCurrentWidget.listOfColumns.attendance_status,
          total_score: Props.dataOfCurrentWidget.listOfColumns.total_score,
          test_score: Props.dataOfCurrentWidget.listOfColumns.test_score,
          // ranking: Props.dataOfCurrentWidget.listOfColumns.ranking,
          // percentile: Props.dataOfCurrentWidget.listOfColumns.percentile,
        }
      : { name: true, date: true }
  );

  const [widgetName, setWidgetName] = useState(
    Props.isEdit ? Props.dataOfCurrentWidget.widgetName : ""
  );
  const [widgetDescription, setWidgetDescription] = useState(
    Props.isEdit ? Props.dataOfCurrentWidget.widgetDescription : ""
  );
  const [collegeList, setCollegeList] = useState([]);

  const [branchList, setBranchList] = useState([]);
  const [branch, setBranch] = useState("");
  const assignList = useRef([]);
  const [reportToShow, setReportToShow] = useState(
    Props.isEdit
      ? Props.dataOfCurrentWidget.widgetConfig.leaderboard_coverage
      : constants.LEADERBOARD_COVERAGE.COMPLETE_BATCH
  );

  const [testList, setTestList] = useState([]);
  const [selectedAssignments, setSelectAssignments] = useState([]);

  const handleCloseResponsePopup = () => {
    clearPopupStates();
  };

  const handleResponse = (title, msg) => {
    let popupdata = {};
    popupdata.showpopup = true;
    popupdata.title = title;
    popupdata.bodydesc = msg;
    popupdata.singlebtn = true;
    popupdata.singlebtntxt = "Ok";
    popupdata.singlebtnsize = "small";
    popupdata.singlebtnfunt = handleCloseResponsePopup;
    handlePopupStates(popupdata);
  };

  const getStartAndEndDates = (period) => {
    let calStartDate;
    let calEndDate;
    if (period == constants.DATE_RANGE.THIS_WEEK) {
      calStartDate = moment().startOf("week").format("YYYY-MM-DD");
      calEndDate = moment().endOf("week").format("YYYY-MM-DD");
    } else if (period == constants.DATE_RANGE.LAST_WEEK) {
      calStartDate = moment()
        .subtract(1, "week")
        .startOf("week")
        .format("YYYY-MM-DD");
      calEndDate = moment()
        .subtract(1, "week")
        .endOf("week")
        .format("YYYY-MM-DD");
    } else if (period == constants.DATE_RANGE.THIS_MONTH) {
      calStartDate = moment().startOf("month").format("YYYY-MM-DD");
      calEndDate = moment().endOf("month").format("YYYY-MM-DD");
    } else if (period == constants.DATE_RANGE.LAST_MONTH) {
      calStartDate = moment()
        .subtract(1, "month")
        .startOf("month")
        .format("YYYY-MM-DD");
      calEndDate = moment()
        .subtract(1, "month")
        .endOf("month")
        .format("YYYY-MM-DD");
    } else if (period == constants.DATE_RANGE.LAST_2_WEEK) {
      calStartDate = moment().subtract(2, "week").format("YYYY-MM-DD");
      calEndDate = moment().format("YYYY-MM-DD");
    } else if (period == constants.DATE_RANGE.LAST_3_MONTHS) {
      calStartDate = moment().subtract(3, "month").format("YYYY-MM-DD");
      calEndDate = moment().format("YYYY-MM-DD");
    } else if (period == constants.DATE_RANGE.CUSTOM) {
      calStartDate = startDate;
      calEndDate = endDate;
    }

    return {
      sd: calStartDate,
      ed: calEndDate,
    };
  };

  const getTestLists = async (tests) => {
    let mappedTests = Props.dataOfCurrentWidget?.widgetConfig?.tests,
      idsToCheck = [],
      payloadDetails = [];
    mappedTests.forEach((item) => {
      if (item.lpid) {
        payloadDetails.push({
          id: item.lpid,
          lpid: item.lpid,
          name: findNameById(assignList.current, item.lpid, true),
          isLp: true,
        });
        let lpcList = getTests(tests, item.lpid, true);
        idsToCheck.push(...lpcList);
      } else if (item.mid) {
        payloadDetails.push({
          id: item.mid,
          mid: item.mid,
          name: findNameById(assignList.current, item.mid, true),
          isModule: true,
        });
        let lpcList = getTests(tests, item.mid, false);
        idsToCheck.push(...lpcList);
      } else if (item.lpcid) {
        payloadDetails.push({
          id: item.lpcid,
          name: findNameById(assignList.current, item.lpcid, true),
          isTest: true,
        });
        idsToCheck.push(item.lpcid);
      }
    });
    console.log("idsToCheck", idsToCheck);
    const updatedTests = markCheckedNodes(tests, idsToCheck);
    console.log("updatedTests", updatedTests);
    assignList.current = updatedTests;
    setTestList(updatedTests);
    setSelectAssignments(payloadDetails);
    // let lpclist =
    //   Props.dataOfCurrentWidget?.widgetConfig?.assignment_ids?.split(",");

    // lpclist?.forEach((item) => {

    //   // let Parentid = findParentItem(tests, item);
    //   // console.log("parentid", Parentid, "item", item);
    //   // changeCheckedStatusinTestlist(Parentid, item);
    // });
  };

  useEffect(() => {
    // node/admin/batch/:id/assignments

    try {
      if (
        // Props.isEdit ||
        (Props.type == constants.BOARD_WIDGETS.ASSIGNMENT_STATUS ||
          Props.type == constants.BOARD_WIDGETS.SCORES) &&
        batchName?.value != undefined
      ) {
        const assignmentResponse = async () => {
          let response = await axios.get(
            `node/admin/batch/${batchName?.value}/assignments`,
            {
              params: getStartAndEndDates(dateRange?.value),
              headers: {
                "Content-type": "application/json",
              },
            }
          );
          console.log("response;;;;assignments", response?.data?.data);
          // let transformedData = response?.data?.data?.map((item) => {
          //   return { label: item?.name, value: item?.lpcid };
          // });

          if (response?.data?.data.length > 0) {
            let data = JSON.parse(
              typeof response.data.data === "string" ? response.data.data : "[]"
            );
            const transformedData = makeTestLists(data);

            setTestList(transformedData);
            assignList.current = transformedData;
            console.log("testlist nested checking......", transformedData);
            if (Props.isEdit) {
              await getTestLists(transformedData);
            }
          }
        };

        assignmentResponse();
      }
    } catch (err) {
      if (err.message.includes("403")) {
        handleResponse(
          "Error",
          "You have been logged-out due to inactivity. Login again."
        );
        setIsUnAuth(true);
      } else {
        handleResponse("Error", err.message);
      }
    }
  }, [batchName, dateRange]);

  useEffect(() => {
    try {
      if (Props.saveTriggered || Props.updateTriggered) {
        const SaveWidget = async () => {
          let widgetConfig = {};

          widgetConfig.batch_id = batchName?.value;
          widgetConfig.date_range = dateRange?.value;
          if (
            Props.type == constants.BOARD_WIDGETS.ATTENDANCE ||
            Props.type == constants.BOARD_WIDGETS.ASSIGNMENT_STATUS ||
            Props.type == constants.BOARD_WIDGETS.SCORES
          ) {
            let sdAnded = getStartAndEndDates(dateRange?.value);
            widgetConfig.start_date = sdAnded.sd;
            widgetConfig.end_date = sdAnded.ed;
          }
          widgetConfig.college = college?.label;
          widgetConfig.branch = branch?.label;
          if (Props.updateTriggered) {
            widgetConfig.college =
              college?.label == undefined ? null : college?.label;
            widgetConfig.branch =
              branch?.label == undefined ? null : branch?.label;
          }
          if (
            Props.type == constants.BOARD_WIDGETS.SCORES ||
            Props.type == constants.BOARD_WIDGETS.ASSIGNMENT_STATUS
          ) {
            // let childrenValues = [];
            // assignList.current.forEach((parent) => {
            //   if (parent.checked) {
            //     parent.children.forEach((child) => {
            //       childrenValues.push(child.value);
            //     });
            //   } else {
            //     parent.children.forEach((child) => {
            //       if (child.checked) {
            //         childrenValues.push(child.value);
            //       }
            //     });
            //   }
            // });

            // console.log("childrenValues????", childrenValues);
            // widgetConfig.assignment_ids = childrenValues
            //   // .map((item) => item.value)
            //   .join(",");
            widgetConfig.tests = selectedAssignments;
          }

          if (
            Props.type === constants.BOARD_WIDGETS.ASSIGNMENT_STATUS ||
            Props.type === constants.BOARD_WIDGETS.ATTENDANCE
            // || Props.type === constants.BOARD_WIDGETS.SCORES
          ) {
            widgetConfig.percentage_duration =
              percentageDuration.length == 2
                ? constants.PERCENTAGE_DURATION.BOTH
                : percentageDuration[0];
          }

          if (
            Props.type === constants.BOARD_WIDGETS.LEADERBOARD ||
            Props.type === constants.BOARD_WIDGETS.PERCENTILE
          ) {
            widgetConfig.leaderboard_coverage = reportToShow;
          }
          let listofColumns = { ...listOfColumns };
          if (percentageDuration.length == 2) {
            listofColumns.percentage_selected_duration = true;
            listofColumns.percentage_total_duration = true;
          }
          if (
            percentageDuration[0] == constants.PERCENTAGE_DURATION.GIVEN_PERIOD
          ) {
            listofColumns.percentage_selected_duration = true;
          }
          if (
            percentageDuration[0] ==
            constants.PERCENTAGE_DURATION.BATCH_START_DATE
          ) {
            listofColumns.percentage_total_duration = true;
          }
          let response;
          console.log("widgetConfig", widgetConfig);
          if (Props.saveTriggered) {
            response = await axios.post(
              "node/admin/dashboard/widget",
              {
                id: Props.dashBoardId,
                type: Props.type,
                widgetName,
                widgetdesc: widgetDescription,
                orderno: Props.selectedWidgets.length + 1,
                widgetConfig,
                listOfColumns: listofColumns,
                orientation,
              },
              {
                headers: {
                  "Content-Type": "application/json",
                },
              }
            );

            if (
              response.data.resultCode == constants.RESULT_STATUS.SUCCESS ||
              response?.data?.hasOwnProperty("data")
            ) {
              let currentWidgetData = {
                widgetname: widgetName,
                wtype: Props.type,
                orderno: Props.selectedWidgets.length + 1,
                wid: response?.data?.data?.id,
              };
              let widgets = [...Props.selectedWidgets, currentWidgetData];
              Props.setSelectedWidgets(widgets);
              Props.setIsEdit(false);
              Props.setShowConfigPopUp(false);
              handleResponse("Success", response.data.msg);
            } else {
              // show popup
              handleResponse("Warning", response.data.msg);
            }
          } else if (
            Props.updateTriggered &&
            Props.dataOfCurrentWidget?.widgetConfig.board_widget_id != undefined
          ) {
            response = await axios.put(
              "node/admin/dashboard/widget/" +
                Props.dataOfCurrentWidget?.widgetConfig.board_widget_id,
              {
                id: Props.dashBoardId,
                type: Props.type,
                widgetName,
                widgetDesc: widgetDescription,
                orderno: Props.selectedWidgets.length + 1,
                widgetConfig,
                listOfColumns: listofColumns,
                orientation,
              },
              {
                headers: {
                  "Content-Type": "application/json",
                },
              }
            );

            if (
              response.data.resultCode == constants.RESULT_STATUS.SUCCESS ||
              response?.data?.hasOwnProperty("data")
            ) {
              let widgets = [...Props.selectedWidgets];
              const foundIndex = widgets.findIndex(
                (i) =>
                  i.wid ==
                  Props.dataOfCurrentWidget?.widgetConfig.board_widget_id
              );
              if (foundIndex !== -1) {
                const updatedArray = [...widgets];
                updatedArray[foundIndex] = {
                  ...updatedArray[foundIndex],
                  widgetname: widgetName,
                };
                Props.setSelectedWidgets(updatedArray);
              }
              Props.setIsEdit(false);
              Props.setShowConfigPopUp(false);
              handleResponse("Success", response.data.msg);
            } else {
              handleResponse("Warning", response.data.msg);
            }
          }
        };

        SaveWidget();
        Props.setSaveTriggered(false);
        Props.setUpdateTriggered(false);
        // Props.setShowConfigPopUp(false);
      }
    } catch (err) {
      if (err.message.includes("403")) {
        handleResponse(
          "Error",
          "You have been logged-out due to inactivity. Login again."
        );
        setIsUnAuth(true);
      } else {
        handleResponse("Error", err.message);
      }
    }
  }, [Props.saveTriggered, Props.updateTriggered]);

  useEffect(() => {
    try {
      const responseOfBranches = async () => {
        let response = await axios.get("node/admin/batch/college/branch", {
          params: {
            id: batchName?.value,
            cname: college?.label,
          },
          headers: {
            "Content-type": "application/json",
          },
        });
        console.log("response>>>", response.data);
        const transformedData = response?.data?.data?.map((item, index) => {
          return { label: item.branch, value: index + 1 };
        });
        console.log("transformedData****", transformedData);
        setBranchList(transformedData);
        if (Props.isEdit) {
          setBranch(
            transformedData?.find(
              (i) =>
                i.label == Props.dataOfCurrentWidget.widgetConfig.branch &&
                i.label != undefined
            )
          );
        }
      };

      responseOfBranches();
    } catch (err) {
      if (err.message.includes("403")) {
        handleResponse(
          "Error",
          "You have been logged-out due to inactivity. Login again."
        );
        setIsUnAuth(true);
      } else {
        handleResponse("Error", err.message);
      }
    }
  }, [college]);

  useEffect(() => {
    try {
      const responseOfColleges = async () => {
        let response = await axios.get("node/admin/batch/college", {
          params: {
            id: batchName?.value,
          },
          headers: {
            "Content-type": "application/json",
          },
        });
        console.log("response>>>", response.data);
        const transformedData = response.data.data.map((item, index) => {
          return { label: item.name, value: index + 1 };
        });
        console.log("transformedData****", transformedData);
        setCollegeList(transformedData);
        if (Props.isEdit) {
          setCollege(
            transformedData.find(
              (i) =>
                i.label == Props.dataOfCurrentWidget.widgetConfig.college &&
                i.label != undefined
            )
          );
        }
      };

      responseOfColleges();
    } catch (err) {
      if (err.message.includes("403")) {
        handleResponse(
          "Error",
          "You have been logged-out due to inactivity. Login again."
        );
        setIsUnAuth(true);
      } else {
        handleResponse("Error", err.message);
      }
    }
  }, [batchName]);

  useEffect(() => {
    try {
      const responseOfBatches = async () => {
        let response = await axios.get("node/admin/batch", {
          headers: {
            "Content-type": "application/json",
          },
        });
        const transformedData = response.data.map((item) => {
          return { label: item.name, value: item.id };
        });
        console.log("transformedData****", transformedData);
        setBatchOptions(transformedData);
        if (Props.isEdit) {
          setbatchName(
            transformedData.find(
              (i) => i.value == Props.dataOfCurrentWidget.widgetConfig.batch_id
            )
          );
        }
      };

      const responseIfDateRange = async () => {
        let response = await axios.get("node/admin/daterange", {
          headers: {
            "Content-type": "application/json",
          },
        });

        const transformedData = response.data.data.map((item) => {
          return { label: item.name, value: item.type };
        });

        setDateRanges(transformedData);

        if (Props.isEdit) {
          setDateRange(
            transformedData.find(
              (i) =>
                i.value == Props.dataOfCurrentWidget.widgetConfig.date_range
            )
          );
        }

        console.log("response>>>", transformedData);
      };
      responseOfBatches();
      if (
        Props.type == constants.BOARD_WIDGETS.ATTENDANCE ||
        Props.type == constants.BOARD_WIDGETS.ASSIGNMENT_STATUS ||
        Props.type == constants.BOARD_WIDGETS.SCORES
      ) {
        responseIfDateRange();
      }
    } catch (err) {
      if (err.message.includes("403")) {
        handleResponse(
          "Error",
          "You have been logged-out due to inactivity. Login again."
        );
        setIsUnAuth(true);
      } else {
        handleResponse("Error", err.message);
      }
    }
  }, []);

  const customStyles = {
    control: (base, state) => ({
      ...base,
      marginTop: "20px",
      border: "1px solid #BDC1C6",
      maxHeight: "40px !important",
      minWidth: "175px",
      maxWidth: "175px",
    }),
    option: (styles, { isSelected }) => {
      return {
        ...styles,
        width: "370px",
        height: "36px",
        fontSize: "14px",
        fontWeight: "400",
        lineHeight: "20px",
        background: "white",
        fontFamily: "Inter",
        minWidth: "175px",
        maxWidth: "175px",
        color: isSelected ? "#000000" : undefined,
        ":hover": {
          color: "white",
          backgroundColor: "#0d6efd",
        },
      };
    },
    singleValue: (styles, { data }) => ({
      ...styles,
      color: "#202124",
      fontSize: "14px",
      fontWeight: "400",
      textAlign: "left",
      lineHeight: "20px",
      fontStyle: "normal",
      fontFamily: "Inter",
      paddingLeft: "10px",
      borderRadius: "20px",
      minWidth: "175px",
      maxWidth: "175px",
    }),
    menuList: (base) => ({
      ...base,
      textAlign: "left",
      maxHeight: "150px",
      overflowX: "hidden",
      borderRadius: "2px",
      minWidth: "175px",
      maxWidth: "175px",
    }),
  };

  useEffect(() => {
    // if(!Props.isEdit){

    if (batchName?.value != undefined && widgetName !== "") {
      Props.setWidgetSaveDisable(false);
    } else {
      Props.setWidgetSaveDisable(true);
    }

    // }
  }, [batchName, widgetName]);

  const handleInputChange = (e, name) => {
    if (name === "batch") {
      setbatchName(e);
    } else if (name === "dateRange") {
      setDateRange(e);
    } else if (name === "startDate") {
      setStartDate(new Date(e).toLocaleDateString("fr-FR"));
    } else if (name === "endDate") {
      setEndDate(new Date(e).toLocaleDateString("fr-FR"));
    } else if (name === "college") {
      setCollege(e);
    } else if (name === "branch") {
      setBranch(e);
    }
  };

  // function changeCheckedStatusinTestlist(mid, lpcid) {
  //   let alltest;
  //   if (assignList.current.length != 0) {
  //     alltest = [...assignList.current];
  //   } else {
  //     alltest = [...testList];
  //   }
  //   if (lpcid == 0) {
  //     for (let i = 0; i < alltest.length; i++) {
  //       let obj = alltest[i];
  //       if (obj.value === mid && !alltest[i].checked) {
  //         if (obj.hasOwnProperty("children")) {
  //           alltest[i].checked = true;
  //           for (let j = 0; j < obj.children.length; j++) {
  //             alltest[i].children[j]["checked"] = true;
  //           }
  //         }
  //       } else if (alltest[i].checked) {
  //         if (obj.hasOwnProperty("children")) {
  //           alltest[i].checked = false;
  //           for (let j = 0; j < obj.children.length; j++) {
  //             alltest[i].children[j]["checked"] = false;
  //           }
  //         }
  //       }
  //     }
  //   } else {
  //     for (let i = 0; i < alltest.length; i++) {
  //       let obj = alltest[i];
  //       if (obj.value == mid) {
  //         if (obj.hasOwnProperty("children")) {
  //           for (let j = 0; j < obj.children.length; j++) {
  //             if (obj.children[j].value == lpcid) {
  //               if (!obj.children[j].checked) {
  //                 console.log("coming in true");
  //                 alltest[i].children[j]["checked"] = true;
  //               } else {
  //                 console.log("coming in false");
  //                 alltest[i].children[j]["checked"] = false;
  //               }
  //             }
  //           }
  //         }
  //       }
  //     }
  //   }
  //   assignList.current = alltest;
  // }

  function changeCheckedStatusinTestlist(mid, lpcid) {
    const alltest =
      assignList.current.length !== 0 ? [...assignList.current] : [...testList];

    const updateNode = (node, checked) => {
      node.checked = checked;
      if (node.children) {
        node.children.forEach((child) => updateNode(child, checked));
      }
    };

    if (lpcid === 0) {
      // Update parent node and all its children
      const parentNode = alltest.find((node) => node.value === mid);
      if (parentNode) {
        const newCheckedState = !parentNode.checked;
        updateNode(parentNode, newCheckedState);
      }
    } else {
      // Update specific child node
      const parentNode = alltest.find((node) => node.value === mid);
      if (parentNode && parentNode.children) {
        const childNode = parentNode.children.find(
          (child) => child.value === lpcid
        );
        if (childNode) {
          childNode.checked = !childNode.checked;

          // Update parent node based on children's state
          parentNode.checked = parentNode.children.every(
            (child) => child.checked
          );
        }
      }
    }

    assignList.current = alltest;
  }

  function findParentItem(testlist, itemId) {
    for (let i = 0; i < testlist.length; i++) {
      const item = testlist[i];
      if (item.children && item.children.length > 0) {
        for (let j = 0; j < item.children.length; j++) {
          if (item.children[j].value == itemId) {
            return item.value;
          } else {
            const parent = findParentItem(item.children, itemId);
            if (parent) {
              return parent.value;
            }
          }
        }
      }
    }
    return null;
  }

  const handleTestsChange = useCallback((currentNode, selectedNodes) => {
    let idsToCheck = [],
      payloadDetails = [];
    for (let item of selectedNodes) {
      let selectedNode = {
        id: item.value,
        name: item.label,
      };

      if (item.isLp) {
        selectedNode.isLp = item.isLp;
        selectedNode.lpid = item.value;
      } else if (item.isModule) {
        selectedNode.isModule = item.isModule;
        selectedNode.mid = item.value;
      } else selectedNode.isTest = true;
      payloadDetails.push(selectedNode);

      // update test state to diplay selected tests
      if (item.isLp) {
        idsToCheck = [
          ...idsToCheck,
          ...getTests(assignList.current, item.value, true),
        ];
      } else if (item.isModule) {
        idsToCheck = [
          ...idsToCheck,
          ...getTests(assignList.current, item.value, false),
        ];
      }
      if (item.isTest) {
        idsToCheck = [...idsToCheck, item.value];
      }
    }
    const updatedTests = markCheckedNodes(assignList.current, idsToCheck);
    assignList.current = updatedTests;
    setSelectAssignments(payloadDetails);
    // setTestList(updatedTests);
    // console.log("currentNode>>>>>>>>>>>?????????", currentNode);
    // if (currentNode.hasOwnProperty("_children")) {
    //   changeCheckedStatusinTestlist(currentNode?.value, 0);
    // } else {
    //   let parentMid = findParentItem(testList, currentNode?.value);
    //   changeCheckedStatusinTestlist(parentMid, currentNode?.value);
    // }
    // console.log("assignList.current>>>>>>", assignList.current);
  }, []);

  const handleExtraColumnConfiguration = (e) => {
    // if it checked add value to array i.e extraColumns array else remove from array
    let extraNewColumns;
    if (percentageDuration.includes(parseInt(e.target.value))) {
      console.log("coming in if");
      extraNewColumns = [...percentageDuration];
      extraNewColumns = extraNewColumns.filter((i) => i != e.target.value);
    } else {
      console.log("coming in else");
      extraNewColumns = [...percentageDuration, parseInt(e.target.value)];
    }
    setPercentageDuration(extraNewColumns);
  };

  const handleColumnstoShowChange = (e, type) => {
    let objectOfListOfColumns = { ...listOfColumns };
    if (type === "email") {
      if (listOfColumns.hasOwnProperty(type) && listOfColumns[type]) {
        objectOfListOfColumns = { ...listOfColumns, [type]: false };
      } else {
        objectOfListOfColumns = { ...listOfColumns, [type]: true };
      }
    } else if (type === "branch") {
      if (listOfColumns.hasOwnProperty(type) && listOfColumns[type]) {
        objectOfListOfColumns = { ...listOfColumns, [type]: false };
      } else {
        objectOfListOfColumns = { ...listOfColumns, [type]: true };
      }
    } else if (type === "college") {
      if (listOfColumns.hasOwnProperty(type) && listOfColumns[type]) {
        objectOfListOfColumns = { ...listOfColumns, [type]: false };
      } else {
        objectOfListOfColumns = { ...listOfColumns, [type]: true };
      }
    } else if (type === "date") {
      if (listOfColumns.hasOwnProperty(type) && listOfColumns[type]) {
        objectOfListOfColumns = { ...listOfColumns, [type]: false };
      } else {
        objectOfListOfColumns = { ...listOfColumns, [type]: true };
      }
    } else if (type === "total_score") {
      if (listOfColumns.hasOwnProperty(type) && listOfColumns[type]) {
        objectOfListOfColumns = { ...listOfColumns, [type]: false };
      } else {
        objectOfListOfColumns = { ...listOfColumns, [type]: true };
      }
    } else if (type === "test_score") {
      if (listOfColumns.hasOwnProperty(type) && listOfColumns[type]) {
        objectOfListOfColumns = { ...listOfColumns, [type]: false };
      } else {
        objectOfListOfColumns = { ...listOfColumns, [type]: true };
      }
    }
    setListOfColumns(objectOfListOfColumns);
  };

  const handleReportstoShowChange = (e) => {
    console.log("eeeee", e.target.value, "type", typeof e.target.value);
    setReportToShow(parseInt(e.target.value));
  };
  const handleOrientationChange = (e) => {
    setOrientation(e.target.value);
  };

  return (
    <div className="WidgetConfigurationContainer">
      <div className="widgetName">
        <label htmlFor="widgetName" className="labelwidget">
          Widget Name: <span style={{ color: "red" }}>*</span>
        </label>
        <input
          type="text"
          id="widgetName"
          value={widgetName}
          onChange={(e) => setWidgetName(e.target.value)}
          required
        />
      </div>
      <div className="widgetDescription">
        <label htmlFor="widgetDescription" className="labelwidget">
          Widget Description:
        </label>
        <textarea
          id="widgetDescription"
          value={widgetDescription}
          onChange={(e) => setWidgetDescription(e.target.value)}
          required
        />
      </div>
      <div className="batchSelect">
        <label className="labelwidget">
          Batch <span style={{ color: "red" }}>*</span>
        </label>
        <div className="widgetFormSelect">
          <Select
            isClearable
            options={batchOptions}
            placeholder={"Choose Batch"}
            value={batchName}
            onChange={(e) => {
              handleInputChange(e, "batch");
            }}
            // styles={customStyles}
          />
        </div>
      </div>
      {(Props.type == constants.BOARD_WIDGETS.LEADERBOARD ||
        Props.type == constants.BOARD_WIDGETS.PERCENTILE) && (
        <div className="reportsToShowContainer">
          <label className="labelwidget">
            Report to Show <span style={{ color: "red" }}>*</span>
          </label>
          <div className="reportsToShow">
            <FormControlLabel
              control={
                <Radio
                  checked={
                    reportToShow == constants.LEADERBOARD_COVERAGE.COLLEGE
                  }
                  onChange={handleReportstoShowChange}
                  value={constants.LEADERBOARD_COVERAGE.COLLEGE}
                />
              }
              label="Against students from the same college"
            />
            {reportToShow == constants.LEADERBOARD_COVERAGE.COLLEGE && (
              <div className="containerforcollegeandbatch">
                <div className="collegeSelect">
                  <label className="labelwidget">College</label>
                  <div className="widgetFormSelect">
                    <Select
                      isClearable
                      options={collegeList}
                      placeholder={"Choose College"}
                      isDisabled={
                        batchName == "" ||
                        batchName == undefined ||
                        batchName == null
                      }
                      value={college}
                      // styles={customStyles}
                      onChange={(e) => {
                        handleInputChange(e, "college");
                      }}
                    />
                  </div>
                </div>
                <div className="branchchSelect">
                  <label className="labelwidget">Branch</label>
                  <div className="widgetFormSelect">
                    <Select
                      isClearable
                      options={branchList}
                      // styles={customStyles}
                      isDisabled={
                        college == "" || college == undefined || college == null
                      }
                      placeholder={"Choose Branch"}
                      value={branch}
                      onChange={(e) => {
                        handleInputChange(e, "branch");
                      }}
                    />
                  </div>
                </div>
              </div>
            )}
            <FormControlLabel
              control={
                <Radio
                  checked={
                    reportToShow ==
                    constants.LEADERBOARD_COVERAGE.COMPLETE_BATCH
                  }
                  onChange={handleReportstoShowChange}
                  value={constants.LEADERBOARD_COVERAGE.COMPLETE_BATCH}
                />
              }
              label="Complete Batch"
            />{" "}
          </div>
        </div>
      )}
      {Props.type == constants.BOARD_WIDGETS.ATTENDANCE ||
      Props.type == constants.BOARD_WIDGETS.ASSIGNMENT_STATUS ||
      Props.type == constants.BOARD_WIDGETS.SCORES ? (
        <div className="dateRange">
          <label className="labelwidget">Date Range</label>
          <div className="widgetFormSelect">
            <Select
              isClearable
              options={dateRanges}
              // styles={customStyles}
              placeholder={"Choose Range"}
              value={dateRange}
              onChange={(e) => {
                handleInputChange(e, "dateRange");
              }}
            />
          </div>
        </div>
      ) : null}
      {dateRange?.label === "Custom" && (
        <div className="startandenddate">
          <Form.Group className="mb-3">
            <Form.Label id="batchStartDateLabel">
              <b>Start Date</b>
            </Form.Label>
            <DatePicker
              name="startDate"
              value={startDate}
              autoComplete="off"
              // minDate={new Date()}
              dateFormat="dd/mm/yyyy"
              className="batchStartDate"
              placeholderText="Pick the date"
              onChange={(date) => {
                // const d = new Date(date).toLocaleDateString("fr-FR");

                handleInputChange(date, "startDate");
              }}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label id="batchStartDateLabel">
              <b>End Date</b>
            </Form.Label>
            <DatePicker
              name="endDate"
              value={endDate}
              autoComplete="off"
              // minDate={new Date()}
              dateFormat="dd/mm/yyyy"
              className="batchStartDate"
              placeholderText="Pick the date"
              onChange={(date) => {
                // const d = new Date(date).toLocaleDateString("fr-FR");
                handleInputChange(date, "endDate");
              }}
            />
          </Form.Group>
        </div>
      )}
      {(Props.type == constants.BOARD_WIDGETS.ASSIGNMENT_STATUS ||
        Props.type == constants.BOARD_WIDGETS.SCORES) && (
        <div className="assignmentSelect">
          <label className="labelwidget">Assignment</label>
          <Container
            onChange={handleTestsChange}
            data={assignList.current}
            keepTreeOnSearch={true}
            keepOpenOnSelect={true}
            showPartiallySelected={true}
          />
        </div>
      )}
      <div className="clgndbranch">
        <label className="labelwidget">Filters</label>
        <div className="collegeSelect">
          <label className="labelwidget">College</label>
          <div className="widgetFormSelect">
            <Select
              isClearable
              options={collegeList}
              placeholder={"Choose College"}
              isDisabled={
                reportToShow == constants.LEADERBOARD_COVERAGE.COLLEGE ||
                batchName == "" ||
                batchName == undefined ||
                batchName == null
              }
              value={college}
              // styles={customStyles}
              onChange={(e) => {
                handleInputChange(e, "college");
              }}
            />
          </div>
        </div>
        <div className="branchchSelect">
          <label className="labelwidget">Branch</label>
          <div className="widgetFormSelect">
            <Select
              isClearable
              options={branchList}
              // styles={customStyles}
              isDisabled={
                reportToShow == constants.LEADERBOARD_COVERAGE.COLLEGE ||
                college == "" ||
                college == undefined ||
                college == null
              }
              placeholder={"Choose Branch"}
              value={branch}
              onChange={(e) => {
                handleInputChange(e, "branch");
              }}
            />
          </div>
        </div>
      </div>
      {Props.type == constants.BOARD_WIDGETS.ATTENDANCE ||
      Props.type == constants.BOARD_WIDGETS.ASSIGNMENT_STATUS ? (
        <>
          <label className="labelwidget1">Additional Columns</label>
          <div className="additionalColumnsDiv">
            <FormControlLabel
              control={
                <Checkbox
                  checked={percentageDuration.includes(
                    constants.PERCENTAGE_DURATION.GIVEN_PERIOD
                  )}
                  disabled={
                    dateRange?.value == constants.DATE_RANGE.FROM_STARTING
                  }
                  value={constants.PERCENTAGE_DURATION.GIVEN_PERIOD}
                  onChange={(e) => handleExtraColumnConfiguration(e)}
                />
              }
              label={`Show the ${
                Props.type == constants.BOARD_WIDGETS.ATTENDANCE
                  ? "attendance"
                  : "assignment"
              } percentage for the given period`}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={percentageDuration.includes(
                    constants.PERCENTAGE_DURATION.BATCH_START_DATE
                  )}
                  value={constants.PERCENTAGE_DURATION.BATCH_START_DATE}
                  onChange={(e) => handleExtraColumnConfiguration(e)}
                />
              }
              label={`Show the ${
                Props.type == constants.BOARD_WIDGETS.ATTENDANCE
                  ? "attendance"
                  : "assignment"
              } percentage from the start of the batch`}
            />
          </div>{" "}
        </>
      ) : null}
      <div className="columnConfigurationContainer">
        <label className="labelwidget">List of Columns to be shown :</label>
        <div className="checkListContainer">
          <FormControlLabel
            control={
              <Checkbox
                // checked={listOfColumns.name}
                checked={true}
                disabled
                onChange={(e) => handleColumnstoShowChange(e, "name")}
              />
            }
            label="Name"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={listOfColumns.email}
                onChange={(e) => handleColumnstoShowChange(e, "email")}
              />
            }
            label="Email"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={listOfColumns.branch}
                onChange={(e) => handleColumnstoShowChange(e, "branch")}
              />
            }
            label="Branch"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={listOfColumns.college}
                onChange={(e) => handleColumnstoShowChange(e, "college")}
              />
            }
            label="College"
          />
          {/* {Props.type == constants.BOARD_WIDGETS.LEADERBOARD && (
            <>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={listOfColumns.ranking}
                    // disabled
                    onChange={(e) => handleColumnstoShowChange(e, "ranking")}
                  />
                }
                label="Ranking"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={listOfColumns.total_score}
                    onChange={(e) =>
                      handleColumnstoShowChange(e, "total_score")
                    }
                  />
                }
                label="Total calculated Score"
              />
            </>
          )} */}
          {/* {Props.type == constants.BOARD_WIDGETS.PERCENTILE && (
            <FormControlLabel
              control={
                <Checkbox
                  checked={listOfColumns.percentile}
                  onChange={(e) => handleColumnstoShowChange(e, "percentile")}
                  // disabled
                />
              }
              label="Percentile"
            />
          )} */}
          {/* {Props.type == constants.BOARD_WIDGETS.SCORES && (
            <FormControlLabel
              control={
                <Checkbox
                  checked={listOfColumns.test_score}
                  onChange={(e) => handleColumnstoShowChange(e, "test_score")}
                  // disabled
                />
              }
              label="Scores of Each Test Selected"
            />
          )} */}
          {Props.type == constants.BOARD_WIDGETS.ATTENDANCE ? (
            <>
              {" "}
              <FormControlLabel
                control={
                  <Checkbox
                    checked={true}
                    disabled
                    onChange={(e) => handleColumnstoShowChange(e, "date")}
                  />
                }
                label="Date"
              />
              {/* <FormControlLabel
                control={
                  <Checkbox
                    checked={listOfColumns.attendance_status}
                    onChange={(e) =>
                      handleColumnstoShowChange(e, "attendance_status")
                    }
                  />
                }
                label="Attendance"
              /> */}
            </>
          ) : null}
        </div>
      </div>

      <label className="labelwidget">Orietation :</label>
      <div className="orientationToShow">
        <FormControlLabel
          control={
            <Radio
              checked={orientation == constants.ORIENTATION.PORTRAIT}
              onChange={handleOrientationChange}
              value={constants.ORIENTATION.PORTRAIT}
            />
          }
          label="Portrait"
        />
        <FormControlLabel
          control={
            <Radio
              checked={orientation == constants.ORIENTATION.LANDSCAPE}
              onChange={handleOrientationChange}
              value={constants.ORIENTATION.LANDSCAPE}
            />
          }
          label="Landscape"
        />{" "}
      </div>

      {/* <button className="btnMedium" onClick={handleSave}>Save</button> */}
    </div>
  );
}

export default WidgetConfiguration;
