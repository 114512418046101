import { Table } from "antd";
import { nanoid } from "nanoid";
import { DndProvider } from "react-dnd";
import React, { useContext } from "react";
import constants from "../../../../constants";
import { HTML5Backend } from "react-dnd-html5-backend";
import AlertContext from "../../../../context/AlertContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MockTestPageContext } from "../../../../context/MockTestPageContext";
import MockLearningPathModuleHooks from "../../../../Hooks/Mock/Admin/MockLearningPath/MockLearningPathModuleHooks";
import MockLearningPathModuleTableHooks from "../../../../Hooks/Mock/Admin/MockLearningPath/MockLearningPathModuleTableHooks";
import MockLearningPathModuleDetailsHooks from "../../../../Hooks/Mock/Admin/MockLearningPath/MockLearningPathModuleDetailsHooks";
import MockLearningPathDraggableModuleHooks from "../../../../Hooks/Mock/Admin/MockLearningPath/MockLearningPathDraggableModuleHooks";
import MockLearningPathDraggableModuleDetailsHooks from "../../../../Hooks/Mock/Admin/MockLearningPath/MockLearningPathDraggableModuleDetailsHooks";

import {
  faCirclePlus,
  faChevronDown,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

export default function MockLearningPathModuleTable() {
  const Alert = useContext(AlertContext);
  const MockTestPageCxt = useContext(MockTestPageContext);

  const MockLearningPathModuleHks = MockLearningPathModuleHooks();
  const moduleComponents = MockLearningPathDraggableModuleHooks();
  const MockLearningPathModuleTableHks = MockLearningPathModuleTableHooks();
  const moduleDetailsComponents = MockLearningPathDraggableModuleDetailsHooks();
  const MockLearningPathModuleDetailsHks = MockLearningPathModuleDetailsHooks();

  return (
    <div
      className="adminmodule-container"
      style={
        !Alert.isOldLpDetail
          ? {
              overflow: "auto",
              minHeight: "calc(100vh - 40vh)",
              maxHeight: "calc(100vh - 32.5vh)",
            }
          : {}
      }
    >
      <DndProvider backend={HTML5Backend}>
        <Table
          key={nanoid()}
          pagination={false}
          components={moduleComponents}
          columns={MockLearningPathModuleTableHks.moduleData}
          onRow={(record, index) => ({
            index,
            movemodules: MockLearningPathModuleTableHks.movemodules,
            onClick: () => MockTestPageCxt.setExpandedRecords(record),
          })}
          expandable={{
            expandedRowRender: (record) => {
              return (
                <div className="adminModuleDetailsSec" key={record.id}>
                  <DndProvider backend={HTML5Backend}>
                    <Table
                      key={nanoid()}
                      pagination={false}
                      dataSource={record.moduleData}
                      components={moduleDetailsComponents}
                      columns={MockLearningPathModuleDetailsHks.moduleDetails}
                      onRow={(record, index) => ({
                        index,
                        movemoduleDetails:
                          MockLearningPathModuleTableHks.movemoduleDetails,
                      })}
                    />
                  </DndProvider>
                  <div className="adminModuleAddDetailSec">
                    <div className="adminModuleAddBtns">
                      <p
                        className="adminModuleAddContent"
                        onClick={() =>
                          MockLearningPathModuleHks.handleAddModuleDetails(
                            record.id,
                            constants.libTypeIds.CONTENT,
                            record.key
                          )
                        }
                      >
                        <FontAwesomeIcon
                          icon={faCirclePlus}
                          className="adminModuleAddDetailIcon"
                        />
                        &nbsp;Content
                      </p>
                      <p
                        className="adminModuleAddTest"
                        onClick={() =>
                          MockLearningPathModuleHks.handleAddModuleDetails(
                            record.id,
                            constants.libTypeIds.MCQ,
                            record.key
                          )
                        }
                      >
                        <FontAwesomeIcon
                          icon={faCirclePlus}
                          className="adminModuleAddDetailIcon"
                        />
                        &nbsp;Mcq Test
                      </p>
                      <p
                        className="adminModuleAddTest"
                        onClick={() =>
                          MockLearningPathModuleHks.handleAddModuleDetails(
                            record.id,
                            constants.libTypeIds.DESCRIPTIVE,
                            record.key
                          )
                        }
                      >
                        <FontAwesomeIcon
                          className="adminModuleAddDetailIcon"
                          icon={faCirclePlus}
                        />
                        &nbsp;Descriptive Test
                      </p>
                      <p
                        className="adminModuleAddCoding"
                        onClick={() =>
                          MockLearningPathModuleHks.handleAddModuleDetails(
                            record.id,
                            constants.libTypeIds.CODING_CHALLENGE,
                            record.key
                          )
                        }
                      >
                        <FontAwesomeIcon
                          icon={faCirclePlus}
                          className="adminModuleAddDetailIcon"
                        />
                        &nbsp;Coding challenge
                      </p>
                    </div>
                  </div>
                </div>
              );
            },
            expandIcon: ({ expanded, onExpand, record }) =>
              expanded ? (
                <>
                  {MockTestPageCxt.modules.map(
                    (module, index) =>
                      module.id === record.id && (
                        <div className="adminModuleSec" key={nanoid()}>
                          <div className="adminModuleContainer">
                            <div className="adminModuleLeftSec">
                              <svg
                                width="6"
                                height="12"
                                fill="none"
                                viewBox="0 0 6 12"
                                className="adminModuleMoveIcon"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fill="#26344A"
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M0.857 10.286C1.331 10.286 1.714 10.669 1.714 11.143C1.714 11.616 1.331 12 0.857 12C0.384 12 0 11.616 0 11.143C0 10.669 0.384 10.286 0.857 10.286ZM5.143 10.286C5.616 10.286 6 10.669 6 11.143C6 11.616 5.616 12 5.143 12C4.669 12 4.286 11.616 4.286 11.143C4.286 10.669 4.669 10.286 5.143 10.286ZM0.857 6.857C1.331 6.857 1.714 7.241 1.714 7.714C1.714 8.188 1.331 8.571 0.857 8.571C0.384 8.571 0 8.188 0 7.714C0 7.241 0.384 6.857 0.857 6.857ZM5.143 6.857C5.616 6.857 6 7.241 6 7.714C6 8.188 5.616 8.571 5.143 8.571C4.669 8.571 4.286 8.188 4.286 7.714C4.286 7.241 4.669 6.857 5.143 6.857ZM0.857 3.43C1.331 3.43 1.714 3.813 1.714 4.287C1.714 4.76 1.331 5.144 0.857 5.144C0.384 5.144 0 4.76 0 4.287C0 3.813 0.384 3.43 0.857 3.43ZM5.143 3.43C5.616 3.43 6 3.813 6 4.287C6 4.76 5.616 5.144 5.143 5.144C4.669 5.144 4.286 4.76 4.286 4.287C4.286 3.813 4.669 3.43 5.143 3.43ZM0.857 0C1.331 0 1.714 0.384 1.714 0.857C1.714 1.331 1.331 1.714 0.857 1.714C0.384 1.714 0 1.331 0 0.857C0 0.384 0.384 0 0.857 0ZM5.143 0C5.616 0 6 0.384 6 0.857C6 1.331 5.616 1.714 5.143 1.714C4.669 1.714 4.286 1.331 4.286 0.857C4.286 0.384 4.669 0 5.143 0Z"
                                />
                              </svg>

                              <input
                                id={record.id}
                                maxLength={100}
                                name="moduleName"
                                title={record.moduleName}
                                value={record.moduleName}
                                className="adminModuleName"
                                placeholder="Enter module name"
                                ref={
                                  MockTestPageCxt.focus &&
                                  parseInt(MockTestPageCxt.focus) === record.id
                                    ? MockLearningPathModuleTableHks.ref
                                    : null
                                }
                                onChange={(event) =>
                                  MockLearningPathModuleTableHks.handleModuleName(
                                    event,
                                    record.id,
                                    index
                                  )
                                }
                                disabled={
                                  record.status === "old" ? true : false
                                }
                              />
                            </div>
                            <div
                              style={{ cursor: "pointer" }}
                              className="adminModuleIconSec"
                              onClick={(e) => {
                                onExpand(record, e);
                                Alert.setModuleExpandKey([undefined]);
                              }}
                            >
                              <FontAwesomeIcon
                                icon={faChevronDown}
                                className="adminModuleExpandIcon"
                              />
                              {record.status === "new" && (
                                <svg
                                  width="20"
                                  height="20"
                                  fill="none"
                                  viewBox="0 0 12 12"
                                  className="adminModuleDeleteIcon"
                                  xmlns="http://www.w3.org/2000/svg"
                                  onClick={() =>
                                    MockLearningPathModuleTableHks.handleDeleteModule(
                                      record
                                    )
                                  }
                                >
                                  <path
                                    fill="#F55533"
                                    d="M5.02196 4.93363C5.29225 4.93363 5.5142 5.14057 5.53803 5.40465L5.54015 5.45182V8.74091C5.54015 9.02709 5.30815 9.25909 5.02196 9.25909C4.75167 9.25909 4.52972 9.05216 4.50589 8.78807L4.50377 8.74091V5.45182C4.50377 5.16563 4.73577 4.93363 5.02196 4.93363Z"
                                  />
                                  <path
                                    fill="#F55533"
                                    d="M7.73075 5.40465C7.70693 5.14057 7.48497 4.93363 7.21469 4.93363C6.9285 4.93363 6.6965 5.16563 6.6965 5.45182V8.74091L6.69862 8.78807C6.72244 9.05216 6.9444 9.25909 7.21469 9.25909C7.50087 9.25909 7.73287 9.02709 7.73287 8.74091V5.45182L7.73075 5.40465Z"
                                  />
                                  <path
                                    fill="#F55533"
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M7.21468 0C8.08291 0 8.79107 0.685314 8.82774 1.54451L8.82924 1.61455V2.19273H11.052C11.3381 2.19273 11.5701 2.42473 11.5701 2.71091C11.5701 2.9812 11.3632 3.20315 11.0991 3.22698L11.052 3.2291H10.4738V10.3855C10.4738 11.2537 9.78847 11.9618 8.92927 11.9985L8.85923 12H3.37742C2.50919 12 1.80103 11.3147 1.76436 10.4555L1.76287 10.3855V3.2291H1.18469C0.898504 3.2291 0.666504 2.9971 0.666504 2.71091C0.666504 2.44062 0.873442 2.21867 1.13753 2.19484L1.18469 2.19273H3.40741V1.61455C3.40741 0.746324 4.09272 0.0381621 4.95192 0.00149169L5.02196 0H7.21468ZM2.79924 3.2291V10.3855C2.79924 10.6888 3.03286 10.9376 3.33 10.9617L3.37742 10.9636H8.85923C9.16258 10.9636 9.41137 10.73 9.43549 10.4329L9.4374 10.3855V3.2291H2.79924ZM7.79286 2.19273H4.44378V1.61455L4.4457 1.56713C4.46982 1.26999 4.71861 1.03637 5.02196 1.03637H7.21468L7.2621 1.03829C7.55924 1.06241 7.79286 1.3112 7.79286 1.61455V2.19273Z"
                                  />
                                </svg>
                              )}
                              {record.status === "old" &&
                                module.moduleData.length === 0 && (
                                  <svg
                                    width="20"
                                    height="20"
                                    fill="none"
                                    viewBox="0 0 12 12"
                                    className="adminModuleDeleteIcon"
                                    xmlns="http://www.w3.org/2000/svg"
                                    onClick={() =>
                                      MockLearningPathModuleHks.handleCreatedDeleteModule(
                                        record
                                      )
                                    }
                                  >
                                    <path
                                      fill="#F55533"
                                      d="M5.02196 4.93363C5.29225 4.93363 5.5142 5.14057 5.53803 5.40465L5.54015 5.45182V8.74091C5.54015 9.02709 5.30815 9.25909 5.02196 9.25909C4.75167 9.25909 4.52972 9.05216 4.50589 8.78807L4.50377 8.74091V5.45182C4.50377 5.16563 4.73577 4.93363 5.02196 4.93363Z"
                                    />
                                    <path
                                      fill="#F55533"
                                      d="M7.73075 5.40465C7.70693 5.14057 7.48497 4.93363 7.21469 4.93363C6.9285 4.93363 6.6965 5.16563 6.6965 5.45182V8.74091L6.69862 8.78807C6.72244 9.05216 6.9444 9.25909 7.21469 9.25909C7.50087 9.25909 7.73287 9.02709 7.73287 8.74091V5.45182L7.73075 5.40465Z"
                                    />
                                    <path
                                      fill="#F55533"
                                      fillRule="evenodd"
                                      clipRule="evenodd"
                                      d="M7.21468 0C8.08291 0 8.79107 0.685314 8.82774 1.54451L8.82924 1.61455V2.19273H11.052C11.3381 2.19273 11.5701 2.42473 11.5701 2.71091C11.5701 2.9812 11.3632 3.20315 11.0991 3.22698L11.052 3.2291H10.4738V10.3855C10.4738 11.2537 9.78847 11.9618 8.92927 11.9985L8.85923 12H3.37742C2.50919 12 1.80103 11.3147 1.76436 10.4555L1.76287 10.3855V3.2291H1.18469C0.898504 3.2291 0.666504 2.9971 0.666504 2.71091C0.666504 2.44062 0.873442 2.21867 1.13753 2.19484L1.18469 2.19273H3.40741V1.61455C3.40741 0.746324 4.09272 0.0381621 4.95192 0.00149169L5.02196 0H7.21468ZM2.79924 3.2291V10.3855C2.79924 10.6888 3.03286 10.9376 3.33 10.9617L3.37742 10.9636H8.85923C9.16258 10.9636 9.41137 10.73 9.43549 10.4329L9.4374 10.3855V3.2291H2.79924ZM7.79286 2.19273H4.44378V1.61455L4.4457 1.56713C4.46982 1.26999 4.71861 1.03637 5.02196 1.03637H7.21468L7.2621 1.03829C7.55924 1.06241 7.79286 1.3112 7.79286 1.61455V2.19273Z"
                                    />
                                  </svg>
                                )}
                            </div>
                          </div>
                        </div>
                      )
                  )}
                </>
              ) : (
                <>
                  {MockTestPageCxt.modules.map(
                    (module, index) =>
                      module.id === record.id && (
                        <div className="adminModuleSec" key={nanoid()}>
                          <div className="adminModuleContainer">
                            <div className="adminModuleLeftSec">
                              <svg
                                width="6"
                                height="12"
                                fill="none"
                                viewBox="0 0 6 12"
                                className="adminModuleMoveIcon"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fill="#26344A"
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M0.857 10.286C1.331 10.286 1.714 10.669 1.714 11.143C1.714 11.616 1.331 12 0.857 12C0.384 12 0 11.616 0 11.143C0 10.669 0.384 10.286 0.857 10.286ZM5.143 10.286C5.616 10.286 6 10.669 6 11.143C6 11.616 5.616 12 5.143 12C4.669 12 4.286 11.616 4.286 11.143C4.286 10.669 4.669 10.286 5.143 10.286ZM0.857 6.857C1.331 6.857 1.714 7.241 1.714 7.714C1.714 8.188 1.331 8.571 0.857 8.571C0.384 8.571 0 8.188 0 7.714C0 7.241 0.384 6.857 0.857 6.857ZM5.143 6.857C5.616 6.857 6 7.241 6 7.714C6 8.188 5.616 8.571 5.143 8.571C4.669 8.571 4.286 8.188 4.286 7.714C4.286 7.241 4.669 6.857 5.143 6.857ZM0.857 3.43C1.331 3.43 1.714 3.813 1.714 4.287C1.714 4.76 1.331 5.144 0.857 5.144C0.384 5.144 0 4.76 0 4.287C0 3.813 0.384 3.43 0.857 3.43ZM5.143 3.43C5.616 3.43 6 3.813 6 4.287C6 4.76 5.616 5.144 5.143 5.144C4.669 5.144 4.286 4.76 4.286 4.287C4.286 3.813 4.669 3.43 5.143 3.43ZM0.857 0C1.331 0 1.714 0.384 1.714 0.857C1.714 1.331 1.331 1.714 0.857 1.714C0.384 1.714 0 1.331 0 0.857C0 0.384 0.384 0 0.857 0ZM5.143 0C5.616 0 6 0.384 6 0.857C6 1.331 5.616 1.714 5.143 1.714C4.669 1.714 4.286 1.331 4.286 0.857C4.286 0.384 4.669 0 5.143 0Z"
                                />
                              </svg>

                              <input
                                id={record.id}
                                maxLength={100}
                                name="moduleName"
                                value={record.moduleName}
                                title={record.moduleName}
                                className="adminModuleName"
                                placeholder="Enter module name"
                                ref={
                                  MockTestPageCxt.focus &&
                                  parseInt(MockTestPageCxt.focus) === record.id
                                    ? MockLearningPathModuleTableHks.ref
                                    : null
                                }
                                onChange={(event) =>
                                  MockLearningPathModuleTableHks.handleModuleName(
                                    event,
                                    record.id,
                                    index
                                  )
                                }
                                onKeyUp={(event) =>
                                  MockLearningPathModuleTableHks.handleEnterKey(
                                    event,
                                    record
                                  )
                                }
                                disabled={
                                  record.status === "old" ? true : false
                                }
                              />
                            </div>
                            <div
                              style={{ cursor: "pointer" }}
                              className="adminModuleIconSec"
                              onClick={(e) => {
                                onExpand(record, e);
                                Alert.setModuleExpandKey([record.key]);
                              }}
                            >
                              <FontAwesomeIcon
                                icon={faChevronRight}
                                className="adminModuleExpandIcon"
                              />
                              {record.status === "new" && (
                                <svg
                                  width="20"
                                  height="20"
                                  fill="none"
                                  viewBox="0 0 12 12"
                                  className="adminModuleDeleteIcon"
                                  xmlns="http://www.w3.org/2000/svg"
                                  onClick={() =>
                                    MockLearningPathModuleTableHks.handleDeleteModule(
                                      record
                                    )
                                  }
                                >
                                  <path
                                    fill="#F55533"
                                    d="M5.02196 4.93363C5.29225 4.93363 5.5142 5.14057 5.53803 5.40465L5.54015 5.45182V8.74091C5.54015 9.02709 5.30815 9.25909 5.02196 9.25909C4.75167 9.25909 4.52972 9.05216 4.50589 8.78807L4.50377 8.74091V5.45182C4.50377 5.16563 4.73577 4.93363 5.02196 4.93363Z"
                                  />
                                  <path
                                    fill="#F55533"
                                    d="M7.73075 5.40465C7.70693 5.14057 7.48497 4.93363 7.21469 4.93363C6.9285 4.93363 6.6965 5.16563 6.6965 5.45182V8.74091L6.69862 8.78807C6.72244 9.05216 6.9444 9.25909 7.21469 9.25909C7.50087 9.25909 7.73287 9.02709 7.73287 8.74091V5.45182L7.73075 5.40465Z"
                                  />
                                  <path
                                    fill="#F55533"
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M7.21468 0C8.08291 0 8.79107 0.685314 8.82774 1.54451L8.82924 1.61455V2.19273H11.052C11.3381 2.19273 11.5701 2.42473 11.5701 2.71091C11.5701 2.9812 11.3632 3.20315 11.0991 3.22698L11.052 3.2291H10.4738V10.3855C10.4738 11.2537 9.78847 11.9618 8.92927 11.9985L8.85923 12H3.37742C2.50919 12 1.80103 11.3147 1.76436 10.4555L1.76287 10.3855V3.2291H1.18469C0.898504 3.2291 0.666504 2.9971 0.666504 2.71091C0.666504 2.44062 0.873442 2.21867 1.13753 2.19484L1.18469 2.19273H3.40741V1.61455C3.40741 0.746324 4.09272 0.0381621 4.95192 0.00149169L5.02196 0H7.21468ZM2.79924 3.2291V10.3855C2.79924 10.6888 3.03286 10.9376 3.33 10.9617L3.37742 10.9636H8.85923C9.16258 10.9636 9.41137 10.73 9.43549 10.4329L9.4374 10.3855V3.2291H2.79924ZM7.79286 2.19273H4.44378V1.61455L4.4457 1.56713C4.46982 1.26999 4.71861 1.03637 5.02196 1.03637H7.21468L7.2621 1.03829C7.55924 1.06241 7.79286 1.3112 7.79286 1.61455V2.19273Z"
                                  />
                                </svg>
                              )}
                            </div>
                          </div>
                        </div>
                      )
                  )}
                </>
              ),
            expandedRowKeys: Alert.moduleExpandKey,
          }}
          size="small"
          dataSource={MockTestPageCxt.modules}
        />
      </DndProvider>
      <p
        className="adminModuleAddModIcon"
        onClick={MockLearningPathModuleHks.handleAddModule}
      >
        <FontAwesomeIcon
          icon={faCirclePlus}
          className="adminModuleAddDetailIcon"
        />
        &nbsp;Add module
      </p>
    </div>
  );
}
