import React from "react";

export default function LightThemeSvgIcon() {
  return (
    <svg
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_637_10328)">
        <path
          stroke="#667085"
          stroke-width="1.6"
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M14.667 7.99935H13.3337M2.66699 7.99935H1.33366M8.00033 13.3327V14.666M11.7909 11.7899L12.7337 12.7327M11.7909 4.20876L12.7337 3.26595M4.20699 11.7899L3.26418 12.7327M4.20699 4.20876L3.26418 3.26595M8.00033 1.33268V2.66602M8.00033 4.66602C6.15938 4.66602 4.66699 6.1584 4.66699 7.99935C4.66699 9.8403 6.15938 11.3327 8.00033 11.3327C9.84127 11.3327 11.3337 9.8403 11.3337 7.99935C11.3337 6.1584 9.84127 4.66602 8.00033 4.66602Z"
        />
      </g>
      <defs>
        <clipPath id="clip0_637_10328">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="matrix(0 -1 -1 0 16 16)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
