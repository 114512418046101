import { Table } from "antd";
import { nanoid } from "nanoid";
import { DndProvider } from "react-dnd";
import React, { useContext, useEffect } from "react";
import { HTML5Backend } from "react-dnd-html5-backend";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MockTestPageContext } from "../../../../../context/MockTestPageContext";
import MockTestConfigTableHooks from "../../../../../Hooks/Mock/Admin/MockTestConfig/MockTestConfigTableHooks";

import {
  faChevronDown,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

export default function MockTestConfigTable() {
  const { focus, testData, isNewTest, testConfig } =
    useContext(MockTestPageContext);

  const {
    ref,
    sections,
    sectionData,
    specificField,
    handleAddTopic,
    handleEnterKey,
    topicExpandedKey,
    handleSectionName,
    handleDeleteSection,
    setTopicExpandedKey,
  } = MockTestConfigTableHooks();

  useEffect(() => {
    setTopicExpandedKey(testConfig.length > 0 && [testConfig[0].key]);
  }, [testConfig]);

  return (
    <DndProvider backend={HTML5Backend}>
      <Table
        key={nanoid()}
        columns={sections}
        pagination={false}
        dataSource={testConfig}
        expandable={{
          expandedRowKeys: topicExpandedKey,
          expandedRowRender: (record) => {
            return (
              <div className="adminTestTopicConfigData">
                <DndProvider backend={HTML5Backend}>
                  <Table
                    key={nanoid()}
                    pagination={false}
                    dataSource={record.secData}
                    columns={
                      specificField === 1
                        ? sectionData.filter(
                            (index) =>
                              index.dataindex !== "desType" &&
                              index.dataindex !== "duration" &&
                              index.dataindex !== "languages"
                          )
                        : specificField === 2 && testData.videoQn
                        ? sectionData.filter(
                            (index) =>
                              index.dataindex !== "desType" &&
                              index.dataindex !== "languages"
                          )
                        : specificField === 2 && !testData.videoQn
                        ? sectionData.filter(
                            (index) =>
                              index.dataindex !== "duration" &&
                              index.dataindex !== "languages"
                          )
                        : sectionData.filter(
                            (index) => index.dataindex !== "desType"
                          )
                    }
                  />
                </DndProvider>
                {(testData.published === 0 || isNewTest) && (
                  <p
                    className="adminTestAddLevelBtn"
                    onClick={() => handleAddTopic(record)}
                  >
                    + Add Topic
                  </p>
                )}
              </div>
            );
          },
          expandIcon: ({ expanded, onExpand, record }) =>
            expanded ? (
              <>
                {testConfig.map(
                  (config) =>
                    config.id === record.id &&
                    specificField !== 3 && (
                      <div className="adminTestSection" key={nanoid()}>
                        <div
                          key={config.id}
                          style={{
                            margin: "0",
                            padding: "0",
                            display: "flex",
                          }}
                        >
                          <input
                            type="text"
                            name="secName"
                            id={record.id}
                            maxLength={30}
                            value={record.secName}
                            className="adminTestSectionName"
                            placeholder="Enter section name"
                            ref={
                              focus && parseInt(focus) === record.id
                                ? ref
                                : null
                            }
                            onChange={(e) => handleSectionName(e, record.id)}
                            disabled={
                              (record.testStatus === "new" &&
                                testData.published === 0) ||
                              isNewTest
                                ? false
                                : true
                            }
                          />
                        </div>
                        <div
                          className="adminTestSectionIconSec adminTestConfig"
                          onClick={(e) => {
                            onExpand(record, e);
                            setTopicExpandedKey([undefined]);
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faChevronDown}
                            className="adminTestSectionExpandIcon"
                          />
                          {specificField !== 3 &&
                          ((record.testStatus === "new" &&
                            testData.published === 0) ||
                            isNewTest) ? (
                            <svg
                              width="12"
                              height="12"
                              fill="none"
                              viewBox="0 0 12 12"
                              id="adminTestSectionTrashIcon"
                              xmlns="http://www.w3.org/2000/svg"
                              onClick={() =>
                                handleDeleteSection(record.id, record.status)
                              }
                            >
                              <path
                                fill="#F55533"
                                d="M5.02196 4.93363C5.29225 4.93363 5.5142 5.14057 5.53803 5.40465L5.54015 5.45182V8.74091C5.54015 9.02709 5.30815 9.25909 5.02196 9.25909C4.75167 9.25909 4.52972 9.05216 4.50589 8.78807L4.50377 8.74091V5.45182C4.50377 5.16563 4.73577 4.93363 5.02196 4.93363Z"
                              />
                              <path
                                fill="#F55533"
                                d="M7.73075 5.40465C7.70693 5.14057 7.48497 4.93363 7.21469 4.93363C6.9285 4.93363 6.6965 5.16563 6.6965 5.45182V8.74091L6.69862 8.78807C6.72244 9.05216 6.9444 9.25909 7.21469 9.25909C7.50087 9.25909 7.73287 9.02709 7.73287 8.74091V5.45182L7.73075 5.40465Z"
                              />
                              <path
                                fill="#F55533"
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M7.21468 0C8.08291 0 8.79107 0.685314 8.82774 1.54451L8.82924 1.61455V2.19273H11.052C11.3381 2.19273 11.5701 2.42473 11.5701 2.71091C11.5701 2.9812 11.3632 3.20315 11.0991 3.22698L11.052 3.2291H10.4738V10.3855C10.4738 11.2537 9.78847 11.9618 8.92927 11.9985L8.85923 12H3.37742C2.50919 12 1.80103 11.3147 1.76436 10.4555L1.76287 10.3855V3.2291H1.18469C0.898504 3.2291 0.666504 2.9971 0.666504 2.71091C0.666504 2.44062 0.873442 2.21867 1.13753 2.19484L1.18469 2.19273H3.40741V1.61455C3.40741 0.746324 4.09272 0.0381621 4.95192 0.00149169L5.02196 0H7.21468ZM2.79924 3.2291V10.3855C2.79924 10.6888 3.03286 10.9376 3.33 10.9617L3.37742 10.9636H8.85923C9.16258 10.9636 9.41137 10.73 9.43549 10.4329L9.4374 10.3855V3.2291H2.79924ZM7.79286 2.19273H4.44378V1.61455L4.4457 1.56713C4.46982 1.26999 4.71861 1.03637 5.02196 1.03637H7.21468L7.2621 1.03829C7.55924 1.06241 7.79286 1.3112 7.79286 1.61455V2.19273Z"
                              />
                            </svg>
                          ) : (
                            <span id="adminTestSectionTrashIcon"></span>
                          )}
                        </div>
                      </div>
                    )
                )}
              </>
            ) : (
              <>
                {testConfig.map(
                  (config) =>
                    config.id === record.id &&
                    specificField !== 3 && (
                      <div className="adminTestSection" key={nanoid()}>
                        <div
                          key={config.id}
                          style={{
                            margin: "0",
                            padding: "0",
                            display: "flex",
                          }}
                        >
                          <input
                            type="text"
                            name="secName"
                            maxLength={30}
                            id={record.id}
                            value={record.secName}
                            className="adminTestSectionName"
                            placeholder="Enter section name"
                            ref={
                              focus && parseInt(focus) === record.id
                                ? ref
                                : null
                            }
                            onChange={(e) => {
                              handleSectionName(e, record.id);
                            }}
                            disabled={
                              (record.testStatus === "new" &&
                                testData.published === 0) ||
                              isNewTest
                                ? false
                                : true
                            }
                            onKeyUp={(event) => handleEnterKey(event, record)}
                          />
                        </div>
                        <div
                          className="adminTestSectionIconSec adminTestConfig"
                          onClick={(e) => {
                            onExpand(record, e);
                            setTopicExpandedKey([record.key]);
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faChevronRight}
                            className="adminTestSectionExpandIcon"
                          />
                          {specificField !== 3 &&
                          ((record.testStatus === "new" &&
                            testData.published === 0) ||
                            isNewTest) ? (
                            <svg
                              width="12"
                              height="12"
                              fill="none"
                              viewBox="0 0 12 12"
                              id="adminTestSectionTrashIcon"
                              xmlns="http://www.w3.org/2000/svg"
                              onClick={() =>
                                handleDeleteSection(record.id, record.status)
                              }
                            >
                              <path
                                fill="#F55533"
                                d="M5.02196 4.93363C5.29225 4.93363 5.5142 5.14057 5.53803 5.40465L5.54015 5.45182V8.74091C5.54015 9.02709 5.30815 9.25909 5.02196 9.25909C4.75167 9.25909 4.52972 9.05216 4.50589 8.78807L4.50377 8.74091V5.45182C4.50377 5.16563 4.73577 4.93363 5.02196 4.93363Z"
                              />
                              <path
                                fill="#F55533"
                                d="M7.73075 5.40465C7.70693 5.14057 7.48497 4.93363 7.21469 4.93363C6.9285 4.93363 6.6965 5.16563 6.6965 5.45182V8.74091L6.69862 8.78807C6.72244 9.05216 6.9444 9.25909 7.21469 9.25909C7.50087 9.25909 7.73287 9.02709 7.73287 8.74091V5.45182L7.73075 5.40465Z"
                              />
                              <path
                                fill="#F55533"
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M7.21468 0C8.08291 0 8.79107 0.685314 8.82774 1.54451L8.82924 1.61455V2.19273H11.052C11.3381 2.19273 11.5701 2.42473 11.5701 2.71091C11.5701 2.9812 11.3632 3.20315 11.0991 3.22698L11.052 3.2291H10.4738V10.3855C10.4738 11.2537 9.78847 11.9618 8.92927 11.9985L8.85923 12H3.37742C2.50919 12 1.80103 11.3147 1.76436 10.4555L1.76287 10.3855V3.2291H1.18469C0.898504 3.2291 0.666504 2.9971 0.666504 2.71091C0.666504 2.44062 0.873442 2.21867 1.13753 2.19484L1.18469 2.19273H3.40741V1.61455C3.40741 0.746324 4.09272 0.0381621 4.95192 0.00149169L5.02196 0H7.21468ZM2.79924 3.2291V10.3855C2.79924 10.6888 3.03286 10.9376 3.33 10.9617L3.37742 10.9636H8.85923C9.16258 10.9636 9.41137 10.73 9.43549 10.4329L9.4374 10.3855V3.2291H2.79924ZM7.79286 2.19273H4.44378V1.61455L4.4457 1.56713C4.46982 1.26999 4.71861 1.03637 5.02196 1.03637H7.21468L7.2621 1.03829C7.55924 1.06241 7.79286 1.3112 7.79286 1.61455V2.19273Z"
                              />
                            </svg>
                          ) : (
                            <span id="adminTestSectionTrashIcon"></span>
                          )}
                        </div>
                      </div>
                    )
                )}
              </>
            ),
        }}
      />
    </DndProvider>
  );
}
