import { useLocation, Navigate, Outlet } from "react-router-dom";
import { getDataFromStorage } from "../util";

const RequireAuth = ({allowedRoles}) => {
    const role=getDataFromStorage("role");
    const location = useLocation();
    // if(!allowedRoles.find(alRole=>alRole.includes(role))) localStorage.clear()
    return (
        allowedRoles.find(alRole=>alRole.includes(role))? <Outlet /> :<Navigate to="/" state={{from:location}} replace/>
    )
}

export default RequireAuth;