import React, { useContext } from "react";
import { Link } from "react-router-dom";
import Paper from "@mui/material/Paper";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import PropTypes from "prop-types";
import AlertContext from "../../../context/AlertContext";

const SideNav = ({ title, menuListItems, from, onMenuClick }) => {
  const paperStyle = {
    border: "1px solid #EAECF0",
    boxShadow: "none",
    width: "24%",
    minHeight: "500px",
    margin: "4px 0 0 25px",
    borderRadius: "10px",
    padding: "16px, 20px, 16px, 20px",
    gap: "10px",
  };

  return (
    <React.Fragment>
      <Paper sx={paperStyle} className="profileNavSec">
        {title && <div className="profileNavTitle">{title}</div>}
        <MenuListItems
          listItems={menuListItems}
          from={from}
          onMenuClick={onMenuClick}
        />
      </Paper>
    </React.Fragment>
  );
};

const MenuListItems = ({ listItems, from, onMenuClick }) => {
  const { setClickedMenu } = useContext(AlertContext);
  const handleMenuNav = (item) => {
    setClickedMenu(item.id);
  };

  const onClick = () => {
    if (onMenuClick) onMenuClick();
  };

  return (
    <MenuList id="profileNavMenuList">
      {listItems.map((item, index) =>
        from === "forpopup" ? (
          <p
            id="profileNavMenuItemSec"
            key={item.id}
            onClick={() => {
              handleMenuNav(item);
            }}
          >
            <MenuItem
              id={`${
                item.active ? "profileNavMenuActiveItem" : "profileNavMenuItem"
              }`}
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText id="profileNavMenuItemText" primary={item.text} />
            </MenuItem>
          </p>
        ) : (
          <Link
            id="profileNavMenuItemSec"
            key={item.id}
            to={item.path}
            tabIndex={index - 1}
            onClick={onClick}
          >
            <MenuItem
              id={`${
                item.active ? "profileNavMenuActiveItem" : "profileNavMenuItem"
              }`}
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText id="profileNavMenuItemText" primary={item.text} />
            </MenuItem>
          </Link>
        )
      )}
    </MenuList>
  );
};

export default SideNav;

SideNav.propTypes = {
  menuListItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      icon: PropTypes.element.isRequired,
      text: PropTypes.string.isRequired,
      active: PropTypes.bool.isRequired,
      path: PropTypes.string.isRequired,
    })
  ),
  title: PropTypes.string,
};

SideNav.defaultProps = {
  menuListItems: [],
  title: "Menu Title",
};
