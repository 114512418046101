import React from "react";
import Button from "../Button";
import { Dialog } from "@mui/material";

const ReleasePopup = ({ open, setOpen }) => {
  return (
    <Dialog open={open} id="releasePopupContainer" scroll="paper">
      <div className="releasePopupContentSec">
        <div className="releasePopupContent">
          <div>
            <div className="releasePopupContentTitle">
              Welcome to the New LMS!
            </div>
            <div className="releasePopupContentBody">
              We've redesigned our LMS to provide a seamless and intuitive
              experience. <br />
              <br />
              Explore the enhanced navigation and modern interface designed to
              make your learning journey smoother and more enjoyable.
            </div>
          </div>
        </div>

        <svg
          width="240"
          height="180"
          viewBox="0 0 240 180"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M240 157.738H0V157.858H240V157.738Z" fill="#EBEBEB" />
          <path
            d="M215.952 165.458H200.055V165.578H215.952V165.458Z"
            fill="#EBEBEB"
          />
          <path
            d="M158.984 166.766H154.812V166.886H158.984V166.766Z"
            fill="#EBEBEB"
          />
          <path
            d="M199.574 161.005H190.363V161.125H199.574V161.005Z"
            fill="#EBEBEB"
          />
          <path
            d="M45.9109 161.806H25.1797V161.926H45.9109V161.806Z"
            fill="#EBEBEB"
          />
          <path
            d="M53.2259 161.806H50.1875V161.926H53.2259V161.806Z"
            fill="#EBEBEB"
          />
          <path
            d="M108.072 163.836H63.1055V163.956H108.072V163.836Z"
            fill="#EBEBEB"
          />
          <path
            d="M113.759 136.328H21.0758C20.3498 136.327 19.6539 136.038 19.1409 135.524C18.6279 135.01 18.3398 134.314 18.3398 133.588V3.30127C18.3462 2.57936 18.6371 1.8891 19.1493 1.38042C19.6616 0.871733 20.3539 0.585709 21.0758 0.584473H113.759C114.486 0.584473 115.183 0.873235 115.697 1.38723C116.211 1.90123 116.5 2.59837 116.5 3.32527V133.588C116.5 134.315 116.211 135.012 115.697 135.526C115.183 136.04 114.486 136.328 113.759 136.328ZM21.0758 0.680473C20.3816 0.681745 19.7162 0.958424 19.2258 1.44978C18.7353 1.94114 18.4598 2.60702 18.4598 3.30127V133.588C18.4598 134.282 18.7353 134.948 19.2258 135.439C19.7162 135.931 20.3816 136.207 21.0758 136.208H113.759C114.454 136.207 115.12 135.931 115.611 135.439C116.102 134.948 116.379 134.282 116.38 133.588V3.30127C116.379 2.60658 116.102 1.94071 115.611 1.44949C115.12 0.958268 114.454 0.681742 113.759 0.680473H21.0758Z"
            fill="#EBEBEB"
          />
          <path
            d="M217.589 136.328H124.901C124.174 136.327 123.478 136.038 122.964 135.524C122.451 135.011 122.161 134.314 122.16 133.588V3.30127C122.168 2.57893 122.46 1.88867 122.973 1.38012C123.486 0.871565 124.179 0.585696 124.901 0.584473H217.589C218.31 0.586971 219.001 0.873552 219.512 1.3821C220.024 1.89065 220.314 2.58019 220.32 3.30127V133.588C220.32 134.313 220.033 135.009 219.521 135.522C219.009 136.036 218.314 136.326 217.589 136.328ZM124.901 0.680473C124.206 0.681742 123.54 0.958268 123.049 1.44949C122.558 1.94071 122.281 2.60658 122.28 3.30127V133.588C122.281 134.282 122.558 134.948 123.049 135.439C123.54 135.931 124.206 136.207 124.901 136.208H217.589C218.284 136.207 218.95 135.931 219.441 135.439C219.932 134.948 220.208 134.282 220.21 133.588V3.30127C220.208 2.60658 219.932 1.94071 219.441 1.44949C218.95 0.958268 218.284 0.681742 217.589 0.680473H124.901Z"
            fill="#EBEBEB"
          />
          <path
            d="M160.545 88.9882L151.898 90.3081L161.944 156.11L170.59 154.79L160.545 88.9882Z"
            fill="#F5F5F5"
          />
          <path
            d="M172.843 100.001H169.805V143.546H172.843V100.001Z"
            fill="#F0F0F0"
          />
          <path
            d="M172.595 94.939H170.052L169.805 100.002H172.843L172.595 94.939Z"
            fill="#F5F5F5"
          />
          <path
            d="M190.097 104.566L187.176 104.192L181.498 148.638L184.419 149.011L190.097 104.566Z"
            fill="#E6E6E6"
          />
          <path
            d="M192.083 100.97L191.179 99.9853L191.373 94.1477L189.448 93.9268L187.02 104.042L187.183 106.222L190.243 106.584L190.678 105.44L190.015 113.119L190.407 113.167C191.662 108.448 192.083 100.97 192.083 100.97Z"
            fill="#EBEBEB"
          />
          <path
            d="M184.997 114.737L175.883 115.195L176.22 106.457L183.704 106.082L184.997 114.737Z"
            fill="#E0E0E0"
          />
          <path
            d="M185.494 115.194L175.883 115.676L178.114 140.51L186.009 140.115L185.494 115.194Z"
            fill="#EBEBEB"
          />
          <path
            d="M188.035 127.344H154.617L157.302 170.888H185.35L188.035 127.344Z"
            fill="#E6E6E6"
          />
          <path
            d="M189.045 126.336H153.602V130.387H189.045V126.336Z"
            fill="#E0E0E0"
          />
          <path
            d="M87.09 27.2144H11.1406V84.9359H87.09V27.2144Z"
            fill="#F0F0F0"
          />
          <path
            d="M36.4497 33.6974L19.9609 35.0327L21.2963 51.5214L37.785 50.1861L36.4497 33.6974Z"
            fill="#FAFAFA"
          />
          <path
            d="M67.624 36.2472L51.0977 35.5112L50.3617 52.0376L66.888 52.7736L67.624 36.2472Z"
            fill="#FAFAFA"
          />
          <path
            d="M49.5654 55.7096L33.0391 54.9736L32.3031 71.5L48.8294 72.236L49.5654 55.7096Z"
            fill="#FAFAFA"
          />
          <path
            d="M51.0161 19.7869C51.0161 19.9636 50.9636 20.1364 50.8653 20.2834C50.767 20.4303 50.6272 20.5447 50.4638 20.6121C50.3004 20.6796 50.1206 20.697 49.9473 20.6621C49.774 20.6273 49.6149 20.5418 49.4903 20.4164C49.3656 20.2911 49.2809 20.1316 49.247 19.9581C49.2131 19.7846 49.2315 19.6049 49.2998 19.4419C49.3681 19.2788 49.4833 19.1397 49.6308 19.0422C49.7782 18.9447 49.9513 18.8931 50.1281 18.8941C50.2451 18.8941 50.361 18.9172 50.469 18.9621C50.5771 19.007 50.6752 19.0729 50.7577 19.1558C50.8402 19.2388 50.9055 19.3373 50.9499 19.4456C50.9942 19.5539 51.0167 19.6698 51.0161 19.7869Z"
            fill="#E6E6E6"
          />
          <path
            d="M95.4301 21.9665C95.431 22.1442 95.3788 22.3182 95.2802 22.4662C95.1816 22.6141 95.041 22.7292 94.8766 22.7968C94.7121 22.8643 94.5312 22.8812 94.3571 22.8453C94.1829 22.8094 94.0235 22.7224 93.8992 22.5953C93.7748 22.4682 93.6913 22.3069 93.6592 22.132C93.6271 21.9572 93.648 21.7767 93.7191 21.6137C93.7902 21.4508 93.9084 21.3128 94.0584 21.2174C94.2085 21.1221 94.3836 21.0737 94.5613 21.0784C94.7935 21.0835 95.0145 21.1792 95.1769 21.3452C95.3393 21.5112 95.4302 21.7342 95.4301 21.9665Z"
            fill="#E6E6E6"
          />
          <path
            d="M42.5322 56.5761C42.5327 56.7766 42.5035 56.9729 42.4482 57.1401C42.3929 57.3074 42.314 57.438 42.2215 57.5155C42.129 57.593 42.0271 57.6138 41.9288 57.5754C41.8304 57.5369 41.7399 57.4409 41.6688 57.2995C41.5977 57.1581 41.5492 56.9777 41.5295 56.7811C41.5097 56.5845 41.5196 56.3806 41.5578 56.1952C41.5961 56.0099 41.6609 55.8514 41.7443 55.7399C41.8276 55.6284 41.9256 55.5689 42.0259 55.5688C42.1597 55.5688 42.288 55.6748 42.3829 55.8635C42.4778 56.0522 42.5315 56.3084 42.5322 56.5761Z"
            fill="#E6E6E6"
          />
          <path
            d="M26.924 40.3796C26.6734 40.3932 26.4234 40.3414 26.191 40.2276C25.9586 40.1137 25.7492 39.9407 25.5771 39.7202C25.5308 39.6587 25.488 39.5934 25.4492 39.5248C25.4021 39.436 25.3429 39.3576 25.2741 39.2927C25.1334 39.2522 24.9859 39.2621 24.8498 39.3212L22.4255 39.9807L22.2773 39.1665L24.7017 38.5071C24.9947 38.3942 25.3115 38.4129 25.5939 38.56C25.7526 38.6767 25.8873 38.8354 25.9879 39.0241C26.0182 39.0729 26.0485 39.1218 26.0822 39.1665C26.2398 39.3372 26.43 39.4578 26.6357 39.5174C26.8414 39.5771 27.0562 39.5741 27.2607 39.5085C27.5854 39.4214 27.9023 39.2959 28.2068 39.134C28.3853 39.0485 28.5435 38.963 28.7422 38.8857C29.8466 38.4297 31.0015 38.4134 32.1093 38.3931L34.4292 38.3564V39.1706L32.1093 39.2072C31.0401 39.1559 29.9708 39.3084 28.9409 39.6591C28.7691 39.7283 28.6041 39.8097 28.4358 39.8871C28.0906 40.0645 27.732 40.2008 27.365 40.2942C27.2204 40.3385 27.0728 40.3671 26.924 40.3796Z"
            fill="#F0F0F0"
          />
          <path
            d="M23.3223 43.4145L23.2891 43.0027L27.8662 42.8688C28.1469 42.871 28.4263 42.8445 28.6932 42.7906C28.8085 42.7598 28.9196 42.7233 29.0254 42.6814C29.0985 42.6547 29.1715 42.6279 29.2479 42.6032C29.9091 42.3921 30.6751 42.345 31.3903 42.4714L31.2076 42.8668C30.6534 42.7664 30.0581 42.8034 29.5469 42.9697C29.4804 42.9903 29.414 43.015 29.3509 43.0377C29.2145 43.0891 29.0724 43.1345 28.9257 43.1736C28.5901 43.245 28.2376 43.2812 27.8828 43.2807L23.3223 43.4145Z"
            fill="#F0F0F0"
          />
          <path
            d="M57.9061 40.3762C56.4845 40.3762 55.0664 40.2818 53.6719 40.1873L53.7293 39.6478C56.2921 39.8204 58.9427 39.9958 61.4953 39.5641L61.752 39.5183C62.208 39.4202 62.6776 39.3685 63.1499 39.3645C63.4704 39.3601 63.7877 39.4159 64.0762 39.5274C64.3648 39.6389 64.6165 39.8031 64.8111 40.0066L64.254 40.3087C64.1193 40.1763 63.9479 40.0708 63.7533 40.0006C63.5587 39.9303 63.3463 39.8973 63.133 39.9041C62.7167 39.9121 62.3031 39.9592 61.9005 40.0444L61.6372 40.0902C60.4096 40.2905 59.1589 40.3864 57.9061 40.3762Z"
            fill="#F0F0F0"
          />
          <path
            d="M64.4913 43.4145C62.7276 42.9343 60.7609 42.7342 58.8069 42.8362L58.7344 42.4318C60.8231 42.3177 62.9275 42.5313 64.8103 43.0485L64.4913 43.4145Z"
            fill="#F0F0F0"
          />
          <path
            d="M59.3294 45.4394C57.434 45.4396 55.5419 45.3031 53.6719 45.0313L53.7853 44.4268C56.4083 44.8078 59.0732 44.9164 61.725 44.7502L61.7731 45.3548C60.9585 45.4122 60.144 45.4394 59.3294 45.4394Z"
            fill="#F0F0F0"
          />
          <path
            d="M69.5057 56.9825C67.673 57.0542 65.8416 56.8107 64.0913 56.2625C63.7025 56.1233 63.3185 55.9601 62.9489 55.7825C62.1334 55.3612 61.2428 55.1051 60.3281 55.0288V54.0688C61.3713 54.1416 62.3887 54.4257 63.3185 54.9041C63.6737 55.0577 64.0433 55.216 64.4033 55.3456C66.3569 56.0416 68.5025 56.0224 70.5713 55.9984C71.0553 56.0145 71.5394 55.9677 72.0113 55.8593C72.2538 55.7816 72.491 55.6886 72.7217 55.5809C72.8849 55.5089 73.0481 55.4368 73.2017 55.3744C74.9153 54.7168 76.8449 54.9856 78.3953 55.2016L78.2609 56.1616C76.7489 55.9504 75.0305 55.7104 73.5425 56.2816C73.3937 56.3344 73.2497 56.4016 73.1009 56.4688C72.8298 56.5941 72.5508 56.7016 72.2657 56.7905C71.7065 56.9251 71.1319 56.9848 70.5569 56.9681L69.5057 56.9825Z"
            fill="#F0F0F0"
          />
          <path
            d="M74.1948 61.1187H63.75V62.0787H74.1948V61.1187Z"
            fill="#F0F0F0"
          />
          <path
            d="M62.4351 67.8622L62.4062 66.9022C66.443 66.7774 70.5663 66.7582 74.5839 66.8398V67.7998C70.5615 67.7182 66.4575 67.7374 62.4351 67.8622Z"
            fill="#F0F0F0"
          />
          <path
            d="M120.001 179.416C171.4 179.416 213.068 176.983 213.068 173.982C213.068 170.981 171.4 168.548 120.001 168.548C68.6012 168.548 26.9336 170.981 26.9336 173.982C26.9336 176.983 68.6012 179.416 120.001 179.416Z"
            fill="#F5F5F5"
          />
          <path
            d="M98.5367 48.9229H95.4023V52.5324H98.5367V48.9229Z"
            fill="#263238"
          />
          <path
            opacity="0.2"
            d="M98.5367 48.9229H95.4023V52.5324H98.5367V48.9229Z"
            fill="white"
          />
          <path
            d="M90.5369 55.5764L91.1657 53.426C91.2776 53.0453 91.5096 52.7111 91.8271 52.4732C92.1446 52.2352 92.5306 52.1064 92.9273 52.106H102.239C102.569 52.1067 102.892 52.1962 103.175 52.3652C103.458 52.5341 103.69 52.7762 103.847 53.066L105.225 55.5812H90.1289"
            fill="#263238"
          />
          <path
            opacity="0.4"
            d="M90.5369 55.5764L91.1657 53.426C91.2776 53.0453 91.5096 52.7111 91.8271 52.4732C92.1446 52.2352 92.5306 52.1064 92.9273 52.106H102.239C102.569 52.1067 102.892 52.1962 103.175 52.3652C103.458 52.5341 103.69 52.7762 103.847 53.066L105.225 55.5812H90.1289"
            fill="white"
          />
          <path
            opacity="0.2"
            d="M94.1211 55.5763H105.228L103.851 53.0611C103.694 52.7713 103.461 52.5292 103.179 52.3603C102.896 52.1914 102.572 52.1018 102.243 52.1011H95.1147C94.8507 52.1023 94.598 52.2081 94.4118 52.3952C94.2256 52.5823 94.1211 52.8355 94.1211 53.0995V55.5763Z"
            fill="white"
          />
          <path
            d="M101.261 49.3809C103.526 47.1164 103.526 43.4451 101.261 41.1807C98.9968 38.9163 95.3254 38.9163 93.061 41.1807C90.7966 43.4451 90.7966 47.1164 93.061 49.3809C95.3254 51.6453 98.9968 51.6453 101.261 49.3809Z"
            fill="#263238"
          />
          <path
            opacity="0.3"
            d="M101.261 49.3809C103.526 47.1164 103.526 43.4451 101.261 41.1807C98.9968 38.9163 95.3254 38.9163 93.061 41.1807C90.7966 43.4451 90.7966 47.1164 93.061 49.3809C95.3254 51.6453 98.9968 51.6453 101.261 49.3809Z"
            fill="white"
          />
          <path
            d="M102.955 46.663C102.955 47.2908 102.769 47.9044 102.42 48.4262C102.071 48.9481 101.575 49.3548 100.995 49.5948C100.415 49.8348 99.777 49.8973 99.1614 49.7745C98.5458 49.6517 97.9805 49.349 97.537 48.9048C97.0934 48.4606 96.7916 47.8949 96.6698 47.2791C96.5479 46.6633 96.6114 46.0252 96.8523 45.4455C97.0931 44.8659 97.5006 44.3707 98.023 44.0226C98.5454 43.6746 99.1593 43.4893 99.787 43.4902C100.628 43.4915 101.433 43.8263 102.027 44.4212C102.621 45.0161 102.955 45.8224 102.955 46.663Z"
            fill="#263238"
          />
          <path
            opacity="0.2"
            d="M102.955 46.663C102.955 47.2908 102.769 47.9044 102.42 48.4262C102.071 48.9481 101.575 49.3548 100.995 49.5948C100.415 49.8348 99.777 49.8973 99.1614 49.7745C98.5458 49.6517 97.9805 49.349 97.537 48.9048C97.0934 48.4606 96.7916 47.8949 96.6698 47.2791C96.5479 46.6633 96.6114 46.0252 96.8523 45.4455C97.0931 44.8659 97.5006 44.3707 98.023 44.0226C98.5454 43.6746 99.1593 43.4893 99.787 43.4902C100.628 43.4915 101.433 43.8263 102.027 44.4212C102.621 45.0161 102.955 45.8224 102.955 46.663Z"
            fill="white"
          />
          <path
            d="M102.241 46.8903C102.241 47.3026 102.118 47.7055 101.889 48.0482C101.66 48.3909 101.334 48.6579 100.954 48.8154C100.573 48.9729 100.153 49.0139 99.7492 48.9331C99.345 48.8524 98.9738 48.6535 98.6827 48.3617C98.3915 48.0699 98.1935 47.6982 98.1137 47.2938C98.0338 46.8894 98.0758 46.4704 98.2342 46.0898C98.3926 45.7092 98.6603 45.3842 99.0035 45.1559C99.3468 44.9276 99.75 44.8062 100.162 44.8071C100.436 44.8071 100.706 44.861 100.959 44.9658C101.211 45.0705 101.441 45.2241 101.634 45.4176C101.827 45.6111 101.98 45.8408 102.084 46.0935C102.188 46.3462 102.241 46.617 102.241 46.8903Z"
            fill="#263238"
          />
          <path
            opacity="0.2"
            d="M96.614 43.0547C96.6149 43.1405 96.5903 43.2247 96.5432 43.2965C96.4961 43.3682 96.4287 43.4243 96.3495 43.4576C96.2704 43.4909 96.1831 43.4999 96.0989 43.4834C96.0146 43.4669 95.9372 43.4256 95.8765 43.3649C95.8158 43.3042 95.7746 43.2268 95.7581 43.1426C95.7416 43.0583 95.7505 42.9711 95.7838 42.8919C95.8171 42.8128 95.8732 42.7454 95.945 42.6983C96.0168 42.6512 96.1009 42.6265 96.1868 42.6275C96.3001 42.6275 96.4087 42.6725 96.4888 42.7526C96.569 42.8327 96.614 42.9414 96.614 43.0547Z"
            fill="black"
          />
          <path
            d="M123.403 172.903V174.751C123.403 174.806 123.392 174.86 123.371 174.91C123.35 174.961 123.319 175.006 123.28 175.045C123.242 175.083 123.195 175.114 123.145 175.134C123.094 175.155 123.04 175.165 122.985 175.164H70.8046C70.3594 175.165 69.9207 175.057 69.5272 174.848C69.1338 174.64 68.7975 174.338 68.5482 173.969C68.2988 173.6 68.1439 173.176 68.0971 172.733C68.0503 172.29 68.113 171.843 68.2798 171.43L84.8158 130.476L111.537 130.658L110.625 135.972L104.731 170.364C104.686 170.625 104.699 170.892 104.768 171.148C104.838 171.403 104.962 171.641 105.132 171.843C105.303 172.045 105.516 172.208 105.755 172.32C105.995 172.432 106.257 172.49 106.521 172.49H122.985C123.04 172.49 123.094 172.5 123.145 172.521C123.195 172.541 123.242 172.571 123.28 172.61C123.319 172.648 123.35 172.694 123.371 172.744C123.392 172.795 123.403 172.849 123.403 172.903Z"
            fill="#FF5553"
          />
          <path
            opacity="0.6"
            d="M123.403 172.903V174.751C123.403 174.806 123.392 174.86 123.371 174.91C123.35 174.961 123.319 175.006 123.28 175.045C123.242 175.083 123.195 175.114 123.145 175.134C123.094 175.155 123.04 175.165 122.985 175.164H70.8046C70.3594 175.165 69.9207 175.057 69.5272 174.848C69.1338 174.64 68.7975 174.338 68.5482 173.969C68.2988 173.6 68.1439 173.176 68.0971 172.733C68.0503 172.29 68.113 171.843 68.2798 171.43L78.5326 146.042L84.8158 130.476L111.537 130.658L110.625 135.972L108.062 150.929L104.731 170.364C104.686 170.625 104.699 170.892 104.768 171.148C104.838 171.403 104.962 171.641 105.132 171.843C105.303 172.045 105.516 172.208 105.755 172.32C105.995 172.432 106.257 172.49 106.521 172.49H122.985C123.04 172.49 123.094 172.5 123.145 172.521C123.195 172.541 123.242 172.571 123.28 172.61C123.319 172.648 123.35 172.694 123.371 172.744C123.392 172.795 123.403 172.849 123.403 172.903Z"
            fill="white"
          />
          <path
            opacity="0.2"
            d="M106.523 172.502H72.5728C72.377 172.502 72.1843 172.454 72.0117 172.362C71.8391 172.269 71.6919 172.136 71.5832 171.973C71.4745 171.81 71.4076 171.623 71.3885 171.428C71.3694 171.233 71.3986 171.037 71.4736 170.856L85.9696 135.984H110.627L104.733 170.376C104.688 170.637 104.701 170.904 104.77 171.16C104.84 171.415 104.964 171.652 105.134 171.855C105.305 172.057 105.517 172.22 105.757 172.332C105.997 172.444 106.259 172.502 106.523 172.502Z"
            fill="black"
          />
          <path
            opacity="0.1"
            d="M111.54 130.673L110.628 135.986L108.065 150.943L78.5352 146.057L84.8184 130.49L111.54 130.673Z"
            fill="black"
          />
          <path
            d="M163.499 54.3462H39.7882C39.3724 54.3462 38.9607 54.4282 38.5766 54.5874C38.1925 54.7467 37.8436 54.9801 37.5498 55.2744C37.256 55.5686 37.0231 55.9179 36.8644 56.3022C36.7057 56.6865 36.6244 57.0984 36.625 57.5142V141.25C36.625 142.089 36.9583 142.894 37.5515 143.487C38.1447 144.08 38.9493 144.413 39.7882 144.413H163.499C163.914 144.414 164.326 144.333 164.711 144.174C165.095 144.015 165.444 143.782 165.738 143.489C166.033 143.195 166.266 142.846 166.425 142.462C166.585 142.078 166.667 141.666 166.667 141.25V57.4998C166.664 56.6617 166.329 55.8589 165.735 55.2676C165.141 54.6763 164.337 54.3449 163.499 54.3462Z"
            fill="#FF5553"
          />
          <path
            opacity="0.7"
            d="M163.499 54.3462H39.7882C39.3724 54.3462 38.9607 54.4282 38.5766 54.5874C38.1925 54.7467 37.8436 54.9801 37.5498 55.2744C37.256 55.5686 37.0231 55.9179 36.8644 56.3022C36.7057 56.6865 36.6244 57.0984 36.625 57.5142V141.25C36.625 142.089 36.9583 142.894 37.5515 143.487C38.1447 144.08 38.9493 144.413 39.7882 144.413H163.499C163.914 144.414 164.326 144.333 164.711 144.174C165.095 144.015 165.444 143.782 165.738 143.489C166.033 143.195 166.266 142.846 166.425 142.462C166.585 142.078 166.667 141.666 166.667 141.25V57.4998C166.664 56.6617 166.329 55.8589 165.735 55.2676C165.141 54.6763 164.337 54.3449 163.499 54.3462Z"
            fill="white"
          />
          <path
            opacity="0.2"
            d="M42.409 144.409H39.7882C38.9493 144.409 38.1447 144.076 37.5515 143.483C36.9583 142.89 36.625 142.085 36.625 141.246V57.4956C36.6244 57.0798 36.7057 56.668 36.8644 56.2837C37.0231 55.8993 37.256 55.5501 37.5498 55.2558C37.8436 54.9616 38.1925 54.7281 38.5766 54.5689C38.9607 54.4096 39.3724 54.3276 39.7882 54.3276H42.409C41.9932 54.3276 41.5815 54.4096 41.1974 54.5689C40.8133 54.7281 40.4644 54.9616 40.1706 55.2558C39.8768 55.5501 39.6439 55.8993 39.4852 56.2837C39.3265 56.668 39.2452 57.0798 39.2458 57.4956V141.246C39.2458 142.085 39.5791 142.89 40.1723 143.483C40.7655 144.076 41.5701 144.409 42.409 144.409Z"
            fill="black"
          />
          <path
            d="M163.815 57.4263H42.1016V138.186H163.815V57.4263Z"
            fill="#FF5553"
          />
          <path
            opacity="0.1"
            d="M151.129 57.4263C134.3 74.2839 91.076 111.81 42.1016 109.06V57.4263H151.129Z"
            fill="white"
          />
          <path
            opacity="0.1"
            d="M163.816 88.4229V138.189H90.5391C93.3471 128.138 98.9631 114.372 109.864 104.532C129.467 86.8341 152.214 86.8869 163.816 88.4229Z"
            fill="white"
          />
          <path
            opacity="0.8"
            d="M114.808 104.831H91.1104C90.5165 104.831 90.0352 105.312 90.0352 105.906V106.107C90.0352 106.701 90.5165 107.183 91.1104 107.183H114.808C115.402 107.183 115.883 106.701 115.883 106.107V105.906C115.883 105.312 115.402 104.831 114.808 104.831Z"
            fill="white"
          />
          <path
            opacity="0.4"
            d="M118.196 110.057H87.7205C87.2751 110.057 86.9141 110.418 86.9141 110.864V113.638C86.9141 114.083 87.2751 114.444 87.7205 114.444H118.196C118.641 114.444 119.002 114.083 119.002 113.638V110.864C119.002 110.418 118.641 110.057 118.196 110.057Z"
            fill="black"
          />
          <path
            d="M118.192 110.583H115.514C115.357 110.583 115.23 110.709 115.23 110.866V113.635C115.23 113.792 115.357 113.919 115.514 113.919H118.192C118.348 113.919 118.475 113.792 118.475 113.635V110.866C118.475 110.709 118.348 110.583 118.192 110.583Z"
            fill="#FF5553"
          />
          <path
            opacity="0.4"
            d="M116.641 87.6622C116.642 90.3682 115.841 93.0138 114.338 95.2642C112.835 97.5146 110.698 99.2687 108.199 100.305C105.699 101.341 102.948 101.612 100.294 101.084C97.6395 100.557 95.2015 99.2537 93.288 97.3402C91.3746 95.4267 90.0716 92.9887 89.5439 90.3346C89.0162 87.6805 89.2875 84.9295 90.3235 82.4296C91.3595 79.9297 93.1136 77.7933 95.364 76.2905C97.6144 74.7876 100.26 73.986 102.966 73.987C104.764 73.9787 106.546 74.3268 108.209 75.0111C109.872 75.6954 111.383 76.7024 112.654 77.9739C113.926 79.2454 114.933 80.7562 115.617 82.4191C116.301 84.082 116.65 85.864 116.641 87.6622Z"
            fill="black"
          />
          <path
            opacity="0.8"
            d="M113.28 96.6169C111.997 98.1006 110.409 99.2906 108.625 100.106C106.841 100.922 104.902 101.344 102.941 101.344C100.979 101.344 99.0405 100.922 97.2565 100.106C95.4724 99.2906 93.8849 98.1006 92.6016 96.6169C93.0993 94.8417 94.045 93.2242 95.3478 91.9196C96.6507 90.6151 98.267 89.6674 100.042 89.1673C99.0925 88.543 98.37 87.6293 97.9815 86.5618C97.593 85.4943 97.5592 84.3299 97.885 83.2416C98.2108 82.1534 98.879 81.1992 99.7902 80.5208C100.701 79.8425 101.807 79.4761 102.943 79.4761C104.079 79.4761 105.185 79.8425 106.096 80.5208C107.007 81.1992 107.675 82.1534 108.001 83.2416C108.327 84.3299 108.293 85.4943 107.905 86.5618C107.516 87.6293 106.794 88.543 105.845 89.1673C107.619 89.6677 109.234 90.6156 110.536 91.9201C111.838 93.2247 112.783 94.842 113.28 96.6169Z"
            fill="white"
          />
          <path
            opacity="0.4"
            d="M149.498 132.075H146.801V134.772H149.498V132.075Z"
            fill="black"
          />
          <path
            opacity="0.4"
            d="M154.053 132.075H152.738C152.356 132.075 152.047 132.384 152.047 132.766V134.081C152.047 134.463 152.356 134.772 152.738 134.772H154.053C154.435 134.772 154.744 134.463 154.744 134.081V132.766C154.744 132.384 154.435 132.075 154.053 132.075Z"
            fill="black"
          />
          <path
            opacity="0.4"
            d="M158.753 132.075H158.523C157.841 132.075 157.289 132.627 157.289 133.308V133.539C157.289 134.22 157.841 134.772 158.523 134.772H158.753C159.434 134.772 159.987 134.22 159.987 133.539V133.308C159.987 132.627 159.434 132.075 158.753 132.075Z"
            fill="black"
          />
          <g opacity="0.1">
            <path
              d="M60.14 57.4268L42.1016 124.828V57.4268H60.14Z"
              fill="white"
            />
            <path
              d="M80.4962 57.4268L58.8818 138.187H43.9922L65.6018 57.4268H80.4962Z"
              fill="white"
            />
            <path
              d="M102.146 57.4268L80.5313 138.187H73.7969L95.4113 57.4268H102.146Z"
              fill="white"
            />
            <path
              d="M122.079 57.4268L100.464 138.187H98.0547L119.669 57.4268H122.079Z"
              fill="white"
            />
          </g>
          <path
            d="M118.65 84.7225L117.987 80.6953L117.325 84.7225L113.293 85.3897L117.325 86.0521L117.987 90.0793L118.65 86.0521L122.677 85.3897L118.65 84.7225Z"
            fill="#FF5553"
          />
          <path
            d="M139.935 42.8195L139.148 38.0483L138.366 42.8195L133.59 43.6067L138.366 44.3939L139.148 49.1651L139.935 44.3939L144.711 43.6067L139.935 42.8195Z"
            fill="#FF5553"
          />
          <path
            d="M159.111 95.6925L158.636 92.8125L158.16 95.6925L155.285 96.1629L158.16 96.6381L158.636 99.5133L159.111 96.6381L161.986 96.1629L159.111 95.6925Z"
            fill="#FF5553"
          />
          <path
            opacity="0.6"
            d="M118.65 84.7225L117.987 80.6953L117.325 84.7225L113.293 85.3897L117.325 86.0521L117.987 90.0793L118.65 86.0521L122.677 85.3897L118.65 84.7225Z"
            fill="white"
          />
          <path
            d="M66.3685 129.502L65.7061 125.475L65.0437 129.502L61.0117 130.165L65.0437 130.832L65.7061 134.859L66.3685 130.832L70.3957 130.165L66.3685 129.502Z"
            fill="#FF5553"
          />
          <path
            opacity="0.6"
            d="M66.3685 129.502L65.7061 125.475L65.0437 129.502L61.0117 130.165L65.0437 130.832L65.7061 134.859L66.3685 130.832L70.3957 130.165L66.3685 129.502Z"
            fill="white"
          />
          <path
            opacity="0.6"
            d="M139.935 42.8195L139.148 38.0483L138.366 42.8195L133.59 43.6067L138.366 44.3939L139.148 49.1651L139.935 44.3939L144.711 43.6067L139.935 42.8195Z"
            fill="white"
          />
          <path
            d="M124.163 153.459L123.376 148.688L122.588 153.459L117.812 154.246L122.588 155.033L123.376 159.804L124.163 155.033L128.934 154.246L124.163 153.459Z"
            fill="#FF5553"
          />
          <path
            opacity="0.6"
            d="M124.163 153.459L123.376 148.688L122.588 153.459L117.812 154.246L122.588 155.033L123.376 159.804L124.163 155.033L128.934 154.246L124.163 153.459Z"
            fill="white"
          />
          <path
            opacity="0.6"
            d="M159.111 95.6925L158.636 92.8125L158.16 95.6925L155.285 96.1629L158.16 96.6381L158.636 99.5133L159.111 96.6381L161.986 96.1629L159.111 95.6925Z"
            fill="white"
          />
          <path
            d="M37.099 48.8034L36.6238 45.9282L36.1486 48.8034L33.2734 49.2786L36.1486 49.7538L36.6238 52.629L37.099 49.7538L39.9742 49.2786L37.099 48.8034Z"
            fill="#FF5553"
          />
          <path
            opacity="0.6"
            d="M37.099 48.8034L36.6238 45.9282L36.1486 48.8034L33.2734 49.2786L36.1486 49.7538L36.6238 52.629L37.099 49.7538L39.9742 49.2786L37.099 48.8034Z"
            fill="white"
          />
        </svg>
      </div>
      <div className="releasePopupActionSec">
        <Button
          size="lg"
          hierarchy={{ type: "secondaryColor", buttonText: "Sure, thank you!" }}
          onClick={() => setOpen(false)}
        />
      </div>
    </Dialog>
  );
};

export default ReleasePopup;
