import React, { useContext, useEffect } from "react";
import AlertContext from "../../../context/AlertContext";
import StudentTestVideoQuestionCard from "./StudentTestVideoQuestionCard";
import { StudentTestPageContext } from "../../../context/StudentTestPageContext";
import constants from "../../../constants";

export default function StudentTestVideoSection(Props) {
  const Alert = useContext(AlertContext);
  const StudentTestPageCxt = useContext(StudentTestPageContext);

  const handleVideoQuestion = (secId, qObjKey, qData) => {
    Alert.qnTrack.current += 1;
    StudentTestPageCxt.videoQnNo.current = parseInt(qObjKey);
    StudentTestPageCxt.setShowTimer(true);
    StudentTestPageCxt.setshowtransition(true);
    let qClickedSecInd = Alert.sectionWithQuestions.current.findIndex(
      (src) => src.sectionid === secId
    );

    let findqndetail =
      Alert.sectionWithQuestions.current[qClickedSecInd].questions[qObjKey - 1];

    let qClicked = [];

    for (let sec of Alert.sectionWithQuestions.current) {
      for (let que of sec.questions) {
        qClicked.push(que);
      }
    }    

    for (let qobj of Alert.questionListObj.current) {
      for (let key in qobj.qnsObj) {
        let newdat = qobj.qnsObj[key];
        for (let det of qClicked) {
          if (newdat.qId === det.id) {
            if (newdat.isAnswered) {
              let ans = Alert.videoQn.current?.filter(
                (data) => data.id === det.id
              )[0]?.answer;

              det.selected = false;
              det.answer = Array.isArray(ans)
                ? ans[0].answer !== undefined
                  ? ans[0].answer
                  : ans
                : ans;
            }
          }
        }
      }
    }

    for (let det of qClicked) {
      if (det.id === findqndetail.id) {
        det.selected = true;
        Props.setVdoQnTimelimit(qData.qnduration * 60);
      }
    }

    let unselectedIndex = qClicked.findIndex(
      (det) => det.selected !== true && det.selected !== false
    );

    if (unselectedIndex === -1) {
      let temp2 = JSON.parse(JSON.stringify(Alert.questionListObj.current));
      let count = 0;
      let trueCount = 0;

      for (let det1 in temp2) {
        for (let det2 in temp2[det1].qnsObj) {
          count++;

          if (temp2[det1].qnsObj[det2].isAnswered) {
            trueCount++;
          }
        }
      }

      let isShowSubmitBtn = count - trueCount;

      if (isShowSubmitBtn === 1) {
        StudentTestPageCxt.showSubmitBtn.current = true;
      } else {
        StudentTestPageCxt.setDisableNxtBtn(true);
      }
    } else {
      StudentTestPageCxt.setDisableNxtBtn(false);
      StudentTestPageCxt.showSubmitBtn.current = false;
    }

    Alert.videoQn.current = qClicked;
    Alert.setQueObj(qClicked);
    Props.setVideoQuestionAttempt(false);
    Alert.setHideSubmitVideoQn(false);
    StudentTestPageCxt.setStartVideo(true);
  };

  const doNothing = () => {
    console.log("Answered Already");
  };

  useEffect(() => {
    if (Alert.updatingStates) {
      Alert.setUpdatingStates(false);
    }
  }, [Alert.updatingStates]);

  return (
    <div className="VideoSectionsViewContainer">
      {Alert.questionListObj?.current?.map((i, index) => (
        <>
          <p key={index}>
            <span className="sectionNameLabelofVid">
              Section: <span className="sectionNameofVid">{i.secName}</span>
            </span>
          </p>
          <div key={index} className="VideoSectionsView">
            {Object.keys(i.qnsObj).map((item) => (
              <div
                className={
                  i.qnsObj[item].isAnswered && Alert.status !== constants.QUESTION_STATUS.CONTINUE ? "vidCardDisable" : "vidCard"
                }
                key={item}
                onClick={() => {
                  i.qnsObj[item].isAnswered && Alert.status !== constants.QUESTION_STATUS.CONTINUE
                    ? doNothing()
                    : handleVideoQuestion(i.secId, item, i.qnsObj[item]);
                }}
              >
                <StudentTestVideoQuestionCard
                  qno={item}
                  qtime={i.qnsObj[item].qnduration}
                  status={i.qnsObj[item].isAnswered}
                />
              </div>
            ))}
          </div>
        </>
      ))}
    </div>
  );
}
