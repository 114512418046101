import constants from "../../constants";
import { useContext, useState } from "react";
import AlertContext from "../../context/AlertContext";

export default function ScoreHooks() {
  const {
    setScore,
    showScore,
    approveData,
    setShowScore,
    totalTestScore,
    totalLearnerScore,
    showBreadCrumbsData,
  } = useContext(AlertContext);

  const [PHtext, setPHtext] = useState("0");
  const [minExtenDate, setMinExtenDate] = useState();
  const [scoreValid, setScoreValid] = useState(true);
  const [extensionDate, setExtensionDate] = useState();
  const [showApprove, setShowApprove] = useState(false);
  const [minExtenDateValue, setMinExtenDateValue] = useState(true);

  const changeScore = (event) => {
    if (isNaN(event.target.value) || event.target.value === "") {
      setScore("");
    } else {
      setScore(Number(event.target.value));
    }
  };

  const toggleDrawerScore = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setShowScore(!showScore);
  };

  const getScore = () => {
    if (
      showBreadCrumbsData.type === constants.libTypeIds.ASSIGNMENT ||
      showBreadCrumbsData.type === constants.libTypeIds.CODING_CHALLENGE
    ) {
      return (
        (approveData.actualscore !== null ? approveData.actualscore : 0) +
        "/" +
        approveData.marks
      );
    } else {
      return totalLearnerScore + "/" + totalTestScore;
    }
  };

  return {
    PHtext,
    getScore,
    setPHtext,
    scoreValid,
    changeScore,
    showApprove,
    minExtenDate,
    setScoreValid,
    extensionDate,
    setShowApprove,
    setMinExtenDate,
    setExtensionDate,
    toggleDrawerScore,
    minExtenDateValue,
    setMinExtenDateValue,
  };
}
