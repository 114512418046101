import Select from "react-select";
import MockTest from "./MockTest";
import Button from "../../../Button";
import constants from "../../../../constants";
import DataTable from "react-data-table-component";
import React, { useContext, useEffect } from "react";
import AlertContext from "../../../../context/AlertContext";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Loading from "../../../Notification-Loading/Loading/Loading";
import { MockTestPageContext } from "../../../../context/MockTestPageContext";
import MockTestListHooks from "../../../../Hooks/Mock/Admin/MockTestListHooks";

export default function MockTestList(Props) {
  const { lpData2, isLoaded, fromMenu, oldTestConfig, fromMenuToEditTest } =
    useContext(AlertContext);

  const { isAddTest, searchText } = useContext(MockTestPageContext);

  const {
    columns,
    filteredData,
    topicOptions,
    customStyles,
    handleBackBtn,
    contentColumn,
    getMockTestList,
    handleSearchTitle,
    handleTopicSelect,
    handleAddTopicToLp,
    handleCreateTestBtn,
    handleGetTestDetails,
    selectedTopicsForValue,
    handleSelectTopicAddToLp,
  } = MockTestListHooks();

  useEffect(() => {
    if (!isAddTest) {
      let libTypeId = oldTestConfig.isOldTestConfig
        ? oldTestConfig.oldTestData.libTypeId
        : undefined;

      getMockTestList(libTypeId);
    }
  }, [
    fromMenu,
    isAddTest,
    lpData2.libTypeId,
    fromMenuToEditTest,
    oldTestConfig.isOldTestConfig,
    oldTestConfig.oldTestData.libTypeId,
  ]);

  return (
    <div className="mockTestListMainContainer">
      {!isAddTest && (
        <>
          <div className="mockTestListHeader">
            <h2 className="mockTestListHeaderLabel">Mock Test List</h2>
            <div className="mockTestListHeaderRightSideDiv">
              <FontAwesomeIcon
                icon={faSearch}
                className="mockTestListHeaderRightSearchIcon"
              />
              <input
                type="text"
                name="search"
                value={searchText}
                placeholder="Search"
                onChange={handleSearchTitle}
                className="mockTestListHeaderSearchBox"
              />
              <Select
                isMulti
                isSearchable={true}
                isClearable={false}
                options={topicOptions}
                placeholder="Choose topic"
                onChange={handleTopicSelect}
                value={selectedTopicsForValue}
                className="mockTestListHeaderTopicSelect"
              />
              <Button
                size={"sm"}
                onClick={handleCreateTestBtn}
                hierarchy={{
                  type: "primary",
                  buttonText: "Create Mock Test",
                }}
              />
            </div>
          </div>
          <DataTable
            data={filteredData}
            selectableRows={!fromMenu}
            customStyles={customStyles}
            className="mockTestListTable"
            onSelectedRowsChange={handleSelectTopicAddToLp}
            onRowClicked={(data) => handleGetTestDetails(data)}
            columns={
              lpData2.libTypeId === constants.libTypeIds.CONTENT ||
              lpData2.libTypeId === constants.libTypeIds.ASSIGNMENT
                ? contentColumn
                : columns
            }
          />
          {!fromMenu && (
            <div className="adminTestBtnSec">
              <Button
                size={"sm"}
                onClick={handleBackBtn}
                hierarchy={{
                  buttonText: "Back",
                  type: "secondaryGrey",
                }}
              />
              <Button
                size={"sm"}
                onClick={handleAddTopicToLp}
                hierarchy={{
                  type: "primary",
                  buttonText: "Add",
                }}
              />
            </div>
          )}
        </>
      )}
      {isLoaded && <Loading />}
      {isAddTest && <MockTest />}
    </div>
  );
}
