import React from "react";
import PropTypes from "prop-types";

const IconButton = ({
  children,
  onClick,
  size,
  destructive,
  disabled,
  hierarchy,
}) => {
  return (
    <button
      className={`iconButton ${size === "2xl" ? "xxl" : size} ${hierarchy}${
        destructive ? " destructive" : ""
      }`}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

export default IconButton;

IconButton.proptype = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  onClick: PropTypes.func,
  size: PropTypes.oneOf(["sm", "md", "lg", "xl", "2xl"]).isRequired,
  destructive: PropTypes.bool,
  disabled: PropTypes.bool,
  hierarchy: PropTypes.string,
};
IconButton.defaultProps = {
  children: (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_7321_3622)">
        <path
          d="M10 18.3334C14.6024 18.3334 18.3333 14.6024 18.3333 10C18.3333 5.39765 14.6024 1.66669 10 1.66669C5.39763 1.66669 1.66667 5.39765 1.66667 10C1.66667 14.6024 5.39763 18.3334 10 18.3334Z"
          stroke="white"
          strokeWidth="1.67"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_7321_3622">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ),
  size: "sm",
  destructive: false,
  disabled: false,
  hierarchy: "primary",
};
