import { useState } from "react";
import constants from "../../../../constants";
export default function QuestionReportHooks() {
  const [error, setError] = useState({});
  const [topics, setTopics] = useState([]);
  const [libType, setLibType] = useState([]);
  const [minIndex, setMinIndex] = useState(0);
  const [maxIndex, setMaxIndex] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [queLoading, setQueLoading] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [questionsData, setQuestionsData] = useState([]);
  const [changingPage, setChangingPage] = useState(false);
  const [checkItsWork, setCheckItsWork] = useState(false);
  const [checkOrderWork, setCheckOrderWork] = useState(false);
  const [sortColumn,setSortColumn] = useState({});
  let [ascOrder, setAscOrder] = useState(false);
  let [selectedLibType, setSelectedLibType] = useState("");

  const updateFilterData = (responseData) => {
    setCheckItsWork(true);

    const updatedToggleData = filteredData.map((newData) => {
      const foundData = responseData.find((data) => newData.id === data.id);

      if (foundData) {
        return {
          ...newData,
          active: foundData.active,
        };
      }

      return newData;
    });

    setFilteredData(updatedToggleData);
  };

  const sortTable = (n,t) => {

    let newOrder = 'ASC';
    if (sortColumn.columnName === n) {
      newOrder = sortColumn.order === 'ASC' ? 'DESC' : 'ASC';
    }

    setSortColumn({ columnName: n, order: newOrder });

    console.log("table sort",t);
    setCheckOrderWork(true);

    let currentorder = !ascOrder;
    let sort1;

    if (n === "question") {
      if (currentorder) {
        sort1 = [...filteredData].sort((a, b) =>
          a.name.trim().localeCompare(b.name.trim())
        );
      } else {
        sort1 = [...filteredData].sort((a, b) =>
          b.name.trim().localeCompare(a.name.trim())
        );
      }
    }

    if (n === "topic") {
      if (currentorder) {
        sort1 = [...filteredData].sort((a, b) =>
         t !== constants.libTypeIds.CODING_CHALLENGE && t !== constants.libTypeIds.DESCRIPTIVE && t !== constants.libTypeIds.MCQ  ? a.topicname.trim().localeCompare(b.topicname.trim()) : a.topics[0].topicname.trim().localeCompare(b.topics[0].topicname.trim())
        );
      } else {
        sort1 = [...filteredData].sort((a, b) =>
          t!== constants.libTypeIds.CODING_CHALLENGE && t !== constants.libTypeIds.DESCRIPTIVE && t !== constants.libTypeIds.MCQ ? b.topicname.trim().localeCompare(a.topicname.trim()) : b.topics[0].topicname.trim().localeCompare(a.topics[0].topicname.trim())
        );
      }
    }

    if (n === "type") {
      if (currentorder) {
        sort1 = [...filteredData].sort((a, b) =>
          a.topicname.trim().localeCompare(b.topicname.trim())
        );
      } else {
        sort1 = [...filteredData].sort((a, b) =>
          b.topicname.trim().localeCompare(a.topicname.trim())
        );
      }
    }

    if (n === "level") {
      if (currentorder) {
        sort1 = [...filteredData].sort((a, b) =>
        t !== constants.libTypeIds.CODING_CHALLENGE && t !== constants.libTypeIds.DESCRIPTIVE && t !== constants.libTypeIds.MCQ ? a.complexity.trim().localeCompare(b.complexity.trim())  : a.topics[0].complexityname.trim().localeCompare(b.topics[0].complexityname.trim())
        );
      } else {
        sort1 = [...filteredData].sort((a, b) =>
        t !== constants.libTypeIds.CODING_CHALLENGE && t !== constants.libTypeIds.DESCRIPTIVE && t !== constants.libTypeIds.MCQ ? b.complexity.trim().localeCompare(a.complexity.trim()) : b.topics[0].complexityname.trim().localeCompare(a.topics[0].complexityname.trim())
        );
      }
    }

    if (n === "createdby") {
      if (currentorder) {
        sort1 = [...filteredData].sort((a, b) =>
          a.createdby?.trim().localeCompare(b.createdby?.trim())
        );
      } else {
        sort1 = [...filteredData].sort((a, b) =>
          b.createdby?.trim().localeCompare(a.createdby?.trim())
        );
      }
    }

    if (n === "impression") {
      if (currentorder) {
        sort1 = [...filteredData].sort(
          (a, b) => a.impressioncount - b.impressioncount
        );
      } else {
        sort1 = [...filteredData].sort(
          (a, b) => b.impressioncount - a.impressioncount
        );
      }
    }

    if (n === "submission") {
      if (currentorder) {
        sort1 = [...filteredData].sort(
          (a, b) => a.successcount - b.successcount
        );
      } else {
        sort1 = [...filteredData].sort(
          (a, b) => b.successcount - a.successcount
        );
      }
    }

    setFilteredData(sort1);
    setAscOrder(currentorder);
  };

  return {
    error,
    topics,
    libType,
    minIndex,
    maxIndex,
    setError,
    ascOrder,
    sortTable,
    setTopics,
    totalPage,
    setLibType,
    queLoading,
    setMaxIndex,
    setMinIndex,
    setAscOrder,
    changingPage,
    checkItsWork,
    setTotalPage,
    filteredData,
    setQueLoading,
    questionsData,
    checkOrderWork,
    setChangingPage,
    setCheckItsWork,
    selectedLibType,
    setFilteredData,
    setQuestionsData,
    updateFilterData,
    setCheckOrderWork,
    setSelectedLibType,
    sortColumn,
    setSortColumn
  };
}
