import React from "react";

export default function DiamondPointSvg() {
  return (
    <svg
      version="1.0"
      width="48.000000pt"
      height="48.000000pt"
      viewBox="0 0 48.000000 48.000000"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        stroke="none"
        fill="#000000"
        transform="translate(0.000000,48.000000) scale(0.100000,-0.100000)"
      >
        <path
          d="M197 422 c-19 -31 -64 -85 -100 -119 l-65 -63 65 -62 c36 -35 81 -89
            100 -121 20 -31 39 -57 42 -57 3 0 23 27 44 59 20 32 66 86 101 119 l63 61
            -64 63 c-35 35 -80 89 -100 121 -20 31 -39 57 -43 57 -4 0 -23 -26 -43 -58z"
        />
      </g>
    </svg>
  );
}
