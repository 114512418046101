import React from "react";
import constants from "../../constants";
import CodingHeaderLearnerSideHooks from "../../Hooks/CodingPage/CodingHeaderLearnerSideHooks";

export default function CodingPageSuccessNotify(Props) {
  const testHeader = CodingHeaderLearnerSideHooks();

  return !Props.timedOut ? (
    <ul className="modalInstructionBodyunorderedListWithSuccess">
      <li className="modalInstructionBodyTextList">
        {testHeader.getSuccessMsg()}
      </li>
      <li className="modalInstructionBodyTextList">
        Your code has been submitted
        {Props.codeDetails.istestcases === constants.ISNOTESTCASES.FALSE
          ? " and evaluated."
          : " successfully."}
      </li>
      {Props.codeDetails.istestcases === constants.ISNOTESTCASES.FALSE && (
        <li className="modalInstructionBodyTextList">
          All the test cases have passed.
        </li>
      )}
    </ul>
  ) : (
    <ul className="modalInstructionBodyunorderedListWithSuccess">
      <li className="modalInstructionBodyTextList">
        {testHeader.getSuccessMsg()}
      </li>
      <li className="modalInstructionBodyTextList">
        Your code has been submitted.
      </li>
    </ul>
  );
}
