import React from 'react'

export default function RankFirstSvg() {
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18 36C27.9411 36 36 27.9411 36 18C36 8.05887 27.9411 0 18 0C8.05887 0 0 8.05887 0 18C0 27.9411 8.05887 36 18 36Z" fill="url(#paint0_linear_1843_16726)"/>
    <g filter="url(#filter0_i_1843_16726)">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M18.0005 5C25.1796 5 31 10.8188 31 17.999C31 25.1797 25.1796 31 18.0005 31C10.8203 31 5 25.1797 5 17.999C5 10.8192 10.8203 5 18.0005 5Z" fill="url(#paint1_linear_1843_16726)"/>
    </g>
    <g filter="url(#filter1_dd_1843_16726)">
    <path d="M19.6637 11.9091V25H17.2923V14.2166H17.2156L14.1538 16.1726V13.9993L17.4073 11.9091H19.6637Z" fill="url(#paint2_linear_1843_16726)"/>
    </g>
    <defs>
    <filter id="filter0_i_1843_16726" x="5" y="5" width="26" height="26" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feOffset/>
    <feGaussianBlur stdDeviation="2"/>
    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.18 0"/>
    <feBlend mode="normal" in2="shape" result="effect1_innerShadow_1843_16726"/>
    </filter>
    <filter id="filter1_dd_1843_16726" x="8.15381" y="9.90918" width="17.51" height="25.0908" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feMorphology radius="2" operator="erode" in="SourceAlpha" result="effect1_dropShadow_1843_16726"/>
    <feOffset dy="2"/>
    <feGaussianBlur stdDeviation="2"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.06 0"/>
    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1843_16726"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feMorphology radius="2" operator="erode" in="SourceAlpha" result="effect2_dropShadow_1843_16726"/>
    <feOffset dy="4"/>
    <feGaussianBlur stdDeviation="4"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.1 0"/>
    <feBlend mode="normal" in2="effect1_dropShadow_1843_16726" result="effect2_dropShadow_1843_16726"/>
    <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_1843_16726" result="shape"/>
    </filter>
    <linearGradient id="paint0_linear_1843_16726" x1="6" y1="4.5" x2="28.5" y2="32.5" gradientUnits="userSpaceOnUse">
    <stop stop-color="#FFE6A8"/>
    <stop offset="1" stop-color="#FEE04F"/>
    </linearGradient>
    <linearGradient id="paint1_linear_1843_16726" x1="10.6507" y1="7.58624" x2="24.574" y2="28.2311" gradientUnits="userSpaceOnUse">
    <stop stop-color="#F6BA60" stop-opacity="0.6"/>
    <stop offset="1" stop-color="#F4B153" stop-opacity="0.9"/>
    </linearGradient>
    <linearGradient id="paint2_linear_1843_16726" x1="16" y1="13.2857" x2="21.6458" y2="25.7335" gradientUnits="userSpaceOnUse">
    <stop stop-color="#FFEB97"/>
    <stop offset="1" stop-color="#FDD44B"/>
    </linearGradient>
    </defs>
    </svg>    
  )
}
