import React from 'react'

export default function NotificationSvgIcon() {
  return (
    <svg
    width="40"
    height="40"
    fill="none"
    viewBox="0 0 40 40"
    xmlns="http://www.w3.org/2000/svg"
    >
    <path
        stroke="#98A2B3"
        stroke-width="1.66667"
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M17.3541 29C18.0593 29.6224 18.9855 30 20 30C21.0144 30 21.9406 29.6224 22.6458 29M26 16C26 14.4087 25.3678 12.8826 24.2426 11.7574C23.1174 10.6321 21.5913 10 20 10C18.4087 10 16.8825 10.6321 15.7573 11.7574C14.6321 12.8826 14 14.4087 14 16C14 19.0902 13.2204 21.206 12.3496 22.6054C11.6151 23.7859 11.2478 24.3761 11.2613 24.5408C11.2762 24.7231 11.3148 24.7926 11.4617 24.9016C11.5944 25 12.1925 25 13.3888 25L26.6111 25C27.8074 25 28.4055 25 28.5382 24.9016C28.6851 24.7926 28.7237 24.7231 28.7386 24.5408C28.7521 24.3761 28.3848 23.7859 27.6503 22.6054C26.7795 21.206 26 19.0902 26 16Z"
    />
    </svg>
  )
}
