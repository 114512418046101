import { nanoid } from "nanoid";
import constants from "../constants";
import { useCallback, useContext } from "react";
import useAxiosPrivate from "./useAxiosPrivate";
import AlertContext from "../context/AlertContext";
import { getPreSignedUrl, getDataFromStorage } from "../util";

export default function ContentPageHooks() {
  const {
    lpId,
    setMsg,
    setShow,
    setTitle,
    lpConfigId,
    setIsUnAuth,
    setIsLoaded,
    contentData,
    setUpdateLeftNav,
    setReferenceLinks,
    handleNavContentOrAssignment,
  } = useContext(AlertContext);

  const axios = useAxiosPrivate();

  const handleGetLinks = useCallback(async () => {
    try {
      let contentData1 = JSON.parse(JSON.stringify(contentData));
      let links =
        contentData1 !== "" &&
        contentData1?.links !== null &&
        contentData1?.links !== undefined &&
        contentData1?.links.length > 0
          ? JSON.parse(contentData1.links)
          : [];

      for (let i = 0; i < links.length; i++) {
        let fileName = links[i].link.substring(
          links[i].link.lastIndexOf("/") + 1
        );

        links[i].id = nanoid();
        links[i].name = fileName;
        links[i].extension = fileName.replace(/^.*\./, "");

        if (links[i].type !== constants.LINK_TYPE.EMBEDED_LINK) {
          if (process.env.REACT_APP_DEPLOYMENT === "LOCAL") {
            links[i].path = links[i].link;
            links[i].link = process.env.REACT_APP_NODE_API + links[i].link;
          } else {
            let getPreSignedUrlRes = await getPreSignedUrl(links[i].link);
            links[i].path = links[i].link;
            links[i].link = getPreSignedUrlRes;
          }
        } else links[i].name = links[i].link;
      }

      setReferenceLinks(links);
    } catch (err) {
      setReferenceLinks([]);
    }
  }, [contentData]);

  const handleContent = (type, lpConfigId, libId, status) => {
    setIsLoaded(true);
    axios
      .post(
        "node/learner/course/content/get",
        JSON.stringify({
          libid: libId,
        }),
        {
          timeout: 60000,
          mode: "no-cors",
          headers: {
            "Content-type": "application/json",
          },
        }
      )
      .then((res) => {
        if (res.data.resultCode !== constants.RESULT_STATUS.SUCCESS) {
          setIsLoaded(false);
          setShow(true);
          setTitle("Info");
          setMsg(res.data.message);
        } else {
          setIsLoaded(false);
          handleNavContentOrAssignment(
            res.data.data,
            lpId,
            type,
            lpConfigId,
            libId,
            status
          );
        }
      })
      .catch((error) => {
        setIsLoaded(false);
        setShow(true);
        setTitle("Error");

        if (error.message === "timeout of 60000ms exceeded") {
          setMsg(
            "Something went wrong… program is taking too long to finish. Please, try again"
          );
        } else if (error.message.includes("403")) {
          setIsUnAuth(true);
          setMsg("You have been logged-out due to inactivity. Login again.");
        } else {
          setMsg(error.message);
        }
      });
  };

  const handleSubmit = async () => {
    setIsLoaded(true);

    try {
      let res = await axios.post(
        "node/learner/course/content/status",
        JSON.stringify({
          lpid: lpId,
          lpconfigid: lpConfigId,
          learnerid: getDataFromStorage("learnerid"),
        }),
        {
          headers: {
            "Content-type": "application/json",
          },
        }
      );
      if (res.data.resultCode !== constants.RESULT_STATUS.SUCCESS) {
        setShow(true);
        setTitle("Info");
        setMsg(res.data.message);
      } else {
        setUpdateLeftNav(true);
      }
    } catch (error) {
      setShow(true);
      setTitle("Error");
      setMsg(error.message);
      if (error.message.includes("403")) {
        setIsUnAuth(true);
        setMsg("You have been logged-out due to inactivity. Login again.");
      } else setMsg(error.message);
    } finally {
      setIsLoaded(false);
    }
  };

  return {
    handleSubmit,
    handleContent,
    handleGetLinks,
  };
}
