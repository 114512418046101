import { nanoid } from "nanoid";
import { useContext, useState } from "react";
import AlertContext from "../../context/AlertContext";
import CodingOutputRunButtonHooks from "./CodingOutputRunButtonHooks";

export default function CodingOutputHooks() {
  const Alert = useContext(AlertContext);

  const { handleRunTestCase } = CodingOutputRunButtonHooks();

  let newCodeDetails = JSON.parse(JSON.stringify(Alert.codeDetails));

  const [value, setValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    for (let i = 0; i < Alert.testCasesRef.current.length; i++) {
      if (
        Alert.testCasesRef.current[i].id ===
        Alert.testCasesRef.current[newValue].id
      ) {
        Alert.testCasesRef.current[i].isActive = true;
      } else {
        Alert.testCasesRef.current[i].isActive = false;
      }
    }

    setValue(newValue);
  };

  const updateTestCase = (data) => {
    let temp = Alert.codeDetails;

    temp.testcases = JSON.stringify(data);
    Alert.testCasesRef.current = data;
    Alert.setCodeDetails(temp);
  };

  const handleCodingOutputTestcasesBoxTab = (tab, index) => {
    const resultData = Alert.runTestResultCode.find(
      (data) => data.id === tab.id
    );

    if (
      (tab.isexample === 0 && resultData !== undefined && Alert.isAdmin) ||
      (tab.isexample === 1 && resultData !== undefined && !Alert.isAdmin)
    ) {
      return `codingOutputTestcasesBoxTab${
        value === index
          ? resultData?.passed
            ? "ActivePass"
            : "ActiveFail"
          : resultData?.passed
          ? "Pass"
          : "Fail"
      }`;
    } else {
      return `codingOutputTestcasesBoxTab${value === index ? "Active" : ""}`;
    }
  };

  const handleRunCurrentTestCase = () => {
    let filteredTestCases = Alert.testCasesRef.current.filter(
      (testCase) => testCase.isActive
    );

    if (
      filteredTestCases[0].input !== "" &&
      filteredTestCases[0].input !== null &&
      filteredTestCases[0].input !== undefined
    ) {
      handleRunTestCase(filteredTestCases);
    }
  };

  const addTab = () => {
    let newTestCase = {
      output: "",
      id: nanoid(),
      language: "",
      isActive: true,
      input: `${
        Alert.testCasesRef.current.length > 0 &&
        Alert.testCasesRef.current[0].input
          .split("\n")
          .map(
            (testCase, index) =>
              index <
                Alert.testCasesRef.current[0].input.split("\n").length - 1 &&
              "\n"
          )
          .join("")
      }`,
    };

    let newTestCases = [...Alert.testCasesRef.current];

    for (let testCase = 0; testCase < newTestCases.length; testCase++) {
      newTestCases[testCase].isActive = false;
    }

    newTestCases.push(newTestCase);
    Alert.testCasesRef.current = newTestCases;
    updateTestCase(newTestCases);

    setValue(newTestCases.length - 1); // Switch to the newly added tab
  };

  const deleteTab = (index) => {
    let filteredTestCases;

    if (index !== 0) {
      const updatedTestCases = [...Alert.testCasesRef.current];

      filteredTestCases = updatedTestCases.filter((tab, i) => i !== index);
    } else {
      filteredTestCases = [...Alert.testCasesRef.current];
    }

    Alert.testCasesRef.current = filteredTestCases;
    updateTestCase(filteredTestCases);
    setValue(index !== 0 ? index - 1 : 0); // Switch to the first tab after deleting
  };

  const handleTestCaseInputChange = (event, testCaseId, ipIndex) => {
    let newTestCases = [...Alert.testCasesRef.current];

    for (let testCase = 0; testCase < newTestCases.length; testCase++) {
      let input = newTestCases[testCase].input.split("\n");

      if (newTestCases[testCase].id === testCaseId) {
        input[ipIndex] = event.target.value;
        newTestCases[testCase].input = input.join("\n");
      }
    }

    newCodeDetails.testcases = JSON.stringify(newTestCases);
    Alert.setCodeDetails(newCodeDetails);
  };

  return {
    value,
    addTab,
    setValue,
    deleteTab,
    newCodeDetails,
    handleTabChange,
    handleRunCurrentTestCase,
    handleTestCaseInputChange,
    handleCodingOutputTestcasesBoxTab,
  };
}
