import { useContext } from "react";
import constants from "../constants";
import TestDueHooks from "./TestDueHooks";
import LeftNavBarHooks from "./LeftNavBarHooks";
import AlertContext from "../context/AlertContext";
import { isEdge, isChrome, isFirefox, isSafari } from "react-device-detect";

export default function WarningNoticeHooks() {
  const Alert = useContext(AlertContext);

  const { openTest } = LeftNavBarHooks();
  const { currentDueLabel } = TestDueHooks();

  const showCompleteSvgIcon = () => {
    return (
      Alert.status === constants.PROGRESS_STATUS.EVALUATED ||
      Alert.status === constants.PROGRESS_STATUS.TO_BE_EVALUATED ||
      Alert.status === constants.PROGRESS_STATUS.PARTIAL_EVALUATED
    );
  };

  const showWarningForAssessment = () => {
    // return (
    //   (isEdge || isChrome || isSafari || isFirefox) &&
    //   Alert.testData.testmode === constants.MODE.TEST_MODE &&
    //   (Alert.testData.status === constants.PROGRESS_STATUS.UNLOCKED ||
    //     Alert.testData.status === constants.PROGRESS_STATUS.QN_CONTINUE ||
    //     Alert.testData.status === constants.PROGRESS_STATUS.REATTEMPT)
    // );

    return false;
  };

  const getMainDivClassNameForWarningNotice = () => {
    return (Alert.status !== constants.PROGRESS_STATUS.EVALUATED &&
      currentDueLabel(Alert.testData.duedate) === "Past Due" &&
      Alert.status !== constants.PROGRESS_STATUS.TO_BE_EVALUATED &&
      Alert.status !== constants.PROGRESS_STATUS.PARTIAL_EVALUATED) ||
      showWarningForAssessment()
      ? "warningNoticeForPastDue"
      : Alert.status === constants.PROGRESS_STATUS.QN_CONTINUE
      ? "warningNoticeForResume"
      : Alert.status === constants.PROGRESS_STATUS.REATTEMPT
      ? "warningNoticeForRetake"
      : Alert.status === constants.PROGRESS_STATUS.IN_PROGRESS
      ? "warningNoticeForConductTrainer"
      : "warningNoticeSubmission";
  };

  const currentTestDue = (type, name) => {
    if (type === constants.libTypeIds.MCQ) {
      return name === "name"
        ? "MCQ"
        : name === "test"
        ? "MCQ test"
        : "MCQ Assessment Past Due";
    } else if (type === constants.libTypeIds.DESCRIPTIVE) {
      return name === "name"
        ? "Descriptive"
        : name === "test"
        ? "Descriptive test"
        : "Descriptive Assessment Past Due";
    } else if (type === constants.libTypeIds.CODING_CHALLENGE) {
      return name === "name"
        ? "Coding"
        : name === "test"
        ? "Coding test"
        : "Coding Assessment Past Due";
    } else {
      return name === "name" || name === "test"
        ? "Assignment"
        : "Assignment Past Due";
    }
  };

  const goToNextTest = () => {
    let modules = Alert.lpData.modules;

    let moduleIndex = modules.findIndex(
      (modules) => modules.modid === Alert.module.id
    );

    if (moduleIndex === -1) {
      console.error("Invalid module ID");
      return;
    }

    let testIndex = modules[moduleIndex].data.findIndex(
      (test) => test.testid === Alert.testId || test.libid === Alert.testId
    );

    if (testIndex === -1) {
      console.error("Invalid test ID");
      return;
    }

    testIndex++;

    if (testIndex >= modules[moduleIndex].data.length) {
      testIndex = 0;
      moduleIndex++;

      if (moduleIndex >= modules.length) {
        moduleIndex = 0;
      }
    }

    while (
      modules[moduleIndex].data[testIndex].status ===
      constants.PROGRESS_STATUS.LOCKED
    ) {
      testIndex++;

      if (testIndex >= modules[moduleIndex].data.length) {
        testIndex = 0;
        moduleIndex++;

        if (moduleIndex >= modules.length) {
          moduleIndex = 0;
        }
      }
    }

    Alert.setModule({
      id: modules[moduleIndex].modid,
      name: modules[moduleIndex].modname,
    });

    openTest(modules[moduleIndex].data[testIndex]);
  };

  return {
    goToNextTest,
    currentTestDue,
    showCompleteSvgIcon,
    showWarningForAssessment,
    getMainDivClassNameForWarningNotice,
  };
}
