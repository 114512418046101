import { TextField } from "@mui/material";
import React, { useState } from "react";

const AchievementForm = ({ detail, setDetail }) => {
  const [focusState, setFocusState] = useState(false);
  return (
    <div className="achievementContainer">
      <TextField
        label="Achievement"
        InputLabelProps={{ shrink: focusState || Boolean(detail["title"]) }}
        id="achievement"
        className="achievementFormField"
        name="title"
        placeholder="Achievement"
        variant="outlined"
        size="small"
        inputProps={{
          onFocus: () => setFocusState(true),
          onBlur: () => setFocusState(false),
          maxLength:500
        }}
        value={detail.title}
        onChange={(event) =>
          setDetail({
            ...detail,
            [event.target.name]: event.target.value,
          })
        }
        multiline
        fullWidth
      />
    </div>
  );
};

export default AchievementForm;
