import Button from "../../Button";
import React, { useContext } from "react";
import constants from "../../../constants";
import Accordion from "@mui/material/Accordion";
import AngleRightSvg from "../../../Svg/AngleRightSvg";
import AlertContext from "../../../context/AlertContext";
import TestLabel from "../../CommonComponents/TestLabel";
import WarningSvgIcon from "../../../Svg/WarningSvgIcon";
import StatusLabel from "../../CommonComponents/StatusLabel";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import CodingTableHooks from "../../../Hooks/CodingTableHooks";
import RightArrowSvgIcon from "../../../Svg/RightArrowSvgIcon";
import WarningNoticeHooks from "../../../Hooks/WarningNoticeHooks";

export default function CodingTable() {
  const { topic, testData } = useContext(AlertContext);

  const { goToNextTest } = WarningNoticeHooks();

  const {
    expanded,
    testScore,
    testStatus,
    handleChange,
    handleExpand,
    currentTestLabel,
    handleCodingTest,
  } = CodingTableHooks();

  testData.data.topics.sort(
    (topic1, topic2) => topic1.ordernosum - topic2.ordernosum
  );

  return (
    <div className="codingTableMainContainer">
      <Accordion disabled defaultExpanded>
        <AccordionSummary className="codingTableHeader">
          <span className="codingTableHeaderLabel">Topic</span>
          <span className="codingTableHeaderLabel">Questions</span>
          <span className="codingTableHeaderLabel">Status</span>
          <span className="codingTableHeaderLabel">
            {testData.viewtest === constants.DISPLAY_TEST_RESULT.SCORE &&
            testData.status === constants.PROGRESS_STATUS.EVALUATED
              ? "Score"
              : ""}
          </span>
        </AccordionSummary>
        <AccordionDetails className="codingTableBodyMainContainer">
          {testData.data.topics.map((test, index) => (
            <Accordion
              key={index}
              onChange={() => handleChange(test)}
              expanded={test.topicid === topic.id && expanded}
            >
              <AccordionSummary
                className="codingTableBody"
                disabled={handleExpand(test).disable}
                onClick={() =>
                  !handleExpand(test).expand &&
                  handleCodingTest(test, currentTestLabel(test))
                }
                expandIcon={
                  currentTestLabel(test) ===
                  constants.PROGRESS_STATUS.IN_PROGRESS ? (
                    <WarningSvgIcon
                      component={"codingTable"}
                      status={currentTestLabel(test)}
                    />
                  ) : (
                    <AngleRightSvg
                      component={"codingTable"}
                      status={currentTestLabel(test)}
                    />
                  )
                }
              >
                <span className="codingTableBodyLabel codingTableBodyTopicLabel">
                  {test.topicname}
                </span>
                <span className="codingTableBodyLabel codingTableBodyCompleteLabel">
                  {test.completedcount}/{test.totalcount} Completed
                </span>
                <StatusLabel
                  component={"codingTable"}
                  status={testStatus(test)}
                />
                {testData.viewtest === constants.DISPLAY_TEST_RESULT.SCORE &&
                  testData.status === constants.PROGRESS_STATUS.EVALUATED && (
                    <span className="codingTableBodyLabel">
                      {testScore(test.qndata)}
                    </span>
                  )}
                {testData.status !== constants.PROGRESS_STATUS.EVALUATED && (
                  <TestLabel status={currentTestLabel(test)} />
                )}
              </AccordionSummary>
              {handleExpand(test).expand && (
                <AccordionDetails className="codingTableInnerBody">
                  {test.qndata.map((question, index) => (
                    <div
                      className="codingTableInnerBodyQuestionList"
                      onClick={() =>
                        handleCodingTest(test, currentTestLabel(test), question)
                      }
                    >
                      Challenge {index + 1}
                      <div className="codingTableInnerBodyQuestionListScore">
                        Score : {question.actualscore}/{question.marks}
                      </div>
                    </div>
                  ))}
                  <div
                    onClick={() =>
                      handleCodingTest(test, currentTestLabel(test))
                    }
                    className={
                      currentTestLabel(test) ===
                      constants.PROGRESS_STATUS.QN_CONTINUE
                        ? "codingTableInnerBodyResumeQuestion"
                        : currentTestLabel(test) ===
                          constants.PROGRESS_STATUS.REATTEMPT
                        ? "codingTableInnerBodyRetakeQuestion"
                        : "codingTableInnerBodyTakeTestQuestion"
                    }
                  >
                    Challenge {test.qndata.length + 1}
                    <div
                      className={
                        currentTestLabel(test) ===
                        constants.PROGRESS_STATUS.QN_CONTINUE
                          ? "codingTableInnerBodyResumeTest"
                          : currentTestLabel(test) ===
                            constants.PROGRESS_STATUS.REATTEMPT
                          ? "codingTableInnerBodyRetakeTest"
                          : "codingTableInnerBodyTakeTestTest"
                      }
                    >
                      {currentTestLabel(test) ===
                      constants.PROGRESS_STATUS.QN_CONTINUE
                        ? "Resume Test"
                        : currentTestLabel(test) ===
                          constants.PROGRESS_STATUS.REATTEMPT
                        ? "Retake Test"
                        : "Take Test"}
                    </div>
                  </div>
                </AccordionDetails>
              )}
            </Accordion>
          ))}
        </AccordionDetails>
      </Accordion>
      {!testData.mockuse &&
        (testData.status === constants.PROGRESS_STATUS.EVALUATED ||
          testData.status === constants.PROGRESS_STATUS.TO_BE_EVALUATED) && (
          <div className="codingTableFooter">
            <Button
              size={"sm"}
              onClick={goToNextTest}
              icon={{ type: "trailing", icon: <RightArrowSvgIcon /> }}
              hierarchy={{
                type: "primary",
                buttonText: "Next  ",
              }}
            />
          </div>
        )}
    </div>
  );
}
