import _ from "lodash";
import { useRef } from "react";
import "./CodingPlayground.scss";
import AceEditor from "react-ace";
import "ace-builds/webpack-resolver";
import { Code } from "@mui/icons-material";
import MenuItem from "@mui/material/MenuItem";
import { TextareaAutosize } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Close";
import "react-datepicker/dist/react-datepicker.css";
import "ace-builds/src-noconflict/ext-language_tools";
import AlertContext from "../../../context/AlertContext";
import React, { useState, useEffect, memo } from "react";
import { apiCall, getDataFromStorage } from "../../../util";
import useAxiosPrivate from "../../../Hooks/useAxiosPrivate";
import Loading from "../../Notification-Loading/Loading/Loading";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const CodeEditor = (Props) => {
  const outputref = useRef(null);
  const containerRef = useRef(null);
  const codeEditorRef = useRef(null);

  const templatecode = [
    {
      id: 1,
      code: "",
      languageName: "java",
    },
    {
      id: 2,
      code: "",
      languageName: "python",
    },

    {
      id: 3,
      code: "",
      languageName: "javascript",
    },
  ];

  const axios = useAxiosPrivate();

  const [fullScreen, setFullScreen] = useState(false);
  const [editorTheme, setEditorTheme] = useState("tomorrow");
  const [isEditorThemeDark, setIsEditorThemeDark] = useState(false);

  const [language, setLanguage] = useState(
    Props.langid === 1 ? "java" : Props.langid === 2 ? "python" : "javascript"
  );

  const [code, setCode] = useState("");
  const [outPut, setOutPut] = useState("");
  const [loading, setLoading] = useState(false);
  const [editorMode, setEditorMode] = useState("normal");
  const [isTryClicked, setIsTryClicked] = useState(false);
  const [boilerplateCode, setBoilerPlateCode] = useState("");

  // const [editorMode, setEditorMode] = useState(() => {
  //     // if (Props.codeDetails?.status === constants.QUESTION_STATUS.SUBMITTED) {
  //     //   return Props.codeDetails?.langname?.toLowerCase() || "";
  //      // } else if (Props.codeDetails?.templatecode?.length > 0) {
  //     //   return Props.codeDetails.templatecode[0].langname?.toLowerCase() || "";
  //     // } else {
  //     //   return "";
  //     // }

  //     return "";
  //   });

  // useEffect(()=>{
  //   const ContainerWidth = window.getComputedStyle(containerRef.current).width;

  //   codeEditorRef.current.
  // })

  useEffect(() => {
    if (Props.langid === null || Props.langid === undefined) {
    } else {
      setCode(Props.code);

      //setBoilerPlateCode(Props.code);
    }
  }, [Props.code]);

  // useEffect(() => {
  //   if (Props.langid !== null || Props.langid !== undefined) {
  //     templatecode.map(
  //       (tempCode) =>
  //         tempCode.id === Props.langid && setLanguage(tempCode.languageName)
  //     );
  //   }
  // }, [Props.langid]);

  const handleCross = () => {
    setIsTryClicked(false);
  };

  var isAdmin;

  function role() {
    isAdmin = getDataFromStorage("role") === "learner" ? false : true;

    return isAdmin;
  }

  isAdmin = role();

  async function apiCall(data) {
    return new Promise((resolve, reject) => {
      axios(data)
        .then((result) => {
          if (result.data) {
            resolve(result.data);
          }

          if (result.data.message) {
            resolve(result.data.message);
          }
        })
        .catch((error) => {
          if (error) {
            reject(error);
          }
          reject(error);
        });
    });
  }

  const handleTryCodeBtn = (lang) => {
    setLoading(true);

    var op = document.getElementsByClassName("codeEditor");

    op.scrollTop = op.scrollHeight + "80px";
    setIsTryClicked(true);

    let programlang = "";
    let langid = "";

    if (lang === "javascript") {
      programlang = "js";
      langid = 3;
    } else if (lang === "java") {
      programlang = "java";
      langid = 1;
    } else {
      programlang = "python";
      langid = 2;
    }

    Props.onDataChange(code, langid, outPut);

    // //Props.output = outPut;
    
    let CodeRunApi;
    if(programlang==="js"){
      CodeRunApi = `${process.env.REACT_APP_NODE_API}node/learner/code/${programlang}/test`;
    }
    else{
      CodeRunApi = `${process.env.REACT_APP_CONTAINER_API}node/learner/code/${programlang}/test`
    }

    console.log("CodeRunApi",CodeRunApi);

    let CodeDetails = {};
    CodeDetails.method = "post";
    CodeDetails.api = "runcode";
    CodeDetails.headers = {
      "Content-type": "application/json",
    };
    CodeDetails.language = lang;
    CodeDetails.url = CodeRunApi;
    CodeDetails.data = {
      custominput: "",
      solutionCode: code,
      learnerid: parseInt(getDataFromStorage("learnerid")),

      // type:"runcode"
    };

    apiCall(CodeDetails)
      .then((result) => {
        setLoading(false);
        setOutPut(result.message);
        Props.onDataChange(code, langid, outPut);
      })
      .catch((error) => {
        setOutPut(error.message);
        Props.onDataChange(code, langid, outPut);
      });
  };

  const handleCodeChange = (newCode) => {
    setCode(newCode);

    //setOutPut("");

    setIsTryClicked(false);
  };

  const handleReset = () => {
    setCode(getBoilerPlateCode(language));
  };

  const handleLanguageChange = (event) => {
    const selectedLanguage = event.target.value;

    setLanguage(selectedLanguage);
    setCode(getBoilerPlateCode(selectedLanguage));
  };

  const getBoilerPlateCode = (lang) => {
    switch (lang) {
      case "java":
        return `public class TestClass
{
  public static void main(String[] args) {
    System.out.println("Hello World");
  }
}`;
      case "python":
        return "";
      case "c":
        return "";
      case "c++":
        return "";
      case "javascript":
        return "";
      default:
        return "";
    }
  };

  useEffect(() => {
    if (isTryClicked && !fullScreen) {
      outputref.current?.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
    }
  }, [isTryClicked]);

  const toggleEditorMode = (element) => {
    document.exitFullscreen();
    setEditorMode(editorMode === "normal" ? "expand" : "normal");
    element.classList.toggle("expand");
    setFullScreen(!fullScreen);

    //setFullScreen(!fullScreen);
    // setEditorMode(editorMode === "normal" ? "expand" : "normal");
    // //element.classList.toggle("expand");
    // setFullScreen(!fullScreen);
  };

  const handleExitfullScre = () => {
    if (document.fullscreenElement === null) {
      // document.exitFullscreen()

      setFullScreen(false);
    }
  };

  useEffect(() => {
    // const keyDownHandler = (event) => {

    //   if (event.key === 'Control') {
    //     //event.preventDefault();

    //     // 👇️ your logic here
    //     document.exitFullscreen();
    //     setFullScreen(!fullScreen)
    //     setEditorMode(editorMode === "normal" ? "expand" : "normal");
    //     // element.classList.toggle("expand");
    //     // setEditorMode("expand");
    //   }
    // };

    document.addEventListener("fullscreenchange", handleExitfullScre);

    // 👇️ clean up event listener

    return () => {
      document.removeEventListener("fullscreenchange", handleExitfullScre);
    };
  }, []);

  const toggleEditorModeFull = (element) => {
    // const CodeEdit = document.getElementsByClassName("codeEditor");
    // if(document.fullscreenElement){
    //   if(document.exitFullscreen){
    //     document.exitFullscreen();
    //   }
    // }

    // else{
    //   if(CodeEdit.requestFullScreen){
    //     CodeEdit.requestFullScreen();
    //   }
    //   else if (CodeEdit.wekitRequestFullScreen){
    //     CodeEdit.wekitRequestFullScreen();
    //   }
    // }
    // element.style.width = '100%';
    // element.style.height = window.innerHeight;
    //if(fullScreen) element.exitFullscreen();

    element.requestFullscreen();

    //element.style.selec

    setEditorMode(editorMode === "normal" ? "expand" : "normal");

    //element.classList.toggle("expand");

    setFullScreen(!fullScreen);

    // if (fullScreen) {
    //   element.style.padding = 0;
    //   containerRef.current.style.maxHeight = window.innerWidth + "px";
    // } else {
    //   element.style.paddingTop = "50px";
    //   containerRef.current.style.maxHeight = 580 + "px";
    // }
  };

  const handleEditorTheme = (themeRef) => {
    setIsEditorThemeDark(!isEditorThemeDark);
    setEditorTheme(themeRef === "light" ? "tomorrow" : "monokai");
  };

  // const handleFullScreenMode = (element) => {
  //   // // if (screenfull.isEnabled) {
  //   // // screenfull.toggle(element);

  //   element.classList.toggle("expanded");
  //   //Props.setFullScreen(!Props.fullScreen);
  //   element.style.padding = 0;
  //   editorContainerRef.current.style.maxHeight = window.innerHeight + "px";
  //   if (fullScreen) {
  //     element.style.padding = 0;
  //     editorContainerRef.current.style.maxHeight = window.innerHeight + "px";
  //   } else {
  //     element.style.paddingTop = "50px";
  //     editorContainerRef.current.style.maxHeight = 580 + "px";
  //   }

  //   // // }
  // };

  //const editorContainerRef = useRef("");

  return (
    isAdmin !== undefined && (
      <div
        ref={containerRef}
        className="codeEditor"

        // className={fullScreen ? "codeEditorFull" : "codeEditor"}
      >
        {/* <div className={fullScreen ? "codeEditorUtilsFull" : "codeEditorUtils"}> */}
        <div className="codeEditorUtils">
          <div className={"codeEditorLanguage"}>
            <Select
              value={language}
              disabled={!isAdmin}
              onChange={handleLanguageChange}
              IconComponent={KeyboardArrowDownIcon}
              className="codeEditorLanguageSelectorAdmin"

              // sx={{ m: 0, maxWidth: 160, maxHeight: 30 }}
            >
              {templatecode.map((tempCode) =>
                tempCode.id === Props.langid ? (
                  <MenuItem
                    id={tempCode.id}
                    key={tempCode.id}
                    value={tempCode.languageName}
                  >
                    {tempCode.languageName}
                  </MenuItem>
                ) : (
                  <MenuItem
                    id={tempCode.id}
                    key={tempCode.id}
                    value={tempCode.languageName}
                  >
                    {tempCode.languageName}
                  </MenuItem>
                )
              )}
            </Select>
          </div>

          <div className="tryandSubUtils">
            <div className="subUtils">
              {isEditorThemeDark ? (
                <svg
                  width="30"
                  height="30"
                  fill="none"
                  viewBox="0 0 30 30"
                  className="codeEditorLightMode"
                  xmlns="http://www.w3.org/2000/svg"
                  onClick={() => handleEditorTheme("light")}
                >
                  <g clip-path="url(#clip0_833_13355)">
                    <path
                      fill="#373739"
                      d="M5 15C5 18.36 7.64 21 11 21C14.36 21 17 18.36 17 15C17 11.64 14.36 9 11 9C7.64 9 5 11.64 5 15ZM11 11.4C13.04 11.4 14.6 12.96 14.6 15C14.6 17.04 13.04 18.6 11 18.6C8.96 18.6 7.4 17.04 7.4 15C7.4 12.96 8.96 11.4 11 11.4ZM12.2 6.6V4.2C12.2 3.48 11.72 3 11 3C10.28 3 9.8 3.48 9.8 4.2V6.6C9.8 7.32 10.28 7.8 11 7.8C11.72 7.8 12.2 7.32 12.2 6.6ZM19.52 6.48C19.04 6 18.32 6 17.84 6.48L16.16 8.16C15.68 8.64 15.68 9.36 16.16 9.84C16.4 10.08 16.76 10.2 17 10.2C17.24 10.2 17.6 10.08 17.84 9.84L19.52 8.16C20 7.8 20 6.96 19.52 6.48ZM21.8 13.8H19.4C18.68 13.8 18.2 14.28 18.2 15C18.2 15.72 18.68 16.2 19.4 16.2H21.8C22.52 16.2 23 15.72 23 15C23 14.28 22.52 13.8 21.8 13.8ZM17.84 20.04C17.36 19.56 16.64 19.56 16.16 20.04C15.68 20.52 15.68 21.24 16.16 21.72L17.84 23.4C18.08 23.64 18.44 23.76 18.68 23.76C18.92 23.76 19.28 23.64 19.52 23.4C20 22.92 20 22.2 19.52 21.72L17.84 20.04ZM9.8 23.4V25.8C9.8 26.52 10.28 27 11 27C11.72 27 12.2 26.52 12.2 25.8V23.4C12.2 22.68 11.72 22.2 11 22.2C10.28 22.2 9.8 22.68 9.8 23.4ZM2.48 23.52C2.72 23.76 3.08 23.88 3.32 23.88C3.56 23.88 3.92 23.76 4.16 23.52L5.84 21.84C6.32 21.36 6.32 20.64 5.84 20.16C5.36 19.68 4.64 19.68 4.16 20.16L2.48 21.84C2 22.2 2 23.04 2.48 23.52ZM-1 15C-1 15.72 -0.52 16.2 0.2 16.2H2.6C3.32 16.2 3.8 15.72 3.8 15C3.8 14.28 3.32 13.8 2.6 13.8H0.2C-0.52 13.8 -1 14.28 -1 15ZM4.16 6.48C3.68 6 2.96 6 2.48 6.48C2 6.96 2 7.68 2.48 8.16L4.16 9.84C4.4 10.2 4.76 10.32 5.12 10.32C5.48 10.32 5.72 10.2 5.96 9.96C6.44 9.48 6.44 8.76 5.96 8.28L4.16 6.48Z"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_833_13355">
                      <rect width="30" height="30" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              ) : (
                <svg
                  width="22"
                  height="24"
                  fill="none"
                  viewBox="0 0 22 24"
                  className="codeEditorDarkMode"
                  xmlns="http://www.w3.org/2000/svg"
                  onClick={() => handleEditorTheme("dark")}
                >
                  <path
                    fill="#373739"
                    d="M19.3859 13C19.2566 12.8807 19.0999 12.8043 18.9336 12.7796C18.7673 12.7548 18.5981 12.7827 18.4453 12.86C17.498 13.3439 16.4681 13.593 15.4263 13.59C13.4992 13.5874 11.6512 12.7345 10.2852 11.2171C8.9192 9.69979 8.14599 7.64114 8.13417 5.49003C8.13825 4.81586 8.21342 4.14458 8.35813 3.49003C8.38883 3.31557 8.37732 3.13512 8.32476 2.96728C8.27221 2.79944 8.18051 2.65028 8.05913 2.53516C7.93775 2.42004 7.79107 2.34313 7.63419 2.31234C7.47732 2.28156 7.31593 2.29802 7.16666 2.36003C5.76227 3.06494 4.54146 4.15679 3.61891 5.53301C2.69636 6.90924 2.10235 8.52466 1.8927 10.2275C1.68305 11.9304 1.86462 13.6648 2.42037 15.2678C2.97612 16.8708 3.8878 18.2898 5.06976 19.3915C6.25172 20.4932 7.66517 21.2414 9.17732 21.5658C10.6895 21.8903 12.2507 21.7803 13.7143 21.2463C15.1779 20.7122 16.4959 19.7716 17.5444 18.5128C18.593 17.254 19.3376 15.7183 19.7084 14.05C19.7536 13.859 19.7471 13.6574 19.6897 13.4705C19.6323 13.2836 19.5266 13.1199 19.3859 13ZM10.8754 19.69C9.372 19.6782 7.90859 19.1479 6.68558 18.1718C5.46257 17.1956 4.53975 15.8214 4.04347 14.2372C3.54719 12.6529 3.50171 10.9362 3.91326 9.32203C4.32482 7.70785 5.17328 6.27513 6.34248 5.22003V5.49003C6.34485 8.17851 7.30266 10.7561 9.0057 12.6572C10.7087 14.5582 13.0179 15.6274 15.4263 15.63C16.0587 15.6326 16.6894 15.5589 17.3076 15.41C16.6894 16.7155 15.7636 17.8079 14.6301 18.5692C13.4965 19.3305 12.1983 19.7319 10.8754 19.73V19.69Z"
                  />
                </svg>
              )}

              <svg
                width="20"
                height="20"
                fill="none"
                viewBox="0 0 20 20"
                onClick={handleReset}
                xmlns="http://www.w3.org/2000/svg"
                className="codeEditorResetCode"
              >
                <path
                  stroke="#373739"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M1 1.99805V7.99962H5.90909"
                />
                <path
                  stroke="#373739"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M18.9994 18.0026V12.001H14.0903"
                />
                <path
                  stroke="#373739"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M16.9464 6.99921C16.5314 5.56562 15.8262 4.2839 14.8964 3.27365C13.9667 2.2634 12.8428 1.55755 11.6296 1.22195C10.4164 0.886356 9.15338 0.931957 7.95848 1.3545C6.76358 1.77704 5.6757 2.56276 4.79636 3.63833L1 7.99947M19 12.0005L15.2036 16.3617C14.3243 17.4372 13.2364 18.223 12.0415 18.6455C10.8466 19.068 9.58363 19.1136 8.37042 18.7781C7.1572 18.4425 6.03329 17.7366 5.10356 16.7264C4.17384 15.7161 3.46859 14.4344 3.05364 13.0008"
                />
              </svg>

              {!fullScreen ? (
                <svg
                  width="19"
                  height="20"
                  fill="none"
                  viewBox="0 0 19 20"
                  className="codeEditorFullS"
                  xmlns="http://www.w3.org/2000/svg"
                  onClick={() => toggleEditorModeFull(containerRef.current)}
                >
                  <path
                    stroke="#373739"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12.6246 1H17.9997M17.9997 1V7M17.9997 1L11.7288 8M7.24956 19H1.87451M1.87451 19V13M1.87451 19L8.14541 12"
                  />
                </svg>
              ) : (
                <svg
                  width="20"
                  height="20"
                  fill="none"
                  viewBox="0 0 20 20"
                  className="codeEditorFullN"
                  xmlns="http://www.w3.org/2000/svg"
                  onClick={() => toggleEditorMode(containerRef.current)}
                >
                  <path
                    d="M2 12H8V18"
                    strokeWidth="2"
                    stroke="#373739"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M18 8H12V2"
                    strokeWidth="2"
                    stroke="#373739"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M12 8L19 1"
                    strokeWidth="2"
                    stroke="#373739"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M1 19L8 12"
                    strokeWidth="2"
                    stroke="#373739"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              )}
            </div>
            <div
              className="TrycodeContent"

              //onClick={handleTryCodeBtn}
              //onClick={Props.onDataChange(code,langid,outPut)}
            >
              <button
                className="TrycodeContentButton"
                onClick={() => handleTryCodeBtn(language)}
              >
                Try code{" "}
                <svg
                  width="22"
                  height="22"
                  fill="none"
                  viewBox="0 0 24 22"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill="white"
                    d="M16.8 9.097L10.8 5.918C10.4353 5.725 10.0217 5.62335 9.60062 5.62325C9.17954 5.62315 8.76584 5.72461 8.40107 5.91743C8.03629 6.11026 7.73329 6.38766 7.52247 6.72179C7.31165 7.05592 7.20044 7.43501 7.2 7.821V14.179C7.20044 14.5648 7.31153 14.9437 7.52216 15.2777C7.73278 15.6117 8.03552 15.8891 8.4 16.082C8.76485 16.2751 9.17872 16.3767 9.6 16.3767C10.0213 16.3767 10.4352 16.2751 10.8 16.082L16.8 12.903C17.1637 12.7096 17.4656 12.432 17.6754 12.098C17.8853 11.7641 17.9958 11.3854 17.9958 11C17.9958 10.6146 17.8853 10.2359 17.6754 9.90195C17.4656 9.56797 17.1637 9.29039 16.8 9.097ZM15.6 11L9.6 14.179V7.821L15.6 11ZM12 0C9.62663 0 7.30655 0.645139 5.33316 1.85383C3.35977 3.06253 1.8217 4.78049 0.913451 6.79048C0.00519943 8.80047 -0.232441 11.0122 0.230582 13.146C0.693605 15.2798 1.83649 17.2398 3.51472 18.7782C5.19295 20.3165 7.33115 21.3642 9.65892 21.7886C11.9867 22.2131 14.3995 21.9952 16.5922 21.1627C18.7849 20.3301 20.6591 18.9202 21.9776 17.1113C23.2962 15.3023 24 13.1756 24 11C24 9.55546 23.6896 8.12506 23.0866 6.79048C22.4835 5.4559 21.5996 4.24327 20.4853 3.22182C19.371 2.20038 18.0481 1.39013 16.5922 0.837325C15.1363 0.284523 13.5759 0 12 0ZM12 19.8C10.1013 19.8 8.24524 19.2839 6.66653 18.3169C5.08782 17.35 3.85736 15.9756 3.13076 14.3676C2.40416 12.7596 2.21405 10.9902 2.58447 9.2832C2.95488 7.57617 3.8692 6.00816 5.21178 4.77746C6.55436 3.54676 8.26492 2.70864 10.1271 2.36909C11.9894 2.02954 13.9196 2.20381 15.6738 2.86986C17.4279 3.53591 18.9272 4.66383 19.9821 6.11098C21.037 7.55813 21.6 9.25952 21.6 11C21.6 13.3339 20.5886 15.5722 18.7882 17.2225C16.9879 18.8729 14.5461 19.8 12 19.8Z"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>

        <div
          className={fullScreen ? "editor-fullscreen" : "editor-normal"}
          // className={editorMode === "expand" ? "editor-expand" : "editor-normal"}
        >
          <AceEditor
            name="blah2"
            value={code}
            fontSize={14}
            mode={language}
            showGutter={true}
            wrapEnabled={true}
            ref={codeEditorRef}
            theme={editorTheme}
            showPrintMargin={false}
            highlightActiveLine={true}
            onChange={handleCodeChange}
            placeholder="Placeholder Text"
            width={fullScreen ? "100%" : "100%"}
            height={fullScreen ? "70vh" : "650px"}
            editorProps={{ $blockScrolling: false }}
            //onLoad={this.onLoad}
            commands={[
              {
                // commands is array of key bindings.
                //key combination used for the command.
                exec: function (editor) {
                  const editorEvents = [
                    "drop",
                    "dragend",
                    "dragover",
                    "dragenter",
                    "dragstart",
                    "dragleave",
                  ];
                  for (const events of editorEvents) {
                    editor.selection.getCursor();
                    editor.container.addEventListener(
                      events,
                      function (e) {
                        e.stopPropagation();
                      },
                      true
                    );
                  }
                },
              },
            ]}
            setOptions={{
              tabSize: 4,
              fontSize: 16,
              enableSnippets: true,
              showLineNumbers: true,
              wrapBehavioursEnabled: true,
              enableLiveAutocompletion: true,
              autoScrollEditorIntoView: true,
              enableBasicAutocompletion: false,
            }}
          />
        </div>
        {/* // for admin side editable for learner side not editable */}
        {/* <div>   {"outPut :" + outPut} </div> */}
        {isTryClicked ? (
          <div
            ref={outputref}
            className={fullScreen ? "adminOutputFullScreen" : "adminOutput"}
          >
            {loading ? (
              <div className="loading">
                {" "}
                <Loading />{" "}
              </div>
            ) : (
              <div>
                <div className="outputLabel">
                  <label className="adminOutputLabel">
                    {" "}
                    <h6>Output :</h6>{" "}
                  </label>
                </div>

                <div className="Output">
                  <pre>{outPut} </pre>{" "}
                </div>
                {/* <textarea className="Output" value={outPut} /> */}
              </div>
            )}
          </div>
        ) : (
          ""
        )}

        {/* <div className={isTryClicked ? "tryBtnclicked" : "tryBtnNotClicked"}>
        <label className="outputLabel">Output :</label>}

         */}

        {/* <div>
          <IconButton className="Closeicon" onClick={handleCross}>
            <ClearIcon />
          </IconButton>

          {outPut}
        </div> */}
      </div>
    )
    // </div>
  );
};
export default memo(CodeEditor);
