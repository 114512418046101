import "./Io.scss";
import { nanoid } from "nanoid";
import IoHooks from "./Hooks/IoHooks";
import VarTypes from "../VarTypes/VarTypes";
import constants from "../../../../../constants";
import ReadOnlyRow from "../ReadOnlyRow/ReadOnlyRow";
import EditableRow from "../EditableRow/EditableRow";
import {TextareaAutosize} from "@mui/base/TextareaAutosize";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import React, { useState, Fragment, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function Io(Props) {
  const {
    tsInp,
    errors,
    setErrors,
    inEditMode,
    checkOuputs,
    addTextArea,
    textAreaRef,
    addFormData,
    focusOnField,
    setInEditMode,
    editContactId,
    initialOutputs,
    setEditContactId,
    setInitialOutputs,
    handleDeleteOutput,
    handleAddFormChange,
  } = IoHooks();

  let [localCuin, setLocalCuin] = useState(Props.cuin);

  const [ioData, setIoData] = useState(
    Props.isqueReport &&
      Props.selectque.qdata.istestcases === constants.ISNOTESTCASES.FALSE
      ? JSON.parse(Props.selectque?.io[0].testCases)?.map((data) => {
          let aop = [];

          aop.push(data.output);

          for (let i = 0; i < data.altout.length; i++) {
            if (data.altout[0].output !== null) {
              aop.push(data.altout[i].output);
            }
          }

          return {
            output: aop,
            id: nanoid(),
            input: data.input,
            isExample: data.isExample,
          };
        })
      : []
  );

  const [editFormData, setEditFormData] = useState(
    Props.isqueReport
      ? ioData
      : [
          {
            input: "",
            output: [],
            isExample: "",
          },
        ]
  );

  const handleEditFormChange = (event, name, ind, tsindex) => {
    let tempformdata;

    if (name === "output") {
      tempformdata = { ...editFormData };
      tempformdata.output[ind] = event.target.value;
    }

    if (name === "input") {
      tempformdata = { ...editFormData };
      tempformdata.input = event;
    }

    if (name === "checkbox") {
      let fieldName;
      let fieldValue;

      fieldValue = event.target.checked;
      fieldName = event.target.getAttribute("name");
      tempformdata = { ...editFormData };
      tempformdata[fieldName] = fieldValue;
    }

    Props.setcuin([]);
    setEditFormData(tempformdata);
  };

  const replaceInitialOutputs = (arr) => {
    setInitialOutputs(arr);
  };

  const handleAddFormSubmit = (event) => {
    event?.preventDefault();

   // if (addFormData.input === "" && !checkOuputs(addFormData.output)) {
    //  setErrors("Input & Output cannot be empty");

    //  return;
    //} 
    
   // else {
      let temp = [...localCuin];

      for (let i = 0; i < Props.inputvariables.length; i++) {
        temp[i] = "";
      }

      Props.setcuin(temp);
      setLocalCuin(temp);
      // console.log("addFormData.input>>>>>>",addFormData.input);
      // console.log(">>>>>>>>>>>",Props.returntype);
      // console.log("type>>>>>>",typeof addFormData.output );
      if(Props.returntype.value=="string"){
        // console.log("addFormData.output.length",addFormData.output.length);
        if(addFormData.output.length==0){
          // console.log(true,);
          addFormData.output.push("");
        }
      }
      // console.log("addFormData.output",addFormData.output);
      const newContact = {
        id: nanoid(),
        input: addFormData.input,
        output: addFormData.output,
        isExample: addFormData.isExample,
      };

      let newArray = [""];

      replaceInitialOutputs(newArray);

      const newContacts = [...ioData, newContact];

      setIoData(newContacts);
      Props.iodata(newContacts, Props.activeLabel);

      addFormData.input = "";
      addFormData.output = [];
      addFormData.isExample = false;
    //}
  };

  const handleEditFormSubmit = (event) => {
    event?.preventDefault();

    if (editFormData.input === "" || !checkOuputs(editFormData.output)) {
      setErrors("Input & Output cannot be empty");

      return;
    }

    const editedContact = {
      id: editContactId,
      input: editFormData.input,
      output: editFormData.output,
      isExample: editFormData.isExample,
    };

    const newContacts = [...ioData];

    const index = ioData.findIndex((contact) => contact.id === editContactId);

    newContacts[index] = editedContact;
    setIoData(newContacts);
    Props.iodata(newContacts, Props.activeLabel);

    setInEditMode(false);
    setEditContactId(null);
    Props.setcuin([]);
  };

  const handleEditClick = (event, contact) => {
    event?.preventDefault();
    setInEditMode(true);

    setEditContactId(contact.id);

    const formValues = {
      input: contact.input,
      output: contact.output,
      isExample: contact.isExample,
    };

    setEditFormData(formValues);
  };

  const handleCancelClick = () => {
    Props.setcuin([]);
    setErrors("");
    setInEditMode(false);
    setEditContactId(null);
  };

  const handleDeleteClick = (contactId) => {
    const newContacts = [...ioData];

    const index = ioData.findIndex((contact) => contact.id === contactId);

    newContacts.splice(index, 1);
    setIoData(newContacts);
    Props.iodata(newContacts, Props.activeLabel);
    focusOnField();
  };

  const customInputs = (e, ind, from, tcIndex) => {
    if (from === "add") {
      let temp = [...tsInp.current];

      temp[ind] = e.target.value;
      tsInp.current = temp;
      Props.setcuin(temp);
      handleAddFormChange(temp.join("\n"), "newinp", "newinp");
    }

    if (from === "edit") {
      let temp = editFormData.input.split("\n");

      temp[ind] = e.target.value;
      Props.setcuin(temp);
      handleEditFormChange(temp.join("\n"), "input", null, tcIndex);
    }
  };

  const setInVatypes = (data) => {
    Props.setInVa(data);
  };

  useEffect(() => {
    setLocalCuin([...Props.cuin]);
  }, [Props.cuin]);

  return (
    <div>
      <form className="questionIo" onSubmit={handleEditFormSubmit}>
        <br />
        <VarTypes
          setInVatypes={setInVatypes}
          isqueReport={Props.isqueReport}
          inputVariables={Props.inputvariables}
          inputDataSource={Props.inputDataSource}
        />
        <div>
          <table className="ioTableTable">
            <thead className="ioTableTableHead">
              <tr>
                <th className="ioTableIo">
                  <span>Input</span>
                </th>
                <th className="ioTableIo">
                  <span>Output</span>
                </th>
                <th className="ioTableIsEx">
                  <span>isExample</span>
                </th>
                <th className="ioTableIsEx">
                  <span>Actions</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {ioData?.map((contact, ioind) => (
                <Fragment>
                  {editContactId === contact.id ? (
                    <EditableRow
                      index={ioind}
                      cuin={Props.cuin}
                      setcuin={Props.setcuin}
                      CustomInputs={customInputs}
                      editFormData={editFormData}
                      setEditFormData={setEditFormData}
                      inputvariables={Props.inputvariables}
                      handleCancelClick={handleCancelClick}
                      handleEditFormChange={handleEditFormChange}
                      handleEditFormSubmit={handleEditFormSubmit}
                    />
                  ) : (
                    <ReadOnlyRow
                      contact={contact}
                      handleEditClick={handleEditClick}
                      handleDeleteClick={handleDeleteClick}
                      handleEditFormSubmit={handleEditFormSubmit}
                    />
                  )}
                </Fragment>
              ))}
            </tbody>
          </table>
          <div className="testIo">
            <div className="questionInput" ref={textAreaRef}>
              {!inEditMode && Props.inputvariables?.length > 0 && (
                <>
                  {Props.inputvariables?.length > 0 &&
                    Props.inputvariables?.map((inp, indx) => (
                      <div
                        key={"addInputVarDiv" + indx}
                        className="questionInputInputs"
                      >
                        <span className="questionInputInputsLabel">{inp}:</span>
                        <span>
                          <TextareaAutosize
                            minRows={1}
                            id={"inputs" + indx}
                            style={{ width: 260 }}
                            key={"addInputVar" + indx}
                            placeholder={"Enter " + inp}
                            className="ioTableComponentTextAreaIp"
                            value={inEditMode ? "" : Props.cuin[indx]}
                            onChange={(e) => {
                              customInputs(e, indx, "add");
                            }}
                          />
                        </span>
                      </div>
                    ))}
                </>
              )}
            </div>
            <div className="questionIOutput">
              <div id="addOutputs">
                <i
                  class="fa fa-plus"
                  aria-hidden="true"
                  onClick={addTextArea}
                ></i>
              </div>
              {initialOutputs?.map((text, index) => (
                <div className="addOutputs">
                  <TextareaAutosize
                    value={text}
                    name="output"
                    placeholder="     Enter output..."
                    className="ioTableComponentTextAreaOp"
                    onChange={(e) =>
                      handleAddFormChange(e, "output", "nofrom", index)
                    }
                  />
                  <div
                    className="cancelOutput"
                    onClick={(e) => {
                      handleDeleteOutput(e, index);
                    }}
                  >
                    <FontAwesomeIcon className="faXmark" icon={faXmark} />
                  </div>
                </div>
              ))}
            </div>

            <div
              ref={textAreaRef}
              id="checkboxFocus"
              className="sampleIo"
              style={{ border: "1px solid #EBECED" }}
            >
              <input
                name="isExample"
                type={"checkbox"}
                checked={addFormData.isExample}
                onChange={(e) => handleAddFormChange(e, "checkbox")}
                style={{ margin: "10px 0 0 0", height: "20px", width: "15px" }}
              />
            </div>

            <div className="questionAddBtn" ref={textAreaRef}>
              <button
                type="button"
                className="ioAddBtn"
                onClick={(e) => {
                  handleAddFormSubmit(e);
                  focusOnField();
                }}
              >
                {" "}
                Add
              </button>
            </div>
          </div>
        </div>
        <p style={{ color: "red" }}>{errors}</p>
      </form>
    </div>
  );
}
