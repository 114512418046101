import React from "react";

export default function ExpandableTableText(Props) {
  return (
    <div className="warningData-info">
      {Props.data.map((d) => (
        <div key={d.lang.id}>
          There are {d.count}
          {d.count > 1 ? " questions" : " question"} available in {d.lang.name}
        </div>
      ))}
    </div>
  );
}
