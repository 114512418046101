import React from "react";
import "./ReadOnlyRow.scss";
import { faPencil } from "@fortawesome/free-solid-svg-icons";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function ReadOnlyRow(Props) {
  try {
    var Contactoutput = Props.contact.output.join(" | ");
  } catch (error) {}

  return (
    <tr style={{ border: "1px solid #EBECED" }}>
      <td
        style={{ border: "1px solid #EBECED", height: "45px", width: "350px" }}
      >
        {Props.contact.input}
      </td>
      <td
        style={{ border: "1px solid #EBECED", height: "45px", width: "350px" }}
      >
        {Contactoutput ? Contactoutput : Props.contact.output}{" "}
      </td>
      <td
        style={{ border: "1px solid #EBECED", height: "45px", width: "80px" }}
      >
        <div className="sampleIo">
          <input
            disabled
            type={"checkbox"}
            checked={Props.contact.isExample}
            style={{ margin: "10px 0 0 0", height: "20px", width: "15px" }}
          />
        </div>
      </td>
      <td
        style={{ border: "1px solid #EBECED", height: "45px", width: "80px" }}
      >
        <div className="editDelete">
          <div className="editDeleteEdit">
            <button
              style={{ border: "none", background: "none" }}
              onClick={(event) => Props.handleEditClick(event, Props.contact)}
            >
              <FontAwesomeIcon className="faPencil" icon={faPencil} />
            </button>
          </div>
          <div className="editDeleteDelete">
            <button
              style={{ border: "none", background: "none" }}
              onClick={() => Props.handleDeleteClick(Props.contact.id)}
            >
              <FontAwesomeIcon className="faTrashCan" icon={faTrashCan} />
            </button>
          </div>
        </div>
      </td>
    </tr>
  );
}
