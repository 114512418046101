import React, { useContext, useEffect, useMemo, useState } from 'react';
import Button from '../../../Button';
import UploadSvg from '../../../../Svg/UploadIconsvg';
import AlertContext from '../../../../context/AlertContext';
import useCommonFunctionHooks from '../../../../Hooks/useCommonFunctionHooks';
import useAxiosPrivate from '../../../../Hooks/useAxiosPrivate';
import constants from '../../../../constants';
import { useParams } from 'react-router-dom';

import { MaterialReactTable } from 'material-react-table';
import { getDataFromStorage } from '../../../../util';

export default function Tablecontent() {

    const Alert = useContext(AlertContext);
    const commonFunction = useCommonFunctionHooks();
    const axios = useAxiosPrivate();

    const { id } = useParams();
    const [tcLoaded, setTcLoaded] = useState(null);
    const [timelineData, setTimelineData] = useState([]);
    const [headers,setHeaders] = useState([]);
    useEffect(()=>{
      fetchTimeline();
    },[id]);

    const fetchTimeline = () => {
      axios.get(`node/admin/batch/profile/calendar/${id}`)
      .then((res) => {
        if (res.data.resultCode === 1000) {
          let TlData = typeof res.data.data.data === "string" ? JSON.parse(res.data.data.data) : res.data.data.data;
          let headersOfdata = typeof res.data.data.headers ==="string" ? JSON.parse(res.data.data.headers) :res.data.data.headers ;
          setHeaders(headersOfdata);
          setTimelineData(TlData);
        } else if (res.data.resultCode === 2050) {
          Alert.setShow(true);
          Alert.setTitle("Warning !");
          Alert.setMsg(res.data.msg);
        } else {
          Alert.setShow(true);
          Alert.setTitle("Error");
          Alert.setMsg("Something went wrong. Please try again later.");
        }
      })
      .catch((error) => {
        console.log("err in fetch timeline");
        if (error.message.includes("403")) {
            Alert.setShow(true);
            Alert.setIsUnAuth(true);
            Alert.setTitle("Error");
            Alert.setMsg(
                "You have been logged-out due to inactivity. Login again."
            );
        } else {
          Alert.setShow(true);
          Alert.setTitle("Error");
          Alert.setMsg(
              "Something went wrong, Please try again later"
          );
        }
      });
    }

    const handleUploadTcfile = (e) =>{
      const file = e.target.files[0];
      if (file && file.name.endsWith('.xlsx')) {
        setTcLoaded(file);
      } else {
        Alert.setShow(true);
        Alert.setTitle("Error");
        Alert.setMsg(
            "Please upload a valid .xlsx file."
        );
        e.target.value = '';
      }
    }

    const handleClearTcFile = () => {
        setTcLoaded(null);
    };

    const handleUploadTcFile = () => {
        if (!tcLoaded?.size) return;
        let reader = new FileReader();
        reader.onload = async (e) => {
            const form = new FormData();
            form.append("csv", tcLoaded);
            form.append("batchid", id);
            axios
              .post(`node/admin/batch/profile/upload/calendar`,
                form,
                commonFunction.getHeaderDetailsForAxios("multipart")
              )
              .then((res) => {
                  if (res.data.resultCode === 1000) {
                      Alert.setShow(true);
                      Alert.setTitle("Success");
                      Alert.setMsg(res.data.msg);
                      setTcLoaded(null);
                      fetchTimeline();
                  } else if (res.data.resultCode === 2050) {
                      Alert.setShow(true);
                      Alert.setTitle("Warning !");
                      Alert.setMsg(res.data.msg);
                  } else {
                      Alert.setShow(true);
                      Alert.setTitle("Error");
                      Alert.setMsg("Something went wrong. Please try again later.");
                  }
                })
              .catch((error) => {
                console.log("err in get timeline");
                if (error.message.includes("403")) {
                    Alert.setShow(true);
                    Alert.setIsUnAuth(true);
                    Alert.setTitle("Error");
                    Alert.setMsg(
                        "You have been logged-out due to inactivity. Login again."
                    );
                }
              });
        };
    
        reader.readAsDataURL(tcLoaded);
    };

  // const headers = useMemo(() => {

  //   if (timelineData.length > 0) {
  //     console.log("Object.keys(timelineData[0])?.map((header)=>header.replace(/\./g, ' '));",Object.keys(timelineData[0])?.map((header)=>header.replace(/\./g, ' ')));
  //     return Object.keys(timelineData[0])?.map((header)=>header.replace(/\./g, ' '));
  //   } else {
  //     return [];
  //   }
    
  // }, [timelineData]);

  // Generate columns based on the headers
  const columns = useMemo(() => {
    if (headers.length > 0) { 
      return headers.map((header) => ({
        accessorKey: header,
        header: header.replace(/ /g, '.'),
      }));
    } else {
      return [];
    }
    
  }, [headers]);
  

  return (
    <React.Fragment>
        <div className='upload_btn_tablecontent'>
          {(getDataFromStorage("role") === constants.Roles.admin || getDataFromStorage("role") === constants.Roles.trainer) &&<>
            {tcLoaded ==null &&
                <div className="upload-div">
                    <Button
                        size={"sm"}
                        disable={false}
                        onClick={handleUploadTcfile}
                        icon={{
                            type: "leading",
                            icon: <UploadSvg stroke="#344054" />,
                        }}
                        hierarchy={{
                            type: "secondaryGrey",
                            buttonText: "Status",
                        }}
                    ></Button>
                    <input
                        className="upload-div-input"
                        type="file"
                        accept='.xlsx'
                        // hidden
                        name="evaluation"
                        onChange={handleUploadTcfile}
                    />
                </div>
            }
            {tcLoaded != null && (
            <div className="afterfileloadedcont">
              {/* <div className="innercontafterfileloaded"> */}
                <p>{tcLoaded.name}</p>
                <svg
                  onClick={handleClearTcFile}
                  width="18"
                  height="18"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 4L4 12M4 4L12 12"
                    stroke="#667085"
                    stroke-width="1.33"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              {/* </div> */}
            </div>
          )}
          
          <div className='timeLine_Excel_SubmitBtn'>
            <Button
              disable={tcLoaded==null}
              size={"sm"}
              onClick={handleUploadTcFile}
              hierarchy={{
                buttonText: "Submit",
                type: "primary",
              }}
            />
          </div>
        </>}
      </div>
      <div>
        {(headers.length>0 && timelineData.length>0) && 
          <MaterialReactTable
            columns={columns}
            data={timelineData.map((row) => {
              const transformedRow = {};
              Object.keys(row).forEach((key) => {
                const transformedKey = key.replace(/\./g, ' ');
                transformedRow[transformedKey] = row[key];
              });
              return transformedRow;
            })}
            enableColumnResizing
            enableSorting
            // enableGlobalFilter={false}
            initialState={{ density: 'comfortable' }}
          />}
      </div>
    </React.Fragment>
  )
}
