import moment from "moment";
import constants from "../constants";
import { useContext, useState } from "react";
import useAxiosPrivate from "./useAxiosPrivate";
import AlertContext from "../context/AlertContext";
import useCommonFunctionHooks from "./useCommonFunctionHooks";
import CommentsHooks from "./CommentsHooks";

export default function RejectHooks() {
  const Alert = useContext(AlertContext);

  const axios = useAxiosPrivate();
  const { handleAddComments } = CommentsHooks();

  const { getCatchBlockDetails, getHeaderDetailsForAxios } =
    useCommonFunctionHooks();

  const [newTime, setNewTime] = useState();
  const [reset, setReset] = useState(false);
  const [rejectOption, setRejectOption] = useState("");
  const [rejectConfirm, setRejectConfirm] = useState(false);
  const [rejectWithNull, setRejectWithNull] = useState(false);
  const [minExtendDateValue, setMinExtendDateValue] = useState(true);

  const [minExtendDate, setMinExtendDate] = useState(
    moment().format("YYYY-MM-DD HH:mm:ss")
  );

  const [extensionDate, setExtensionDate] = useState(
    moment().format("YYYY-MM-DD HH:mm:ss")
  );

  const handleResubmissionReject = () => {
    if (Alert.comment !== "") {
      handleAddComments("", "reject");
    }

    if (Alert.testData.testtype === constants.libTypeIds.ASSIGNMENT) {
      handleResubmission();
    } else {
      if (rejectOption === "extsam") {
        handleResubmission(Alert.rejectData.timeleft);
      } else if (rejectOption === "givetime") {
        handleResubmission(newTime * 60);
      } else if (rejectOption === "restart") {
        handleResubmission(Alert.rejectData.duration);
      } else if (rejectOption === "scrnil") {
        handleResubmission(Alert.rejectData.timeleft);
      }
    }

    setMinExtendDateValue(false);
  };

  const rejectOptionChange = (value) => {
    setRejectOption(value);

    if (value === "scrnil") {
      setRejectWithNull(true);
      setReset(false);
    } else {
      setRejectWithNull(false);

      if (value === "extsam") {
        setReset(false);
        setRejectWithNull(false);
      }

      if (value === "restart") {
        setReset(true);
        setRejectWithNull(false);
      }

      if (value === "givetime") {
        setReset(false);
        setRejectWithNull(false);
      }
    }
  };

  const extensionDateChange = (exdate) => {
    setExtensionDate(moment(exdate).format("YYYY-MM-DD HH:mm:ss"));
  };

  const setGivenTime = (e) => {
    if (e.target.value > Alert.rejectData.duration / 60) {
      setNewTime(Alert.rejectData.duration / 60);
    } else {
      setNewTime(e.target.value);
    }
  };

  const getTime = (element) => {
    if (element === "duration") {
      return (
        Math.trunc(Alert.rejectData.duration / 60) +
        " min " +
        (Alert.rejectData.duration % 60 > 0
          ? (Alert.rejectData.duration % 60) + " s"
          : "")
      );
    } else {
      return (
        Math.trunc(Alert.rejectData.timeleft / 60) +
        " min " +
        (Alert.rejectData.timeleft % 60 > 0
          ? (Alert.rejectData.timeleft % 60) + " s"
          : "")
      );
    }
  };

  const getDueDateLogic = () => {
    if (minExtendDateValue) {
      let currentDate = new Date(),
        currentDuedate = new Date(Alert.showBreadCrumbsData.learnerDueDate);

      if (currentDuedate < currentDate) {
        setExtensionDate(moment(currentDate).format("YYYY-MM-DD HH:mm:ss"));
        setMinExtendDate(moment(currentDate).format("YYYY-MM-DD HH:mm:ss"));
      } else {
        setExtensionDate(
          moment(Alert.showBreadCrumbsData.learnerDueDate).format(
            "YYYY-MM-DD HH:mm:ss"
          )
        );
        setMinExtendDate(
          moment(Alert.showBreadCrumbsData.learnerDueDate).format(
            "YYYY-MM-DD HH:mm:ss"
          )
        );
      }
    }
  };

  const getUrlForReSubmission = () => {
    if (rejectWithNull) {
      return "node/admin/test/score";
    } else {
      return "node/admin/test/resubmit";
    }
  };

  const getPayLoadForReSubmission = (newTime) => {
    let payLoad = {
      testid: Alert.showBreadCrumbsData.testId,
      libtypeid: Alert.showBreadCrumbsData.type,
      learnerid: Alert.showBreadCrumbsData.learnerId,
      questionid: Alert.showBreadCrumbsData.questionId,
      lpconfigid: Alert.showBreadCrumbsData.lpConfigId,
    };

    if (Alert.showBreadCrumbsData.type === constants.libTypeIds.ASSIGNMENT) {
      payLoad.questionid = Alert.showBreadCrumbsData.testId;
      payLoad.testid = null;
    } else {
      payLoad.questionid = Alert.showBreadCrumbsData.questionId;
    }

    if (rejectWithNull) {
      payLoad.reject = rejectWithNull;
    } else {
      payLoad.reset = reset;
      payLoad.timeleft = newTime;
      payLoad.exdate = extensionDate.toString();
    }

    return JSON.stringify(payLoad);
  };

  const handleResubmission = (newTime) => {
    let successMsg = rejectWithNull
      ? "Test rejected and score updated to 0"
      : "Resubmission request successfull!";

    Alert.setIsLoaded(true);

    axios
      .post(
        getUrlForReSubmission(),
        getPayLoadForReSubmission(newTime),
        getHeaderDetailsForAxios()
      )
      .then((res) => {
        if (res.data.resultCode === constants.RESULT_STATUS.SUCCESS) {
          Alert.setShowReject(false);
          Alert.setShowApprove(false);
          setMinExtendDate(extensionDate);
          Alert.setShowNotify({
            show: true,
            title: "Info",
            msg: successMsg,
          });

          if (
            Alert.showBreadCrumbsData.type === constants.libTypeIds.ASSIGNMENT
          ) {
            Alert.setStatus(constants.PROGRESS_STATUS.REATTEMPT);
          }
        } else {
          Alert.setShowReject(false);
          Alert.setShowApprove(false);
          Alert.setShowNotify({
            show: true,
            title: "Info",
            msg: "Something went wrong. Please, try again",
          });
        }
      })
      .catch(function (error) {
        getCatchBlockDetails(error);
      })
      .finally(() => Alert.setIsLoaded(false));
  };

  return {
    newTime,
    getTime,
    rejectOption,
    setGivenTime,
    minExtendDate,
    extensionDate,
    rejectConfirm,
    getDueDateLogic,
    setRejectConfirm,
    minExtendDateValue,
    handleResubmission,
    rejectOptionChange,
    extensionDateChange,
    handleResubmissionReject,
  };
}
