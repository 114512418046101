import Button from "../../../Button";
import DataTable from "react-data-table-component";
import React, { useContext, useEffect } from "react";
import AlertContext from "../../../../context/AlertContext";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Loading from "../../../Notification-Loading/Loading/Loading";
import { MockTestPageContext } from "../../../../context/MockTestPageContext";
import MockLearningPathListHooks from "../../../../Hooks/Mock/Admin/MockLearningPath/MockLearningPathListHooks";

export default function MockLearningPathList() {
  const { isAddLp, isLoaded, isOldLpDetail } = useContext(AlertContext);

  const { searchText } = useContext(MockTestPageContext);

  const {
    columns,
    filteredData,
    customStyles,
    handleSearchTitle,
    handleCreateTestBtn,
    getMockLearningPathList,
    handleSelectTopicAddToLp,
    handleGetLearningPathDetails,
  } = MockLearningPathListHooks();

  useEffect(() => {
    if (!isAddLp) {
      getMockLearningPathList();
    } else return undefined;
  }, [isAddLp]);

  return (
    <div className="mockTestListMainContainer">
      {!isAddLp && !isOldLpDetail && (
        <>
          <div className="mockTestListHeader">
            <h2 className="mockTestListHeaderLabel">Mock Learningpath List</h2>
            <div className="mockTestListHeaderRightSideDiv">
              <FontAwesomeIcon
                icon={faSearch}
                className="mockTestListHeaderRightSearchIcon"
              />
              <input
                type="text"
                name="search"
                value={searchText}
                placeholder="Search"
                onChange={handleSearchTitle}
                className="mockTestListHeaderSearchBox"
              />
              <Button
                size="sm"
                onClick={handleCreateTestBtn}
                hierarchy={{
                  type: "primary",
                  buttonText: "Create Mock Learningpath",
                }}
              />
            </div>
          </div>
          <DataTable
            columns={columns}
            data={filteredData}
            customStyles={customStyles}
            className="mockTestListTable"
            onSelectedRowsChange={handleSelectTopicAddToLp}
            onRowClicked={(data) =>
              handleGetLearningPathDetails({ name: data.name, id: data.id })
            }
          />
        </>
      )}
      {isLoaded && <Loading />}
    </div>
  );
}
