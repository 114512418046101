import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import SplitPane from "react-split-pane";
import constants from "../../../constants";
import Pane from "react-split-pane/lib/Pane";
import CustomTabPanel from "./CustomTabPanel";
import { getDataFromStorage } from "../../../util";
import Scrollbars from "react-custom-scrollbars-2";
import CustomFileExplorer from "./CustomFileExplorer";
import Tooltip from "@mui/material/Tooltip";
import AlertContext from "../../../context/AlertContext";
import { sandpackDark } from "@codesandbox/sandpack-themes";
import Loading from "../../Notification-Loading/Loading/Loading";
import useUploadLargeFileToS3 from "../../../Hooks/useUploadLargeFileToS3";
import SandPackEditorSplitHooks from "../../../Hooks/SandPackEditorSplitHooks";
import FullScreenSvgIcon from "../../../Svg/FullScreenSvgIcon";
import Profile from "../Home/LandingPage/Profile";
import React, {
  useRef,
  useState,
  useEffect,
  useContext,
  useCallback,
} from "react";

import {
  useSandpack,
  SandpackLayout,
  SandpackThemeProvider,
  useActiveCode,
  FileTabs,
  SandpackPreview,
  SandpackConsole,
} from "@codesandbox/sandpack-react";
import MonacoEditor from "./MonacoEditor";

export function validateImageExtension(file) {
  var allowedExtensions = ["jpg", "jpeg", "png", "gif"];
  var extension = file.name.split(".").pop().toLowerCase();
  return allowedExtensions.indexOf(extension) !== -1;
}

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

export default function SandpackReactEditorTools(Props) {
  const {
    handleDragFinished,
    outputWindowHeight,
    problemWindowHeight,
    setOutputWindowHeight,
    setProblemWindowHeight,
    handleProblemDragFinished,
  } = SandPackEditorSplitHooks();

  const {
    isAdmin,
    learnerId,
    sandpackFiles,
    setSandpackFiles,
    openSandpackEditor,
    isFullScreen,
    setIsFullScreen,
    testData,
    isTakeTest,
  } = useContext(AlertContext);

  const { uploadFileToS3, getS3ObjectByFolder, deleteS3Objects, getS3Object } =
    useUploadLargeFileToS3();

  const { sandpack } = useSandpack();
  const { updateCode } = useActiveCode();

  const preValue = useRef(0);
  // const previewRef = useRef(null);
  const outputRef = useRef(null);
  const getSandpackFilesCalled = useRef(false);
  // const [isFullScreen, setIsFullScreen] = useState(false);
  const [value, setValue] = useState(0);
  // const [refreshKey, setRefreshKey] = useState(0);
  // const [previewUrl, setPreviewUrl] = useState("");
  const [filesLoaded, setFilesLoaded] = useState(false);
  const [previewLoaded, setPreviewLoaded] = useState(false);
  // const [consoleMessages, setConsoleMessages] = useState([]);

  const getSandpackFiles = useCallback(async () => {
    if (
      (isAdmin && Props.isqueReport) ||
      (!isAdmin &&
        Props.isAddSubmission &&
        Props.status === constants.PROGRESS_STATUS.UNLOCKED)
    ) {
      const filePath = `${process.env.REACT_APP_WEBSITE_HOST}/library/${Props.libDetails.id}/${Props.libDetails.id}.json`;
      let fileData = await getS3Object(
        filePath,
        process.env.REACT_APP_S3_WEBSITE_HOST_BUCKET_NAME
      );
      fileData = await new Response(fileData.Body).json();
      sandpack.addFile(fileData);
      updateCode(fileData[sandpack.activeFile]?.code);
      setFilesLoaded(true);
    } else if (Props.status !== constants.PROGRESS_STATUS.UNLOCKED) {
      const filePath = `${process.env.REACT_APP_WEBSITE_HOST}/${
        Props.libDetails.id
      }/${isAdmin ? learnerId : getDataFromStorage("learnerid")}.json`;

      let fileData = await getS3Object(
        filePath,
        process.env.REACT_APP_S3_WEBSITE_HOST_BUCKET_NAME
      );
      fileData = await new Response(fileData.Body).json();
      sandpack.addFile(fileData);
      updateCode(fileData[sandpack.activeFile]?.code);
      setFilesLoaded(true);
    } else {
      return;
    }
  }, [
    isAdmin,
    Props.status,
    Props.libDetails,
    Props.isqueReport,
    Props.isAddSubmission,
  ]);

  useEffect(() => {
    const savedSize = localStorage.getItem("splitPos");
    const savedWidthSize = localStorage.getItem("splitWidthPos");

    if (savedSize) {
      const [,] = savedSize.split(","); // Extract the height percentage value
      setOutputWindowHeight(8.4);
    }

    if (savedWidthSize) {
      const [widthPercentage] = savedWidthSize.split(",");
      const parsedWidth = parseFloat(widthPercentage.replace("%", ""));

      setProblemWindowHeight(parsedWidth);
    }

    const editorHeightdiv = document.getElementById("sandpackCodeEditor");

    if (editorHeightdiv) {
      editorHeightdiv.style.height = `90vh`;
    }
  }, [setProblemWindowHeight, setOutputWindowHeight]);

  useEffect(() => {
    if (previewLoaded) {
      setTimeout(() => {
        setPreviewLoaded(false);
      }, 1000);
    }
  }, [previewLoaded]);

  useEffect(() => {
    if (!getSandpackFilesCalled.current) {
      if (Props.libDetails.id) {
        getSandpackFiles();
      }

      getSandpackFilesCalled.current = true;
    }
  }, [getSandpackFiles, Props.libDetails.id]);

  useEffect(() => {
    setSandpackFiles(sandpack.files);
  }, [sandpack, setSandpackFiles]);

  // useEffect(() => {
  //   const handleMessage = (event) => {
  //     if (event.data.message) {
  //       setConsoleMessages((prevMessages) => [
  //         ...prevMessages,
  //         event.data.message,
  //       ]);
  //     }
  //   };

  //   if (window.addEventListener) {
  //     window.addEventListener("message", handleMessage);
  //   } else {
  //     window.attachEvent("onmessage", handleMessage);
  //   }
  // }, [previewUrl]);

  // const refreshIframe = async () => {
  //   setConsoleMessages([]);

  //   setRefreshKey((prevKey) => prevKey + 1);

  //   const learnerId = getDataFromStorage("learnerid");
  //   const s3Object = await getS3ObjectByFolder(
  //     isAdmin && Props.isqueReport
  //       ? `${process.env.REACT_APP_WEBSITE_HOST}/library/${Props.libDetails.id}`
  //       : isAdmin
  //       ? `${process.env.REACT_APP_WEBSITE_HOST}/admin/${learnerId}`
  //       : `${process.env.REACT_APP_WEBSITE_HOST}/${Props.libDetails.id}/${learnerId}`
  //   );

  //   if (Object.keys(s3Object ?? {}).length)
  //     await deleteS3Objects(
  //       Object.keys(s3Object).map((file) => ({
  //         Key:
  //           isAdmin && Props.isqueReport
  //             ? `${process.env.REACT_APP_WEBSITE_HOST}/library/${Props.libDetails.id}${file}`
  //             : isAdmin
  //             ? `${process.env.REACT_APP_WEBSITE_HOST}/admin/${learnerId}${file}`
  //             : `${process.env.REACT_APP_WEBSITE_HOST}/${Props.libDetails.id}/${learnerId}${file}`,
  //       })),
  //       "deleteWebHost"
  //     );

  //   setSandpackFiles(sandpack.files);
  //   const uploadPromises = Object.keys(sandpack.files).map(async (file) => {
  //     const isImage = validateImageExtension({ name: file });
  //     let code = sandpack.files[file].code;

  //     let extraParams = {};
  //     if (isImage) {
  //       extraParams.ContentEncoding = "base64";
  //       const base64Data = code
  //         .replace(/^data:application\/octet-stream;base64,/, "")
  //         .replace(/^data:image\/jpeg;base64,/, "")
  //         .replace(/^data:image\/jpg;base64,/, "")
  //         .replace(/^data:image\/png;base64,/, "");
  //       code = Uint8Array.from(atob(base64Data), (char) => char.charCodeAt(0));
  //     }
  //     try {
  //       await uploadFileToS3(
  //         code,
  //         isAdmin && Props.isqueReport
  //           ? `${process.env.REACT_APP_WEBSITE_HOST}/library/${Props.libDetails.id}${file}`
  //           : isAdmin
  //           ? `${process.env.REACT_APP_WEBSITE_HOST}/admin/${learnerId}${file}`
  //           : `${process.env.REACT_APP_WEBSITE_HOST}/${Props.libDetails.id}/${learnerId}${file}`,
  //         file.split(".")[1],
  //         "uploadAssignment",
  //         extraParams
  //       );
  //     } catch (error) {
  //       console.error("Error uploading file to S3:", error);
  //     }
  //   });

  //   await Promise.all(uploadPromises);

  //   setSandpackFiles(sandpack.files);

  //   const baseURL = `https://${process.env.REACT_APP_S3_WEBSITE_HOST_BUCKET_NAME}.s3.ap-south-1.amazonaws.com/${process.env.REACT_APP_WEBSITE_HOST}`;
  //   const previewUrl =
  //     isAdmin && Props.isqueReport
  //       ? `${baseURL}/library/${Props.libDetails.id}`
  //       : isAdmin
  //       ? `${baseURL}/admin/${learnerId}/index.html`
  //       : `${baseURL}/${Props.libDetails.id}/${learnerId}/index.html`;
  //   setPreviewUrl(previewUrl);
  // };

  const handleChange = (event, newValue) => {
    event.preventDefault();
    preValue.current = newValue;
    setValue(newValue);
  };

  const handleFullScreen = () => {
    if (!isFullScreen) {
      // Request full-screen mode
      if (outputRef.current.requestFullscreen) {
        outputRef.current.requestFullscreen();
      } else if (outputRef.current.mozRequestFullScreen) {
        outputRef.current.mozRequestFullScreen();
      } else if (outputRef.current.webkitRequestFullscreen) {
        outputRef.current.webkitRequestFullscreen();
      } else if (outputRef.current.msRequestFullscreen) {
        outputRef.current.msRequestFullscreen();
      }
      setIsFullScreen(true);
    } else {
      // Exit full-screen mode
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
      setIsFullScreen(false);
    }
  };

  return (
    <SandpackLayout>
      <SandpackThemeProvider theme={sandpackDark}>
        <div className="sandpackEditor">
          <SplitPane
            size={100}
            minSize={30}
            primary="first"
            split="vertical"
            className="fileExplorerDiv"
            onChange={handleProblemDragFinished}
          >
            <Pane
              minSize="20%"
              maxSize="30%"
              iniinitialSize="30%"
              className="fileExplorerpane"
              paneStyle={{ backgroundColor: "red" }}
              initialSize={`${problemWindowHeight}%`}
            >
              <Scrollbars autoHide>
                <CustomFileExplorer
                  sandpack={sandpack}
                  filesLoaded={filesLoaded}
                  sandpackFiles={sandpackFiles}
                  setFilesLoaded={setFilesLoaded}
                  isopenEditor={openSandpackEditor}
                  setSandpackFiles={setSandpackFiles}
                  setOpenSandpackEditor={Props.setOpenSandpackEditor}
                />
              </Scrollbars>
            </Pane>
            <SplitPane
              primary="second"
              split="horizontal"
              onChange={handleDragFinished}
            >
              <Pane
                minSize="2%"
                maxSize="90%"
                className="editor-pane"
                onChange={handleDragFinished}
              >
                <>
                  <FileTabs closableTabs={true} />
                  <MonacoEditor />
                  {!isAdmin &&
                    Props.status !== constants.PROGRESS_STATUS.EVALUATED &&
                    testData.proctored == constants.PROCTORING_MODE.TAB_CAM && (
                      <Profile
                        isTakeTest={isTakeTest}
                        questionid={testData.libid}
                        libtypeid={constants.libTypeIds.ASSIGNMENT}
                      />
                    )}
                </>
              </Pane>
              <Pane
                minSize={"5%"}
                maxSize={"95%"}
                id="outputParentWindowContainer"
                initialSize={`${outputWindowHeight}%`}
              >
                <div className="outputandpreview" ref={outputRef}>
                  <Box sx={{ bgcolor: "background.paper" }}>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                      <Tabs
                        value={value}
                        onChange={handleChange}
                        indicatorColor="secondary"
                        aria-label="basic tabs example"
                        className="outputsandpacktab"
                      >
                        <Tab label="Output" {...a11yProps(0)} />
                        <Tab label="Console" {...a11yProps(1)} />
                        <Tooltip
                          placement="right-start"
                          title="Toggle FullScreen"
                        >
                          <div
                            className="openinnewtab"
                            // onClick={handleOpenInNewTab}
                            onClick={handleFullScreen}
                          >
                            <FullScreenSvgIcon />
                          </div>
                        </Tooltip>
                      </Tabs>
                    </Box>
                    <CustomTabPanel value={value} index={0}>
                      {previewLoaded ? (
                        <div
                          style={{
                            position: "relative",
                            transform: "translate(-70px,70px)",
                          }}
                        >
                          <Loading />
                        </div>
                      ) : (
                        <SandpackPreview
                          showRefreshButton={true}
                          showOpenInCodeSandbox={false}
                          showRestartButton={true}
                        />
                      )}
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={1}>
                      {/* <div className="sandpackConsoleSec">
                        <button
                          title="Clear"
                          className="sandpackConsoleClearBtn"
                          onClick={() => setConsoleMessages([])}
                        >
                          Clear
                        </button>
                        {consoleMessages.map((message, index) => (
                          <div key={index}>{JSON.stringify(message)}</div>
                        ))}
                      </div> */}
                      <SandpackConsole />
                    </CustomTabPanel>
                  </Box>
                </div>
              </Pane>
            </SplitPane>
          </SplitPane>
        </div>
      </SandpackThemeProvider>
    </SandpackLayout>
  );
}
