import React, { useRef, useEffect } from "react";

export default function StudentTestVideoPreview({ stream, isBlurred }) {
  const videoRef = useRef(null);

  useEffect(() => {
    if (
      stream &&
      videoRef.current &&
      videoRef.current.srcObject?.active !== stream?.active
    ) {
      videoRef.current.srcObject = stream;
    }
  }, [stream]);

  return (
    <video
      autoPlay
      ref={videoRef}
      className={`video-recorder ${isBlurred ? "blurred" : ""}`}
    />
  );
}
