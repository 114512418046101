import Button from "../../Button";
import constants from "../../../constants";
import React, { useContext, useEffect } from "react";
import TestDueHooks from "../../../Hooks/TestDueHooks";
import AlertContext from "../../../context/AlertContext";
import TestDueDate from "../../CommonComponents/TestDueDate";
import McqAndDescHooks from "../../../Hooks/McqAndDescHooks";
import RightArrowSvgIcon from "../../../Svg/RightArrowSvgIcon";
import WarningNoticeHooks from "../../../Hooks/WarningNoticeHooks";
import WarningNoticeSvgIcon from "../../../Svg/WarningNoticeSvgIcon";
import CompleteSvgIcon from "../../CommonComponents/CompleteSvgIcon";
import WarningNoticeBodySvgIcon from "../../../Svg/WarningNoticeBodySvgIcon";

export default function WarningNotice() {
  const Alert = useContext(AlertContext);

  const { viewTest } = McqAndDescHooks();
  const { currentDueLabel } = TestDueHooks();
  const WarningNoticeHks = WarningNoticeHooks();

  useEffect(() => {}, [Alert.VideoSaved]);

  return (
    <div className={WarningNoticeHks.getMainDivClassNameForWarningNotice()}>
      {WarningNoticeHks.showCompleteSvgIcon() ? (
        <CompleteSvgIcon />
      ) : (
        <WarningNoticeSvgIcon />
      )}
      <div className="warningNoticeInfo">
        <p className="warningNoticeInfoHeader">
          {Alert.status !== constants.PROGRESS_STATUS.EVALUATED &&
          currentDueLabel(Alert.testData.duedate) === "Past Due" &&
          Alert.status !== constants.PROGRESS_STATUS.TO_BE_EVALUATED &&
          Alert.status !== constants.PROGRESS_STATUS.PARTIAL_EVALUATED ? (
            WarningNoticeHks.currentTestDue(
              Alert.testData.libtype !== null
                ? Alert.testData.libtype
                : Alert.testData.testtype
            )
          ) : Alert.status === constants.PROGRESS_STATUS.IN_PROGRESS ? (
            "Contact Trainer"
          ) : Alert.status === constants.PROGRESS_STATUS.QN_CONTINUE ? (
            "The test had a problem, you can resume it."
          ) : Alert.status === constants.PROGRESS_STATUS.REATTEMPT ? (
            "The trainer has asked you to retake the test"
          ) : Alert.status === constants.PROGRESS_STATUS.EVALUATED ||
            Alert.status === constants.PROGRESS_STATUS.TO_BE_EVALUATED ||
            Alert.status === constants.PROGRESS_STATUS.PARTIAL_EVALUATED ? (
            <div>
              Completed
              <p className="warningNoticeInfoHeaderSubText">
                You've completed this test, you can go to the next available
                test/chapter.
              </p>
            </div>
          ) : (
            WarningNoticeHks.showWarningForAssessment() &&
            "The test has to be taken in Gradious LMS Desktop App."
          )}
        </p>
        <div className="warningNoticeInfoBody">
          {Alert.status !== constants.PROGRESS_STATUS.EVALUATED &&
          currentDueLabel(Alert.testData.duedate) === "Past Due" &&
          Alert.status !== constants.PROGRESS_STATUS.TO_BE_EVALUATED &&
          Alert.status !== constants.PROGRESS_STATUS.PARTIAL_EVALUATED ? (
            <>
              The due date for the{" "}
              {WarningNoticeHks.currentTestDue(
                Alert.testData.libtype !== null
                  ? Alert.testData.libtype
                  : Alert.testData.testtype,
                "name"
              )}{" "}
              has crossed. Request your trainer to extend the due date.
              <div className="warningNoticeInfoBodyDivText">
                If your trainer extends, you can complete the{" "}
                {WarningNoticeHks.currentTestDue(
                  Alert.testData.libtype !== null
                    ? Alert.testData.libtype
                    : Alert.testData.testtype,
                  "test"
                )}
                .
              </div>
            </>
          ) : Alert.status === constants.PROGRESS_STATUS.QN_CONTINUE ? (
            <>
              There has been an issue in your test. This can be for multiple
              reasons. You can talk to our team for the exact issue.
              <div className="warningNoticeInfoBodyDivText">
                You will be allowed to take the test from where you left.
              </div>
            </>
          ) : Alert.status === constants.PROGRESS_STATUS.REATTEMPT ? (
            <>
              You need to take the test again as the trainer has asked to. This
              can be for multiple reasons. You can talk to our team for the
              exact issue.
              <div className="warningNoticeInfoBodyDivText">
                Your older attempt will be deleted and this new attempt will be
                considered.
              </div>
            </>
          ) : Alert.status === constants.PROGRESS_STATUS.IN_PROGRESS ? (
            <>
              We encountered an issue in your test. It can be for multiple
              reasons:
              <ul className="warningNoticeInfoBodyUnorderedList">
                <li>You left the test page by mistake.</li>
                <li>You were glancing away from this screen.</li>
                <li>
                  We couldn't proctor you because your face wasn't visible.
                </li>
              </ul>
              <div className="warningNoticeInfoBodyDivText">
                You can talk to our team for the exact issue. If your trainer
                extends, you can complete the test.
              </div>
            </>
          ) : WarningNoticeHks.showWarningForAssessment() ? (
            <>
              We do not allow tests to be taken in a web browser due to
              unsupported features.
              <div className="warningNoticeInfoBodyDivText">
                Please open the Gradious LMS application you’ve already
                downloaded to begin your test.
              </div>
            </>
          ) : (
            (Alert.status === constants.PROGRESS_STATUS.EVALUATED ||
              Alert.status === constants.PROGRESS_STATUS.TO_BE_EVALUATED ||
              Alert.status === constants.PROGRESS_STATUS.PARTIAL_EVALUATED) && (
              <div className="submissionDetail">
                <div className="submissionDetailHeader">
                  <div className="submissionDetailHeaderField">
                    Status
                    <span className="submissionDetailHeaderFieldColon">:</span>
                  </div>
                  {Alert.testData.viewtest ===
                    constants.DISPLAY_TEST_RESULT.SCORE && (
                    <div className="submissionDetailHeaderSubField">
                      Score
                      <span className="submissionDetailHeaderFieldColon">
                        :
                      </span>
                    </div>
                  )}
                  <div className="submissionDetailHeaderSubField">
                    Submission Date
                    <span className="submissionDetailHeaderFieldColon">:</span>
                  </div>
                </div>
                <div className="submissionDetailValue">
                  {Alert.testData.viewtest !==
                  constants.DISPLAY_TEST_RESULT.SCORE
                    ? `${
                        Alert.testData.videoqn == 1 &&
                        Alert.qnTrack.current !== Alert.VideoSaved
                          ? `Video upload inprogress (${
                              Alert.VideoSaved + "/" + Alert.qnTrack.current
                            })`
                          : "Submitted"
                      }`
                    : "Evaluated"}
                  {Alert.testData.viewtest ===
                    constants.DISPLAY_TEST_RESULT.SCORE && (
                    <div className="submissionDetailFieldValue">
                      {Alert.testData.actualscore}/
                      {Alert.testData.configdata.marks}
                    </div>
                  )}
                  <div className="submissionDetailFieldValue">
                    <TestDueDate component="warningNotice" />
                  </div>
                  {(Alert.testData.viewtest ===
                    constants.DISPLAY_TEST_RESULT.SUBMISSION ||
                    Alert.testData.viewtest ===
                      constants.DISPLAY_TEST_RESULT.ALL) && (
                    <div className="submissionDetailValueButton">
                      <Button
                        size={"sm"}
                        onClick={viewTest}
                        disable={Alert.buttonDisable}
                        hierarchy={{
                          type: "secondaryGrey",
                          buttonText: "View Answers",
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
            )
          )}
        </div>
      </div>
      {!WarningNoticeHks.showCompleteSvgIcon() ? (
        <WarningNoticeBodySvgIcon />
      ) : (
        <div className="warningNoticeSubmissionButton">
          <Button
            size={"sm"}
            onClick={WarningNoticeHks.goToNextTest}
            icon={{ type: "trailing", icon: <RightArrowSvgIcon /> }}
            hierarchy={{
              type: "primary",
              buttonText: "Next  ",
            }}
          />
        </div>
      )}
    </div>
  );
}
